import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';




////////////////////////////////

const ZPurchaseOrderButton= (props) => {
  const history = useHistory();

  return (
    <strong>
      <Router>
        <div>
          <Button color="primary" 
            id={props.id}
            style={{ marginLeft: 8 }}
            onClick={(event) => { 
              let path = generatePath('/purchase_order/:id', {
                id: props.id}
                )
              history.push(path)
            }}                     
          >
            {`${props.label ? props.label : 'Primac PO#'} ${props.title}`}
          </Button>
        </div>
      </Router>
    </strong>

  )
}

export default ZPurchaseOrderButton;