import React from 'react';
import clsx from 'clsx';
import { makeStyles, styled } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { addSensorTrigger, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'; //Menu
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { uuid } from 'uuidv4';
import moment from 'moment';
import ZSensorAutocomplete from 'components/core/ZSensorAutocomplete';
import { CheckBox, StarRateOutlined } from '@material-ui/icons';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  timeUnits: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  },   
}));

//////////////////////

const ZFormControlLabel = styled(FormControlLabel)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  
});

//////////////////////////////////////////////////

const ZAssignContactSensorToTriggerButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [anchorEl, setAnchorEl] = React.useState(null);

  // default wait units is seconds
  const [state, setState] = React.useState({
    open: false,
    sensor: undefined,
    name: "Dry Contact Sensor Trigger",
    description: null,
    channel: 0,
    triggerOnValue: false,
    waitUnitsTxt: 'Hours',
    waitUnits: 60 * 60, //  hours
    waitFor: 3, // 3 hours
    dailyWaitFor: false,
    lastTriggered: 0,
    status: 'active',
  });

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [insertTriggerResult,     insertTriggerFunction]     = useMutation(addSensorTrigger);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    setState({...state, open: false})
    if(props.onClose){
      props.onClose();
    }
  }

  const handleSensorChange = (event,item) => {
    console.log('setting sensor')
    console.log(item)
    setState({...state, sensor_id: item.id, sensor: item})
  }

  const handleChannelChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleNotificationChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleDailyWaitForChange = (event) => {
    setState({ ...state, waitFor: 0, waitUnits: 0, [event.target.name]: event.target.checked });
  }

  let sensor_id = null;
  if(props.sensor){
    sensor_id = props.sensor.id
  }


  const handleConfirm = () => {

    let variables = { 
      name: state.name,
      input_sensor_id: props.sensor.id,
      output_sensor_id: state.sensor_id,
      description: state.description,
      channel: state.channel,
      notification: state.notification,
      client_id: props.sensor.client.id,
      lastTriggered: state.lastTriggered,
      triggerOnValue: state.triggerOnValue,
      dailyWaitFor: state.dailyWaitFor,
      status: "active",
    }

    variables.waitFor = state.waitFor * state.waitUnits;

    /*
    if(state.channel0){
      variables.channel = 0
    }

    if(state.channel1){
      variables.channel = 1
    }

    if(state.channel2){
      variables.channel = 2
    }

    if(state.channel3){
      variables.channel = 3
    }
    */

    console.log(variables)

    insertTriggerFunction({"object": variables}).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        console.log('ZAssignContactSensorToTriggerButton::updateSensorFunction')
        console.log(result);

        insertNotificationFunction({object: {
          name: `Sensor ${state.sensor.alias} assigned to Trigger ${props.sensor.alias}`,
          description: `${userData.alias} updated Trigger ${props.sensor.name} added sensor ${state.sensor.name} to Channel ${state.channel}`,
          role: 'admin',
        }});
                
        setAlertState({
          open: true,
          message: 'Successfully added Sensor to Trigger',
          severity: 'info',
        })
      }

    })

    //handleClose();
  }

  //const error = [state.channel0, state.channel1, state.channel2, state.channel3].filter((v) => v).length !== 1;
  const error = Number.isSafeInteger(state.waitFor);

  ////////////////

  return (
    <div>
      <strong>
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">Contact Trigger</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
                <div id="message-id">
                  <FormControl required component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <TextField
                        id="name-control"
                        style={{ width: 500 }}
                        fullWidth
                        label="Name"
                        onChange={(event) =>{
                          setState({...state, name: event.target.value});
                        }} 
                      />   
                      <br/>
                      <TextField
                        id="description-control"
                        label="Description"
                        style={{ width: 500 }}
                        fullWidth
                        onChange={(event) =>{
                          setState({...state, description: event.target.value});
                        }} 
                      />   
                      <br/>          
                      <FormControl className={classes.formControl}>
                        <InputLabel id="channel-select-label">Sensor Channel</InputLabel>
                        <Select
                          labelId="channel-select-label"
                          id="channel-select"
                          value={state.channel}
                          onChange={(event)=>setState({...state, channel: event.target.value})}
                        >
                          <MenuItem value={0}>One</MenuItem>
                          <MenuItem value={1}>Two</MenuItem>
                          <MenuItem value={2}>Three</MenuItem>
                          <MenuItem value={3}>Four</MenuItem>
                        </Select> 
                      </FormControl>   
                      <br/>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="contact-select-label">Trigger On</InputLabel>
                        <Select
                          labelId="contact-select-label"
                          id="contact-select"
                          value={state.triggerOnValue}
                          onChange={(event)=>setState({...state, triggerOnValue: event.target.value})}
                        >
                          <MenuItem value={false}>Close</MenuItem>
                          <MenuItem value={true}>Open</MenuItem>
                        </Select> 
                      </FormControl>                     
                      <br/>
                      <ZFormControlLabel
                          control={
                            <Switch
                              checked={state.dailyWaitFor}
                              onChange={handleDailyWaitForChange}
                              name="dailyWaitFor"
                              color="secondary"
                            />
                          }
                          label="Daily Trigger"
                        />                        
                      <br/>
                      {
                        state.dailyWaitFor === false 
                        ?
                        <Paper component="form" className={classes.timeUnits}>
                          <TextField
                            id="name-control"
                            style={{ width: 500 }}
                            error={Number.isSafeInteger(state.waitFor)}
                            fullWidth
                            defaultValue={state.waitFor}
                            label="Time to ignore trigger after collection"
                            onChange={(event) =>{
                              setState({...state, waitFor: event.target.value});
                            }} 
                          />                         
                          <Button aria-controls="time-units-menu" aria-haspopup="true" 
                            onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                          }}>
                            {state.waitUnitsTxt}
                          </Button>
                          <Menu
                            id="time-units-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={()=>{
                              setAnchorEl(null)
                            }}
                          >
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60, waitUnitsTxt: 'Minutes'}));setAnchorEl(null)}}>Minutes</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60, waitUnitsTxt: 'Hours'}));setAnchorEl(null)}}>Hours</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24, waitUnitsTxt: 'Days'}));setAnchorEl(null)}}>Days</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24 * 7, waitUnitsTxt: 'Weeks'}));setAnchorEl(null)}}>Weeks</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24 * 30, waitUnitsTxt: 'Months'}));setAnchorEl(null)}}>Months</MenuItem>
                          </Menu>                        
                        </Paper>
                        :
                        <div></div>
                      }
                      <br/>
                      <ZFormControlLabel
                        control={
                          <Switch
                            checked={state.notification}
                            onChange={handleNotificationChange}
                            name="notification"
                            color="primary"
                          />
                        }
                        label="Send notification"
                      />                                   
                    </FormGroup>
                  </FormControl>

                  <br/><br/>
                  <ZSensorAutocomplete open={state.open} default={state.sensor} sensors={props.sensors} onChange={handleSensorChange}/>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={handleConfirm} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Box>
          <Collapse in={alertState.open}>
            <Alert severity={alertState.severity}>
              <AlertTitle>Alert</AlertTitle>
              <strong>{alertState.message}</strong>
            </Alert>  
          </Collapse>
        </Box>
      </Container>
      </strong>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true});
          if(props.togglePause){
            props.togglePause();
          }         
        }}       
        disabled={props.disabled}              
      >
        Assign Sensor To Contact Trigger
      </Button>
    </div>
  )
}

export default ZAssignContactSensorToTriggerButton;


/*

                      <FormControl required error={error} component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Sensor Channel</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={state.channel0} onChange={handleChannelChange} name="channel0" color="primary"/>}
                            label={<Typography variant="caption">Channel-1</Typography>}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={state.channel1} onChange={handleChannelChange} name="channel1" color="primary"/>}
                            label={<Typography variant="caption">Channel-2</Typography>}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={state.channel2} onChange={handleChannelChange} name="channel2" color="primary"/>}
                            label={<Typography variant="caption">Channel-3</Typography>}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={state.channel3} onChange={handleChannelChange} name="channel3" color="primary"/>}
                            label={<Typography variant="caption">Channel-4</Typography>}
                            labelPlacement="end"
                          />                      
                        </FormGroup>
                        <FormHelperText>You can select only one</FormHelperText>
                      </FormControl>

*/