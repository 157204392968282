import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, yellow, orange, grey } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getSurveyStatsForClientQuery } from 'queries_urql';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, PieChart, Pie, Sector, Cell } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import eventBus from 'utils/EventBus';

//////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

//////////////////////////
/*
props {
  data: []
  title: string
}
*/
//////////////////////////

const ZAssetSurveySeverityPieChart = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  let { id } = useParams();

  const COLORS = [green[200], green[100], yellow[100], orange[100], red[100], grey[200], grey[100]];

  let rows = [];
  let shouldPause = true;

  if(props.data === undefined){
    shouldPause = false;
  }

  /////////////

  console.log('setting up query')

  const [ result, reexecuteQuery ] = useQuery({
    query: getSurveyStatsForClientQuery, 
    variables: { 
      client_id: id, 
    },
    pause: shouldPause,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if(shouldPause === false){
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;

    rows.push({
      "Good": data.good_assets.aggregate.count,
    })

    rows.push({
      "Acceptable": data.acceptable_assets.aggregate.count,
    })
    
    rows.push({
      "Reportable": data.reportable_assets.aggregate.count,
    })
    
    rows.push({
      "Unsatisfactory": data.unsatisfactory_assets.aggregate.count,
    })
    
    rows.push({
      "Unacceptable": data.unacceptable_assets.aggregate.count,
    })  
    
    rows.push({
      "No Status": data.nostatus_assets.aggregate.count,
    })  

    rows.push({
      "No Access": data.noaccess_assets.aggregate.count,
    })  

  } else {

    rows.push({
      "Good": props.data.good_assets.aggregate.count,
    })

    rows.push({
      "Acceptable": props.data.acceptable_assets.aggregate.count,
    })
    
    rows.push({
      "Reportable": props.data.reportable_assets.aggregate.count,
    })
    
    rows.push({
      "Unsatisfactory": props.data.unsatisfactory_assets.aggregate.count,
    })
    
    rows.push({
      "Unacceptable": props.data.unacceptable_assets.aggregate.count,
    })  
    
    rows.push({
      "No Status": props.data.nostatus_assets.aggregate.count,
    })  

    rows.push({
      "No Access": props.data.noaccess_assets.aggregate.count,
    })
  }

  ////////////////////////

  return (
    <Card>
      <ZTitle>Asset Survey Results</ZTitle>
      <Paper>
        <PieChart width={800} height={400}>
          <Pie
            data={rows}
            cx={120}
            cy={200}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {
              rows.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))
            }
          </Pie>
        </PieChart>
      </Paper>
    </Card>
  );
}


export default ZAssetSurveySeverityPieChart;

