import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllUsers, getUser } from '../../queries';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllUsers, getUser } from 'queries_urql';
import { subscribeAllUsers } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZUserAutocomplete = (props) => {

  const [state, setState] = React.useState({
    user: null,
  })
  /*
  const { loading, error, data }  = useQuery(getAllUsers);
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log("users")
  console.log(data)
  */
  let shouldPause = false;
  if(props.open){
    shouldPause = !props.open;
  }

  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)

    return response
  };


  //const { fetching, error, data }  = useQuery(getAllUsers);

  const [ queryResult, reexecuteQuery ] = useQuery({
    query: getAllUsers,
  });    
  
  let { data, fetching, error } = queryResult;

  /*
  const [result, executeSubscription] = useSubscription({ 
    query: subscribeAllUsers,
    //pause: false,
    pause: shouldPause,
  }, handleSubscription); 
  */
  ////////////////////////


  //const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  //if (error) return <div></div>;

  console.log('data');
  console.log(data)

  return (
    <Autocomplete
      id="choose-user-combo-box"
      onChange={(event,item)=>{
        console.log("user autocomplete change")
        console.log(item)
        setState({...state, user: item})
        if(props.onChange){
          props.onChange(event,item)
        } 
      }}
      options={data.zeus_zeus_user}
      getOptionLabel={(option) => option.alias}
      getOptionSelected={(option, value) => option.alias === value.alias }
      value={props.default ? props.default : null}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={props.label ? props.label : "Choose User"} variant="outlined" />}
    />
  )
}


export default ZUserAutocomplete;
