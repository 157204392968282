import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
import { useMutation } from '@apollo/client';
import { addSupplier } from '../../mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getAllClients } from 'queries_urql';
import { addSupplier, updateSupplier } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';



/////////////////////

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

/////////////////////

const ZAddSupplierDialog = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    name: '',
    description: '',
  });

  const [insertFunction] = useMutation(addSupplier);

  const handleClose = () => {
    props.handleClose()
  };

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    insertFunction({variables: state})
  }

  return (
    <div>
      <Container maxWidth="lg">
        <Box>
          <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Supplier</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter any Supplier Details
              </DialogContentText>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.nameField}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  onChange={(event) =>{
                    var newState = state
                    newState.name = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                />

                <TextField
                  className={classes.descriptionField}
                  id="description"
                  label="Description"
                  multiline
                  rows={8}
                  onChange={(event) =>{
                    var newState = state
                    newState.description = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </div>
  );
}

export default ZAddSupplierDialog;

/*
sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}
*/