import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


import { useLocalStorage } from 'hooks/useStorage';
import usePersistentState from 'hooks/usePersistentState';
import { uploadFileToAzure } from 'azure/AzureBlobAPI';

/*
import { useMutation } from '@apollo/client';
import { getAllClients } from '../../queries';
import { addClient, updateClient } from '../../mutations';
*/

import { useQuery, useMutation } from 'urql';
import { getAllAssetsForClient } from 'queries_urql';
import { addAsset, updateAsset } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

import ZComponentAutocomplete from 'components/core/ZComponentAutocomplete'; 
import ZClientAutocomplete from 'components/core/ZClientAutocomplete';//ZClientAutocomplete
import ZRouteAutocomplete from 'components/core/ZRouteAutocomplete'; //ZClientNodeTypeAutocomplete
import ZClientNodeAutocomplete from 'components/core/ZClientNodeAutocomplete';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';

import  ZWizard  from 'components/core/ZWizard';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

const ZAddAssetDialog = (props) => {
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');

  const [address, setAddress] = React.useState({})
  const [region, setRegion] = React.useState({})
  const [geometry, setGeometry] = React.useState({})
  
  const [alertState, setAlertState] = React.useState({
    show: false,
    message: '',
    severity: 'info',
  })

  const [state, setState] = usePersistentState({
    guuid: uuidv4(),
    name: null,
    description: null,
    survey_frequency: 30,
    status: 'active',
    component_type: null,
    component_type_id: null,
    parent: null,
    parent_id: null,
    user: null,
    user_id: null,
    //client: props.client ? props.client : null,
    client: null,
    client_id: null,
  }, 'create-new-asset-wizard')

  let tempName = '';

  const handleClose = () => {
    if(props.onClose){
      props.onClose();
    }
  }

  const handleReset = () => {
    setState({
      guuid: uuidv4(),
      name: null,
      description: null,
      survey_frequency: 30,
      status: 'active',
      parent: null,
      parent_id: null,
      user: null,
      user_id: null,
      client: null,
      client_id: null,
      component_type: null,
      component_type_id: null,
    })
    handleClose();
  }

  ////////////

  const [insertResult, insertFunction] = useMutation(addAsset);

  ////////////
  /*
  const [ result, reexecuteQuery ] = useQuery({
    query: getAllClients,
    pause: !props.open,
  });

  console.log('result');
  console.log(result)

  let { data, fetching, error } = result;
  */
  ///////////////

  const handleReview = () => {
    console.log("ZAddAssetDialog->handleReview")
    const createdOn = moment().format()
    var client = state.client;
    if(props.client){
      client = props.client
    }

    console.log('client')
    console.log(client)

    setState({
      ...state,
      parent_id: state.parent_id,
      client: client,
      client_id: client.id,
      component_type_id: state.component_type_id,
      createdOn: createdOn,
    })
    console.log("handleReview->"+ JSON.stringify(state));
  }

  const handleFrequencyChange = (event,item) => {
    console.log("handleFrequencyChange")
    console.log(item.props.value)
    //let address = item.
    setState({...state, survey_frequency: item.props.value})
  }

  const handleCriticalityChange = (event,item) => {
    console.log("handleCriticalityChange")
    console.log(item.props.value)
    //let address = item.
    setState({...state, criticality: item.props.value})
  }

  const handleComponentTypeChange = (event,item) => {
    console.log("handleComponentTypeChange")
    console.log(item)
    //let address = item.
    setState({...state, component_type: item, component_type_id: item.id})    
  }

  const handleClientChange = (event,item) => {
    console.log("handleClientChange")
    console.log(item)
    //let address = item.
    setState({...state, client: item, client_id: item.id})    
  }

  const handleParentChange = (event,item) => {
    console.log("handleParentChange")
    console.log(item)
    //let address = item.
    setState({...state, parent: item, parent_id: item.id})    
  }

  const handleRouteChange = (event,item) => {
    console.log("handleRouteChange")
    console.log(item)
    //let address = item.
    setState({...state, route: item, route_id: item.id})    
  }

  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      setRegion({province: province, country: country})
      setGeometry({ lat, lng })
      setAddress(place) 
    });    
  }

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));

    const { guuid, client, parent, user, component_type, user_id, component_type_id, duplicateName, ...object } = state;
    object.id = state.guuid;

    console.log('object');
    console.log(object);

    let activities = [];

    activities.push({
      user_id: userData.id,
      parent_id: state.client.id,
      name: `asset ${state.name} created`,
      description: `${userData.alias} created asset ${state.name} in ${state.client.name}`,           
    })

    if(state.parent_id !== null){
      activities.push({
        user_id: userData.id,
        parent_id: state.parent.id,
        name: `asset ${state.name} created`,
        description: `${userData.alias} created asset ${state.name} in ${state.parent.name}`,           
      })      
    }

    activities.push({
      user_id: userData.id,
      parent_id: state.guuid,
      name: `asset created`,
      description: `${userData.alias} created asset`,           
    })

    // finally insert all records
    insertFunction({
      object: object,
      activities: activities,
    }).then((result)=>{
      if(result.error){
        console.log('ERROR creating asset')
        console.log(result)
      } else {
        console.log('successfully created new asset')
        setAlertState({
          open: true,
          message: `Successfully created asset ${state.name}`
        })
      }
    });

    handleClose()
  }

  //////////////////////////

  return(
    <div>
     <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alert.show}
        message={alert.message}
        key="alert_button"
        //onClose={()=>{
        //  setAlert({...alert, show: false, message: '', error: false})
        //}}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color={alert.error === true ? "secondary" : "primary"}
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alert, show: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />       
      <ZWizard 
        title="Create New Asset"
        steps={[
          'Enter Parent Details', 
          'Enter Asset Details', 
          'Enter Survey Details'
        ]}
        open={props.open} 
        onClose={props.onClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
        onReset={handleReset}
        disabled={
          state.client_id === null ? true 
          : state.name === null ? true
          : state.criticality === null ? true 
          : state.survey_frequency === null ? true 
          : false}
      >
        { /*  client details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                props.client 
                ? <ZClientAutocomplete client={props.client} default={props.client ? props.client : state.client} onChange={handleClientChange} />
                : state.client !== null
                ? <ZClientAutocomplete default={state.client} onChange={handleClientChange} />
                : <ZClientAutocomplete open={props.open} onChange={handleClientChange} />
              }
            </Paper>   
            <br/>            
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                props.client
                ? <ZClientNodeAutocomplete client={props.client} default={state.parent} items={props.client.client_nodes} open={props.open} onChange={handleParentChange} />
                : state.parent !== null
                ? <ZClientNodeAutocomplete client={state.client} default={state.parent} items={state.client.client_nodes} open={props.open} onChange={handleParentChange} />
                : state.client !== null
                ? <ZClientNodeAutocomplete open={props.open} client={state.client} items={state.client.client_nodes} onChange={handleParentChange} />
                : state.client !== null && state.parent !== null
                ? <ZClientNodeAutocomplete open={props.open} client={state.client} default={state.parent} items={state.client.client_nodes} onChange={handleParentChange} />
                : <div></div>
              }
            </Paper> 
            <br/> 
            {/*
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                state.route !== null
                ? <ZRouteAutocomplete open={props.open} client={state.client} default={state.route}  onChange={handleRouteChange} />
                : state.client !== null
                ? <ZRouteAutocomplete open={props.open} client={state.client} items={state.client.routes} onChange={handleRouteChange} />
                : <div></div>
              }
            </Paper>   
            */}                   
          </Box>
        </Container>
        {/* asset details */}
        <Container maxWidth="sm">
          <Box>
            {/* 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZComponentAutocomplete isOpen={props.open} onChange={handleComponentTypeChange} />
            </Paper>
            <br/>
             */}
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  let newName = event.target.value;
                  let isDuplicate = false;
                  props.client.assets.forEach((asset)=>{
                    if(asset.name === newName){
                      isDuplicate = true
                    }
                  })
                  setState({...state, name: newName, duplicateName: isDuplicate});
                }}
                id="assetName" 
                label="Asset Tag" 
                variant="standard"
                error={state.duplicateName}
                helperText={state.duplicateName && 'duplicate tag'}
                defaultValue={tempName}
              />
            </Paper> 
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="assetDescription"
                label="Description"
                multiline
                rows={4}
                onChange={(event) =>{
                  setState({...state, description: event.target.value});
                }} 
              />  
            </Paper>  
          </Box>
        </Container>
        {/* survey details */}
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <FormControl className={classes.formControl}>
              <InputLabel id="criticality-select-label">Asset Criticality</InputLabel>
                <Select
                  labelId="criticality-select-label"
                  id="criticality-simple-select"
                  value={state.criticality}
                  onChange={handleCriticalityChange}
                >
                  <MenuItem value={1}>Insignificant</MenuItem>
                  <MenuItem value={2}>Minor</MenuItem>
                  <MenuItem value={3}>Moderate</MenuItem>
                  <MenuItem value={4}>Major</MenuItem> 
                  <MenuItem value={5}>Extreme</MenuItem> 
                </Select>
              </FormControl>
            </Paper>  
            <br/>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <FormControl className={classes.formControl}>
              <InputLabel id="freq-select-label">Survey Frequency</InputLabel>
                <Select
                  labelId="freq-select-label"
                  id="freq-simple-select"
                  value={state.survey_frequency}
                  onChange={handleFrequencyChange}
                >
                  <MenuItem value={10*60}>10 Minutes</MenuItem>
                  <MenuItem value={30*60}>30 Minutes</MenuItem>
                  <MenuItem value={3600}>1 Hour</MenuItem>
                  <MenuItem value={3*3600}>3 Hours</MenuItem>
                  <MenuItem value={12*3600}>12 Hours</MenuItem>
                  <MenuItem value={24*3600} >Daily</MenuItem>
                  <MenuItem value={7*24*3600} >Weekly</MenuItem>
                  <MenuItem value={14*24*3600}>Bi Weekly</MenuItem> 
                  <MenuItem value={60*24*3600}>Bi Monthly</MenuItem> 
                  <MenuItem value={90*24*3600}>Quarterly</MenuItem> 
                </Select>
              </FormControl>
            </Paper>  
          </Box>
        </Container>        
        {/* review details */}
        <Container maxWidth="sm">
        <Box>
            <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow key='1' selected={state.name === null ? true : false}>
                      <TableCell align="right">Asset Tag</TableCell><TableCell align="left">{state.name}</TableCell>
                    </TableRow>

                    <TableRow key='2' selected={state.criticality === null ? true : false}>
                      <TableCell align="right">Criticality</TableCell><TableCell align="left">{state.criticality}</TableCell>
                    </TableRow>

                    <TableRow key='3'> 
                      <TableCell align="right">Description</TableCell><TableCell align="left">{state.description}</TableCell>
                    </TableRow>

                    <TableRow key='4' selected={state.survey_frequency === null ? true : false}>
                      <TableCell align="right">Survey Frequency</TableCell><TableCell align="left">{`${state.survey_frequency} seconds`}</TableCell>
                    </TableRow>

                    <TableRow key='5' selected={state.parent_id === null ? true : false}>
                      <TableCell align="right">Client Node</TableCell><TableCell align="left">{state.parent ? `${state.parent.name}` : 'No Parent'}</TableCell>
                    </TableRow>

                    <TableRow key='6' selected={state.client_id === null ? true : false}>
                      <TableCell align="right">Client</TableCell><TableCell align="left">{state.client ? state.client.name : props.client ? props.client.name : 'Client Required'}</TableCell>
                    </TableRow>

                    <TableRow key='7'>
                      <TableCell align="right">User</TableCell><TableCell align="left">{userData.alias}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddAssetDialog;

