import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
import { useQuery, useMutation, useSubscription } from 'urql';
import { getUser } from 'queries_urql'; ///
import { setToolNodeOutOfService, 
        setToolNodeOutForCalibration, addImage, checkInTool, checkInToolNode,
        checkOutToolNode, checkOutTool, unpinClientToUser,
        setToolNodeRetired, setToolNodeReady, updateUsage, updateUser } from 'mutations_urql';


import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, green } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import Input from '@material-ui/core/Input';

import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import ClearIcon from '@material-ui/icons/Clear';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab'; //Fab

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import PlaceIcon from '@material-ui/icons/Place';
import CloseIcon from '@material-ui/icons/Close';
import LoopIcon from '@material-ui/icons/Loop';//LoopIcon

import QRCode from "react-qr-code";
import GoogleMapReact from 'google-map-react';
import Marker from 'google-map-react';
import {isMobile} from 'react-device-detect';
import FileBase64 from 'react-file-base64';

import ZMapComponent from 'components/core/ZMapComponent';
import ZLocatorMapComponent from 'components/core/ZLocatorMapComponent';
import ZCheckInButton from 'components/buttons/ZCheckInButton';
import ZCheckOutButton from 'components/buttons/ZCheckOutButton';
import ZPinClientToUserButton from 'components/buttons/ZPinClientToUserButton';

import ZSetOutofServiceButton from 'components/buttons/ZSetOutofServiceButton';
import ZSetReadyButton from 'components/buttons/ZSetReadyButton';
import ZSetRetiredButton from 'components/buttons/ZSetRetiredButton';
import ZBackCameraCapture from 'components/core/ZBackCameraCapture';
import ZJobNumberAutoComplete from 'components/core/ZJobNumberAutoComplete';
import ZFrontCameraCapture from 'components/core/ZFrontCameraCapture';
import { ZNFCScanner } from 'components/core/ZNFCScanner';
import ZUserAvatar from 'components/core/ZUserAvatar';

/////////////////////////////////////////

const useStyles = makeStyles((theme)=>({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: green[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button_new: {
    margin: theme.spacing(5),
  }, 
  button: {
    margin: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  notes: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    height: '200px',
  },
  image_root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },  
}));


/////////////////////////////////////

const ZUserBox = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const fileInput = React.useRef();
  const base64file = React.useRef();

  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [job, setJob] = React.useState(null);
  const [openBookNodeDialog, setOpenBookNodeDialog] = React.useState(false);

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: ''
  });

  const [usageUpdated, setUsageUpdated] = React.useState(false);

  const [imageData, setImageData] = React.useState({
    file: null,
    base64URL: ""
  });

  //const snackbarEl = React.useRef(null);

  console.log(`ZUserBox props.usage_id -> ${props.usage_id}`)

  //49.2592432 , -123.0142475
  //53.268443   , -113.552206
  //const [lat, setLat] = React.useState(null);
  //const [lng, setLng] = React.useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMenuClick = (event) => {
    console.log('ZUserBox->handleMenuClick')
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  }

  /////////

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  /////////

  let user = {}
  let usage_description = null;

  let jobs = (props.jobs) ? props.jobs : []; 

  let usage_id = null;

  const [updateResult, updateUsageFunction] = useMutation(updateUsage);
  const [insertResult, insertImageFunction] = useMutation(addImage);
  const [checkInToolResult, checkInToolFunction] = useMutation(checkInTool);
  const [checkInToolNodeResult, checkInToolNodeFunction] = useMutation(checkInToolNode);
  const [checkOutToolResult, checkOutToolFunction] = useMutation(checkOutTool);
  const [checkOutToolNodeResult, checkOutToolNodeFunction] = useMutation(checkOutToolNode);

  let shouldPause = false;
  if(props.static){
    shouldPause = true;
  }
  const id = props.user.id;

  const [result, reexecuteQuery] = useQuery({
    query: getUser,
    variables: { id },
    pause: shouldPause,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  //let snackbar = (<div></div>)
  let snackbarEl = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to close snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              setAlertState({
                open: false,
                message: ""
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    />     
  )

  const [unpinClientResult,     unpinClientFunction]      = useMutation(unpinClientToUser);

  const handleFileInputChange = React.useCallback((e)=>{
    console.log('handleFileInputChange')
    console.log(e.target.files[0]);

    let  file  = imageData.file;

    file = e.target.files[0];

    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        setImageData({
          base64URL: result,
          file
        });
      })
      .catch(err => {
        console.log(err);
      });
  },[]);


  const captureImage = React.useCallback((imgSrc)=>{
    console.log('captureImage')
    const guuid = uuidv4()
    const createdOn = moment().format()

    let variables = {
      id: guuid,
      user_id: userData.id,
      createdOn: createdOn,
      object: {
        id: guuid,
        img: imgSrc,
        user_id: userData.id,
        parent_id: id,
        createdOn: createdOn,
      }
    }

    console.log('captureImage->variables')
    console.log(variables)

    insertImageFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: insertImageFunction: ${result.error}`)
        setAlertState({
          open: true,
          message: `Error uploading image ${result.error}`
        });
       
      } else {
        console.log(result)
        setAlertState({
          open: true,
          message: `successfully uploaded image`
        });
      }
    })
  },[]);

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static === undefined){
    user = data.zeus_zeus_user_by_pk
  } else {
    user = props.user
  }

  /////////////////////////////////

  let smallChips = (<div></div>)
  let nodeNotes = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)

  let action = (<div></div>)
  let addSetOutofServiceButton = (<div></div>)
  let addSetReadyButton = (<div></div>)
  let addSetRetiredButton = (<div></div>)
  let addBookToolNodeButton = (<div></div>)

  const ZToolLocationComponent = ({ text }) => <div>{text}</div>;

  /////////////////////////////////

  if(props.action){

  }

  if(props.actions || props.showActions){
    addSetOutofServiceButton = (
      <div>
        <ZSetOutofServiceButton id={id} lat={props.lat} lng={props.lng} toolnode/>      
      </div>
    )
  }

  if(props.actions || props.showActions){
    addSetReadyButton = (
      <div>
        <ZSetReadyButton id={id} lat={props.lat} lng={props.lng} toolnode/>      
      </div>
    )
  }

  if(props.actions || props.showActions){
    addSetRetiredButton = (
      <div>
        <ZSetRetiredButton id={id} lat={props.lat} lng={props.lng} toolnode/>   
      </div>
    )
  }

  if(props.actions || props.showActions){
    addBookToolNodeButton = (
      <div>
        <Button size="large" color="primary"
          id={props.id}
          style={{ marginLeft: 8 }}
          onClick={(event) => { 
            setOpenBookNodeDialog(true)
          }}                     
        >
          {`Reserve ${user.name}`}
        </Button>          
      </div>
    )
  }

  ///////////////////////////////////

  if(props.fullBox){
    /*
    smallChips = (
      <div>
        <br/><Divider /><br/>
        <Chip label={'Total Uses:'+ user.usage_count.aggregate.count} />
        <br/><Divider /><br/>
      </div>
    )
    

    nodeNotes = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="user_box_tooltip" 
            aria-label="add note">
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="user_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Notes</Typography>
            {user.annotations.map((note) => {
              return (
                <div key={note.id}>
                  <Card>
                    <CardHeader>{note.name}</CardHeader>
                    <CardContent>{note.description}</CardContent>
                  </Card>
                </div>             
              );
            })}
          </CardContent>
        </Collapse>
      </div>
    )
    */
  }

  let title = (<div></div>)
  title = (
    <div>
      <Typography variant='h3'>{`${user.firstName} ${user.lastName}`}</Typography>
    </div>      
  )

  /*
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
  */

  // ready is default
  /*
  let urlValue = `${baseURL}/toolnode/${node.id}/checkout`;
  if(node.status === 'in-use'){
    urlValue = `${baseURL}/toolnode/${node.id}/checkin/${node.usage[0].id}`;
  }

  if(node.status === 'out-of-service'){
    urlValue = `${baseURL}/toolnode/${node.id}/ready`;
  }

  if(node.status === 'out-for-calibration'){
    urlValue = `${baseURL}/toolnode/${node.id}/ready`;
  }
  

  console.log(`QRcode url: ${urlValue}`)
  console.log(`props.usage_id: ${props.usage_id}`)
  if(node.usage){
    if(node.usage.length > 0){
      console.log(`node.usage[0].id: ${node.usage[0].id}`)
      usage_description = node.usage[0].description
    }
  }
  

  //////////////

  if(props.qrcode || props.showQRcode){
    qrcode = (
      <div>
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
          <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={urlValue}
          viewBox={`0 0 512 512`}
          />
        </div>
        <br/><Divider /><br/>
      </div>
    )
  }
  */

  if(props.showmap || props.showMap){
    let xlat = props.lat;
    let xlng = props.lng;

    gmap = (
      <div>
        <ZMapComponent name="tool_node_map" lat={xlat} lng={xlng} zoom={12}/>
        <br/><Divider /><br/>
      </div>
    )
  }

  /////////////////////////  status

  let current_user = (<div></div>)
  let status_colour = "primary"
  let node_status = user.status.toUpperCase()
  

  current_user = (
    <Typography>User Alias: {user.alias}</Typography>
  )

  const handleDescriptionChange = (value) => {
    setDescription(value)
  }

  const handleJobChange = (value) => {
    setJob(value)
  }

  /*
  const handleSubmitUsageInfo = () => {
    //setDescription(value)
    let job_id = null;
    if(job !== null){
      job_id = job.id;
    }

    let variables = {
      id: props.usage_id,
      _set: {
        description: description,
        job_id: job_id,
      }
    }

    console.log('subitting usage info')
    console.log(variables)
    //return

    updateUsageFunction(variables).then((result)=>{
      if(result.error){
        console.log('ERROR updating tool usage')
        setAlertState({
          open: true,
          message: `Error updateing ${node.node_type} usage ${result.error}`
        });
      }else {
        console.log(`successfully updated ${node.node_type} usage`)
        //setAlertMessage(`successfully updated ${node.node_type} usage`)
        console.log(result)
        setAlertState({
          open: true,
          message: `successfully updated ${node.node_type} usage`
        });

      }
    })
  }
  */

  //let checkin_qrcode_button = (<div></div>)
  let nfc_button = (<div></div>)
  let nfc_checkout_button = (<div></div>)
  let nfc_checkin_button = (<div></div>)
  let usage_info = (<div></div>)

  const handleNFCScanCheckIn = (scannerData) => {
    // we are handling the scan of an item that
    console.log('ZUserBox::handleNFCScanCheckIn');
    console.log(scannerData)
    let serialNumber = scannerData.serialNumber;
    let item_id = scannerData.id;
    let tag_type = scannerData.type === 0 ? 'toolnode' : 'tool' ;
    let tag_name = scannerData.name;

    let the_description = `NFC tag ${serialNumber} was used to checkin ${tag_name}`;

    // so lets checkin the item
    //($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "")
    if(tag_type === 'toolnode'){
      const guuid = uuidv4()
      const createdOn = moment().format()

      let variables = {
        id: guuid,
        user_id: userData.id,
        createdOn: createdOn,
        description: the_description,
        node_id: item_id,
        lat: props.lat,
        lng: props.lng,
      }

      console.log('ZUserBox::handleNFCScanCheckIn -> variables')
      console.log(variables)

      checkInToolNodeFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR checkInToolNodeFunction ${result.error}`)
          setAlertState({
            open: true,
            message: `Error checking in ${tag_name} using NFC tag ${serialNumber}`,
          })
          return;
        }

        let urlValue = `/toolnode/:id`;
        let path = generatePath(urlValue, {
          id: guuid}
          )
        history.push(path)
      })
    }

    if(tag_type === 'tool'){
      const guuid = uuidv4()
      const createdOn = moment().format()

      let variables = {
        id: guuid,
        user_id: userData.id,
        createdOn: createdOn,
        description: the_description,
        tool_id: item_id,
        lat: props.lat,
        lng: props.lng,
      }

      console.log('ZUserBox::handleNFCScanCheckIn -> variables')
      console.log(variables)

      checkInToolFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR checkInToolFunction ${result.error}`)
          setAlertState({
            open: true,
            message: `Error checking in ${tag_name} using NFC tag ${serialNumber}`,
          })
          return;
        }

        let urlValue = `/tool/:id`;
        let path = generatePath(urlValue, {
          id: guuid}
          )
        history.push(path)
      })
    }

    // let urlValue = `${baseURL}/${tag_type}/${guuid}/checkin/${node.usage[0].id}`;
    // let urlValue = `${baseURL}/nfc/${tag_type}/${guuid}/checkin`;
    // let urlValue = `/nfc/${tag_type}/${guuid}/checkin`;
  }


  const handleNFCScanCheckOut_1 = (scannerData) => {
    // we are handling the scan of an item that
    console.log('ZUserBox::handleNFCScanCheckOut');
    console.log(scannerData)
    let serialNumber = scannerData.serialNumber;
    let item_id = scannerData.id;
    let tag_type = scannerData.type === 0 ? 'toolnode' : 'tool' ;
    let tag_name = scannerData.name;

    let the_description = `NFC tag ${serialNumber} was used to checkout ${tag_name}`;

    // so lets checkin the item
    //($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "")
    if(tag_type === 'toolnode'){
      const guuid = uuidv4()
      const createdOn = moment().format()

      let variables = {
        id: guuid,
        user_id: userData.id,
        createdOn: createdOn,
        description: the_description,
        node_id: item_id,
        lat: props.lat,
        lng: props.lng,
      }

      console.log('ZUserBox::handleNFCScanCheckIn -> variables')
      console.log(variables)

      checkOutToolFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR checkOutToolFunction ${result.error}`)
          setAlertState({
            open: true,
            message: `Error checking out ${tag_name} using NFC tag ${serialNumber}`,
          })
          return;
        }

        let urlValue = `/toolnode/:id`;
        let path = generatePath(urlValue, {
          id: guuid}
          )
        history.push(path)
      })
    }

    if(tag_type === 'tool'){
      const guuid = uuidv4()
      const createdOn = moment().format()

      let variables = {
        id: guuid,
        user_id: userData.id,
        createdOn: createdOn,
        description: the_description,
        tool_id: item_id,
        lat: props.lat,
        lng: props.lng,
      }

      console.log('ZUserBox::handleNFCScanCheckOut -> variables')
      console.log(variables)

      checkOutToolFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR checkOutToolFunction ${result.error}`)
          setAlertState({
            open: true,
            message: `Error checking out ${tag_name} using NFC tag ${serialNumber}`,
          })
          return;
        }

        let urlValue = `/tool/:id`;
        let path = generatePath(urlValue, {
          id: guuid}
          )
        history.push(path)
      })
    }

    // let urlValue = `${baseURL}/${tag_type}/${guuid}/checkin/${node.usage[0].id}`;
    // let urlValue = `${baseURL}/nfc/${tag_type}/${guuid}/checkin`;
    // let urlValue = `/nfc/${tag_type}/${guuid}/checkin`;
  }  


  const handleNFCScanCheckOut = (scannerData) => {
      
    console.log('ZUserBox::handleNFCScanCheckOut');
    console.log(scannerData)
    let serialNumber = scannerData.serialNumber;
    let item_id = scannerData.id;
    let tag_type = scannerData.type === 0 ? 'toolnode' : 'tool' ;
    let tag_name = scannerData.name;

    let the_description = `NFC tag ${serialNumber} was used to checkout ${tag_name}`;
    console.log(the_description)

    let urlValue = `/nfc/${tag_type}/:id/checkout`;
    let path = generatePath(urlValue, {
      id: item_id}
    )
    history.push(path)
  }


  const handleNFCScan = (scannerData) => {
      
    console.log('ZUserBox::handleNFCScan');
    console.log(scannerData)
    let serialNumber = scannerData.serialNumber;
    let item_id = scannerData.id;
    let tag_type = scannerData.type === 0 ? 'toolnode' : 'tool' ;
    let tag_name = scannerData.name;

    let the_description = `NFC tag ${serialNumber} was used to access ${tag_name}`;
    console.log(the_description)

    let urlValue = `/${tag_type}/:id`;
    let path = generatePath(urlValue, {
      id: item_id}
    )
    history.push(path)
  }


  const handleUnPinClient = (item) => {
    console.log('ZUserBox->handleUnPinClient')
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let item_id = item.id;

    let variables = {
      id: item_id,
      activity: {
        user_id: user_id,
        parent_id: user_id,
        name: `unpinned client`,
        description: `${userData.alias} unpinned client ${item.name}`,          
      },
    }

    console.log(variables);
    
    unpinClientFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR unpinning client: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log('unpinned client')
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully unpinned client', severity: 'success'})
      }
    })
  }


  if(isMobile){
    // mobile device has back facing camera as well as NFC detector
    // onScan of NFC tag navigate to ZToolNodeEventView ZToolEventView page to finish checkin
    if(props.user.my_checkedout_tools){
      if(props.user.my_checkedout_tools.length > 0){
        nfc_checkin_button = <ZNFCScanner title='Scan Check-In' onScan={handleNFCScanCheckIn} />
      }
    }

    if(props.user.my_checkedout_nodes){
      if(props.user.my_checkedout_nodes.length > 0){
        nfc_checkin_button = <ZNFCScanner title='Scan Check-In' onScan={handleNFCScanCheckIn} />
      }
    }

    nfc_checkout_button = <ZNFCScanner title='Scan Check-Out' onScan={handleNFCScanCheckOut} />
  } else {
    // laptop or desktop
    // so we don't allow tag based checkin of tools
    // checkin_qrcode_button = <ZCheckInButton key='node_checkin' id={node.id} usage_id={props.usage_id} data={node} lat={props.lat} lng={props.lng} description={description} toolnode/> 
  }

  nfc_button = <ZNFCScanner title='Scan Tag' onScan={handleNFCScan} />

  // debug code below remove for release
  nfc_checkout_button = <ZNFCScanner title='Scan Check-Out' onScan={handleNFCScanCheckOut} />
  nfc_checkin_button = <ZNFCScanner title='Scan Check-In' onScan={handleNFCScanCheckIn} />

  //////////////////// legacy code below ///////////////



  /////////////////////////////////////

  let node_images = (<div></div>)

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };


  let user_camera = (<div></div>);

  if(props.camera || props.withCamera){
    console.log('tool or toolnode status')
    if(isMobile){
      user_camera = (<div>
        <ZBackCameraCapture width={320} height={480} handleChange={captureImage} />
      </div>)
    } else {
      user_camera = (<div>
        <ZFrontCameraCapture width={320} height={480} handleChange={captureImage} />
      </div>)
    }
  } 
  
  if((props.images || props.withImages) && props.user.images){
    node_images = (
      <div className={classes.image_root}>
        {/* <Input type="file" inputRef={fileInput}/><br/> */ }
        <input type="file" name="file" onChange={handleFileInputChange} />
        <Button variant="outlined" color="primary"
          onClick={()=>{
            console.log('image upload button clicked')
            console.log('uploading selected image file')
            console.log(imageData);
            captureImage(imageData.base64URL);
          }}
        >
          Upload Image
        </Button>
        <ImageList className={classes.imageList} cols={3.0}>
          {props.user.images.map((item,index) => (
            <ImageListItem key={`node_image_${index}`}>
              <img src={item.img} alt={`node_image_${index}`} />
              <ImageListItemBar 
                title={item.createdOn}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <br/><Divider /><br/>
      </div>
    )
  }

  var pinned_clients = []
  if(props.user.pinned_clients){
    for(var item of props.user.pinned_clients){

      const client = item.client;
      console.log(`pinning client`)
      console.log(client)

      var clientName = ( 
          <ListItem key={client.id}
            onClick={(event) => { 
              let path = generatePath('/client/:id', {
                id: client.id}
              )
              history.push(path)
            }}            
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <AccountBoxIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={client.name}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete"
                onClick={(event) => { 
                  console.log('unpin client')
                  handleUnPinClient(item);
                }}             
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
      )  
      pinned_clients.push(clientName);
    }
  }

  return (
    <div className={classes.root}>
      { snackbarEl }
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="node" className={classes.avatar}>
              {user.alias}
            </Avatar>
          }
          action={
            <div>
              <Chip
                onClick={props.refresh ? props.refresh : null}
                style={{ marginLeft: 8 }}
                icon={<LoopIcon />}
                label={ node_status }
                color={status_colour}
                clickable
              />              
              <br/><br/>
              <div>
                <Paper>
                  <ZPinClientToUserButton open clients={props.clients} static/>
                  <Divider/>
                  <List dense={false}>
                    { pinned_clients.length > 0 ? pinned_clients : <div/> }
                  </List>
                </Paper>
              </div>
              <div>
                { props.action ? props.action : action }
              </div>
            </div>
          }
          
          title={title}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {user.createdOn}
                </Moment>
              </div>
              { current_user }
              <div><Typography>Details: {user.description}</Typography></div> 
            </div>
          }
        />
        <CardActions className={classes.button}>
          {/* nfc_button */}
          <br/>
          {/* nfc_checkin_button */}
          <br/>
          {/* nfc_checkout_button */}
          <br/>
          {user_camera}
        </CardActions>
        <CardContent className={classes.button}>
          {qrcode}
          <br/>
          {gmap}
          <br/>
          { smallChips } 
          { node_images }              
        </CardContent>
        { nodeNotes }
      </Card>
      <ReactTooltip id="user_box_tooltip"/>
    </div>
  );
};


export default ZUserBox;

 /*

          <Button 
            component="span"
            //variant="contained"
            color="primary"
            size="small"
            //style={{ marginLeft: 8 }}
            startIcon={ <ClearIcon color="secondary"/> }
            onClick={(event) => { 
              let path = generatePath('/client/:id', {
                id: client.id}
              )
              history.push(path)
            }}          
          >
            Pinned: {client.name}
          </Button> 




  let lat = 0;
  let lng = 0;
  navigator.geolocation.getCurrentPosition((position)=>{
    lat = position.coords.latitude
    lng = position.coords.longitude
    console.log(`ZToolNodeBox [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
  })
  */

  /*
  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZSetReadyButton [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  }, [setLat,setLng]);
  */

  /*
  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolNodeBox [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })

    let posTimer = setTimeout(() => {
      navigator.geolocation.getCurrentPosition((position)=>{
        setLat(position.coords.latitude)
        setLng(position.coords.longitude)
        console.log(`ZToolNodeBox update [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
      })
    }, 5000);

    return () => {
      console.log("stopping ZToolNodeBox -> position timer")
      clearTimeout(posTimer);

    };
  });
  */

/*
            <Fab
              data-tip="status"
              data-for="toolnode_box_tooltip"
              color={status_colour}
              aria-label="status"
              size="large"
              variant="extended"
              onClick={handleMenuClick}
            >
              <MoreVertIcon/>
              {headerMenu}
              { node.status.toUpperCase() }
              </Fab>

*/