import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllConditions, getAllClients, getClient, getContact } from 'queries_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZDescriptorAutocomplete = (props) => {
  let descriptors = [];

  console.log('ZDescriptorAutocomplete->props')
  console.log(props);

  for(let dd of props.condition.descriptors){
    descriptors.push(dd);
  }

  console.log(descriptors);

  return (
    <Autocomplete
      id="choose-descriptor-combo-box"
      onChange={(event,item)=>{
        console.log("descriptor autocomplete change")
        console.log(item)
        props.onChange(event,item)
      }}
      options={descriptors}
      getOptionLabel={(option) => option.name  || ""}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}

      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Descriptor" variant="outlined" />}
    />
  )
}

export default ZDescriptorAutocomplete;
