import React from 'react';
//import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery } from '@apollo/client';
import { lostSalesTotalQuery, salesFunnelQuery, salesBySupplierAndFunnelQuery } from 'queries';
*/
import { useQuery } from 'urql';
import { lostSalesTotalQuery, salesFunnelQuery, salesBySupplierAndFunnelQuery } from 'queries_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import Typography from '@material-ui/core/Typography';
import ZTitle from 'components/core/ZTitle';
import Moment from 'react-moment';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';


import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

//////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10),
  },
  info: {
    margin: theme.spacing(2),
  },
}));

//////////////////////

const ZSalesLostBox = (props) => {
  const classes = useStyles();
  const now = moment()
  const the_year = now.year()

  let query = lostSalesTotalQuery
  let variables = {}

  let supplier = false;
  if(typeof props.supplier !== 'undefined'){
    if(! props.supplier.includes('All')){
      supplier = true
      console.log("ZSalesLostBox -> supplier is set")
      console.log(props)
    }     
  }

  if(typeof props.year !== 'undefined'){
    variables = {accepted_year: props.year}
  }

  if(typeof props.funnel !== 'undefined'){
    query = salesFunnelQuery
    variables = {funnel: props.funnel}
  }

  if(supplier === true){
    query = salesBySupplierAndFunnelQuery
    variables = {
      funnel: props.funnel,
      supplier: `%${props.supplier}%`,
      status: "accepted",
    }   
  }

  /////////////////////

  /*
  const { loading, error, data }  = useQuery(query,{ 
    variables: variables,
    pollInterval: 10000,
   });

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: query,
    variables: variables,
  });
  
  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(typeof props.year !== 'undefined'){
    console.log("lost sales to date")
    console.log(data);
    let total_amount = data.zeus_quotation_view_aggregate.aggregate.sum.amount
    let max_amount = data.zeus_quotation_view_aggregate.aggregate.max.amount

    let withTotal = false
    let pctConversion = 0
    if(typeof props.total !== 'undefined'){
      withTotal = true
      pctConversion = data.zeus_quotation_view_aggregate.aggregate.count / props.total
      pctConversion *= 100
      pctConversion = pctConversion.toFixed(1)
    }

    return (
      <Card>
        <ZTitle>Sales Lost Year To Date</ZTitle>
        <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="secondary">count: {data.zeus_quotation_view_aggregate.aggregate.count}</Typography>
          {withTotal ? (
            <Typography color="primary">{`closing rate: ${pctConversion}%`}</Typography>
          ):(
            <Typography color="primary">{`closing rate: NA%`}</Typography>
          )}        
          <Typography>{`max lost quote $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
        </div>
      </Card>
    );
  }


  if(typeof props.funnel !== 'undefined' && supplier === true){
    console.log("supplier lost sales in funnel")
    console.log(data);

    let total_amount = data.lost_aggregate.aggregate.sum.amount
    let max_amount = data.lost_aggregate.aggregate.max.amount
    let avg_amount = data.lost_aggregate.aggregate.avg.amount
    let min_amount = data.lost_aggregate.aggregate.min.amount
    let quote_count = data.lost_aggregate.aggregate.count


    let total_count =  (data.won_aggregate.aggregate.count + data.pending_aggregate.aggregate.count + data.lost_aggregate.aggregate.count)
    let loss_ratio = data.lost_aggregate.aggregate.count / total_count
    let pctLoss = 100.0 * loss_ratio
    pctLoss = pctLoss.toFixed(1)

    return (
      <Card>
        <ZTitle>{`Funnel: ${props.supplier} Sales Lost`}</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="secondary">count: {quote_count}</Typography>
          <Typography color="primary">{`loss ratio: ${pctLoss}%`}</Typography>
          <Typography>{`max lost quote $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );
  }


  if(typeof props.funnel !== 'undefined'){
    console.log("lost sales in funnel")
    console.log(data);
    let total_amount = data.lost_sales_stats.aggregate.sum.amount
    let max_amount = data.lost_sales_stats.aggregate.max.amount
    let count = data.lost_sales_stats.aggregate.count
    let total_count =  (data.accepted_sales_stats.aggregate.count + data.pending_sales_stats.aggregate.count + data.lost_sales_stats.aggregate.count)
    let loss_ratio = data.lost_sales_stats.aggregate.count / total_count
    let pctLoss = 100.0 * loss_ratio
    pctLoss = pctLoss.toFixed(1)

    return (
      <Card>
        <ZTitle>Funnel: Sales Lost</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="secondary">count: {count}</Typography>
          <Typography color="primary">{`loss ratio: ${pctLoss}%`}</Typography>
          <Typography>{`max lost quote $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );
  } else {
    return (
      <Card>
        <ZTitle>Sales Lost Year To Date</ZTitle>
          <Typography component="p" variant="h4">
            NA
          </Typography>
          <Typography color="secondary">NA</Typography>
          <Typography>NA</Typography>
      </Card>
    );
  }


}

export default ZSalesLostBox;

/*
      <div>
        <Link color="primary" href="#">
          Do something
        </Link>
      </div>
*/