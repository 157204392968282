import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { useSubscription as useMQTTSubscription } from 'mqtt-react-hooks';
import { getSensor } from 'queries_urql'; //subscribeToolBox
import { subscribeSensorBox } from 'subscriptions_urql';
import { updateSensor, updateSensorField, zeusSensorUpdateAlias } from 'mutations_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, blue, green, cyan, grey } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select'; 
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RestoreIcon from '@material-ui/icons/Restore';
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import BuildIcon from '@material-ui/icons/Build';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QRCode from "react-qr-code";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ZChip from 'components/core/ZChip';
import ZMapComponent from 'components/core/ZMapComponent';
import ZSetOutofServiceButton from 'components/buttons/ZSetOutofServiceButton';
import ZSetReadyButton from 'components/buttons/ZSetReadyButton'; 
import ZSetRetiredButton from 'components/buttons/ZSetRetiredButton';
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';
import ZAddAliasButton from 'components/buttons/ZAddAliasButton'; //ZAssignSensorToClientButton
import ZAssignSensorToClientButton from 'components/buttons/ZAssignSensorToClientButton'; //ZAssignSensorToClientButton
//import ZAssignSensorToParentButton from 'components/buttons/ZAssignSensorToParentButton'; //ZAssignSensorToClientButton
import ZAssignSensorToAssetButton from 'components/buttons/ZAssignSensorToAssetButton'; //ZAssignSensorToClientButton
import ZAssignSensorToLocationButton from 'components/buttons/ZAssignSensorToLocationButton';//ZAssignSensorToLocationButton
import ZSetSensorOrientationButton from 'components/buttons/ZSetSensorOrientationButton';//ZSetSensorOrientationButton
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';
import ZAssignContactSensorToTriggerButton from 'components/buttons/ZAssignContactSensorToTriggerButton';
import ZAssignVibrationSensorToTriggerButton from 'components/buttons/ZAssignVibrationSensorToTriggerButton';
import ZSetAnalysisBloCButton from 'components/buttons/ZSetAnalysisBloCButton'; 
import ZSensorGatewayCommandsButton from 'components/buttons/ZSensorGatewayCommandsButton'; //ZSensorGatewayCommandsButton

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import GaugeChart from 'react-gauge-chart';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },  
  avatar: {
    backgroundColor: blue[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

/////////////////////////////////////

const ZSensorBox = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);

  const [state, setState] = React.useState({
    cloneable: false,
  })

  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    alias: false,
    status: false,
    field: null,
    value: null,
  })

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  console.log('ZSensorBox->props')
  console.log(props);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const calcRestrictedDates = (elements) =>{
    let restrictedDates = []
    console.log('callout')
    //console.log(row)
    elements.forEach((elem)=>{
      //console.log(elem)
      /*
      let d = new Date(elem.theDate);
      console.log(d)

      let str = d.toLocaleDateString();
      d = new Date(str);
      */
      let d = moment(elem.theDate).local()
      d = d.add(1, 'day').toDate()
      //let d = a.l

      //console.log(d)
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  const [updateResult, updateFunction] = useMutation(updateSensorField); //updateAliasFunction
  const [updateAliasResult, updateAliasFunction] = useMutation(zeusSensorUpdateAlias);

  ///////////


  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZSensorBox->handleEditCommit')
    let sensor_id = props.sensor.id;
  
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: sensor_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: sensor_id,
        name: `sensor ${field} updated`,
        description: `${userData.alias} updated sensor ${field} from ${props.sensor[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating sensor: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`sensor update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated sensor', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);


  const handleAliasEditCommit = React.useCallback((field,value) => {
    console.log('ZSensorBox->handleAliasEditCommit')
    let sensor_id = props.sensor.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;

    let variables = {
      serialNumber: props.sensor.name, 
      userId: user_id, 
      userName: userData.alias, 
      alias: value
    }

    console.log(variables);
    
    updateAliasFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating sensor alias: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated sensor alias', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);  


  /////////

  let sensor = {}

  const queryPause = props.static !== undefined ? true : false;
  const subscriptionPause = props.realtime !== undefined ? false : true;
  const id = props.sensor.id;

  const handleSubscription = (previousData, response) => {
    console.log('ZSensorBox::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    sensor = data.zeus_sensor_by_pk
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZSensorBox setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeSensorBox,
    variables: { id },
    pause: subscriptionPause,
  }, handleSubscription); 


  const [result, reexecuteQuery] = useQuery({
    query: getSensor,
    variables: { id },
    //pause: queryPause,
    pause: true,
  });

  /*
  let serialNumber = props.sensor.name;
  let gwSerial = props.sensor.gateway.name
  let topic = `server/gateway/${gwSerial}/${serialNumber}/stateupdate`

  const { message } = useMQTTSubscription([topic]);  
  console.log("ZSensorBox->mqtt message");
  console.log(message)
  */

  console.log('ZSensorBox result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static === undefined){
    sensor = data.zeus_sensor_by_pk
  } else {
    sensor = props.sensor
  }

  /////////
  

  console.log('ZSensorBox->sensor');
  console.log(sensor)

  let smallChips = (<div></div>)
  let sensorNotes = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)
  let images = (<div></div>)

  let addSetOutofServiceButton = (<div></div>)
  let assignToClientButton = (<div></div>)
  let assignToClientNodeButton = (<div></div>)
  let assignToComponentButton = (<div></div>)
  let assignToLocationButton = (<div></div>)
  let assignToAssetButton = (<div></div>)
  let setOrientationButton = (<div></div>)
  let addAliasButton = (<div></div>)
  let assignSensorToTriggerButton = (<div></div>)

  // MQTT based functionality
  let mqttSensorToStartButton = (<div></div>)

  // Prodigy AI based functionality
  let analysisBloCs = []

  //////////////////////////////////

  if(props.noactions === undefined){
    assignToClientButton = (
      <div>
        <ZAssignSensorToClientButton id={id} sensor={props.sensor} done={props.sensor.client ? true : false} />      
      </div>
    )

    if(props.sensor.stateupdate.type === 31){
      assignSensorToTriggerButton = (
        <div>
          <ZAssignContactSensorToTriggerButton sensor={props.sensor} client={props.sensor.client} sensors={props.sensors} static/>
        </div>
      )
    }

    //ZAssignVibrationSensorToTriggerButton
    if(props.sensor.stateupdate.type === 3 || props.sensor.stateupdate.type === 5 || props.sensor.stateupdate.type === 6 ){
      assignSensorToTriggerButton = (
        <div>
          <ZAssignVibrationSensorToTriggerButton sensor={props.sensor} client={props.sensor.client} sensors={props.sensors} static/>
        </div>
      )
    }


    if(props.sensor.client){
      /*
      assignToClientNodeButton = (
        <div>
          <ZAssignSensorToParentButton id={id} sensor={props.sensor} client={props.sensor.client}  items={props.sensor.client.client_nodes} done={props.sensor.client ? true : false}/>      
        </div>
      )
      */

      assignToAssetButton = (
        <div>
          <ZAssignSensorToAssetButton id={id} sensor={props.sensor} client={props.sensor.client} items={props.sensor.client.assets} done={props.sensor.asset ? true : false}/>      
        </div>        
      )
    } else {

      /*
      assignToClientNodeButton = (
        <div>
          <ZAssignSensorToParentButton id={id} sensor={props.sensor} client={props.sensor.client}  items={props.sensor.client.client_nodes} disabled/>      
        </div>
      )
      */

      assignToAssetButton = (
        <div>
          <ZAssignSensorToAssetButton id={id} sensor={props.sensor} disabled/>      
        </div>        
      )



    }

    if(props.sensor.asset){

      assignToLocationButton = (
        <div>
          <ZAssignSensorToLocationButton id={id} sensor={props.sensor} items={props.sensor.component_locations ? props.sensor.component_locations : undefined} done={props.sensor.component_location ? true : false} />      
        </div>        
      )
    }

    addAliasButton = (
      <div>
        <ZAddAliasButton id={id} entity={sensor} updateMutation={zeusSensorUpdateAlias} />  
      </div>
    )

    setOrientationButton = (
      <div>
        <ZSetSensorOrientationButton id={id} sensor={sensor} done={props.sensor.orientation ? true : false}/>
      </div>
    )

    if(props.sensor.client && props.sensor.analysis_blocs.length === 0){
      props.sensor.client.analysis_blocs.forEach(ab => {
        let bloc = (
          <div>
            <ZSetAnalysisBloCButton id={id} client={props.sensor.client} sensor={props.sensor} bloc={ab} active={ab.status === "enabled" ? true : false} 
              cloneable={state.cloneable} onClone={()=>setState({...state, cloneable: true})} global isSensor/>
          </div>      
        )
        analysisBloCs.push(bloc)        
      });
    }

    props.sensor.analysis_blocs.forEach(ab => {
      let bloc = (
        <div>
          <ZSetAnalysisBloCButton id={id} client={props.sensor.client} sensor={props.sensor} bloc={ab} active={ab.status === "enabled" ? true : false} 
            configurable isSensor/>
        </div>      
      )
      analysisBloCs.push(bloc)        
    });

  }

  ///////////////////////////////////

  if(props.fullBox){
    smallChips = (
      <div>
        <ZChip color={sensor.orientation ? 'green' : 'red'} variant='outlined' label={`Orientation: ${sensor.orientation ? sensor.orientation : 'N/A'}`} />
        <Chip label={'Total Measurements: '+ sensor.measurements_aggregate.aggregate.count} />  
      </div>
    )

    images = (
      <div>
        <CardActions disableSpacing>       
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show images"
            data-tip="show images"
            data-for="gateway_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
          { props.showImages && <ZImageListHorizontal parent_id={sensor.id} path={`sensor/${sensor.id}/images`} data={sensor.images} /> }             
          </CardContent>
        </Collapse>
      </div>
    )
  }


  let title = (<div>{
    isEditable.alias ?
      <div>
        <Typography  variant='overline' color='primary'>Sensor</Typography>   
        <Typography variant='h3'>{sensor.name}</Typography>  
        <TextField
          className={classes.descriptionField}
          fullWidth
          id="alias"
          label="Alias"
          defaultValue={sensor.alias}
          onChange={(event) =>{
            console.log(event.target.value)
            setIsEditable({...isEditable, field: 'alias', value: event.target.value});
            //console.log("state->"+ JSON.stringify(state));
          }} 
          variant="filled"
          InputProps={{
            endAdornment: (
              <IconButton
                variant="contained"
                color="primary"
                onClick={()=>{
                  console.log('edit commit')
                  if(isEditable.value === '' || isEditable.value === ' '){
                    handleAliasEditCommit(isEditable.field, '');
                  } else {
                    handleAliasEditCommit(isEditable.field, isEditable.value);
                  }                  
                }}                    
              >
                <CloudUploadIcon />
              </IconButton>
            )
          }}              
        /> 
      </div>
      :
      <div>
        <Button 
          component="span"
          //variant="contained"
          color="primary"
          size="small"
          //style={{ marginLeft: 8 }}
          onClick={(event) => { 
            let path = generatePath('/sensors')
            history.push(path)
          }}          
        >
          Sensor
        </Button> 
        <Typography variant='h3'>{sensor.name}</Typography>
        <div onClick={()=>setIsEditable({...isEditable, alias: true, field: 'alias'})}>
          <Typography variant='h4' color='secondary'>{sensor.alias ? sensor.alias : ''}</Typography>
        </div>
      </div>                
    }</div>
  )  
  

  /*
  let title = (
    <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/sensors')
          history.push(path)
        }}          
      >
        Sensor
      </Button> 
      <Typography variant='h3'>{sensor.name}</Typography>
      <div>
        <Typography variant='h4' color='secondary'>{sensor.alias ? sensor.alias : ''}</Typography>
      </div>
    </div>                
  )  
  */

  /*
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
  */

  // ready is the default
  let urlValue = `${baseURL}/sensor/${sensor.id}`;

  if(props.noqrcode === undefined){
    qrcode = (
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
        <QRCode
        size={512}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={urlValue}
        viewBox={`0 0 512 512`}
        />
      </div>
    )
  }

  
  let current_user = (<div></div>)
  let status_colour = "primary"

  if(sensor.status.toUpperCase() === "ANOMALY DETECTED"){
    status_colour = "secondary"
  }

  /////////////////////////

  let lastMeasurement = (
    <Chip color="primary" label="Last Measurement: Never" />
  )

  let lastUpdate = (
    <div></div>
  )

  let blocStatus = (<div></div>)
  let blocGauge = (<div></div>)

  let lastCheckedIn  = (<div>Last Checked-In: never</div>)
  let clientName = (<div>Client: N/A</div>)
  let assetName = (<div>Asset: N/A</div>)
  let componentName = (<div>Component: N/A</div>)
  let locationName = (<div>Location: N/A</div>)
  let gatewayName = (<div>Gateway: N/A</div>)
  let nfcTag = (<div>NFC Tag: N/A</div>)

  //if(tool.locker !== null){
  //  lockerName = (<div>Locker: {tool.locker.name}</div>)
 // }

  if(sensor.asset !== null){
    assetName = ( 
      <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/asset/:id', {
            id: sensor.asset.id}
          )
          history.push(path)
        }}          
      >
        Asset: {sensor.asset.name}
      </Button> 
      </div>
    )   
  }


  if(sensor.component){
    componentName = ( 
      <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/component/:id', {
            id: sensor.component.id}
          )
          history.push(path)
        }}          
      >
        Component: {sensor.component.name}
      </Button> 
      </div>
    )   
  }  


  if(sensor.component_location){
    locationName = ( 
      <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/location/:id', {
            id: sensor.component_location.id}
          )
          history.push(path)
        }}          
      >
        Location: {sensor.component_location.name}
      </Button> 
      </div>
    )   
  }    


  if(sensor.client !== null){
    clientName = ( 
      <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client/:id', {
            id: sensor.client.id}
          )
          history.push(path)
        }}          
      >
        Client: {sensor.client.name}
      </Button> 
      </div>
    )   
  }


  if(sensor.gateway !== null){
    gatewayName = ( 
      <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/gateway/:id', {
            id: sensor.gateway.id}
          )
          history.push(path)
        }}          
      >
        Gateway: {sensor.gateway.alias ? sensor.gateway.alias : sensor.gateway.name}
      </Button> 
      </div>
    )   
  }  

  if(sensor.lastMeasurement){
    lastMeasurement = (
      <Chip color="primary" label={
          <div>
            Last Measurement: &nbsp;
            <Moment fromNow>
              {sensor.lastMeasurement}
            </Moment>     
          </div> 
        } 
      />
    )
  }

  if(sensor.lastUpdate){
    lastUpdate = (
      <div>
        Last update: &nbsp;
        <Moment fromNow>
          {sensor.lastUpdate}
        </Moment>     
      </div> 
    )
  }

  if(sensor.measurements){
    if(sensor.measurements.length > 0){
      let lastval = "";
      let xlastval = 0.0;
      let lastm = sensor.measurements[0];
      let color = "white"
      let strLabel = "Anomaly -- not analyzed"
      let strVariant = "outlined"
      // if no value then         --- white
      // if pct thresh 0% - 70%   --- light blue
      // if pct thresh 71% - 90%  --- yellow
      if(lastm.analysis_results.length > 0){
        color = "lightblue"
        strVariant = "regular"

        let lastr = lastm.analysis_results[0]
        lastval = lastr.value;
        lastval = lastval.toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        strLabel = `Anomaly: ${lastval} % of threshold`

        xlastval = lastr.value / 100.0;
        if(lastr.value > 70.0 && lastr.value < 90.0){
          color = "yellow"
          xlastval = lastr.value / 100.0;
        }
        // if pct thresh 91% - 100% --- orange
        if(lastr.value > 90.0 && lastr.value < 100.0){
          color = "orange"
          xlastval = lastr.value / 100.0;
        }
        // if pct thresh > 100%     --- red
        if(lastr.value > 100.0){
          color = "red"
          xlastval = 1.0;
        }        
      } 
      
      blocStatus = (
        <ZChip color={color} label={strLabel} variant={strVariant} />        
      )

      console.log(`gauge value = ${xlastval}`)
      let nc = grey[900]

      if(xlastval > 0.9){
        nc = red[500]
      }

      blocGauge = (
        <Paper elevation={3} className={classes.paper}>
          <GaugeChart id="sensor-anomaly-guage" 
            nrOfLevels={420}
            animate={false}
            textColor={grey[900]}
            arcsLength={[0.7, 0.2, 0.1]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={xlastval}
            arcPadding={0.02}   
            hideText={true}  
            needleColor={nc}     
          />
          <Typography align='center'>Anomaly Threshold</Typography> 
        </Paper>
      )
    }
  }

  let sensorState = (<div/>)
  
  if(props.stateupdate){
    let message = props.stateupdate;

    if(message.type === 31){
      sensorState = (
        <div>
          <Typography variant='h6'>Sensor State</Typography> 
          <IconButton
            variant="contained"
            color="primary"
            onClick={()=>{
              console.log('refresh')
              if(props.onRefresh){
                props.onRefresh()
              }
            }}                    
          >
            <RestoreIcon />
          </IconButton>            
          <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="simple table">
                <TableBody>
                  <TableRow key='000'>
                    <TableCell align="left">Last</TableCell><TableCell align="left">{moment.unix(message.timestamp).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                  </TableRow>
                  <TableRow key='00'>
                    <TableCell align="left">Timestamp</TableCell><TableCell align="left">{message.timestamp}</TableCell>
                  </TableRow>
                  <TableRow key='1'>
                    <TableCell align="left">RSSI:</TableCell><TableCell align="left">{message.rssi}</TableCell>
                  </TableRow>
                  <TableRow key='2'>
                    <TableCell align="left">Battery:</TableCell><TableCell align="left">{message.battery} volts&#9889;</TableCell>
                  </TableRow>
                  <TableRow key='3'>
                    <TableCell align="left">Temperature:</TableCell><TableCell align="left">{message.temperature} &deg;C</TableCell>
                  </TableRow>
                  <TableRow key='4'>
                    <TableCell align="left">Update Interval:</TableCell><TableCell align="left">{message.updateInterval} seconds</TableCell>
                  </TableRow>
                  <TableRow key='11'>
                    <TableCell align="left">Channel-1:</TableCell><TableCell align="left">{message.dryContactsState[0] === true ? 'Open' : 'Closed'}</TableCell>
                  </TableRow>
                  <TableRow key='12'>
                    <TableCell align="left">Channel-2:</TableCell><TableCell align="left">{message.dryContactsState[1] === true ? 'Open' : 'Closed'}</TableCell>
                  </TableRow>
                  <TableRow key='13'>
                    <TableCell align="left">Channel-3:</TableCell><TableCell align="left">{message.dryContactsState[2] === true ? 'Open' : 'Closed'}</TableCell>
                  </TableRow>
                  <TableRow key='14'>
                    <TableCell align="left">Channel-4:</TableCell><TableCell align="left">{message.dryContactsState[3] === true ? 'Open' : 'Closed'}</TableCell>
                  </TableRow>        
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )
    }

    if(message.type === 3 || message.type === 5 || message.type === 6){
      sensorState = (
        <div>
          <Typography variant='h6'>Sensor State</Typography>
          <IconButton
            variant="contained"
            color="primary"
            onClick={()=>{
              console.log('refresh')
              if(props.onRefresh){
                props.onRefresh()
              }
            }}                    
          >
            <RestoreIcon />
          </IconButton>          
          <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="simple table">
                <TableBody>
                  <TableRow key='000'>
                    <TableCell align="left">Last</TableCell><TableCell align="left">{moment.unix(message.timestamp ? message.timestamp : 'N/A').format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                  </TableRow>                
                  <TableRow key='00'>
                    <TableCell align="left">Timestamp</TableCell><TableCell align="left">{message.timestamp ? message.timestamp : 'N/A'}</TableCell>
                  </TableRow>                
                  <TableRow key='1'>
                    <TableCell align="left">RSSI:</TableCell><TableCell align="left">{message.rssi ? message.rssi : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow key='2'>
                    <TableCell align="left">Battery:</TableCell><TableCell align="left">{message.battery ? message.battery  : 'N/A'} volts&#9889;</TableCell>
                  </TableRow>
                  <TableRow key='3'>
                    <TableCell align="left">Temperature:</TableCell><TableCell align="left">{message.temperature ? message.temperature : 'N/A'} &deg;C</TableCell>
                  </TableRow>
                  <TableRow key='4'>
                    <TableCell align="left">Update Interval:</TableCell><TableCell align="left">{message.updateInterval ? message.updateInterval : 'N/A'} seconds</TableCell>
                  </TableRow>
                  <TableRow key='21'>
                    <TableCell align="left">Velocity RMS-X:</TableCell><TableCell align="left">{message.rms ? message.rms[0] : 'N/A'} mm/s</TableCell>
                  </TableRow>
                  <TableRow key='22'>
                    <TableCell align="left">Velocity RMS-Y:</TableCell><TableCell align="left">{message.rms ? message.rms[1] : 'N/A'} mm/s</TableCell>
                  </TableRow>
                  <TableRow key='23'>
                    <TableCell align="left">Velocity RMS-Z:</TableCell><TableCell align="left">{message.rms ? message.rms[2] : 'N/A'} mm/s</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )
    }    
  }

  if(sensor.nfcTag){
    nfcTag = (<div>NFC Tag: {sensor.nfcTag}</div>)
  }  

  const renderStatusEditInputCell = (params) => {
    console.log('renderStatusEditInputCell')
    console.log(params)
    return (
      <TextField
        className={classes.descriptionField}
        id="sensor_status"
        label="Status"
        defaultValue={props.sensor.status}
        onChange={(event) =>{
          console.log(event.target.value)
          setIsEditable({...isEditable, field: 'status', value: event.target.value});
          //console.log("state->"+ JSON.stringify(state));
        }} 
        variant="filled"
        InputProps={{
          endAdornment: (
            <IconButton
              variant="contained"
              color="primary"
              onClick={()=>{
                console.log('edit commit')
                handleEditCommit(isEditable.field, isEditable.value)
                //setIsEditable({...isEditable, description: false, field: null, value: null})
              }}                    
            >
              <CloudUploadIcon />
            </IconButton>
          )
        }}              
      >  
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="non-responsive">non-responsive</MenuItem>
        <MenuItem value="recovered">recovered</MenuItem>
        <MenuItem value="lost">lost</MenuItem>
        <MenuItem value="low battery">low battery</MenuItem>
        <MenuItem value="retired">retired</MenuItem>
      </TextField>
    );
  }

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>      
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="sensor" className={classes.avatar}>
              <NetworkCheckIcon />
            </Avatar>
          }
          action={
            <div>
              <Paper>
                {
                  isEditable.status ?
                  <TextField
                    select
                    className={classes.descriptionField}
                    id="sensor_status"
                    label="Status"
                    defaultValue={props.sensor.status}
                    onChange={(event) =>{
                      console.log(event.target.value)
                      setIsEditable({...isEditable, field: 'status', value: event.target.value});
                      //console.log("state->"+ JSON.stringify(state));
                    }} 
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          variant="contained"
                          color="primary"
                          onClick={()=>{
                            console.log('edit commit')
                            handleEditCommit(isEditable.field, isEditable.value)
                            //setIsEditable({...isEditable, description: false, field: null, value: null})
                          }}                    
                        >
                          <CloudUploadIcon />
                        </IconButton>
                      )
                    }}              
                  >  
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="active">active</MenuItem>
                    <MenuItem value="non-responsive">non-responsive</MenuItem>
                    <MenuItem value="recovered">recovered</MenuItem>
                    <MenuItem value="lost">lost</MenuItem>
                    <MenuItem value="low battery">low battery</MenuItem>
                    <MenuItem value="retired">retired</MenuItem>
                  </TextField>
                  :
                  <Chip
                    style={{ marginLeft: 8 }}
                    icon={<TrackChangesIcon />}
                    label={ sensor.status.toUpperCase() }
                    color={status_colour}
                    clickable
                    onClick={()=>setIsEditable({...isEditable, status: true, field: 'status'})}
                  />   
                }           
                <br/>
                <div>
                  <ZSensorGatewayCommandsButton sensor={props.sensor} stateupdate={props.stateupdate}/>
                  {assignToClientButton}
                  {assignToAssetButton}
                  {assignToComponentButton}
                  {assignToLocationButton}
                  {setOrientationButton}
                  {addAliasButton}
                  {assignSensorToTriggerButton}
                  <br/>
                  {analysisBloCs}
                  {props.actions}
                </div>
              </Paper>
              <br/>
              {blocGauge}
            </div>
          }
          title={title}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {sensor.createdOn}
                </Moment>
              </div>
              <br/>
              {blocStatus}
              {lastUpdate}
              {sensorState}
              <br/>
              <div className={classes.chips}>
                {lastMeasurement}
                {smallChips}
              </div>
              {clientName}
              {assetName}
              {componentName}
              {locationName}
              {gatewayName}
              {nfcTag}
            </div>
          }
        />
        <CardContent>
          {gmap}          
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >          
          {
            isEditable.description ?
            <TextField
              className={classes.descriptionField}
              fullWidth
              id="details"
              label="Description"
              multiline
              defaultValue={sensor.description}
              rows={4}
              onChange={(event) =>{
                console.log(event.target.value)
                setIsEditable({...isEditable, field: 'description', value: event.target.value});
                //console.log("state->"+ JSON.stringify(state));
              }} 
              variant="filled"
              InputProps={{
                endAdornment: (
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      console.log('edit commit')
                      handleEditCommit(isEditable.field, isEditable.value)
                      //setIsEditable({...isEditable, description: false, field: null, value: null})
                    }}                    
                  >
                    <CloudUploadIcon />
                  </IconButton>
                )
              }}              
            />  
            :       
            <Typography>{sensor.description}</Typography>
          }
          </Paper>
        </CardContent>
        { /* images */ }
        { images }
      </Card>
      <ReactTooltip id="sensor_box_tooltip"/>
    </div>
  );
};


export default ZSensorBox;




/*

      <Select
        labelId="freq-select-label"
        id="freq-simple-select"
        value={props.sensor.status}
        onChange={(event)=>{
            console.log(event.target.value)
            setIsEditable({...isEditable, field: 'status', value: event.target.value});
            //handleEditCommit(isEditable.field, isEditable.value);
          }
        }
      >

*/