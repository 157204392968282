import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

/*
import { addAnnotation } from 'mutations';
import { useMutation } from '@apollo/client';
*/

import { useMutation } from 'urql';
import { addAnnotation } from 'mutations_urql';

import S3 from 'react-aws-s3';
import gql from 'graphql-tag';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    width: '35ch',
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  avatar_closed: {
    backgroundColor: red[500],
  },
  avatar_pending: {
    backgroundColor: blue[500],
  },
  avatar_accepted: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
  props {
    client_id
    contact_id
    sales_document_id
    asset_id
    annotation_id
    supplier_id
    job_id
    route_id
    activity_id
    title
    name  **file name
    description
    bucket
    dirName
    s3Url
  }
*/

const ZAnnotationInputMenuItem = (props) => {
  const classes = useStyles();
  const fileInput = React.useRef();
  const [name , setName] = React.useState("");
  const [description, setDescription] = React.useState(null);
  let target = ""

  if( props.client_id !== undefined){
    target = " to Client"
  }

  if( props.contact_id !== undefined){
    target = " to Contact"
   }

  if( props.sales_document_id !== undefined){
    target = " to Document"
  }

  if( props.asset_id !== undefined){
    target = " to Asset"
  }

  if( props.supplier_id !== undefined){
    target = " to Supplier"
  }

  if( props.job_id !== undefined){
    target = " to Job"
  }

  if( props.route_id !== undefined){
    target = " to Route"
  }

  if( props.client_node_id !== undefined){
    target = " to Node"
  }

  if( props.product_id !== undefined){
    target = " to Product"
  }

  if( props.product_line_id !== undefined){
    target = " to Product Line"
  }

  console.log('building annotation input')

  const [insertFunction] = useMutation(addAnnotation);

  const handleSubmit = (event) => {
    event.preventDefault()
    let annotation = {
      name: name,
      description: description,
    }

    if( props.client_id !== undefined){
      annotation.client_id = props.client_id
    }

    if( props.contact_id !== undefined){
      annotation.contact_id = props.contact_id
    }

    if( props.sales_document_id !== undefined){
      annotation.sales_document_id = props.sales_document_id
    }

    if( props.asset_id !== undefined){
      annotation.asset_id = props.asset_id
    }

    if( props.supplier_id !== undefined){
      annotation.supplier_id = props.supplier_id
    }

    if( props.job_id !== undefined){
      annotation.job_id = props.job_id
    }

    if( props.route_id !== undefined){
      annotation.route_id = props.route_id
    }

    if( props.client_node_id !== undefined){
      annotation.client_node_id = props.client_node_id
    }

    if( props.product_id !== undefined){
      annotation.product_id = props.product_id
    }

    if( props.product_line_id !== undefined){
      annotation.product_line_id = props.product_line_id
    }

    insertFunction({variables: annotation})
  }

  /*
    .then((result)=>{
      if (result.error) {
        console.error('ERROR creating revised quote', result.error);
        setError(result.error)
        setAlertOpen(true)
        return
      }
      let path = generatePath('/quotation/:id', {id: entry.id})
      //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})
      history.push(path)      
    })
  */

  return (
    <Paper className={classes.root}>
      <strong>
        <div>
          <form onSubmit={handleSubmit}>
            <label>{`Add Note ${target}`}</label><br/>
            <TextField 
              onChange={(event) =>{
                console.log("setName->"+ JSON.stringify(event.target.value));
                setName(event.target.value);
              } }
              id="subject" 
              label="Subject" 
              variant="standard"
            />   
            <br/>
            <TextField 
              onChange={(event) =>{
                console.log("setDescription->"+ JSON.stringify(event.target.value));
                setDescription(event.target.value);
              } }
              id="description" 
              label="Description" 
              variant="standard"
              multiline
              rows={4}
            />
            <br/>  
            <Button variant="outlined" color="primary" type="submit">Create Note</Button>
          </form>
        </div>
      </strong>
    </Paper>
  )
}

export default ZAnnotationInputMenuItem;

/*
          <Button color="primary" 
            style={{ marginLeft: 8 }}
            onClick={() => uploadFile(selectedFile)}                     
          >
            {title}
          </Button>

          */