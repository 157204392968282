import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import clsx from 'clsx';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useQuery, useMutation } from 'urql';

import { addImage, addImages } from 'mutations_urql';

import moment from 'moment';
import { uuid } from 'uuidv4';
import { v4 as uuidv4 } from 'uuid';

import { uploadFileToAzure } from 'azure/AzureBlobAPI';
import { useLocalStorage } from 'hooks/useStorage';

import Button from '@material-ui/core/Button';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Snackbar from '@material-ui/core/Snackbar';
import PlaceIcon from '@material-ui/icons/Place';
import CloseIcon from '@material-ui/icons/Close';

///////////////////////////////

const useStyles = makeStyles((theme)=>({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button_new: {
    margin: theme.spacing(5),
  }, 
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  notes: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    height: '200px',
  },
  image_root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },  
}));

///////////////////////////////

const ZImageListHorizontal = (props) => {
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState(null);

  /*
  const [imageData, setImageData] = React.useState({
    file: null,
    base64URL: ""
  });
  */

  const [imageData, setImageData] = React.useState([]);

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: ''
  });

  const [insertResult, insertImageFunction] = useMutation(addImages);

  let id = null;
  let data = [];

  if(props.parent){
    id =  props.parent.id;
  }
  
  if(props.data){
    data = props.data;
  }

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // on reader load somthing...
      reader.onload = (event) => {
        var img = new Image();
        img.onload = () => {
          // Dynamically create a canvas element
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          // Actual resizing
          console.log('resizing image to thumbnail');
          ctx.drawImage(img, 0, 0, 100, 100);
        }
        
        img.src = event.target.result;;
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      
      // Convert the file to base64 text
      reader.readAsDataURL(file);

      console.log(fileInfo);
    });
  };


  const getBase64_1 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };


  const handleFileInputChange = React.useCallback((e)=>{
    console.log('handleFileInputChange')
    console.log(e.target.files);

    for (const file of e.target.files) {
      getBase64(file).then(result => {
        file["base64"] = result;
        console.log(`base64.length: ${result.length}`);
        console.log("File Is", file);
        //uploads.push(file);
        setImageData([...imageData, file]);
      }).catch(err => {
        console.log(err);
      });
    }

    console.log('imageData')
    console.log(imageData)

  },[]);


  const handleFileInputChange_1 = React.useCallback((e)=>{
    console.log('handleFileInputChange')
    console.log(e.target.files);

    //let  file  = imageData.file;
    let uploads = [];

    for (const file of e.target.files) {
      getBase64(file).then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        uploads.push(file);
      }).catch(err => {
        console.log(err);
      });
    }

    console.log(uploads);
    setImageData(uploads);
  },[]);

  
  const captureImage = React.useCallback((imgSrc)=>{
    console.log('ZImageListHorizontal::captureImage')
    const guuid = uuidv4()
    const createdOn = moment().format()

    /*
    let objects = [];
    let activities = [];

    imgSrc.forEach((ss)=>{
      activities.push({
        user_id: userData.id,
        parent_id: id,
        name: "uploaded image",
        description: `${userData.alias} uploaded an image`,   
      })
    })

    imgSrc.forEach((file)=>{
      objects.push({
        img: file,
        user_id: userData.id,
        parent_id: id,
        createdOn: createdOn,
      })
    })

    let variables = {
      user_id: userData.id,
      createdOn: createdOn,
      objects: objects,
      activities: activities,
    }

    console.log('captureImage->variables')
    console.log(variables)
    */

    console.log('ZImageListHorizontal::captureImage->imgSrc')
    console.log(imgSrc)

    uploadFileToAzure(props.path, imgSrc).then(result => {
      let imageFiles = []; 

      result.forEach((data)=>{

        let imageFile = {};
        imageFile.user_id = userData.id;
        imageFile.name = data.fileName;
        imageFile.img = data.base64;
        imageFile.src = data.url;
        //imageFile.bucket = data.bucket;
        //imageFile.path = data.path;

        if( props.description ){
          imageFile.description = props.description
        }
  
        if(props.parent_id){
          imageFile.parent_id = props.parent_id
        }

        imageFiles.push(imageFile);
      })

      let activities = [{
        user_id: userData.id,
        parent_id: props.parent_id,
        name: "images uploaded",
        description: `${userData.alias} uploaded ${result.length} images`,          
      }]
      //attachment.user_id = userData.id;
      
      console.log('imageFiles')
      console.log(imageFiles)

      let variables = {
        user_id: userData.id,
        parent_id: props.parent_id,
        createdOn: createdOn,
        objects: imageFiles,
        activities: activities,
      }

      insertImageFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR: insertImageFunction: ${result.error}`)
          setAlertState({
            open: true,
            message: `Error uploading image ${result.error}`
          });
         
        } else {
          console.log(result)
          setAlertState({
            open: true,
            message: `successfully uploaded image`
          });
        }
      })
    })

  },[]);


  let snackbarEl = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to close snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              setAlertState({
                open: false,
                message: ""
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    />     
  )

  //////////////////

  return (
    <div className={classes.image_root}>
      { snackbarEl }
      <input type="file" name="file" onChange={handleFileInputChange} multiple/>
      <Button variant="outlined" color="primary"
        onClick={()=>{
          console.log('image upload button clicked')
          console.log('uploading selected image file')
          console.log(imageData);
          captureImage(imageData);
        }}
      >
        Upload Image
      </Button>
      <ImageList className={classes.imageList} cols={3.0}>
        {data.map((item,index) => (
          <ImageListItem key={`list_image_${index}`}>
            <img src={item.img} alt={`list_image_${index}`} />
            <ImageListItemBar 
              title={item.createdOn}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>    
  )
};


export default ZImageListHorizontal;
