import React from 'react';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';

import { purple, green, lightBlue, cyan } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllJobBoardEntries } from '../../queries';
import { updateClient, updateJobBoardEntry } from '../../mutations'
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllGateways } from 'queries_urql';
import { updateClient, updateGateway, updateGatewayField, zeusGatewayConfigsBackup  } from 'mutations_urql';
import { subscribeAllGateways } from 'subscriptions_urql';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import LinearProgress from '@material-ui/core/LinearProgress'; //LinearProgress
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //ZJobBoardDetailPane
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Snackbar from '@material-ui/core/Snackbar';

import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import ZSensorDetailPane from 'components/core/ZSensorDetailPane';
import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';

import ZAddJobBoardEntryDialog from 'components/dialogs/ZAddJobBoardEntryDialog';
import ZAddToolNodeDialog from 'components/dialogs/ZAddToolNodeDialog';
import ZAddToolDialog from 'components/dialogs/ZAddToolDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { Link as RouterLink, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';
import eventBus from 'utils/EventBus';

//////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: cyan[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////////////////////////////////

const ZGatewaysPanel = (props) => {
  console.log('ZGatewaysPanel->props');
  console.log(props);  

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const [userData, setUserData] = useLocalStorage('userData');

  const history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    showDetailPane: false,
    showAddGatewayDialog: false,
    open: false,
    activeEntry: null,
    gateway_id: null,
  });

  const [alert, setAlert] = React.useState({
    show: false,
    message: '',
    error: false,
    severity: 'info',
  });    

  let rows = []

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>               
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/gateway/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>           
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 180,
      editable: false,
      hide: false,
    },
    {
      field: 'alias',
      title: 'Alias',
      headerName: 'Alias',
      minWidth: 250,
      editable: true,
      hide: false,
    },    
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 500,
      editable: true,
      sortable: false,
    },
    {
      field: 'sensors',
      title: 'Sensors',
      headerName: 'Sensors',
      minWidth: 220,
      editable: false,
      sortable: false,
      renderCell: (params)=>{
        return params.row.sensors_aggregate.aggregate.count
      }      
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'client',
      title: 'Client',
      headerName: 'Client',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.client){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let path = generatePath('/client/:id', {
                      id: params.row.client.id}
                      )
                    history.push(path)
                  }}     
                >
                  {params.row.client.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      }
    },
    {
      field: 'parent',
      title: 'Parent',
      headerName: 'Parent',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.parent){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let path = generatePath('/client_node/:id', {
                      id: params.row.parent.id}
                      )
                    history.push(path)
                  }}     
                >
                  {params.row.parent.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      }
    },    
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];

  //////

  const [updateGatewayResult, updateFunction] = useMutation(updateGatewayField);
  const [zeusGatewayBackupResult,     zeusGatewayBackupFunction]     = useMutation(zeusGatewayConfigsBackup);

  const handleCellEditCommit_1 = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZGatewaysPanel::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );


  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZJobBoardPanel::handleCellEditCommit');
      var modifiedOn = moment().format();
      var user_id = userData.id;
      var theName = "gateway updated";

      var _set = {}
      _set[field] = value
      _set.modifiedOn = modifiedOn;

      if(field === 'status'){
        theName = "gateway status updated";
        if(value === 'active'){
          theName = "gateway status set to active";
        }
      }

      let activity = {
        user_id: user_id,
        parent_id: id,
        name: theName,
        description: `${userData.alias} updated gateway ${field} to ${value}`,        
      }

      let variables = { 
        id: id, 
        _set: _set,
        activity: activity, 
      }
      console.log(variables)

      updateFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR updating gateway: ${result.error}`)
          setAlert({...alert, show: true, message: result.error, severity: 'error'})
        } else {
          console.log(`gateway update [ ${_set} ]`)
          console.log(result)
          setAlert({...alert, show: true, message: 'Successfully updated Gateway', severity: 'success'})
        }
      }) 
    },[]
  );  

  const handleClickOpen = () => {
    if(props.handleOpen){
      console.log('ZGatewaysPanel::handleClickOpen props.handleOpen is present handing off to parent');
      props.handleOpen()
      return;
    }
    console.log("ZGatewaysPanel::handleClickOpen->open add sensor dialog")
    setState({...state, showAddSensorDialog: true});
    console.log("ZGatewaysPanel::handleClickOpen == "+ state.showAddSensorDialog);
  };
  
  const handleClose = () => {
    setState({...state, showAddGatewayDialog: false});
  };

  const handleCloseDetailPane = () => {
    setState({...state, showDetailPane: false});
  };

  const handleBackupGatewayConfigs = () => {
    // need to incorporated debounce

    zeusGatewayBackupFunction().then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlert({...alert, show: true, error: true, message: `ERROR: ${result.error}`, severity: 'error'})
      }else {
        console.log('ZGatewaysPanel::zeusGatewayBackupFunction')
        console.log(result); 
        setAlert({...alert, show: true, error: false, message: 'Successfully requested Gateway configuration backup', severity: 'info'})
      }
    })
  }

  console.log('props')
  console.log(props)

  let title = 'View Gateway';

  if(state.activeEntry !== null){
    title = `View Gateway: ${state.activeEntry.name}`;
  }

  ////////////////////////////

  //use subscription
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return response;
  }

  const [subResult] = useSubscription({ 
    query: subscribeAllGateways, 
    pause: !window.navigator.onLine, 
  }, handleSubscription); 

  const [ result, reexecuteQuery ] = useQuery({
    query: getAllGateways,
    //pause: window.navigator.onLine
  });

  console.log('res');
  console.log(subResult)  

  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = result.data;
    fetching = result.fetching;
    error = result.error;
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  rows = data.zeus_gateway.slice(0)

  console.log("ZGatewaysPanel->rows")
  console.log(rows)

  ////////////////////////////

  const ZGatewayConfigBackupButton = () => {

    return (
      <div>
        <strong>
          <Collapse in={alert.show}>
            <Alert severity={alert.severity}>
              <AlertTitle>Alert</AlertTitle>
              <strong>{alert.message}</strong>
            </Alert>  
          </Collapse>
        </strong>
        <Button size="large" color="primary"
          id='backup_gateway_configs'
          style={{ marginLeft: 8 }}
          onClick={(event) => { 
            handleBackupGatewayConfigs()      
          }}       
        >
          Backup Gateway Configurations
        </Button>
      </div>
    )
  }


  ////////////////////////////

  return (
    <div className={classes.root}>
      <ZSensorDetailPane isOpen={state.showDetailPane} handleClose={handleCloseDetailPane} sensor={state.activeEntry} static/>
      {/* <ZAddJobBoardEntryDialog open={showDialog} handleClose={handleClose}/> */}
      <ZAddToolDialog open={state.showAddToolDialog} parent={props.parent} handleClose={()=>setState({...state, showAddToolDialog: false})} static/>  
      {/* alert component */} 
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alert.show}
        key="alert_button"
        onClose={()=>{
            setAlert({...alert, show: false, message: '', severity: 'info'})
        }}
      >
        <Alert severity={alert.severity} 
          onClose={()=>{
            setAlert({...alert, show: false, message: '', severity: 'info'})
        }}>
          {alert.message}
        </Alert>
      </Snackbar>  
      {/* main component */}       
      <Card variant="outlined" elevation={2}>
      <ZGatewayConfigBackupButton />
        <CardContent>
        <Box display="flex" bgcolor="#5c6bc0">
          <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          {props.title}
          </Typography>
          <IconButton
            onClick={handleClickOpen}
            style={{color:"white"}}
            color="inherit"
            aria-label="add row"
            edge="start"
            data-tip="create new gateway"
            data-for="gateways_panel_tooltip" 
          >
            <AddCircleOutlineIcon/>
          </IconButton>
        </Box>
        <DataGrid autoHeight
          title="Gateways"
          columns={columns}
          rows={rows}
          pageSize={20}
          density="compact"
          onCellEditCommit={handleCellEditCommit}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick={false}
          getRowClassName={(params) =>
            clsx('super-app', {
              positive: params.row.status === 'active',
              negative: params.row.status !== 'active',
            })
          }
          options={{
            toolbar: true,
            filtering: true,
            search: true,
            grouping: true,
            exportButton: true,
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          filterModel={props.filter}
          onRowDoubleClick={(params, evt) => {
            let entry = params.row;
            console.log("selected gateway")
            console.log(entry)
            setState({...state, activeEntry: entry, showDetailPane: true});
          }}
        />
        </CardContent>
      </Card>
      <ReactTooltip id="gateways_panel_tooltip"/>
    </div>
  );  
}
  
export default ZGatewaysPanel;


