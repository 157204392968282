import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getRoutesForClient } from 'queries_urql';
import { subscribeRoutesForClient } from 'subscriptions_urql';
import { addRoute, updateRoute } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZAddRouteDialog from 'components/dialogs/ZAddRouteDialog'; //ZAddRouteDialog

import ReactTooltip from 'react-tooltip';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  route_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  client: {}

}
*/

const ZRoutesList = (props) => {
  const history = useHistory();

  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [userData, setUserData] = useLocalStorage('userData');
  const [showRouteDialog, setShowRouteDialog] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    severity: 'info',
    open: false,
    message: '',
  });
  
  let rows = []

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/route/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Route',
      headerName: 'Route',
      minWidth: 180,
      editable: 'true',
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 180,
      editable: 'true',
      hide: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: true,   
      sortable: false,
    },
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 140,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
  ];


  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZRoutesList::handleCellEditCommit');
      console.log(`id=${id}  field=${field}  value=${value}`)

      var _set = {}
      _set[field] = value
      _set['modifiedOn'] = moment().format()

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set,
        activity: {
          user_id: userData.id,
          parent_id: id,
          name: "route updated",
          description: `${userData.alias} updated route ${field} to ${value}`,            
        }
      }
      console.log(variables)      

      updateFunction(variables).then(result => {
        if(result.error){
          console.log(`ERROR updating route ${result.error}`)
          setAlertState({
            open: true,
            message: `ERROR updating route ${result.error}`
          })          
        } else {
          console.log(`updated route`)
          setAlertState({
            open: true,
            message: "Successfully updated route"
          })
        }
      })
    },
    [],
  );


  const handleCellEditCommit_1 = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZRoutesList::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );


  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  console.log(props)

  /*
  const { loading, error, data }  = useQuery(getRoutesForClient,{ variables: { client_id: props.client.id }, pollInterval: 5000 });
  const [updateFunction] = useMutation(updateRoute);
  //const [insertFunction] = useMutation(addQuotation);
  */

  let client_id = props.client.id

  /*
  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: getRoutesForClient,
    variables: { client_id } ,
  });
  const [updateClientResult, updateFunction] = useMutation(updateRoute);

  const { data, fetching, error } = result;

  console.log(data);

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  //let skip_query = false;
  let skip_sub = false;
  let variables = {};

  if(props.static){
    skip_sub = true;
  }

  if(props.realtime || props.live){
    skip_sub = false;
  }

  variables = { client_id: client_id}

  // use subscription
  /*
  const [ result, reexecuteQuery ] = useQuery({
    query: getRoutesForClient,
    variables: { client_id } ,
    pause: skip_query,
  });
  */

  const [updateRouteResult, updateFunction] = useMutation(updateRoute);

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return response;
  };
 
  const [result] = useSubscription({ 
    query: subscribeRoutesForClient, 
    variables: variables,
    pause: skip_sub, 
  }, handleSubscription); 

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if(skip_sub === false){
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_sub){
    rows = props.items
  } else {
    rows = data.zeus_route
  }


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={()=>setShowRouteDialog(true)}>New Route</MenuItem>
      </Menu>
    </div>  
  )


  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="route actions"
      data-for="routes_list_tooltip" 
    >
      <MoreVertIcon />
      {props.menu ?? headerMenu}
    </Fab>
  )

  ////////////////////
  
  return (
    <div>
      <ZAddRouteDialog open={showRouteDialog} parent={props.client} onClose={()=>setShowRouteDialog(false)} static />
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="routes" className={classes.routes_avatar}>
              <AccountTreeIcon />
            </Avatar>
          }      
          action={props.actions ?? defaultAction}
          title='Routes'
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper className={classes.route_row}>
            <DataGrid autoHeight
              rows={rows}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  negative: params.row.status === 'closed',
                  positive: params.row.status === 'active',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={handleCellEditCommit}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="routes_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZRoutesList;
