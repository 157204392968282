import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useMutation } from '@apollo/client';
import { addAnnotation } from '../../mutations';
*/
import { uuid } from 'uuidv4';
import moment from 'moment';

import { useLocalStorage } from 'hooks/useStorage';

import { useMutation, useQuery } from 'urql';
import { addToolNode, updateToolNode, addNotification } from 'mutations_urql'; //getAllToolKits
import { getAllToolKits } from 'queries_urql';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Select from '@material-ui/core/Select'; //FormControl
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import  ZWizard  from 'components/core/ZWizard';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ZUniqueTextField from 'components/core/ZUniqueTextField';
import ZToolTransferList from 'components/core/ZToolTransferList';
import { plPL } from '@mui/x-data-grid';
import { getAllToolLockers } from 'queries_urql';
import { faPepperHot } from '@fortawesome/free-solid-svg-icons';
import ZToolLockerAutocomplete from 'components/core/ZToolLockerAutocomplete';

/////////////////////

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

/////////////////////

const ZAddToolKitDialog = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [parentId, setParentId] = React.useState('')
  const [parentName, setParentName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [parent, setParent] = React.useState(null)
  const [deleted, setDeleted] = React.useState(false);
  const [items, setItems] = React.useState([])
  const [tools, setTools] = React.useState([])

  const [userData, setUserData] = useLocalStorage('userData');

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
  });

  const [state, setState] = React.useState({
    name: '',
    description: '',
  });

  let names = [];

  let skip_query = false;
  if(props.static !== undefined){
    skip_query = true;
  }

  const [insertFunction] = useMutation(addToolNode);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllToolLockers,
    pause: skip_query,
  });

  console.log('ZAddToolKitDialog->result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  let rows = [];

  if(props.static === undefined){
    rows = data.tool_lockers
  } else {
    rows = props.parents;
  }


  const handleClose = () => {
    props.handleClose()
  };
  

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    var newState = state
    const guuid = uuid()
    const createdOn = moment().format()
    newState.id = guuid
    newState.createdOn = createdOn
    newState.node_type = 'ToolKit'
    newState.user_id = userData.id;
    newState.lat = parent.lat;
    newState.lng = parent.lng;
    newState.parent_id = parentId

    console.log("insertFunction->"+ JSON.stringify(newState));
    setState(newState)

    insertFunction(newState).then((result)=>{
      if(result.error){
        console.log(`ERROR creating new ToolKit: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR creating new ToolKit ${result.error}`
        });

      } else {
        insertNotificationFunction({object: {
          name: `Created new ToolKit ${state.name}`,
          description: `${userData.alias} created a new ToolKit ${state.name}`,
          role: 'admin',
        }}); 

        setAlertState({
          open: true,
          message: `Successfully created new ToolKit`
        });
      }
    });

    if(props.handleClose){
      props.handleClose();
    } 
  }

  const handleParentChange = (event,item) => {
    console.log("handleParentChange")
    console.log(item)
    console.log(event.target.value)
    //let address = item.
    setState({...state, parent_id: event.target.value.id, parent: event.target.value})
  }

  const handleToolChange = (items) => {
    console.log("handleToolChange")
    console.log(items)
    setState({...state, tools: items});
  }

  const handleLockerChange = (event,item) => {
    console.log("handleLockerChange")
    console.log(item)
    setState({...state, locker: item})
  }  

  const handleReview = () => {
    console.log("handleReview->"+ JSON.stringify(state));
  }

  ///////////////////////////

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity="error">
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>    
      <ZWizard 
        title="Create New ToolKit"
        steps={[
          'Select ToolLocker',
          'ToolKit Details',
          'Select Tool Items',
        ]}
        open={props.open} 
        onClose={props.handleClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
      >
        {/* select parent node  key={mkey} */}
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZToolLockerAutocomplete default={state.locker ? state.locker : null} onChange={handleLockerChange} />
            </Paper>
          </Box>
        </Container>  

        {/* toolkit details */}
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZUniqueTextField
                data={rows} 
                id="kitName" 
                label="ToolKit Name" 
                variant="standard"
                value={state.name ? state.name : ''}
                handleChange={(value)=>{
                  console.log("setName->"+ JSON.stringify(value));
                  setState({...state, name: value});                  
                }}
              />
            </Paper> 
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="kitDescription"
                label="Description"
                multiline
                rows={4}
                value={state.description ? state.description : ''}
                onChange={(event) =>{
                  console.log("setDescription->"+ JSON.stringify(event.target.value));
                  setState({...state, description: event.target.value});
                }} 
              />  
            </Paper> 
          </Box>
        </Container>

        {/*  enter tools in kit */}
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 1000, maxHeight: 400, my: 1, mx: 'auto', p: 2 }}>
              <div> 
                <ZToolTransferList parent={state.locker} default={state.tools ? state.tools : false} onChange={handleToolChange}/>  
              </div>
            </Paper>
          </Box>
        </Container>  

        <Container maxWidth="sm">
          <Box>
            <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow key='0'>
                      <TableCell align="right">Name:</TableCell><TableCell align="left">{state.name}</TableCell>
                    </TableRow>

                    <TableRow key='1'>
                      <TableCell align="right">Description:</TableCell><TableCell align="left">{state.description}</TableCell>
                    </TableRow>            

                    <TableRow key='2'> 
                      <TableCell align="right">Locker:</TableCell><TableCell align="left">{state.locker ? state.locker.name : ''}</TableCell>
                    </TableRow>

                    <TableRow key='3'> 
                      <TableCell align="right">Tools: [</TableCell>
                    </TableRow>                    
                    { state.tools ? 
                      state.tools.map((row,index)=>{
                        console.log(index)
                        console.log(row)
                        return (
                          <TableRow key={index + 4}> 
                            <TableCell align="right">{index + 1} --></TableCell><TableCell align="left">{row.name}</TableCell>
                          </TableRow>                          
                        )
                      })
                      :
                      <div></div>
                    }
                    <TableRow key='999'> 
                      <TableCell align="right">]</TableCell>
                    </TableRow>                       
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Container>  
      </ZWizard>
    </div>
  );
}

export default ZAddToolKitDialog;

/*
sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}


              <Select
                labelId="parent-node-select-label"
                id="parent-node-simple-select"
                value={state.parent ? state.parent.name : ''}
                onChange={handleParentChange}
              >
                {
                  rows.forEach((ppp)=>{
                    let mkey = `parent-node-${ppp.id}`
                    console.log(ppp)
                    return (
                      <MenuItem value={ppp}>{ppp.name}</MenuItem>
                    )
                  })
                }
              </Select>

*/
