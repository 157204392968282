import React from 'react';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';

import { purple, green, lightBlue, cyan } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllJobBoardEntries } from '../../queries';
import { updateClient, updateJobBoardEntry } from '../../mutations'
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllUsers } from 'queries_urql';
import { updateClient, updateUser, updateUserField  } from 'mutations_urql';
import { subscribeAllUsers } from 'subscriptions_urql';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import LinearProgress from '@material-ui/core/LinearProgress'; //LinearProgress
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //ZJobBoardDetailPane
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


//import ZUserDetailPane from 'components/core/ZUserDetailPane';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';

import ZAddZeusUserDialog from 'components/dialogs/ZAddZeusUserDialog';

import { Link as RouterLink, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';
import eventBus from 'utils/EventBus';

//////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: cyan[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////////////////////////////////

const ZZeusUsersPanel = (props) => {
  console.log('ZZeusUsersPanel->props');
  console.log(props);  

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const [userData, setUserData] = useLocalStorage('userData');

  const history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    showDetailPane: false,
    showAddUserDialog: false,
    open: false,
    activeEntry: null,
    user_id: null,
  });

  const [alert, setAlert] = React.useState({
    show: false,
    message: '',
    error: false,
  });  

  let rows = []

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>               
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/user/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>           
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'firstName',
      title: 'First Name',
      headerName: 'First Name',
      minWidth: 180,
      editable: true,
      hide: false,
    },
    {
        field: 'lastName',
        title: 'Last Name',
        headerName: 'Last Name',
        minWidth: 180,
        editable: true,
        hide: false,
      },
    {
      field: 'alias',
      title: 'Alias',
      headerName: 'Alias',
      minWidth: 250,
      editable: true,
      hide: false,
    }, 
    {
        field: 'email',
        title: 'Email',
        headerName: 'Email',
        minWidth: 250,
        editable: true,
        hide: false,
    }, 
    {
        field: 'global_permissions',
        title: 'Global Permissions',
        headerName: 'Global Permissions',
        minWidth: 250,
        editable: true,
        hide: false,
    }, 
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 500,
      editable: true,
      sortable: false,
    },
    {
      field: 'roles',
      title: 'Roles',
      headerName: 'Roles',
      minWidth: 220,
      editable: false,
      sortable: false,
      renderCell: (params)=>{
        return params.row.roles_aggregate.aggregate.count
      }      
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    }, 
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];

  //////

  const [updateUserResult, updateFunction] = useMutation(updateUserField);


  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZZeusUsersPanel::handleCellEditCommit');
      var modifiedOn = moment().format();
      var user_id = userData.id;
      var theName = "user updated";

      var _set = {}
      _set[field] = value
      _set.modifiedOn = modifiedOn;

      if(field === 'status'){
        theName = "user status updated";
        if(value === 'active'){
          theName = "user status set to active";
        }
      }

      let activity = {
        user_id: user_id,
        parent_id: id,
        name: theName,
        description: `${userData.alias} updated user ${field} to ${value}`,        
      }

      let variables = { 
        id: id, 
        _set: _set,
        activity: activity, 
      }
      console.log(variables)

      updateFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR updating user: ${result.error}`)
          setAlert({...alert, show: true, message: result.error, severity: 'error'})
        } else {
          console.log(`user update [ ${_set} ]`)
          console.log(result)
          setAlert({...alert, show: true, message: 'Successfully updated User', severity: 'success'})
        }
      }) 
    },[]
  );  

  const handleClickOpen = () => {
    if(props.handleOpen){
      console.log('ZZeusUsersPanel::handleClickOpen props.handleOpen is present handing off to parent');
      props.handleOpen()
      return;
    }
    console.log("ZZeusUsersPanel::handleClickOpen->open add sensor dialog")
    setState({...state, showAddUserDialog: true});
    console.log("ZZeusUsersPanel::handleClickOpen == "+ state.showAddUserDialog);
  };
  
  const handleClose = () => {
    setState({...state, showAddUserDialog: false});
  };

  const handleCloseDetailPane = () => {
    setState({...state, showDetailPane: false});
  };

  console.log('props')
  console.log(props)

  let title = 'View User';

  if(state.activeEntry !== null){
    title = `View User: ${state.activeEntry.alias}`;
  }

  ////////////////////////////

  //use subscription
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return response;
  }

  const [subResult] = useSubscription({ 
    query: subscribeAllUsers, 
    pause: !window.navigator.onLine, 
  }, handleSubscription); 

  const [ result, reexecuteQuery ] = useQuery({
    query: getAllUsers,
    //pause: window.navigator.onLine
  });

  console.log('res');
  console.log(subResult)  

  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = result.data;
    fetching = result.fetching;
    error = result.error;
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  rows = data.zeus_zeus_user.slice(0)

  console.log("ZZeusUsersPanel->rows")
  console.log(rows)

  ////////////////////////////

  return (
    <div className={classes.root}>
      {/* <ZUserDetailPane isOpen={state.showDetailPane} handleClose={handleCloseDetailPane} user={state.activeEntry} static/> */}
      {/* <ZAddJobBoardEntryDialog open={showDialog} handleClose={handleClose}/> */}
      <ZAddZeusUserDialog open={state.showAddUserDialog} parent={props.parent} handleClose={()=>setState({...state, showAddUserDialog: false})} static/>
      {/* alert component */} 
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alert.show}
        key="alert_button"
        onClose={()=>{
            setAlert({...alert, show: false, message: '', severity: 'info'})
        }}
      >
        <Alert severity={alert.severity} 
          onClose={()=>{
            setAlert({...alert, show: false, message: '', severity: 'info'})
        }}>
          {alert.message}
        </Alert>
      </Snackbar>  
      {/* main component */}       
      <Card variant="outlined" elevation={2}>
        <CardContent>
        <Box display="flex" bgcolor="#5c6bc0">
          <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          {props.title}
          </Typography>
          <IconButton
            onClick={handleClickOpen}
            style={{color:"white"}}
            color="inherit"
            aria-label="add row"
            edge="start"
            data-tip="create new user"
            data-for="users_panel_tooltip" 
          >
            <AddCircleOutlineIcon/>
          </IconButton>
        </Box>
        <DataGrid autoHeight
          title="Users"
          columns={columns}
          rows={rows}
          pageSize={20}
          onCellEditCommit={handleCellEditCommit}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick={false}
          getRowClassName={(params) =>
            clsx('super-app', {
              positive: params.row.status === 'active',
              negative: params.row.status !== 'active',
            })
          }
          options={{
            toolbar: true,
            filtering: true,
            search: true,
            grouping: true,
            exportButton: true,
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          filterModel={props.filter}
          onRowDoubleClick={(params, evt) => {
            let entry = params.row;
            console.log("selected user")
            console.log(entry)
            setState({...state, activeEntry: entry, showDetailPane: true});
          }}
        />
        </CardContent>
      </Card>
      <ReactTooltip id="users_panel_tooltip"/>
    </div>
  );  
}
  
export default ZZeusUsersPanel;


