import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllClients, getGateway, getAllGateways,  getQuotesForClient } from 'queries_urql';
import { addClient, updateGateway } from 'mutations_urql';
import { subscribeAllActiveJobBoardEntries, subscribeGateway } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';

import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZGatewayBox from 'components/boxes/ZGatewayBox';

import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';
import ZSensorsList from 'components/lists/ZSensorsList';// ZSensorsList
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZTicketsList from 'components/lists/ZTicketsList';//ZTicketsList
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZAddTicketDialog from 'components/dialogs/ZAddTicketDialog';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';
import ZMeasurementList from 'components/lists/ZMeasurementList';
import ZMeasurementPlot from 'components/charts/ZMeasurementPlot'; 
import ZMeasurementPlotly from 'components/charts/ZMeasurementPlotly';
import ZSensorRMSPlotly from 'components/charts/ZSensorRMSPlotly'; //ZSensorRMSPlotly

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import { downloadObjectFromAzure } from 'azure/AzureBlobAPI';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ReactTooltip from 'react-tooltip';
import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 400,
    height: 800,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

//////////////////////////////////////////////

const ZGatewayView = (props) => {
  const theme = useTheme();
  console.log('ZGatewayView->props');
  console.log(props);

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const classes = useStyles();
  let { id } = useParams();
  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    selectedData: undefined,
    showAnnotationDialog: false,
    showTicketDialog: false,
  });
  
  const [tabIndex, setTabIndex] = React.useState(0);

  let row = undefined;
  let selectedData = [];

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false});
  }  

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };


  const [updateGatewayResult, updateFunction] = useMutation(updateGateway);

  /////////////////
  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_tool_by_pk
    return response
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('setting up subscription')

  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeGateway,
    variables: { id },
    pause: !window.navigator.onLine,
    //pause: state.showBookingDialog,
  }, handleSubscription); 

  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getGateway,
    variables: { id },
    //pause: window.navigator.onLine,
  });


  const [ clientsResult, reexecuteClientsQuery ]  = useQuery({
    query: getAllClients,
    //pause: window.navigator.onLine,
  }); 
  
  const [ gatewaysResult, reexecuteGatewaysQuery ]  = useQuery({
    query: getAllGateways,
    //pause: window.navigator.onLine,
  }); 

  let { data, fetching, error } = subResult;

  let { data: clientList, fetching: clientFetching, error: clientError } = clientsResult;

  let { data: gatewaysList, fetching: gatewaysFetching, error: gatewaysError } = gatewaysResult;

  if(!window.navigator.onLine){
    console.log('not online')
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }  

  if (fetching || clientFetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log('ZGatewayView->data');
  console.log(data);

  row = data.zeus_gateway_by_pk  

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showAnnotationDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )  
  
  let addTicketAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showTicketDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )   

  let loadDataAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={(event,selectedIDs)=>{
          console.log('ZSensorView->selectedIDs')
          console.log(selectedIDs)
          /*
          if(row.xpath){
            setState({...state, rawData: downloadObjectFromAzure(row.xpath)})
          } else {
            setState({...state, rawData: downloadObjectFromAzure(row.apath)})
          }
          */
        }}
      >
        <CloudDownloadIcon />
      </IconButton>      
    </div>    
  )

  let toprow = (
    <Row>
      <Col xs={6}>
        <ZGatewayBox id='gateway_box' 
          gateway={row}
          name={row.name} 
          description={row.description} 
          annotations={row.annotations}
          clients={clientList.zeus_client}
          gateways={gatewaysList.zeus_gateway}
          refresh={executeSubscription}
          fullBox
          showImages
          static
        />
      </Col>
      <Col xs={6}>     
        { row.activities ?
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar aria-label="jobboard_entry">
                    <TimelineIcon />
                  </Avatar>
                }
                title='Timeline'
                titleTypographyProps={{variant:'h5'}}
              />
              <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                <ZActivitiesTimeline items={row.activities} />
              </Paper>  
            </Card> 
            : <div/>
          }        
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`gateway/${row.id}/attachments`} multiple static/>      
      </Col>     
    </Row>    
  )

  //////////////////////////
  console.log("selectedData")
  console.log(state.selectedData)

  return (
    <div>
      <Container fluid>
        {toprow}
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
            <ZAddTicketDialog open={state.showTicketDialog} parent={row} handleClose={()=>setState({...state, showTicketDialog: false})} />
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Sensors" />
                  <Tab label="Open Tickets" />
                  <Tab label="Closed Tickets" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.asset} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZSensorsList key='sensors_panel' nameW={180} aliasW={350} descriptionW={450} pageSize={20} client={row} parent={row.asset} action={addNoteAction} items={row.sensors} editable static/>
                  </Box>
                )}                
                {tabIndex === 2 && (
                  <Box>                   
                    <ZTicketsList key='open_tickets_panel' title="Open Tickets" action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box>                   
                    <ZTicketsList key='closed_tickets_panel' title="Closed Tickets" action={<div></div>} data={row.closed_tickets} static/>
                  </Box>
                )}                
              </Box>              
            </Paper>
          </Col>
        </Row> 
      </Container>
    </div>
  )
}

export default ZGatewayView;


//{/* <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`sensor/${row.id}/attachments`} multiple static/> */}

/*

              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.locker} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Open Tickets" action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Closed Tickets" action={<div></div>} data={row.closed_tickets} static/>
                  </Box>
                )}                
              </Box>


      <Col xs={6}>     
        <ZMeasurementList key='measurement_panel' sensor={row} data={row.measurements}  static/>
      </Col>

        <Row><br/></Row>
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="sensor_images" className={classes.image_avatar}>
                  <PhotoLibraryIcon />
                </Avatar>
              }
              title='Images'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZImageListHorizontal key='image_list' parent_id={row.id} path={`sensor/${row.id}/images`} data={row.images} /> 
            </Paper>  
          </Card> 


*/

