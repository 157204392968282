// getRMSMeasurementsForSensor
import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import Plot from 'react-plotly.js';
import ZTitle from 'components/core/ZTitle';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { monthlySalesQuery, monthlySupplierSalesQuery } from 'queries';
import { addQuotation, updateSalesDocument } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getRMSMeasurementsForSensor } from 'queries_urql';
import { subscribeRMSMeasurementsForSensor  } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'; 
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import Moment from 'react-moment';
import moment from 'moment';

//////////////////////////
/*
props {
  width: number
  height: number
  year: number
  funnel: string
  percentages: boolean
}
*/
//////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  snackbarStyle: {
    backgroundColor: "lightyellow",
    minHeight: 400,
  },
}));

/////////////////////////////
const ZSensorRMSPlotly = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  ////////////////////////////////////

  let x_rms = []
  let y_rms = []
  let z_rms = []  
  let x_axis = []
  let data_traces = []

  //let limit = props.limit ? props.limit : 100

  const [state, setState] = React.useState({
    limit: props.limit ? props.limit : 25,
  });  

  //use subscription
  const handleSubscription_X = (messages = [], response) => {
    console.log('handleSubscription_X::response')
    console.log(response)
    return response;
  }

  const handleSubscription_Y = (messages = [], response) => {
    console.log('handleSubscription_Y::response')
    console.log(response)
    return response;
  }

  const handleSubscription_Z = (messages = [], response) => {
    console.log('handleSubscription_Z::response')
    console.log(response)
    return response;
  }

  const [subResult_X] = useSubscription({ 
    query: subscribeRMSMeasurementsForSensor, 
    variables: { "sensor_id": props.sensor.id, "mAxis": "x", "limit": state.limit },
    pause: props.realtime ? false : true,
  }, handleSubscription_X);  
  
  const [subResult_Y] = useSubscription({ 
    query: subscribeRMSMeasurementsForSensor, 
    variables: { "sensor_id": props.sensor.id, "mAxis": "y", "limit": state.limit },
    pause: props.realtime ? false : true,
  }, handleSubscription_Y);  

  const [subResult_Z] = useSubscription({ 
    query: subscribeRMSMeasurementsForSensor, 
    variables: { "sensor_id": props.sensor.id, "mAxis": "z", "limit": state.limit },
    pause: props.realtime ? false : true,
  }, handleSubscription_Z);  


  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getRMSMeasurementsForSensor,
    variables: { "sensor_id": props.sensor.id, "limit": state.limit  },
    pause: props.realtime ? props.realtime : false,
  });


  console.log('res_x');
  console.log(subResult_X)  

  console.log('res_y');
  console.log(subResult_Y)  

  console.log('res_z');
  console.log(subResult_Z)  

  let { data: dataX, fetching: fetchingX, error: errorX } = subResult_X;
  let { data: dataY, fetching: fetchingY, error: errorY } = subResult_Y;
  let { data: dataZ, fetching: fetchingZ, error: errorZ } = subResult_Z;

  let { data, fetching, error } = queryResult

  /*
  if(!window.navigator.onLine){
    data = result.data;
    fetching = result.fetching;
    error = result.error;
  }  

  let { data, fetching, error } = queryResult
  */

  if(props.realtime){
    if (fetchingX || fetchingY || fetchingZ) return (<h2>Loading... <CircularProgress /></h2>);

    if (errorX || errorY || errorZ){
      if (errorX) return `Error! ${errorX.message}`;
      if (errorY) return `Error! ${errorY.message}`;
      if (errorZ) return `Error! ${errorZ.message}`;
    }    
    
    console.log('ZSensorView->dataX');
    console.log(dataX);
  
    console.log('ZSensorView->dataY');
    console.log(dataY);
  
    console.log('ZSensorView->dataZ');
    console.log(dataZ);
  
    x_axis = dataX.zeus_measurement_rms_view.reverse().map((x_val,index)=>{
      return moment.unix(x_val.timestamp).format("YYYY-MM-DD HH:mm:ss")
      //return index;
    })
  
    x_rms = dataX.zeus_measurement_rms_view.map((x_val)=>{
      return x_val.rms;
    }) 
  
    const trace1 = {
      x: x_axis.slice(0),
      y: x_rms,
      type: 'lines+markers',
      name: "x-rms",
    }  
  
    y_rms = dataY.zeus_measurement_rms_view.reverse().map((y_val)=>{
      return y_val.rms;
    })
  
    const trace2 = {
      x: x_axis.slice(0),
      y: y_rms,
      type: 'lines+markers',
      name: "y-rms",
    }    
  
    z_rms = dataZ.zeus_measurement_rms_view.reverse().map((z_val)=>{
      return z_val.rms;
    }) 
  
    const trace3 = {
      x: x_axis.slice(0),
      y: z_rms,
      type: 'lines+markers',
      name: "z-rms",
    }   
  
    data_traces = [trace1, trace2, trace3];

  } else {
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;

    x_axis = data.x_rms.reverse().map((x_val,index)=>{
      return moment.unix(x_val.timestamp).format("YYYY-MM-DD HH:mm:ss")
      //return index;
    })
  
    x_rms = data.x_rms.map((x_val)=>{
      return x_val.rms;
    }) 
  
    const trace1 = {
      x: x_axis.slice(0),
      y: x_rms,
      type: 'lines+markers',
      name: "x-rms",
    }  
  
    y_rms = data.y_rms.reverse().map((y_val)=>{
      return y_val.rms;
    })
  
    const trace2 = {
      x: x_axis.slice(0),
      y: y_rms,
      type: 'lines+markers',
      name: "y-rms",
    }    
  
    z_rms = data.z_rms.reverse().map((z_val)=>{
      return z_val.rms;
    }) 
  
    const trace3 = {
      x: x_axis.slice(0),
      y: z_rms,
      type: 'lines+markers',
      name: "z-rms",
    }   
  
    data_traces = [trace1, trace2, trace3];   
  }




  ////////////////////////////////////

  return (
    <Card>
      <ZTitle>{props.sensor ? `RMS Trend for ${props.sensor.name} - ${props.sensor.alias !== null ? props.sensor.alias : ''}` : "RMS Trend"}</ZTitle>
      <FormControl className={classes.formControl}>
        <InputLabel id="channel-select-label">Limit</InputLabel>
        <Select
          labelId="limit-label"
          id="limit-select"
          value={state.limit}
          onChange={(event)=>setState({...state, limit: event.target.value})}
        >
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={400}>400</MenuItem>
          <MenuItem value={800}>800</MenuItem>
          <MenuItem value={2000}>2000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
        </Select> 
      </FormControl>   
      <Paper>
        <Plot
          data={data_traces}
          layout={ {width: props.width ?? 1200, height: props.height ?? 400, title: `last ${state.limit} measurements`} }
        />
      </Paper>
    </Card>  
  );
}


export default ZSensorRMSPlotly;


