
export const subscribeAllCashFlowEntries = `

`;


export const subscribeClient = `
subscription subscribeClient($id: uuid = "") {
  zeus_client_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    address(path: "label")
    region: address(path: "region.province")
    active_contacts: contacts(order_by: {firstName: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      firstName
      lastName
      email
      status
    }
    all_contacts: contacts(order_by: {firstName: asc}) {
      __typename
      id
      firstName
      lastName
      email
      status
    }    
    gateways(order_by: {name: asc}) {
      __typename
      id
      name
      alias
      description
      createdOn
      status    
      sensors_aggregate {
        aggregate {
          count
        }
      }   
      parent {
        id
        name
        description
        status
      }           
    }  
    sensors_aggregate {
      aggregate {
        count
      }
    }     
    sensors(order_by: {name: asc}) {
      id
      name
      alias
      orientation
      description
      createdOn
      modifiedOn
      lastMeasurement
      status
      measurements_aggregate(where: {dataType: {_eq: "collection"}}) {
        aggregate {
          count
        }
      }
    } 
    paired_sensors: sensors(where: {gateway_id: {_is_null: false}}, order_by: {name: asc}) {
      id
      name
      alias
      description
      createdOn
      modifiedOn
      lastMeasurement
      status
      gateway {
        id
        name
        alias
        description
        createdOn
        status
      }
      measurements_aggregate(where: {dataType: {_eq: "collection"}}) {
        aggregate {
          count
        }
      }
    }  
    sensors_to_trigger(order_by: {createdOn: asc}) {
      __typename
      id
      name
      channel
      rms
      status
      createdOn
      input_sensor {
        __typename
        id
        name
        alias
        description
        status        
      }
      output_sensor {
        __typename
        id
        name
        alias
        status
        description
        lastMeasurement
        createdOn      
      }
    }      
    analysis_blocs(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      model_path
      analysis_type
      default_settings
      analysis_bloc_type {
        __typename
        id
        name
        alias
        default_settings
      }
    }        
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    } 
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    assets_aggregate {
      aggregate {
        count
      }
    }
    active_routes: routes_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
    jobs(order_by: {createdOn: desc}){
      __typename
      id
      name
      description
      createdOn
      quoteAmount
      status
      scheduled
      poAmount
      invoiceNum
      invoiceDate
      invoiceAmount
      customerPO
      createdBy
      companyName
      completedOn
      primacQuoteName
      primacPO
      isTravelBooked
      serviceType
      technician {
        __typename
        id
        alias
      }  
      purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate
      }            
    }
    quotations: sales_documents(where: { document_type: {_eq: "Quotation"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      amount
      funnel
      description
      status
      createdOn
      modifiedOn
      closedOn
      acceptedOn
      preparedBy
      revision
      document_type
      contact {
        __typename
        id
        firstName
        lastName
        status
        createdOn
      }
    } 
    purchaseOrders: sales_documents(where: { document_type: {_eq: "PurchaseOrder"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      amount
      funnel
      description
      status
      createdOn
      modifiedOn
      closedOn
      acceptedOn
      preparedBy
      revision
      document_type
      contact {
        __typename
        id
        firstName
        lastName
        status
        createdOn
      }
      invoices: sales_documents(where: { document_type: {_eq: "Invoice"}}, order_by: {createdOn: desc}) {
        __typename
        id
        name
        amount
        funnel
        description
        status
        createdOn
        modifiedOn
        closedOn
        acceptedOn
        preparedBy
        revision
        document_type
        contact {
          __typename
          id
          firstName
          lastName
          status
          createdOn
        }
      }        
    }  
    invoices: sales_documents(where: { document_type: {_eq: "Invoice"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      amount
      funnel
      description
      status
      createdOn
      modifiedOn
      closedOn
      acceptedOn
      preparedBy
      revision
      document_type
      contact {
        __typename
        id
        firstName
        lastName
        status
        createdOn
      }
    }   
    pending_quotations_aggregate: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    routes(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      modifiedOn
      client_node {
        __typename
        id
        name
        description
      }
    }
    client_nodes(order_by: {name: asc}) {
      __typename
      id
      name
      description
      createdOn
      status
      active_assets: assets_aggregate(where: {status: {_eq: "active"}}) {
        aggregate {
          count
        }
      }  
      reportable_assets: assets_aggregate(where: {_or: [{currentCondition: {_eq: "Reportable"}}, {currentCondition: {_eq: "Unsatisfactory"}}, {currentCondition: {_eq: "Unacceptable"}}]}) {
        aggregate {
          count
        }
      }        
    }
    assets(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      currentCondition
      criticality
      component_locations {
        id
        name
        description
        createdOn
        status
        component {
          id
          name
          description
          createdOn
          status
        }
      }
      surveys(order_by: {theDate: desc}, limit: 12) {
        __typename
        id
        theDate
        status
        severity
        recommendations
        findings_from_work_completed
        createdOn
        summary
        component {
          __typename
          id
          name
        }
        technician {
          __typename
          id
          alias
        }
      }      
    }
    asset_surveys {
      __typename
      id
      summary
      status
      theDate
      recommendations
      severity
      createdOn
      asset {
        __typename
        id
        name
        description
        currentCondition
        status
      }
    }
  }
}`;

export const subscribeSurveysForAsset = `
subscription subscribeSurveysForAsset($asset_id: uuid = "") {
  zeus_asset_survey(where: {asset_id: {_eq: $asset_id}}, order_by: {name: asc}) {
    __typename
    id
    severity
    recommendations
    notification_num
    modifiedOn
    summary
    status
    theDate
    createdOn
    findings_from_work_completed
    asset {
      __typename
      id
      name
      description
      currentCondition
      createdOn
      status
    }
  }
}
`;


export const subscribeAssetSurvey = `
subscription subscribeAssetSurvey($id: uuid = "") {
  zeus_asset_survey_by_pk(id: $id) {
    __typename
    id
    severity
    recommendations
    notification_num
    modifiedOn
    summary
    status
    theDate
    createdOn
    findings_from_work_completed
    condition_value
    condition {
      __typename
      id
      name
      descriptors {
        __typename
        id
        name       
      }
    }
    descriptor {
      __typename
      id
      name
    }    
    asset {
      __typename
      id
      name
      description
      criticality
      currentCondition
      createdOn
      status
      client {
        __typename
        id
        name       
      }
      client_node{
        __typename
        id
        name
        node_type{
          __typename
          name
        }
      }
    }
    images (order_by: {createdOn: desc}, limit: 10) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      status
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
  }
}
`;


export const subscribeContact = `
subscription subscribeContact($id: uuid = "") {
  zeus_contact_by_pk(id: $id) {
    id
    name
    firstName
    lastName
    email
    description
    createdOn
    phone
    client {
      id
      name
    }
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    activities(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
      status
    }
  }
}`;

export const subscribeContact_1 = `
subscription subscribeContact($id: uuid = "") {
  zeus_contact_by_pk(id: $id) {
    id
    name
    firstName
    lastName
    email
    description
    createdOn
    phone
    client {
      id
      name
    }
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    activities(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
      status
    }
    accepted_quotations_aggregate: sales_document_aggregate(where: {_and: { status: {_eq: "accepted"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }   
    pending_quotations_aggregate: sales_documents_aggregate(where: {_and: { status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    quotations: sales_documents(where: {_and: { document_type: {_eq: "Quotation"}, status: {_eq: "pending"}}}, order_by: {createdOn: desc}) {
      id
      name
      description
      amount
      acceptedOn
      closedOn
      stage
      status
    }
    accepted_quotations: sales_documents(where: {_and: { document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}}}, order_by: {createdOn: desc}) {
      id
      name
      description
      amount
      acceptedOn
      closedOn
      stage
      status
    }
    lost_quotations: sales_documents(where: {_and: { document_type: {_eq: "Quotation"}, status: {_eq: "closed"}}}, order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
      modifiedOn
      status
      amount
    }
    lost_quotations_aggregate: sales_documents_aggregate(where: {_and: { status: {_eq: "closed"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }     
  }
}`;

export const subscribeSalesDoc = `
subscription subscribeSalesDoc($id: uuid = "") {
  zeus_sales_document_by_pk(id: $id) {
    __typename
    id
    name
    amount
    funnel
    description
    status
    theDate
    createdOn
    modifiedOn
    closedOn
    acceptedOn
    preparedBy
    revision
    document_type
    jobBoardEntry{
      __typename
      id
      name
      companyName
      description
      createdOn    
      modifiedOn 
      status
    }    
    parent {
      __typename
      id
      name
      description
      createdOn    
      modifiedOn  
      status
      document_type
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    contact {
      __typename
      id
      name
      firstName
      lastName
      email
      createdOn
      modifiedOn
    }
    client {
      __typename
      id
      name
      description
      status
      createdOn
      modifiedOn
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }   
    invoices: sales_documents(where: {document_type: {_eq: "Invoice"}}, order_by:{createdOn:desc}) {
      __typename
      id
      name
      description
      amount
      createdOn
      document_type
      status
      theDate
      closedOn
      acceptedOn
      parent {
        __typename
        id
        name
        description
      }
    }
    purchaseOrders: sales_documents(where: {document_type: {_eq: "PurchaseOrder"}}, order_by:{createdOn:desc}) {
      __typename
      id
      name
      description
      amount
      createdOn
      document_type
      status
      theDate
      closedOn
      acceptedOn
    }  
    quotations: sales_documents(where: {document_type: {_eq: "Quotation"}}, order_by:{createdOn:desc}) {
      __typename
      id
      name
      description
      amount
      createdOn
      document_type
      status
      theDate
      closedOn
      acceptedOn
    }           
  }
}`;


export const subscribeToolLockers = `
query subscribeToolLockers {
  tool_lockers: zeus_tool_node( where: {node_type: {_eq: "ToolLocker"}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    child_nodes(where: {_and: { node_type: {_eq: "ToolKit"}}}){
      __typename
      id
      name
      createdOn
      status          
    }
    active_tools: locker_tools {
      __typename
      id
      name
      description
      createdOn
      status    
    }    
  }
  tool_kits: zeus_tool_node( where: {node_type: {_eq: "ToolKit"}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    status
    lat
    lng
    lastCheckedIn
    lastCheckedOut     
  }  
  zeus_tool(order_by: {createdOn: desc}){
    __typename
    id
    name
    description
    status
    createdOn
    lat
    lng
    lastCheckedIn
    lastCheckedOut
  }
}`;


export const subscribeNotifications = `
subscription subscribeNotifications($roles: [String!] = {}){
  zeus_notification(where: {role: {_in: $roles}}, order_by: {createdOn: desc}){
    __typename
    id
    name
    description
    role
    createdOn
    status   
  }
}
`;


export const subscribeOpenTickets = `
subscription subscribeOpenTickets {
  zeus_ticket(where: {status: {_eq: "active"}}, order_by: {createdOn: desc}){
    __typename
    id
    name
    description
    resolution
    closedOn
    createdOn
    status  
    user {
      __typename
      id
      firstName
      lastName
      alias
      email
      description
      status
      createdOn 
    } 
    closer {
      __typename
      id
      firstName
      lastName
      alias
      email
      description
      status
      createdOn
    }
    tool {
      __typename
      id
      name
      description
      status
      createdOn
      lat
      lng
      lastCheckedIn
      lastCheckedOut
    }
    toolNode {
      __typename
      id
      name
      description
      status
      createdOn
      lat
      lng
      lastCheckedIn
      lastCheckedOut
    }
  }
}
`;


export const subscribeAllClients = `
subscription subscribeAllClients {
  zeus_client(where: {status: {_eq: "active"}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    address(path: "label")
    region: address(path: "region.province")
    assets_aggregate {
      aggregate {
        count
      }
    }
    active_routes: routes_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
    pending_quotations: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
  }
}`;

export const subscribeAllMeasurements = `
subscription subscribeAllMeasurements {
  zeus_measurement(order_by: {createdOn: desc}) {
    __typename
    id
    timestamp
    mAxis
    apath
    xpath
    rms
    description
    status
    createdOn
  }
}`;

export const subscribeWaveformsForSensor = `
subscription subscribeWaveformsForSensor($sensor_id: uuid = "") {
  zeus_measurement(where: {_and: {sensor_id: {_eq: $sensor_id}, dataType: {_eq: "collection"}}}, order_by: {timestamp: desc}, limit: 100) {
    __typename
    id
    timestamp
    dataType
    sampleRate
    sensorType
    bucket
    mAxis
    mType
    status
    apath
    xpath
    rms
    createdOn
    analysis_results(order_by: {createdOn: desc}) {
      id
      name
      description
      serialNumber
      analysis_type
      createdOn
    }
  }
}
`;

//subscribeMeasurementsForSensor, subscribeWaveformsForSensor 

export const subscribeMeasurementsForSensor = `
subscription subscribeMeasurementsForSensor($sensor_id: uuid = "") {
  zeus_measurement(where: {_and: {sensor_id: {_eq: $sensor_id}, dataType: {_eq: "measurement"}}}, order_by: {timestamp: desc}, limit: 100) {
    id
    mAxis
    apath
    xpath
    timestamp
    createdOn
    bucket
  }
}
`;


export const subscribeSensorStateUpdate = `
subscription subscribeSensorStateUpdate($id: uuid = "") {
  zeus_sensor_by_pk(id: $id) {
    __typename
    id
    name
    alias
    serialNumber
    status
    lastMeasurement
    lastUpdate
    stateupdate
  }
}
`;


export const subscribeSensor = `
subscription getSensor($id: uuid = "") {
  zeus_sensor_by_pk(id: $id) {
    __typename
    id
    name
    alias
    serialNumber
    manufacturer
    orientation
    description
    asset_id
    gateway_id
    measurement_point_id
    status
    createdOn
    nfcTag
    lastMeasurement
    lastUpdate
    stateupdate
    user_defined_fields
    component {
      __typename
      id
      name
      description
      status
      component_type {
        __typename
        id
        name
        description
      }
    }
    component_location {
      __typename
      id
      name
      description
      status
    }     
    analysis_blocs(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      default_settings
      model_path
    }      
    sensors_to_trigger(where: {status: {_eq: "active"}}, order_by: {createdOn: asc}) {
      __typename
      id
      name
      channel
      rms
      status
      waitFor
      dailyWaitFor
      createdOn
      input_sensor {
        __typename
        id
        name
        alias
        description
        status        
      }          
      client {
        __typename
        id
        name
      }
      asset {
        __typename
        id
        name
      }
      component {
        __typename
        id
        name
      }
      component_location {
        __typename
        id
        name
      }
      output_sensors {
        __typename
        id
        name
        alias
        serialNumber
        status
        description
        lastMeasurement
        createdOn
        measurements_aggregate {
          aggregate {
            count
          }
        }
        measurement_point {
          __typename
          id
          name
          description
          status
        }
        component_location {
          __typename
          id
          name
          description
          status
        }
        asset {
          __typename
          id
          name
          description
          status
        }
        client {
          __typename
          id
          name
          description
          status
        }         
      }
    }         
    activities(order_by: {createdOn: desc}, limit: 20)  {
      __typename
      id
      name
      description
      createdOn
    }
    gateway {
      __typename
      id
      name
      alias
      description
    }
    asset {
      __typename
      id
      name
      description
      status
      client {
        __typename
        id
        name
        description
      }       
      components(order_by: {name: asc}) {
        __typename
        id
        name
        description
        status
      }
      component_locations {
        __typename
        id
        name
        description
        component {
          __typename
          id
          name
          description
          status
          component_type {
            __typename
            id
            name
            description
          }
        }
      }
    }    
    client {
      __typename
      id
      name
      description
      analysis_blocs(order_by: {name: asc}) {
        __typename
        id
        name
        description
        status
        createdOn
        default_settings
        model_path
      } 
      sensors(order_by: {name: asc}) {
        id
        name
        alias
        orientation
        description
        createdOn
        modifiedOn
        lastMeasurement
        status
        measurements_aggregate(where: {mAxis: {_eq: "xyz"}}) {
          aggregate {
            count
          }
        }
      }               
    }
    measurements_aggregate(where: {mAxis: {_eq: "xyz"}}) {
      aggregate {
        count
      }
    }
    measurements(where: {dataType: {_eq: "collection"}}, order_by: {timestamp: desc}, limit: 1000) {
      __typename
      id
      timestamp
      dataType
      sampleRate
      sensorType      
      bucket
      mAxis
      mType
      status
      apath
      xpath
      rms
      is_running
      createdOn
      analysis_results(order_by: {createdOn: desc})  {
        id
        name
        description
        serialNumber
        analysis_type
        createdOn
      }      
    } 
    annotations {
      __typename
      id
      name
      createdOn
      description
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    } 
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    open_tickets: tickets(where: {status: {_eq: "open"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    closed_tickets: tickets(where: {status: {_eq: "closed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
    }        
  }
}`;


export const subscribeSensorBox = `
subscription subscribeSensorBox($id: uuid = "") {
  zeus_sensor_by_pk(id: $id) {
    __typename
    id
    name
    alias
    serialNumber
    description
    asset_id
    gateway_id
    measurement_point_id
    status
    createdOn
    gateway {
      __typename
      id
      name
      description
    }
    asset {
      __typename
      id
      name
      description
    }
    measurements_aggregate {
      aggregate {
        count
      }
    }
    measurements(order_by: [{timestamp: desc},{mAxis: asc}], limit: 100) {
      __typename
      id
      timestamp
      bucket
      mAxis
      mType
      status
      apath
      xpath
      rms
      createdOn
    }
  }
}`;


export const subscribeAllSensors = `
subscription subscribeAllSensors {
  zeus_sensor(order_by: {lastMeasurement: desc}) {
    __typename
    id
    name
    alias
    serialNumber
    description
    manufacturer
    lastMeasurement
    asset_id
    gateway_id
    measurement_point_id
    status
    createdOn
    gateway {
      __typename
      id
      name
      alias
      description
    }
    asset {
      __typename
      id
      name
      description
    }
    measurements_aggregate(where: {mAxis: {_eq: "xyz"}}) {
      aggregate {
        count
        max {
          timestamp
        }        
      }
    }
  }
}`;


export const subscribeAllGateways = `
subscription subscribeAllGateways {
  zeus_gateway {
    id
    name
    alias
    description
    manufacturer
    createdOn
    status
    client {
      id
      name
      description
    }
    parent {
      id
      name
      description
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const subscribeGateway = `
subscription subscribeGateway($id: uuid = "") {
  zeus_gateway_by_pk(id: $id) {
    id
    name
    alias
    description
    manufacturer
    createdOn
    device_info
    status
    client {
      id
      name
      description
      status
      client_nodes(order_by: {name: asc}) {
        id
        name
        description
        status
      }      
    }
    parent {
      id
      name
      description
    }
    sensors(order_by: {name: asc}) {
      id
      name
      alias
      orientation
      description
      createdOn
      modifiedOn
      lastMeasurement
      status
      measurements_aggregate(where: {mAxis: {_eq: "xyz"}}) {
        aggregate {
          count
        }
      }
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }
    annotations {
      __typename
      id
      name
      createdOn
      description
    }    
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    open_tickets: tickets(where: {status: {_eq: "open"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    closed_tickets: tickets(where: {status: {_eq: "closed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
    }  
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }       
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }        
  }
}`;


export const subscribeGatewayBox = `
subscription subscribeGatewayBox($id: uuid = "") {
  zeus_gateway_by_pk(id: $id) {
    __typename
    id
    name
    alias
    description
    client_id
    parent_id
    status
    createdOn
    client {
      __typename
      id
      name
      description
    }
    parent {
      __typename
      id
      name
      description
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const subscribeRMSMeasurementsForSensor = `
subscription subscribeRMSMeasurementsForSensor($sensor_id: uuid = "", $mAxis: String = "x", $offset: Int = 0, $limit: Int = 100) {
  zeus_measurement_rms_view(offset: $offset, limit: $limit, order_by: {timestamp: desc}, where: {_and: [{sensor_id: {_eq: $sensor_id}}, {mAxis: {_eq: $mAxis}}]}) {
    timestamp
    rms
  } 
}`;


export const subscribeTicket = `
subscription subscribeTicket($id: uuid = "") {
  zeus_ticket_by_pk(id: $id) {
    __typename
    id
    name
    description
    resolution
    createdOn
    closedOn
    status
    parent_id
    tool {
      __typename
      id
      name
    }
    toolNode {
      __typename
      id
      name
    }    
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    images {
      __typename
      id
      name
      description
      src
      lng
      lat
      img
      createdOn
      user {
        __typename
        id
        alias
      }
    }
    images_aggregate {
      aggregate {
        count
      }
    }
    user {
      __typename
      id
      alias
    }
    closer {
      __typename
      id
      alias
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      parent_id
      user {
        __typename
        id
        alias
      }      
    }
  }
}
`;

export const subscribeTool = `
subscription subscribeTool($id: uuid = "") {
  zeus_tool_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    nfcTag
    locker {
      __typename
      id
      name
      description
      node_type
      status
      location
      address
      lat
      lng
      createdOn
    }    
    parents {
      node {
        __typename
        id
        name
        description
        node_type
        status   
        createdOn   
      }
    } 
    usage (order_by: {createdOn: desc}) {
      __typename
      id
      status
      createdOn
      description
      lat
      lng
      job {
        __typename
        id
        name
        description
        companyName
        status
      }
      user {
        __typename
        id
        alias
        firstName
        lastName
      }
      locations (order_by: {createdOn: asc}) {
        __typename
        id
        lat
        lng
        createdOn
      }    
    } 
    usage_count: usage_aggregate(where: {status: {_eq: "check-out"}}) {
      aggregate {
        count
      }
    }
    usage_aggregate {
      aggregate {
        count
      }
    }   
    images (order_by: {createdOn: desc}, limit: 10) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    annotations {
      __typename
      id
      name
      createdOn
      description
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      user {
        __typename
        id
        alias
      }
    }
    open_tickets: tickets(where: {status: {_eq: "active"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      resolution
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
      closer {
        __typename
        id
        alias
      }
      tool {
        __typename
        id
        name
      }
      toolNode {
        __typename
        id
        name
      }       
    }
    closed_tickets: tickets(where: {status: {_eq: "completed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      resolution
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
      closer {
        __typename
        id
        alias
      }
      tool {
        __typename
        id
        name
      }
      toolNode {
        __typename
        id
        name
      }       
    }    
    attachments(order_by: {createdOn: desc}){
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      url
      src
      parent_id
      user {
        __typename
        id
        alias
      }
    }      
  } 
}`;


export const subscribeToolBox = `
subscription subscribeToolBox($id: uuid = "") {
  zeus_tool_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    nfcTag
    locker {
      __typename
      id
      name
      description
      node_type
      status
      location
      address
      lat
      lng
      createdOn
    }    
    usage_count: usage_aggregate(where: {status: {_eq: "check-out"}}) {
      aggregate {
        count
      }
    }
    usage_aggregate {
      aggregate {
        count
      }
    }       
  } 
}`;


export const subscribeToolNode = `
subscription subscribeToolNode($id: uuid = "") {
  zeus_tool_node_by_pk(id: $id) {
    __typename
    id
    name
    description
    node_type
    createdOn
    address
    location
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    status
    nfcTag
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }     
    parent {
      __typename
      id
      name
      description
      node_type
      createdOn
      status
      lat
      lng
      tools {
        tool {
          __typename
          id
          name
          description
          serialNumber
          status
        }
      }   
      locker_tools {
        __typename
        id
        name
        description
        serialNumber
        status
        createdOn
      }   
    }
    child_nodes {
      __typename
      id
      name
      description
      address
      location
      status
      node_type
      lat
      lng
      bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
        __typename
        id
        theDate
        description
        createdOn
        status
      }
    }
    active_locker_tools: locker_tools(where: {status: {_eq: "ready"}}) {
      __typename
      id
      name
      description
      status
      lastCheckedIn
      lastCheckedOut
      createdOn
      lat
      lng
    }
    all_locker_tools: locker_tools {
      __typename
      id
      name
      description
      status
      lastCheckedIn
      lastCheckedOut
      createdOn
      lat
      lng
    }    
    active_tools: tools(where: {status: {_eq: "ready"}}) {
      tool{
        __typename
        id
        name
        description
        status
        lastCheckedIn
        lastCheckedOut
        createdOn
        lat
        lng
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
          user {
            __typename
            id
            alias
          }
        }
      }
    }
    all_tools: tools {
      tool{
        __typename
        id
        name
        description
        status
        lastCheckedIn
        lastCheckedOut
        createdOn
        lat
        lng
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
           user {
            __typename
            id
            alias
          }
        }
      }
    }
    tools_inuse_aggregate: tools_aggregate(where: {tools: {_and: {parents: {node_id: {_eq: $id}}, status: {_eq: "in-use"}}}}) {
      aggregate {
        count
      }
    }
    tools_aggregate {
      aggregate {
        count
      }
    }
    usage(order_by: {createdOn: desc}, limit: 10) {
      __typename
      id
      status
      createdOn
      description
      lat
      lng
      job {
        __typename
        id
        name
        description
      }
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      locations (order_by: {createdOn: asc}) {
        __typename
        id
        lat
        lng
        createdOn
      } 
    }
    usage_count: usage_aggregate(where: {status: {_eq: "check-out"}}) {
      aggregate {
        count
      }
    }
    usage_aggregate {
      aggregate {
        count
      }
    }
    images (order_by: {createdOn: desc}, limit: 10){
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }      
    }
    annotations (order_by: {createdOn: desc}){
      __typename
      id
      name
      createdOn
      description
      user {
        __typename
        id
        alias
      }
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      user {
        __typename
        id
        alias
      }
    }
    open_tickets: tickets(where: {status: {_eq: "open"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    closed_tickets: tickets(where: {status: {_eq: "closed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    attachments(order_by: {createdOn: desc}){
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      url
      src
      parent_id
      user {
        __typename
        id
        alias
      }
    }
  }
}
`;




export const subscribeAllQuotations = `
subscription subscribeAllQuotations {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}) {
    __typename
    id
    name
    funnel
    amount
    description
    acceptedOn
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
}`;


export const subscribeAllSuppliers = `
subscription subscribeAllSuppliers {
  zeus_supplier(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    product_lines_aggregate {
      aggregate {
        count
      }
    }
    products_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const subscribeQuotesForClient = `
subscription subscribeQuotesForClient($client_id: uuid = "") {
  zeus_quotation_view(where: {_and: {client_id: {_eq: $client_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const subscribeContactsForClient = `
subscription subscribeContactsForClient($client_id: uuid = "") {
  zeus_contact(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    first_name
    last_name
    description
    status
    createdOn
  }
}`;


export const subscribeQuotesForContact = `
subscription subscribeQuotesForContact($contact_id: uuid = "") {
  zeus_quotation_view(where: {_and: {contact_id: {_eq: $contact_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const subscribeAllActivities = `
subscription subscribeAllActivities {
  zeus_activity(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    contact {
      __typename
      id
      firstName
      lastName
    }
    client {
      __typename
      id
      name
    }
  }
}
`;


export const subscribeAnnotationsForParent = `
subscription subscribeAnnotationsForParent($parent_id: uuid = "") {
  zeus_annotation(where: {_and: {parent_id: {_eq: $parent_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}
`;


export const subscribeAnnotation = `
subscription subscribeAnnotation($id: uuid = "") {
  zeus_annotation_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    attachments (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    } 
  }
}
`;


export const subscribeActivitiesForClient = `
subscription subscribeActivitiesForClient($client_id: uuid = "") {
  zeus_activity(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    salesDocument {
      __typename
      id
      name
      document_type
    }
    client {
      __typename
      id
      name
    }
    contact {
      __typename
      id
      lastName
      firstName
    }
  }
}
`;


export const subscribeActivitiesForContact = `
subscription subscribeActivitiesForContact($contact_id: uuid = "") {
  zeus_activity(where: {_and: {contact_id: {_eq: $contact_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    salesDocument {
      __typename
      id
      name
      document_type
    }
    client {
      __typename
      id
      name
    }
    contact {
      __typename
      id
      lastName
      firstName
    }
  }
}
`;

export const subscribeAllPurchaseOrders = `
subscription getAllPurchaseOrders {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
  }
}`;


export const subscribeLowBalancePurchaseOrders = `
subscription subscribeLowBalancePurchaseOrders {
  zeus_sales_document(order_by: {createdOn: desc}, where: {_and: {document_type: {_eq: "PurchaseOrder"}, status: {_eq: "active"}}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
    client {
      __typename
      id
      name
      description
    }
    jobBoardEntry {
      __typename
      id
      name
      description
    }        
    invoices: sales_documents(where: {document_type: {_eq: "Invoice"}}) {
      __typename
      id
      name
      description
      amount
      createdOn
      closedOn
      status
    }
  }
}`;


export const subscribeAllInvoices = `
subscription subscribeAllInvoices {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
  }
}`;


export const subscribeJobBoardEntry = `
subscription subscribeJobBoardEntry($id: uuid = "") {
  zeus_jobboard_entry_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    quoteAmount
    status
    scheduled
    poAmount
    invoiceNum
    invoiceDate
    invoiceAmount
    customerPO
    createdBy
    companyName
    completedOn
    primacQuoteName
    primacPO
    isProduct
    isService
    isTravelBooked
    serviceType
    technician {
      __typename
      id
      alias
    }    
    client {
      __typename
      id
      name
      description
    }
    contact {
      __typename
      id
      firstName
      lastName
      email
      description
    }    
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }       
    quotations: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
      theDate
      invoices: sales_documents(where: {document_type: {_eq: "Invoice"}}) {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate        
      }
    }
    invoices: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
      theDate
      parent {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate        
      }
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      user {
        __typename
        id
        alias
      }
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    tools_used(distinct_on: tool_id, where: {tool_id: {_is_null: false}}) {
      tool {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
      }
    }
    toolnodes_used(distinct_on: node_id, where: {node_id: {_is_null: false}}) {
      node {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
      }
    }   
    scheduled_dates(order_by: {theDate:asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      span_id
      technician {
        __typename
        id
        alias
      }
      user {
        __typename
        id
        alias
      }
    } 
    tools_booked(distinct_on: tool_id, where: {tool_id: {_is_null: false}}) {
      tool {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
        serialNumber
        locker {
          __typename
          id
          name          
        }
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
          user {
            __typename
            id
            alias
          }
        }
      }
    }
    toolnodes_booked: tools_booked(distinct_on: node_id, where: {node_id: {_is_null: false}}) {
      node {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
          user {
            __typename
            id
            alias
          }
        }
      }
    }      
  }
}`;



export const subscribeAllJobBoardEntries = `
subscription subscribeAllJobBoardEntries {
  zeus_jobboard_entry(order_by: [{jobCounter: desc_nulls_last}, {createdOn: desc}]) {
    __typename
    id
    name
    jobCounter
    description
    customerPO
    serviceType
    status
    createdOn
    primacQuoteName
    quoteAmount
    primacPO
    contact {
      __typename
      id
      name
      firstName
      lastName
      description
    }
    client {
      __typename
      id
      name
      description
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      user {
        id
        alias
      }
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        id
        alias
      }
    }
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      createdOn
    }            
    quotations: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
      invoices: sales_documents(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}){
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate        
      }
    }
    invoices: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
      theDate
    }
    scheduled_dates(order_by: {theDate:asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      span_id
      technician {
        __typename
        id
        alias
      }
      user {
        __typename
        id
        alias
      }
    }    
  }
}`;


export const subscribeAllActiveJobBoardEntries = `
subscription subscribeAllActiveJobBoardEntries {
  zeus_jobboard_entry(where: {status :{_eq: "active"}} order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    customerPO
    serviceType
    status
    createdOn
    scheduled
    primacQuoteName
    isTravelBooked
    technician {
      __typename
      id
      alias      
    }
    client {
      __typename
      id
      name
      description
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      user {
        id
        alias
      }
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }
    scheduled_dates(order_by: {theDate:asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      span_id
      technician {
        __typename
        id
        alias
      }
      user {
        __typename
        id
        alias
      }
    }     
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      createdOn
    }            
    quotations: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
      __typename
      id
      name
      description
      amount
      status
      theDate
      createdOn
      closedOn
      document_type
    }
    invoices: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      theDate
      closedOn
      document_type
    }    
  }
}`;

//subscribeAllActiveJobBoardEntries

export const subscribeClientNode = `
subscription subscribeClientNode($id: uuid = "") {
  zeus_client_node_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    node_type {
      __typename
      id
      name
      status
    }  
    client {
      __typename
      id
      name
      description
      status
      createdOn
    }    
    parent_node {
      __typename
      id
      name
      status
    }
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    } 
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }     
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    } 
    assets(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      currentCondition
      criticality
      surveys(order_by: {theDate: desc}, limit: 12) {
        __typename
        id
        theDate
        status
        severity
        recommendations
        findings_from_work_completed
        createdOn
        summary
        component {
          __typename
          id
          name
        }
        technician {
          __typename
          id
          alias
        }
      }      
    }    
    routes_aggregate {
      aggregate {
        count
      }
    }
    children_aggregate {
      aggregate {
        count
      }
    }
    assets_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;


export const subscribeClientNodesForClient = `
subscription subscribeClientNodesForClient($client_id: uuid = "") {
  zeus_client_node(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    node_type {
      __typename
      id
      name
      status
    }  
    parent_node {
      __typename
      id
      name
      status
    }
    routes_aggregate {
      aggregate {
        count
      }
    }
    children_aggregate {
      aggregate {
        count
      }
    }
    assets_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;


export const subscribeUsageForNode = `
subscription subscribeUsageForNode($node_id: uuid = "") {
  zeus_tool_user_status(where: {_and: {node_id: {_eq: $node_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    status
    description
    createdOn
    lat
    lng
    job {
      __typename
      id
      name
      description
    }
    user {
      __typename
      id
      alias
    }
  }
}`;


export const subscribeBookingsForNode = `
subscription subscribeBookingsForNode($node_id: uuid = "") {
  zeus_tool_user_booking(where: {_and: {node_id: {_eq: $node_id}}}, order_by: [{createdOn: desc},{theDate: asc}]) {
    __typename
    id
    description
    createdOn
    theDate
    status  
    user {
      __typename
      id
      alias
      firstName
      lastName
      status
    }
    node {
      __typename
      id
      name
      description
      lastCheckedIn
      lastCheckedOut
      lat
      lng
      node_type
      createdOn
      status
      parent {
        __typename
        id
        name
        node_type
        status
      }
      tools {
        tool {
          __typename
          id
          name
          description
          createdOn
          status
          lastCheckedIn
          lastCheckedOut
          lat
          lng
        }
      }
    }
  }
}`;


export const subscribeBookingsForTool = `
subscription subscribeBookingsForTool($tool_id: uuid = "") {
  zeus_tool_user_booking(where: {_and: {tool_id: {_eq: $tool_id}}}, order_by: [{createdOn: desc},{theDate: asc}]) {
    __typename
    id
    description
    createdOn
    theDate
    status
    user {
      __typename
      id
      alias
      firstName
      lastName
      status
    }
    tool {
      __typename
      id
      name
      description
      status
      createdOn
      lat
      lng
      usage {
        __typename
        id
        description
        lat
        lng
        createdOn
        status
        user {
          __typename
          id
          alias
          firstName
          lastName
        }
      }
    }
  }
}`;


export const subscribeRoutesForClient = `
subscription subscribeRoutesForClient($client_id: uuid = "") {
  zeus_route(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    client_node {
      __typename
      id
      name
      description
      node_type_id
      status
      createdOn
    }
  }
}`;


export const subscribeAllProductsForSupplier = `
subscription subscribeAllProductsForSupplier($supplier_id: uuid = "") {
  zeus_products(where: {_and: {supplier_id: {_eq: $supplier_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    amount
    description
    status
    createdOn
    supplier {
      __typename
      id
      name
      description
      createdOn
    }
  }
}`;


export const subscribeAllTools = `
subscription subscribeAllTools {
  zeus_tool(order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    bookings(where: {theDate: {_gte: "now()"}}) {
      __typename
      id
      theDate
      description
      createdOn
      status
    }
    locker {
      __typename
      id
      name
      description
      node_type
      status      
    }
  }
}`;


export const subscribeAllUsers = `
subscription subscribeAllUsers {
  zeus_zeus_user(order_by: {firstName: asc}) {
    __typename
    id
    alias
    firstName
    lastName
    email
    description
    global_permissions
    status
    createdOn
    roles_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const subscribeToolsForNode = `
subscription subscribeToolsForNode($parent_id: uuid = "") {
  zeus_tool(where: { parent_id: {_eq: $parent_id}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    nfcTag
    locker {
      __typename
      id
      name
      description
      node_type
      status
      location
      address
      lat
      lng
      createdOn
    }    
    parents {
      node {
        __typename
        id
        name
        description
        node_type
        status   
        createdOn   
      }
    } 
  }
}`;

export const subscribeToolsForLocker = `
subscription subscribeToolsForLocker($locker_id: uuid = "") {
  zeus_tool(where: { locker_id: {_eq: $locker_id}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    nfcTag
    locker {
      __typename
      id
      name
      description
      node_type
      status
      location
      address
      lat
      lng
      createdOn
    }    
    parents {
      node {
        __typename
        id
        name
        description
        node_type
        status   
        createdOn   
      }
    } 
  }
}`;

// subscribeToolKitsForNode
// where: {_and: {parent_id: {_eq: $parent_id}}, {node_type: {_eq: $node_type}}}, order_by: {name: asc}
export const subscribeToolKitsForNode = `
subscription subscribeToolKitsForNode($parent_id: uuid = "", $node_type: String = "ToolKit") {
  zeus_tool_node(
    where: {
      _and: [
        {parent_id: {_eq: $parent_id}}, 
        {node_type: {_eq: $node_type}}
      ]}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    location
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    history
    parent {
      __typename
      id
      name
      description
      node_type
      status      
    }
  }
}`;


export const subscribeAllToolKits = `
subscription subscribeAllToolKits {
  zeus_tool_node(where: {node_type: {_eq: "ToolKit"}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    parent {
      __typename
      id
      name
      description
      node_type
      createdOn
      status
      lat
      lng
      tools {
        tool {
          id
          name
          description
          serialNumber
          status
        }
      }
    }
  }
}`;


export const subscribeAllLockers = `
subscription subscribeAllLockerssubscribeAllLockers {
  zeus_tool_node(where: {node_type: {_eq: "ToolLocker"}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
  }
}`;


export const subscribeToolNodesForNode = `
subscription subscribeToolNodesForNode($parent_id: uuid = "") {
  zeus_tool_node(where: {_and: parent_id: {_eq: $parent_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    location
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    history
    parent {
      __typename
      id
      name
      description
      node_type
      status      
    }
  }
}`;


export const subscribeUserByEmail = `
subscription subscribeUserByEmail($email: String!) {
  zeus_zeus_user(where: {email: {_eq: $email}}) {
    __typename
    id
    email
    firstName
    lastName
    alias
    description
    status
    createdOn
    roles(order_by: {is_default: desc}){
      role {
        __typename
        id
        name
        description
        notifications(order_by: {createdOn: desc}, limit: 20) {
          __typename
          id
          name
          role
          description
          createdOn
        }
      }
    }      
    active_tools: tool_usage(where: {status: {_eq: "checked-out"}}, order_by: {createdOn: desc}) {
      id
      description
      status
      createdOn
      tool {
        id
        name
        description
      }
      job {
        id
        name
        description
        companyName
      } 
    }  
  }
}
`;


export const subscribeUser = `
subscription subscribeUser($id: uuid!) {
  zeus_zeus_user_by_pk(id: $id) {
    __typename
    id
    firstName
    lastName
    alias
    email
    description
    status
    createdOn
    roles {
      role {
        __typename
        id
        name
        description
        notifications(order_by: {createdOn: desc}, limit: 50) {
          __typename
          id
          name
          role
          description
          createdOn
        }
      }
    }   
    pinned_clients: clients {
      id
      createdOn
      client {
        id
        name
        alias
        description
      }
    }
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
    }      
    jobs_assigned(where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      description
      quoteAmount
      companyName
      createdOn
      status
      purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate
      }        
      salesDocuments(order_by: {createdOn: desc}) {
        __typename
        id
        name
        document_type
        acceptedOn
        closedOn       
        preparedBy
        status
        contact {
          __typename
          id
          firstName
          lastName
          phone
          email
        }
      }      
    }
    tickets_created {
      __typename
      id
      name
      description
      resolution
      status
      createdOn
      closedOn
      closer {
        __typename
        id
        alias
      }
      tool {
        __typename
        id
        name
      }
      toolNode {
        __typename
        id
        name
      }   
    }
    tickets_assigned {
      __typename
      id
      name
      description
      resolution
      status
      createdOn
      closedOn
      closer {
        __typename
        id
        alias
      }
      tool {
        __typename
        id
        name
      }
      toolNode {
        __typename
        id
        name
      }   
    }
    quotations: sales_documents (where: {document_type: {_eq: "Quotation"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    pending_quotations_agg: sales_documents_aggregate(where: {_and: [
        {status: {_eq: "pending"}}, 
        {document_type: {_eq: "Quotation"}}
      ]}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }
    open_activities: activities(where: {status: {_eq: "pending"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }  
    tool_bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}){
      __typename
      id
      description
      theDate
      status
      createdOn
      job {
        __typename
        id
        description
        companyName
        createdBy
        name
        status
      }
      tool {
        __typename
        id
        name
        description
        lastCheckedIn
        lastCheckedOut
        status
        serialNumber
        createdOn
      }
      node {
        __typename
        id
        name
        description
        createdOn
        status
        node_type
      }
    } 
    tool_usage(order_by: {createdOn: desc}, limit: 10){
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
      tool {
        __typename
        id
        name
        description
        lastCheckedIn
        lastCheckedOut
        status
        serialNumber
        createdOn
      }
      node {
        __typename
        id
        name
        description
        createdOn
        status
        node_type
      }      
      user {
        __typename
        id
        alias
      }      
    } 
  }
}
`;


export const subscribeCheckedOutToolNodesForUser = `
subscription subscribeToolNodesForUser($id: uuid!) {
  zeus_tool_node(distinct_on: id, where: {_and: [
    {usage: {user_id: {_eq: $id}}}, 
    {status: {_eq: "in-use"}}
    ]}) {
    __typename
    id
    name
    status
    lastCheckedOut
    lat
    lng
    node_type
    usage(order_by: {createdOn: desc}, limit: 1) {
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
      user {
        __typename
        id
        alias
      }
    }
  }
}
`;


export const subscribeCheckedOutToolsForUser = `
subscription subscribeToolsForUser($id: uuid!) {
  zeus_tool(distinct_on: id, where: {_and: [
    {usage: {user_id: {_eq: $id}}}, 
    {status: {_eq: "in-use"}}
  ]}) {
    __typename
    id
    name
    status
    lastCheckedOut
    lat
    lng
    usage(order_by: {createdOn: desc}, limit: 1) {
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
      user {
        __typename
        id
        alias
      }      
    }
  } 
}
`;


export const subscribeAsset = `
subscription subscribeAsset($id: uuid = "") {
  zeus_asset_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    currentCondition
    criticality
    survey_frequency
    client{
      __typename
      id
      name
      description
      status
    }
    client_node{
      __typename
      id
      name
      description
      status
      node_type{
        __typename
        name
      }
    }
    components(order_by: {name: asc}) {
      id
      name
      description
      status
      createdOn
      locations {
        id
        name
        description
        createdOn
        status
        sensors {
          id
          name
          alias
          orientation
          lastMeasurement
          createdOn
          status
        }
      }
    }    
    images (order_by: {createdOn: desc}, limit: 10) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    } 
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    } 
    surveys(order_by: {theDate: desc}, limit: 12) {
      __typename
      id
      theDate
      status
      severity
      recommendations
      findings_from_work_completed
      createdOn
      summary
      condition_value
      technician {
        __typename
        id
        alias
      }
    }  
    sensors {
      id
      name
      alias
      description
      createdOn
      modifiedOn
      status
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }       
  }
}`;



export const subscribeComponent = `
subscription subscribeComponent($id: uuid = "") {
  zeus_component_by_pk(id: $id) {
    id
    name
    description
    createdOn
    modifiedOn
    status
    asset {
      id
      name
      description
    }
    sensors {
      __typename
      id
      name
      nfcTag
      description
      lastMeasurement
      status
      createdOn
      modifiedOn
      user_defined_fields
      asset {
        __typename
        id
        name
        description
      }
    }
    locations(order_by:{name: asc}) {
      id
      name
      description
      status
      createdOn
      modifiedOn
      sensors {
        id
        name
        nfcTag
        description
        lastMeasurement
        status
        createdOn
        modifiedOn
        user_defined_fields
      }
    }
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    } 
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    } 
  }
}
`;


export const subscribeAssetsForParent = `
subscription subscribeAssetsForParent($parent_id: uuid = "") {
  zeus_asset(where: {parent_id: {_eq: $parent_id}}) {
    __typename
    id
    name
    description
    currentCondition
    status
    createdOn
    client {
      __typename
      id
      name
      description
    }
    client_node {
      id
      name
      description
      status
    }
  }
}
`;
