import React from 'react';
import clsx from 'clsx';

import PropTypes from "prop-types";
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

///////////

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { callMsGraph } from "msgraph";
import { loginRequest } from "authConfig";

import { useQuery, useMutation } from 'urql';
import { getUserByEmail } from 'queries_urql';

import ZSignInButton from "components/buttons/ZSignInButton";
import ZSignOutButton from "components/buttons/ZSignOutButton";

///////////

import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from "@material-ui/core/Hidden";
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BuildIcon from '@material-ui/icons/Build';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import SecurityIcon from '@material-ui/icons/Security';

import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RefreshIcon from '@material-ui/icons/Refresh';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'; 
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import BallotIcon from '@material-ui/icons/Ballot'; 
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputBase from '@material-ui/core/InputBase';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Switch, Route, Link, BrowserRouter, useLocation } from "react-router-dom";
import { useLocalStorage } from 'hooks/useStorage';

import { Auth } from '../Auth';

import ZClientsPanel from 'components/panels/ZClientsPanel';
import ZContactsPanel from 'components/panels/ZContactsPanel';
import ZQuotationsPanel from 'components/panels/ZQuotationsPanel';
import ZJobBoardPanel from 'components/panels/ZJobBoardPanel'; 
import ZSensorsPanel from 'components/panels/ZSensorsPanel'; //ZSensorsPanel
import ZGatewaysPanel from 'components/panels/ZGatewaysPanel'; //ZGatewaysPanel
import ZJobBoardView from 'components/ZJobBoardView'; 

import ZSalesDashboard from 'components/ZSalesDashboard';
import ZClientView from 'components/ZClientView';
import ZContactView from 'components/ZContactView'; //ZQuotationView
import ZSalesDocumentView from 'components/ZSalesDocumentView'; 
import ZClientNodeView from 'components/ZClientNodeView';//ZClientNodeView
import ZAssetView from 'components/ZAssetView'; //ZAssetView
import ZComponentView from 'components/ZComponentView';

import ZAssetSurveyView from 'components/ZAssetSurveyView';// ZAssetSurveyView
import ZSupplierView from 'components/ZSupplierView'; 
import ZSuppliersPanel from 'components/panels/ZSuppliersPanel';  //ZSuppliersPanel
import ZProductLineView from 'components/ZProductLineView'; 
import ZProductLinesPanel from 'components/panels/ZProductLinesPanel';  //ZSuppliersPanel
import ZToolingView from 'components/ZToolingView';  // ZToolingView
import ZAnnotationView from 'components/ZAnnotationView'; //ZAnnotationView

import ZToolView from 'components/ZToolView';  
import ZSensorView from 'components/ZSensorView';  
import ZGatewayView from 'components/ZGatewayView'; //ZGatewayView 
import ZToolNodeView from 'components/ZToolNodeView';  // 
import ZToolNodeEventView from 'components/ZToolNodeEventView';
import ZToolEventView from 'components/ZToolEventView'; 
import ZJobBoardEntryView from 'components/ZJobBoardEntryView'; //ZJobBoardEntryView
import ZTicketView from 'components/ZTicketView';//ZTicketView

import { ZGPTConversationDetailPaneWss } from 'components/core/ZGPTDetailPane';


import ZUserAvatar from 'components/core/ZUserAvatar';  // ZDevUserAvatar
import ZDevUserAvatar from 'components/core/ZDevUserAvatar';  // ZDevUserAvatar
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZUserView from 'components/ZUserView';
import ZZeusUserView from 'components/ZZeusUserView';
import ZZeusUsersPanel from 'components/panels/ZZeusUsersPanel';


import ReactTooltip from 'react-tooltip';

import eventBus from 'utils/EventBus';

const drawerWidth = 250;


/////////////////////////////
/*
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));
*/

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const titles = {
  "": "Home",
  "asset": "Asset",
  "asset_survey": "Asset Survey",
  "annotation": "Annotation",
  "survey": "Asset Survey",
  "sensor": "Sensor",
  "sensors": "Sensors",
  "gateway": "Gateway",
  "gateways": "Gateways",
  "clients": "Clients",
  "client": "Client",
  "client_node": "Client Node",
  "sales": "Sales Dashboard",
  "dashboard": "Dashboard",
  "jobboard": "Job Board",
  "job": "Job Board Entry",
  "jobboard_entry": "Job Board Entry",
  "contacts": "Contacts",
  "suppliers": "Suppliers",
  "quotations": "Quotations",
  "tooling": "Tooling",
  "tool": "Tool",
  "toolnode": "ToolKit",
  "toolkit": "ToolKit",
  "ToolKit": "ToolKit",
  "toollocker": "ToolLocker",
  "ToolLocker": "ToolLocker",
  "invoice": "Invoice",
  "Invoice": "Invoice",
  "purchase_order": "Purchase Order",
  "purchaseorder": "Purchase Order",
  "PurchaseOrder": "Purchase Order",
  "quotation": "Quotation",
  "Quotation": "Quotation",
  "Ticket": "Ticket",
  "ticket": "Ticket",
  "users": "Users",
  "user": "User",
}

///////////////////////////

const ZNavigationBar = (props) => {
  //const { container } = props;
  let email_addr = "";
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  //const location = useLocation();

  const [screen, setScreen] = React.useState("Home");
  //const [screen, setScreen] = React.useState(titles["/"]);
  //const [title, setTitle] = React.useState(titles["/"]);
  
  const [accessToken, setAccessToken] = useLocalStorage('accessToken');
  const [open, setOpen] = React.useState(false);
  //const [state, setState] = React.useState("");
  const [state, setState] = React.useState({
    condition: '',
    showGPTChat: false,
  });
  const [showQuoteDialog, setShowQuoteDialog] = React.useState(false);

  const isAuthenticated = useIsAuthenticated();
  const [auth, setAuth] = React.useState(true);

  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  ////////////////////////

  React.useEffect(()=>{
    eventBus.on("pageLoad", (data) => {
      let title = titles[data.message];
      console.log('setting app bar title: '+ title)
      setScreen(title)
    });

    var condition = navigator.onLine ? "online" : "offline";
    setState({...state, condition: condition});

    window.addEventListener('load', () => {
    
      const updateOnlineStatus = (event) => {
        var condition = navigator.onLine ? "online" : "offline";
        setState({...state, condition: condition});
      }
    
      window.addEventListener('online',  updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);
    });    
  },[setScreen,setState])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  console.log('ZNavigationBar-userRoles')
  console.log(userRoles)
  //if(userRoles === undefined) return (<h2>Loading User Roles...</h2>)

  let userAvatar = (<div></div>)

  if(process.env.NODE_ENV === "development"){
    userAvatar = <ZDevUserAvatar />
  } else {
    userAvatar = <ZUserAvatar />
  }

  let drawer_list = []

  let admin_list = []
  let finance_list = []
  let root_list = []

  let tech_list = [
    <ListItem key='home' component={Link} to='/'
      onClick={()=>{setScreen("Home")}}
    >
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary='Home' />
    </ListItem> ,

    <ListItem key='tools' component={Link} to='/tooling'
      onClick={()=>{setScreen("Tooling View")}}
    >
      <ListItemIcon>
        <BuildIcon />
      </ListItemIcon>
      <ListItemText primary='ToolTracker' />
    </ListItem> ,

    <ListItem key='gateways' component={Link} to='/gateways'
      onClick={()=>{setScreen("Gateways View")}}
    >
      <ListItemIcon>
        <BlurCircularIcon />
      </ListItemIcon>
      <ListItemText primary='Gateways' />
    </ListItem> ,

    <ListItem key='sensors' component={Link} to='/sensors'
      onClick={()=>{setScreen("Sensors View")}}
    >
      <ListItemIcon>
        <NetworkCheckIcon />
      </ListItemIcon>
      <ListItemText primary='Sensors' />
    </ListItem> ,

    <Divider /> ,
  ]

  admin_list = [

    <Divider /> ,

    <ListItem key='sales' component={Link} to='/sales'
      onClick={()=>{setScreen("Sales Dashboard")}}
    >
      <ListItemIcon>
        <MonetizationOnIcon />
      </ListItemIcon>
      <ListItemText primary='SalesDashboard' />
    </ListItem> ,

    <ListItem key='jobboard' component={Link} to='/jobboard'
      onClick={()=>{setScreen("Job Board")}}
    >
      <ListItemIcon>
        <InboxIcon/>
      </ListItemIcon>
      <ListItemText primary='JobBoard' />
    </ListItem> ,

    <ListItem key='clients' component={Link} to='/clients'
      onClick={()=>{setScreen("Clients View")}}
    >
      <ListItemIcon>
        <AccountBoxIcon />
      </ListItemIcon>
      <ListItemText primary='Clients' />
    </ListItem> ,


    <ListItem key='suppliers' component={Link} to='/suppliers'
      onClick={()=>{setScreen("Suppliers View")}}
    >
      <ListItemIcon>
        <PermDataSettingIcon />
      </ListItemIcon>
      <ListItemText primary='Suppliers' />
    </ListItem> ,


    <ListItem key='contacts' component={Link} to='/contacts'
      onClick={()=>{setScreen("Contacts View")}}
    >
      <ListItemIcon>
        <ContactPhoneIcon />
      </ListItemIcon>
      <ListItemText primary='Contacts' />
    </ListItem> ,


    <ListItem key='quotations' component={Link} to='/quotations'
      onClick={()=>{setScreen("Quotations View")}}
    >
      <ListItemIcon>
        <BallotIcon />
      </ListItemIcon>
      <ListItemText primary='Quotations' />
    </ListItem> ,

    <Divider /> ,
  ]


  root_list = [
    <ListItem key='users' component={Link} to='/users'
      onClick={()=>{setScreen("Users View")}}
    >
      <ListItemIcon>
        <SecurityIcon />
      </ListItemIcon>
      <ListItemText primary='Users' />
    </ListItem>  ,
  ]


  if(userRoles){

    drawer_list = drawer_list.concat(tech_list)

    if(userRoles.includes('admin')){
      console.log('adding admin nav elements')
      drawer_list = drawer_list.concat(admin_list)
    }

    if(userRoles.includes('finance')){
      console.log('adding finance nav elements')
      drawer_list = drawer_list.concat(finance_list)
    }        

    if(userRoles.includes('root')){
      console.log('adding root nav elements')
      drawer_list = drawer_list.concat(root_list)
    }    
  }

  console.log(`nav-drawer elements: ${drawer_list.length}`);
  console.log(drawer_list);

  let drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        { drawer_list }
        <Divider />
        <div className={classes.bottomPush}>
          <ListItem>  {/* key='account' component={Link} to='/account' */}
            <ListItemIcon>
              { userAvatar }
            </ListItemIcon>
            { isAuthenticated ? <ZSignOutButton /> : <ZSignInButton /> }          
          </ListItem>
        </div>
      </List>
    </div>
  );


  /*
        <Divider />
        <ListItem key='users' component={Link} to='/users'
          onClick={()=>{setScreen("Users View")}}
        >
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary='Users' />
        </ListItem> 
  */
  
  let switch_component = (
    <Switch>
      <Route exact path="/" component={ZUserView} />

      <Route path="/clients" component={ZClientsPanel}/>
      <Route path="/client/:id" component={ZClientView}/>
      <Route path="/contacts" component={ZContactsPanel} />
      <Route path="/contact/:id" component={ZContactView} />
      <Route path="/sensors" component={ZSensorsPanel} />
      <Route path="/gateways" component={ZGatewaysPanel} />  

      <Route path="/contact/:id" component={ZContactView} />
      <Route path="/supplier/:id" component={ZSupplierView} />
      <Route path="/product_lines" component={ZProductLinesPanel} />
      <Route path="/product_line/:id" component={ZProductLineView} />

      <Route path="/tooling" component={ZToolingView} />
      <Route exact path="/tool/:id/event" component={ZToolEventView} />
      <Route exact path="/toolnode/:id/event" component={ZToolNodeEventView} />

      <Route exact path="/tool/:id/status/:usageid"><ZToolEventView status/></Route>
      <Route exact path="/toolnode/:id/status/:usageid"><ZToolNodeEventView status/></Route>

      <Route exact path="/tool/:id/status"><ZToolView status/></Route>
      <Route exact path="/toolnode/:id/status"><ZToolNodeView status/></Route>

      <Route exact path="/tool/:id/checkout"><ZToolEventView checkout/></Route>
      <Route exact path="/toolnode/:id/checkout"><ZToolNodeEventView checkout/></Route>

      <Route exact path="/tool/:id/checkin/:usageid"><ZToolEventView checkin/></Route>
      <Route exact path="/toolnode/:id/checkin/:usageid"><ZToolNodeEventView checkin/></Route>

      <Route exact path="/nfc/tool/:id/checkin"><ZToolEventView checkin nfc/></Route>
      <Route exact path="/nfc/toolnode/:id/checkin"><ZToolNodeEventView checkin nfc/></Route>

      {/* the nfc prefix in route puts device into NFC mode
          an NFC tag must have been scanned by clicking the NFC logo which puts device into NFC mode
            in NFC mode we must have scanned either a:
              ToolLocker
              ToolKit
              Tool

              if a ToolLocker was scanned 
                the mobile device is put into NFC mode with ToolLocker open and expects 
                    a ToolKit or Tool to be checked in

              if a ToolKit or Tool was scanned 
                the mobile device is put into NFC mode
                  if item is ready then expecting to docheckout
                  if item is checked-out then expecting to docheckin

      */}
      <Route exact path="/nfc/toolnode/:id/doopen"><ZToolNodeEventView doopen nfc/></Route>
      <Route exact path="/nfc/tool/:id/docheckin"><ZToolEventView docheckin nfc/></Route>
      <Route exact path="/nfc/toolnode/:id/docheckin"><ZToolNodeEventView docheckin nfc/></Route>

      {/* so we want to land on a mobile friendly page that allows the user to 
          see current state of the tool
            calibration
            pictures
            notes
          confirm the checkout if
            tool is not already booked by another user
      */}
      <Route exact path="/nfc/tool/:id/docheckout"><ZToolEventView docheckout nfc/></Route>
      <Route exact path="/nfc/toolnode/:id/docheckout"><ZToolNodeEventView docheckout nfc/></Route>

      <Route exact path="/toolnode/:id" component={ZToolNodeView} />
      <Route exact path="/toollocker/:id" component={ZToolNodeView} />
      <Route exact path="/tool/:id" component={ZToolView} />
      
      <Route path="/quotation/for/contact/:id/new">
        <ZAddQuotationDialog 
          open={!showQuoteDialog} 
          handleClose={(event) => { 
            //let path = generatePath('/quotations')
            //history.push(path)
            setShowQuoteDialog(!showQuoteDialog)
          }}
        />
      </Route>

      <Route path="/sensor/:id" component={ZSensorView} />
      <Route path="/gateway/:id" component={ZGatewayView} />

      <Route path="/client_node/:id" component={ZClientNodeView} />
      <Route path="/asset/:id" component={ZAssetView} />
      <Route path="/asset_survey/:id" component={ZAssetSurveyView} />
      <Route path="/jobboard" component={ZJobBoardView} />
      <Route path="/jobboard_entry/:id" component={ZJobBoardEntryView} />
      <Route path="/job/:id" component={ZJobBoardEntryView} />
      <Route path="/annotation/:id" component={ZAnnotationView} />
      <Route path="/ticket/:id" component={ZTicketView} />
      <Route path="/component/:id" component={ZComponentView} />
    </Switch>
  )

  if(userRoles){
    if(userRoles.includes('admin')){
      switch_component = (
        <Switch>
          <Route exact path="/" component={ZUserView} />

          <Route path="/clients" component={ZClientsPanel}/>
          <Route path="/client/:id" component={ZClientView}/>
          <Route path="/contacts" component={ZContactsPanel} />
          <Route path="/contact/:id" component={ZContactView} />
          <Route path="/sensors" component={ZSensorsPanel} />
          <Route path="/gateways" component={ZGatewaysPanel} />  

          <Route path="/tooling" component={ZToolingView} /> 

          <Route exact path="/tool/:id/event" component={ZToolEventView} />
          <Route exact path="/toolnode/:id/event" component={ZToolNodeEventView} />
    
          <Route exact path="/tool/:id/status/:usageid"><ZToolEventView status/></Route>
          <Route exact path="/toolnode/:id/status/:usageid"><ZToolNodeEventView status/></Route>
    
          <Route exact path="/tool/:id/status"><ZToolView status/></Route>
          <Route exact path="/toolnode/:id/status"><ZToolNodeView status/></Route>
    
          <Route exact path="/tool/:id/checkout"><ZToolEventView checkout/></Route>
          <Route exact path="/toolnode/:id/checkout"><ZToolNodeEventView checkout/></Route>
    
          <Route exact path="/tool/:id/checkin/:usageid"><ZToolEventView checkin/></Route>
          <Route exact path="/toolnode/:id/checkin/:usageid"><ZToolNodeEventView checkin/></Route>
    
          <Route exact path="/nfc/tool/:id/checkin"><ZToolEventView checkin nfc/></Route>
          <Route exact path="/nfc/toolnode/:id/checkin"><ZToolNodeEventView checkin nfc/></Route>          

          <Route path="/client_node/:id" component={ZClientNodeView} />
          <Route path="/asset/:id" component={ZAssetView} />
          <Route path="/asset_survey/:id" component={ZAssetSurveyView} />

          <Route path="/sensor/:id" component={ZSensorView} />
          <Route path="/gateway/:id" component={ZGatewayView} />

          <Route path="/jobboard" component={ZJobBoardView} />
          <Route path="/jobboard_entry/:id" component={ZJobBoardEntryView} />
          <Route path="/job/:id" component={ZJobBoardEntryView} />
          <Route path="/annotation/:id" component={ZAnnotationView} />
          <Route path="/ticket/:id" component={ZTicketView} />
          <Route path="/component/:id" component={ZComponentView} />

          {/* admin level  */}
          <Route path="/sales" component={ZSalesDashboard} />           
          <Route path="/suppliers" component={ZSuppliersPanel} />

          <Route path="/supplier/:id" component={ZSupplierView} />
          <Route path="/product_lines" component={ZProductLinesPanel} />
          <Route path="/product_line/:id" component={ZProductLineView} />
    
          <Route path="/quotations" component={ZQuotationsPanel} />
    
          {/* the nfc prefix in route puts device into NFC mode
              an NFC tag must have been scanned by clicking the NFC logo which puts device into NFC mode
                in NFC mode we must have scanned either a:
                  ToolLocker
                  ToolKit
                  Tool
    
                  if a ToolLocker was scanned 
                    the mobile device is put into NFC mode with ToolLocker open and expects 
                        a ToolKit or Tool to be checked in
    
                  if a ToolKit or Tool was scanned 
                    the mobile device is put into NFC mode
                      if item is ready then expecting to docheckout
                      if item is checked-out then expecting to docheckin
    
          */}
          <Route exact path="/nfc/toolnode/:id/doopen"><ZToolNodeEventView doopen nfc/></Route>
          <Route exact path="/nfc/tool/:id/docheckin"><ZToolEventView docheckin nfc/></Route>
          <Route exact path="/nfc/toolnode/:id/docheckin"><ZToolNodeEventView docheckin nfc/></Route>
    
          {/* so we want to land on a mobile friendly page that allows the user to 
              see current state of the tool
                calibration
                pictures
                notes
              confirm the checkout if
                tool is not already booked by another user
          */}
          <Route exact path="/nfc/tool/:id/docheckout"><ZToolEventView docheckout nfc/></Route>
          <Route exact path="/nfc/toolnode/:id/docheckout"><ZToolNodeEventView docheckout nfc/></Route>
    
          <Route exact path="/toolnode/:id" component={ZToolNodeView} />
          <Route exact path="/toollocker/:id" component={ZToolNodeView} />
          <Route exact path="/tool/:id" component={ZToolView} />
          
          <Route path="/quotation/for/contact/:id/new">
            <ZAddQuotationDialog 
              open={!showQuoteDialog} 
              handleClose={(event) => { 
                //let path = generatePath('/quotations')
                //history.push(path)
                setShowQuoteDialog(!showQuoteDialog)
              }}
            />
          </Route>

          <Route path="/quotation/:id"><ZSalesDocumentView documentType="Quotation" dialog={false}/></Route>
          <Route path="/invoice/:id"><ZSalesDocumentView documentType="Invoice" dialog={false}/></Route>
          <Route path="/purchase_order/:id"><ZSalesDocumentView documentType="PurchaseOrder" dialog={false}/></Route>

          <Route path="/users" component={ZZeusUsersPanel}/>
          <Route path="/user/:id" component={ZZeusUserView}/>
        </Switch>
      )
    }
  }


  ///////////////////////
  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}       
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={()=>window.history.back()}
            data-tip="previous page"
            data-for="navbar_tooltip" 
          >
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography variant="h6" style={{ flex: 1 }} noWrap >
            Zeus
          </Typography>
          <Typography variant="h6" style={{ flex: 1 }} noWrap
            onClick={()=>window.location.reload()}
            data-tip="reload page"
            data-for="navbar_tooltip" 
          >
            {screen}
          </Typography>
          <IconButton
            onClick={()=>window.history.forward()}
            data-tip="next page"
            data-for="navbar_tooltip" 
          >
            <ArrowForwardIosIcon/>
          </IconButton>
           
          {/* 
          <IconButton
            color="inherit"
            onClick={()=>{
              console.log('showGPTChat == toggle')
              setState({...state, showGPTChat: !state.showGPTChat})}  
            }
            data-tip="Zeus AI Chat"
            data-for="navbar_tooltip" 
          >
            <SearchIcon/>
          </IconButton>
          */}
        </Toolbar>
      </AppBar>

      <BrowserRouter>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Typography color={state.condition === 'offline' ? 'secondary' : 'primary'}>{state.condition}</Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <div><ChevronLeftIcon /><ChevronLeftIcon /></div> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          {drawer}
        </Drawer>
        <main className={clsx(classes.content, {
          [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {switch_component}
        </main>
       {/* <ZGPTConversationDetailPaneWss isOpen={state.showGPTChat} action={<div/>} handleClose={()=>setState({...state, showGPTChat: false})} static/> */}
      </BrowserRouter>
      <ReactTooltip id="navbar_tooltip"/>
    </div>
  );
}

////////////////

ZNavigationBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  )
};

export default ZNavigationBar;


/*

<div className={classes.search}>
  <div className={classes.searchIcon}>
    <SearchIcon />
  </div>            
  <InputBase
    placeholder="Search…"
    classes={{
      root: classes.inputRoot,
      input: classes.inputInput,
    }}
    inputProps={{ 'aria-label': 'search' }}
  /> 
</div>

*/
