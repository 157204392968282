import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getJobBoardEntry } from 'queries';
import { addJobBoardEntry, updateJobBoardEntry } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getTool } from 'queries_urql';
import { addTool, updateTool } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Link from '@material-ui/core/Link';

import Moment from 'react-moment';
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactTooltip from 'react-tooltip';

import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container'; //Container

import QRCode from 'react-qr-code';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import BallotIcon from '@material-ui/icons/Ballot';
import BuildIcon from '@material-ui/icons/Build';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ZToolUsageTimeline from 'components/core/ZToolUsageTimeline';
import ZContactButton from 'components/buttons/ZContactButton';
import ZClientButton from 'components/buttons/ZClientButton';
import ZPurchaseOrderButton from 'components/buttons/ZPurchaseOrderButton';
import ZQuotationButton from 'components/buttons/ZQuotationButton';

///////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  avatar_closed: {
    backgroundColor: red[500],
  },
  avatar_pending: {
    backgroundColor: blue[500],
  },
  avatar_accepted: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  isOpen: false
  handleClose: ()=>{}
  quotation: {}
}
*/

const ZToolDetailPane = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);

  let skip_query = false;
  if(props.isOpen === false){
    console.log('skipping query')
    skip_query = true
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /*
  const { loading, error, data } = useQuery(getJobBoardEntry, {
    variables: { id: props.entry.id },
    skip: skip_query,
  });
  const [updateFunction] = useMutation(updateJobBoardEntry);

  console.log('props')
  console.log(props)
  console.log("job board entry data loaded")
  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) return (<div></div>);
  if (skip_query) return (<div></div>)
  */

  /////////////////////////////////

  let id = props.entry.id
  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: getTool,
    variables: { id },
    pause: skip_query,
  });
  const [updateFunction] = useMutation(updateTool);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return (<div></div>);
  if (skip_query) return (<div></div>)
  
  /////////////////////////////////
  /*
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
  */

  let avatar_class = classes.avatar
  let status_colour = ""

  if(data.zeus_tool_by_pk.status === 'in-use'){
    avatar_class = classes.avatar_pending
    status_colour = "success"
  }

  if(data.zeus_tool_by_pk.status === 'ready'){
    avatar_class = classes.avatar_accepted
    status_colour = "primary"
  }

  if(data.zeus_tool_by_pk.status === 'out-of-service'){
    avatar_class = classes.avatar_accepted
    status_colour = "danger"
  }

  if(data.zeus_tool_by_pk.status === 'out-for-calibration'){
    avatar_class = classes.avatar_accepted
    status_colour = "warning"
  }

  if(data.zeus_tool_by_pk.status === 'retired'){
    avatar_class = classes.avatar_accepted
    status_colour = "default"
  }

  return (
    <>
    <SlidingPane
      isOpen={props.isOpen}
      title="Tool Detail"
      width="800px"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        //setDetailPane(false);
        props.handleClose()
      }}
    >
      <Card variant="outlined">
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="tool" className={avatar_class}>
                <BuildIcon />
              </Avatar>
            }
            action={
              <Fab
                data-tip="tool actions"
                data-for="tool_box_tooltip"
                //color="primary"
                aria-label="add"
                size="small"
                onClick={/* 
                  handleCardHeaderClick */
                  console.log("tool card header clicked")
                }
              >
                <MoreVertIcon />
                { /* headerMenu */ }
              </Fab>
            }
            title={data.zeus_tool_by_pk.name}
            titleTypographyProps={{variant:'h4'}}
            subheader={  
              <div>
                { 
                  data.zeus_tool_by_pk.quotations.map((item) => {
                    return (
                      <ZQuotationButton id={item.id} title={item.name}/>
                    );
                  })
                }
                { 
                  data.zeus_jobboard_entry_by_pk.purchaseOrders.map((item) => {
                    return (
                      <ZPurchaseOrderButton id={item.id} title={item.name}/>
                    );
                  })
                }
                <ZClientButton id={data.zeus_jobboard_entry_by_pk.client.id} title={data.zeus_jobboard_entry_by_pk.client.name}/>
              </div>
            }
          />
          <Paper>
            <CardContent>
              <Container>
                <Chip color={`${status_colour}`} variant="outlined" label={data.zeus_tool_by_pk.status} />
              </Container>
              <br/>
              <Typography variant="h6" component="h4">Details</Typography>
              <Paper>
                <div style={{ background: 'white', padding: '16px' }}>
                  <QRCode value={`http://localhost:3099/tool/${data.zeus_tool_by_pk.id}`} />
                </div>
                <Typography color="textSecondary">
                  Created &nbsp;
                  <Moment fromNow>
                    {data.zeus_tool_by_pk.createdOn}
                  </Moment>
                </Typography>
                <Typography color="textSecondary">Last Modified &nbsp;
                  <Moment fromNow>
                    {data.zeus_tool_by_pk.modifiedOn}
                  </Moment>
                </Typography>
                <Typography color="textSecondary">Last CheckOut &nbsp;
                  <Moment fromNow>
                    {data.zeus_tool_by_pk.lastCheckOut}
                  </Moment>
                </Typography>
                <Typography color="textSecondary">Last CheckIn &nbsp;
                  <Moment fromNow>
                    {data.zeus_tool_by_pk.lastCheckIn}
                  </Moment>
                </Typography>
              </Paper>
              <br/>
              <Typography variant="h6" component="h4">Description</Typography>
              <Paper>
                <Typography paragraph color="textSecondary">{data.zeus_tool_by_pk.description}</Typography>
              </Paper>
              <Typography variant="h6" component="h4" >Notes</Typography>
              <Paper>
                <ZToolUsageTimeline items={data.zeus_tool_by_pk.usage} />
              </Paper>
            </CardContent>  
          </Paper>  

          <CardActions disableSpacing>       
            <IconButton 
              data-tip="add note"
              data-for="tool_box_tooltip" 
              aria-label="add note">
              <ShareIcon />
            </IconButton>
           
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show notes"
              data-tip="show notes"
              data-for="tool_box_tooltip" 
            >
              <ExpandMoreIcon />
            </IconButton>           
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Notes</Typography>
              { data.zeus_tool_by_pk.annotations.map((note) => {
                return (
                  <div key={note.id}>
                    <Card>
                      <CardHeader>{note.name}</CardHeader>
                      <CardContent>{note.description}</CardContent>
                    </Card>
                  </div>             
                );
              }) }
            </CardContent>
          </Collapse>
        </CardActionArea>    
      </Card>
    </SlidingPane> 
    <ReactTooltip id="zeus_tool_by_pk"/>
    </>   
  )
}

export default ZToolDetailPane;