import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZClientNodeAutocomplete = (props) => {

  return (
    <Autocomplete
      id="choose-client-node-combo-box"
      onChange={(event,item)=>{
        console.log("client node autocomplete change")
        console.log(item)
        props.onChange(event,item)
      }}
      options={[ ...props.items ]}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}

      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Client Node" variant="outlined" />}
    />
  )
}

export default ZClientNodeAutocomplete;