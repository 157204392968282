import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';

import { updateSalesDocumentField } from 'mutations_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, blue } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //BallotIcon
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BallotIcon from '@material-ui/icons/Ballot';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZContactButton from 'components/buttons/ZContactButton';
import ZClientButton from 'components/buttons/ZClientButton'; //ZFileS3UploadButton
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem'; //ZAnnotationInputMenuItem
import ZAnnotationInputMenuItem from 'components/core/ZAnnotationInputMenuItem'; 
import ZAttachmentItem from 'components/core/ZAttachmentItem';  //ZAttachmentItem
import ZAddJobInputMenuItem from 'components/core/ZAddJobInputMenuItem'; // ZAddJobInputMenuItem
import ZAddQuotationRevisionMenuItem from 'components/core/ZAddQuotationRevisionMenuItem'; // ZAddQuotationRevisionMenuItem

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: blue[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    padding: '6px 16px',
  },
});

//////////////////////////////////////////////////////////

const ZSalesDocumentBox = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null); //setActiveContact
  const [activeContact, setActiveContact] = React.useState({});
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    poAmount: false,
    amount: false,
    closedOn: false,
    invoicedOn: false,
    field: null,
    value: null,
  })  

  const [updateResult, updateFunction] = useMutation(updateSalesDocumentField);

  const handleMenuClick = (event) => {
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  }


  const handleDateChange = (value) => {
    console.log('handleDateChange')
    console.log(value)
    setIsEditable({...isEditable, field: 'closedOn', value: value})
  }

  const handleLogActivityForDocument = () => {

  }

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false, poAmount: false, closedOn: false})
    }
  }, []);

  //////////

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);  

  //////////

  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZJSalesDocumentBox->handleEditCommit')
    let doc_id = props.document.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: doc_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: doc_id,
        name: `${field} updated`,
        description: `${userData.alias} updated ${field} from ${props.document[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating job: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`job update [ ${_set} ]`)
        console.log(result)
        setAlertState({...alertState, open: true, message: `Successfully updated ${props.document.document_type}`, severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);  

  ////////////////

  let smallChips = (<div></div>)
  let docNotes = (<div></div>)
  let poAmount = 0.00
  let poRemaining = 0.00
  let acculatedInv = 0.00
  let poColor = ''
  let status_colour = "secondary"

  if(props.document.status === 'active'){
    status_colour = "primary";
  }

  //let po = props.document.purchaseOrders[0]
  if(props.document.amount !== null){
    poAmount = props.document.amount;
  }

  props.document.invoices.forEach((inv)=>{
    acculatedInv += inv.amount;
  })

  poRemaining = props.document.amount - acculatedInv;
  let pctRemaining = (poRemaining / props.document.amount) * 100.0
  if(pctRemaining < 20){
    poColor = 'secondary'
    console.log('pctRemaining')
    console.log(pctRemaining)
  }
  if(props.fullBox){
    smallChips = (
      <div>
        <br/><Divider/><br/>
        <Chip label={`Amount: $ ${props.document.amount ? props.document.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0.00}`} />
        <Chip color="primary" label={`Status: ${props.document.status}`} />
        <br/><Divider/><br/>
      </div>
    )
  }

  const handleCreateJob = (
    <div>
      <ZAddJobInputMenuItem sales_document={props.document} />
    </div>
  )

  const addRevision = (
    <div>
      <ZAddQuotationRevisionMenuItem parent={props.document} />
    </div>
  )

  const addFileElement = (
    <div>
      <ZFileCloudUploadMenuItem sales_document_id={props.document.id} dirName={"attachments"} />
    </div>
  )

  const addAnnotationElement = (
    <div>
      <ZAnnotationInputMenuItem sales_document_id={props.document.id} />
    </div>
  )

  if(props.fullBox){

    docNotes = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="document_box_tooltip" 
            aria-label="add note"
            //onClick={addAnnotationElement}
          >
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="document_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Notes</Typography>
            {props.document.annotations.map((note) => {
              return (
                <div key={note.id}>
                  <Card>
                    <CardHeader>{note.name}</CardHeader>
                    <CardContent>{note.description}</CardContent>
                  </Card>
                </div>             
              );
            })}
          </CardContent>
        </Collapse>
      </div>
    )
  }

  let headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorMenu}
        keepMounted
        open={Boolean(anchorMenu)}
        onClose={handleCloseMenu}
      >
        <Divider variant="middle"/>
        <MenuItem>{addRevision}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{handleCreateJob}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{addFileElement}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{addAnnotationElement}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem onClick={handleCloseMenu}>Close</MenuItem>
        <Divider variant="middle"/>
      </Menu>
    </div>  
  )

  if(props.documentType === 'PurchaseOrder' || props.documentType === 'Invoice'){
    let ttip = `update ${props.documentType} status`
    headerMenu = (
      <div>
        { isEditable.status ?
          <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
          <Typography variant='overline' color='primary'>Update Status</Typography>
          <br/>
            <IconButton
              variant="contained"
              color="primary"
              onClick={()=>{
                console.log('edit commit')
                handleEditCommit(isEditable.field, isEditable.value)
                //setIsEditable({...isEditable, description: false, field: null, value: null})
              }}                    
            >
              <CloudUploadIcon />
            </IconButton>
            <Select
              labelId="status-select-label"
              id="status-simple-select"
              defaultValue={props.document.status}
              onChange={(event,item)=>{
                console.log(item.props.value)
                setIsEditable({...isEditable, field: 'status', value: item.props.value});
              }}                   
            >
              <MenuItem value="pending">pending</MenuItem>
              <MenuItem value="active">active</MenuItem>
              <MenuItem value="closed">closed</MenuItem>
            </Select>
          </Paper>   
        :
          <Chip
            style={{ marginLeft: 64, marginBottom: 16 }}
            icon={<MoreVertIcon />}
            label={ props.document.status.toUpperCase() }
            color={status_colour}
            clickable
            onClick={()=>setIsEditable({...isEditable, status: true, field: 'status'})}
            data-for="document_box_tooltip"
            data-tip={ttip}
          /> 
        }             
      </div>      
    )
  }

  let title = props.document.name;
  if(props.title){
    console.log('creating big title')
    title = (
      <div>
        <Typography variant='overline' color='primary'>{props.title}</Typography>
        <Typography color='primary' variant='h3'>{props.document.name}</Typography>
      </div>      
    )
  }

  let attachmentsElement = (<div></div>)
  if (props.withAttachments){
    attachmentsElement = (
      <div>
        <br/><Divider/><br/>
        <Typography variant='h5'>{`Attachments - (${props.document.attachments_aggregate.aggregate.count})`}</Typography>
        {props.document.attachments.map((file) => {
          return (
            <ZAttachmentItem id={file.id} attachment={file} />             
          );
        })}
      </div>
    )
  }

  let parentButton = (<div></div>)
  let jobButton = (<div></div>)
  let clientButton = (<div></div>)

  if(props.document.parent){
    var dpath = 'invoice';
    var ddid = props.document.parent.id;

    if(props.document.parent.document_type === 'Quotation'){
      dpath = 'quotation'
    }

    if(props.document.parent.document_type === 'PurchaseOrder'){
      dpath = 'purchase_order'
    }

    parentButton = ( 
      <div>
        <Button 
          component="span"
          //variant="contained"
          color="primary"
          size="small"
          //style={{ marginLeft: 8 }}
          onClick={(event) => { 
            let path = generatePath(`/${dpath}/:id`, {
              id: ddid,
              })
            history.push(path)
          }}          
        >
          {props.document.parent.document_type}:&nbsp; {props.document.parent.name}
        </Button> 
      </div>     
    )   
  }

  if(props.document.jobBoardEntry){
    jobButton = ( 
      <div>
        <Button 
          component="span"
          //variant="contained"
          color="primary"
          size="small"
          //style={{ marginLeft: 8 }}
          onClick={(event) => { 
            let path = generatePath(`/jobboard_entry/:id`, {
              id: props.document.jobBoardEntry.id,
              })
            history.push(path)
          }}          
        >
          Job:&nbsp; {props.document.jobBoardEntry.name}
        </Button> 
      </div>
    ) 
  }

  if(props.document.client){
    clientButton = ( 
      <div>
        <Button 
          component="span"
          //variant="contained"
          color="primary"
          size="small"
          //style={{ marginLeft: 8 }}
          onClick={(event) => { 
            let path = generatePath(`/client/:id`, {
              id: props.document.client.id,
              })
            history.push(path)
          }}          
        >
          Client:&nbsp; {props.document.client.name}
        </Button> 
      </div>
    ) 
  }


  /////////////////

  return (
    <div className={classes.root}>
      {/* alert component */} 
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="alert_button"
        onClose={()=>setAlertState({...alertState, open: false, message: '', severity: 'info'})}
      >
        <Alert severity={alertState.severity} 
          onClose={()=>setAlertState({...alertState, open: false, message: '', severity: 'info'})}
        >
          {alertState.message}
        </Alert>
      </Snackbar>      
      {/* main component */} 
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="document" className={classes.avatar}>
            {
              props.document.document_type === 'PurchaseOrder'
              ? <ShoppingCartIcon />
              : props.document.document_type === 'Invoice'
              ? <MonetizationOnIcon />
              : <BallotIcon />
            }             
            </Avatar>
          }
          action={props.actions ? props.actions : headerMenu}
          title={
            <div>
            {props.document.document_type === 'PurchaseOrder' || props.document.document_type === 'Invoice' ?
              <div>
                { isEditable.name ?
                  <TextField
                    className={classes.nameField}
                    fullWidth
                    id="details"
                    label="Name"
                    defaultValue={props.document.name}
                    onChange={(event) =>{
                      console.log(event.target.value)
                      setIsEditable({...isEditable, field: 'name', value: event.target.value});
                    }} 
                    variant="filled"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          variant="contained"
                          color="primary"
                          onClick={()=>{
                            console.log('edit commit')
                            handleEditCommit(isEditable.field, isEditable.value)
                          }}                    
                        >
                          <CloudUploadIcon />
                        </IconButton>
                      )
                    }}              
                  />  
              :
                <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name', value: props.document.name})}>
                  { title }
                </div>  
              } 
              </div>   
              :
               title      
            }
            </div>
          }
          titleTypographyProps={{variant:'h4'}}
          subheader={
            <div>
              { parentButton }
              { clientButton }
              { jobButton }
              { props.document.document_type === 'PurchaseOrder'  &&
                <div>
                  { isEditable.poAmount ?
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="filled-adornment-amount">PO Amount</InputLabel>
                      <FilledInput
                        id="filled-adornment-amount"
                        defaultValue={props.document.amount ?? ''}
                        onChange={(event) =>{
                          console.log("quoteAmount->"+ JSON.stringify(event.target.value));
                          setIsEditable({...isEditable, value: event.target.value});
                        }}                
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        endAdornment={
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                              console.log('edit commit')
                              handleEditCommit(isEditable.field, isEditable.value)
                            }}                    
                          >
                            <CloudUploadIcon />
                          </IconButton>
                        }
                      />
                    </FormControl>  
                    :
                    <Chip color="primary" label={`PO Amount: $ ${poAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` }
                      onClick={()=>setIsEditable({...isEditable, poAmount: true, field: 'amount'})}
                    />
                  }
                  <Chip label={`Invoiced: $ ${acculatedInv.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` }/>
                  <Chip color={poColor} label={`PO Remaining: $ ${poRemaining.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` }/>  
                </div>                          
              }

              { props.document.document_type === 'Invoice'  &&
                <div>
                  { isEditable.amount ?
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                      <FilledInput
                        id="filled-adornment-amount"
                        defaultValue={props.document.amount ?? ''}
                        onChange={(event) =>{
                          console.log("quoteAmount->"+ JSON.stringify(event.target.value));
                          setIsEditable({...isEditable, value: event.target.value});
                        }}                
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        endAdornment={
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                              console.log('edit commit')
                              handleEditCommit(isEditable.field, isEditable.value)
                            }}                    
                          >
                            <CloudUploadIcon />
                          </IconButton>
                        }
                      />
                    </FormControl>  
                    :
                    <Chip color="primary" label={`Amount: $ ${props.document.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` }
                      onClick={()=>setIsEditable({...isEditable, amount: true, field: 'amount'})}
                    />
                  }
                </div>                          
              }

              { props.documentType === 'Quotation' && <Typography>Funnel:&nbsp;{props.document.funnel}</Typography> }
              { props.documentType === 'Invoice' && 
                isEditable.invoicedOn ?
                <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                              console.log('edit commit')
                              handleEditCommit(isEditable.field, isEditable.value)
                            }}                    
                          >
                            <CloudUploadIcon />
                          </IconButton>                          
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="invoicedOn"
                            value={isEditable.value}
                            label="Invoiced Date"
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />                 
                        </Grid>
                      </MuiPickersUtilsProvider> 
                    </div> 
                : 
                  <Typography color='primary'
                    onClick={()=>setIsEditable({...isEditable, invoicedOn: true, field: 'invoicedOn', value: props.document.theDate})}
                  >
                    Invoiced On:&nbsp;
                    <Moment format="YYYY-MM-DD">
                      {props.document.theDate}
                    </Moment>
                  </Typography>                
              }
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                  {props.document.createdOn}
                </Moment>
              </div>
              <div>
                Last Modified:&nbsp;
                <Moment format="YYYY-MM-DD">
                  {props.document.modifiedOn}
                </Moment>
              </div>
              { props.document.document_type === 'PurchaseOrder' ?
                  isEditable.closedOn ?
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                              console.log('edit commit')
                              handleEditCommit(isEditable.field, isEditable.value)
                            }}                    
                          >
                            <CloudUploadIcon />
                          </IconButton>                          
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="closedOn"
                            value={props.document.closedOn}
                            label="PO Close Date"
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />                 
                        </Grid>
                      </MuiPickersUtilsProvider> 
                    </div> 
                  :
                    <Typography color='primary'
                      onClick={()=>setIsEditable({...isEditable, closedOn: true, field: 'closedOn'})}
                    >
                        Closing On:&nbsp;
                        <Moment format="YYYY-MM-DD">
                          {props.document.closedOn}
                        </Moment>
                    </Typography>
                :
                  <div></div>
              }              
            </div>
          }
        />
        <CardContent>
          { smallChips }          
          <Typography variant='h5'>Description</Typography>
          <Paper className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >
            {
              isEditable.description ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Description"
                multiline
                defaultValue={props.document.description}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'description', value: event.target.value});
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{props.document.description}</Typography>
            }        
          </Paper>   

          <br/>
          <br/><Divider/><br/>
          {/* <ZFileCloudUploadMenuItem parent_id={props.document.id} path={`${props.document.document_type}/${props.document.id}/attachments`} /> */}
          {attachmentsElement}
        </CardContent>
        { docNotes }
      </Card>
      <ReactTooltip id="document_box_tooltip"/>
    </div>
  );
};


export default ZSalesDocumentBox;
