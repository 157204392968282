import React from 'react';
import Webcam from "react-webcam";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

//////////////////

const useStyles_1 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

///////////////

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 240,
    width: 160,
  },
  control: {
    padding: theme.spacing(2),
  },  
  button: {
    alignItems: 'left',
    justifyContent: 'left',
  },
}));

//////////////////

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

////////////////

const ZFrontCameraCapture = (props) => {
  const classes = useStyles();

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const capture = React.useCallback(() => {
    console.log('ZFrontCameraCapture->capturing image')
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    props.handleChange(imageSrc)
  }, [webcamRef, setImgSrc]);

  return (
    <div> 
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={10}>
            <Grid key="webcam" item>
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  //videoConstraints={videoConstraints}
                  width={props.width ? props.width : 160} 
                  height={props.height ? props.height : 240}
                />           
              </div>
              <Button variant="outlined" onClick={capture}>Capture photo</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};


export default ZFrontCameraCapture;

/*
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={openAlert}
        message={alertMessage}
        key="alert_bar"
        onClose={()=>{
          console.log('trying to open snackbar')
          setOpenAlert(false)
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                console.log('trying to close snackbar')
                setOpenAlert(false)
              }}
            >
              Close
            </Button>
          </div>
        }
      /> 
*/

/*

              <Webcam
                className={classes.root}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                //videoConstraints={videoConstraints}
                width={160} 
                height={240}
              />



*/

/*
            <BarcodeScannerComponent
              width={160}
              height={240}
              onUpdate={(err, result) => {
                console.log('barcode scanner')
                console.log(result)
                if (result){
                  setImgSrc(result.text);
                } else {
                  setImgSrc("Not Found");
                }
              }}
            />
*/

/*

    <>
      <Webcam
        className={classes.root}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        //videoConstraints={videoConstraints}
        width={160} 
        height={240}
      />
      <Button variant="outlined" onClick={capture}>Capture photo</Button>
      <div>
        {imgSrc && (
          <img src={imgSrc} />
        )}
      </div>
    </>

*/