import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import Button from '@material-ui/core/Button';

////////////////////////////////////

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch(e => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
const ZSignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button color="primary" 
      onClick={() => handleLogin(instance)}
    >
      Sign in
    </Button>
  );
}

export default ZSignInButton;