import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useMutation, useQuery } from 'urql';

import { getAllQuotations, getAllClients, getClient, getContact } from 'queries_urql';
import { addQuotation, updateQuotation} from 'mutations';

import Moment from 'react-moment';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';


///////////////////

const ZQuotationNumberInput = (props) => {
  const [state, setState] = React.useState({})

  const computeQuoteNum = () => {
    let supplier_alias = '';
    let client_alias   = ''; 
    let quoteType = '';

    if(props.quoteType){
      quoteType = props.quoteType
    }

    let client = undefined;
    if(props.client){
      client = props.client;
    }

    let supplier = undefined;
    if(props.supplier){
      supplier = props.supplier;
    }

    console.log('ZQuotationNumberInput.props')
    console.log(props)

    let today = moment()
    let year  = today.format('YY')
    let month = today.format('MM')
    let day   = today.format('DD')

    if(supplier){
      supplier_alias = supplier.name.split(' ')[0]
    }
    
    if(client){
      client_alias   = client.name.split(' ')[0]
    }
    
    if(supplier){
      if(supplier.alias){
        supplier_alias = supplier.alias
      }
    }

    if(client){
      if(client.alias){
        client_alias = client.alias
      }   
    }

    let quoteNum  = quoteType + year + month + day + supplier_alias + client_alias;
    //setState(quoteNum)
    return quoteNum;
  }

  return (
    <TextField
      id="quotationNumber"
      label={computeQuoteNum()}
      variant="outlined"
      rows={1}
      onChange={(event) => {
        console.log(`handleQuoteChange -> ${event.target.value}`)
        props.onChange(event.target.value)
      }} 
    />  
  )
}

export default ZQuotationNumberInput;
