import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getTool } from 'queries_urql'; //subscribeToolBox
import { subscribeToolBox } from 'subscriptions_urql';
import { updateToolField } from 'mutations_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, blue, green, cyan } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import BuildIcon from '@material-ui/icons/Build';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QRCode from "react-qr-code";

import ZMapComponent from 'components/core/ZMapComponent';
import ZSetOutofServiceButton from 'components/buttons/ZSetOutofServiceButton';
import ZSetReadyButton from 'components/buttons/ZSetReadyButton'; 
import ZSetRetiredButton from 'components/buttons/ZSetRetiredButton';
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: blue[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

/////////////////////////////////////

const ZToolBox = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);

  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    field: null,
    value: null,
  })

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const calcRestrictedDates = (elements) =>{
    let restrictedDates = []
    console.log('callout')
    //console.log(row)
    elements.forEach((elem)=>{
      //console.log(elem)
      /*
      let d = new Date(elem.theDate);
      console.log(d)

      let str = d.toLocaleDateString();
      d = new Date(str);
      */
      let d = moment(elem.theDate).local()
      d = d.add(1, 'day').toDate()
      //let d = a.l

      //console.log(d)
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  const [updateResult, updateFunction] = useMutation(updateToolField);

  ///////////


  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZToolBox->handleEditCommit')
    let tool_id = props.tool.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: tool_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: tool_id,
        name: `tool ${field} updated`,
        description: `${userData.alias} updated tool ${field} from ${props.tool[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating tool: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`tool update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated tool', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);


  /////////

  let tool = {}

  const queryPause = props.static !== undefined ? true : false;
  const subscriptionPause = props.realtime !== undefined ? false : true;
  const id = props.tool.id;

  const handleSubscription = (previousData, response) => {
    console.log('ZToolBox::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    tool = data.zeus_tool_by_pk
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZToolBox setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeToolBox,
    variables: { id },
    pause: subscriptionPause,
  }, handleSubscription); 


  const [result, reexecuteQuery] = useQuery({
    query: getTool,
    variables: { id },
    //pause: queryPause,
    pause: true,
  });

  console.log('ZToolBox result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static === undefined){
    tool = data.zeus_tool_by_pk
  } else {
    tool = props.tool
  }

  /////////

  let smallChips = (<div></div>)
  let toolNotes = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)

  let addSetOutofServiceButton = (<div></div>)
  let addSetReadyButton = (<div></div>)
  let addSetRetiredButton = (<div></div>)
  let addBookingButton = (<div></div>)

  //////////////////////////////////

  if(props.noactions === undefined){
    addSetOutofServiceButton = (
      <div>
        <ZSetOutofServiceButton id={id} lat={props.lat} lng={props.lng} toolnode/>      
      </div>
    )

    addSetReadyButton = (
      <div>
        <ZSetReadyButton id={id} lat={props.lat} lng={props.lng} toolnode/>      
      </div>
    )

    addSetRetiredButton = (
      <div>
        <ZSetRetiredButton id={id} lat={props.lat} lng={props.lng} toolnode/>   
      </div>
    )
  }

  ///////////////////////////////////

  if(props.fullBox !== undefined){
    smallChips = (
      <div>
        <Chip label={'Total Uses:'+ tool.usage_count.aggregate.count} />
      </div>
    )

    toolNotes = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="tool_box_tooltip" 
            aria-label="add note">
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="tool_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Notes</Typography>
            {tool.annotations.map((note) => {
              return (
                <div key={note.id}>
                  <Card>
                    <CardHeader>{note.name}</CardHeader>
                    <CardContent>{note.description}</CardContent>
                  </Card>
                </div>             
              );
            })}
          </CardContent>
        </Collapse>
      </div>
    )
  }

  let title = (
    <div>
      <Typography  variant='overline' color='primary'>TOOL</Typography>
      <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
        <Typography variant='h3'>{tool.name}</Typography>
      </div>
    </div>      
  )

  /*
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
  */

  // ready is the default
  let urlValue = `${baseURL}/tool/${tool.id}/checkout`;
  if(tool.status === 'in-use'){
    urlValue = `${baseURL}/tool/${tool.id}/checkin`;
  }

  if(tool.status === 'out-of-service'){
    urlValue = `${baseURL}/tool/${tool.id}/ready`;
  }

  if(tool.status === 'out-for-calibration'){
    urlValue = `${baseURL}/tool/${tool.id}/ready`;
  }

  if(props.noqrcode === undefined){
    qrcode = (
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
        <QRCode
        size={512}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={urlValue}
        viewBox={`0 0 512 512`}
        />
      </div>
    )
  }

  
  if(props.showmap || props.showMap){
    let xlat = props.lat;
    let xlng = props.lng;
    if(tool.usage.length > 0){
      xlat = tool.usage[0].lat
      xlng = tool.usage[0].lng
      console.log(`last usage xlat: ${xlat} xlng: ${xlng}`)
    }
    gmap = (
      <div>
        <ZMapComponent name="tool_map" lat={xlat} lng={xlng} zoom={12}/>
        <br/><Divider /><br/>
      </div>
    )
  }
  
  let current_user = (<div></div>)
  let status_colour = "primary"

  if(tool.status === 'in-use'){
    status_colour = "secondary"
    if(tool.usage.length > 0){
      current_user = (
        <Typography>Current User: {tool.usage[0].user.alias}</Typography>
      )
    }
  }

  /////////////////////////

  let lastCheckedOut = (<div>Last Checked-Out: never</div>)
  let lastCheckedIn  = (<div>Last Checked-In: never</div>)
  let lockerName = (<div>Locker: N/A</div>)
  let nfcTag = (<div>NFC Tag: N/A</div>)

  if(tool.lastCheckedOut !== null){
    lastCheckedOut = (
      <div>
        Last Checked-Out:&nbsp;
        <Moment format="YYYY-MM-DD hh:mm">
        {tool.lastCheckedOut}
        </Moment>
      </div>
    )
  }

  if(tool.lastCheckedIn !== null){
    lastCheckedIn = (
      <div>
        Last Checked-In:&nbsp;
        <Moment format="YYYY-MM-DD hh:mm">
        {tool.lastCheckedIn}
        </Moment>
      </div>
    )
  }

  //if(tool.locker !== null){
  //  lockerName = (<div>Locker: {tool.locker.name}</div>)
 // }

  if(tool.locker !== null){
    lockerName = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/toolnode/:id', {
            id: tool.locker.id}
          )
          history.push(path)
        }}          
      >
        Locker: {tool.locker.name}
      </Button> 
    )   
  }

  if(tool.nfcTag !== null){
    nfcTag = (<div>NFC Tag: {tool.nfcTag}</div>)
  }  

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>      
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="tool" className={classes.avatar}>
              <BuildIcon />
            </Avatar>
          }
          action={
            <Paper>
              <Chip
                style={{ marginLeft: 8 }}
                icon={<TrackChangesIcon />}
                label={ tool.status.toUpperCase() }
                color={status_colour}
              />              
              <br/>
              <div>
                {addSetReadyButton}
                {addSetOutofServiceButton}
                {addSetRetiredButton}       
                {props.actions}
              </div>
              <ZFluentCalendar restrictedDates={calcRestrictedDates(tool.bookings)}/>
            </Paper>
          }
          title={
            isEditable.name ?
            <div>
              <Typography  variant='overline' color='primary'>TOOL</Typography>         
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="name"
                label="Name"
                defaultValue={tool.name}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'name', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />
            </div>          
            : 
            title
          }
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {tool.createdOn}
                </Moment>
              </div>
              { smallChips } 
              {lockerName}
              {lastCheckedOut}
              {lastCheckedIn}
              {nfcTag}
              {current_user}
            </div>
          }
        />
        <CardContent>
          { qrcode }         
           <br/>
          {gmap}          
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >          
          {
            isEditable.description ?
            <TextField
              className={classes.descriptionField}
              fullWidth
              id="details"
              label="Description"
              multiline
              defaultValue={tool.description}
              rows={4}
              onChange={(event) =>{
                console.log(event.target.value)
                setIsEditable({...isEditable, field: 'description', value: event.target.value});
                //console.log("state->"+ JSON.stringify(state));
              }} 
              variant="filled"
              InputProps={{
                endAdornment: (
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      console.log('edit commit')
                      handleEditCommit(isEditable.field, isEditable.value)
                      //setIsEditable({...isEditable, description: false, field: null, value: null})
                    }}                    
                  >
                    <CloudUploadIcon />
                  </IconButton>
                )
              }}              
            />  
            :       
            <Typography>{tool.description}</Typography>
          }
          </Paper>
          {/* 
          <br/><Divider/><br/>
          <ZFileCloudUploadMenuItem parent_id={tool.id} path={`tool/${tool.id}/attachments`} />
          */}
          <br/><Divider/><br/>
          { props.showImages && <ZImageListHorizontal parent_id={tool.id} path={`tool/${tool.id}/images`} data={tool.images}/> }
          <br/><Divider/><br/>
        </CardContent>
        { /* toolNotes */ }
      </Card>
      <ReactTooltip id="tool_box_tooltip"/>
    </div>
  );
};


export default ZToolBox;
