import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Moment from 'react-moment';
import moment from 'moment';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ReactTooltip from 'react-tooltip';

/////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //overflowY: 'auto',
    maxHeight: 400,
    overflowY: 'scroll',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));


const ZActivitiesTimeline = (props) => {
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      <div>
      { props.items.map((note,index)=>{
        let size = props.size || props.items.length 
        if(index < size){
          return (
            <div key={note.id}>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                  <Moment format="YYYY-MM-DD">
                    {note.createdOn}
                  </Moment>
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot/>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}
                    data-tip={note.description}
                    data-for="activity_timeline_tooltip"  
                  >
                    <Typography variant="body2" >
                      {note.name}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <ReactTooltip id="activity_timeline_tooltip"/>
            </div>
          )
        } else {
          return null
        }
      })}
      </div>
    </Timeline>
  )
}
//component="h6"

export default ZActivitiesTimeline;