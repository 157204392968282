import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { updateTicketClosed, addNotification } from 'mutations_urql';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { uuid } from 'uuidv4';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
}));

//////////////////////

const ZSetTicketClosedButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({
    open: false,
    closedOn: '',
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });


  console.log('ZSetTicketClosedButton->props')
  console.log(props)

  const [updateJobResult,     updateTicketFunction]     = useMutation(updateTicketClosed);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);


  const handleClose = () => {
    setState({...state, open: false})
  }

  let ticket_id = null;
  if(props.ticket){
    ticket_id = props.ticket.id
  }


  const handleConfirm = () => {
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: ticket_id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: ticket_id,
        name: "ticket completed",
        description: `${userData.alias} updated ticket with completion date ${state.closedOn}`,
      },
      _set: {
      status: 'completed',
      closedOn: state.closedOn,
      resolution: state.resolution,
      modifiedOn: modifiedOn,
    }}

    console.log(variables)

    updateTicketFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        insertNotificationFunction({object: {
          name: `Ticket ${props.ticket.name} completed`,
          description: `${userData.alias} set Ticket ${props.ticket.name} as completed`,
          role: 'admin',
        }}); 

        setAlertState({
          open: true,
          message: 'Successfully set ticket as completed',
          severity: 'info',
        })
      }

    })
  }

  ////////////////

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={12000}
              open={state.open}
              key="set_ticket_closed"
              onClose={()=>{
                setState({...state, open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={
                <div>
                  <TextField
                    className={classes.nameField}
                    autoFocus
                    //margin="dense"
                    format="yyyy-MM-dd"
                    id="closedOn"
                    type="date"
                    defaultValue={new Date()}
                    label="Completion Date"
                    onChange={(event) =>{
                      setState({...state, closedOn: event.target.value})
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                  /> 
                  <br/>
                  <TextField
                    className={classes.nameField}
                    //autoFocus
                    margin="dense"
                    id="resolution"
                    label="Resolution"
                    onChange={(event) =>{
                      setState({...state, resolution: event.target.value})
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                  />                   
                </div>                   
              }
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleConfirm}>
                    Submit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                    No
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary" /> : <div/>}
              onClick={(event) => { 
                setState({...state, open: true})
              }}       
              disabled={props.disabled}               
            >
              Set Ticket As Completed
            </Button>
          </div>
        </Router>
      </strong>
    </div>
  )
}

export default ZSetTicketClosedButton;


/*

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="closedOn"
                      label="Completion Date"
                      value={new Date()}
                      onChange={(event) =>{
                        setState({...state, closedOn: event.target.value})
                        console.log("state->"+ JSON.stringify(state));
                      }} 
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <br/>

*/
