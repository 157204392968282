import React from 'react';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import moment from 'moment';

import { useQuery, useMutation, useSubscription } from 'urql';
import { subscribeAllActiveJobBoardEntries } from 'subscriptions_urql';
import { addJobboardSchedules, updateJobboardDaySchedule, updateJobBoardScheduleSpan, deleteJobBoardSchedule, deleteJobBoardScheduleSpan, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import ReactTooltip from 'react-tooltip';

import InputBase from '@material-ui/core/InputBase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import EmojiPeopleOutlinedIcon from '@material-ui/icons/EmojiPeopleOutlined';
import EventIcon from '@material-ui/icons/Event';
import FlightTakeoffOutlinedIcon from '@material-ui/icons/FlightTakeoffOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import PortraitOutlinedIcon from '@material-ui/icons/PortraitOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Snackbar from '@material-ui/core/Snackbar';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ZUserAutocomplete from 'components/core/ZUserAutocomplete';
import JobBoardBloC from 'blocs/JobBoardBloC';

import ZJobBoardDetailPane from 'components/core/ZJobBoardDetailPane';


/////////////////////////////////

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },
}));

////////////////////

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { daysAhead  = 182, daysBack  = 182, page, daysPerPage=7, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleTodayButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(daysAhead / daysPerPage) ));
  }

  const handleLastPageButtonClick = (event) => {
    //onPageChange(event, Math.max(0, Math.ceil(count / daysPerPage) - 1));
    onPageChange(event, Math.max(0, Math.ceil((daysBack+daysAhead-7) / daysPerPage) ));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first week"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous week">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>

      {/* handleTodayButtonClick */}
      <IconButton
        onClick={handleTodayButtonClick}
        //disabled={page >= Math.ceil((daysAhead+daysBack) / daysPerPage) - 1}
        aria-label="this week"
      >
        {theme.direction === 'rtl' ? <ChangeHistoryIcon /> : <ChangeHistoryIcon />}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil((daysAhead+daysBack) / daysPerPage) - 1}
        aria-label="next week"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil((daysAhead+daysBack) / daysPerPage) - 1}
        aria-label="last week"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}


/////////////

const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
});

const getMonday = () => {
  var a = moment().startOf('week');
  console.log('getMonday')
  console.log(a)
  return a;
}

const getLastDay = (forward) => {
  var a = moment().add(forward, 'days'); // adjust when day is sunday
  console.log('getLastDay')
  return a;
}

const getAllDays = (forward, back) => {
  console.log(`getAllDays ${forward} ~ ${back}`)
  var a = moment();
  //console.log(a);

  a = a.subtract(back, 'days');
  //console.log(a);

  a = a.startOf('week');
  //console.log(a);

  //var firstMonday = a.clone();
  var thisMonday = moment().startOf('week');
  var startIndex = 0;

  var rval = []
  for(var i=0; i < (forward + back); i++){
    var b = a.clone();
    b.add(i, 'days')
    if(b.isSame(thisMonday, 'day')){
      startIndex = i;
    }
    rval.push( b )
    //console.log(b)
  }
  
  //console.log(rval)
  return rval;
}

const getStartIndex = (forward, back) => {
  console.log(`getStartIndex ${forward} ~ ${back}`)
  var a = moment();
  //console.log(a);

  a = a.subtract(back, 'days');
  //console.log(a);

  a = a.startOf('week');
  //console.log(a);

  //var firstMonday = a.clone();
  var thisMonday = moment().startOf('week');
  var startIndex = 0;

  for(var i=0; i < (forward + back); i++){
    var b = a.clone();
    b.add(i, 'days')
    if(b.isSame(thisMonday, 'day')){
      startIndex = i;
    }
  }
  
  console.log(startIndex)
  return startIndex; 
}

const getAllDays_1 = (forward) => {
  console.log('getAllDays')
  var a = moment().startOf('week');
  var rval = []
  for(var i=0; i < forward; i++){
    var b = a.clone();
    b.add(i, 'days')
    rval.push( b )
  }
  
  return rval;
}

////////////////////////////

const  ZSchedulePanel = ({daysAhead=182, daysBack=182, rows}) => {
  const classes = useStyles();
  const history = useHistory();

  const dragItem = React.useRef();
  const dragOverItem = React.useRef();

  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [calendar,setCalendar] = React.useState([])
  const [filter,setFilter] = React.useState({
    filter: false,
    value: '',
  })
  const [page,setPage] = React.useState(getStartIndex(daysAhead, daysBack) / 7);
  const [activeEntry, setActiveEntry] = React.useState({
    entry: null,
    show: false
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });   
  const [showScheduleDialog,setShowScheduleDialog] = React.useState({
    show: false, 
    job: {}, 
    scheduleDay: {},
  });
  const [state,setState] = React.useState({
    scheduleDay: {},    
    today: new Date(),
    firstDay: getMonday(),
    lastDay: getLastDay(daysAhead),
    fullSchedule: getAllDays(daysAhead, daysBack),
    startIndex: getStartIndex(daysAhead, daysBack),
  });

  ////////

  const [updateScheduleDayResult, updateScheduleDayFunction]      = useMutation(updateJobboardDaySchedule);
  const [deleteScheduleDayResult, deleteDateFunction]             = useMutation(deleteJobBoardSchedule);// deleteScheduleDayFunction
  const [deleteScheduleDateSpanResult, deleteDateSpanFunction]    = useMutation(deleteJobBoardScheduleSpan);// deleteDateSpanFunction
  const [updateScheduleDateSpanResult, updateScheduleDateSpanFunction]    = useMutation(updateJobBoardScheduleSpan);//updateScheduleDateSpanFunction
  const [updateJobResult,     updateJobFunction]                  = useMutation(addJobboardSchedules);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  ////////

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return [...response.zeus_jobboard_entry];
  }
 
  const [res] = useSubscription({ 
    query: subscribeAllActiveJobBoardEntries, 
    //pause: (showDialog === true) ? true : false, 
  }, handleSubscription); 

  console.log('res');
  console.log(res)  

  const { data, fetching, error } = res;

  React.useMemo(()=>{
    console.log('ZSchedulePanel::useMemo')
    let monday = moment().startOf('week');
    //let rval = [];
    if(!data) return;

    //console.log(state.fullSchedule);

    data.map((job)=>{
      //job.days = new Array(state.fullSchedule.length).fill(0);
      job.days = state.fullSchedule.slice(0);
      //console.log(job)
      state.fullSchedule.map((day,index)=>{
        //console.log(day)
        let td = day.clone();
        for(var j=0; j < job.scheduled_dates.length; j++){  
          let dd = job.scheduled_dates[j]   
          let ddd = moment(dd.theDate)
          if(td.isSame(ddd, 'day')){
            //console.log(`date: ${td} === theDate: ${ddd}`)
            if(dd.status === 'active'){
              job.days[index] = dd
            } else {
              job.days[index] = dd
            }
          } 
        }
      })
      
      console.log(job)
    })

    setCalendar(data)
  },[data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setShowScheduleDialog({...showScheduleDialog, show: false})
  }

  const handleCloseDetailPane = () => {
    setActiveEntry({
      show: false,
      entry: null,
    })
  };  

  const handleUserChange = (event,item) => {
    console.log('setting tech')
    console.log(item)
    console.log(item.id)
    setShowScheduleDialog({...showScheduleDialog, tech_id: item.id, tech: item})
  }  

  const handleStatusChange = (event) => {
    console.log('setting status')
    console.log(event.target.value)
    setShowScheduleDialog({...showScheduleDialog, status: event.target.value})
  }    

  const handleSubmit = () => {
    let user_id = userData.id;
    let modifiedOn = moment().format();

    console.log('handleSubmit')
    console.log(showScheduleDialog)

    let activity_desc = `${userData.alias} modified job board entry with schedule day ${showScheduleDialog.scheduleDay.theDate}`;

    let variables = { 
      id: showScheduleDialog.scheduleDay.id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: showScheduleDialog.job.id,
        name: "job schedule modified",
        description: activity_desc,
      },
      _set: {
        tech_id: showScheduleDialog.tech_id,
        status: showScheduleDialog.status,
        description: showScheduleDialog.description,
        modifiedOn: modifiedOn,
      },
    }

    console.log('variables')
    console.log(variables)

    //return;
    
    updateScheduleDayFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        insertNotificationFunction({object: {
          name: `Job ${showScheduleDialog.job.name} scheduling updated`,
          description: activity_desc,
          role: 'admin',
        }});

        setAlertState({
          open: true,
          message: 'Successfully modified job schedule',
        })
      }

      handleClose();
    })
  }  

  //////////////////

  const handleSubmitSpan = () => {
    let user_id = userData.id;
    var span_id = showScheduleDialog.scheduleDay.span_id;
    var statusHasChanged = showScheduleDialog.scheduleDay.status === showScheduleDialog.status;
    //var tech_id = showScheduleDialog.tech_id;

    console.log('handleSubmitSpan')
    console.log(showScheduleDialog)

    //return;
    // first delete all the dates in the date range
    var del_activity = {
      user_id: user_id,
      parent_id: showScheduleDialog.job.id,
      name: "job scheduled date span deleted",
      description: `${userData.alias} updated job board entry deleted scheduled dates for job ${showScheduleDialog.job.name}`,
    }

    var dayOne = showScheduleDialog.selectedSpan[0];
    var lastDay = showScheduleDialog.selectedSpan[showScheduleDialog.selectedSpan.length - 1];

    deleteDateSpanFunction( { span_id: span_id, activity: del_activity } ).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })

      } else {
        console.log('successfully deleted job schedule date span')
        console.log('showScheduleDialog')
        console.log(showScheduleDialog)
        let tech_id = showScheduleDialog.technician ? showScheduleDialog.technician.id : null;
        let today = moment(showScheduleDialog.startDate);
        let endDate = moment(showScheduleDialog.endDate);
        endDate.add(1, 'days');
    
        let modifiedOn = moment().format();
    
        let objects = []
    
        console.log('creating new date span')
        while(today.isBefore(endDate)){
          objects.push({
            span_id: span_id,
            theDate: today.format(),
            user_id: user_id,
            job_id: showScheduleDialog.job.id,
            tech_id: showScheduleDialog.tech_id,
            status: showScheduleDialog.status,
            description: showScheduleDialog.job.description,
          })
          today.add(1, 'days')
        } 
    
        let activity_desc = `${userData.alias} updated job board entry with start date ${showScheduleDialog.startDate} and end date ${showScheduleDialog.endDate}`;
        let variables = { 
          id: showScheduleDialog.job.id, 
          user_id: user_id,
          activity: {
            user_id: user_id,
            parent_id: showScheduleDialog.job.id,
            name: "job scheduled",
            description: activity_desc,
          },
          _set: {
            scheduled: {
              startDate: showScheduleDialog.startDate,
              endDate: showScheduleDialog.endDate,
              description: showScheduleDialog.description,
            },
            modifiedOn: modifiedOn,
          },
          objects: objects,
        }
    
        console.log('creating new job schedule date span')
        console.log(variables)

        updateJobFunction(variables).then((result)=>{
          if(result.error){
            console.log(`ERROR: ${result.error}`)
            setAlertState({
              open: true,
              message: `ERROR: ${result.error}`,
            })
          } else {
            insertNotificationFunction({object: {
              name: `Job ${showScheduleDialog.job.name} scheduling updated`,
              description: activity_desc,
              role: 'admin',
            }});

            setAlertState({
              open: true,
              message: 'Successfully modified job schedule',
            })
          }
        })  
      }

      handleClose();
    })
    


  }

  /////////

  const handleSubmitSpan_1 = () => {
    let user_id = userData.id;
    var span_id = showScheduleDialog.scheduleDay.span_id;
    let modifiedOn = moment().format();

    console.log('handleSubmit')
    console.log(showScheduleDialog)

    let variables = { 
      span_id: span_id, 
      activity: {
        user_id: user_id,
        parent_id: showScheduleDialog.job.id,
        name: "job schedule date range modified",
        description: `${userData.alias} modified job board entry with schedule date range`,
      },
      _set: {
        tech_id: showScheduleDialog.tech_id,
        status: showScheduleDialog.status,
        modifiedOn: modifiedOn,
      },
    }

    console.log('variables')
    console.log(variables)

    //return;
    updateScheduleDateSpanFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          open: true,
          message: 'Successfully modified job schedule',
        })
      }

      handleClose();
    })    
  }  

  //////////////////

  const handleDeleteScheduledDate = () => {
    console.log('handleDeleteScheduledDate');
    //console.log(node.id)
    var user_id = userData.id;
    var node_id = showScheduleDialog.scheduleDay.id;

    var activity = {
      user_id: user_id,
      parent_id: showScheduleDialog.job.id,
      name: "job scheduled date deleted",
      description: `${userData.alias} updated job board entry deleted scheduled date ${showScheduleDialog.scheduleDay.theDate} from job ${showScheduleDialog.job.name}`,
    }

    console.log('schedule')
    console.log(showScheduleDialog)

    console.log('activity')
    console.log(activity)

    //return;

    deleteDateFunction( { id: node_id, activity: activity } ).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          open: true,
          message: 'Successfully delete job schedule for this day',
        })
      }

      handleClose();
    })
  }  

  //////////////////

  const handleDeleteScheduledDateSpan = () => {
    console.log('handleDeleteScheduledDateSpan');
    //console.log(node.id)
    var user_id = userData.id;
    var span_id = showScheduleDialog.scheduleDay.span_id;

    var activity = {
      user_id: user_id,
      parent_id: showScheduleDialog.job.id,
      name: "job scheduled date span deleted",
      description: `${userData.alias} updated job board entry deleted scheduled dates for job ${showScheduleDialog.job.name}`,
    }

    console.log('schedule')
    console.log(showScheduleDialog)

    console.log('activity')
    console.log(activity)

    //return;

    deleteDateSpanFunction( { id: span_id, activity: activity } ).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          open: true,
          message: 'Successfully delete job schedule for this day',
        })
      }

      handleClose();
    })
  }  


  const handleCellClick = React.useCallback((job, sdate) => {
    console.log('ZSchedulePanel->handleCellClick')
    console.log(job)
    console.log(sdate)

    if(!userRoles.includes('admin')){
      console.log('insufficient permissions to modify schedule')
      return;
    }

    var today = moment();
    var xdate = moment(sdate.theDate);

    if(xdate.isBefore(today, 'day')){
      console.log('date chosen has passed and is locked');
      setAlertState({...alertState, open: true, message: 'date chosen has passed and is locked', error: true});
      return;
    }

    let selectedSpan = []
    let span_id = sdate.span_id ?? null;
    let tech_id = sdate.technician ? sdate.technician.id : null;
    if(span_id !== null){
      job.scheduled_dates.map((day)=>{
        if(day.span_id){
          if(day.span_id === span_id){
            selectedSpan.push(day)
          }
        }
      })
    }
    console.log('selectedSpan')
    console.log(selectedSpan)
    if(span_id !== null){
      let startDate = selectedSpan[0].theDate;
      let endDate = selectedSpan[selectedSpan.length - 1].theDate
      setShowScheduleDialog({...showScheduleDialog, show: true, status: sdate.status, description: sdate.description, job: job, tech_id: tech_id, scheduleDay: sdate, selectedSpan: selectedSpan, startDate: startDate, endDate: endDate})
    } else {
      setShowScheduleDialog({...showScheduleDialog, show: true, status: sdate.status, description: sdate.description, job: job, tech_id: tech_id, scheduleDay: sdate})      
    }
  },[showScheduleDialog,alertState]);

  //////////////////

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  const dragStart = (e, position, job, sdate) => {
    dragItem.current = position;
    console.log('dragStart')
    console.log(e)
    console.log(e.target.innerHTML);
    console.log(position)
    console.log(job)
    console.log(sdate)
  };

  const dragEnter = (e, position, job, sdate) => {
    console.log('dragEnter')
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
    console.log(position)
    console.log(job)
    console.log(sdate)    
  };  

  /*
  const dragDrop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
  };  
  */


  //////////////////////////////////

  return (
    <div>
      <ZJobBoardDetailPane isOpen={activeEntry.show} action={<div/>} handleClose={handleCloseDetailPane} entry={activeEntry.entry} static/>
     <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="scheduling_alert"
        onClose={()=>{
          setAlertState({...alertState, open: false, message: '', error: false})
        }}
        ContentProps={alertState.error === true      
        ? 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarErrorStyle
          }
        : 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarSuccessStyle
          }
        }
        message={alertState.message}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alertState, open: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />    
      <Container maxWidth="lg">
        {/* modify schedule day dialog */}
        <Box>
          <Dialog open={showScheduleDialog.show} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{showScheduleDialog.scheduleDay.span_id === null ? 'Update Job Schedule' : 'Update Job Schedule Date Range'}</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="startDate"
                        value={showScheduleDialog.startDate}
                        label="Start Date"
                        onChange={(value) =>{
                          setShowScheduleDialog({...showScheduleDialog, startDate: value})
                          //console.log("state->"+ JSON.stringify(showScheduleDialog));
                        }} 
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="endDate"
                        value={showScheduleDialog.endDate}
                        label="End Date"
                        onChange={(value) =>{
                          setShowScheduleDialog({...showScheduleDialog, endDate: value})
                          //console.log("state->"+ JSON.stringify(showScheduleDialog));
                        }} 
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />                    
                    </Grid>
                  </MuiPickersUtilsProvider> 
                </div>               
                <br/>
                <div>
                  <Grid container justifyContent="space-around">
                    <ZUserAutocomplete open={showScheduleDialog.show} label='Choose Tech' onChange={handleUserChange} default={showScheduleDialog.scheduleDay.technician ? showScheduleDialog.scheduleDay.technician : null}/>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={showScheduleDialog.status}
                      onChange={handleStatusChange}
                    >
                      <MenuItem value={'pending'}>Pending</MenuItem>
                      <MenuItem value={'active'}>Active</MenuItem>
                    </Select>
                  </Grid>
                </div>  
                <br/>
                <TextField
                  className={classes.descriptionField}
                  id="details"
                  label="Details"
                  defaultValue={showScheduleDialog.description}
                  multiline
                  rows={4}
                  variant="filled"
                  onChange={(event)=>{
                    setShowScheduleDialog({...showScheduleDialog, description: event.target.value});
                    //console.log("state->"+ JSON.stringify(showScheduleDialog));
                  }}                   
                /> 
              </FormControl> 
              </form>
            </DialogContent>
            <br/><br/>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Update
              </Button>
              <Button onClick={handleSubmitSpan} color="primary">
                Update Range
              </Button>              
              <Button onClick={handleDeleteScheduledDate} color="secondary">
                Delete Date
              </Button> 
              <Button onClick={handleDeleteScheduledDateSpan} color="secondary">
                Delete Date Range
              </Button>                            
            </DialogActions>
          </Dialog>
        </Box>
      </Container>    
      {/* schedule table */}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Job#</TableCell>
              {
                state.fullSchedule.slice(page*7, (page*7)+7).map((dd,index)=>{
                  if(moment().isSame(dd,'day')){
                    return (
                      <TableCell  data-tip={`${dd.format("dddd")}`} data-for="schedule_panel_tooltip"  align="center">
                        <div style={{backgroundColor:'lightgreen', color: 'black',}}>{dd.format("MMMD,YYYY")}</div>
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell data-tip={`${dd.format("dddd")}`} data-for="schedule_panel_tooltip" align="center">
                        {dd.format("MMMD,YYYY")}
                      </TableCell>
                    )
                  }
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {calendar.map((job) => {
              /*
              if(filter.value.length > 0){
                if(!job.name.includes(filter)) return (<div></div>)
              }
              */

              var jobState = []
              const bloc = new JobBoardBloC(job);
              //if(job.customerPO){
              if(bloc.isValidCustomerPO()){
                jobState.push(<VerifiedUserOutlinedIcon fontSize="small" data-tip="Customer PO Received" data-for="schedule_panel_tooltip"/>);
              } else {
                jobState.push(<VerifiedUserOutlinedIcon color="disabled" fontSize="small" data-tip="No Active Customer PO" data-for="schedule_panel_tooltip"/>);
              }

              if(job.tool || job.node){
                jobState.push(<BuildOutlinedIcon fontSize="small" data-tip="Tooling Booked" data-for="schedule_panel_tooltip"/>);
              } else {
                jobState.push(<BuildOutlinedIcon color="disabled" fontSize="small" data-tip="Tooling Not Booked" data-for="schedule_panel_tooltip"/>);
              }

              //PortraitOutlinedIcon
              if(job.technician){
                jobState.push(<PortraitOutlinedIcon fontSize="small" data-tip="Technician Assigned" data-for="schedule_panel_tooltip"/>);
              } else {
                jobState.push(<PortraitOutlinedIcon color="disabled" fontSize="small" data-tip="Technician Not Assigned" data-for="schedule_panel_tooltip"/>);
              }               

              if(job.scheduled_dates.length > 0){
                jobState.push(<DateRangeIcon fontSize="small" data-tip="Job Active And Has Days Scheduled" data-for="schedule_panel_tooltip"/>);
              } else {
                jobState.push(<DateRangeIcon color="disabled" fontSize="small" data-tip="Job Active But Has No Days Scheduled" data-for="schedule_panel_tooltip"/>);
              }               


              if(job.isTravelBooked){
                jobState.push(<FlightTakeoffOutlinedIcon fontSize="small" data-tip="Travel or Shipping Arrangements Made" data-for="schedule_panel_tooltip"/>);
              } else {
                jobState.push(<FlightTakeoffOutlinedIcon color="disabled" fontSize="small" data-tip="Travel or Shipping Arrangements Not Made" data-for="schedule_panel_tooltip"/>);
              }       

              //if(job.invoiceDate){
              if(bloc.isInvoiced()){
                jobState.push(<MonetizationOnOutlinedIcon fontSize="small" data-tip="Customer Invoiced" data-for="schedule_panel_tooltip"/>);
              } else {
                jobState.push(<MonetizationOnOutlinedIcon color="disabled" fontSize="small" data-tip="Customer Not Invoiced" data-for="schedule_panel_tooltip"/>);
              }                               

              return (
              <TableRow key={job.id}>
                <TableCell component="th" scope="row" style={{position: 'sticky', left: 0, zindex: 1, background: '#fff',}}>
                  <div>
                    <Button 
                      component="span"
                      color="primary"
                      size="small"
                      data-tip={`${job.client ? job.client.name : ''} - ${job.description}`}
                      data-for="schedule_panel_tooltip" 
                      endIcon={
                        <div>
                          {jobState}
                        </div>
                      }
                      style={{ marginLeft: 4 }}
                      onClick={userRoles.includes('admin') ? (event) => { 
                        let path = generatePath('/jobboard_entry/:id', {
                          id: job.id}
                          )
                        history.push(path)
                        } : ()=>setActiveEntry({entry: job, show: true})
                      }          
                    >
                      {job.name}
                    </Button>                  
                  </div>               
                </TableCell>
                { job.days ? 
                  job.days.slice(page*7, (page*7)+7).map((ddd,index)=>{
                    //console.log(`day: ${JSON.stringify(ddd)}`)
                    return (
                      ddd.status === 'active'
                      ? <TableCell padding='none' align="center" data-tip={ddd.description} data-for="schedule_panel_tooltip">
                          <div value={{job,ddd}} onClick={()=>handleCellClick(job, ddd)} style={{backgroundColor:'darkblue', color: 'white',}} draggable>{ddd.technician.alias}</div>
                        </TableCell>
                      : 
                      ddd.status === 'pending'
                      ? <TableCell padding='none' align="center" data-tip={ddd.description} data-for="schedule_panel_tooltip">
                          <div value={{job,ddd}} onClick={()=>handleCellClick(job, ddd)} style={{backgroundColor:'lightgray', color: 'black',}} draggable>{ddd.technician.alias}</div>
                        </TableCell>
                      : 
                        <TableCell padding='none' align="center"><div></div></TableCell>
                    )
                  })
                  : <TableCell/>
                }
              </TableRow>
            )})}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TextField
                style={{ marginLeft: 4 }}
                placeholder="Filter…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(event) =>{
                  setFilter({...filter, value: event.target.value})
                }} 
              />
              <TablePagination
                //rowsPerPageOptions={[7, 14, 28, { label: 'All', value: -1 }]}
                colSpan={8}
                daysAhead={daysAhead}
                daysBack={daysBack}
                count={daysAhead + daysBack}
                rowsPerPage={7}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'days per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>        
        </Table>
        <ReactTooltip id="schedule_panel_tooltip"/>
      </TableContainer>
    </div>
  );
}

export default ZSchedulePanel;
