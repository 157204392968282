import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

//import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { getAllSuppliers } from 'queries';
import { addSupplier, updateSupplier } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllSuppliers } from 'queries_urql';
import { addSupplier, updateSupplier } from 'mutations_urql';
import { subscribeAllSuppliers } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddSupplierDialog from 'components/dialogs/ZAddSupplierDialog';
import ZSupplierDetailPane from 'components/core/ZSupplierDetailPane';


//////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////

const ZSuppliersPanel = (props) => {
  const history = useHistory();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showDetailPane, setShowDetailPane] = React.useState(false);
  const [activeSupplier, setActiveSupplier] = React.useState({});

  const classes = useStyles();

  let query = getAllSuppliers;
  let variables = {};
  let query_params = { 
    pollInterval: 5000,
  }

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 60,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/supplier/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 200,
      editable: 'never',
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 350,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: true,
      cellClassName: (params) =>
        clsx('super-app', {
          negative: params.value == 'closed',
          positive: params.value == 'accepted',
        }),      
    },
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 150,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    },
    {
      field: 'product_lines_aggregate.aggregate.count',
      title: 'Product Lines',
      headerName: 'Product Lines',
      minWidth: 200,
      editable: 'never',
      hide: false,
      renderCell: (params) => (
        <div>{params.row.product_lines_aggregate.aggregate.count}</div>
      ),
    },
    {
      field: 'products_aggregate.aggregate.count',
      title: 'Products',
      headerName: 'Products',
      minWidth: 200,
      editable: 'never',
      hide: false,
      renderCell: (params) => (
        <div>{params.row.products_aggregate.aggregate.count}</div>
      ),
    },
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZSuppliersPanel::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleClickOpen = () => {
    console.log("ZSuppliersPanel::handleClickOpen->open dialog wizard")
    setShowDialog(true);
    console.log("ZSuppliersPanel::handleClickOpen == "+ showDialog);
  };
  
  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCloseDetailPane = () => {
    setShowDetailPane(false);
  };

  /*
  const { loading, error, data }  = useQuery(query, query_params);
  const [updateFunction] = useMutation(updateSupplier);
  const [insertFunction] = useMutation(addSupplier);

  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  //if (error) return (<div/>);
  */

  let rows = []

  const [ result, reexecuteQuery ] = useQuery({
    query: query,
  });

  const [updateSupplierResult, updateFunction] = useMutation(updateSupplier);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };
 
  const [res] = useSubscription({ query: subscribeAllSuppliers }, handleSubscription); 

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  rows = data.zeus_supplier.slice(0)

  ////////////////////////////////////

  return (
    <div className={classes.root}>
    <ZAddSupplierDialog open={showDialog} handleClose={handleClose}/>  
    <ZSupplierDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} supplier={activeSupplier}/>

    <Card variant="outlined" elevation={2}>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          Suppliers
        </Typography>
        <IconButton
          onClick={handleClickOpen}
          style={{color:"white"}}
          color="inherit"
          aria-label="add row"
          edge="start"
          data-tip="add supplier"
          data-for="supplier_panel_tooltip" 
        >
          <AddCircleOutlineIcon/>
        </IconButton>
      </Box>
        <DataGrid autoHeight
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick={false}
          getRowClassName={(params) =>
            clsx('super-app', {
              negative: params.row.status === 'closed',
              positive: params.row.status === 'accepted',
            })
          }
          options={{
            toolbar: true,
            filtering: true,
            search: true,
            grouping: true,
            exportButton: true,
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          onCellEditCommit={handleCellEditCommit}
          onRowClick={(params, evt) => {
            let supplier = params.row;
            console.log("selected supplier")
            console.log(supplier)
            setActiveSupplier(supplier)
            setShowDetailPane(true)
          }}
        />
      </CardContent>
    </Card>
    <ReactTooltip id="supplier_panel_tooltip"/>
    </div>
  ); 
}
  
export default ZSuppliersPanel;
