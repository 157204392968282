import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { addAnalysisBloC } from 'mutations_urql';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select'; //FormControl
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ReactTooltip from 'react-tooltip';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { uuid } from 'uuidv4';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  snackbarStyle: {
    backgroundColor: "lightyellow",
    minHeight: 400,
  },
}));

//////////////////////
/*props
    {
        

    }
*/
//////////////////////

const ZAddAnalysisBloCButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const snackbarEl = React.useRef(null);

  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({
    open: false,
    alias: '',
    bloc: null,
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });


  console.log('ZAddAnalysisBloCButton->props')
  console.log(props)

  const [insertResult,     insertFunction]     = useMutation(addAnalysisBloC);

  const handleClose = () => {
    setState({...state, open: false})
  }

  let parent_id  = null;
  if(props.parent){
    parent_id = props.parent.id;
  }

  ///////////////

  const handleSubmit = () => {
    console.log("ZAddAnalysisBloCButton->submit")
    let user_id = userData.id

    let bloc = props.bloc_types.find((b)=>{
      return b.id === state.bloc_id;
    });

    let variables = { 
      activities: [{
        user_id: user_id,
        parent_id: parent_id,
        name: "added AI analysis component",
        description: `${userData.alias} updated client and added ${bloc.alias} analysis component`,
      }],
      object: {
        name: bloc.alias,
        analysis_type: bloc.name,
        status: "enabled",
        parent_id: props.parent.id,
        analysis_bloc_type_id: bloc.id,
        description: bloc.description,
        default_settings: bloc.default_settings,
      },
    }

    console.log(variables);

    //return;

    insertFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        setAlertState({
          open: true,
          message: 'Successfully added analysis component to client',
          severity: 'info',
        })
      }
    })

    handleClose();
  }

  ////////////////

  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to open snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              //setOpenAlert(false)
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    /> 
  );

  return (
    <div>
      <div ref={snackbarEl} />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Prodigy:AI Modules</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <div>
                  <Paper sx={{ maxWidth: 400, my: 10, mx: 'auto', p: 2 }}>
                    <FormControl className={classes.formControl}>
                    <InputLabel id="version-select-label">Select AI:Module</InputLabel>
                      <Select
                        labelId="module-select-label"
                        id="module-simple-select"
                        value={state.bloc_id}
                        onChange={(event,item) =>{
                          setState({...state, bloc_id: item.props.value})
                          console.log(state);
                        }} 
                      >
                        {
                          props.bloc_types.map((bloc)=>{
                            return(
                              <MenuItem value={bloc.id}>{bloc.alias}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Paper>  
                </div>  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8, marginBottom: 5, marginTop: 5 }}
        variant="outlined"
        data-tip="add AI analysis module"
        data-for="analysis_bloc_button_tooltip" 
        startIcon={ props.done ? <CheckIcon color="secondary" /> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true})
        }}       
      >
        Add Prodigy:AI Analysis Module
      </Button>
      <ReactTooltip id="analysis_bloc_button_tooltip"/>
    </div>
  );

}

export default ZAddAnalysisBloCButton;

