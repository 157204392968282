import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';
import { red, green, cyan, purple } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAnnotationsForSalesDocument } from 'queries';
import { addAnnotation, updateAnnotation } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAnnotationsForSalesDocument} from 'queries_urql';
import { addAnnotation, updateAnnotation, updateAsset } from 'mutations_urql';
import { subscribeAssetsForParent } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShutterSpeedIcon from '@material-ui/icons/ShutterSpeed';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import ZAddAssetDialog from 'components/dialogs/ZAddAssetDialog';
import ZAddAssetSurveyDialog from 'components/dialogs/ZAddAssetSurveyDialog';
import AssetBloC from 'blocs/AssetBloC';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  asset_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.overdue': {
      backgroundColor: purple[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },    
  },
  avatar: {
    backgroundColor: green[500],
  },
  annotations_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  items: {}

}
*/

/////////////////////////////////////////

const ZAssetsList = (props) => {
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    status: 'N/A',
    message: '',
    asset: null,
    showSurveyDialog: false,
    showAssetDialog: false,
  });
  const [anchorHeader, setAnchorHeader] = React.useState(null);

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });
  

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="retired">retired</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/asset/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                setState({...state, asset: params.row, showSurveyDialog: true})
              }}          
            >
              <DeveloperBoardIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: props.nameW ?? 180,
      editable: true,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 180,
      editable: false,
      hide: false,
    },    
    {
      field: 'currentCondition',
      title: 'Current Condition',
      headerName: 'Current Condition',
      minWidth: props.conditionW ?? 180,
      editable: false,
      hide: false,
      valueGetter: (params) => {
        if(params.row.currentCondition === null){
          let size = params.row.surveys.length;
          if(size > 0){
            let last_survey = params.row.surveys[0];
            return last_survey.severity;
          } else {
            return 'N/A'
          }
        } else {
          return params.row.currentCondition;
        }
      },
    },
    {
      field: 'since_survey',
      title: 'Last Survey',
      headerName: 'Last Survey',
      minWidth: 180,
      editable: false,
      hide: false,
      valueGetter: (params) =>{
        let size = params.row.surveys.length;
        if(size > 0){
          let last_survey = params.row.surveys[0];
          return moment(last_survey.theDate).fromNow();
        } else {
          return 'N/A'
        }
      },
    },
    {
      field: 'recommendations',
      title: 'Recommendations',
      headerName: 'Recommendations',
      minWidth: 240,
      editable: false,
      hide: false,
      valueGetter: (params) =>{
        let size = params.row.surveys.length;
        if(size > 0){
          let last_survey = params.row.surveys[0];
          return last_survey.recommendations;
        } else {
          return 'N/A'
        }
      },
    }, 
    {
      field: 'summary',
      title: 'Summary',
      headerName: 'Summary',
      minWidth: 240,
      editable: false,
      hide: false,
      valueGetter: (params) =>{
        let size = params.row.surveys.length;
        if(size > 0){
          let last_survey = params.row.surveys[0];
          return last_survey.summary;
        } else {
          return 'N/A'
        }
      },
    },        
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      minWidth: 180,
      editable: true,
      hide: false,
      renderEditCell: renderStatusEditInputCell, 
    },
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 140,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
    {
      field: 'modifiedOn',
      title: 'Updated',
      headerName: 'Updated',
      type: 'date',
      minWidth: 140,
      editable: false,
      renderCell: (params) => {
        if(params.row.modifiedOn){
          return(
            <Moment format="YYYY-MM-DD">
              {params.row.modifiedOn}
           </Moment>
          )
        } else {
          return(
            <div></div>
          )
        }
      },
    },    
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZAssetsList::handleCellEditCommit');

      var _set = {}      
      _set[field] = value
      _set['modifiedOn'] = moment().format()

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set,
        activity: {
          user_id: userData.id,
          parent_id: id,
          name: "asset updated",
          description: `${userData.alias} updated asset ${field} to ${value}`,            
        }         
      }
      console.log(variables)      

      updateFunction(variables).then(result => {
        if(result.error){
          console.log(`ERROR updating asset ${result.error}`)
          setAlertState({
            open: true,
            message: `ERROR updating asset ${result.error}`
          })          
        } else {
          console.log(`updated annotation`)
          setAlertState({
            open: true,
            message: "Successfully updated asset"
          })
        }
      })
    },
    [],
  );

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={()=>setState({...state, showAssetDialog: true})}>New Asset</MenuItem>
      </Menu>
    </div>  
  )

  console.log(props)

  let items = []
  let query = getAnnotationsForSalesDocument
  let variables = {}

  //let skip_query = true;
  let skip_sub = true;
  let title = "Assets"

  if(props.static){
    skip_sub = true;
  }

  if(props.realtime || props.live){
    skip_sub = false;
  }

  if( props.title){
    title = props.title
  }

  if( props.items){
    skip_sub = true;
  }

  if( props.client){
    variables = { parent_id: props.client.id }
  }

  console.log(props)

  /*
  const { loading, error, data }  = useQuery(query,{ variables: variables, pollInterval: 5000, skip: skip_query });
  const [updateFunction] = useMutation(updateAnnotation);
  //const [insertFunction] = useMutation(addQuotation);
  if(skip_query === false){
    if (loading) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }
  

  const [ result, reexecuteQuery ]  = useQuery({
    query: query,
    variables: variables,
    pause: skip_query,
  });
  */
  const [updateResult, updateFunction] = useMutation(updateAsset);

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return response;
  };
 
  const [result] = useSubscription({ 
    query: subscribeAssetsForParent, 
    variables: variables,
    pause: skip_sub, 
  }, handleSubscription); 

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if(skip_sub === false){
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_sub === true){
    items = props.items
  } else {
    items = data.zeus_asset
  }

  console.log(items);

  var now = moment();

  items.forEach((asset)=>{
    let size = asset.surveys.length;
    var surv_freq = asset.survey_frequency;
    

    if(size > 0){
      let last_survey = asset.surveys[0];
      var next_slot = moment(last_survey.theDate).add(surv_freq, 'seconds')

      if(now > next_slot){
        asset.overdue = true;
      } else {
        asset.overdue = false;
      }
    }
  })

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="assets actions"
      data-for="assets_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>
  )

  if(props.addColumns){
    console.log('adding outside columns');
    for(var col of props.addColumns){
      columns.push(col);
    }
  }

  //////////////////////////////////

  return (
    <div>
      {/* <ZAddAssetDialog open={state.showAssetDialog} onClose={()=>setState({...state, showAssetDialog: false})} client={props.client} /> */}
      <ZAddAssetSurveyDialog open={state.showSurveyDialog} onClose={()=>setState({...state, showSurveyDialog: false})} client={props.client} asset={state.asset} />
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        message={alertState.message}
        key="alert_bar"
        onClose={()=>{
          console.log('trying to open snackbar')
          //setOpenAlert(false)
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                console.log('trying to close snackbar')
                //setOpenAlert(false)
                setAlertState({
                  open: false,
                  message: ''
                })
              }}
            >
              Close
            </Button>
          </div>
        }
      />     
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="asset" className={classes.annotations_avatar}>
                <ShutterSpeedIcon />
              </Avatar>
            }      
            action={props.action ? props.action : defaultAction}            
            title={title}
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>
          {
            props.showStatus ?
            <div>
              <Paper>
                <Typography>STATUS: {state.status}</Typography>
              </Paper>
              <br/><Divider/><br/>
            </div>
            : <div></div>
          }           
            <Paper className={classes.asset_row}>
              <DataGrid autoHeight
                rows={items}
                columns={columns}
                density='compact'
                pageSize={props.pageSize ? props.pageSize : 10}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                getRowClassName={(params) =>
                  clsx('super-app', {
                    negative: params.row.currentCondition === 'Unacceptable' ? true : params.row.currentCondition === 'Reportable' ? true : params.row.currentCondition ===  'Unsatisfactory' ? true : false,
                    positive: params.row.currentCondition === 'Good' ? true : params.row.currentCondition === 'Acceptable' ? true : false,
                    overdue: params.row.overdue,
                  })
                }
                options={{
                  toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  exportButton: true,
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                onCellEditCommit={handleCellEditCommit}
                onRowEnter={props.showStatus ? (params,event)=>{
                  console.log('mouse enter asset row')
                  let ass = params.row;
                  const bloc = new AssetBloC(ass);
                  let message = bloc.isOverdue();
                  console.log(message)
                  //localMessage = message;
                  setState({...state, message: message});
                  //return message;
                } : null}
                onRowLeave={props.showStatus ? (params,event)=>{
                  console.log('mouse enter asset row')
                  //localMessage = message;
                  setState({...state, status: 'N/A'});
                  //return message;
                } : null}
              />
            </Paper>
          </CardContent>
        </CardActionArea>
        <ReactTooltip id="assets_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZAssetsList;
