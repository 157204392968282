import React from 'react';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
import { useQuery, useMutation } from '@apollo/client';
import { getProductLine,  getQuotesForClient } from 'queries';
import { addProductLine, updateProductLine } from 'mutations';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZClientBox from 'components/boxes/ZClientBox'; 
import ZSupplierBox from 'components/boxes/ZSupplierBox'; //ZSupplierBox
import ZProductLineBox from 'components/boxes/ZProductLineBox'; //ZSupplierBox
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZProductLinesList from 'components/lists/ZProductLinesList';//ZProductLinesList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZProductsList from 'components/lists/ZProductsList';// ZProductsList

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const ZProductLineView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  let { id } = useParams();
  const [state, setState] = React.useState({});
  const [showDialog, setShowDialog] = React.useState(false);

  console.log('supplier_id: '+ id)

  const { loading, error, data } = useQuery(getProductLine, {
    variables: { id: id },
  });

  if (loading) return (<h2>Loading supplier... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log('supplier data')
  console.log(data)


  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;


  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ZProductLineBox id='supplier_box' 
              productLine={data.zeus_product_line_by_pk}
              name={data.zeus_product_line_by_pk.name} 
              description={data.zeus_product_line_by_pk.description} 
              createdOn={data.zeus_product_line_by_pk.createdOn}
              annotations={data.zeus_product_line_by_pk.annotations}
              fullBox
            />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZProductsList key='products_panel' productLine={data.zeus_product_line_by_pk} data={data.zeus_product_line_by_pk.products}  />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ZQuotationsList key='quotations_panel' supplier={data.zeus_supplier_by_pk}  />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={6}>

          </Col>
          <Col xs={6}>

          </Col>
        </Row>
      </Container>
    </div>
  )


}

export default ZProductLineView;
