import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';
import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAnnotationsForSalesDocument } from 'queries';
import { addAnnotation, updateAnnotation } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAnnotationsForSalesDocument} from 'queries_urql';
import { addAnnotation, updateAnnotation  } from 'mutations_urql';
import { subscribeAnnotationsForParent } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  annotation_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  annotations_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  items: {}

}
*/

/////////////////////////////////////////

const ZAnnotationsList = (props) => {
  const history = useHistory();
  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });
  const [userData, setUserData] = useLocalStorage('userData');

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/annotation/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: props.nameW ?? 180,
      editable: true,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 280,
      editable: true,
      hide: false,
    },
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 180,
      editable: true,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
    {
      field: 'modifiedOn',
      title: 'Updated',
      headerName: 'Updated',
      type: 'date',
      minWidth: 180,
      editable: false,
      renderCell: (params) => {
        if(params.row.modifiedOn){
          return(
            <Moment format="YYYY-MM-DD">
              {params.row.modifiedOn}
           </Moment>
          )
        } else {
          return(
            <div></div>
          )
        }
      },
    },    
    {
      field: 'files',
      title: 'Files',
      headerName: 'Files',
      minWidth: 180,
      editable: false,
      renderCell: (params) => {       
        var noteState = []       
        if(params.row.attachments_aggregate.aggregate.count > 0){
          noteState.push(<InsertDriveFileIcon fontSize="small" />)
        }

        if(params.row.images_aggregate.aggregate.count > 0){
          noteState.push(<ImageIcon fontSize="small" />)
        }
        return noteState;
      },      
    },
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZAnnotationsList::handleCellEditCommit');

      var _set = {}
      let modifiedOn = uuidv4()
      _set[field] = value
      _set['modifiedOn'] = moment().format()

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set 
      }
      console.log(variables)      

      updateFunction(variables).then(result => {
        if(result.error){
          console.log(`ERROR updating annotation ${result.error}`)
          setAlertState({
            open: true,
            message: `ERROR updating annotation ${result.error}`
          })          
        } else {
          console.log(`updated annotation`)
          setAlertState({
            open: true,
            message: "Successfully updated annotation"
          })
        }
      })
    },
    [],
  );

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Note</MenuItem>
      </Menu>
    </div>  
  )

  console.log(props)

  let items = []
  let query = getAnnotationsForSalesDocument
  let variables = {}

  let skip_query = true;
  let skip_sub = false;
  let title = "Notes"

  if(props.static){
    skip_query = true;
  }

  if(props.realtime === undefined || props.live === undefined){
    skip_sub = true;
  }

  if( props.title){
    title = props.title
  }

  if( props.items){
    skip_query = true;
  }

  if( props.client !== undefined){
    variables = { client_id: props.client.id }
  }

  console.log(props)

  /*
  const { loading, error, data }  = useQuery(query,{ variables: variables, pollInterval: 5000, skip: skip_query });
  const [updateFunction] = useMutation(updateAnnotation);
  //const [insertFunction] = useMutation(addQuotation);
  if(skip_query === false){
    if (loading) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }
  */

  const [ result, reexecuteQuery ]  = useQuery({
    query: query,
    variables: variables,
    pause: skip_query,
  });

  const [updateAnnotationResult, updateFunction] = useMutation(updateAnnotation);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  /*
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = items.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    items = mergedRows
  };
 
  const [res] = useSubscription({ query: subscribeAnnotationsForParent, pause: skip_sub }, handleSubscription); 
  */

  if(skip_query === false){
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_query === true){
    items = props.items
  } else {
    items = data.zeus_annotation
  }

  console.log(items);

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="notes actions"
      data-for="annotations_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>
  )

  //////////////////////////////////

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        message={alertState.message}
        key="alert_bar"
        onClose={()=>{
          console.log('trying to open snackbar')
          //setOpenAlert(false)
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                console.log('trying to close snackbar')
                //setOpenAlert(false)
                setAlertState({
                  open: false,
                  message: ''
                })
              }}
            >
              Close
            </Button>
          </div>
        }
      />   

      {/* main component */} 
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="annotation" className={classes.annotations_avatar}>
                <AssignmentTurnedInIcon />
              </Avatar>
            }      
            action={props.action ? props.action : defaultAction}
            
            title={title}
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>
            <Paper className={classes.activity_row}>
              <DataGrid autoHeight
                rows={items}
                columns={columns}
                pageSize={props.pageSize ? props.pageSize : 10}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                getRowClassName={(params) =>
                  clsx('super-app', {
                    negative: params.row.status === 'active',
                    positive: params.row.status === 'closed',
                  })
                }
                options={{
                  toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  exportButton: true,
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                onCellEditCommit={handleCellEditCommit}
              />
            </Paper>
          </CardContent>
        </CardActionArea>
        <ReactTooltip id="annotations_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZAnnotationsList;
