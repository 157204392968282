import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';
import { red, green, cyan, yellow, orange, grey } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAnnotationsForSalesDocument } from 'queries';
import { addAnnotation, updateAnnotation } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getSurveysForAsset} from 'queries_urql';
import { updateAssetSurvey  } from 'mutations_urql';
import { subscribeSurveysForAsset } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';//LinearProgress
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZSeverityEditInputCell from 'components/core/ZSeverityEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  severity: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.noaccess': {
      backgroundColor: grey[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.nostatus': {
      backgroundColor: grey[200], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unacceptable': {
      backgroundColor: red[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unsatisfactory': {
      backgroundColor: orange[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.reportable': {
      backgroundColor: yellow[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },        
    '& .super-app.acceptable': {
      backgroundColor: green[50], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.good': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  invoices_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  items: {}

}
*/

/*
status: {
  pending
  active
}
*/

/////////////////////////////////////////

const ZAssetSurveyList = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [alertState, setAlertState] = React.useState({
    severity: 'info',
    open: false,
    message: '',
  });
  const [userData, setUserData] = useLocalStorage('userData');

  const renderStatusEditInputCell = (params) => {
    console.log('renderStatusEditInputCell')
    console.log(params)
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem  value="active">active</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const renderSeverityEditInputCell = (params) => {
    console.log('renderSeverityEditInputCell')
    console.log(params)
    return (
      <ZSeverityEditInputCell {...params} >
        <MenuItem       value="Good">Good</MenuItem>
        <MenuItem value="Acceptable">Acceptable</MenuItem>
        <MenuItem value="Reportable">Reportable</MenuItem>
        <MenuItem value="Unsatisfactory">Unsatisfactory</MenuItem>
        <MenuItem value="Unacceptable">Unacceptable</MenuItem>
        <MenuItem  value="No Status">No Status</MenuItem>
        <MenuItem  value="No Access">No Access</MenuItem>
      </ZSeverityEditInputCell>
    );   
  }

  const renderConditionEditInputCell = (params) => {
    console.log('renderConditionEditInputCell')
    console.log(params)
    return (
      <ZSeverityEditInputCell {...params} >
        <MenuItem value="Good">Good</MenuItem>
        <MenuItem value="Acceptable">Acceptable</MenuItem>
        <MenuItem value="Reportable">Reportable</MenuItem>
        <MenuItem value="Unsatisfactory">Unsatisfactory</MenuItem>
        <MenuItem value="Unacceptable">Unacceptable</MenuItem>
        <MenuItem value="No Status">No Status</MenuItem>
        <MenuItem value="No Access">No Access</MenuItem>
      </ZSeverityEditInputCell>
    );   
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
          <strong>
            <ButtonGroup size="small">
              <div>        
                <IconButton 
                  component="span"
                  //variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let path = generatePath('/asset_survey/:id', {
                      id: params.row.id}
                      )
                    history.push(path)
                  }}          
                >
                  <OpenInNewIcon />
                </IconButton>
              </div>
            </ButtonGroup>
          </strong>
        </div>
      ),
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      minWidth: 120,
      editable: true,
      hide: false,
      renderEditCell: renderStatusEditInputCell,
      cellClassName: (params) =>
        clsx('super-app', {
          negative: params.value === 'active',
          positive: params.value === 'pending',
        }),         
    }, 
    {
      field: 'severity',
      title: 'Severity',
      headerName: 'Severity',
      minWidth: 120,
      editable: true,
      hide: false,
      valueGetter: (params) =>{
        return params.row.severity;
      },      
      renderEditCell: renderSeverityEditInputCell,
      cellClassName: (params) =>
        clsx('severity . super-app', {
          good: params.row.severity === 'Good',
          acceptable: params.row.severity === 'Acceptable',
          reportable: params.row.severity === 'Reportable',
          unsatisfactory: params.row.severity === 'Unsatisfactory',
          unacceptable: params.row.severity === 'Unacceptable',
          nostatus: params.row.severity === 'No Status',
          noaccess: params.row.severity === 'No Access',
        }),         
    }, 
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 150,
      editable: false,
      hide: false,
      valueGetter: (params) =>{
        //console.log(params.row)
        return params.row.asset ? params.row.asset.name : props.parent ? props.parent.name : 'N/A'
      },
      renderCell: (params) => (
        <div>
          <strong>
            <ButtonGroup size="small">
              <div>        
                <Button 
                  component="span"
                  //variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let path = generatePath('/asset/:id', {
                      id: params.row.asset.id}
                      )
                    history.push(path)
                  }}          
                >
                  {params.row.asset ? params.row.asset.name : props.parent ? props.parent.name : 'N/A'}
                </Button>
              </div>
            </ButtonGroup>
          </strong>
        </div>
      ),     
    },   
    {
      field: 'theDate',
      title: 'Survey Date',
      headerName: 'Survey Date',
      type: 'date',
      minWidth: props.activeW ?? 170,
      editable: true,
      renderCell: (params) => {
        if(params.row.theDate){
          return(
            <Moment format="YYYY-MM-DD">
              {params.row.theDate}
           </Moment>
          )
        } else {
          return(
            <div></div>
          )
        }
      },
    },    
    {
      field: 'condition_value',
      title: 'Condition',
      headerName: 'Condition',
      minWidth: props.summaryW ?? 240,
      editable: false,
      hide: false,     
    },  
    {
      field: 'summary',
      title: 'Summary',
      headerName: 'Summary',
      minWidth: props.summaryW ?? 240,
      editable: false,
      hide: false,
    }, 
    {
      field: 'recommendations',
      title: 'Recommendations',
      headerName: 'Recommendations',
      minWidth: props.recommendationsW ?? 240,
      editable: true,
      hide: false,
    },   
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 140,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },       
  ];

  if(props.addColumns){
    console.log('adding outside columns');
    for(var col of props.addColumns){
      columns.push(col);
    }
  }

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZAssetSurveyList::handleCellEditCommit');
      console.log(`id=${id}  field=${field}  value=${value}`)

      /*
      if(field === 'status'){
        if(value === 'active'){

          insertNotificationFunction({object: {
            name: `Current condition ${state.severity} reported for ${state.asset.name}`,
            description: `${userData.alias} reported the current condition for asset ${state.client.name} - ${state.asset.name} as ${object.severity}`,
            role: 'manager',
          }}); 

        }
      }
      */
     
      var _set = {}
      _set[field] = value
      _set['modifiedOn'] = moment().format()

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set,
        activities: [{
            user_id: userData.id,
            parent_id: id,
            name: "asset survey updated",
            description: `${userData.alias} updated asset survey entry ${field} to ${value}`,            
          },
          {
            user_id: userData.id,
            parent_id: props.parent.id,
            name: "asset survey updated",
            description: `${userData.alias} updated asset survey entry ${field} to ${value}`,             
          },
        ]
      }
      console.log(variables)      

      updateFunction(variables).then(result => {
        if(result.error){
          console.log(`ERROR updating asset survey entry ${result.error}`)
          setAlertState({
            open: true,
            message: `ERROR updating asset survey entry ${result.error}`,
            severity: 'error',
          })          
        } else {
          console.log(`updated asset survey entry`)
          setAlertState({
            open: true,
            message: "Successfully updated asset survey entry",
            severity: 'info',
          })
        }
      })
    },[]);

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  const handleEditRowsModelChange = React.useCallback((model) => {
    console.log('handleEditRowsModelChange');
    console.log(model)
    setEditRowsModel(model);
  }, []);

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Note</MenuItem>
      </Menu>
    </div>  
  )

  console.log(props)

  let items = []
  let query = getSurveysForAsset
  let variables = {}

  let skip_query = false;
  let skip_sub = false;

  if(props.static){
    skip_query = true;
    skip_sub = true
  }

  if(!props.realtime || !props.live){
    skip_sub = true;
  }

  if( props.items){
    skip_query = true;
    skip_sub = true;
  }

  if( props.asset){
    variables = { asset_id: props.asset.id }
  }

  console.log(props)

  /*
  const [ result, reexecuteQuery ]  = useQuery({
    query: query,
    variables: variables,
    pause: skip_query,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;
  */

  const [updateResult, updateFunction] = useMutation(updateAssetSurvey);

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return response;
  };
 
  const [result] = useSubscription({ 
    query: subscribeSurveysForAsset, 
    variables: variables,
    pause: skip_sub, 
  }, handleSubscription); 

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if(skip_sub === false){
    if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_sub === true){
    items = props.items
  } else {
    items = data.zeus_asset_survey
  }

  console.log(items);

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="asset survey actions"
      data-for="survey_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>
  )

  //////////////////////////////////

  return (
    <div>
      {/* alert component */}
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="alert_bar"
        onClose={()=>setAlertState({...alertState, open: false})}
      >   
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>       
      </Snackbar> 
      {/* main component */}
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="asset surveys" data-tip="add asset survey" data-for="survey_list_tooltip"  className={classes.avatar}>
              <DeveloperBoardIcon />
            </Avatar>
          }      
          action={props.action ? props.action : defaultAction}         
          title={props.title ? props.title : 'Asset Surveys'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper className={classes.severity}>
            <DataGrid autoHeight
              rows={items}
              columns={columns}
              density="compact"
              pageSize={props.pageSize ? props.pageSize : 10}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  good: params.row.severity === 'Good',
                  acceptable: params.row.severity === 'Acceptable',
                  reportable: params.row.severity === 'Reportable',
                  unsatisfactory: params.row.severity === 'Unsatisfactory',
                  unacceptable: params.row.severity === 'Unacceptable',
                  nostatus: params.row.severity === 'No Status',
                  noaccess: params.row.severity === 'No Access',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={handleCellEditCommit}
              editRowsModel={editRowsModel}
              onEditRowsModelChange={handleEditRowsModelChange}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="survey_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZAssetSurveyList;


/*
  <CardHeader
    avatar={
      <Avatar aria-label="annotation" className={classes.annotations_avatar}>
        <MonetizationOnIcon />
      </Avatar>
    }      
    action={props.action ? props.action : defaultAction}
    
    title={title}
    titleTypographyProps={{variant:'h5'}}
  />
*/
