import React from 'react';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getContact,  getQuotesForContact} from 'queries';
import { addContact, updateContact } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getContact,  getQuotesForContact } from 'queries_urql'; //subscribeContact
import { addContact, updateContact } from 'mutations_urql';
import { subscribeContact } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Fab from '@material-ui/core/Fab';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZClientBox from 'components/boxes/ZClientBox';
import ZContactBox from 'components/boxes/ZContactBox';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';

//const ResponsiveReactGridLayout = WidthProvider(Responsive);

//////////////////////////////////////////////

const ZContactView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const history = useHistory();
  let { id } = useParams();
  const [state, setState] = React.useState({});
  const [showDialog, setShowDialog] = React.useState(false);
  const [showQuoteDialog, setShowQuoteDialog] = React.useState(false);// showQuoteDialog

  //const [screen, setScreen] = useLocalStorage('screen_name');
  //setScreen('Contacts')

  console.log('contact_id: '+ id)
  /*
  const { loading, error, data } = useQuery(getContact, {
    variables: { id: id },
  });

  if (loading) return (<h2>Loading contact... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log('contact data')
  console.log(data)
  */

  const [ result, reexecuteQuery ] = useQuery({
    query: getContact,
    variables: { id },
  });

  const { data, fetching, error } = result;
  console.log(data)
  //const contact = React.useRef()

  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = data.zeus_contact_by_pk
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeContact,
    variables: { id },
    //pause: true,
  }, handleSubscription);   

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  //contact.current = data;

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  //console.log('contact')
  //console.log(contact)

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={8}>
            <ZContactBox
              fullBox 
              id='contact_box' 
              contact={data.zeus_contact_by_pk}
              pending_quotations_aggregate={data.pending_quotations_aggregate}
              accepted_quotations_aggregate={data.accepted_quotations_aggregate}
              lost_quotations_aggregate={data.lost_quotations_aggregate}
            />
          </Col>
          <Col xs={4}>
            <ZClientBox id='client_box' 
              client={data.zeus_contact_by_pk.client}
              name={data.zeus_contact_by_pk.client.name} 
              description={data.zeus_contact_by_pk.client.description} 
              createdOn={data.zeus_contact_by_pk.client.createdOn}
              annotations={data.zeus_contact_by_pk.client.annotations}
            >
              <Fab
                data-tip="client actions"
                data-for="client_box_tooltip"
                //color="primary"
                aria-label="add"
                size="small"
                onClick={(event) => { 
                    let path = generatePath('/client/:id', {
                      id: data.zeus_contact_by_pk.client.id}
                      )
                    console.log('push path')
                    console.log(path)
                    history.push(path)
                  }} 
              >
                <OpenInNewIcon />
              </Fab>              
            </ZClientBox>
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={8}>
            <ZQuotationsList key='quotations_panel' 
              contact={data.zeus_contact_by_pk} 
              items={data.quotations} 
            static>
              <Fab
                //color="secondary"
                aria-label="add"
                size="small"
                onClick={(event) => { 
                  let path = generatePath('/quotation/for/contact/:id/new', {
                    id: data.zeus_contact_by_pk.id}
                    )
                  history.push(path)
                }} 
                data-tip="activity actions"
                data-for="activities_list_tooltip" 
              >
                <AddCircleOutlineIcon/>
              </Fab>
            </ZQuotationsList>
          </Col>
          <Col xs={4}>
            <ZActivitiesList key='activities_panel' contact={data.zeus_contact_by_pk} items={data.zeus_contact_by_pk.activities}  static/>
          </Col>
        </Row>
        <Row><br/></Row>
      </Container>
    </div>
  )


}

export default ZContactView;

/*

          <Fab
            //color="secondary"
            aria-label="add"
            onClick={(event) => { 
              let path = generatePath('/quotation/for/contact/:id/new', {
                id: data.zeus_contact_by_pk.id}
                )
              history.push(path)
            }} 
            data-tip="activity actions"
            data-for="activities_list_tooltip" 
          >
            <AddCircleOutlineIcon/>
          </Fab>

*/

/*

              <IconButton
                color="inherit"
                aria-label="add row"
                edge="start"
                data-tip="add quotation"
                data-for="quotation_panel_tooltip" 
                onClick={(event) => { 
                  let path = generatePath('/quotation/for/contact/:id/new', {
                    id: data.zeus_contact_by_pk.id}
                    )
                  history.push(path)
                }} 
              >
                <div>
                  <AddCircleOutlineIcon/>
                </div>
              </IconButton>

*/

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/