import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import nfc from './nfc.svg';
import nfc_writer from './nfc_writer.jpeg';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from 'material-ui';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { Button, Snackbar } from '@material-ui/core';
import IconButton from 'material-ui/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from 'material-ui/Avatar';

import ZPulsate from 'components/core/ZPulsate';

/////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    //listStyle: 'none',
    padding: theme.spacing(1),
    margin: 'auto',
    minWidth: 100,
  },
  root_1: {
    '& > *': {
      margin: theme.spacing(1),
    },   
  },
  logo: {
    minWidth: 100,
    //display: 'flex',
    //flexWrap: 'wrap',
    //justifyContent: 'center',
    padding: theme.spacing(1),
    overflow: 'hidden',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
    justifyContent: 'center',
  },
}));

/////////////////////////////////////////////////////

const ZScannerPopup = (props) => {
  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    console.log('ZScannerPopup::handleClose')
    setOpen(false);
    if(props.onClose){
      props.onClose()
    }
  }

  return (
    <>
      <div className="scanner">
        <p className="scanner-exit" onClick={props.onClose}>X</p>
        <div className="scanner-container">
          <h2>Scanning... <CircularProgress /></h2>
        </div>
      </div>      
    </>
  );
};

////////////////

  /*
    message : {
      id: uuid
      name: text
      type: 0 = ToolNode / 1 = Tool
    }

    onScan => {
      serialNumber: uuid
      id: uuid
      name: text
      type: 0 = ToolNode / 1 = Tool
    }
  */

const init = () => {
  return {
    serialNumber: '',
    url: '',
    mime: '',
    json: '',
  }
}

const initialState = {
  scan: null,
  write: null,
  error: null,
  serialNumber: null,
  url: null,
  mime: null,
  data: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...action.payload,
      };
    case 'scan':
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case 'scanned':
      return { ...state, scan: 'scanned', write: null };
    case 'write':
      return { ...state, scan: null, write: 'write' };
    case 'written':
      return { ...state, scan: null, write: 'written' };
    default:
      throw new Error();
  }
}

const ZScannerControl = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  //const [message, setMessage] = React.useState('');
  const messageEl = React.useRef(null)

  const [serialNumber, setSerialNumber] = React.useState('');
  const [actions, setActions] = React.useState({scan: null, write: null});

  const scanAction = ({recordType,data}) => {
    dispatch({
      type: 'scan',
      payload: {
        key: recordType,
        value: data,
      },
    })
  };

  const scannedAction = ({recordType,data}) => {
    dispatch({
      type: 'scanned',
      payload: {
        key: recordType,
        value: data,
      },
    })
  };


  const scan = React.useCallback(async() => {
    if ('NDEFReader' in window) { 
      console.log('NDEFReader object is present')
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();
        
        console.log("ZScanControl::Scan started successfully.");

        ndef.onreadingerror = () => {
          console.log("ZScanControl::Cannot read data from the NFC tag. Try another one?");
        };
        
        ndef.onreading = event => {
          console.log("ZScanControl::NDEF message read.");
          onReading(event);
          scannedAction({
            scan: 'scanned',
            write: null
          })
        };

      } catch(error){
        console.log(`ZScanControl::Error! Scan failed to start: ${error}.`);
      };
    } else {
      console.log('NDEFReader object is NOT present')
      console.log('This device does not support NFC tag scanning')
      scannedAction({
        scan: 'error',
        write: null
      })

      // handleClose();
      if(props.onError){
        props.onError('This device does not support NFC tag scanning')
      }
    }
  },[]);


  const onReading = ({message, serialNumber}) => {
    //setSerialNumber(serialNumber);
    scanAction({
      'recordType': 'serialNumber',
      'data': serialNumber,
    })

    for (const record of message.records) {
      switch (record.recordType) {
        case "mime":
          if (record.mediaType === "application/json") {
            console.log('ZScanControl::NFC tag json record');
            const decoder = new TextDecoder(record.encoding);
            const json = JSON.parse(decoder.decode(record.data));

            scanAction({
              'recordType': record.recordType,
              'data': json,
            })

          } else {
            console.log('unsupported NFC message type')
          }
          break;
        case "text":
          const textDecoder = new TextDecoder(record.encoding);
          let strMessage = textDecoder.decode(record.data);
          console.log('ZScanControl::NFC tag text record');
          console.log(strMessage)
          //setMessage(strMessage);
          scanAction({
            'recordType': record.recordType,
            'data': strMessage,
          })
          //props.onScan(strMessage);
          break;
        case "url":
          // TODO: Read URL record with record data.
          console.log('ZScanControl::NFC tag url record');
          let strURL = record.data;
          console.log(`ZScanControl -> ${strURL}`)
          scanAction({
            'recordType': record.recordType,
            'data': strURL,
          })
          //props.onScan(strURL);
          break;
        default:
          // TODO: Handle other records with record data.
          console.log('ZScanControl::NFC tag other record so send serialNumber by default');
          //setMessage(serialNumber)
          scanAction({
            'recordType': record.recordType,
            'data': strMessage,
          })
          //props.onScan(serialNumber)
      }
    }

    props.onScan(state)
  };


  React.useEffect(() => {
    if(props.onScan){
      console.log('scan mode')
      console.log(state)
      scan();
    }
  }, [scan]);


  const handleClose = () => {
    console.log('setActions')
    scannedAction({
      scan: null,
      write: null
    })
    props.onClose()
  }

  messageEl.current = (
    <div>
      <p>NFC Serial Number: {state.serialNumber}</p>
      <p>Primac Tool: {state}</p>
    </div>
  )

  return(
    <>
      {console.log(`ZScannerControl::state`)}
      {console.log(state)}
      {
        state.scan === 'error' ?
          <div><h2>This device does not support NFC scanning</h2></div>
        :
          state.scan === 'scanned' ?  
            messageEl
          : 
            <ZScannerPopup onClose={handleClose} status={state.scan}></ZScannerPopup> 
      }
    </>
  );
};

///////////////////

/*
props {
  onScan: ()=>{}

}
*/

const ZNFCScanner = (props) => {
  const classes = useStyles();
  const [scan, setScan] = React.useState(false)

  const handleNFCScanned = (nfcMessage) => {
    console.log('ZNFCScanner::NFC message');
    console.log(nfcMessage);
    props.onScan(nfcMessage)
  }

  const handleClose = () => {
    setScan(false);
  }

  let nfc_logo = (
    <Image width={100} height={100} src={nfc} className={classes.logo} alt="nfc" />
  )

  if(props.pulsate){
    nfc_logo = (
      <ZPulsate visible={props.pulsate}>
        <Image width={100} height={100} src={nfc} className={classes.logo} alt="nfc" />
      </ZPulsate>
    )
  }

  let coreEl = (
    <Paper className={classes.root}>
      {nfc_logo}
      {/* <h1>NFC Scanner</h1> */}
      <div>
        <Button className={classes.button} vsize="large" color="primary" variant="outlined"
          onClick={()=>{
            console.log('ZNFCScanner scan button clicked')
            setScan(true)
          }}
        >
          {props.title ? props.title : 'Scan'}
        </Button>
      </div>
        {scan && <ZScannerControl onClose={handleClose} onScan={props.doScan ? handleNFCScanned : false} />}
    </Paper>
  )

  if(props.asButton){
    let button = (
      <IconButton component="span">
        <img width={70} height={70} src={nfc} alt="nfc-logo"/>
      </IconButton>  
    )

    if(props.pulsate){
      button = (
        <IconButton component="span">
          <ZPulsate visible={props.pulsate}>
            <img width={70} height={70} src={nfc} alt="nfc-logo"/>
          </ZPulsate>
        </IconButton>    
      )
    }

    coreEl = (
      <div 
        onClick={()=>{
          console.log('ZNFCScanner scan button clicked')
          setScan(true)
        }}
      >
        <div>
          {button}
        </div>
        {scan && <ZScannerControl  onClose={handleClose} onScan={props.doScan ? handleNFCScanned : false} />}
      </div>
    )
  }

  return (
    <div>
      { coreEl }
    </div>
  );
}

////////////////////////////////////////

const ZNFCWriter = (props) => {
  const classes = useStyles();
  const [alertState, setAlertState] = React.useState({open: false, message: ''});

  const handleClose = () => {
    setAlertState({
      open: false,
      message: ''   
    });
  }

  let snackbarEl = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to close snackbar alert')
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar alert')
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    />     
  )

  ////////////////////////

  const write = async (data) => {
    if ('NDEFReader' in window) { 
      console.log('NDEFReader object is present')
      try {
        const ndef = new window.NDEFReader();
        const encoder = new TextEncoder();
        const records = [];

        if(data.url){
          let recordURL = {
            recordType: 'url',
            data: data.url,
          }
          records.push(recordURL)
        }

        if(data.json){
          let recordJSON = {
            recordType: 'mime',
            mediaType: 'application/json',
            data: encoder.encode(JSON.stringify(data.json))
          }
          records.push(recordJSON)
        }

        await ndef.write({records: records}).then(()=>{
          console.log(`Successfully wrote ${data.name} to NFC tag`)
          setAlertState({
            open: true,
            message: `Successfully wrote ${data.name} to NFC tag`
          })
        }).catch((error) => {
          console.log(`Write failed :-( try again: ${error}.`);
          setAlertState({
            open: true,
            message: `Write failed :-( try again: ${error}.`
          })
        });

      }catch(error){
        console.log(`ZNFCWriter::Error! Write failed to start: ${error}.`);
        setAlertState({
          open: true,
          message: `ZNFCWriter::Error! Write failed to start: ${error}.`
        })
      };

    } else {
      console.log('ERROR device does not support NFC tags')
      setAlertState({
        open: true,
        message: 'ERROR device does not support NFC tags'
      })
    }
  }  

  //////////

  let nfc_logo = (
    <Image width={100} height={100} src={nfc_writer} className={classes.logo} alt="nfc" />
  )

  if(props.pulsate){
    nfc_logo = (
      <ZPulsate visible={props.pulsate}>
        <Image width={100} height={100} src={nfc_writer} className={classes.logo} alt="nfc" />
      </ZPulsate>
    )
  }

  let coreEl = (
    <Paper className={classes.root}>
      {nfc_writer}
      {/* <h1>NFC Scanner</h1> */}
      <div>
        <Button className={classes.button} vsize="large" color="primary" variant="outlined"
          onClick={()=>{
            console.log('ZNFCWriter scan button clicked with data')
            let data = props.doWrite();
            console.log(data)
            write(data)
          }}
        >
          {props.title ? props.title : 'Scan'}
        </Button>
      </div>
        {alertState.open && snackbarEl}
    </Paper>
  )


  if(props.asButton){
    let button = (
      <IconButton component="span">
        <img width={70} height={70} src={nfc_writer} alt="nfc-logo"/>
      </IconButton>  
    )

    if(props.pulsate){
      button = (
        <IconButton component="span">
          <ZPulsate visible={props.pulsate}>
            <img width={70} height={70} src={nfc_writer} alt="nfc-logo"/>
          </ZPulsate>
        </IconButton>    
      )
    }

    coreEl = (
      <div 
        onClick={()=>{
          console.log('ZNFCWriter scan button clicked with data')
          let data = props.doWrite();
          console.log(data)
          write(data)
        }}
      >
        <div>
          {button}
        </div>
        {alertState.open && snackbarEl}
      </div>
    )
  }

  return (
    <div>
      { coreEl }
    </div>
  );
}


////////////////////////

export { ZNFCScanner, ZNFCWriter };
