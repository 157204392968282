import React from 'react';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';

import { red, green, grey } from '@material-ui/core/colors';

import { useLocalStorage } from 'hooks/useStorage';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllJobBoardEntries } from '../../queries';
import { updateClient, updateJobBoardEntry } from '../../mutations'
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllJobBoardEntries } from 'queries_urql';
import { updateClient, updateJobBoardEntryField, updatePrimacPO, updateJobSetPrimacPO } from 'mutations_urql';
import { subscribeAllJobBoardEntries } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //ZJobBoardDetailPane
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Alert from '@material-ui/lab/Alert';


import ZJobBoardDetailPane from 'components/core/ZJobBoardDetailPane';
import ZSetJobInvoicedButton from 'components/buttons/ZSetJobInvoicedButton';
import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';

import ZAddJobBoardEntryDialog from 'components/dialogs/ZAddJobBoardEntryDialog';
import ZAddJobPurchaseOrderDialog from 'components/dialogs/ZAddJobPurchaseOrderDialog';
import ZAddJobInvoiceDialog from 'components/dialogs/ZAddJobInvoiceDialog'; 

import { Link as RouterLink, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';
import ZBreadCrumbs from 'components/core/ZBreadCrumbs';

//////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.active': {
      backgroundColor: grey[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },    
  },
});

//////////////////////////////////////////////////

const ZJobBoardPanel = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [userData, setUserData] = useLocalStorage('userData');
  const [showDialog, setShowDialog] = React.useState(false);
  const [jobInvoice, setJobInvoice] = React.useState({
    show: false,
  });
  const [jobPO, setJobPO] = React.useState({
    show: false,
    message: '',
  });  
  const [alert, setAlert] = React.useState({
    show: false,
    message: '',
    error: false,
  });
  const [activeEntry, setActiveEntry] = React.useState({
    entry: null,
    show: false
  });
  const [primacPO, setPrimacPO] = React.useState({
    show: false,
    message: '',
  });
  let rows = []

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: false,
      renderCell: (params) => (
        <strong>
          <Router>
            <div>
            <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                data-tip="quick view"
                data-for="jobboard_panel_tooltip" 
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let entry = params.row;
                  console.log("selected entry");
                  console.log(entry);
                  setActiveEntry({
                    entry: entry,
                    show: true,
                  });
                }}          
              >
                <BurstModeIcon />
              </IconButton>            
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/jobboard_entry/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Job#',
      headerName: 'Job#',
      minWidth: 140,
      editable: false,
      hide: false,
    },
    {
      field: 'primacPO',
      title: 'Primac PO#',
      headerName: 'Primac PO#',
      minWidth: 140,
      editable: false,
      renderCell: (params) => {

        if(params.row.primacPO){
          return params.row.primacPO
        } else {
          if(params.row.status !== 'completed'){
            return (
              <strong>
                <div>
                  <IconButton 
                    component="span"
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={(event) => { 
                      let entry = params.row;
                      console.log("selected entry");
                      console.log(entry);
                      handleCreatePrimacPO(entry)
                    }}          
                  >
                    <PlaylistAddIcon />
                  </IconButton>            
                </div>
              </strong>
            )
          } else {
            return(<div></div>)
          }
        }
      },        
    },
    {
      field: 'primacQuoteName',
      title: 'Primac Quote#',
      headerName: 'Primac Quote#',
      minWidth: 260,
      editable: false,   
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      minWidth: 140,
      editable: true,      
      type: "singleSelect",
      valueOptions: ["pending", "active", "completed"]    
    }, 
    {
      field: 'client_name',
      title: 'Company Name',
      headerName: 'Company Name',
      minWidth: 320,
      editable: false,
      valueGetter: (params) => {
        //console.log({ params });
        if( params.row.client === null){
          return params.row.companyName;
        } else {
          return params.row.client.name;
        }
      }
    }, 
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 400,
      editable: true,
      sortable: false,
    },
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        return params.row.createdOn.split('T')[0];
      }, 
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    },
    {
      field: 'serviceType',
      title: 'Service Type',
      headerName: 'Service Type',
      minWidth: 220,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: ["Motion Amplification", "Vibration", "Ultrasound", "Balancing"]      
    },
    {
      field: 'quoteAmount',
      title: 'Quote Amount',
      headerName: 'Quote Amount',
      type: 'number',
      minWidth: 180,
      editable: true,
      valueGetter: (params) => {
        if(params.row.quoteAmount){
          return `$ ${params.row.quoteAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        } else {
          return '$ -0.00'
        }   
      }         
    },
    {
      field: 'poNum',
      title: 'Customer P0#',
      headerName: 'Customer PO#',
      minWidth: 180,
      editable: false,
      valueGetter: (params) => {
        if(params.row.purchaseOrders.length > 0){ 
          let po = params.row.purchaseOrders[0];
          return po.name;
        } else {
          return 'N/A';
        }
      }, 
      renderCell: (params) => {
        var size = params.row.purchaseOrders.length;
        if(size > 0){
          let po =  params.row.purchaseOrders[0];
          return (
            <div>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                let path = generatePath('/purchase_order/:id', {
                  id: po.id}
                  )
                history.push(path)
              }}         
              >
                {po.name}
              </IconButton>            
            </div>
          )         
        } else {
          if(params.row.status !== 'completed'){
            return (
              <div>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let entry = params.row;
                    console.log("selected entry");
                    console.log(entry);
                    handleCreateCustomerPO(entry)
                  }}          
                >
                  <PlaylistAddIcon />
                </IconButton>            
              </div>
            )
          } else {
            return (<div></div>)
          }
        }
      },       
    },     
    {
      field: 'poAmount',
      title: 'PO Amount',
      headerName: 'PO Amount',
      type: 'number',
      minWidth: 180,
      editable: false,
      valueGetter: (params) => {
        if(params.row.poAmount){
          return `$ ${params.row.poAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        } else if(params.row.purchaseOrders.length > 0) {
          let po = params.row.purchaseOrders[0];
          let poAmount = po.amount;
          return `$ ${poAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        } else {
          return '$ -0.00'
        }
      }     
    },   
    {
      field: 'closeDate',
      title: 'PO End Date',
      headerName: 'PO End Date',
      type: 'date',
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        if(params.row.purchaseOrders.length > 0){ 
          let closedOn = params.row.purchaseOrders[0].closedOn;
          if(closedOn !== null){
            return closedOn.split('T')[0];
          } else {
            return 'N/A';
          }
        } else {
          return 'N/A';
        }
      },
      renderCell: (params) => {
        if(params.row.purchaseOrders.length > 0){ 
          let closedOn = params.row.purchaseOrders[0].closedOn;
          if(closedOn !== null){
            return(
              <Moment format="YYYY-MM-DD">
                {closedOn}
              </Moment>
            )
          } else {
            return(
              <div></div>
            )
          }
        } else {
          return(
            <div></div>
          )
        }
      },
    },        
    {
      field: 'inv_num',
      title: 'Invoice#',
      headerName: 'Invoice#',
      minWidth: 160,
      editable: false,
      valueGetter: (params) => {
        if(params.row.invoices.length > 0){ 
          let inv = params.row.invoices[0];
          return inv.name;
        } else {
          return 'N/A';
        }
      },      
      renderCell: (params) => {
        var size = params.row.invoices.length;
        if(size > 0){
          let inv = params.row.invoices[0];
          return(
            <div>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                let path = generatePath('/invoice/:id', {
                  id: inv.id}
                  )
                history.push(path)
              }}         
              >
                {inv.name}
              </IconButton>            
            </div>
          )
        } else {
          return (
            <strong>
              <div>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    setJobInvoice({...jobInvoice, open: true, job: params.row})
                  }}          
                >
                  <PlaylistAddIcon />
                </IconButton>            
              </div>
            </strong>
          )
        }
      },       
    }, // invoiceDate 
    {
      field: 'invoiceDate',
      title: 'Invoice Date',
      headerName: 'Invoice Date',
      type: 'date',
      minWidth: 180,
      editable: false,
      valueGetter: (params) => {
        if(params.row.invoiceDate){
          return params.row.invoiceDate.split('T')[0];
        }

        if(params.row.invoices.length > 0){ 
          let theDate = params.row.invoices[0].theDate;
          if(theDate !== null){
            return theDate.split('T')[0];
          } else {
            return 'N/A';
          }
        } else {
          return 'N/A';
        }
      },
      renderCell: (params) => {
        if(params.row.invoiceDate){
          return(
            <Moment format="YYYY-MM-DD">
              {params.row.invoiceDate}
            </Moment>
          )
        } else if(params.row.invoices.length > 0){ 
          let theDate = params.row.invoices[0].theDate;
          return(
            <Moment format="YYYY-MM-DD">
              {theDate}
            </Moment>
          )
        } else {
          return(
            <div></div>
          )
        }
      },
    },    
    {
      field: 'inv_amount',
      title: 'Last Invoice',
      headerName: 'Last Invoice',
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        //console.log({ params });
        var size = params.row.invoices.length;
        if (size === 0){
          return '$ -0.00'
        } else {
          if(params.row.invoices[0].amount !== null){
            return `$ ${params.row.invoices[0].amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
          } else {
            return '$ -0.00'
          }
        }
      }
    },  
    {
      field: 'accum_inv',
      title: 'Accumulated Invoiced',
      headerName: 'Accumulated Invoiced',
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        //console.log({ params });
        var size = params.row.purchaseOrders.length;
        if (size === 0){
          return '$ -0.00'
        } else {
          let acculatedInv = 0.00
          let po = params.row.purchaseOrders[0]
          po.invoices.forEach((inv)=>{
            acculatedInv += inv.amount
          })
          return `$ ${acculatedInv.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        }
      }
    }, 
    {
      field: 'po_remaining',
      title: 'PO Remaining',
      headerName: 'PO Remaining',
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        //console.log({ params });
        var size = params.row.purchaseOrders.length;
        if (size === 0){
          return '$ 0.00'
        } else {
          let poRemaining = 0.00
          let acculatedInv = 0.00
          let po = params.row.purchaseOrders[0]
          po.invoices.forEach((inv)=>{
            acculatedInv += inv.amount;
          })

          poRemaining = po.amount - acculatedInv;
          return `$ ${poRemaining.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        }
      }
    },       
  ];

  //////

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZJobBoardPanel::handleCellEditCommit');
      var modifiedOn = moment().format();
      var user_id = userData.id;
      var theName = "job updated";

      var _set = {}
      _set[field] = value
      _set.modifiedOn = modifiedOn;

      if(field === 'status'){
        theName = "job status updated";
        if(value === 'completed'){
          theName = "job completed";
        }
      }

      let activity = {
        user_id: user_id,
        parent_id: id,
        name: theName,
        description: `${userData.alias} updated job ${field} to ${value}`,        
      }

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set,
        activity: activity, 
      }
      console.log(variables)

      updateFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR updating job: ${result.error}`)
          setAlert({...alert, show: true, message: result.error, severity: 'error'})
        } else {
          console.log(`job update [ ${_set} ]`)
          console.log(result)
          setAlert({...alert, show: true, message: 'Successfully updated Job Board', severity: 'success'})
        }
      }) 
    },[]
  );

  const handleCreatePrimacPO = (entry) => {
    console.log('handleCreatePrimacPO')
    console.log(entry)
    setPrimacPO({...primacPO, show: true, job: entry, job_id: entry.id, message: `Are you sure you want to create a new Primac PO# for job ${entry.name}?`});
  }

  const handleCreateCustomerPO = (entry) => {
    console.log('handleCreateCustomerPO')
    console.log(entry)
    setJobPO({...jobPO, show: true, job: entry, job_id: entry.id, message: `Are you sure you want to create a new customer PO# for job ${entry.name}?`});
  }

  const doHandleCreatePrimacPO = () => {
    console.log('ZJobBoardPanel::handleCreatePrimacPO');
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let job_id = primacPO.job_id;
    let job = primacPO.job;
   // _set[field] = value

    let variables = {
      user_id: userData.id,
      id: job_id, 
      _set: {
        modifiedOn: modifiedOn,
      },
      activity: {
        user_id: user_id,
        parent_id: job_id,
        name: 'job updated with Primac PO',
        description: `${userData.alias} updated job ${job.name} added PrimacPO#`,        
      }
    }
    console.log(variables)
    setPrimacPO({...primacPO, show: false});

    createPrimacPOFunction().then((result)=>{
      if(result.error){
        console.log(`ERROR creating new primacPO: ${result.error}`)
        setAlert({...alert, show: true, message: `ERROR creating new primacPO: ${result.error}`, severity: 'error'})
      } else {
        console.log(result)
        let newPrimacPO = result.data.update_zeus_document_counter.returning[0].sequence;
        
        console.log(`received new primacPO ${newPrimacPO}`);
        variables._set.primacPO = newPrimacPO;
        variables.activity.description = `${userData.alias} updated job ${job.name} added PrimacPO#: ${newPrimacPO}`;
        console.log(variables);

        updateJobSetPrimacPOFunction(variables).then((result)=>{
          if(result.error){
            console.log(`ERROR creating new primacPO: ${result.error}`)
            setAlert({...alert, show: true, message: `ERROR creating new primacPO: ${result.error}`, severity: 'error'})
          } else {
            setAlert({...alert, show: true, message: `Successfully updated Job ${job.name}`, severity: 'success'})
          }
        })
      }
    })     
  }


  const handleClickOpen = () => {
    console.log("ZJobBoardPanel::handleClickOpen->open dialog wizard")
    setShowDialog(true);
    console.log("ZJobBoardPanel::handleClickOpen == "+ showDialog);
  };
  
  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCloseDetailPane = () => {
    setActiveEntry({
      show: false,
      entry: null,
    })
  };  


  const [updateJobResult, updateFunction] = useMutation(updateJobBoardEntryField); //createPrimacPOFunction
  const [createPOResult, createPrimacPOFunction] = useMutation(updatePrimacPO); 
  const [updateJobPrimacPOResult, updateJobSetPrimacPOFunction] = useMutation(updateJobSetPrimacPO); 

  ////////////////

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return [...response.zeus_jobboard_entry];
  }
 
  const [subResult] = useSubscription({ 
    query: subscribeAllJobBoardEntries, 
    pause: !window.navigator.onLine,  
  }, handleSubscription); 

  const [ result, reexecuteQuery ] = useQuery({
    query: getAllJobBoardEntries,
    pause: window.navigator.onLine
  });  

  console.log('res');
  console.log(subResult)  

  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = result.data;
    fetching = result.fetching;
    error = result.error;
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  rows = data.slice(0)
  
  ////////////////////////////

  return (
    <div className={classes.root}>
    {/* dialog components */}
    <ZJobBoardDetailPane isOpen={activeEntry.show} action={<div/>} handleClose={handleCloseDetailPane} entry={activeEntry.entry} static/>
    <ZAddJobBoardEntryDialog open={showDialog} handleClose={handleClose}/>
    <ZAddJobInvoiceDialog open={jobInvoice.open} job={jobInvoice.job} onClose={()=>setJobInvoice({...jobInvoice, open: false})} />
    <ZAddJobPurchaseOrderDialog open={jobPO.open} parent={jobPO.job} job={jobPO.job} onClose={()=>setJobPO({...jobPO, open: false})} />
    {/* primacPO component */}
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={primacPO.show}
      message={primacPO.message}
      key="primac_po_button"
      action={
        <div>
          <Button className={classes.button} variant="contained" color="default" onClick={doHandleCreatePrimacPO}>
            Yes
          </Button>        
          <Button className={classes.button} variant="contained" color="secondary" startIcon={<CloseIcon />}
            onClick={()=>{
              setPrimacPO({...alert, show: false, message: '', error: false})
            }}
          >
            No
          </Button>
        </div>
      }
    />    
    {/* alert component */} 
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alert.show}
      key="alert_button"
      onClose={()=>{
          setAlert({...alert, show: false, message: '', severity: 'info'})
      }}
    >
      <Alert severity={alert.severity} 
        onClose={()=>{
          setAlert({...alert, show: false, message: '', severity: 'info'})
      }}>
        {alert.message}
      </Alert>
    </Snackbar>  
    {/* main component */}   
    <Card variant="outlined" elevation={2}>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
        Job Board
        </Typography>
        <IconButton
          onClick={handleClickOpen}
          style={{color:"white"}}
          color="inherit"
          aria-label="add row"
          edge="start"
          data-tip="add job board entry"
          data-for="jobboard_panel_tooltip" 
        >
          <AddCircleOutlineIcon/>
        </IconButton>
      </Box>
      <DataGrid autoHeight
        title="Job Board"
        columns={columns}
        rows={rows}
        pageSize={props.pageSize ? props.pageSize : 20}
        density="compact"
        onCellEditCommit={handleCellEditCommit}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick={false}
        getRowClassName={(params) =>
          clsx('super-app', {
            negative: params.row.status === 'completed',
            active: params.row.status === 'active',
          })
        }        
        options={{
          toolbar: true,
          filtering: true,
          search: true,
          grouping: true,
          exportButton: true,
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        filterModel={props.filter}
        isCellEditable={(params) => params.row.status !== 'completed'}
      />
      </CardContent>
    </Card>
    <ReactTooltip id="jobboard_panel_tooltip"/>
    </div>
  );  
}
  

export default ZJobBoardPanel;


