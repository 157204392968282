import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useMutation } from 'urql';
import { addAttachments } from 'mutations_urql';

import { uploadFileToAzure } from 'azure/AzureBlobAPI';

import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import S3 from 'react-aws-s3';
import gql from 'graphql-tag';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  avatar_closed: {
    backgroundColor: red[500],
  },
  avatar_pending: {
    backgroundColor: blue[500],
  },
  avatar_accepted: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

////////////////////////////////

/*
  props {
    title
    name  **file name
    description
    bucket
    dirName
    s3Url
  }
*/

// primac/quotations/IQ210930CTCPMTECH/attachments/IQ210930CTCPMTECH.1.pdf

const ZFileCloudUploadMenuItem = React.forwardRef((props, ref) => {

  const classes = useStyles();
  const history = useHistory();
  const [userData, setUserData] = useLocalStorage('userData');
  const fileInput = React.useRef();
  const [progress , setProgress] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [description, setDescription] = React.useState(null);
  const [error , setError]          = React.useState(null);
  const [alert , setAlert]  = React.useState({
    error: '',
    open: false,
  });



  //console.log('building S3 upload button')

  let title = "Upload File"

  let config = {
    bucketName: 'primac',
    dirName: 'uploads', /* optional */
    region: 'ca-central-1',
    accessKeyId: 'JAJHAFJFHJDFJSDHFSDHFJKDSF',
    secretAccessKey: 'jhsdf99845fd98qwed42ebdyeqwd-3r98f373f=qwrq3rfr3rf',
    //s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  }

  if ( props.title ){
    title = props.title
  }

  if ( props.bucket ){
    config.bucketName = props.bucket
  }

  if ( props.dirName ){
    config.dirName = props.dirName
  }

  if ( props.s3Url ){
    config.s3Url = props.s3Url
  }

  if ( props.region ){
    config.region = props.region
  }

  const [insertResult, addAttachmentsFunction] = useMutation(addAttachments);

  const handleFileInput = (event) => {
    console.log('ZFileCloudUploadMenuItem -> handleFileInput')
    console.log(event.target.files)
    setSelectedFiles(event.target.files);
    if(props.onFileChange){
      props.onFileChange(event.target.files)
    }
  }
  

  var handleOfflineFileSelect = (evt) => {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
        var reader = new FileReader();

        reader.onload = function(readerEvt) {
            var binaryString = readerEvt.target.result;
            document.getElementById("base64textarea").value = btoa(binaryString);
        };

        reader.readAsBinaryString(file);
    }
  };

  React.useImperativeHandle(ref, () => ({
    doSubmitAzure: () => {
      console.log('calling submit from parent')
      handleSubmitAzure();
    },
    doSubmitS3: () => {
      console.log('calling submit from parent')
      handleSubmitS3();
    }    
  }));


  const handleSubmitAzure = () => {
    console.log('ZFileCloudUploadMenuItem -> handleSubmitAzure')
    console.log(selectedFiles)

    //return;

    uploadFileToAzure(props.path, selectedFiles).then(result => {
      let attachments = []; 

      result.forEach((data)=>{
        let attachment = {};
        attachment.name = data.fileName;
        attachment.url = data.url;
        attachment.bucket = data.bucket;
        attachment.path = data.path;

        if( props.description ){
          attachment.description = props.description
        }
  
        if(props.parent_id){
          attachment.parent_id = props.parent_id
        }
        attachments.push(attachment);
      })

      let activities = [{
        user_id: userData.id,
        parent_id: props.parent_id,
        name: "images uploaded",
        description: `${userData.alias} uploaded  ${result.length} images`,          
      }]
      //attachment.user_id = userData.id;
      
      console.log('ZFileCloudUploadMenuItem attachments')
      console.log(attachments)

      addAttachmentsFunction({ user_id: userData.id, objects: attachments, activities: activities }).then((result)=>{
        if (result.error) {
          console.error('ZFileCloudUploadMenuItem ERROR creating revised quote', result.error);
          setError(result.error)
          setAlert({
            error: result.error,
            open:true,
          })
          return
        } 

        if(props.follow){
          console.log('ZFileCloudUploadMenuItem follow -> loading attachment')
          let path = generatePath('/attachment/:id', {id: props.sales_document_id})
          if(props.path){
            path = props.path;
            history.push(path) 
          }
        }
        //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
      })
    }).catch(err => console.error(err))     
  }

  //////////

  const ReactS3Client = new S3(config);
  const handleSubmitS3 = (event) => {
    if(event){
      event.preventDefault()
    }
    console.log('handleSubmitS3')
    console.log(fileInput.current.files)
    let file = fileInput.current.files[0]
    let newFileName = fileInput.current.files[0].name

    console.log('uploading file:=>')
    console.log(file)
    console.log('to =>')
    console.log(newFileName)

    //return;

    // if we are online then upload to S3 and execute the mutation
    ReactS3Client.uploadFile(file, newFileName)
    .then(data => {
      console.log('ZFileCloudUploadMenuItem file successfully uploaded to S3')
      console.log(data)
      /**
       * {
       *   Response: {
       *     bucket: "myBucket",
       *     key: "image/test-image.jpg",
       *     location: "https://myBucket.s3.amazonaws.com/media/test-file.jpg"
       *   }
       * }
       */
      // show success alert
      // do mutation to insert file into db
      let attachment = {}; 
      attachment.name = file;
      attachment.url = data.Response.location;
      attachment.bucket = config.bucket;
      attachment.path = config.dirName;
      //attachment.user_id = userData.id;

      if( props.description ){
        attachment.description = props.description
      }

      if(props.parent_id){
        attachment.parent_id = props.parent_id
      }
      
      console.log('attachment')
      console.log(attachment)

      addAttachmentsFunction({ user_id: userData.id, object: attachment }).then((result)=>{
        if (result.error) {
          console.error('ZFileCloudUploadMenuItem ERROR creating revised quote', result.error);
          //setError(result.error)
          setAlert({
            open: true,
            error: result.error,
          })
          return
        }

        if(props.follow){
          let path = generatePath('/attachment/:id', {id: props.sales_document_id})
          if(props.path){
            path = props.path;
            history.push(path) 
          }
        }
        //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})           
      })
    })
    .catch(err => console.error(err))

    // if we are offline then lets store the file and mutation in a cache
  }

  return (
    <div>
      <Collapse in={alert.open}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Error creating new Attachment — <strong>{alert.error}</strong>
        </Alert>  
      </Collapse>
      <Paper className={classes.root}>
        <strong>
          <div>
            <form>
              <label>{title}</label><br/>
              {/* <Input type="file" inputRef={fileInput} onChange={handleFileInput} multiple={props.multiple}/><br/>  */}   
              <input type="file" name="file" onChange={handleFileInput} multiple={props.multiple}/>
              {props.description &&  
                <TextField 
                  onChange={(event) =>{
                    console.log("setName->"+ JSON.stringify(event.target.value));
                    //setName(event.target.value);
                    setDescription(event.target.value);
                  } }
                  id="description" 
                  label="Description" 
                  variant="standard"
                  multiline
                  rows={4}
                /> 
              }              
              { props.noButton ? <div></div> : <div><br/><Button variant="outlined" color="primary" onClick={()=>handleSubmitAzure()} >Upload Attachment</Button></div> }
            </form>
          </div>
        </strong>
      </Paper>
    </div>
  )
});

export default ZFileCloudUploadMenuItem;

/*
  <Button color="primary" 
    style={{ marginLeft: 8 }}
    onClick={() => uploadFile(selectedFile)}                     
  >
    {title}
  </Button>

  <Button variant="outlined" color="primary" type="submit">Upload Attachment</Button>

  onSubmit={props.s3 ? handleSubmitS3 : handleSubmitAzure}
         
*/
