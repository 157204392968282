import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllConditions, getAllComponents, getClient, getContact, getLocationsForComponent, getComponentLocationsForAsset } from 'queries_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZComponentLocationAutocomplete = (props) => {

  let component_id = props.component ? props.component.id : undefined;

  let rows = []
  let skip_query = false;

  if(props.open !== undefined){
    if(props.open){
      skip_query = false;
    }
  }

  if(props.items){
    rows = [...props.items]
    skip_query = true
  } else {
    rows = [...props.asset.component_locations]
    skip_query = true
  }  

  /*
  const { loading, error, data }  = useQuery(getAllClients, {skip: skip_query});
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  

  const [ result, reexecuteQuery ]  = useQuery({
    query: getComponentLocationsForAsset,
    variables: { asset_id: props.asset.id },
    pause: skip_query,
  });

  console.log('ZComponentLocationAutocomplete->result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  //////////////////  

  if(rows.length > 0){
    console.log("items")
    console.log(rows)
  }

  console.log('skip_query');
  console.log(skip_query);

  return (
    <Autocomplete
      id="choose-component-combo-box"
      onChange={(event,item)=>{
        console.log("component autocomplete change")
        console.log(item)
        if(props.onChange){
          props.onChange(event,item)
        }
      }}
      options={props.items ? [ ...props.items ] : rows}
      getOptionLabel={(option) => {
        return  `${option.component.name} -> ${option.name}` 
      }}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}

      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Component Location" variant="outlined" />}
    />
  )
}

export default ZComponentLocationAutocomplete;
