import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';
import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAnnotationsForSalesDocument } from 'queries';
import { addAnnotation, updateAnnotation } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getInvoicesForSalesDocument} from 'queries_urql';
import { addJobInvoice, updateJobInvoice, addNotification  } from 'mutations_urql';
import { subscribeAnnotationsForParent } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';//LinearProgress
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  annotation_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  invoices_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  items: {}

}
*/

/////////////////////////////////////////

const ZInvoicesList = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [alertState, setAlertState] = React.useState({
    severity: 'info',
    open: false,
    message: '',
  });
  const [userData, setUserData] = useLocalStorage('userData');

  const renderStatusEditInputCell = (params) => {
    console.log('renderStatusEditInputCell')
    console.log(params)
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/invoice/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      minWidth: 120,
      editable: true,
      hide: false,
      renderEditCell: renderStatusEditInputCell,
      cellClassName: (params) =>
        clsx('super-app', {
          negative: params.value === 'active',
          positive: params.value === 'closed',
        }),         
    }, 
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: props.nameW ?? 120,
      editable: true,
      hide: false,
    },
    {
      field: 'amount',
      title: 'Amount',
      headerName: 'Amount',
      editable: true,
      hide: false,
      type: 'number',
      minWidth: 130,
      renderCell: (params) => {
        if(params.row.amount){
          return (
            <div>$ { params.row.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }</div>
          )
        } else {
          return (
            <div>$ N/A</div>
          )
        }
      },
      valueGetter: (params) => {
        return params.row.amount ? params.row.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 'N/A'
      },      
    },  
    {
      field: 'poNum',
      title: 'Customer P0#',
      headerName: 'Customer PO#',
      minWidth: 180,
      editable: false,
      valueGetter: (params) => {
        if(params.row.parent){ 
          let po = params.row.parent;
          return po.name;
        } else {
          return 'N/A';
        }
      }, 
      renderCell: (params) => {
        if(params.row.parent){
          let po =  params.row.parent;
          return (
            <div>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                let path = generatePath('/purchase_order/:id', {
                  id: po.id}
                  )
                history.push(path)
              }}         
              >
                {po.name}
              </IconButton>            
            </div>
          ) 
        }
        return (<div></div>);
      },       
    },           
    {
      field: 'theDate',
      title: 'Invoiced Date',
      headerName: 'Invoiced Date',
      type: 'date',
      minWidth: 140,
      editable: true,
      renderCell: (params) => {
        if(params.row.theDate){
          return(
            <Moment format="YYYY-MM-DD">
              {params.row.theDate}
           </Moment>
          )
        } else {
          return(
            <div></div>
          )
        }
      },
    }, 
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 180,
      editable: true,
      hide: false,
    },   
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 140,
      editable: true,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },       
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      // 
      console.log('ZInvoicesList::handleCellEditCommit');
      console.log(`id=${id}  field=${field}  value=${value}`)
      //console.log(others)
      let invoice = null;

      props.parent.invoices.forEach((inv)=>{
        if(inv.id === id){
          invoice = inv;
        }
      })

      console.log('invoice')
      console.log(invoice)

      var _set = {}
      let guuid = uuidv4()
      _set[field] = value
      _set['modifiedOn'] = moment().format()

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set,
        activities:[
          {
            user_id: userData.id,
            parent_id: id,
            name: "invoice updated",
            description: `${userData.alias} updated invoice entry ${field} to ${value}`,            
          },
          {
            user_id: userData.id,
            parent_id: props.parent.id,
            name: "invoice updated",
            description: `${userData.alias} updated invoice entry ${invoice.name} ${field} to ${value}`,            
          }
        ] 
      }
      console.log(variables)      

      updateFunction(variables).then(result => {
        if(result.error){
          console.log(`ERROR updating invoice entry ${result.error}`)
          setAlertState({
            open: true,
            message: `ERROR updating invoice entry ${result.error}`,
            severity: 'error',
          })          
        } else {
          console.log(`updated invoice entry`)

          if(field === 'status'){
            insertNotificationFunction({object: {
              name: `Invoice ${invoice.name} status updated`,
              description: `${userData.alias} set Invoice ${invoice.name} status as ${value}`,
              role: 'admin',
            }});
          }

          setAlertState({
            open: true,
            message: "Successfully updated invoice entry",
            severity: 'success',
          })
        }
      })

    },[setAlertState]);

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  const handleEditRowsModelChange = React.useCallback((model) => {
    console.log('handleEditRowsModelChange')
    console.log(model)
    setEditRowsModel(model);
  }, []);

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Note</MenuItem>
      </Menu>
    </div>  
  )

  console.log(props)

  let items = []
  let query = getInvoicesForSalesDocument
  let variables = {}

  let skip_query = true;
  let skip_sub = false;
  let title = "Invoices"

  if(props.static !== undefined){
    skip_query = true;
  }

  if(!props.realtime || !props.live){
    skip_sub = true;
  }

  if( props.title){
    title = props.title
  }

  if( props.items){
    skip_query = true;
  }

  if( props.client){
    variables = { client_id: props.client.id }
  }

  console.log(props)

  ////////////////////////////////////

  const [ result, reexecuteQuery ]  = useQuery({
    query: query,
    variables: variables,
    pause: skip_query,
  });

  const [updateInvoiceResult, updateFunction] = useMutation(updateJobInvoice);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if(skip_query === false){
    if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_query === true){
    items = props.items
  } else {
    items = data.zeus_sales_document
  }

  console.log(items);

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="notes actions"
      data-for="invoices_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>
  )

  if(props.addColumns){
    console.log('adding outside columns');
    for(var col of props.addColumns){
      columns.push(col);
    }
  }

  //////////////////////////////////

  return (
    <div>
    {/* alert component */}
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="sales_doc_alert_bar"
        onClose={()=>setAlertState({...alertState, open: false})}
      >     
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>       
      </Snackbar>
      {/* main component */}
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="invoices" data-tip="add invoice" data-for="invoices_list_tooltip"  className={classes.avatar}>
              <MonetizationOnIcon />
            </Avatar>
          }      
          action={props.action ? props.action : defaultAction}
          title={props.title ? props.title : 'Invoices'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper className={classes.activity_row}>
            <DataGrid autoHeight
              rows={items}
              columns={columns}
              pageSize={props.pageSize ? props.pageSize : 10}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  negative: params.row.status === 'active',
                  positive: params.row.status === 'closed',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={handleCellEditCommit}
              //editRowsModel={editRowsModel}
              //onEditRowsModelChange={handleEditRowsModelChange}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="invoices_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZInvoicesList;


/*
  <CardHeader
    avatar={
      <Avatar aria-label="annotation" className={classes.annotations_avatar}>
        <MonetizationOnIcon />
      </Avatar>
    }      
    action={props.action ? props.action : defaultAction}
    
    title={title}
    titleTypographyProps={{variant:'h5'}}
  />
*/
