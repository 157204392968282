import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

import gql from 'graphql-tag';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllProductLines } from 'queries';
import { addProductLine, updateProductLine } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getAllProductLines } from 'queries_urql';
import { addProductLine, updateProductLine } from 'mutations_urql';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddSupplierDialog from 'components/dialogs/ZAddSupplierDialog';
import ZSupplierDetailPane from 'components/core/ZSupplierDetailPane';


//////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////

const ZProductLinesPanel = (props) => {
  //const [quoteId, setQuoteId] = useLocalStorage('quote_id', '');
  const history = useHistory();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showDetailPane, setShowDetailPane] = React.useState(false);
  const [activeProductLine, setActiveProductLine] = React.useState({});

  const classes = useStyles();

  let query = getAllProductLines;
  let variables = {};
  let query_params = { 
    pollInterval: 5000,
  }

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 60,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/product_line/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 200,
      editable: 'never',
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 250,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: true,
      cellClassName: (params) =>
        clsx('super-app', {
          negative: params.value == 'closed',
          positive: params.value == 'accepted',
        }),      
    },
    {
      field: 'createdOn',
      title: 'Quoted',
      headerName: 'Quoted',
      type: 'date',
      minWidth: 150,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    },
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZSuppliersPanel::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleClickOpen = () => {
    console.log("ZProductLinesPanel::handleClickOpen->open dialog wizard")
    setShowDialog(true);
    console.log("ZProductLinesPanel::handleClickOpen == "+ showDialog);
  };
  
  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCloseDetailPane = () => {
    setShowDetailPane(false);
  };

  const { loading, error, data }  = useQuery(query, query_params);
  const [updateFunction] = useMutation(updateProductLine);
  const [insertFunction] = useMutation(addProductLine);

  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  //if (error) return (<div/>);

  return (
    <div className={classes.root}>
    <ZAddSupplierDialog open={showDialog} handleClose={handleClose}/>  
    <ZSupplierDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} supplier={activeProductLine}/>

    <Card variant="outlined" elevation={2}>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          Product Lines
        </Typography>
        <IconButton
          onClick={handleClickOpen}
          style={{color:"white"}}
          color="inherit"
          aria-label="add row"
          edge="start"
          data-tip="add product line"
          data-for="productLine_panel_tooltip" 
        >
          <AddCircleOutlineIcon/>
        </IconButton>
      </Box>
        <DataGrid autoHeight
          rows={data.zeus_product_line}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick={false}
          getRowClassName={(params) =>
            clsx('super-app', {
              negative: params.row.status === 'closed',
              positive: params.row.status === 'accepted',
            })
          }
          options={{
            toolbar: true,
            filtering: true,
            search: true,
            grouping: true,
            exportButton: true,
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          onCellEditCommit={handleCellEditCommit}
          onRowClick={(params, evt) => {
            let productLine = params.row;
            console.log("selected product line")
            console.log(productLine)
            setActiveProductLine(productLine)
            setShowDetailPane(true)
          }}
        />
      </CardContent>
    </Card>
    <ReactTooltip id="productLine_panel_tooltip"/>
    </div>
  ); 
}
  
export default ZProductLinesPanel;
