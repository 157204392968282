import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { addJobCustomerPO,addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  },   
}));

//////////////////////

const ZSetJobClientPOReceivedButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({
    open: false,
    customerPO: '',
    poAmount: '',
    receivedDate: new Date(),
    closeDate: null,
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });

  const [updateJobResult,     updateJobFunction]     = useMutation(addJobCustomerPO);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    setState({...state, open: false})
  }

  const handleDateChange = (value) => {
    // check to see if date overlaps with restrictedDates
    setState({...state, poDate: value})
    console.log("state->"+ JSON.stringify(state));
  }

  let job_id = null;
  if(props.job){
    job_id = props.job.id
  }


  const handleConfirm = () => {
    let user_id = userData.id
    let guuid = uuidv4();
    let modifiedOn = moment().format();

    let variables = { 
      job_id: job_id,
    }
  
    variables.object = {
      id: guuid,
      job_id: job_id,
      document_type: "PurchaseOrder",
      name: state.customerPO,
      amount: state.poAmount,
      description: state.description,
      theDate: moment(state.receivedDate).format(),
      closedOn: state.closeDate ? moment(state.closeDate).format() : null,
    }   

    variables._set = {
      poAmount: state.poAmount,
      customerPO: state.customerPO,
      modifiedOn: modifiedOn,
    }

    variables.activities = [
      {
        user_id: user_id,
        parent_id: job_id,
        name: "client PO received",
        description: `${userData.alias} updated job with a customer PO# ${state.customerPO}`, 
      },
      {
        user_id: user_id,
        parent_id: guuid,
        name: "customer PO created",
        description: `${userData.alias} created new customer PO# ${state.customerPO} for ${state.poAmount} active from ${state.receivedDate}`,   
      },      
    ]

    console.log(variables)

    updateJobFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        insertNotificationFunction({object: {
          name: `New Customer PO ${result.data.insert_zeus_sales_document_one.name} created`,
          description: `${userData.alias} created Customer PO ${result.data.insert_zeus_sales_document_one.name} for job ${props.job.name}`,
          role: 'admin',
        }}); 

        setAlertState({
          open: true,
          message: 'Successfully added client PO# to Job',
        })
      }
      handleClose();
    })
  }

  ////////////////
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="scheduling_alert"
        onClose={()=>{
          setAlertState({...alertState, open: false, message: '', error: false})
        }}
        ContentProps={alertState.error === true      
        ? 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarErrorStyle
          }
        : 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarSuccessStyle
          }
        }
        message={alertState.message}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                setState({...alertState, open: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Customer PO</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="invoiceDate"
                        value={state.poDate}
                        label="PO Start Date"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />   
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="endDate"
                        value={state.closeDate}
                        label="PO End Date"
                        onChange={(value) =>{
                          setState({...state, closeDate: value})
                          console.log("state->"+ JSON.stringify(state));
                        }} 
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />                                     
                    </Grid>
                  </MuiPickersUtilsProvider> 
                </div>  
                <br/>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                  <FilledInput
                    id="filled-adornment-amount"
                    onChange={(event) =>{
                      setState({...state, poAmount: event.target.value});
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                </FormControl>
                <br/>
                <TextField
                  error={state.duplicateName}
                  helperText={state.duplicateName && 'duplicate name'}
                  id="customerPO"
                  label="Customer PO#"
                  onChange={(event) =>{
                    let newName = event.target.value;
                    let isDuplicate = true;
                    props.job.purchaseOrders.forEach((po)=>{
                      if(po.name !== newName){
                        isDuplicate = false
                      }
                    })                    
                    setState({...state, customerPO: newName, duplicateName: isDuplicate});
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
                <br/>
                <TextField
                  className={classes.descriptionField}
                  id="details"
                  label="Description"
                  multiline
                  rows={4}
                  onChange={(event) =>{
                    setState({...state, description: event.target.value});
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" disabled={state.duplicateName}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true})
        }}    
        disabled={props.disabled}                  
      >
        Add Customer PO#
      </Button>
    </div>
  )  
}

export default ZSetJobClientPOReceivedButton;


/*

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity="error">
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={10000}
              open={state.open}
              key="set_job_closed"
              onClose={()=>{
                setState({...state, customerPO: '', poAmount: '', open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={
                <div id="message-id">
                  <TextField
                    className={classes.nameField}
                    autoFocus
                    margin="dense"
                    id="clientPO"
                    label="Enter Client PO#"
                    onChange={(event) =>{
                      setState({...state, customerPO: event.target.value})
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                  /> 
                  <br/>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                    <FilledInput
                      id="filled-adornment-amount"
                      onChange={(event) =>{
                        setState({...state, poAmount: event.target.value});
                        console.log("state->"+ JSON.stringify(state));
                      }} 
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />       
                  </FormControl>        
                </div>
              }              
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleConfirm}>
                    Submit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                  Close
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
              onClick={(event) => { 
                setState({...state, open: true})
              }}                     
            >
              Add Client PO#
            </Button>
          </div>
        </Router>
      </strong>
    </div>
  )

*/