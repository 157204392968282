import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import Moment from 'react-moment';
import moment from 'moment';

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { subscribeToolNode, subscribeToolsForLocker, subscribeToolsForNode, subscribeAllJobBoardEntries } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab'; //Fab
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimelineIcon from '@material-ui/icons/Timeline';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZToolNodeBox from 'components/boxes/ZToolNodeBox';
import ZToolUsageList from 'components/lists/ZToolUsageList';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZAttachmentsList from 'components/lists/ZAttachmentsList'; //ZToolBookingsList
import ZToolBookingsList from 'components/lists/ZToolBookingsList';

import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';
import ReactTooltip from 'react-tooltip';

import ZToolsPanel from 'components/panels/ZToolsPanel'; 
import ZToolsList from 'components/lists/ZToolsList';  
import ZToolKitsList from 'components/lists/ZToolKitsList'; //ZToolKitsList
import ZTicketsList from 'components/lists/ZTicketsList';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZToolTransferList from './core/ZToolTransferList';
import ZToolTransferDialog from './dialogs/ZToolTransferDialog';
import ZAddToolDialog from './dialogs/ZAddToolDialog';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 400,
    height: 800,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

//////////////////////////////////////////////

const ZToolNodeView = (props) => {
  //let path_element = props.location.pathname.split('/')[1]

  const classes = useStyles();
  let { id } = useParams();

  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    showTicketDialog: false,
    showAnnotationDialog: false,
    showBookingDialog: false,
    showDialog: false,
    activeEntry: null,
  });
  
  const [tabIndex, setTabIndex] = React.useState(0);
  const [lat, setLat] = React.useState(null);
  const [lng, setLng] = React.useState(null);

  let row = {}
  let jobs = []
  let available_tools = []
  let all_tools = []

  let toolSub = subscribeToolsForNode

  console.log('ZToolNodeView->id');
  console.log(id);

  const calcRestrictedDates = (elements) =>{
    let restrictedDates = []
    console.log('callout')
    //console.log(row)
    elements.forEach((elem)=>{
      //console.log(elem)
      /*
      let d = new Date(elem.theDate);
      console.log(d)

      let str = d.toLocaleDateString();
      d = new Date(str);
      */
      let d = moment(elem.theDate).local()
      d = d.add(1, 'day').toDate()
      //let d = a.l

      //console.log(d)
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleAddBooking = (item) => {
    console.log('handleAddBooking')
    setState({...state, activeEntry: item, showBookingDialog: true});
  }

  const handleBookingClose = () => {
    setState({...state, showBookingDialog: false});
  };


  const handleCloseDialog = () => {
    console.log('ZToolNodeView::handleCloseDialog')
    setState({...state, showDialog: false});
  }

  const handleOpenDialog = () => {
    console.log('ZToolNodeView::handleOpenDialog');
    setState({...state, showDialog: true});
  }

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false});
  }    

  const handleSubmit = (items=[]) => {
    console.log('ZToolNodeView::handleSubmit->tooltransfer list')
    console.log(items)
  }

  const defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleAddBooking}
      data-tip="toolkit actions"
      data-for="toolkit_list_tooltip" 
    >
      <PlusOneIcon />
    </Fab>    
  )

  /////////////////

  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolNodeView [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  }, [setLat,setLng]);

  //////////////////////////////////////////////

  const [updateClientResult, updateFunction] = useMutation(updateClient);

  /*
  const [ result, reexecuteQuery ] = useQuery({
    query: getToolNode,
    variables: { id },
    requestPolicy: 'cache-and-network',
  });

  console.log('ZToolNodeView -> result');
  console.log(result)

  const { data, fetching, error } = result;
  */

  const handleSubscription = (previousData, response) => {
    console.log('ZToolNodeView->handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    let rrow = response.zeus_tool_node_by_pk
    if( rrow.node_type === 'ToolKit'){
      rrow.all_tools.map((xtool) => {
        all_tools.push(xtool.tool)
      });
    } else {
      //toolSub = subscribeToolsForLocker;
      rrow.all_locker_tools.map((xtool) => {
        all_tools.push(xtool)
      });
    }    
    //jobs = data.zeus_jobboard_entry
    return rrow
  };

  const handleJobsSubscription = (previousData, response) => {
    console.log('handleJobsSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    jobs = response.zeus_jobboard_entry
    //jobs = data.zeus_jobboard_entry
  };  
 
  console.log('setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeToolNode,
    variables: { id },
    pause: !window.navigator.onLine,
  }, handleSubscription); 

  const [jobResult] = useSubscription({ 
    query: subscribeAllJobBoardEntries,
    //pause: showDialog,
  }, handleJobsSubscription);  

  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getToolNode,
    variables: { id },
    //pause: window.navigator.onLine,
  });

  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    console.log('ZToolNodeView->queryResult');
    console.log(queryResult);
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error; 
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(!window.navigator.onLine){
    let rrow = data.zeus_tool_node_by_pk;
    if( rrow.node_type === 'ToolKit'){
      rrow.all_tools.map((xtool) => {
        all_tools.push(xtool.tool)
      });
    } else {
      //toolSub = subscribeToolsForLocker;
      rrow.all_locker_tools.map((xtool) => {
        all_tools.push(xtool)
      });
    }     
    row = data.zeus_tool_node_by_pk;
  } else {
    row = data
  }
  
  console.log('ZToolNodeView->row')
  console.log(row)

  console.log('ZToolNodeView->node_type')
  console.log(row.node_type)
  eventBus.dispatch("pageLoad", { message: row.node_type });

  for(var tool of row.all_tools){
    all_tools.push(tool.tool);
  }

  //available_tools = data.available_tools
  //////////////////////////////////////////////////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showAnnotationDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  ) 

  let addTicketAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showTicketDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  ) 

  // default is toollocker
  let dialogEl  = (
    <ZAddToolDialog parent_id={id} open={state.showDialog} handleClose={handleCloseDialog}/>
  )

  let closeButtonElement = (
    <Button
      variant="outlined"
      size="small"
      className={classes.button}
      onClick={handleCloseDialog}
      aria-label="close tool transfer"
    >
      Close
    </Button>
  );

  let submitButtonElement = (
    <Button
      variant="outlined"
      size="small"
      className={classes.button}
      onClick={handleSubmit}
      aria-label="submit"
    >
      Submit
    </Button>
  );

  let openBookingAction = (
    <div>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        onClick={(event) => { 
          setState({...state, showBookingDialog: true});
        }}                     
      >
        Add Booking
      </Button>    
    </div>
  ) 

  
  let topRow = (
    <Row>
      <Col xs={6}>
        <ZToolNodeBox id='node_box' 
          title='Tool Locker'
          node={row}
          lat={lat}
          lng={lng}
          fullBox
          showmap
          static
        />
      </Col>
      <Col xs={6}>
        { row.activities ?
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="jobboard_entry">
                  <TimelineIcon />
                </Avatar>
              }
              title='Timeline'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZActivitiesTimeline items={row.activities} />
            </Paper>  
          </Card> 
          : <div/>
        }  
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`toolnode/${row.id}/attachments`} multiple static/>
        <Row><br/></Row>
        <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />           
      </Col>      
    </Row>
  )

  if(row.node_type === 'ToolKit'){
    dialogEl = (
      <ZToolTransferList open={state.showDialog} parent={row.parent} data={all_tools} closeButton={closeButtonElement} submitButton={submitButtonElement} static/>       
    )
    topRow = (
      <Row>
        <Col xs={6}>
          <ZToolNodeBox id='node_box' 
            title='Toolkit'
            node={row}
            lat={lat}
            lng={lng}
            //refresh={reexecuteQuery}
            actions={openBookingAction}
            fullBox
            showmap
            static
          />
        </Col>
        <Col xs={6}>
          <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />               
          <ZToolUsageList key='node_usage_panel' parent={row} data={row.usage}  static/>   
          <Row><br/></Row>
          <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`toolnode/${row.id}/attachments`} multiple static/>  
          <Row><br/></Row>
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="asset_survey_images" className={classes.image_avatar}>
                  <PhotoLibraryIcon />
                </Avatar>
              }
              title='Images'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZImageListHorizontal key='image_list' parent_id={row.id} path={`toolnode/${row.id}/images`} data={row.images} />
            </Paper>  
          </Card>          
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <ZBookToolDialog open={state.showBookingDialog} handleClose={handleBookingClose} lat={lat} lng={lng} data={row} jobs={jobs} static toolnode/>
      <Container fluid>
        { topRow }
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            {/* showDialog && <ZToolTransferDialog open={showDialog} parent={row.parent} /> */}
            { state.showDialog &&  dialogEl }
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Bookings" />
                  <Tab label="Tools" />
                  <Tab label="ToolKits" />
                  <Tab label="Tickets" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={250} descriptionW={450} client={row} parent={row.locker} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZToolBookingsList key='toolkits_booked_panel' title='Bookings' action={<div></div>} parent={data} data={row.bookings} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    {/* <ZToolsPanel key='tools_panel' title={`All Tools in ${row.name}`} parent={row} data={all_tools} nodeType={row.node_type} static /> */}
                    <ZToolsList key='tools_list' title={`All Tools in ${row.name}`} parent={row} data={all_tools} action={<div></div>} nodeType={row.node_type} static />  
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box>
                    <ZToolKitsList key='kit_list' parent={row} data={row.child_nodes} static/> 
                  </Box>
                )}                
                {tabIndex === 4 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  )


}

export default ZToolNodeView;
