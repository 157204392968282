import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { setToolNodeOutOfService, setToolOutOfService } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import IconButton from 'material-ui/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

import { uuid } from 'uuidv4';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
}));

////////////////////////////////

const ZSetOutofServiceButton = (props) => {
  const history = useHistory();
  const classes = useStyles();
  //const {id} = useParams();
  const [userData, setUserData] = useLocalStorage('userData');
  const [open, setOpen] = React.useState(false);
  //const [lat, setLat] = React.useState(null);
  //const [lng, setLng] = React.useState(null);

  let updateResult = null;
  let updateFunction = null;
  
  const [updateToolNodeResult, updateToolNodeFunction] = useMutation(setToolNodeOutOfService);
  const [updateToolResult,     updateToolFunction]     = useMutation(setToolOutOfService);

  /*
  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZSetOutofServiceButton [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  });
  */

  const handleConfirmToolNode = () => {
    console.log('ZSetOutofServiceButton->handleConfirm')
    let id = uuid()
    console.log(userData)
    let user_id = userData.id
    //let user_id = "needed"
    let createdOn = moment().format();
    let description = null;
    let node_id = props.id;
    let lat = props.lat;
    let lng = props.lng;

    let variables = { id, user_id, job_id, createdOn, description, node_id, lat, lng }
    console.log(variables)

    updateToolNodeFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
      }
      let path = generatePath('/toolnode/:id', {
        id: props.id}
      )
      history.push(path)
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  let job_id = null;
  if(props.job_id !== undefined){
    job_id = props.job_id
  }

  let elem = (<div></div>)

  if(props.toolnode !== undefined){
    elem = (
      <strong>
        <div>      
          <Snackbar
            anchorOrigin={{ vertical:'top', horizontal:'center' }}
            autoHideDuration={6000}
            open={open}
            message="Are you sure you want to set this item out of service?"
            key="set_out_of_service"
            onClose={()=>{
              setOpen(false)
            }}
            action={
              <div>
                <Button className={classes.button} color="default" variant="contained" onClick={handleConfirmToolNode}>
                  Yes
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  startIcon={<CloseIcon />}
                  onClick={()=>{
                    setOpen(false)
                  }}
                >
                  No
                </Button>
              </div>
            }
          />
            <Button size="large" color="primary"           
              id={props.id}
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                setOpen(true)
              }}                     
            >
              Set Out of Service
            </Button>
        </div>
      </strong>
    )
  }

  const handleConfirmTool = () => {
    let id = uuid()
    let user_id = userData.id
    let createdOn = moment().format();
    let description = "";
    let tool_id = props.id;
    let lat = props.lat;
    let lng = props.lng;

    let variables = { id, user_id, job_id, createdOn, description, tool_id, lat, lng }
    console.log(variables)

    updateToolFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
      }
      let path = generatePath('/tool/:id', {
        id: props.id}
      )
      history.push(path)
    })
  }

  if(props.tool !== undefined){
    elem = (
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={6000}
              open={open}
              message="Are you sure you want to set this item out of service?"
              key="set_out_of_service"
              onClose={()=>{
                setOpen(false)
              }}
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleConfirmTool}>
                    Yes
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setOpen(false)
                    }}
                  >
                    No
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              onClick={(event) => { 

              }}                     
            >
              Set Out of Service
            </Button>
          </div>
        </Router>
      </strong>
    )
  }

  return (
    <div>{elem}</div>
  )
}

export default ZSetOutofServiceButton;