import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { uuid } from 'uuidv4';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation } from 'urql';
import { getAllContacts } from 'queries_urql';
import { updateZeusUser, addZeusUser, addZeusUserRoles } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';

import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';

import  ZWizard  from 'components/core/ZWizard';
import ZUserRoleAutocomplete from 'components/core/ZUserRoleAutocomplete';

import moment from 'moment';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

//import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from 'material-ui';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

/*
props: {
  client
}
*/

//////////////////////////////////////////

const ZAddZeusUserDialog = (props) => {
  const classes = useStyles();

  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    alias: undefined,
    phone: undefined,
    description: undefined,
    address: undefined,
    suite: undefined,
    region: undefined,
    roles: [],
  })

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [address, setAddress] = React.useState({label: 'NA'})
  const [suite, setSuite] = React.useState('')
  const [region, setRegion] = React.useState({})

  const [description, setDescription] = React.useState('')
  const [geometry, setGeometry] = React.useState({})

  /////////////////////

  const handleReview = () => {
    //console.log("handleReview->address"+ JSON.stringify(address));
    //console.log("handleReview->address.description"+ JSON.stringify(address.value.description));
    const x = {
      firstName: state.firstName.trim(),
      lastName: state.lastName.trim(),
      alias: state.alias.trim(),
      email: state.email.trim(),
      description: state.description.trim(),
      phone: state.phone.trim(),
      roles: [...state.roles]
    }
    console.log("handleReview->"+ JSON.stringify(x));
    setState(x) 
  }

  const handleRoleChange = (event,item) => {
    console.log('setting role')
    console.log(item)
    let roles_updated = state.roles.slice(0);
    roles_updated.push(item)
    setState({...state, role_id: item.id, roles: roles_updated})
    console.log('roles selected')
    console.log(roles_updated)
  }

  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      setRegion({province: province, country: country})
      setGeometry({ lat, lng })
      setAddress(place) 
    });    
  }

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    const guuid = uuid()
    const createdOn = moment().format()
    const user_id = userData.id;

    console.log(`id: ${guuid}`)
    address.suite = suite

    let variables = { 
      id: guuid,
      alias: state.alias.trim(),
      firstName: state.firstName.trim(), 
      lastName: state.lastName.trim(), 
      email: state.email.trim(),
      description: state.description.trim(), 
      //address: address,
      //region: region,
      status: 'active',
      createdOn: createdOn,
    }

    console.log("creating zeus_user")
    console.log(variables)
    
    let activity = {
      user_id: user_id,
      parent_id: guuid,
      name: "user created",
      description: `${userData.alias} created user ${state.alias}`,        
    }

    let user_roles = [];

    state.roles.forEach((role,index) => {
      let xx = {
        user_id: guuid,
        role_id: role.id,
      }

      if(index === 0){
        xx.is_default = 1
      }

      user_roles.push(xx)
    })

    //return;
    insertUserFunction({object: variables, activity: activity}).then(result => {
      // The result is almost identical to `updateTodoResult` with the exception
      // of `result.fetching` not being set.
      // It is an OperationResult.
      console.log("insertUserFunction -> result")

      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })

      } else {
        console.log('ZAddZeusUserDialog::insertUserRolesFunction')
        console.log(result);

        insertUserRolesFunction({objects: user_roles}).then(result => {
          if(result.error){
            console.log(`ERROR: ${result.error}`)
            setAlertState({
              open: true,
              message: `ERROR: ${result.error}`,
              severity: 'error',
            })
          }else {
            setAlertState({
              open: true,
              message: 'Successfully create new zeus user',
              severity: 'info',
            })
          }
        })
      }

      props.handleClose()
    });
  }

  //const [insertFunction] = useMutation(mutation);
  const [addUserResult, insertUserFunction] = useMutation(addZeusUser);
  const [addRolesResult, insertUserRolesFunction] = useMutation(addZeusUserRoles);

  return(
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>    
      <ZWizard 
        title="Create New Zeus User"
        steps={['Enter ZeusUser Details','Enter Description', 'Select Roles']}
        open={props.open} 
        onClose={props.handleClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
      >
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  console.log("setFirstName->"+ JSON.stringify(event.target.value));
                  setState({...state, firstName: event.target.value});
                }}
                id="userFirstName" 
                label="First Name" 
                variant="standard"
              />
              <TextField 
                onChange={(event) =>{
                  console.log("setLastName->"+ JSON.stringify(event.target.value));
                  setState({...state, lastName: event.target.value});
                }}
                id="userLastName" 
                label="Last Name" 
                variant="standard"
              />
            </Paper>   
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <TextField
                  id="userAlias"
                  label="Alias"
                  fullWidth
                  onChange={(event) =>{
                    console.log("setAlias->"+ JSON.stringify(event.target.value));
                    setState({...state, alias: event.target.value});
                  }} 
                />  
            </Paper> 

            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <TextField
                  id="userEmail"
                  label="Email"
                  fullWidth
                  onChange={(event) =>{
                    console.log("setEmail->"+ JSON.stringify(event.target.value));
                    setState({...state, email: event.target.value});
                  }} 
                />  
            </Paper> 
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <TextField
                  id="userPhone"
                  label="Phone"
                  fullWidth
                  onChange={(event) =>{
                    console.log("setPhone->"+ JSON.stringify(event.target.value));
                    setState({...state, phone: event.target.value});
                  }} 
                />  
            </Paper> 
            <br/> 

          </Box>
        </Container>

        <Container maxWidth="sm">
          <Box>
            <div>
              <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                  <TextField
                    fullWidth
                    id="userDescription"
                    label="Description"
                    multiline
                    rows={4}
                    onChange={(event) =>{
                      console.log("setDescription->"+ JSON.stringify(event.target.value));
                      setState({...state, description: event.target.value});
                    }} 
                  />  
              </Paper>            
            </div>
          </Box>
        </Container>

        <Container maxWidth="sm">
          <Box>
            <div>
              <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <ZUserRoleAutocomplete  onChange={handleRoleChange} />
              </Paper> 
              <br/>
              <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <List>
                  {state.roles.map((role)=>{

                    return(
                      <ListItem key={role.name} >
                        <ListItemText
                          primary={role.name}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete"
                            onClick={(item,index) => {
                              console.log(`removing role: ${index}`);
                              let roles_updated = state.roles.slice(0)
                              roles_updated.splice(index,1)
                              setState({...state, roles: roles_updated})
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })}                  
                </List>
              </Paper>            
            </div>
          </Box>
        </Container>        

        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Typography>{`Name: ${state.firstName} ${state.lastName}`}</Typography>
              <Typography>{`Alias: ${state.alias}`}</Typography>
              <Typography>{`Email: ${state.email}`}</Typography>
              <Typography>{`Phone: ${state.phone}`}</Typography>
              <Typography>{`Description: ${state.description}`}</Typography>
              <Typography>{`Roles`}</Typography>
              {
                state.roles.map((role)=>{
                  return(
                    <Typography>{`+ ${role.name}`}</Typography>
                  )
                })
              }
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddZeusUserDialog;
