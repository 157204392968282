import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

import { useLocalStorage } from 'hooks/useStorage';
import usePersistentState from 'hooks/usePersistentState';
import { uploadFileToAzure } from 'azure/AzureBlobAPI'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { useMutation, useQuery } from 'urql';
import { getAllQuotations, getAllClients, getClient, getContact } from 'queries_urql';
import { addAssetSurveyDialog, addNotification, addAttachments, addImages } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container'; //FormControlLabel
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import  ZWizard  from 'components/core/ZWizard';
//import  ZAutoCompleteInput  from 'components/core/ZAutoCompleteInput';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ZClientAutocomplete  from 'components/core/ZClientAutocomplete';  //
import ZContactAutocomplete from 'components/core/ZContactAutocomplete';  //ZContactAutocomplete
import ZSupplierAutocomplete from 'components/core/ZSupplierAutocomplete'; 
import ZUserAutocomplete from 'components/core/ZUserAutocomplete';  //ZUserAutocomplete
import ZProductTransferList from 'components/core/ZProductTransferList';  //ZProductTransferList
import ZFluentCalendar from 'components/core/ZFluentCalendar';

import ZPipelineInput from 'components/core/ZPipelineInput'; // ZPipelineInput
import ZQuotationNumberInput from 'components/core/ZQuotationNumberInput'; //  ZQuotationNumberInput
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';
import ZConditionAutocomplete from 'components/core/ZConditionAutocomplete';
import ZDescriptorAutocomplete from 'components/core/ZDescriptorAutocomplete';
import ZSurveyLocationAutocomplete from 'components/core/ZSurveyLocationAutocomplete';
import ZComponentAutocomplete from 'components/core/ZComponentAutocomplete';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 300,
  },  
  tableRow: {
    "&$hover:hover": {
      backgroundColor: "red"
    }
  },
}));

//////////////////////////////////////////

const ZAddAssetSurveyDialog = (props) => {
  const classes = useStyles()
  const [userData, setUserData] = useLocalStorage('userData');

  const [alert, setAlert] = React.useState({
    show: false,
    message: '',
    error: false,
  })


  const [state, setState] = usePersistentState({
    guuid: uuidv4(),
    summary: null,
    recommendations: null,
    condition: null,
    condition_value: null,
    component: null,
    severity: 'Good',
    findings_from_work_completed: null,

    client: null,
    client_node: null,
    parent: null,
    asset: null,

    user: null,
    tech: null,

    theDate: new Date(), //new Date()
    status: 'pending',
  }, 'create-new-asset-survey')


  const [addSurveyResult, insertAssetSurveyFunction] = useMutation(addAssetSurveyDialog);
  //const [addChildrenResult, insertAssetSurveyChildrenFunction] = useMutation(addAssetSurveyChildren);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);
  const [insertResult, addAttachmentsFunction] = useMutation(addAttachments); 
  const [insertImageResult, addImagesFunction] = useMutation(addImages);


  const handleReview = () => {
    const createdOn = moment().format()
    let fullName = userData.firstName +" "+ userData.lastName;

    setState({
      ...state, 
      id: state.guuid, 
      createdOn: createdOn, 
      preparedBy: fullName, 
      client_id: state.client.id, 
      client_node_id: state.client_node.id,
      parent: state.parent.id,
      asset_id: state.asset.id, 
      user_id: userData.id,
      tech_id: state.tech.id,
      status: "pending"
    })

    console.log("handleReview->"+ JSON.stringify(state));
  }

  const handleReset = () => {
    setState({
      guuid: uuidv4(),
      summary: null,
      recommendations: null,
      condition: null,
      component: null,
      severity: 'Good',
      findings_from_work_completed: null,
  
      client: null,
      client_node: null,
      parent: null,
      asset: null,
  
      user: null,
      tech: null,
  
      theDate: new Date(), //new Date()
      status: 'pending',
    })
    
    //if(props.onClose) props.onClose();
  }

  
  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      //setRegion({province: province, country: country})
      //setGeometry({ lat, lng })
      //setAddress(place) 
    });    
  }


  const handleSubmit = () => {
    console.log("ZAddAssetSurveyDialog::handleSubmit->"+ JSON.stringify(state));
    console.log(state);
  
    const modifiedOn = moment().format();

    // create job
    const { guuid, client, client_node, parent, asset, user, tech, condition, location, summary, component, componentName, theDate, ...object } = state;

    object.id = state.guuid;
    object.createdBy = userData.alias;
    object.theDate = JSON.stringify(theDate);
    object.asset_id = props.asset.id;
    object.client_id = props.client.id;
    object.summary = summary.name;

    console.log('asset_survey->object');
    console.log(object);

    let asset_activity = {
      user_id: userData.id,
      parent_id: props.asset.id,
      name: "asset survey created",
      description: `${userData.alias} created asset survey on ${state.theDate}`,           
    }

    let survey_activity = {
      user_id: userData.id,
      parent_id: state.guuid,
      name: "asset survey created",
      description: `${userData.alias} created asset survey for ${props.asset.name} on ${state.theDate}`,           
    }    

    let asset_update = {
      currentCondition: state.severity,
      modifiedOn: modifiedOn,
    }

    console.log('asset_survey');
    console.log(object);

    console.log('asset_update');
    console.log(asset_update);

    console.log('asset_activity');
    console.log(asset_activity);

    console.log('survey_activity');
    console.log(survey_activity);

    insertAssetSurveyFunction({
      user_id: userData.id,
      id: state.guuid,
      object: object,
      asset_id: props.asset.id,
      _set: asset_update,
      activities: [asset_activity, survey_activity],
    }).then((result)=>{
      if(result.error){
        console.log(`ERROR creating asset survey: ${result.error}`)
        setAlert({...alert, show: true, message: `ERROR creating asset survey: ${result.error}`, error: true})

      } else {
        console.log(`created asset survey [ ${object} ]`)
        console.log(result)

        // if the asset is in bad shape send a notification
        // this should be done when record status is changed from pending -> active
        /*
        if(['Unacceptable','Unsatisfactory','Reportable'].includes(state.severity)){
          insertNotificationFunction({object: {
            name: `Current condition ${state.severity} reported for ${state.asset.name}`,
            description: `${userData.alias} reported the current condition for asset ${state.client.name} - ${state.asset.name} as ${object.severity}`,
            role: 'manager',
          }}); 
        }
        */

        if(state.images !== null){
          uploadFileToAzure(`survey/${guuid}/images`, state.File).then((data) => {
            let xuuid = uuidv4();
            let attachment = {}; 
            attachment.id = xuuid;
            attachment.name = data.fileName;
            attachment.url = data.url;
            attachment.bucket = data.bucket;
            attachment.path = data.path;
            attachment.parent_id = guuid;          
      
            console.log('attachment')
            console.log(attachment)
      
            addImagesFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
              if (result.error) {
                console.error('ERROR creating attachment', result.error);
                setAlert({
                  ...alert,
                  message: result.error,
                  show: true,
                  error: true,
                })
              }
              //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
            })
          }).catch(err => console.error(err))           
        }
  
        if(state.attachments !== null){
          uploadFileToAzure(`survey/${guuid}/attachments`, state.File).then((data) => {
            let xuuid = uuidv4();
            let attachments = [{}]; 
            attachments[0].id = xuuid;
            attachments[0].name = data.fileName;
            attachments[0].url = data.url;
            attachments[0].bucket = data.bucket;
            attachments[0].path = data.path;
            attachments[0].parent_id = guuid;          
      
            console.log('attachments')
            console.log(attachments)
      
            addAttachmentsFunction({ id: xuuid, user_id: userData.id, object: attachments }).then((result)=>{
              if (result.error) {
                console.error('ERROR creating attachment', result.error);
                setAlert({
                  ...alert,
                  message: result.error,
                  show: true,
                  error: true,
                })
              }
              //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
            })
          }).catch(err => console.error(err)) 
        }              
      }
    })

    if(props.handleClose){
      props.handleClose()
    }
  }

  const handleClientNotification = (event,item) => {
    console.log("handleClientNotification")
    console.log(item)
    console.log(event.target.checked)
    setState({...state, notifyClient: event.target.checked})
  }

  const handleSeverityChange = (event,item) => {
    console.log("handleSeverityChange")
    console.log(item.props.value)
    //let address = item.
    setState({...state, severity: item.props.value})
  }

  const handleComponentChange = (event,item) => {
    console.log("handleComponentChange")
    console.log(item)
    setState({...state, component: item, component_id: item.id, componentName: item.name})
  }

  const handleLocationChange = (event,item) => {
    console.log("handleLocationChange")
    console.log(item)
    setState({...state, location: item, location_id: item.id})
  }

  const handleConditionChange = (event,item) => {
    console.log("handleConditionChange")
    console.log(item)
    setState({...state, condition: item, condition_id: item.id, condition_value: item.name})
  }

  const handleDescriptorChange = (event,item) => {
    console.log("handleDescriptorChange")
    console.log(item)
    setState({...state, summary: item, descriptor_id: item.id})
  }

  const handleSurveyDateSelected = (theDate, dateRange) => {
    console.log("handleSurveyDateSelected")
    console.log(theDate)
    setState({...state, theDate: theDate})
  }

  const handleAttachmentFileChange = (file) => {
    console.log('handleAttachmentFileChange')
    console.log(file)
    var fileName = file.name.substring(0, file.name.lastIndexOf('.'));
    console.log('attachmentFileName')
    console.log(fileName)
    setState({...state, attachmentFileName: fileName, attachmentFile: file})
  }

  const handleUserChange = (event,item) => {
    console.log('setting tech')
    console.log(item)
    setState({...state, tech_id: item.id, tech: item})
  }    
  //const { loading, error, data }  = useQuery(getAllClients);

  //console.log("add quotation dialog->data")
  //console.log(data)

  const AssetNotificationCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  //////////////////

  return(
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alert.show}
        message={alert.message}
        key="alert_button"
        //onClose={()=>{
        //  setAlert({...alert, show: false, message: '', error: false})
        //}}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color={alert.error === true ? "secondary" : "primary"}
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlert({...alert, show: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />     
      <ZWizard 
        title={`Create New Asset Survey: ${props.asset ? props.asset.name : ''}`}
        steps={[
          'Severity',
          'Survey Details',
          'Recommendations',
          'Technician Details',
        ]}
        open={props.open} 
        onClose={props.onClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
        onReset={handleReset}
        disabled={
          state.tech === null ? true 
        : state.severity === null ? true 
        : state.theDate === null ? true 
        : state.component === null ? true 
        : state.location === null ? true 
        //: state.condition === null ? true 
        : false}
      >
        {/* severity */}
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Select
                labelId="severity-select-label"
                id="severity-simple-select"
                value={state.severity}
                onChange={handleSeverityChange}
              >
                <MenuItem value={'Good'}>Good</MenuItem>
                <MenuItem value={'Acceptable'}>Acceptable</MenuItem>
                <MenuItem value={'Reportable'}>Reportable</MenuItem> 
                <MenuItem value={'Unsatisfactory'}>Unsatisfactory</MenuItem> 
                <MenuItem value={'Unacceptable'}>Unacceptable</MenuItem> 
                <MenuItem value={'No Status'}>No Status</MenuItem> 
                <MenuItem value={'No Access'}>No Access</MenuItem> 
              </Select>
            </Paper>        
            <br/><br/>
            <FormControlLabel
              control={<AssetNotificationCheckbox checked={state.notifyClient} onChange={handleClientNotification} name="notifyClient" />} label="Send Client Notification"
            />   
            <br/><br/>
            <FormControl className={classes.formControl}>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="surveyDate"
                      value={state.theDate}
                      label="Survey Date"
                      onChange={handleSurveyDateSelected}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />   
                  </Grid>
                </MuiPickersUtilsProvider> 
              </div> 
            </FormControl>  
          </Box>
        </Container>        
        { /*  survey details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZComponentAutocomplete open={props.open} default={state.component} onChange={handleComponentChange} />
            </Paper>   
            <br/>           
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZSurveyLocationAutocomplete open={props.open} default={state.location} onChange={handleLocationChange} />
            </Paper>     
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZConditionAutocomplete open={props.open} default={state.condition} onChange={handleConditionChange} />
            </Paper> 
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                state.condition !== null
                ? <ZDescriptorAutocomplete open={props.open} default={state.summary} condition={state.condition} onChange={handleDescriptorChange} />
                : <div></div>
              }
            </Paper> 
          </Box>
        </Container>
        { /*  recommendation details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 600, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="details"
                label="Recommendations"
                value={state.recommendations}
                fullWidth
                multiline
                rows={2}
                onChange={(event) =>{
                  console.log("recommendations->"+ JSON.stringify(event.target.value));
                  setState({...state, recommendations: event.target.value});
                }} 
              />  
            </Paper> 
            <br/><br/>
            <ZFileCloudUploadMenuItem parent_id={state.guuid} title='Upload Attachments' onFileChange={handleAttachmentFileChange} path={`survey/${state.guuid}/attachments`} noButton/>              
          </Box>
        </Container>
        { /*  technician details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZUserAutocomplete open={props.open} default={state.tech} label='Choose Tech' onChange={handleUserChange} />
            </Paper> 
            <br/>
            <Paper sx={{ maxWidth: 600, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="findings"
                label="Findings from work completed"
                value={state.findings_from_work_completed}
                fullWidth
                multiline
                rows={2}
                onChange={(event) =>{
                  console.log("findings_from_work_completed->"+ JSON.stringify(event.target.value));
                  setState({...state, findings_from_work_completed: event.target.value});
                }} 
              />  
            </Paper>             
          </Box>
        </Container>          
        <Container maxWidth="sm">
          {console.log(state)}
          <Box>
            <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow key='0' selected={props.client ? false : true}>
                      <TableCell align="right">Client</TableCell><TableCell align="left">{props.client ? props.client.name  : 'Client Required'}</TableCell>
                    </TableRow>

                    <TableRow key='1' selected={props.asset ? false : true}>
                      <TableCell align="right">Asset Tag</TableCell><TableCell align="left">{props.asset ? props.asset.name  : 'Asset Required'}</TableCell>
                    </TableRow>

                    <TableRow key='2' selected={state.component === null ? true : false}>
                      <TableCell align="right">Component</TableCell><TableCell align="left">{state.component ? state.component.name : 'Component Type Required'}</TableCell>
                    </TableRow>

                    <TableRow key='3' selected={state.location === null ? true : false}>
                      <TableCell align="right">Location</TableCell><TableCell align="left">{state.location ? state.location.name : 'Location Required'}</TableCell>
                    </TableRow>

                    <TableRow key='4' selected={state.severity === null ? true : false}> 
                      <TableCell align="right">Severity</TableCell><TableCell align="left">{state.severity ? state.severity : 'Severity Required'}</TableCell>
                    </TableRow>

                    <TableRow key='4' selected={state.condition === null ? true : false}> 
                      <TableCell align="right">Condition</TableCell><TableCell align="left">{state.condition ? state.condition.name : 'Condition Required'}</TableCell>
                    </TableRow>                    

                    <TableRow key='5'>
                      <TableCell align="right">Summary</TableCell><TableCell align="left">{state.summary ? state.summary.name : 'Summary Required'}</TableCell>
                    </TableRow>

                    <TableRow key='6'>
                      <TableCell align="right">Recommendations</TableCell><TableCell align="left">{state.recommendations}</TableCell>
                    </TableRow>                    

                    <TableRow key='7' selected={state.theDate === null ? true : false}>
                      <TableCell align="right">Survey Date</TableCell><TableCell align="left">{state.theDate ? JSON.stringify(state.theDate) : 'Survey Date Required'}</TableCell>
                    </TableRow>

                    <TableRow key='8'>
                      <TableCell align="right">Findings From Work Completed</TableCell><TableCell align="left">{state.findings_from_work_completed}</TableCell>
                    </TableRow>

                    <TableRow key='9'>
                      <TableCell align="right">User</TableCell><TableCell align="left">{userData.alias}</TableCell>
                    </TableRow>

                    <TableRow key='10' selected={state.tech === null ? true : false}>
                      <TableCell align="right">Tech</TableCell><TableCell align="left">{state.tech ? state.tech.alias : 'Tech Required'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Container>                
      </ZWizard>
    </div>
  )
};

export default ZAddAssetSurveyDialog;


/*

  <br/> 
  <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
    <ZConditionAutocomplete open={props.open} onChange={handleConditionChange} />
  </Paper> 

*/
