import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { getAllQuotations, salesBySupplierAndFunnelQuery, getQuotationsByMonthAndSupplier } from 'queries';
import { addQuotation, updateSalesDocument } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllQuotations, salesBySupplierAndFunnelQuery, getQuotationsByMonthAndSupplier } from 'queries_urql';
import { subscribeAllQuotations } from 'subscriptions_urql';
import { addQuotation, updateSalesDocument  } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import BurstModeIcon from '@material-ui/icons/BurstMode';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZQuotationDetailPane from 'components/core/ZQuotationDetailPane';


//////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////

const ZQuotationsPanel = (props) => {
  //const [quoteId, setQuoteId] = useLocalStorage('quote_id', '');
  const [userData, setUserData] = useLocalStorage('userData');
  const history = useHistory();
  const [showDialog, setShowDialog] = React.useState(false);
  const [activeQuotation, setActiveQuotation] = React.useState({
    show: false,
    quotation: null,
  });
  //const [rows, setRows] = React.useState([]);

  let rows = []
  const classes = useStyles();

  let query = getAllQuotations;
  let variables = {};
  let query_params = { 
    pollInterval: 5000,
  }

  const [updateSalesDocResult, updateFunction] = useMutation(updateSalesDocument);
  const [insertSalesDocResult, insertFunction] = useMutation(addQuotation);

  let supplier = false;
  if(props.supplier !== undefined){
    if(! props.supplier.includes('All')){
      supplier = true
      console.log("ZQuotationsPanel -> supplier is set")
      console.log(props)
    }     
  }

  if(props.funnel !== undefined){
    let funnel = props.funnel
    variables = {
      funnel,
    }

    query = `
      query getQuotationsForFunnel($funnel: String = "") {
        zeus_sales_document(where: {_and: {funnel: {_eq: $funnel}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
          id
          name
          funnel
          amount
          description
          createdOn
          closedOn
          acceptedOn
          status
          stage
          mmcStage
          preparedBy
          contact {
            id
            name
            email
          }
          client {
            id
            name
            region: address(path: "region.province")
          }
        }
      }
    `;

    query_params = { 
      variables: variables,
      pollInterval: 5000,
    }
  }

  if(supplier === true
    && typeof props.funnel !== 'undefined'){
    console.log("setting query to salesBySupplierAndFunnelQuery")
    query = salesBySupplierAndFunnelQuery

    let funnel = props.funnel
    let supplier = `%${props.supplier}%`
    let status = "accepted"

    variables = {
      funnel,
      supplier,
      status,
    }   

    query_params = {
      variables: variables,
    }
  }

  if(supplier === true 
    && typeof props.year !== 'undefined'
    && typeof props.month !== 'undefined'){
    console.log("setting query to getQuotationsByMonthAndSupplier")
    query = getQuotationsByMonthAndSupplier

    let created_year = props.year
    let created_month = props.month
    let supplier = `${props.supplier}`

    variables = {
      created_year,
      created_month,
      supplier,
    }   
    query_params = {
      variables: variables,
      pollInterval: 5000,
    }
  }


  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="accepted">accepted</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  let columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 120,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>
            <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let quote = params.row;
                  console.log("selected quotation");
                  console.log(quote);
                  setActiveQuotation({
                    quotation: quote,
                    show: true,
                  });
                }}          
              >
                <BurstModeIcon />
              </IconButton>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/quotation/:id', {
                    id: params.row.id}
                    )
                  console.log('push path')
                  console.log(path)
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Quote#',
      headerName: 'Quote#',
      minWidth: 220,
      editable: false,
      hide: false,
    },
    {
      field: 'funnel',
      title: 'Funnel',
      headerName: 'Funnel',
      minWidth: 200,
      editable: true,
      hide: false,
    },    
    {
      field: 'amount',
      title: 'Amount',
      headerName: 'Amount',
      type: 'number',
      minWidth: 130,
      editable: true,
      renderCell: (params) => (
        <div>$ { params.row.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }</div>
      )
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 400,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: true,
      cellClassName: (params) =>
        clsx('super-app', {
          negative: params.value === 'closed',
          positive: params.value === 'accepted',
        }),      
    },
    {
      field: 'createdOn',
      title: 'Quoted',
      headerName: 'Quoted',
      type: 'date',
      minWidth: 150,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    },
    {
      field: 'acceptedOn',
      title: 'Accepted',
      headerName: 'Accepted',
      type: 'date',
      minWidth: 150,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.acceptedOn}
        </Moment>
      )
    },
    {
      field: 'closedOn',
      title: 'Closed',
      headerName: 'Closed',
      type: 'date',
      minWidth: 150,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.closedOn}
        </Moment>
      )
    },
    {
      field: 'preparedBy',
      title: 'Prepared By',
      headerName: 'Prepared By',
      minWidth: 200,
      editable: false,
      sortable: true,
    },
    {
      field: 'region',
      title: 'Region',
      headerName: 'Region',
      minWidth: 150,
      editable: false,
      sortable: true,
      valueGetter: (params) =>{
        //console.log(params.row)
        return params.row.client.region
      },
    },
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZQuotationsPanel::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      
      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set 
      }
      console.log(variables)

      updateFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR updating sales document: ${result.error}`)
        } else {
          console.log(`sales document update [ ${_set} ]`)
          console.log(result)
        }
      })
    },
    [],
  );

  const handleClickOpen = () => {
    console.log("ZQuotationsPanel::handleClickOpen->open dialog wizard")
    setShowDialog(true);
    console.log("ZQuotationsPanel::handleClickOpen == "+ showDialog);
  };
  
  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCloseDetailPane = () => {
    //setShowDetailPane(false);
    setActiveQuotation({
      show: false,
      quotation: null,
    });
  };

  /////////////////
  console.log('query')
  console.log(query)
  console.log(variables)
  
  const [ result, reexecuteQuery ] = useQuery({
    query: query,
    variables: variables,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };
 
  const [res] = useSubscription({ query: subscribeAllQuotations }, handleSubscription); 

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(data.zeus_sales_document !== undefined){
    rows = data.zeus_sales_document.slice(0)
  } 

  if(data.zeus_quotation_view !== undefined){
    rows = data.zeus_quotation_view.slice(0)
  } 

  /////////////////////

  if(props.funnel !== undefined && props.filter !== undefined){
    console.log('ZQuotationsPanel')
    console.log('filter')
    console.log(props.filter)
    console.log(props.funnel)
    console.log(variables)
    console.log(data)

    if(props.filter.items.length !== 0){
      console.log('filter')
      console.log(props.filter)
      return (
        <div className={classes.root}>
        {/* 
        ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
        <ZQuotationDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} quotation={activeQuotation}/>
        */}
        <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
        <ZQuotationDetailPane isOpen={activeQuotation.show} handleClose={handleCloseDetailPane} quotation={activeQuotation.quotation}/>
        <Card  variant="outlined" elevation={2}>
          <CardContent>
          <Box display="flex" bgcolor="#5c6bc0">
            <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
            {`Quotations in funnel: ${props.funnel}`}
            </Typography>
            <IconButton
              onClick={handleClickOpen}
              style={{color:"white"}}
              color="inherit"
              aria-label="add row"
              edge="start"
              data-tip="add quotation"
              data-for="quotation_panel_tooltip" 
            >
              <AddCircleOutlineIcon/>
            </IconButton>
          </Box>
            <DataGrid autoHeight
              rows={data.zeus_sales_document}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={false}
              getRowClassName={(params) =>
                clsx('super-app', {
                  negative: params.row.status === 'closed',
                  positive: params.row.status === 'accepted',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              filterModel={props.filter}
              onCellEditCommit={handleCellEditCommit}
            />
          </CardContent>
        </Card>
        <ReactTooltip id="quotation_panel_tooltip"/>
        </div>
      ); 
    }
  }


  if(props.funnel !== undefined && supplier === true){
    //refetch()
    console.log('ZQuotationsPanel')
    console.log('funnel')
    console.log('supplier')
    console.log(props.funnel)
    console.log(props.supplier)
    console.log(variables)
    console.log(data)
    //let qqq = JSON.stringify(query)
    //console.log("gql query")
    //console.log(qqq)

    return (
      <div className={classes.root}>
        {/* 
        <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
        <ZQuotationDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} quotation={activeQuotation}/>
        */}
      <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
      <ZQuotationDetailPane isOpen={activeQuotation.show} handleClose={handleCloseDetailPane} quotation={activeQuotation.quotation}/>
      <Card  variant="outlined" elevation={2}>
        <CardContent>
        <Box display="flex" bgcolor="#5c6bc0">
          <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          {`Quotations for ${props.supplier} in funnel: ${props.funnel}`}
          </Typography>
          <IconButton
            onClick={handleClickOpen}
            style={{color:"white"}}
            color="inherit"
            aria-label="add row"
            edge="start"
            data-tip="add quotation"
            data-for="quotation_panel_tooltip" 
          >
            <AddCircleOutlineIcon/>
          </IconButton>
        </Box>
          <DataGrid autoHeight
            rows={data.zeus_sales_document}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            getRowClassName={(params) =>
              clsx('super-app', {
                negative: params.row.status === 'closed',
                positive: params.row.status === 'accepted',
              })
            }
            options={{
              toolbar: true,
              filtering: true,
              search: true,
              grouping: true,
              exportButton: true,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellEditCommit={handleCellEditCommit}
          />
        </CardContent>
      </Card>
      <ReactTooltip id="quotation_panel_tooltip"/>
      </div>
    ); 
  }


  if(props.funnel !== undefined){
    console.log('ZQuotationsPanel')
    console.log('funnel')
    console.log(props.funnel)
    console.log(variables)
    console.log(data)

    return (
      <div className={classes.root}>
        {/* 
        <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
        <ZQuotationDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} quotation={activeQuotation}/>
        */}
      <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
      <ZQuotationDetailPane isOpen={activeQuotation.show} handleClose={handleCloseDetailPane} quotation={activeQuotation.quotation}/>
      <Card  variant="outlined" elevation={2}>
        <CardContent>
        <Box display="flex" bgcolor="#5c6bc0">
          <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          {`Quotations in funnel: ${props.funnel}`}
          </Typography>
          <IconButton
            onClick={handleClickOpen}
            style={{color:"white"}}
            color="inherit"
            aria-label="add row"
            edge="start"
            data-tip="add quotation"
            data-for="quotation_panel_tooltip" 
          >
            <AddCircleOutlineIcon/>
          </IconButton>
        </Box>
          <DataGrid autoHeight
            rows={data.zeus_sales_document}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            getRowClassName={(params) =>
              clsx('super-app', {
                negative: params.row.status === 'closed',
                positive: params.row.status === 'accepted',
              })
            }
            options={{
              toolbar: true,
              filtering: true,
              search: true,
              grouping: true,
              exportButton: true,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellEditCommit={handleCellEditCommit}
          />
        </CardContent>
      </Card>
      <ReactTooltip id="quotation_panel_tooltip"/>
      </div>
    ); 
  }


  if(props.year !== undefined
    && props.month !== undefined
    && supplier === true){
    console.log('ZQuotationsPanel')
    console.log('year + month + supplier')
    console.log(props.supplier)
    console.log(variables)
    console.log(data)

    return (
      <div className={classes.root}>
        {/* 
        <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>        
        <ZQuotationDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} quotation={activeQuotation}/>
        */}
      <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
      <ZQuotationDetailPane isOpen={activeQuotation.show} handleClose={handleCloseDetailPane} quotation={activeQuotation.quotation}/>
      <Card  variant="outlined" elevation={2}>
        <CardContent>
        <Box display="flex" bgcolor="#5c6bc0">
          <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          {`Quotations in for ${props.supplier} in Selected Month`}
          </Typography>
          <IconButton
            onClick={handleClickOpen}
            style={{color:"white"}}
            color="inherit"
            aria-label="add row"
            edge="start"
            data-tip="add quotation"
            data-for="quotation_panel_tooltip" 
          >
            <AddCircleOutlineIcon/>
          </IconButton>
        </Box>
          <DataGrid autoHeight
            rows={data.zeus_quotation_view}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            getRowClassName={(params) =>
              clsx('super-app', {
                negative: params.row.status === 'closed',
                positive: params.row.status === 'accepted',
              })
            }
            options={{
              toolbar: true,
              filtering: true,
              search: true,
              grouping: true,
              exportButton: true,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellEditCommit={handleCellEditCommit}
          />
        </CardContent>
      </Card>
      <ReactTooltip id="quotation_panel_tooltip"/>
      </div>
    ); 
  }

  console.log('ZQuotationsPanel')
  console.log('default')
  console.log(props.funnel)
  console.log(props.filter)
  console.log(variables)
  console.log(data)
  console.log('activeQuotation');
  console.log(activeQuotation);
  return (
    <div className={classes.root}>
    {/* 
    <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
    <ZQuotationDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} quotation={activeQuotation}/>
    */}
    <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
    <ZQuotationDetailPane isOpen={activeQuotation.show} handleClose={handleCloseDetailPane} quotation={activeQuotation.quotation}/>
    <Card variant="outlined" elevation={2}>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
          Quotations
        </Typography>
        <IconButton
          onClick={handleClickOpen}
          style={{color:"white"}}
          color="inherit"
          aria-label="add row"
          edge="start"
          data-tip="add quotation"
          data-for="quotation_panel_tooltip" 
        >
          <AddCircleOutlineIcon/>
        </IconButton>
      </Box>
        <DataGrid autoHeight
          loading={fetching}
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          getRowClassName={(params) =>
            clsx('super-app', {
              negative: params.row.status === 'closed',
              positive: params.row.status === 'accepted',
            })
          }
          options={{
            toolbar: true,
            filtering: true,
            search: true,
            grouping: true,
            exportButton: true,
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          onCellEditCommit={handleCellEditCommit}
        />
      </CardContent>
    </Card>
    <ReactTooltip id="quotation_panel_tooltip"/>
    </div>
  ); 
}
  
export default ZQuotationsPanel;

/*
          onRowDoubleClick={(params, evt) => {
            let quote = params.row;
            console.log("selected quotation")
            console.log(quote)
            setActiveQuotation(quote)
            setShowDetailPane(true)
          }}
*/