import React from 'react';
//import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery } from '@apollo/client';
import { salesStatsQuery , salesFunnelStatsQuery, salesBySupplierAndFunnelQuery, salesBySupplierAndMonthQuery } from 'queries';
*/

import { useQuery } from 'urql';
import { salesStatsQuery , salesFunnelStatsQuery, salesBySupplierAndFunnelQuery, salesBySupplierAndMonthQuery } from 'queries_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import Typography from '@material-ui/core/Typography';
import ZTitle from 'components/core/ZTitle';
import Moment from 'react-moment';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

//////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10),
  },
  info: {
    margin: theme.spacing(2),
  },
}));

//////////////////////

const ZSalesTimeStatsBox = (props) => {
  const classes = useStyles();
  const now = moment()
  const the_year = now.year()
  const the_month = now.month()

  let supplier = false;
  if(typeof props.supplier !== 'undefined'){
    if(! props.supplier.includes('All')){
      supplier = true
    }     
  }

  let query = salesStatsQuery
  let variables = {
    accepted_year: the_year,
    accepted_month: the_month,    
  }

  if(typeof props.year !== 'undefined' && typeof props.month !== 'undefined'){
    variables = {
      accepted_year: props.year,
      accepted_month: props.month,
    }
  }

  if(typeof props.funnel !== 'undefined'){
    query = salesFunnelStatsQuery
    variables = {funnel: props.funnel}
  }

  if(supplier){
    query = salesBySupplierAndFunnelQuery
    variables = {
      funnel: props.funnel,
      supplier: `%${props.supplier}%`,
      status: "accepted",
    }   
  }

  //salesBySupplierAndMonthQuery
  if(supplier === true
    && typeof props.year !== 'undefined' 
    && typeof props.month !== 'undefined'){

      query = salesBySupplierAndMonthQuery
      variables = {
        accepted_year: props.year,
        accepted_month: props.month,
        supplier: `${props.supplier}`,
        status: "accepted",
      } 
  }

  /*
  const { loading, error, data }  = useQuery(query,{ 
    variables: variables,
    pollInterval: 10000,
   });

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: query,
    variables: variables,
  });

  console.log('variables')
  console.log(variables)

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  const naReturn = (
    <Card>
      <ZTitle>Current Month Time Statistics</ZTitle>
        <Typography component="p" variant="h4">
          NA
        </Typography>
        <Typography color="primary">NA</Typography>
        <Typography>NA</Typography>
        <Typography>NA</Typography>
    </Card>
  ); 

  if(typeof props.funnel !== 'undefined' && supplier === true){

    console.log("supplier sales in funnel stats")
    console.log(data);

    let current_funnel= data.zeus_supplier_funnel_sales_stats[0]
  
    let total_amount = current_funnel.total_amount
    let max_amount = current_funnel.max_amount
    let min_amount = current_funnel.min_amount
  
    let avg_time_to_accept = current_funnel.avg_time_to_accept
    let max_time_to_accept = current_funnel.max_time_to_accept
    let min_time_to_accept = current_funnel.min_time_to_accept
    
    let quote_count = current_funnel.quote_count

    return (
      <Card>
        <ZTitle>{`Funnel: ${props.supplier} Time Statistics`}</ZTitle>
        <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`Sales: ${quote_count}`}
          </Typography>
          <Typography color="primary">{`average: ${avg_time_to_accept}`}</Typography>
          <Typography>{`max: ${max_time_to_accept}`}</Typography>
          <Typography>{`min: ${min_time_to_accept}`}</Typography>
          </div>
      </Card>
    );
  }


  if(typeof props.year !== 'undefined' && supplier === true){

    console.log("supplier sales in month stats")
    console.log(props)
    console.log(variables)
    console.log(data);

    if(data.zeus_monthly_supplier_sales_view.length === 0){
      return naReturn;
    }
    let index = data.zeus_monthly_supplier_sales_view.length -1;
    let current_month = data.zeus_monthly_supplier_sales_view[index]
    //let current_month = data.zeus_monthly_supplier_sales_view[0]
    
    let total_amount = current_month.total_amount
    let max_amount = current_month.max_amount
    let min_amount = current_month.min_amount
  
    let avg_time_to_accept = current_month.avg_time_to_accept
    let max_time_to_accept = current_month.max_time_to_accept
    let min_time_to_accept = current_month.min_time_to_accept
    
    let quote_count = current_month.quote_count

    let title = 'Current Month Time Statistics'
    if(supplier === true){
      title = `Selected Month Time Statistics for ${props.supplier}`
    }

    return (
      <Card>
        <ZTitle>{title}</ZTitle>
        <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`Sales: ${quote_count}`}
          </Typography>
          <Typography color="primary">{`average: ${avg_time_to_accept}`}</Typography>
          <Typography>{`max: ${max_time_to_accept}`}</Typography>
          <Typography>{`min: ${min_time_to_accept}`}</Typography>
          </div>
      </Card>
    );
  }


  if(typeof props.funnel !== 'undefined'){

    console.log("sales in funnel stats")
    console.log(data);

    let current_funnel= data.zeus_funnel_sales_stats[0]
  
    let total_amount = current_funnel.total_amount
    let max_amount = current_funnel.max_amount
    let min_amount = current_funnel.min_amount
  
    let avg_time_to_accept = current_funnel.avg_time_to_accept
    let max_time_to_accept = current_funnel.max_time_to_accept
    let min_time_to_accept = current_funnel.min_time_to_accept
    
    let quote_count = current_funnel.quote_count

    return (
      <Card>
        <ZTitle>Funnel: Time Statistics</ZTitle>
        <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`Sales: ${quote_count}`}
          </Typography>
          <Typography color="primary">{`average: ${avg_time_to_accept}`}</Typography>
          <Typography>{`max: ${max_time_to_accept}`}</Typography>
          <Typography>{`min: ${min_time_to_accept}`}</Typography>
          </div>
      </Card>
    );
  }


  if(typeof props.year !== 'undefined'){
    console.log("sales in month stats")
    console.log(data);
    //let index = data.zeus_monthly_sales_stats.length -1;
    //let current_month = data.zeus_monthly_sales_stats[index]
    let current_month = data.zeus_monthly_sales_stats[0]

    let total_amount = 0
    let max_amount = 0
    let min_amount = 0

    let avg_time_to_accept = 0
    let max_time_to_accept = 0
    let min_time_to_accept = 0

    let quote_count = 0

    if (typeof current_month !== 'undefined'){
      total_amount = current_month.total_amount
      max_amount = current_month.max_amount
      min_amount = current_month.min_amount

      avg_time_to_accept = current_month.avg_time_to_accept
      max_time_to_accept = current_month.max_time_to_accept
      min_time_to_accept = current_month.min_time_to_accept
      
      quote_count = current_month.quote_count
    }
  


    let title = 'Current Month Time Statistics'
    if(props.month !== the_month){
      title = 'Selected Month Time Statistics'
    }

    return (
      <Card>
        <ZTitle>{title}</ZTitle>
        <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`Sales: ${quote_count}`}
          </Typography>
          <Typography color="primary">{`average: ${avg_time_to_accept}`}</Typography>
          <Typography>{`max: ${max_time_to_accept}`}</Typography>
          <Typography>{`min: ${min_time_to_accept}`}</Typography>
          </div>
      </Card>
    );
  } else {
    return (
      <Card>
        <ZTitle>Current Month Time Statistics</ZTitle>
          <Typography component="p" variant="h4">
            NA
          </Typography>
          <Typography color="primary">NA</Typography>
          <Typography>NA</Typography>
          <Typography>NA</Typography>
      </Card>
    );    
  }
}

export default ZSalesTimeStatsBox;

/*
      <div>
        <Link color="primary" href="#">
          Do something
        </Link>
      </div>
*/