import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan, purple, lightBlue, orange, yellow } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getSensorsForGateway } from 'queries_urql';
import { subscribeToolKitsForNode } from 'subscriptions_urql';
import { updateSensor, zeusSensorUpdateAlias, addNotification } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import ReactTooltip from 'react-tooltip';

import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import { DivIcon } from 'leaflet';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  sensor_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.active': {
      backgroundColor: lightBlue[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },    
    '& .super-app.non_responsive': {
      backgroundColor: orange[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.recovered': {
      backgroundColor: yellow[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.lost': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.anomaly': {
      backgroundColor: red[200], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },    
    '& .super-app.low_battery': {
      backgroundColor: purple[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: red[100], //red
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100],   //green
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.anomaly': {
      backgroundColor: orange[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },    
  },
}));

//////////////////////////////////////////////////////////////////////

/*
props : {
  parent: {}

}
*/

const styles = mergeStyleSets();

//////////////////////////////////////////////////////////////////////

const ZSensorsList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  //const [routeId, storeRouteId] = useLocalStorage('route_id', '');
  const [userData, setUserData] = useLocalStorage('userData');
  //const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [anchorHeader, setAnchorHeader] = React.useState(null);

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    serverity: 'info',
    error: false,
  });

  const [state, setState] = React.useState({
    activeEntry: undefined,
  });
  
  //////////////

  let rows = []

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="non-responsive">non-responsive</MenuItem>
        <MenuItem value="recovered">recovered</MenuItem>
        <MenuItem value="lost">lost</MenuItem>
        <MenuItem value="low battery">low battery</MenuItem>
        <MenuItem value="retired">retired</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  let columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={()=>window.open(`https://zeus.primac.app/sensor/${params.row.id}`, "newwindow")}     
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
        </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: props.nameW ?? 130,
      editable: false,
      hide: false,
    },
    {
      field: 'alias',
      title: 'Alias',
      headerName: 'Alias',
      minWidth: props.aliasW ?? 180,
      editable: true,
      hide: false,
    },    
    {
      field: 'lastMeasurement',
      title: 'Last',
      headerName: 'Last',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => {
        if(params.row.lastMeasurement == null){
          return 'N/A'
        }
      
        return (
          <Moment format="YYYY-MM-DD HH:mm:ss">
          {params.row.lastMeasurement}
          </Moment>
        )
      },
      valueGetter: (params) => {
        return params.row.lastMeasurement !== null ? params.row.lastMeasurement : 'N/A'
      },
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 200,
      editable: true,   
      sortable: false,
    },
    {
      field: 'measurements',
      title: 'Measurememts',
      headerName: 'Measurememts',
      minWidth: 180,
      editable: false,
      valueGetter: (params) => {
        return params.row.measurements_aggregate !== null ? params.row.measurements_aggregate.aggregate.count : 'N/A'
      },
      renderCell: (params) => {
        if(params.row.measurements_aggregate == null){
          return 'N/A'
        }
      
        return params.row.measurements_aggregate.aggregate.count
      },      
    },    
    {
      field: 'asset',
      title: 'Asset',
      headerName: 'Asset',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.asset){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={()=>window.open(`https://zeus.primac.app/asset/${params.row.id}`, "newwindow")}
                >
                  {params.row.asset.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      },
      valueGetter: (params) => {
        return params.row.asset ? params.row.asset.name : 'N/A'
      },
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 400,
      editable: true,
      sortable: false,
    },    
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];

  //////////////////
  console.log('ZSensorsList->props')
  console.log(props)

  if(props.recolumns){
    if(props.recolumns.hasOwnProperty('index')){
      var index = props.recolumns.index;
      columns[index] = props.recolumns;
      console.log('column replaced')
    }    
  }

  if(props.columns){
    if(Array.isArray(props.columns)){
      if(props.columns[0].hasOwnProperty('index')){
        for(let i=0; i < props.columns.length; i++){
          let index = props.columns[i].index;
          if(index === -1){
            // we are adding this column to the end
            let col = props.columns[i];
            columns = columns.concat([col])
            continue;
          }          
          let col = props.columns[i];
          columns.splice(index, 0, col);
        }
      } else {
        columns = columns.concat(props.columns);
        console.log('list of columns added');
      }
    } else {
      if(props.columns.hasOwnProperty('index')){
        let index = props.columns.index;
        columns.splice(index, 0, props.columns);
        console.log('single column added')
      }
    }
  }


  if(props.data){
    console.log('ZSensorsList->data')
    rows = props.data
  }

  if(props.items){
    console.log('ZSensorsList->items')
    rows = props.items
  }
  
  const [updateSensorResult, updateFunction] = useMutation(updateSensor);
  const [updateAliasResult, updateAliasFunction] = useMutation(zeusSensorUpdateAlias);
  const [addNotificationResult, insertNotificationFunction]   = useMutation(addNotification);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      if(field === "alias"){
        console.log('ZSensorsList::handleCellEditCommit::alias');
        console.log(id, field, value)

        let user_id = userData.id;

        var sensor = rows.find((sn)=>{
          return sn.id === id;
        })

        let variables = {
          serialNumber: sensor.name, 
          userId: user_id, 
          userName: userData.alias, 
          alias: value
        }
    
        console.log(variables);
        
        updateAliasFunction(variables).then((result)=>{
          console.log(result);
          if(result.error){
            console.log(`ERROR updating sensor alias: ${result.error}`)
            setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
          } else {
            console.log(result)
            
            setAlertState({...alertState, open: true, message: 'Successfully updated sensor alias', severity: 'success'})
          }    
        })
    
      } else {

        console.log('ZSensorsList::handleCellEditCommit');
        console.log(id, field, value)

        let user_id = userData.id;

        var _set = {}
        _set[field] = value

        const modifiedOn = moment().format();
        _set.modifiedOn = modifiedOn;

        let variables = {
          id: id,
          _set: _set,
          activity: {
            user_id: user_id,
            parent_id: id,
            name: `sensor ${field} updated`,
            description: `${userData.alias} updated sensor ${field} to ${value}`,          
          },
        }

        console.log('variables')
        console.log(variables)

        updateFunction( variables ).then((result)=>{
          if(result.error){
            console.log(result.error)
            setAlertState({...alertState, message: `ERROR: ${result.error}`, severity: 'error', open: true})

          } else {
            console.log("successfully updated sensor")

            // send a notification if status was updated
            if(field === 'status'){

              var sensor = rows.find((sn)=>{
                return sn.id === id;
              })

              insertNotificationFunction({object: {
                name: `Sensor ${sensor.name} - [${sensor.alias}] status was updated`,
                description: `${userData.alias} updated sensor ${sensor.name} - [${sensor.alias}] updating status to ${value}`,
                role: 'manager',
              }});

            }

            setAlertState({...alertState, message: "successfully updated sensor", severity: 'info', open: true})
          }
        })
      }
    },
  []);


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Note</MenuItem>
      </Menu>
    </div>  
  )


  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="sensor actions"
      data-for="sensor_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>
  )

  ////////////////////
  
  return (
    <div>
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="jobs" className={classes.routes_avatar}>
              <NetworkCheckIcon />
            </Avatar>
          }      
          action={props.action ? props.action : defaultAction}         
          title={props.title ? props.title : 'Sensors'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper id='sensor-data-grid' className={classes.sensor_row}>
            <DataGrid autoHeight
              rows={rows}
              columns={columns}
              pageSize={props.pageSize ?? 20}
              rowsPerPageOptions={[5]}
              density="compact"
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  active: params.row.status === 'active',
                  non_responsive: params.row.status === 'non responsive',
                  recovered: params.row.status === 'recovered',
                  lost: params.row.status === 'lost',
                  anomaly: params.row.status === 'anomaly detected',
                  low_battery: params.row.status === 'low battery',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={props.editable ? handleCellEditCommit : ()=>{console.log("ZSensorList is not editable")}}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="sensor_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZSensorsList;
