


import moment from 'moment';


export default class PurchaseOrderBloC {

  constructor(entry){
    this._entry = entry;
  }

  getStatusMessage = () => {
    let po = this._entry;
    let message = `Purchase Order ${po.name} is ${po.status}.`;
    if(moment().isSameOrAfter(po.closedOn, 'month')){
      message += ` Expiring soon on ${po.closedOn.split('T')[0]}.`;
    }

    let poRemaining = 0.00
    let acculatedInv = 0.00
    let pctUsage = 0;
    po.invoices.forEach((inv)=>{
      acculatedInv += inv.amount;
    })

    poRemaining = po.amount - acculatedInv;
    pctUsage = (acculatedInv / po.amount) * 100.0;
    pctUsage = pctUsage.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    poRemaining = poRemaining.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    message += ` With ${pctUsage}% of the total PO billed. $${poRemaining} remaining.`;
    console.log(message) 
    return message;   
  }

  isActive = () => {
    let rval = false;  

    if(moment().isSameOrBefore(this._entry.closedOn, 'day')){
      rval = true;
    }   

    if(this._entry.status === 'pending' || this._entry.status === 'completed'){
      rval = false;
    }     

    return rval;
  }

  isCompleted = () => {
    let rval = false;

    if(this._entry.status === 'completed'){
      rval = true;
    } 

    if(moment().isSameOrAfter(this._entry.closedOn, 'day')){
      rval = true;
    }   

    return rval;
  }


  accumulatedInvoiced = () => {
    let rval = 0.0;

    if(this._entry.invoices.length > 0){
      this._entry.invoices.forEach((inv)=>{
        rval += inv.amount;
      })
    }

    return rval;
  }

  isInvoiced = () => {
    return this._entry.invoices.length > 0;
  }


  
} ////////

