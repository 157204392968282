import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getJobBoardEntry } from 'queries_urql'; //subscribeToolBox
import { addAttachment, updateAssetSurveyField } from 'mutations_urql';
import { subscribeAssetSurvey } from 'subscriptions_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, blue, green, cyan, grey, yellow, orange } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import BuildIcon from '@material-ui/icons/Build';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactTooltip from 'react-tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Moment from 'react-moment';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QRCode from "react-qr-code";

import ZMapComponent from 'components/core/ZMapComponent';
import ZSetJobScheduledButton from 'components/buttons/ZSetJobScheduledButton';
import ZSetJobClosedButton from 'components/buttons/ZSetJobClosedButton'; 
import ZSetJobClientPOReceivedButton from 'components/buttons/ZSetJobClientPOReceivedButton'; 
import ZSetJobInvoicedButton from 'components/buttons/ZSetJobInvoicedButton'; 
import ZAssignTechToJobButton from 'components/buttons/ZAssignTechToJobButton';//ZAssignTechToJobButton
import ZSetJobPrimacPOButton from 'components/buttons/ZSetJobPrimacPOButton';  // SetJobPrimacPOButton
import ZSetTravelAndAccomToJobButton from 'components/buttons/ZSetTravelAndAccomToJobButton';  //addSetTravelAndAccomodationsArrangedForJobButton
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';// ZFileCloudUploadMenuItem
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';// ZActivitiesTimeline
import ZDescriptorAutocomplete from 'components/core/ZDescriptorAutocomplete';// ZDescriptorAutocomplete
import ZConditionAutocomplete from 'components/core/ZConditionAutocomplete';//ZConditionAutocomplete
import ZPublishAssetSurveyButton from 'components/buttons/ZPublishAssetSurveyButton';//ZPublishAssetSurveyButton

import JobBoardBloC from 'blocs/JobBoardBloC';

import { uploadFileToAzure } from 'azure/AzureBlobAPI';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.noaccess': {
      backgroundColor: grey[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.nostatus': {
      backgroundColor: grey[200], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unacceptable': {
      backgroundColor: red[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unsatisfactory': {
      backgroundColor: orange[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.reportable': {
      backgroundColor: yellow[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },        
    '& .super-app.acceptable': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.good': {
      backgroundColor: green[200], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: blue[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  editField: {
    position: "absolute",
    top: 0,
    right: 0
  },  
  paper: {
    padding: '6px 16px',
  },  
  redText: {
    color: red,
  },
  greenText: {
    color: green,
  },
});

/////////////////////////////////////

const ZAssetSurveyBox = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [userData, setUserData] = useLocalStorage('userData');
  const [isEditable, setIsEditable] = React.useState({
    severity: false,
    condition_value: false,
    summary: false,
    surveyDate: false,
    recommendations: false,
    field: null,
    value: null,
  })
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [updateResult, updateFunction] = useMutation(updateAssetSurveyField);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDateChange = (value) => {
    console.log('handleDateChange')
    console.log(value)
    setIsEditable({...isEditable, field: 'completedOn', value: value})
  }

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleDescriptorSummaryCommit = React.useCallback((field,value) => {
    console.log('ZAssetSurveyBox->handleDescriptorSummaryCommit')
    let entry_id = props.entry.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {
      descriptor_id: value.id,
      summary: value.name,
    };
    //_set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: entry_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: entry_id,
        name: `asset survey summary updated`,
        description: `${userData.alias} updated asset survey summary from ${props.entry.summary} to ${value.name}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating job: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`asset survey update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated Asset Survey', severity: 'success'})
      }

      setIsEditable({...isEditable, summary: false, field: null, value: null})
    })

  },[]);


  const handleConditionCommit = React.useCallback((field,value) => {
    console.log('ZAssetSurveyBox->handleConditionCommit')
    let entry_id = props.entry.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {
      condition_id: value.id,
      condition_value: value.name,
    };
    //_set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: entry_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: entry_id,
        name: `asset survey condition updated`,
        description: `${userData.alias} updated asset survey summary from ${props.entry.condition_value} to ${value.name}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating asset survey: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`asset survey update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated Asset Survey', severity: 'success'})
      }

      setIsEditable({...isEditable, condition: false, field: null, value: null})
    })

  },[]);  
  

  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZAssetSurveyBox->handleEditCommit')
    let job_id = props.entry.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: job_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: job_id,
        name: `job ${field} updated`,
        description: `${userData.alias} updated job ${field} from ${props.entry[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating job: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`job update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated Job', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  /////////

  console.log(`ZAssetSurveyBox->props`)
  console.log(props)

  let entry = {}
  let hasValidCustomerPO = false;
  let isInvoiced = false;

  let subscriptionPause = props.static ? true : false;
  subscriptionPause = props.realtime ? false : true;
  const id = props.entry.id;

  //console.log(`ZAssetSurveyBox->queryPause: ${queryPause}`)
  console.log(`ZAssetSurveyBox->subscriptionPause: ${subscriptionPause}`)

  const handleSubscription = (previousData, response) => {
    console.log('ZAssetSurveyBox::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //entry = data.zeus_jobboard_entry_by_pk
    return response;
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZAssetSurveyBox setting up subscription')

  const [result, executeSubscription] = useSubscription({ 
    query: subscribeAssetSurvey,
    variables: { id },
    pause: subscriptionPause,
  }, handleSubscription); 


  /*
  const [result, reexecuteQuery] = useQuery({
    query: getJobBoardEntry,
    variables: { id },
    pause: queryPause,
  });
  */

  console.log('ZAssetSurveyBox result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  ///////////////////////////

  if(props.static){
    entry = props.entry
  } else {
    entry = data.zeus_asset_survey_by_pk
  }

  //const bloc = new JobBoardBloC(entry);

  /////////

  let smallChips = (<div></div>)
  let activities = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)

  ///////////////////////////////////

  if(props.fullBox !== undefined){

    activities = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="entry_box_tooltip" 
            aria-label="add note">
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="entry_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="h6" component="h4">Timeline</Typography>
              { entry.activities ?
                <Paper elevation={0} variant="outlined" square>
                  <ZActivitiesTimeline items={entry.activities} size={3} />
                </Paper>   
                : <div/>
              }              
          </CardContent>
        </Collapse>
      </div>
    )
  }

  let title = (
    <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/asset/:id',{
            id: entry.asset.id
          })
          history.push(path)
        }}          
      >
        Asset Survey
      </Button> 
      <Typography variant='h3'>{entry.asset.name}</Typography>
      {
        isEditable.severity ?
          <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
            <FormControl className={classes.formControl}>
            <InputLabel id="freq-select-label">Severity</InputLabel>
              <Select
                labelId="freq-select-label"
                id="freq-simple-select"
                value={isEditable.value ? isEditable.value : entry.severity}
                onChange={(event)=>setIsEditable({...isEditable, field: 'severity', value: event.target.value})}
              >
                <MenuItem value={'Good'}>Good</MenuItem>
                <MenuItem value={'Acceptable'}>Acceptable</MenuItem>
                <MenuItem value={'Reportable'}>Reportable</MenuItem> 
                <MenuItem value={'Unsatisfactory'}>Unsatisfactory</MenuItem> 
                <MenuItem value={'Unacceptable'}>Unacceptable</MenuItem> 
                <MenuItem value={'No Status'}>No Status</MenuItem> 
                <MenuItem value={'No Access'}>No Access</MenuItem> 
              </Select>
            </FormControl>
            <IconButton
              variant="contained"
              color="primary"
              onClick={()=>{
                console.log('edit commit')
                handleEditCommit(isEditable.field, isEditable.value)
                //setIsEditable({...isEditable, description: false, field: null, value: null})
              }}                    
            >
              <CloudUploadIcon />
            </IconButton>
          </Paper> 
        :
          <div
            onClick={()=>setIsEditable({...isEditable, severity: true, field: 'severity'})}
          >
            <Typography variant='h4'
              className={
                clsx('super-app', {
                good: entry.severity === 'Good',
                acceptable: entry.severity === 'Acceptable',
                unacceptable: entry.severity === 'Unacceptable',
                unsatisfactory: entry.severity === 'Unsatisfactory',
                reportable: entry.severity === 'Reportable',
                nostatus: entry.severity === 'No Status',
                noaccess: entry.severity === 'No Access',
              })} 
            >
              {entry.severity ? entry.severity : 'N/A'}
            </Typography>
          </div>
      } 
    </div>      
  )

  /*
  status = "pending"
  status = "active"
  */

  // ready is the default
  let urlValue = `${baseURL}/survey/${entry.id}`;

  if(props.noqrcode === undefined){
    qrcode = (
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
        <QRCode
        size={512}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={urlValue}
        viewBox={`0 0 512 512`}
        />
      </div>
    )
  }

  /////////////////////////

  let severity = (<div>Severity: N/A</div>)
  let surveyDate  = (<div>Survey Date: never</div>)
  let createdBy = (<div>Created By: N/A</div>)
  let asset = (<div>Asset: N/A</div>)
  let client = (<div>Client: N/A</div>)
  let plant = (<div>Plant: N/A</div>)

  if(entry.technician){
    createdBy = (<div>{`Surveyed By: ${entry.technician.alias}`}</div>)
  } 

  if(entry.asset.client){
    client = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client/:id', {
            id: entry.asset.client.id}
          )
          history.push(path)
        }}          
      >
        Client: {entry.asset.client.name}
      </Button> 
    )   

  }

  if(entry.asset.client_node){
    plant = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client_node/:id', {
            id: entry.asset.client_node.id}
          )
          history.push(path)
        }}          
      >
        Plant: {entry.asset.client_node.name}
      </Button> 
    )   
  }

  let publishButton = (
    <div>
      <ZPublishAssetSurveyButton id={id} entry={entry} asset={entry.asset} />
    </div>
  )

  ///////////////////////

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>    
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="survey_entry" className={classes.avatar}>
              <DepartureBoardIcon />
            </Avatar>
          }
          action={
            <div>
              <Chip className={clsx('super-app', {
                  good: entry.severity === 'Good',
                  acceptable: entry.severity === 'Acceptable',
                  reportable: entry.severity === 'Reportable',
                  unsatisfactory: entry.severity === 'Unsatisfactory',
                  unacceptable: entry.severity === 'Unacceptable',
                  nostatus: entry.severity === 'No Status',
                  noaccess: entry.severity === 'No Access',
                })}
                style={{ marginLeft: 42, marginBottom: 16 }}
                icon={<TrackChangesIcon />}
                label={ entry.severity.toUpperCase() }
                clickable
                onClick={props.statusUpdate ? props.statusUpdate : null}
                data-for="survey_box_tooltip"
                data-tip="publish survey status"
              />  
              <br/>
              { qrcode } 
              <br/>
              { publishButton }
            </div>             
          }
          title={title}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>  
              {isEditable.surveyDate ?
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={()=>{
                          console.log('edit commit')
                          handleEditCommit(isEditable.field, isEditable.value)
                        }}                    
                      >
                        <CloudUploadIcon />
                      </IconButton>                          
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="closedOn"
                        value={entry.theDate}
                        label="Survey Date"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />                 
                    </Grid>
                  </MuiPickersUtilsProvider> 
                </div> 
                :
                <Typography color='primary' variant='h5'>
                  <div
                    onClick={()=>setIsEditable({...isEditable, surveyDate: true, field: 'theDate'})}
                  >
                    Survey Date:&nbsp;
                    <Moment format="YYYY-MM-DD">
                      {entry.theDate}
                    </Moment>
                  </div>
                </Typography>
              }            
              {client}
              <br/>
              {plant}
              {createdBy}
              {/* severity */}
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                  {entry.createdOn}
                </Moment>
              </div>              
            </div>
          }
        />
        <CardContent>     
           <br/><Divider/><br/>
          {gmap}         
          { smallChips }
          <Typography variant='h5'>Condition</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, condition: true, field: 'condition'})}
            square
          >
            {
              isEditable.condition ?
              <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <ZConditionAutocomplete open={props.open} default={entry.condition} onChange={handleConditionCommit} />
              </Paper>  
              :
              <Typography>{entry.condition_value}</Typography>
            }        
          </Paper>  
          <br/><Divider/><br/>          
          <Typography variant='h5'>Summary</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, summary: true, field: 'summary'})}
            square
          >
            {
              isEditable.summary ?
              <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <ZDescriptorAutocomplete open={props.open} default={entry.descriptor} condition={entry.condition} onChange={handleDescriptorSummaryCommit} />
              </Paper>  
              :
              <Typography>{entry.summary}</Typography>
            }        
          </Paper>  
          <br/><Divider/><br/>
          <Typography variant='h5'>Recommendations</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, recommendations: true, field: 'recommendations'})}
            square
          >
            {
              isEditable.recommendations ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Summary"
                multiline
                defaultValue={entry.recommendations}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'recommendations', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{entry.recommendations}</Typography>
            }        
          </Paper>                                        
        </CardContent>
        { activities }
      </Card>
      <ReactTooltip id="survey_box_tooltip"/>
    </div>
  );
};


export default ZAssetSurveyBox;

/*

  <br/><Divider/><br/>
  <ZFileCloudUploadMenuItem parent_id={entry.id} path={`survey/${entry.id}/attachments`} />

  **********

  <TextField
    className={classes.descriptionField}
    fullWidth
    id="details"
    label="Summary"
    multiline
    defaultValue={entry.summary}
    rows={4}
    onChange={(event) =>{
      console.log(event.target.value)
      setIsEditable({...isEditable, field: 'summary', value: event.target.value});
      //console.log("state->"+ JSON.stringify(state));
    }} 
    variant="filled"
    InputProps={{
      endAdornment: (
        <IconButton
          variant="contained"
          color="primary"
          onClick={()=>{
            console.log('edit commit')
            handleEditCommit(isEditable.field, isEditable.value)
            //setIsEditable({...isEditable, description: false, field: null, value: null})
          }}                    
        >
          <CloudUploadIcon />
        </IconButton>
      )
    }}              
  /> 

*/
