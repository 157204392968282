import React, { useCallback, useEffect, useState } from 'react';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import ZNavigationBar from 'components/ZNavigationBar';

const theme = createTheme();

const App = (props) => {

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ZNavigationBar />
    </MuiThemeProvider>
  );
}

export default App;
