import gql from 'graphql-tag';


export const updateSalesDocument = gql`
mutation updateSalesDocument($id: uuid = "", $_set: zeus_sales_document_set_input = {}) {
  update_zeus_sales_document_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
    document_type
    createdOn
    amount
    status
    jobBoardEntry {
      id
      name
      description
      status
    }
  }
}
`;


export const addAttachment = gql`
mutation addAttachment($object: zeus_attachment_insert_input = {}) {
  insert_zeus_attachment_one(object: $object) {
    id
    name
    path
    description
  }
}
`;


export const addClient = gql`
mutation addClient($address: jsonb = "", $name: String = "", $description: String = "") {
  insert_zeus_client(objects: {address: $address, name: $name, description: $description}) {
    returning {
      id
      name
      description
      createdOn
      status
    }
  }
}
`;


export const addSupplier = gql`
mutation addSupplier($name: String = "", $description: String = "") {
  insert_zeus_supplier(objects: {name: $name, description: $description}) {
    returning {
      id
      name
      description
      createdOn
      status
    }
  }
}
`;


export const addAnnotation = gql`
mutation addAnnotation($name: String = "", $description: String = "") {
  insert_zeus_annotation(objects: {name: $name, description: $description}) {
    returning {
      id
      name
      description
      createdOn
    }
  }
}
`;

export const addProductLine = gql`
mutation addProductLine($name: String = "", $description: String = "") {
  insert_zeus_product_line(objects: {name: $name, description: $description}) {
    returning {
      id
      name
      description
      createdOn
    }
  }
}
`;


export const addRoute = gql`
mutation addRoute($name: String = "", $description: String = "") {
  insert_zeus_route(objects: {name: $name, description: $description}) {
    returning {
      id
      name
      description
      createdOn
      status
    }
  }
}
`;


export const updateClient = gql`
mutation updateClient($id: uuid = "", $_set: zeus_client_set_input = {}) {
    update_zeus_client_by_pk(pk_columns: {id: $id}, _set: $_set) {
      id
      name
      description
      createdOn
      status
    }
}
`;


export const updateSupplier = gql`
mutation updateSupplier($id: uuid = "", $_set: zeus_supplier_set_input = {}) {
    update_zeus_supplier_by_pk(pk_columns: {id: $id}, _set: $_set) {
      id
      name
      description
      createdOn
      status
    }
}
`;


export const updateQuotation = gql`
mutation updateQuotation($id: uuid = "", $_set: zeus_sales_document_set_input = {}) {
  update_zeus_sales_document_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
    createdOn
    status
  }
}
`;


export const updateContact = gql`
mutation updateContact($id: uuid = "", $_set: zeus_contact_set_input = {}) {
  update_zeus_contact_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    firstName
    lastName
    email
    description
    createdOn
    status
  }
}
`;


export const updateAsset = gql`
mutation updateAsset($id: uuid = "", $_set: zeus_asset_set_input = {}) {
  update_zeus_asset_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
    createdOn
    status
  }
}
`;


export const updateRoute = gql`
mutation updateRoute($id: uuid = "", $_set: zeus_route_set_input = {}) {
  update_zeus_route_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
    createdOn
    status
  }
}
`;


export const updateJobBoardEntry = gql`
mutation updateJobBoardEntry($id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
    serviceJob
    customerPO
    createdOn
    salesDocuments {
      id
      name
      amount
      createdOn
      document_type
    }
  }
}
`;


export const addQuotation = gql`
mutation addQuotation($address: jsonb = "", $name: String = "", $description: String = "") {
  insert_zeus_sales_document(objects: {address: $address, name: $name, description: $description, sales_document_type: "Quotation"}) {
    returning {
      id
      name
      description
      createdOn
      status
    }
  }
}
`;


export const addJobBoardEntry = gql`
mutation addQuotation($name: String = "", $description: String = "") {
  insert_zeus_jobboard_entry(objects: {name: $name, description: $description}) {
    returning {
      id
      name
      description
      createdOn
      status
    }
  }
}
`;


export const addPurchaseOrder = gql`
mutation addPurchaseOrder($object: zeus_sales_document_insert_input = {}) {
  insert_zeus_sales_document_one(object: $object) {
    id
    name
    description
    document_type
    createdOn
    status
    client {
      id
      name
      description
    }
    jobBoardEntry {
      id
      name
      description
      createdOn
      customerPO
    }
  }
}
`;


export const updateActivity = gql`
mutation updateActivity($id: uuid = "", $_set: zeus_activity_set_input = {}) {
  update_zeus_activity(where: {id: {_eq: $id}}, _set: $_set) {
    returning {
      id
      name
      description
      status
      createdOn
      closedOn
    }
  }
}
`;


export const updateProductLine = gql`
mutation updateProductLine($id: uuid = "", $_set: zeus_product_line_set_input = {}) {
  update_zeus_product_line(where: {id: {_eq: $id}}, _set: $_set) {
    returning {
      id
      name
      description
      status
      createdOn
    }
  }
}
`;


export const updateClientNode = gql`
mutation updateClientNode($id: uuid = "", $_set: zeus_client_node_set_input = {}) {
  update_zeus_client_node(where: {id: {_eq: $id}}, _set: $_set) {
    returning {
      id
      name
      description
      createdOn
      modifiedOn
      status
    }
  }
}
`;


export const addClientNode = gql`
mutation addClientNode($object: zeus_client_node_insert_input = {}) {
  insert_zeus_client_node_one(object: $object) {
    id
    name
    description
    createdOn
    status
    parent_id
    client_id
  }
}
`;