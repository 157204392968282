import React from 'react';

import { MemoryRouter as Router, generatePath } from 'react-router';

import { red, green } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllJobBoardEntries } from '../../queries';
import { updateClient, updateJobBoardEntry } from '../../mutations'
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllJobBoardEntries } from 'queries_urql';
import { setToolNodeRetired, updateClient, updateToolKit  } from 'mutations_urql';
import { subscribeAllJobBoardEntries } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Link as RouterLink, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //ZJobBoardDetailPane
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';


import ZJobBoardDetailPane from 'components/core/ZJobBoardDetailPane';
import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';

import ZAddToolKitDialog from 'components/dialogs/ZAddToolKitDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';

//////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////////////////////////////////

const ZToolKitPanel = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [showDialog, setShowDialog] = React.useState(false);
  const [showBookingDialog, setShowBookingDialog] = React.useState({
    open: false,
    activeEntry: null,
    node_id: null,
  });
  const [showDetailPane, setShowDetailPane] = React.useState(false);
  const [activeEntry, setActiveEntry] = React.useState({});
  const [toolNodeId, setToolNodeId] = React.useState(null)

  let rows = []

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/toolnode/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let node_id = params.row.id;
                  setToolNodeId(node_id)
                  setActiveEntry(params.row)
                  setShowBookingDialog({...showBookingDialog, activeEntry: params.row, node_id: node_id, open: true})
                }}          
              >
                <CalendarTodayIcon />
              </IconButton>
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 150,
      editable: 'never',
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 400,
      editable: true,
      sortable: false,
    },
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    },
    {
      field: 'lastCheckOut',
      title: 'Last CheckOut',
      headerName: 'Last CheckOut',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.lastCheckOut}
        </Moment>
      ),
    },
    {
      field: 'lastCheckIn',
      title: 'Last CheckIn',
      headerName: 'Last CheckIn',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.lastCheckIn}
        </Moment>
      ),
    },    
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      minWidth: 320,
      editable: 'never',
      valueGetter: (params) => {
        //console.log({ params });
        return params.row.status;
      }
    },   
    {
      field: 'tool_locker',
      title: 'Tool Locker',
      headerName: 'Tool Locker',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.parent){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let path = generatePath('/toolnode/:id', {
                      id: params.row.parent.id}
                      )
                    history.push(path)
                  }}     
                >
                  {params.row.parent.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      }
    }, 
  ];

  //////

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZToolKitPanel::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleClickOpen = () => {
    console.log("ZToolKitPanel::handleClickOpen->open dialog wizard")
    setShowDialog(true);
    console.log("ZToolKitPanel::handleClickOpen == "+ showDialog);
  };
  
  const handleClose = () => {
    setShowDialog(false);
  };

  const handleClickBookingOpen = () => {
    console.log("ZToolKitPanel::handleClickBookingOpen->open booking dialog wizard")
    setShowBookingDialog(true);
    console.log("ZToolKitPanel::handleClickBookingOpen == "+ showBookingDialog);
  };
  
  const handleBookingClose = () => {
    setShowBookingDialog({...showBookingDialog, open: false});
  };

  const handleCloseDetailPane = () => {
    setShowDetailPane(false);
  };

  /*
  const { loading, error, data  } = useQuery(getAllJobBoardEntries, {
    pollInterval: 5000,
  });
  const [updateFunction] = useMutation(updateJobBoardEntry);
  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  /*
  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: getAllJobBoardEntries,
  });

  const [updateSalesDocResult, updateFunction] = useMutation(updateJobBoardEntry);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  

  //use subscription
  
  const [ result, reexecuteQuery ] = useQuery({
    query: getAllJobBoardEntries,
  });
  

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };
 
  const [res] = useSubscription({ query: subscribeAllJobBoardEntries }, handleSubscription); 

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [updateToolKitResult, updateFunction] = useMutation(updateToolKit);

  if(props.data !== undefined && props.data !== null){
    rows = props.data
  }

  let title = 'Book Toolkit';

  if(showBookingDialog.activeEntry !== null){
    title = `Book Toolkit: ${showBookingDialog.activeEntry.name}`;
  }
  
  ////////////////////////////

  return (
    <div className={classes.root}>
    {/* <ZJobBoardDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} entry={activeEntry}/> 
    <ZAddToolKitDialog open={showDialog} handleClose={handleClose} lat={props.lat} lng={props.lng} parents={props.parents} static/>
    <ZBookToolDialog open={showBookingDialog.open} handleClose={handleBookingClose} lat={props.lat} lng={props.lng} data={showBookingDialog.activeEntry} static toolnode/> */}
    <ZBookToolDialog open={showBookingDialog.open} title={title} handleClose={handleBookingClose} lat={props.lat} lng={props.lng} data={showBookingDialog.activeEntry} static toolnode/>
    <Card variant="outlined" elevation={2}>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
        {props.title}
        </Typography>
        <IconButton
          onClick={handleClickOpen}
          style={{color:"white"}}
          color="inherit"
          aria-label="add row"
          edge="start"
          data-tip="create new tool kit"
          data-for="toolkit_panel_tooltip" 
        >
          <AddCircleOutlineIcon/>
        </IconButton>
      </Box>
      <DataGrid autoHeight
        title={props.title}
        columns={columns}
        rows={rows}
        pageSize={20}
        onCellEditCommit={handleCellEditCommit}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick={false}
        options={{
          toolbar: true,
          filtering: true,
          search: true,
          grouping: true,
          exportButton: true,
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        filterModel={props.filter}
        onRowDoubleClick={(params, evt) => {
          let entry = params.row;
          console.log("selected tool kit")
          console.log(entry)
          setActiveEntry(entry)
          setShowDetailPane(true)
        }}
      />
      </CardContent>
    </Card>
    <ReactTooltip id="toolkit_panel_tooltip"/>
    </div>
  );  
}
  
export default ZToolKitPanel
;

