import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getTicket } from 'queries_urql'; //subscribeToolBox
import { addAttachment, updateTicketField } from 'mutations_urql';
import { subscribeTicket } from 'subscriptions_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, blue, green, cyan } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import BuildIcon from '@material-ui/icons/Build';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FlashOnIcon from '@material-ui/icons/FlashOn';


import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactTooltip from 'react-tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Moment from 'react-moment';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QRCode from "react-qr-code";

import ZMapComponent from 'components/core/ZMapComponent';
import ZBreadCrumbs from 'components/core/ZBreadCrumbs';
import ZSetJobScheduledButton from 'components/buttons/ZSetJobScheduledButton';
import ZSetTicketClosedButton from 'components/buttons/ZSetTicketClosedButton'; 
import ZSetJobClientPOReceivedButton from 'components/buttons/ZSetJobClientPOReceivedButton'; 
import ZSetJobInvoicedButton from 'components/buttons/ZSetJobInvoicedButton'; 
import ZAssignTechToTicketButton from 'components/buttons/ZAssignTechToTicketButton';//ZAssignTechToJobButton
import ZSetJobPrimacPOButton from 'components/buttons/ZSetJobPrimacPOButton';  // SetJobPrimacPOButton
import ZSetTravelAndAccomToJobButton from 'components/buttons/ZSetTravelAndAccomToJobButton';  //addSetTravelAndAccomodationsArrangedForJobButton
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';// ZFileCloudUploadMenuItem
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';// ZActivitiesTimeline
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

import TicketBloC from 'blocs/TicketBloC';

import { uploadFileToAzure } from 'azure/AzureBlobAPI';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  editField: {
    position: "absolute",
    top: 0,
    right: 0
  },  
  paper: {
    padding: '6px 16px',
  },  
});

/////////////////////////////////////

const ZTicketBox = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [userData, setUserData] = useLocalStorage('userData');
  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    closedOn: false,
    resolution: false,
    field: null,
    value: null,
  })
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [updateResult, updateFunction] = useMutation(updateTicketField);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDateChange = (value) => {
    console.log('handleDateChange')
    console.log(value)
    setIsEditable({...isEditable, field: 'closedOn', value: value})
  }

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZTicketBox->handleEditCommit')
    let ticket_id = props.entry.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: ticket_id,
      _set: _set,
      activities: [{
        user_id: user_id,
        parent_id: ticket_id,
        name: `ticket ${field} updated`,
        description: `${userData.alias} updated ticket ${field} from ${props.entry[field]} to ${value}`,          
      }],
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating ticket: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`ticket update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated Ticket', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  /////////

  let entry = {}
  let hasValidCustomerPO = false;
  let isInvoiced = false;

  const queryPause = props.static ? true : false;
  const subscriptionPause = props.realtime ? false : true;
  const id = props.entry.id;

  console.log(`ZTicketBox->queryPause: ${queryPause}`)
  console.log(`ZTicketBox->subscriptionPause: ${subscriptionPause}`)

  const handleSubscription = (previousData, response) => {
    console.log('ZTicketBox::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    entry = data.zeus_ticket_by_pk
    return data
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZTicketBox setting up subscription')

  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeTicket,
    variables: { id },
    pause: subscriptionPause,
  }, handleSubscription); 


  const [result, reexecuteQuery] = useQuery({
    query: getTicket,
    variables: { id },
    pause: queryPause,
  });

  console.log('ZTicketBox result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static === undefined){
    entry = data.zeus_ticket_by_pk
  } else {
    entry = props.entry
  }

  console.log('ZTicketBox->entry');
  console.log(entry);

  const bloc = new TicketBloC(entry);

  /////////

  let smallChips = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)

  let addSetTicketClosedButton = (<div></div>)
  let addTechToTicketButton = (<div></div>)

  //////////////////////////////////

  if(props.noactions === undefined || props.noActions === undefined){
    addSetTicketClosedButton = (
      <div>
        <ZSetTicketClosedButton id={id} ticket={entry} done={entry.closedOn ? true : false} disabled={bloc.isCompleted()}/>      
      </div>
    )

    addTechToTicketButton = (
      <div>
        <ZAssignTechToTicketButton id={id} ticket={entry} done={entry.closer ? true : false} disabled={bloc.isCompleted()}/>
      </div>
    )
  
  }

  ///////////////////////////////////

  let title = (<div>{
    isEditable.name ?
      <div>
        <Typography  variant='overline' color='primary'>Ticket</Typography>     
        <TextField
          className={classes.descriptionField}
          fullWidth
          id="name"
          label="Name"
          defaultValue={entry.name}
          onChange={(event) =>{
            console.log(event.target.value)
            setIsEditable({...isEditable, field: 'name', value: event.target.value});
            //console.log("state->"+ JSON.stringify(state));
          }} 
          variant="filled"
          InputProps={{
            endAdornment: (
              <IconButton
                variant="contained"
                color="primary"
                onClick={()=>{
                  console.log('edit commit')
                  handleEditCommit(isEditable.field, isEditable.value)
                  //setIsEditable({...isEditable, description: false, field: null, value: null})
                }}                    
              >
                <CloudUploadIcon />
              </IconButton>
            )
          }}              
        /> 
      </div>
      :
      <div>
        <Typography  variant='overline' color='primary'>Ticket</Typography>  
        <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
          <Typography color='primary' variant='h2'>{entry.name}</Typography>
        </div> 
      </div>                
    }</div>)

  /*
  status = "pending"
  status = "active"
  status = "completed"

  */

  // ready is the default
  let urlValue = `${baseURL}/ticket/${entry.id}`;

  if(props.noqrcode === undefined){
    qrcode = (
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
        <QRCode
        size={512}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={urlValue}
        viewBox={`0 0 512 512`}
        />
      </div>
    )
  }
  
  let assigned_user = (<div>Assigned Support Person: N/A</div>)
  let status_colour = "primary"

  if(entry.status === 'active'){
    status_colour = "secondary"
  }

  if(entry.closer){
    assigned_user = (
      <Typography>Assigned Support Person: {entry.closer ? entry.closer.alias : 'N/A'}</Typography>
    )
  }


  /////////////////////////

  let completedDate  = (<div>Completed Date: never</div>)
  let createdBy = (<div>Created By: N/A</div>)
  let client = (<div>Client: N/A</div>)
  let parent = (<div>Parent: N/A</div>)

  if(entry.user){
    createdBy = (<div>{`Created By: ${entry.user.alias}`}</div>)
  }

  if(entry.client){
    client = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client/:id', {
            id: entry.client.id}
          )
          history.push(path)
        }}          
      >
        Client: {entry.client.name}
      </Button> 
    )   
  }

  if(entry.tool !== null){
    parent = ( 
      <Button 
        //component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath(`/tool/:id`, {
            id: entry.tool.id}
          )
          history.push(path)
        }}          
      >
        {`Tool: ${entry.tool.name}`}
      </Button> 
    )   
  }

  if(entry.toolNode !== null){
    parent = ( 
      <Button 
        //component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath(`/toolnode/:id`, {
            id: entry.toolNode.id}
          )
          history.push(path)
        }}          
      >
        {`Tool Node: ${entry.toolNode.name}`}
      </Button> 
    )   
  }  

  let resolution = (<div></div>)
  
  if(entry.resolution){
    resolution = (
      <div onClick={()=>setIsEditable({...isEditable, resolution: true, field: 'resolution'})}>
        <Typography color='primary' variant='h6'>Resolution: {entry.resolution}</Typography>
      </div>     
    )
  }


  /*
  if(entry.contact !== null){
    contact = (<div>Contact: {`${entry.contact.firstName} ${entry.contact.lastName}`}</div>)
  }  
  */
  ////////////////////////////////////

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>    
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="ticket" className={classes.avatar}>
              <FlashOnIcon />
            </Avatar>
          }
          action={
            <div>
              <Chip
                style={{ marginLeft: 64, marginBottom: 16 }}
                icon={<TrackChangesIcon />}
                label={ entry.status.toUpperCase() }
                color={status_colour}
                clickable
                onClick={props.statusUpdate ? props.statusUpdate : null}
                data-for="ticket_box_tooltip"
                data-tip="analyze ticket status"
              />              
              <br/>
              <Paper>
                { qrcode }
                {addTechToTicketButton}
                {addSetTicketClosedButton}    
              </Paper>
            </div>
          }
          title={title}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {entry.createdOn}
                </Moment>
              </div>
              {parent}
              {createdBy}
              {assigned_user}
              {isEditable.closedOn ?
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={()=>{
                          console.log('edit commit')
                          handleEditCommit(isEditable.field, isEditable.value)
                        }}                    
                      >
                        <CloudUploadIcon />
                      </IconButton>                          
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="closedOn"
                        value={entry.closedOn}
                        label="Completed Date"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />                 
                    </Grid>
                  </MuiPickersUtilsProvider> 
                </div> 
                :
                <div
                  onClick={()=>setIsEditable({...isEditable, closedOn: true, field: 'closedOn'})}
                >
                  { entry.closedOn === undefined || entry.closedOn === null ?
                    <Typography color='primary'>Completed Date: N/A </Typography>
                  :
                    <Typography color='primary'>
                      Completed Date:&nbsp;
                      <Moment format="YYYY-MM-DD">
                        {entry.closedOn}
                      </Moment>
                    </Typography>
                  }
                </div>
              } 
              {isEditable.resolution ?
                <div>
                  <TextField
                    className={classes.descriptionField}
                    fullWidth
                    id="resolution"
                    label="Resolution"
                    defaultValue={entry.resolution}
                    onChange={(event) =>{
                      console.log(event.target.value)
                      setIsEditable({...isEditable, field: 'resolution', value: event.target.value});
                      //console.log("state->"+ JSON.stringify(state));
                    }} 
                    variant="filled"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          variant="contained"
                          color="primary"
                          onClick={()=>{
                            console.log('edit commit')
                            handleEditCommit(isEditable.field, isEditable.value)
                            //setIsEditable({...isEditable, description: false, field: null, value: null})
                          }}                    
                        >
                          <CloudUploadIcon />
                        </IconButton>
                      )
                    }}              
                  /> 
                </div>
                :
                resolution
              }              
            </div>
          }
        />
        <CardContent>
          <br/><Divider/><br/>
          {gmap}         
          { smallChips }
          <Typography variant='h5'>Reason</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >
            {
              isEditable.description ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Description"
                multiline
                defaultValue={entry.description}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'description', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{entry.description}</Typography>
            }        
          </Paper>
        </CardContent>
      </Card>
      <ReactTooltip id="ticket_box_tooltip"/>
    </div>
  );
};


export default ZTicketBox;

