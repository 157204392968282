import React from 'react';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getClient,  getQuotesForClient } from 'queries';
import { addClient, updateClient } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getClient,  getQuotesForClient } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';

import ZMSalesChart from 'components/charts/ZMSalesChart';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZSalesToDateBox from 'components/boxes/ZSalesToDateBox';// ZSalesToDate
import ZSalesLostBox from 'components/boxes/ZSalesLostBox';//ZSalesLostBox
import ZMSalesDeltaChart from 'components/charts/ZMSalesDeltaChart';//ZMSalesDeltaChart
import ZSalesStatsBox from 'components/boxes/ZSalesStatsBox';// ZSalesStatsBox
import ZSalesTimeStatsBox from 'components/boxes/ZSalesTimeStatsBox';// ZSalesTimeStatsBox
import ZPendingSalesStatsBox from 'components/boxes/ZPendingSalesStatsBox';// ZPendingSalesStatsBox
import ZSalesFunnelPanel from 'components/panels/ZSalesFunnelPanel';
import ZSalesSupplierPanel from 'components/panels/ZSalesSupplierPanel';
import ZSalesMonthlyPanel from 'components/panels/ZSalesMonthlyPanel';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';


import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const ZSalesDashboard = (props) => {
  //const [screen, setScreen] = useLocalStorage('screen_name');
  //setScreen('Sales Dashboard')
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const [state, setState] = React.useState({
    totalSales: 0
  });

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  const this_year = new Date().getFullYear();
  const last_year = this_year -1;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ZSalesMonthlyPanel key='sales_monthly'/>
          </Col>  
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZSalesSupplierPanel key='sales_supplier_product'/>
          </Col>          
        </Row>    
      </Container>
    </div>
  )
}

export default ZSalesDashboard;

/*

        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZSalesFunnelPanel key='sales_funnel'/>
          </Col>          
        </Row>   

*/


/*
          <Col xs={3}>
            <ZSalesToDateBox key='sales_total' year={this_year}/>
          </Col>
          <Col xs={3}>
            <ZPendingSalesStatsBox id='sales_lost' year={this_year} />
          </Col>
          <Col xs={3}>
            <ZSalesStatsBox id='sales_stats' year={this_year} />
          </Col>
          <Col xs={3}>
            <ZSalesTimeStatsBox id='sales_stats' year={this_year} />
          </Col>


        <Row><br/></Row>
        <Row>
          <Col xs={9}>
            <ZMSalesChart id='monthly_sales_chart' year={this_year} />
          </Col>
          <Col xs={3}>
            
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={6}>
            <ZMSalesDeltaChart id='sales_deltas' year={this_year} width={600} height={400} />
          </Col>
          <Col xs={6}>
            <ZMSalesDeltaChart id='sales_deltas_pct' year={this_year} width={600} height={400} percentages/>
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={9}>
            <ZMSalesChart id='prev_monthly_sales_chart' year={last_year} />          
          </Col>
          <Col xs={4}>

          </Col>
        </Row>
*/