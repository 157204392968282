import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import IconButton from 'material-ui/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';

//import { uuid } from 'uuidv4';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
}));

////////////////////////////////
/*
  props {
    id
    data
    lat
    lng
    job_id
    tool
    toolnode
    disabled
  }
*/
////////////////////////////////

const ZPublishAssetSurveyButton = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams();

  const [userData, setUserData] = useLocalStorage('userData');
  const [open, setOpen] = React.useState(false);
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });  

  let updateResult = null;
  let updateFunction = null;
  
  const [insertNotificationResult,   insertNotificationFunction] = useMutation(addNotification);

  ///////////////////

  const handleClose = () => {
    setOpen(false)
  }

  //////////////////

  let is_disabled = false;
  if(props.disabled !== undefined){
    is_disabled = true;
  }

  const handlePublishSurvey = () => {

    insertNotificationFunction({object: {
      name: `Asset ${props.asset.name} survey posted`,
      description: `${userData.alias} created a new survey for asset ${props.asset.name} condition ${props.entry.severity}`,
      role: 'admin',
    }}).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR publishing survey: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`published survey`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully published survey', severity: 'success'})
      }
    })
  }

  //////////////////////////

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse> 
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={6000}
              open={open}
              message="Are you sure you want to publish these survey results?"
              key="publish_button"
              onClose={()=>{
                setOpen(false)
              }}
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handlePublishSurvey}>
                    Yes
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setOpen(false)
                    }}
                  >
                    No
                  </Button>
                </div>
              }
            />        
            <Button size="large" color="primary" variant="outlined"
              id={props.id}
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                setOpen(true)
              }}   
              disabled={is_disabled}  
              data-for="publish_survey_box_tooltip"
              data-tip="publish survey status"                
            >
              Publish Survey
            </Button>
          </div>
        </Router>
      </strong>
      <ReactTooltip id="publish_survey_box_tooltip"/>
    </div>
  )
}

export default ZPublishAssetSurveyButton;
