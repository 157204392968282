import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getClient,  getQuotesForClient } from 'queries_urql';
import { addClient, updateTicketField } from 'mutations_urql';
import { subscribeTicket } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimelineIcon from '@material-ui/icons/Timeline';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';//PhotoLibraryIcon
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZTicketBox from 'components/boxes/ZTicketBox';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZInvoicesList from 'components/lists/ZInvoicesList';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZAssetsList from 'components/lists/ZAssetsList';//ZAssetsList
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZAttachmentsList from 'components/lists/ZAttachmentsList';
import ZAssetSurveyList from 'components/lists/ZAssetSurveyList';//ZAssetSurveyList
import ZPurchaseOrdersList from 'components/lists/ZPurchaseOrdersList';

import ZAddJobPurchaseOrderDialog from 'components/dialogs/ZAddJobPurchaseOrderDialog';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

//////////////////////////////////////////////

const ZTicketView = (props) => {
  const classes = useStyles();

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
    
  let { id } = useParams();

  const [userRoles, setUserRoles] = useLocalStorage('userRoles');
  const [state, setState] = React.useState({});
  const [showAnnotationDialog, setShowAnnotationDialog] = React.useState(false);
  const [tabIndex,  setTabIndex] = React.useState(0); //tabIndexB


  let row = {}

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleCloseAnnotation = () => {
    setShowAnnotationDialog(false);
  }

  /////////////////

  /*
  const [ result, reexecuteQuery ] = useQuery({
    query: getClient,
    variables: { id },
  });
  */

  const [updateResult, updateFunction] = useMutation(updateTicketField);

  const handleSubscription = (previousData, response) => {
    console.log('ZTicketView::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = data.zeus_client_by_pk
    //jobs = data.zeus_jobboard_entry
    return response;
  };
 
  console.log('setting up subscription')
  const [result, executeSubscription] = useSubscription({ 
    query: subscribeTicket,
    variables: { id },
    //pause: true,
  }, handleSubscription); 

  console.log('ZTicketView->result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  row = data.zeus_ticket_by_pk

  console.log('ticket->row')
  console.log(row)

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  //////////////////

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowAnnotationDialog(true)
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )


  /////////////////

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <ZTicketBox id='ticket_box'
              entry={row}
              parent_id={row.parent_id}
              static
            />  
          </Col>
          <Col xs={6}>
              { row.activities ?
              <Card variant="outlined">
                <CardHeader
                  avatar={
                    <Avatar aria-label="jobboard_entry">
                      <TimelineIcon />
                    </Avatar>
                  }
                  title='Timeline'
                  titleTypographyProps={{variant:'h5'}}
                />
                <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                  <ZActivitiesTimeline items={row.activities} />
                </Paper>  
              </Card> 
              : <div/>
            }  
            <Row><br/></Row>          
            <ZAttachmentsList key='docs_panel' parent={row} action={<div></div>} data={row.attachments} path={`ticket/${row.id}/attachments`} multiple static/>    
            <Row><br/></Row>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar aria-label="asset_survey_images" className={classes.image_avatar}>
                    <PhotoLibraryIcon />
                  </Avatar>
                }
                title='Images'
                titleTypographyProps={{variant:'h5'}}
              />
              <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                <ZImageListHorizontal key='image_list' parent_id={row.id} path={`ticket/${row.id}/images`} data={row.images} />
              </Paper>  
            </Card>             
          </Col>
        </Row>     
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZAddAnnotationDialog open={showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
            <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} action={addNoteAction} items={row.annotations} static/>
          </Col>
        </Row>            
      </Container>
    </div>
  )
}

export default ZTicketView;

