import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card'; //CardActionArea
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List'; 
import ListItem from '@material-ui/core/ListItem'; 
import Button from '@material-ui/core/Button'; //Button
import Menu from '@material-ui/core/Menu'; //Menu
import MenuItem from '@material-ui/core/MenuItem';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; 
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';   //ContactPhoneIcon
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fab from '@material-ui/core/Fab'; //Fab

import ReactTooltip from 'react-tooltip';

//import ZContactRow from 'components/core/ZContactRow';

import Chip from '@material-ui/core/Chip';

import ZContactMenu from 'components/core/ZContactMenu';
import ZSupplierMenu from 'components/core/ZSupplierMenu'; //ZSupplierMenu
import ZProductLineMenu from 'components/core/ZProductLineMenu';  // ZProductLineMenu

///////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  avatar: {
    backgroundColor: green[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

////////////////////////////////

const ZProductLinesList = (props) => {

  const classes = useStyles();
  const history = useHistory();

  const [anchorProductLine, setAnchorProductLine] = React.useState(null); //setActiveContact
  const [activeProductLine, setActiveProductLine] = React.useState({})
  ;
  const [anchorHeader, setAnchorHeader] = React.useState(null);

  const handleProductLineSelect = (event) => {
    //console.log(contact)
    console.log(event)
    setAnchorProductLine(event.currentTarget);
  };

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  const handleCloseProductLine = () => {
    setAnchorProductLine(null);
  }

  const handleShowProductLine = () => {
    setAnchorProductLine(null);
  }

  const handleNewProductForProductLine = () => {
    setAnchorProductLine(null);
  }

  const handleLogActivityProductLine = () => {
    setAnchorProductLine(null);
  }

  // className={classes.root} component="h2"

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New ProductLine</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
      </Menu>
    </div>  
  )

  const supplierMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorProductLine}
        keepMounted
        open={Boolean(anchorProductLine)}
        onClose={handleCloseProductLine}
      >
        <MenuItem onClick={(event) => { 
          let path = generatePath('/product_line/:id', {
            id: activeProductLine}
            )
          history.push(path)
        }}>
          Show
        </MenuItem>
        <MenuItem onClick={handleNewProductForProductLine}>New Product</MenuItem>       
        <MenuItem onClick={handleLogActivityProductLine}>Log Activity</MenuItem>
      </Menu>
    </div> 
  )

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardHeader
          avatar={
            <Avatar aria-label="product_line" className={classes.avatar}>
              <ContactPhoneIcon />
            </Avatar>
          }
          action={
            <Fab
              data-tip="product line actions"
              data-for="productLine_list_tooltip"            
              //color="primary"
              aria-label="add"
              size="small"
              onClick={handleCardHeaderClick}
            >
              <MoreVertIcon />
              {headerMenu}
            </Fab>
          }
          title='Product Lines'
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper className={classes.root} component="ul">
            <List component="nav" aria-label="product_lines">
              {props.data.map((productLine) => {
                return (
                  <div key={productLine.id}>
                    <ListItem                      
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="text"
                      color="primary"
                      button={true}
                      onClick={ (event)=>{
                        setActiveProductLine(productLine.id)
                        handleProductLineSelect(event)
                      }}
                    >
                      {productLine.name}
                    </ListItem>
                    {/* contactMenu */}
                    <ZProductLineMenu productLine={productLine} anchorEl={anchorProductLine} onClose={handleCloseProductLine}/> 
                  </div>             
                );
              })}
            </List>
          </Paper>
        </CardContent>
      </CardActionArea>
      <ReactTooltip id="productLine_list_tooltip"/>
    </Card>
  );
};

export default ZProductLinesList;


/*

<ZContactRow data={contact} />

                <li key={contact.id}>
                  <Chip
                    label={fullName}
                    className={classes.chip}
                  />
                </li>

*/