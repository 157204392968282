import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from 'hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllClientNodeTypes } from 'queries_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZClientNodeTypeAutocomplete = (props) => {
  let rows = []
  let skip_query = false;

  if(props.client !== undefined){
    skip_query = true
  }

  if(props.isOpen !== undefined){
    if(props.isOpen){
      skip_query = false;
    }
  }

  /*
  const { loading, error, data }  = useQuery(getAllClients, {skip: skip_query});
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllClientNodeTypes,
    pause: skip_query,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  

  if( props.items){
    console.log("items")
    console.log(props.items)
    //rows = [...props.items]
  } else {
    console.log("client node types")
    console.log(data)
    rows = data.zeus_node_type
  }

  return (
    <Autocomplete
      id="choose-client-node-type-combo-box"
      onChange={(event,item)=>{
        console.log("client node type autocomplete change")
        console.log(item)
        if(props.onChange){
          props.onChange(event,item)
        }  
      }}
      options={props.items ? [ ...props.items ] : rows}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}

      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Client Node Type" variant="outlined" />}
    />
  )
}

export default ZClientNodeTypeAutocomplete;