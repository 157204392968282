import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { updateTicketClosed, addNotification, updateAnalysisBloC, addAnalysisBloC, 
  prodigyClientAutoencode, prodigySensorAutoencode, addActivity, prodigyClientRetrain, prodigySensorRetrain} from 'mutations_urql';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import LoupeIcon from '@material-ui/icons/Loupe';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select'; //FormControl
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ReactTooltip from 'react-tooltip';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { uuid } from 'uuidv4';
import Moment from 'react-moment';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  snackbarStyle: {
    backgroundColor: "lightyellow",
    minHeight: 400,
  },
}));

//////////////////////
/*props
    {
        

    }
*/
//////////////////////

const ZSetAnalysisBloCButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const snackbarEl = React.useRef(null);

  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    open: false,
    alias: '',
    startDate: undefined,
    endDate: undefined,
    selectionModel: [ props.client.sensors.filter((r) => r.status === "active").map((r) => r.id), ] ,
    selectionAnalysisModel: [...props.selectionAnalysisModel ?? []],
  });

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [tabIndex, setTabIndex] = React.useState(0);

  console.log('ZSetAnalysisBloCButton->props')
  console.log(props)

  let analyzeMutation = prodigyClientAutoencode
  let retrainMutation = prodigyClientRetrain

  if(props.isSensor){
    analyzeMutation = prodigySensorAutoencode
    retrainMutation = prodigySensorRetrain
    // do we have a trained model for this sensor

  }

  const [updateResult,     updateFunction]     = useMutation(updateAnalysisBloC);
  const [insertResult,     insertFunction]     = useMutation(addAnalysisBloC);

  const [analyzeResult,     blocAnalyzeEventFunction]   = useMutation(analyzeMutation);
  const [retrainResult,     blocRetrainEventFunction]   = useMutation(retrainMutation);

  const [activityResult,     activityFunction]   = useMutation(addActivity);

  const handleClose = () => {
    setState({...state, open: false})
  }

  let parent_id  = props.id;
  let bloc_id = null;
  if(props.bloc){
    bloc_id = props.bloc.id;
    //parent_id = props.bloc.parent_id;
  }

  ///////////////

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };  

  const handleStartDateSelected = (theDate, dateRange) => {
    console.log("handleStartDateSelected")
    console.log(theDate)
    setState({...state, startDate: theDate})
  }

  const handleEndDateSelected = (theDate, dateRange) => {
    console.log("handleEndDateSelected")
    console.log(theDate)
    setState({...state, endDate: theDate})
  }  

  const handleSubmit = () => {
    let user_id = userData.id
    let modifiedOn = moment().format();
    state.modifiedOn = modifiedOn;

    const { open, alias, ...object } = state;

    console.log("default_settings");
    console.log(props.bloc.default_settings);

    let default_settings = [];

    for (var i=0; i < props.bloc.default_settings.length; i++) {
      let element = props.bloc.default_settings[i];
      let xxx = {...element};
      for (const key in state) {
        if(element.name === key) {
          if(xxx.type === 'number'){
            xxx.value = parseFloat(state[key]);
          } else {
            xxx.value = state[key];
          }
        } 
      }
      default_settings.push(xxx)
    }

    let _set = {
      default_settings: default_settings,
      status: "enabled",
    }

    let variables = { 
      id: bloc_id, 
      activity: {
        user_id: user_id,
        parent_id: props.bloc.id,
        name: `client ${props.bloc.name} updated`,
        description: `${userData.alias} updated ${props.bloc.name} settings for client`,
      },
      _set: _set,
    }

    console.log(variables);

    //return;

    updateFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        setAlertState({
          open: true,
          message: `Successfully enabled ${props.bloc.name} for client`,
          severity: 'info',
        })
      }
    })

    handleClose();
  }


  const handleDisableLocal = () => {
    let user_id = userData.id
    // we need to create a local copy of this analysisBloC that is disabled
    let variables = {
      object: {
        name: props.bloc.alias,
        analysis_type: props.bloc.name,
        status: "disabled",
        parent_id: props.id,
        analysis_bloc_type_id: props.bloc.analysis_bloc_type_id,
        description: props.bloc.description,
        default_settings: props.bloc.default_settings, 
      },
      activities: [
        {
          user_id: user_id,
          parent_id: props.id,
          name: `analysis module ${props.bloc.name} disabled`,
          description: `${userData.alias} disabled ${props.bloc.name} analysis for this Sensor`,
        },
        {
          user_id: user_id,
          parent_id: props.client.id,
          name: `analysis module ${props.bloc.name} disabled for Sensor ${props.sensor.name}`,
          description: `${userData.alias} disabled ${props.bloc.name} analysis for Sensor ${props.sensor.name}`,
        }
      ]   
    }

    console.log(variables);

    //return;

    insertFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        setAlertState({
          open: true,
          message: `Successfully created analysis`,
          severity: 'info',
        })
      }      
    })
    
    handleClose();      
  }


  const handleRetrain = () => {
    console.log("ZSetAnalysisBloCButton::handleRetrain")
    if(props.onRetrain){
      console.log("using onRetrain() from parent component")
      props.onRetrain(props.bloc)
      return
    }

    let user_id = userData.id

    let variables = {} 

    if(props.isSensor){
      variables = {
        serialNumber: props.sensor.name,
        period: 28,
        ascending: 1,
        iretrain: {
          serialNumber: props.sensor.name,
          gwSerial: props.sensor.gateway.name,
          //measurementPaths: selectedData,
        },        
      }

      if(state.startDate){
        variables.iretrain.start = moment(state.startDate).unix()
        variables.iretrain.end = moment(state.endDate).unix()
      }

    } else {
      variables = {
        clientId: props.client.id,
        period: 28,
      }
    }

    console.log(variables);   

    blocRetrainEventFunction(variables).then((result)=>{

      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        let activity = {
          user_id: user_id,
          parent_id: parent_id,
          name: `analysis module ${props.bloc.name} retrain`,
          description: `${userData.alias} triggered a retrain event of ${props.bloc.name}`,
        }

        activityFunction({activity: activity})

        setAlertState({
          open: true,
          message: `Successfully triggered retraining event`,
          severity: 'info',
        })
      }  
      
      handleClose();
    })

  }


  const handleAnalyze = () => {
    console.log("ZSetAnalysisBloCButton::handleAnalyze");
    if(props.onAnalyze){
      console.log("ZSetAnalysisBloCButton::props.onAnalyze");
      props.onAnalyze(props.bloc)
      return
    }

    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = {
      clientId: props.client.id,
    }

    if(props.isSensor){
      variables = {
        serialNumber: props.sensor.name,
      }
    }

    console.log(variables)

    blocAnalyzeEventFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        let activity = {
          user_id: user_id,
          parent_id: parent_id,
          name: `analysis module ${props.bloc.name} triggered`,
          description: `${userData.alias} triggered an analysis event of ${props.bloc.name} analysis`,
        }

        activityFunction({activity: activity})

        setAlertState({
          open: true,
          message: `Successfully triggered analysis`,
          severity: 'info',
        })
      }      
    })
    
    handleClose();     
  }


  const handleDisable = () => {
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: bloc_id, 
      activity: {
        user_id: user_id,
        parent_id: props.sensor.id,
        name: `analysis module ${props.bloc.name} disabled`,
        description: `${userData.alias} disabled ${props.bloc.name} analysis for this sensor`,
      },
      _set: {
        status: "disabled",
        modifiedOn: modifiedOn,
      }
    }

    console.log(variables);

    //return;

    updateFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {

        if(props.client){
          props.client.sensors.forEach((sensor)=>{
            sensor.analysis_blocs.forEach((ab)=>{
              if(ab.analysis_bloc_type_id === props.bloc.analysis_bloc_type_id){
                let vvv = { 
                  id: ab.id, 
                  activity: {
                    user_id: user_id,
                    parent_id: props.sensor.id,
                    name: `analysis module ${props.bloc.name} disabled`,
                    description: `${userData.alias} disabled ${props.bloc.name} analysis for this sensor`,
                  },
                  _set: {
                    status: "disabled",
                    modifiedOn: modifiedOn,
                  }
                }            
                updateFunction(vvv)
              }
            })
          })
        }

        setAlertState({
          open: true,
          message: `Successfully disabled ${props.bloc.name} for client`,
          severity: 'info',
        })
      }
    })

    handleClose();    
  }

  ////////////////

  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to open snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              //setOpenAlert(false)
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    /> 
  );


  const ModelList = () => {

    console.log("creating model list")

    let columns = [
      {
        field: 'timestamp',
        title: 'Timestamp',
        headerName: 'Timestamp',
        minWidth: props.nameW ?? 220,
        editable: false,
        hide: false,
        type: 'date',
        renderCell: (params) => (
          <Moment format="YYYY-MM-DD HH:mm:ss" unix>
           {params.row.timestamp}
          </Moment>
        ),
        valueGetter: (params) => {
          return moment.unix(params.row.timestamp)
        },           
      },        
      {
        field: 'name',
        title: 'Name',
        headerName: 'Name',
        minWidth: props.nameW ?? 200,
        editable: false,
        hide: false,
      }, 
    ]

    let active_models = [];

    let udfs = props.sensor.user_defined_fields;
    for (const property in udfs){
      let active_model = udfs[property]
      active_models.push( {
        id: active_model.replace(/^.*[\\/]/, ''),
        name: active_model.replace(/^.*[\\/]/, ''),
        path: active_model,
        timestamp: active_model.replace(/^.*[\\/]/, '').split(".")[0],
      } )
    }

    console.log(active_models);

    return (
      <div>
        <Card variant="outlined" className={classes.root}>
          <CardContent>
            <Paper id='model-data-grid' className={classes.sensor_row}>
              <DataGrid autoHeight
                rows={active_models}
                columns={columns}
                pageSize={props.pageSize ?? 20}
                rowsPerPageOptions={[5]}
                density="compact"
                checkboxSelection={true}
                disableSelectionOnClick={true}
                getRowClassName={(params) =>
                  clsx('super-app', {
                    active: params.row.status === 'active',
                  })
                }
                options={{
                  //toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  //exportButton: true,
                }}
                //components={{
                //  Toolbar: GridToolbar,
                //}}
                rowSelectionModel={state.selectionAnalysisModel}
                onSelectionModelChange={(newSelectionModel) => {
                  console.log("model selected")
                  console.log(newSelectionModel)
                  if(props.onSelectionAnalysisModelSelect){
                    console.log("calling props.onSelectionAnalysisModelSelect(newSelectionModel)")
                    props.onSelectionAnalysisModelSelect(newSelectionModel);
                  }

                  setState({...state, selectionAnalysisModel: newSelectionModel});
                }}
                //onCellEditCommit={props.editable ? handleCellEditCommit : ()=>{}}
              />
            </Paper>
          </CardContent>
          <ReactTooltip id="model_list_tooltip"/>
        </Card>
      </div>      
    )
  }


  const EnabledSensorList = () => {

    let columns = [
      {
        field: 'name',
        title: 'Name',
        headerName: 'Name',
        minWidth: props.nameW ?? 130,
        editable: false,
        hide: false,
      },
      {
        field: 'alias',
        title: 'Alias',
        headerName: 'Alias',
        minWidth: props.aliasW ?? 180,
        editable: true,
        hide: false,
      },    
    ]

    return (
      <div>
        <Card variant="outlined" className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="jobs" className={classes.routes_avatar}>
                <NetworkCheckIcon />
              </Avatar>
            }      
            //action={props.action ? props.action : defaultAction}         
            title='Sensors'
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>
            <Paper id='sensor-data-grid' className={classes.sensor_row}>
              <DataGrid autoHeight
                rows={props.client.sensors}
                columns={columns}
                pageSize={props.pageSize ?? 20}
                rowsPerPageOptions={[5]}
                density="compact"
                checkboxSelection={true}
                disableSelectionOnClick={true}
                getRowClassName={(params) =>
                  clsx('super-app', {
                    active: params.row.status === 'active',
                    non_responsive: params.row.status === 'non responsive',
                    recovered: params.row.status === 'recovered',
                    lost: params.row.status === 'lost',
                    low_battery: params.row.status === 'low battery',
                  })
                }
                options={{
                  toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  exportButton: true,
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                rowSelectionModel={state.selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                  setState({...state, selectionModel: newSelectionModel});
                }}
                //onCellEditCommit={props.editable ? handleCellEditCommit : ()=>{}}
              />
            </Paper>
          </CardContent>
          <ReactTooltip id="sensor_list_tooltip"/>
        </Card>
      </div>      
    )
  }

  const BloCForm = () => {

    return (
      <div>
        <form className={classes.form} noValidate>
          <FormControl className={classes.formControl}>
            <div>
              <Paper sx={{ maxWidth: 400, my: 10, mx: 'auto', p: 2 }}>
                <FormControl className={classes.formControl}>
                {
                  props.bloc.default_settings.map((ds,index)=>{
                    let elem = (<div></div>)
                    if(ds.type === "bool"){
                      elem = (
                        <FormControlLabel
                          label={ds.name}
                          labelPlacement="start"                            
                          control={
                            <Switch
                              checked={state[ds.name] === undefined ? ds.value : state[ds.name]}
                              disabled={(props.configurable || props.cloneable) ? false : true}
                              onChange={(event) => {
                                setState({ ...state, [event.target.name]: event.target.checked });
                              }}
                              name={ds.name}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          }
                        />                            
                      )
                    }
  
                    if(ds.type === "number"){
                      elem = (
                        <div>
                          <TextField
                            id={`td-${index}`}
                            label={ds.name}
                            type="number"
                            defaultValue={state[ds.name] === undefined ? ds.value : state[ds.name]}
                            disabled={(props.configurable || props.cloneable) ? false : true}
                            onChange={(event) =>{
                              let sss = {...state}
                              sss[ds.name] = event.target.value;
                              setState(sss);
                            }} 
                          />                             
                        </div>                            
                      )
                    }
  
                    if(ds.type === "text"){
                      elem = (
                        <div>
                          <TextField
                            id={`td-${index}`}
                            label={ds.name}
                            defaultValue={state[ds.name] === undefined ? ds.value : state[ds.name]}
                            disabled={(props.configurable || props.cloneable) ? false : true}
                            onChange={(event) =>{
                              let sss = {...state}
                              sss[ds.name] = event.target.value;
                              setState(sss);
                            }} 
                          />                             
                        </div>                            
                      )
                    }
                    return (elem)
                  })
                }
                </FormControl>
              </Paper>  
            </div>  
          </FormControl> 
        </form>
      </div>
    )
  } 
  
  

  /*
  {
    name
    type -> text, bool, number,
    value
  }
  */

  return (
    <div>
      <div ref={snackbarEl} />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`${props.bloc.name} - Global Default Settings`}</DialogTitle>
            {/* model existence */}
            {
              props.noSensorList
              ?
              <DialogContent>
                <Box>
                  <Tabs value={tabIndex} variant="fullWidth" onChange={handleTabChange}>
                    <Tab label="General" />
                    <Tab label="Models" />
                  </Tabs>
                </Box>
                <Box sx={{ padding: 2 }}>
                  {tabIndex === 0 && (
                    <Box>
                      <BloCForm />  
                    </Box>
                  )}    
                  {tabIndex === 1 && (
                    <Box>                   
                      <ModelList />
                    </Box>
                  )}                                 
                </Box>  
                <Box>
                  <FormControl className={classes.formControl}>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="startDate"
                            value={state.startDate ?? null}
                            label="Start Date"
                            onChange={handleStartDateSelected}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />   
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="endDate"
                            value={state.endDate ?? null}
                            label="End Date"
                            onChange={handleEndDateSelected} 
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          /> 
                        </Grid>
                      </MuiPickersUtilsProvider> 
                    </div> 
                  </FormControl>                   
                </Box>
              </DialogContent>
              :
              <DialogContent>
                <Box>
                  <Tabs value={tabIndex} variant="fullWidth" onChange={handleTabChange}>
                    <Tab label="General" />
                    <Tab label="Sensors" />
                    <Tab label="Models" />
                  </Tabs>
                </Box>
                <Box sx={{ padding: 2 }}>
                  {tabIndex === 0 && (
                    <Box>
                      <BloCForm />  
                    </Box>
                  )}
                  {tabIndex === 1 && (
                    <Box>                   
                      <EnabledSensorList />
                    </Box>
                  )}    
                  {tabIndex === 2 && (
                    <Box>                   
                      <ModelList />
                    </Box>
                  )}                                 
                </Box>   
                <Box>
                <FormControl className={classes.formControl}>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="startDate"
                          value={state.startDate}
                          label="Start Date"
                          onChange={handleStartDateSelected}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />   
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="endDate"
                          value={state.endDate ?? null}
                          label="End Date"
                          onChange={handleEndDateSelected} 
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        /> 
                      </Grid>
                    </MuiPickersUtilsProvider> 
                  </div> 
                </FormControl>                       
                </Box>
              </DialogContent>
            }
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleRetrain} color="primary">
                Retrain
              </Button>
              <Button onClick={handleAnalyze} color="primary">
                Analyze
              </Button>              
              <Button onClick={handleSubmit} color={props.cloneable ? "secondary" : "primary"} disabled={props.configurable || props.cloneable ? false : true}>
                { props.cloneable ? "Save Configuration for Sensor" : "Save" }
              </Button>
              {props.noDisableButton ? <div/> : <Button onClick={props.global ? handleDisableLocal : handleDisable} color="primary">
                Disable
              </Button>}
              {props.noCloneButton ? <div/> : <Button onClick={props.onClone} color="primary">
                Clone
              </Button> }
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8, marginBottom: 5, marginTop: 5 }}
        variant="outlined"
        data-tip={props.bloc.description}
        data-for="anomaly_button_tooltip" 
        startIcon={ props.active ? <CheckIcon color="secondary" /> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true})
        }}       
        disabled={props.disabled}               
      >
        {props.bloc.name}
      </Button>
      <ReactTooltip id="anomaly_button_tooltip"/>
    </div>
  );

}

export default ZSetAnalysisBloCButton;


