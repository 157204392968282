import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
import { addQuotation, updateQuotation} from '../../mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getAllQuotations, getAllClients, getClient, getContact } from 'queries_urql';
import { addQuotation, updateQuotation } from 'mutations_urql';

import Moment from 'react-moment';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';


///////////////////

const ZPipelineInput = (props) => {
  const [state, setState] = React.useState({})

  const getQuarter = (d) => {
    d = d || new Date(); // If no date supplied, use today
    //var q = [4,1,2,3];
    var q = [1,2,3,4];
    return q[Math.floor(d.getMonth() / 3)];
  }

  const computeFunnel = () => {
    var now = new Date()
    var year = now.getFullYear()
    var quarter = getQuarter()
    console.log("quarter: "+ quarter)
    var fff = year +" Q"+ quarter +" Pipeline";
    //setFunnel(fff)
    console.log("pipeline: "+ fff)
    return fff;
  }

  return (
    <TextField
      id="funnel"
      label={computeFunnel()}
      variant="outlined"
      rows={1}
      onChange={props.onChange} 
    />  
  )
}

export default ZPipelineInput;