import React from 'react';
//import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';

import Moment from 'react-moment';
import moment from 'moment';

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery } from '@apollo/client';
import { wonSalesTotalQuery, wonSalesFunnelQuery, salesBySupplierAndFunnelQuery } from 'queries';
*/

import { useQuery } from 'urql';
import { wonSalesTotalQuery, wonSalesFunnelQuery, salesBySupplierAndFunnelQuery } from 'queries_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ZTitle from 'components/core/ZTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

//////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10),
  },
  info: {
    margin: theme.spacing(2),
  },
}));

//////////////////////

const ZSalesToDateBox = (props) => {
  const classes = useStyles();
  const now = moment()
  let query = wonSalesTotalQuery
  let variables = {}

  let supplier = false;
  if(typeof props.supplier !== 'undefined'){
    if(! props.supplier.includes('All')){
      supplier = true
      console.log("ZSalesToDateBox -> supplier is set")
      console.log(props)
    }     
  }

  if(typeof props.year !== 'undefined'){
    variables = {accepted_year: props.year}
  }

  if(typeof props.funnel !== 'undefined'){
    query = wonSalesFunnelQuery
    variables = {funnel: props.funnel}
  }

  if(supplier === true){
    query = salesBySupplierAndFunnelQuery
    variables = {
      funnel: props.funnel,
      supplier: `%${props.supplier}%`,
      status: "accepted",
    }   
  }


  /////////
  /*
  if(supplier === true
    && typeof props.year !== 'undefined' 
    && typeof props.month !== 'undefined'){

      query = pendingSalesBySupplierAndMonthQuery
      variables = {
        created_year: props.year,
        created_month: props.month,
        supplier: `${props.supplier}`,
        status: "pending",
      } 
  }
*/

  console.log('variables')
  console.log(variables)
  //console.log(`ZSalesToDateBox query ${JSON.stringify(query)}`)
  //const handleOnProduct

  /*
  const { loading, error, data, refetch }  = useQuery(query,{ 
    variables: variables,
    pollInterval: 10000,
   });

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: query,
    variables: variables,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(typeof props.year !== 'undefined'){
    console.log("sales in month to date")
    console.log(variables)
    console.log(data);
    let total_amount = data.zeus_quotation_view_aggregate.aggregate.sum.amount
    total_amount = total_amount ? total_amount : 0;

    let avg_amount = data.zeus_quotation_view_aggregate.aggregate.avg.amount
    avg_amount = avg_amount ? avg_amount : 0;

    let max_amount = data.zeus_quotation_view_aggregate.aggregate.max.amount
    max_amount = max_amount ? avg_amount : 0;

    return (
      <Card>
        <ZTitle>Sales Current Year To Date</ZTitle>
          <div className={classes.info}>
            <Typography component="p" variant="h6">
              {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
            </Typography>
            <Typography color="primary" >count: {data.zeus_quotation_view_aggregate.aggregate.count}</Typography>
            <Typography>{`max sale $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
            <Typography>{`avg sale $ ${avg_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );
  } 

  if(supplier === true && typeof props.funnel !== 'undefined'){
    console.log("sales by supplier in funnel to date")
    //refetch()
    console.log(variables)
    console.log(data);
    let total_amount = data.won_aggregate.aggregate.sum.amount
    let max_amount = data.won_aggregate.aggregate.max.amount
    let avg_amount = data.won_aggregate.aggregate.avg.amount
    return (
      <Card>
        <ZTitle>{`Funnel: ${props.supplier} Sales To Date`}</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="primary" >count: {data.won_aggregate.aggregate.count}</Typography>
          <Typography>{`max sale $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          <Typography>{`avg sale $ ${avg_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );    
  }


  if(typeof props.funnel !== 'undefined'){
    console.log("sales in funnel to date")
    console.log(variables)
    console.log(data);
    let total_amount = data.zeus_quotation_view_funnel_aggregate.aggregate.sum.amount
    let max_amount = data.zeus_quotation_view_funnel_aggregate.aggregate.max.amount
    let avg_amount = data.zeus_quotation_view_funnel_aggregate.aggregate.avg.amount
    return (
      <Card>
        <ZTitle>Funnel: Sales To Date</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="primary" >count: {data.zeus_quotation_view_funnel_aggregate.aggregate.count}</Typography>
          <Typography>{`max sale $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          <Typography>{`avg sale $ ${avg_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );    
  }  else {

    return (
      <Card>
        <ZTitle>Funnel Sales Year To Date</ZTitle>
          <Typography component="p" variant="h6">

          </Typography>
          <Typography color="primary" ></Typography>
          <Typography></Typography>
          <Typography></Typography>
      </Card>
    );     
  }
}

export default ZSalesToDateBox;

/*
      <div>
        <Link color="primary" href="#">
          Do something
        </Link>
      </div>
*/