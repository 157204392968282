import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getQuotation,  getQuotesForClient } from 'queries';
import { addClient, updateClient } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getQuotation,  getQuotesForClient, getSalesDocument } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { subscribeSalesDoc } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZSalesDocumentBox from 'components/boxes/ZSalesDocumentBox';
import ZClientBox from 'components/boxes/ZClientBox';
import ZContactBox from 'components/boxes/ZContactBox';
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZInvoicesList from 'components/lists/ZInvoicesList';//ZInvoicesList
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZAddJobInvoiceDialog from 'components/dialogs/ZAddJobInvoiceDialog';

import ZAddAnnotationDialog from './dialogs/ZAddAnnotationDialog';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';

//const ResponsiveReactGridLayout = WidthProvider(Responsive);
///////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

//////////////////////////////////////////////

const ZSalesDocumentView = (props) => {

  /*
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  */
  //eventBus.dispatch("pageLoad", { message: '' });
  
  const history = useHistory();
  const classes = useStyles();
  let { id } = useParams();
  const [state, setState] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showAnnotationDialog, setShowAnnotationDialog] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [showInvoiceDialog, setShowInvoiceDialog]  = React.useState({
    open: false,
    job: null,
  });

  //const [screen, setScreen] = useLocalStorage('screen_name');
  //setScreen('Clients')
  let row = undefined;

  console.log('sales_document_id: '+ id)

  const handleCloseAnnotation = () => {
    setShowAnnotationDialog(false);
  }

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleMenuClick = (event) => {
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
    //setShowInvoiceDialog({...showInvoiceDialog, open: true})
  }

  /*
  const { loading, error, data } = useQuery(getQuotation, {
    variables: { id: id },
  });

  if (loading) return (<h2>Loading client... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;


  const [ result, reexecuteQuery ]  = useQuery({
    query: getQuotation,
    variables: { id },
  });

    */

  /////////////////
  //const [updateSalesDocResult, updateFunction] = useMutation(updateClient);

  //useQuery({query: getSalesDocument, variables: { id },});

  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //return response.zeus_sales_document_by_pk
    //row = response
    //row = data.zeus_tool_by_pk
    //jobs = data.zeus_jobboard_entry
    return response;
  };
 
  console.log('setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeSalesDoc,
    variables: { id },
    pause: !window.navigator.onLine,
  }, handleSubscription); 

  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getSalesDocument,
    variables: { id },
    //pause: window.navigator.onLine,
  });

  console.log(subResult)
  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log(props.documentType +' data')
  row = data.zeus_sales_document_by_pk
  console.log(row)

  eventBus.dispatch("pageLoad", { message: props.documentType });

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10,
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  ////////////////



  ////////////////

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowAnnotationDialog(true)
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addInvoiceAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowInvoiceDialog({...showInvoiceDialog, open: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>    
  )

  let addInvoiceButton = (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowInvoiceDialog({...showInvoiceDialog, open: true})
          setAnchorMenu(null);
        }}
      >
        Add Invoice
      </Button>      
    </div>      
  )

  let addAnnotationButton = (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowAnnotationDialog(true)
          setAnchorMenu(null);
        }}
      >
        Add Annotation
      </Button>      
    </div>      
  )  

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorMenu}
        //keepMounted
        open={Boolean(anchorMenu)}
        onClose={handleCloseMenu}
      >
        <Divider variant="middle"/>
        <MenuItem>{addInvoiceButton}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{addAnnotationButton}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem onClick={handleCloseMenu}>Close</MenuItem>
        <Divider variant="middle"/>
      </Menu>
    </div>  
  )

  let actionMenu = (
    <Fab
      data-tip="document actions"
      data-for="document_box_tooltip"
      //color="primary"
      aria-label="add"
      size="small"
      onClick={handleMenuClick}
    >
      <MoreVertIcon/>
      { headerMenu }
    </Fab>    
  )

  //////////

  let main_component = (
    <div>
      <Container fluid>
        <ZAddJobInvoiceDialog open={showInvoiceDialog.open} parent={row} job={row.jobBoardEntry} onClose={()=>setShowInvoiceDialog({...showInvoiceDialog, open: false})} />
        <ZAddAnnotationDialog open={showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} /> 
        <Row>
          <Col xs={6}>
            <ZSalesDocumentBox 
              id='document_box' 
              title={props.documentType}
              document={row}
              //actions={actionMenu}
              documentType={props.documentType}
            />
          </Col>
          <Col xs={6}>
            { row.activities ?
              <Card variant="outlined">
                <CardHeader
                  avatar={
                    <Avatar aria-label="jobboard_entry">
                      <TimelineIcon />
                    </Avatar>
                  }
                  title='Timeline'
                  titleTypographyProps={{variant:'h5'}}
                />
                <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                  <ZActivitiesTimeline items={row.activities} />
                </Paper>  
              </Card> 
              : <div/>
            }  
            <Row><br/></Row> 
            <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} path={`sales_document/${row.id}/attachments`} data={row.attachments} multiple static/>
          </Col>
        </Row>
        <Row><br/></Row>  
        <Row>
          <Col xs={12}>
          <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Contact" />
                  <Tab label="Client" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={350} descriptionW={550} action={addNoteAction} parent={row} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZContactBox 
                      id='contact_box'
                      title='Contact' 
                      contact={row.contact ? row.contact : false}
                      name={row.contact ? row.contact.name : ''} 
                      description={row.contact ? row.contact.description : ''} 
                      createdOn={row.contact ? row.contact.createdOn : ''}
                    />  
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box onClick={(event) => {
                      let path = generatePath('/client/:id', {
                        id: row.client.id}
                        )
                      history.push(path)                      
                  }}>
                    <ZClientBox 
                      id='client_box' 
                      title='Client'
                      client={row.client ? row.client:  false}
                      name={row.client ? row.client.name : ''} 
                      description={row.client ? row.client.description : ''} 
                      createdOn={row.client ? row.client.createdOn : ''}
                    /> 
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  )

  if(props.documentType === 'PurchaseOrder'){

    main_component = (
      <div>
      <Container fluid>
        <ZAddJobInvoiceDialog open={showInvoiceDialog.open} parent={row} job={row.jobBoardEntry} onClose={()=>setShowInvoiceDialog({...showInvoiceDialog, open: false})} />
        <ZAddAnnotationDialog open={showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} /> 
        <Row>
          <Col xs={6}>
            <ZSalesDocumentBox 
              id='document_box' 
              title={props.documentType}
              document={row}
              //actions={actionMenu}
              documentType={props.documentType}
            />
          </Col>
          <Col xs={6}>
            { row.activities ?
              <Card variant="outlined">
                <CardHeader
                  avatar={
                    <Avatar aria-label="jobboard_entry">
                      <TimelineIcon />
                    </Avatar>
                  }
                  title='Timeline'
                  titleTypographyProps={{variant:'h5'}}
                />
                <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                  <ZActivitiesTimeline items={row.activities} />
                </Paper>  
              </Card> 
              : <div/>
            }  
            <Row><br/></Row> 
            <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} path={`sales_document/${row.id}/attachments`} data={row.attachments} multiple static/>
          </Col>
        </Row>
        <Row><br/></Row>  
        <Row>
          <Col xs={12}>
          <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Invoices" />
                  <Tab label="Contact" />
                  <Tab label="Client" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel'nameW={350} descriptionW={550} action={addNoteAction} parent={row} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZInvoicesList key='invoices_panel' descriptionW={350} action={addInvoiceAction} parent={row} items={row.invoices} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    <ZContactBox 
                      id='contact_box'
                      title='Contact' 
                      contact={row.contact ?? false}
                      name={row.contact ? row.contact.name : ''} 
                      description={row.contact ? row.contact.description : ''} 
                      createdOn={row.contact ? row.contact.createdOn : ''}
                    />  
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box onClick={(event) => {
                      let path = generatePath('/client/:id', {
                        id: row.client.id}
                        )
                      history.push(path)                      
                  }}>
                    <ZClientBox 
                      id='client_box' 
                      title='Client'
                      client={row.client ?? false}
                      name={row.client ? row.client.name : ''} 
                      description={row.client ? row.client.description : ''} 
                      createdOn={row.client ? row.client.createdOn : ''}
                    /> 
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
    )
  }


  //////////

  return (
    <div>
      {main_component}
    </div>
  )
}

export default ZSalesDocumentView;

/*

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <ZSalesDocumentBox 
              id='document_box' 
              title={props.documentType}
              document={data}
              actions={<div></div>}
              documentType={props.documentType}
            />
          </Col>
          <Col xs={6}>
              { data.activities ?
                <Card variant="outlined">
                  <CardHeader
                    avatar={
                      <Avatar aria-label="jobboard_entry">
                        <TimelineIcon />
                      </Avatar>
                    }
                    title='Timeline'
                    titleTypographyProps={{variant:'h5'}}
                  />
                  <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                    <ZActivitiesTimeline items={data.activities} />
                  </Paper>  
                </Card> 
                : <div/>
              }  
          </Col>
        </Row>
        <Row><br/></Row>        
        <Row>
          <Col xs={6}> 
            <ZClientBox 
                id='client_box' 
                title='Client'
                client={data.client ?? false}
                name={data.client ? data.client.name : ''} 
                description={data.client ? data.client.description : ''} 
                createdOn={data.client ? data.client.createdOn : ''}
              /> 
          </Col>
          <Col xs={6}>
              <ZContactBox 
                id='contact_box'
                title='Contact' 
                contact={data.contact ?? false}
                name={data.contact ? data.contact.name : ''} 
                description={data.contact ? data.contact.description : ''} 
                createdOn={data.contact ? data.contact.createdOn : ''}
              />                
          </Col>
        </Row>
        <Row><br/></Row>
        {
          props.documentType === 'PurchaseOrder'
          ?
          <div>
            <Row>
              <Col xs={12}>                 
                <ZInvoicesList key='notes_panel' action={<div></div>} parent={data} items={data.invoices} static/>
              </Col>      
            </Row>  
            <Row><br/></Row>
          </div>
          :
          <div></div>
        }      
        <Row>
          <Col xs={6}>                 
            <ZAnnotationsList key='notes_panel' action={addNoteAction} parent={data} items={data.annotations} static/>
          </Col>
          <Col xs={6}>
            <ZAddAnnotationDialog open={showAnnotationDialog} parent_id={data.id} handleClose={handleCloseAnnotation} />
            <ZAttachmentsList key='docs_panel' action={<div></div>} data={data.attachments} static/>
          </Col>        
        </Row>
      </Container>
    </div>
  )

*/

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/
