import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from 'hooks/useStorage';

import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ZJobBoardPanel from 'components/panels/ZJobBoardPanel';
import ZSchedulePanel from 'components/panels/ZSchedulePanel';
import ZBreadCrumbs from 'components/core/ZBreadCrumbs';

import eventBus from 'utils/EventBus';


//////////////////////////////////////////////////

const ZJobBoardView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10,
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ZSchedulePanel />
          </Col>
        </Row>
          <Row><br/></Row>
          { userRoles.includes('admin') &&
            <Row>
              <Col xs={12}>
                <ZJobBoardPanel pageSize={50} />         
              </Col>        
            </Row>
          }
          <Row><br/></Row>
      </Container>
    </div>
  )
}
  
export default ZJobBoardView;

