import React from 'react';
//import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery } from '@apollo/client';
import { pendingSalesStatsQuery, 
  pendingSalesFunnelStatsQuery, 
  salesBySupplierAndFunnelQuery, 
  salesBySupplierAndMonthQuery,
  pendingSalesBySupplierAndMonthQuery } from 'queries';
*/

import { useQuery } from 'urql';
import { pendingSalesStatsQuery, 
  pendingSalesFunnelStatsQuery, 
  salesBySupplierAndFunnelQuery, 
  salesBySupplierAndMonthQuery,
  pendingSalesBySupplierAndMonthQuery } from'queries_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import Typography from '@material-ui/core/Typography';
import ZTitle from 'components/core/ZTitle';
import Moment from 'react-moment';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

//////////////////////
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10),
  },
  info: {
    margin: theme.spacing(2),
  },
}));

//////////////////////

const ZPendingSalesStatsBox = (props) => {
  const classes = useStyles();
  const now = moment()
  const the_year = now.year()
  const the_month = now.month()

  let supplier = false;
  if(typeof props.supplier !== 'undefined'){
    if(! props.supplier.includes('All')){
      supplier = true
      console.log("ZPendingSalesStatsBox -> supplier is set")
      console.log(props)
    }     
  }

  let query = pendingSalesStatsQuery
  let variables = {
    created_year: the_year,
    created_month: the_month,    
  }

  if(typeof props.year !== 'undefined' && typeof props.month !== 'undefined'){
    variables = {
      created_year: props.year,
      created_month: props.month,
    }
  }

  if(typeof props.funnel !== 'undefined'){
    query = pendingSalesFunnelStatsQuery
    variables = {funnel: props.funnel}
  }

  if(supplier === true){
    query = salesBySupplierAndFunnelQuery
    variables = {
      funnel: props.funnel,
      supplier: `%${props.supplier}%`,
      status: "pending",
    }   
  }

  if(supplier === true
    && typeof props.year !== 'undefined' 
    && typeof props.month !== 'undefined'){

      query = pendingSalesBySupplierAndMonthQuery
      variables = {
        created_year: props.year,
        created_month: props.month,
        supplier: `${props.supplier}`,
        status: "pending",
      } 
  }

  /*
  const { loading, error, data }  = useQuery(query,{ 
    variables: variables,
    pollInterval: 10000,
   });

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
   */

  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: query,
    variables: variables,
  });
  
  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  ////////////////
  
  if(typeof props.funnel !== 'undefined' && supplier === true){
    console.log("pending sales for supplier in funnel")
    console.log(variables)
    console.log(data);

    //let index = data.zeus_funnel_pending_sales_stats.length -1;
    //let current_funnel = data.zeus_funnel_pending_sales_stats[0]
    
    let total_amount = data.pending_aggregate.aggregate.sum.amount
    let max_amount = data.pending_aggregate.aggregate.max.amount
    let avg_amount = data.pending_aggregate.aggregate.avg.amount
    let min_amount = data.pending_aggregate.aggregate.min.amount
    
    let quote_count = data.pending_aggregate.aggregate.count

    let title = 'Current Month Sales Statistics'
    if(props.month !== the_month){
      title = `Selected Month Sales Statistics for ${props.supplier}`
    }

    return (
      <Card>
        <ZTitle>{`Funnel: ${props.supplier} Pending Sales`}</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="primary">{`quotes: ${quote_count}`}</Typography>
          <Typography>{`max quote: $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          <Typography>{`min quote: $ ${min_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );
  }


  if(typeof props.year !== 'undefined' && supplier === true){
    console.log("pending supplier sales in month")
    console.log(props)
    console.log(variables)
    console.log(data);
    //let index = data.zeus_monthly_pending_sales_stats.length -1;
    //let current_month = data.zeus_monthly_pending_sales_stats[index]
    let total_amount = 0
    let max_amount = 0
    let avg_amount = 0
    let min_amount = 0
    
    let quote_count = 0

    if(data.pending_aggregate.aggregate.sum.amount !== null){
      total_amount = data.pending_aggregate.aggregate.sum.amount
    }

    if(data.pending_aggregate.aggregate.max.amount !== null){
      max_amount = data.pending_aggregate.aggregate.max.amount
    }

    if(data.pending_aggregate.aggregate.avg.amount !== null){
      avg_amount = data.pending_aggregate.aggregate.avg.amount
    }

    if(data.pending_aggregate.aggregate.min.amount !== null){
      min_amount = data.pending_aggregate.aggregate.min.amount
    }

    if(data.pending_aggregate.aggregate.count !== null){
      quote_count = data.pending_aggregate.aggregate.count
    }

    let title = `Current Month Pending Sales for ${props.supplier}`
    if(props.month !== the_month){
      title = `Selected Month Pending Sales for ${props.supplier}`
    }

    return (
      <Card>
        <ZTitle>{title}</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="primary">{`quotes: ${quote_count}`}</Typography>
          <Typography>{`max quote: $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          <Typography>{`min quote: $ ${min_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );
  }


  if(typeof props.year !== 'undefined'){
    console.log("pending sales in month")
    console.log(variables)
    console.log(data);
    //let index = data.zeus_monthly_pending_sales_stats.length -1;
    //let current_month = data.zeus_monthly_pending_sales_stats[index]
    let current_month = data.zeus_monthly_pending_sales_stats[0]

    let total_amount = 0
    let max_amount = 0
    let min_amount = 0
    let quote_count = 0

    if(typeof current_month !== 'undefined'){
      total_amount = current_month.total_amount
      max_amount = current_month.max_amount
      min_amount = current_month.min_amount
      quote_count = current_month.quote_count
    }

    let title = 'Current Month Pending Sales'
    if(props.month !== the_month){
      title = 'Selected Month Pending Sales'
    }

    return (
      <Card>
        <ZTitle>{title}</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="primary">{`quotes: ${quote_count}`}</Typography>
          <Typography>{`max quote: $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          <Typography>{`min quote: $ ${min_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );
  }


  if(typeof props.funnel !== 'undefined'){
    console.log("pending sales in funnel")
    console.log(variables)
    console.log(data);

    //let index = data.zeus_funnel_pending_sales_stats.length -1;
    let current_funnel = data.zeus_funnel_pending_sales_stats[0]
    
    let total_amount = current_funnel.total_amount
    let max_amount = current_funnel.max_amount
    let min_amount = current_funnel.min_amount
    
    let quote_count = current_funnel.quote_count
    return (
      <Card>
        <ZTitle>Funnel: Pending Sales</ZTitle>
          <div className={classes.info}>
          <Typography component="p" variant="h6">
            {`$ ${total_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          </Typography>
          <Typography color="primary">{`quotes: ${quote_count}`}</Typography>
          <Typography>{`max quote: $ ${max_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          <Typography>{`min quote: $ ${min_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Typography>
          </div>
      </Card>
    );
  } else {
    return (
      <Card>
        <ZTitle>Current Month Pending Sales</ZTitle>
          <Typography component="p" variant="h4">
            NA
          </Typography>
          <Typography color="primary">NA</Typography>
          <Typography>NA</Typography>
          <Typography>NA</Typography>
      </Card>
    );    
  }

}

export default ZPendingSalesStatsBox;

/*
      <div>
        <Link color="primary" href="#">
          Do something
        </Link>
      </div>

              <Typography>{`average time to sale: ${avg_time_to_accept}`}</Typography>
        <Typography>{`max time to sale: ${max_time_to_accept}`}</Typography>
        <Typography>{`min time to sale: ${min_time_to_accept}`}</Typography>
*/