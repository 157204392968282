import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

/*
import { useQuery } from '@apollo/client';
import { getFunnelsQuery } from 'queries';
import { getClient,  getQuotesForClient } from 'queries';
import { addClient, updateClient } from 'mutations';
*/

import { useQuery, useMutation } from 'urql';
import { getFunnelsQuery, getClient,  getQuotesForClient } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReactTooltip from 'react-tooltip';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';

import ZMSalesChart from 'components/charts/ZMSalesChart';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZSalesToDateBox from 'components/boxes/ZSalesToDateBox';// ZSalesToDate
import ZSalesLostBox from 'components/boxes/ZSalesLostBox';//ZSalesLostBox
import ZMSalesDeltaChart from 'components/charts/ZMSalesDeltaChart';//ZMSalesDeltaChart
import ZSalesStatsBox from 'components/boxes/ZSalesStatsBox';// ZSalesStatsBox
import ZSalesTimeStatsBox from 'components/boxes/ZSalesTimeStatsBox';// ZSalesTimeStatsBox
import ZPendingSalesStatsBox from 'components/boxes/ZPendingSalesStatsBox';// ZPendingSalesStatsBox
import ZQuotationsPanel from 'components/panels/ZQuotationsPanel';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

////////////////////////////

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////////

const ZSalesFunnelPanel = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [funnel, setFunnel] = React.useState('2021 Q3 Pipeline');
  const [expanded, setExpanded] = React.useState(false);
  const [filter, setFilter] = React.useState({items:[]})

  const handleExpandClick = (event, params) => {
    console.log('handleExpandClick')
    console.log('params')
    console.log(params)
    console.log('event')
    console.log(event)

    if(typeof params !== 'undefined'){
      console.log('expand with filter')
      setFilter(params)
    } else {
      console.log('expand w/o filter')
      setFilter({items:[]})
    }

    setExpanded(!expanded);    
  };

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const getQuarter = (d) => {
    d = d || new Date(); // If no date supplied, use today
    //var q = [4,1,2,3];
    var q = [1,2,3,4];
    return q[Math.floor(d.getMonth() / 3)];
  }

  const computeFunnel = () => {
    var now = new Date()
    var year = now.getFullYear()
    var quarter = getQuarter()
    console.log("quarter: "+ quarter)
    var fff = year +" Q"+ quarter +" Pipeline";
    //setFunnel(fff)
    console.log("pipeline: "+ fff)
    return fff;
  }

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  const this_year = new Date().getFullYear();
  const last_year = this_year -1;

  /*
  var { loading, error, data }  = useQuery(getFunnelsQuery);
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */


  const [ result, reexecuteQuery ]  = useQuery({
    query: getFunnelsQuery,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;


  return (
    <div>
      <Card>
        <CardActionArea>
          <Paper>
            <Container fluid>
              <Row>
                <Col xs={3}>
                <div>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-funnel-native-simple">Funnel</InputLabel>
                    <Select
                      native
                      onChange={(event)=>{
                        let funnel = event.target.value
                        console.log()
                        setFunnel(funnel)
                      }}
                      inputProps={{
                        name: 'funnel',
                        id: 'outlined-funnel-native-simple',
                      }}                      
                      value={funnel}
                      label="Funnel"
                    >
                      {
                        data.zeus_sales_document.map((f)=>{
                          return (
                            <option key={f.funnel} value={f.funnel}>{f.funnel}</option>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <ZSalesToDateBox key='funnel_sales_total' funnel={funnel}                     
                    onClick={(event)=>{
                      console.log('expand')
                      handleExpandClick(event,{
                        items: [{ columnField: 'status', operatorValue: 'contains', value: 'accepted' }],
                      })
                    }} />
                </Col>
                <Col xs={3}>
                  <ZPendingSalesStatsBox id='funnel_sales_lost' funnel={funnel}                     
                    onClick={(event)=>{
                      console.log('expand')
                      handleExpandClick(event,{
                        items: [{ columnField: 'status', operatorValue: 'contains', value: 'pending' }],
                      })
                    }} />
                </Col>
                <Col xs={3}>
                  <ZSalesLostBox id='funnel_loss_stats' funnel={funnel} 
                    onClick={(event)=>{
                      console.log('expand')
                      handleExpandClick(event, {
                        items: [{ columnField: 'status', operatorValue: 'contains', value: 'closed' }],
                      })
                    }} 
                  />
                </Col>
                <Col xs={3}>
                  <ZSalesTimeStatsBox id='funnel_time_sales_stats' funnel={funnel} />
                </Col>
              </Row>
              <Row><br/></Row>
              <CardActions disableSpacing>       
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show quotations in funnel"
                  data-tip="show quotations for funnel"
                  data-for="funnel_box_tooltip" 
                >
                  <ExpandMoreIcon />
                </IconButton>           
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Row>
                  <Col xs={12}>
                    <ZQuotationsPanel id='funnel_quotations' funnel={funnel} filter={filter} />
                  </Col>
                </Row>
              </Collapse>
            </Container>
          </Paper>
        </CardActionArea>
      </Card>
    <ReactTooltip id="funnel_box_tooltip"/>
    </div>
  )
}

export default ZSalesFunnelPanel