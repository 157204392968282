import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan, purple, lightBlue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getSensorsForGateway } from 'queries_urql';
import { subscribeToolKitsForNode } from 'subscriptions_urql';
import { updateSensor } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import ReactTooltip from 'react-tooltip';

import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  sensor_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: red[100], //red
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100],   //green
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  parent: {}

}
*/

const styles = mergeStyleSets();

const ZGatewaysList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  //const [routeId, storeRouteId] = useLocalStorage('route_id', '');
  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    serverity: 'info',
    error: false,
  });

  const [state, setState] = React.useState({
    activeEntry: undefined,
    showBookingDialog: false,
    showCalendarCallout: false,
  });
  
  //////////////

  let rows = []

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  let columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/gateway/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 130,
      editable: false,
      hide: false,
    },
    {
      field: 'alias',
      title: 'Alias',
      headerName: 'Alias',
      minWidth: props.aliasW ?? 180,
      editable: true,
      hide: false,
    },  
    {
      field: 'sensor_count',
      title: 'Sensors',
      headerName: 'Sensors',
      minWidth: 180,
      editable: false,
      hide: false,
      valueGetter: (params) => {
        return params.row.sensors_aggregate.aggregate.count
      },
    },    
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 500,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'parent',
      title: 'Parent',
      headerName: 'Parent',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.parent){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let path = generatePath('/client_node/:id', {
                      id: params.row.parent.id}
                      )
                    history.push(path)
                  }}     
                >
                  {params.row.parent.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      }
    },
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];
  
  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  let headerMenu = (<div></div>)
  if(props.headerMenu){
    headerMenu = (
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorHeader}
          keepMounted
          open={Boolean(anchorHeader)}
          onClose={handleCloseHeader}
        >
          <MenuItem onClick={handleCloseHeader}>New Kit</MenuItem>
          <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
          <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
        </Menu>
      </div>  
    )
  } 

  let gateway_id = null;
  let skip_query = false;
  let skip_sub = true;

  if(props.static !== undefined){
    skip_query = true;
  } else {
    gateway_id = props.gateway.id
  }

  if(props.realtime !== undefined || props.live !== undefined){
    skip_sub = true;
  }

  let variables = {
    gateway_id
  }
  
  /*
  if(props.parent.node_type === undefined){
    let id = props.parent.id
    variables = {
      id
    }
  }
  */

  console.log(props)

  if(props.data){
    rows = props.data
  }

  if(props.items){
    rows = props.items
  }

  const [ result, reexecuteQuery ] = useQuery({
    query: getSensorsForGateway,
    variables: { gateway_id },
    pause: skip_query,
  });
  
  const [updateSensorResult, updateFunction] = useMutation(updateSensor);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZSensorsList::handleCellEditCommit');
      console.log(id, field, value)
      var _set = {}
      _set[field] = value
      console.log('variables')
      console.log({ id: id, _set })
      const createdOn = moment().format()

      updateFunction( { id: id, user_id: userData.id, createdOn: createdOn, _set } ).then((result)=>{
        if(result.error){
          console.log(result.error)
          setAlertState({...alertState, message: `ERROR: ${result.error}`, severity: 'error', open: true})

        } else {
          console.log("successfully updated sensor")
          setAlertState({...alertState, message: "successfully updated sensor", severity: 'info', open: true})
        }
      })
    },
  [updateFunction]);

  /*
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };
 
  const [res] = useSubscription({ query: subscribeToolKitsForNode, variables: variables, pause: skip_sub, }, handleSubscription); 
  */

  const handleClickBookingOpen = () => {
    console.log("ZSensorsList::handleClickBookingOpen->open booking dialog wizard")
    setState({...state, showBookingDialog: true});
    console.log("ZSensorsList::handleClickBookingOpen == "+ state.showBookingDialog);
  };
  
  const handleBookingClose = () => {
    setState({...state, showBookingDialog: false});
  };

  const calcRestrictedDates = () =>{
    let restrictedDates = []
    console.log('callout')
    console.log(state.activeEntry)
    state.activeEntry.bookings.forEach((elem)=>{
      //console.log(elem)
      let d = new Date(elem.theDate);
      console.log(d)
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }
  ////////////////////
  
  return (
    <div>
      {state.showCalendarCallout && (
        <Callout
          className={styles.callout}
          role="dialog"
          gapSpace={0}
          onDismiss={()=>{
            setState({...state, showCalendarCallout: false});
          }}
          setInitialFocus
        >
          <Text as="h4" block variant="xLarge" className={styles.title}>
            {`Bookings for ${state.activeEntry.name}`}
          </Text>
          <ZFluentCalendar 
            restrictedDates={calcRestrictedDates()}
            target='#job-data-grid'
          />
        </Callout>
      )}
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="jobs" className={classes.routes_avatar}>
              <NetworkCheckIcon />
            </Avatar>
          }      
          action={props.action}          
          title={props.title ? props.title : 'Sensors'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper id='sensor-data-grid' className={classes.sensor_row}>
            <DataGrid autoHeight
              rows={rows}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[5]}
              density="compact"
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  negative: params.row.status !== 'active',
                  positive: params.row.status === 'active',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={props.editable ? handleCellEditCommit : ()=>{}}
              //onRowClick={(params, evt) => {
              //  let job = params.row;
              //  console.log("selected job")
              //  console.log(job)
              //  setState({...state, activeEntry: job, showCalendarCallout: true});
              //}}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="gateways_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZGatewaysList;
