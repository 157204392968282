import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { v4 as uuidv4 } from 'uuid';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { updateJobScheduled, addJobboardSchedules, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { uuid } from 'uuidv4';
import moment from 'moment';
import ZUserAutocomplete from 'components/core/ZUserAutocomplete';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  snackbarErrorStyle: {
    backgroundColor: "lightred"
  },
  snackbarSuccessStyle: {
    backgroundColor: "lightgreen"
  },  
}));

//////////////////////

const ZSetJobScheduledButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  //const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    //tech: props.job.technician ?? undefined,
    tech: undefined,
    open: false,
    startDate: new Date(),
    endDate: new Date(),
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });

  console.log('props')
  console.log(props)

  const [updateJobResult,     updateJobFunction]     = useMutation(addJobboardSchedules);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    //setOpen(false)
    setState({...state, open: false})
  }

  const handleUserChange = (event,item) => {
    console.log('setting tech')
    console.log(item)
    setState({...state, tech_id: item.id, tech: item})
  }  

  let job_id = null;
  if(props.job){
    job_id = props.job.id
  }


  const handleConfirm = () => {
    let user_id = userData.id;
    let span_id = uuidv4();
    let today = moment(state.startDate);
    
    let endDate = moment(state.endDate);
    endDate.add(1, 'days');

    let modifiedOn = moment().format();

    let objects = []

    while(today.isBefore(endDate)){
      objects.push({
        span_id: span_id,
        theDate: today.format(),
        user_id: user_id,
        job_id: job_id,
        tech_id: state.tech.id,
        description: state.description,
      })
      today.add(1, 'days')
    } 

    let variables = { 
      id: job_id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: job_id,
        name: "job scheduled",
        description: `${userData.alias} updated job board entry with start date ${state.startDate} and end date ${state.endDate}`,
      },
      _set: {
        scheduled: {
          startDate: state.startDate,
          endDate: state.endDate
        },
        modifiedOn: modifiedOn,
      },
      objects: objects,
    }

    console.log(variables)

    updateJobFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        insertNotificationFunction({object: {
          name: `Job ${props.job.name} scheduled`,
          description: `${userData.alias} scheduled Job ${props.job.name} on the Zeus Calendar`,
          role: 'admin',
        }}); 

        setAlertState({
          open: true,
          message: 'Successfully scheduled job',
        })
      }

    })
  }


  const handleConfirm_1 = () => {
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: job_id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: job_id,
        name: "job scheduled",
        description: `${userData.alias} updated job board entry with start date ${state.startDate} and end date ${state.endDate}`,
      },
      _set: {
      scheduled: {
        startDate: state.startDate,
        endDate: state.endDate
      },
      modifiedOn: modifiedOn,
    }}

    console.log(variables)

    updateJobFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          open: true,
          message: 'Successfully scheduled job',
        })
      }

    })
  }

  ////////////////

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="scheduling_alert"
        onClose={()=>{
          setAlertState({...alertState, open: false, message: '', error: false})
        }}
        ContentProps={alertState.error === true      
        ? 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarErrorStyle
          }
        : 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarSuccessStyle
          }
        }
        message={alertState.message}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alertState, open: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Schedule Job</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="startDate"
                        value={state.startDate}
                        label="Start Date"
                        onChange={(value) =>{
                          setState({...state, startDate: value})
                          console.log("state->"+ JSON.stringify(state));
                        }} 
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="endDate"
                        value={state.endDate}
                        label="End Date"
                        onChange={(value) =>{
                          setState({...state, endDate: value})
                          console.log("state->"+ JSON.stringify(state));
                        }} 
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />                    
                    </Grid>
                  </MuiPickersUtilsProvider> 
                </div>   
                <br/>
                <ZUserAutocomplete label={props.label} onChange={handleUserChange}/>
                <br/>
                <TextField
                  className={classes.descriptionField}
                  id="details"
                  label="Details"
                  multiline
                  rows={4}
                  onChange={(event) =>{
                    setState({...state, description: event.target.value});
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
        onClick={(event) => { 
          console.log("Schedule Job Dates button - clicked")
          //setOpen(true)
          setState({...state, open: true})
        }}     
        disabled={props.disabled}                 
      >
        Schedule Job Dates
      </Button>
    </div>
  )
}

export default ZSetJobScheduledButton;

/*
    <div>
      <Collapse in={alertState.open}>
        <Alert severity="error">
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={12000}
              open={open}
              key="set_job_closed"
              onClose={()=>{
                setOpen(false)
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={
                <div>
                  <TextField
                    className={classes.nameField}
                    autoFocus
                    //margin="dense"
                    id="startDate"
                    type="date"
                    defaultValue={new Date()}
                    label="Start Date"
                    onChange={(event) =>{
                      setState({...state, startDate: event.target.value})
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                  />   
                  <br/>
                  <TextField
                    className={classes.nameField}
                    autoFocus
                    //margin="dense"
                    id="endDate"
                    type="date"
                    defaultValue={new Date()}
                    label="End Date"
                    onChange={(event) =>{
                      setState({...state, endDate: event.target.value})
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                  />   
                </div>                 
              }              
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleConfirm}>
                    Submit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setOpen(false)
                    }}
                  >
                    No
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
              onClick={(event) => { 
                setOpen(true)
              }}                     
            >
              Schedule Job Dates
            </Button>
          </div>
        </Router>
      </strong>
    </div>
*/

