import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getTool,  getQuotesForClient, getJobBoardEntry } from 'queries_urql';
import { addClient, updateJobBoardEntry, updateJobBoardEntryStatus, addJobPurchaseOrder } from 'mutations_urql';
import { subscribeJobBoardEntry } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import TimelineIcon from '@material-ui/icons/Timeline';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';//OpenIcon
import SnoozeIcon from '@material-ui/icons/Snooze';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZScheduleList from 'components/lists/ZScheduleList';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';// ZAttachmentsList
import ZInvoicesList from 'components/lists/ZInvoicesList';//ZInvoicesList
import ZToolKitsList from 'components/lists/ZToolKitsList';// ZToolKitsList
import ZToolsList from 'components/lists/ZToolsList';// ZToolsList
import ZPurchaseOrdersList from 'components/lists/ZPurchaseOrdersList';//ZPurchaseOrdersList
import ZJobBoardEntryBox from 'components/boxes/ZJobBoardEntryBox';// ZJobBoardEntryBox
import ZAddJobPurchaseOrderDialog from 'components/dialogs/ZAddJobPurchaseOrderDialog';

import JobBoardBloC from 'blocs/JobBoardBloC';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import { DatePicker } from  'react-responsive-calendar-picker'
import  'react-responsive-calendar-picker/dist/index.css';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';


import ReactTooltip from 'react-tooltip';
import ZAddAnnotationDialog from './dialogs/ZAddAnnotationDialog';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZBreadCrumbs from 'components/core/ZBreadCrumbs';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

//////////////////////////////////////////////

const ZJobBoardEntryView = (props) => {

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
  
  const classes = useStyles();
  let { id } = useParams();

  const [state, setState] = React.useState({
    open: false,
    showPODialog: false,
    showAnnotationDialog: false,
    showBookingDialog: false,
    message: '',
    action: null,
  });

  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [tabIndex, setTabIndex] = React.useState(0); //tabIndexAdmin
  const [tabIndexAdmin, setAdminTabIndex] = React.useState(0);

  const [showDialog, setShowDialog] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    severity: 'info',
    open: false,
    message: '',
  });
  const [lat, setLat] = React.useState(null);
  const [lng, setLng] = React.useState(null);
  //const [showBookingDialog, setShowBookingDialog] = React.useState(false);
  //const [showAnnotationDialog, setShowAnnotationDialog] = React.useState(false);
  //const [showPODialog, setShowPODialog] = React.useState(false);

  let row = undefined;
  let toolkits = []
  let tools = []
  let tools_booked = []
  let toolnodes_booked = []
  let jobs = []
  let dates = {
    checkin: new Date(),
    checkout: new Date(),
  }

  console.log(`ZJobBoardEntryView->id->${id}`);

  const handleAddBooking = () => {
    console.log('handleAddBooking');
    setState({...state, showBookingDialog: true})
    //setShowBookingDialog(true);
  }

  const handleBookingClose = () => {
    setState({...state, showBookingDialog: false})
    //setShowBookingDialog(false);
  };

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false})
    //setShowAnnotationDialog(false);
  }

  const handleAdminTabChange = (event, tabIndexAdmin) => {
    setAdminTabIndex(tabIndexAdmin);
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleSetDates = () => {

  }
   
  
  const handleChangeStatusActive = () => {
    console.log('handleChangeStatusActive')
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: id,
        name: "job active",
        description: `${userData.alias} updated job board entry set status active ${modifiedOn}`,
      },
      _set: {
      status: 'active',
      modifiedOn: modifiedOn,
    }}

    console.log(variables)

    updateJobStatusFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          severity: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          severity: 'success',
          open: true,
          message: 'Successfully updated job status',
        })
      }
      setState({...state, open: false})
    })       
  }

  const handleChangeStatusCompleted  = () => {
    console.log('handleChangeStatusActive')
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: id,
        name: "job completed",
        description: `${userData.alias} updated job board entry set status completed ${modifiedOn}`,
      },
      _set: {
      status: 'completed',
      completedOn: modifiedOn,
      modifiedOn: modifiedOn,
    }}

    console.log(variables)

    updateJobStatusFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          severity: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          severity: 'success',
          open: true,
          message: 'Successfully updated job status',
        })
      }
      setState({...state, open: false})
    })       
  } 

  const calcRestrictedDates = (elements) =>{
    let restrictedDates = []
    console.log('callout')
    //console.log(row)
    elements.forEach((elem)=>{
      let d = moment(elem.theDate).local()
      d = d.add(1, 'day').toDate()
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  const handleMuteUpdateStatus = () => {
    console.log('last_notification stamp')
    let key = data.id;
    if(data.id in userData){
      let x = userData[data.id];
      if('last_notification' in x){
        x.last_notification = moment().unix(); // mute notifications for 10 minutes
        let z = {...userData}
        z[key] = x
        setUserData(z);
      } else {
        let z = {...userData}
        z[key] = {last_notification: moment().unix()}
        setUserData(z)
      }
    } else {
      let z = {...userData}
      z[key] = {last_notification: moment().unix()}
      setUserData(z)
    }    
    console.log('userData')     
    console.log(userData)  
    setState({...state, open: false})      
  }

  const [updateJobResult, updateJobFunction] = useMutation(updateJobBoardEntry);
  const [updateJobStatusResult, updateJobStatusFunction] = useMutation(updateJobBoardEntryStatus);//updateJobBoardEntryStatus

  //useQuery({query: getJobBoardEntry,variables: { id },});

  const handleSubscription = (previousData, response) => {
    console.log('ZJobBoardEntryView->handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_jobboard_entry_by_pk
    //return response.zeus_jobboard_entry_by_pk;
    return response;
  };
 
  console.log('ZJobBoardEntryView setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeJobBoardEntry,
    variables: { id },
    pause: !window.navigator.onLine
  }, handleSubscription); 

  console.log('ZJobBoardEntryView setting up offline query')
  const [ queryResult, reexecuteQuery ] = useQuery({
    query: getJobBoardEntry,
    variables: { id },
    pause: window.navigator.onLine,
  });    
  
  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }

  console.log('ZJobBoardEntryView->data')
  console.log(data);

  

  console.log('ZJobBoardEntryView->subResult')
  console.log(subResult);

  console.log('ZJobBoardEntryView->queryResult')
  console.log(queryResult);

  let UpdateStatus = (
    <div>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={12000}
              open={state.open}
              key="set_job_active"
              onClose={()=>{
                setState({...state, open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={state.message}
              action={
                <div>
                  {state.action !== null ??
                    <Button className={classes.button} color="default" variant="contained" onClick={state.action}>
                      Yes
                    </Button>
                  }
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                    {state.action !== null ? 'No' : 'Close'}
                  </Button>
                  {state.action !== null ??
                    <Button className={classes.button} color="default" variant="contained" startIcon={<SnoozeIcon />} onClick={handleMuteUpdateStatus}>
                      Mute
                    </Button>  
                  }                
                </div>
              }
            />
          </div>
        </Router>
      </strong>
    </div>    
  )


  const runJobStatusLogic = () =>{
    var bloc = new JobBoardBloC(row)
    console.log('JobBoardBloC');
    console.log(row);

    console.log('JobBoardBloC -> testing active and testing status');
    if(bloc.isActive() && row.status === 'pending'){
      console.log('JobBoardBloC isActive and status==pending')
      console.log('JobBoardBloC -> job status is still pending but enough info to be active, changing status')
      setState({...state, action: null, open: true, message: 'Job status is still pending but has enough info to be active, would you like to change status to active?'})  
      return;
    }

    console.log('JobBoardBloC -> testing validPO and testing status')
    if(!bloc.isValidCustomerPO() && row.status === 'active'){
      console.log('JobBoardBloC notValidCustomerPO and status==active') 
      console.log('JobBoardBloC -> job status is still active but customer PO has expired, asking to set to completed')
      setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is still active but Customer PO has expired, would you like to change status to completed?'})       
      return;
    }

    setState({...state, action: handleChangeStatusActive, open: true, message: 'Job status is up to date'}) 

    /*
    console.log('JobBoardBloC -> testing validPO and testing status')
    if(!block.isInvoiced() && row.status === 'active'){
      console.log('JobBoardBloC not invoiced and status==active') 
      console.log('JobBoardBloC -> job status is still active but not invoiced ')
      setTimeout(()=>{
        console.log('JobBoardBloC -> showing status update dialog')
        setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is active with a valid Customer PO, but customer has not been invoiced.'})
      }, 3000);         
    }      
    */
  };


  const runJobStatusLogic_1 = React.useCallback(
    (row)=>{
      var bloc = new JobBoardBloC(row)
      console.log('JobBoardBloC');
      console.log(row);
  
      console.log('JobBoardBloC -> testing active and testing status');
      if(bloc.isActive() && row.status === 'pending'){
        console.log('JobBoardBloC isActive and status==pending')
        console.log('JobBoardBloC -> job status is still pending but enough info to be active, changing status')
        setTimeout(()=>{
          console.log('JobBoardBloC -> showing status update dialog')
          setState({...state, action: handleChangeStatusActive, open: true, message: 'Job status is still pending but has enough info to be active, would you like to change status to active?'})
        }, 3000);    
      }
  
      console.log('JobBoardBloC -> testing validPO and testing status')
      if(!bloc.isValidCustomerPO() && row.status === 'active'){
        console.log('JobBoardBloC notValidCustomerPO and status==active') 
        console.log('JobBoardBloC -> job status is still active but customer PO has expired, asking to set to completed')
        setTimeout(()=>{
          console.log('JobBoardBloC -> showing status update dialog')
          setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is still active but Customer PO has expired, would you like to change status to completed?'})
        }, 3000);         
      }

      /*
      console.log('JobBoardBloC -> testing validPO and testing status')
      if(!block.isInvoiced() && row.status === 'active'){
        console.log('JobBoardBloC not invoiced and status==active') 
        console.log('JobBoardBloC -> job status is still active but not invoiced ')
        setTimeout(()=>{
          console.log('JobBoardBloC -> showing status update dialog')
          setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is active with a valid Customer PO, but customer has not been invoiced.'})
        }, 3000);         
      }      
      */
    },
  [row]);

  React.useEffect(()=>{
    if(!row) return;
    var bloc = new JobBoardBloC(row)
    console.log('JobBoardBloC');

    console.log('JobBoardBloC -> testing active and testing status');
    if(bloc.isActive() && row.status === 'pending'){
      console.log('JobBoardBloC isActive and status==pending')
      let key = row.id;
      if(key in userData){
        console.log('key in userData')
        let settings = userData[key];
        if('last_notification' in settings){
          let last_notification = settings.last_notification;
          console.log('last_notification');
          console.log(last_notification);

          let now = moment().unix();
          let diff = now - last_notification;
          if(diff > (20 * 60)){
            console.log('JobBoardBloC -> job status is still pending but enough info to be active, changing status')
            setTimeout(()=>{
              console.log('JobBoardBloC -> showing status update dialog')
              setState({...state, action: handleChangeStatusActive, open: true, message: 'Job status is still pending but has enough info to be active, would you like to change status to active?'})
            }, 3000);  
          } else {
            console.log('JobBoardBloC -> skipping notification for this job board entry');
            console.log(settings);
            console.log(`JobBoardBloC -> ${(20 * 60) - diff} seconds until mute off`)
          }
        } else {
          console.log('JobBoardBloC -> job status is still pending but enough info to be active, changing status')
          setTimeout(()=>{
            console.log('JobBoardBloC -> showing status update dialog')
            setState({...state, action: handleChangeStatusActive, open: true, message: 'Job status is still pending but has enough info to be active, would you like to change status to active?'})
          }, 3000);            
        }
      } else {
        console.log('JobBoardBloC -> job status is still pending but enough info to be active, changing status')
        setTimeout(()=>{
          console.log('JobBoardBloC -> showing status update dialog')
          setState({...state, action: handleChangeStatusActive, open: true, message: 'Job status is still pending but has enough info to be active, would you like to change status to active?'})
        }, 3000);    
      }
    }

    console.log('JobBoardBloC -> testing validPO and testing status')
    if(!bloc.isValidCustomerPO() && row.status === 'active'){
      console.log('JobBoardBloC notValidCustomerPO and status==active')
      let key = row.id;
      if(key in userData){
        console.log('key in userData')
        let settings = userData[key];
        if('last_notification' in settings){
          let last_notification = settings.last_notification;
          console.log('last_notification');
          console.log(last_notification);

          let now = moment().unix();
          let diff = now - last_notification;
          if(diff > (20 * 60)){
            console.log('JobBoardBloC -> job status is still active but customer PO has expired, asking to set to completed')
            setTimeout(()=>{
              console.log('showing status update dialog')
              setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is still active but Customer PO has expired, would you like to change status to completed?'})
            }, 3000);  
          } else {
            console.log('JobBoardBloC -> skipping notification for this job board entry');
            console.log(settings);
            console.log(`JobBoardBloC -> ${(20 * 60) - diff} seconds until mute off`)
          }
        } else {
          console.log('JobBoardBloC -> job status is still active but customer PO has expired, asking to set to completed')
          setTimeout(()=>{
            console.log('JobBoardBloC -> showing status update dialog')
            setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is still active but Customer PO has expired, would you like to change status to completed?'})
          }, 3000);            
        }
      } else {
        console.log('JobBoardBloC -> job status is still active but customer PO has expired, asking to set to completed')
        setTimeout(()=>{
          console.log('JobBoardBloC -> showing status update dialog')
          setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is still active but Customer PO has expired, would you like to change status to completed?'})
        }, 3000);         
      }
    }
  },[row])

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  
  console.log('ZJobBoardEntryView->data');
  row = data.zeus_jobboard_entry_by_pk;
  console.log(row);

  const bloc = new JobBoardBloC(row)

  const handleStatusUpdate = () => {
    if(bloc.isActive() && row.status === 'pending'){
      console.log('job status is still pending but enough info to be active, changing status')
      console.log('showing status update dialog')
      setState({...state, open: true})

    } else {
      setAlertState({...alertState, severity: 'info', open: true, message: 'Job status is currently up to date'})
    }
  }

  row.tools_used.forEach(element => {
    tools.push(element.tool)
  })

  row.toolnodes_used.forEach(element => {
    toolkits.push(element.node)
  })

  row.tools_booked.forEach(element => {
    tools_booked.push(element.tool)
  })

  row.toolnodes_booked.forEach(element => {
    toolnodes_booked.push(element.node)
  })

  if(row.scheduled){
    dates = {
      checkout: new Date(row.scheduled.startDate),
      checkin: new Date(row.scheduled.endDate),
    }
  }


  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          //setShowAnnotationDialog(true)
          setState({...state, showAnnotationDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addPOAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          //setShowPODialog({...showPODialog, open: true})
          setState({...state, showPODialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let toprow = (
    <Row>
      <Col xs={6}>      
        <ZJobBoardEntryBox id='entry_box' 
          entry={row}
          name={row.name} 
          description={row.description} 
          annotations={row.annotations}
          refresh={executeSubscription}
          statusUpdate={runJobStatusLogic}
          //statusUpdate={handleStatusUpdate}
          //showmap
          //scheduled={data.scheduled}
          fullBox
          showImages
          static
        />
      </Col>
      <Col xs={6}>
        { row.activities ?
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="jobboard_entry">
                  <TimelineIcon />
                </Avatar>
              }
              title='Timeline'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZActivitiesTimeline items={row.activities} />
            </Paper>  
          </Card> 
          : <div/>
        }  
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`job/${row.id}/attachments`} multiple static/>
        <Row><br/></Row>
        <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />           
      </Col>
    </Row>    
  )

  let activePOCount = 0;
  for(var po of row.purchaseOrders){
    if(po.status === 'active'){
      activePOCount++;
    }
  }

  let activePOsBadge = (<Badge badgeContent={activePOCount} color="primary"/>)

  /////////////////////
  return (
    <div>
      { UpdateStatus }
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="job_state_active"
        onClose={()=>setAlertState({...alertState, open: false})}
      >
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>      
      </Snackbar>

      <Container fluid>
        <ZAddJobPurchaseOrderDialog open={state.showPODialog} parent={row} job={row} onClose={()=>setState({...state, showPODialog: false})} />
        {toprow}
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Scheduled Dates" />
                  <Tab label="Tools Booked" />
                  <Tab label="ToolKits Booked" />
                  <Tab label="Toolkits Used" />
                  <Tab label="Tools Used" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={300} descriptionW={450} action={addNoteAction} parent={row} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZScheduleList key='schedule_panel' title='Scheduled Dates' descriptionW={500} action={<div></div>} parent={row} data={row.scheduled_dates} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    <ZToolsList key='tools_booked_panel' title='Tools Booked' descriptionW={350} parent={row} data={tools_booked} static/>
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box>
                    <ZToolKitsList key='toolkits_booked_panel' title='ToolKits Booked' nameW={340} descriptionW={500} action={<div></div>} parent={row} data={toolnodes_booked} static/>
                  </Box>
                )}
                {tabIndex === 4 && (
                  <Box>
                    <ZToolKitsList key='toolkits_panel' title='Toolkits Used' nameW={340} descriptionW={500} action={<div></div>} parent={row} data={toolkits} static/>
                  </Box>
                )}
                {tabIndex === 5 && (
                  <Box>
                    <ZToolsList key='tools_panel' title='Tools Used' descriptionW={380} action={<div></div>} parent={row} data={tools} static/>
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>        
        </Row>   
        <Row><br/></Row> 
        <Row>
          <Col xs={12}>
          { userRoles.includes('admin') &&
            <Paper>
              <Box>
                <Tabs value={tabIndexAdmin} onChange={handleAdminTabChange}>
                  <Tab icon={activePOsBadge} label="Purchase Orders" />
                  <Tab label="Invoices" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndexAdmin === 0 && (
                  <Box>
                    <ZPurchaseOrdersList key='purchase_orders_panel' descriptionW={300} action={addPOAction} client={row.client} parent={row} items={row.purchaseOrders} static/>
                  </Box>
                )}
                {tabIndexAdmin === 1 && (
                  <Box>
                    <ZInvoicesList key='invoices_panel' nameW={200} descriptionW={300} action={<div></div>} client={row.client} parent={data} items={row.invoices} static/>
                  </Box>
                )}
              </Box>
            </Paper>
          }
          </Col>        
        </Row>                     
      </Container>
    </div>
  )


}

export default ZJobBoardEntryView;


