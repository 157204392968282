import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getQuotation } from 'queries';
import { addQuotation, updateSalesDocument } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getQuotation } from 'queries_urql';
import { addQuotation, updateSalesDocument } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';


import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Link from '@material-ui/core/Link';

import Moment from 'react-moment';
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactTooltip from 'react-tooltip';

import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container'; //Container

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import LinearProgress from '@material-ui/core/LinearProgress';
import BallotIcon from '@material-ui/icons/Ballot';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZContactButton from 'components/buttons/ZContactButton';
import ZClientButton from 'components/buttons/ZClientButton'; //ZFileS3UploadButton
import ZFileS3UploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem'; //ZAnnotationInputMenuItem
import ZAnnotationInputMenuItem from 'components/core/ZAnnotationInputMenuItem';
import ZAddJobInputMenuItem from 'components/core/ZAddJobInputMenuItem'; // ZAddJobInputMenuItem

///////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  avatar_closed: {
    backgroundColor: red[500],
  },
  avatar_pending: {
    backgroundColor: blue[500],
  },
  avatar_accepted: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  isOpen: false
  handleClose: ()=>{}
  quotation: {}
}
*/

const ZQuotationDetailPane = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [anchorHeader, setAnchorHeader] = React.useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  const handlePaneHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = (event) => {
    console.log(event)
    setAnchorHeader(null);
  }

  //////////////////
  /*
  const { loading, error, data } = useQuery(getQuotation, {
    variables: { id: props.quotation.id },
    skip: skip_query,
  });
  const [updateFunction] = useMutation(updateSalesDocument);

  console.log('props')
  console.log(props)
  console.log("quotation data loaded")
  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) {
    console.log('ZQuotationDetailPane::ERROR')
    console.log(error.message);
    return (<div></div>);
  }
  if (skip_query) {
    console.log('skip_query')
    return (<div></div>)
  }
  */
  //////////////////
  console.log('props')
  console.log(props)

  let id = null
  let row = {}
  let skip_query = true;
  if(props.realtime || props.live){
    skip_query = false;
  }

  if(props.quotation !== null){
    id = props.quotation.id
    skip_query = false
  } 

  const [ result, reexecuteQuery ]  = useQuery({
    query: getQuotation,
    variables: { id },
    pause: skip_query,
  });
  const [updateFunction] = useMutation(updateSalesDocument);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return (<div></div>);
  if (props.quotation === null) return (<div></div>)

  //////////////////

  let avatar_class = classes.avatar
  let status_colour = "primary"

  if(!skip_query){
    row = data.zeus_sales_document_by_pk
  } else {
    row = props.quotation
  }

  if(row.status === 'closed'){
    avatar_class = classes.avatar_closed
    status_colour = "secondary"
  }

  if(row.status === 'pending'){
    avatar_class = classes.avatar_pending
    status_colour = ""
  }

  if(row.status === 'accepted'){
    avatar_class = classes.avatar_accepted
    status_colour = "primary"
  }

  let contactButton = (<div/>)
  let contact_name = ""
  let contact_id = ""

  if(row.contact !== null){
    contactButton = (<ZContactButton id={row.contact.id} title={row.contact.name} />)
  }

  const handleCreateJob = (
    <div>
      <ZAddJobInputMenuItem sales_document={data} />
    </div>
  )

  const handleAddFile = (
    <div>
      <ZFileS3UploadMenuItem sales_document_id={row.id} dirName={"quotations"} />
    </div>
  )

  const handleAnnotation = (
    <div>
      <ZAnnotationInputMenuItem sales_document_id={row.id} />
    </div>
  )

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <Divider variant="middle"/>
        <MenuItem>{handleCreateJob}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{handleAddFile}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{handleAnnotation}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem onClick={handleCloseHeader}>Close</MenuItem>
        <Divider variant="middle"/>
      </Menu>
    </div>  
  )

  return (
    <>
    <SlidingPane
      isOpen={props.isOpen}
      title="Quotation Detail"
      subtitle={props.quotation.funnel}
      width="800px"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        //setDetailPane(false);
        props.handleClose()
      }}
    >
      <Card variant="outlined">
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="quotation" className={avatar_class}>
                <BallotIcon />
              </Avatar>
            }
            action={
              <Fab
                data-tip="quotation actions"
                data-for="quotation_box_tooltip"
                //color="primary"
                aria-label="add"
                size="small"
                onClick={handlePaneHeaderClick}
              >
                <MoreVertIcon />
                { headerMenu }
              </Fab>
            }
            title={row.name}
            titleTypographyProps={{variant:'h4'}}
            subheader={  
              <div>
                <Typography>{row.funnel}</Typography>
                <ZClientButton id={row.client.id} title={row.client.name}/>
                {contactButton}
              </div>
            }
          />
          <Paper>
            <CardContent>
              <Container>
                <Chip color="primary" label={`Total $ ${ row.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` } />
                <Chip color={`${status_colour}`} variant="outlined" label={row.status} />
              </Container>
              <br/>
              <Typography variant="h6" component="h4">Details</Typography>
              <Paper>
                <Typography color="textSecondary">
                  Created &nbsp;
                  <Moment format="YYYY-MM-DD">
                    {row.createdOn}
                  </Moment>
                </Typography>
                <Typography color="textSecondary">Last Modified &nbsp;
                  <Moment format="YYYY-MM-DD">
                    {row.modifiedOn}
                  </Moment>
                </Typography>
                <Typography color="textSecondary">Close &nbsp;
                  <Moment fromNow>
                    {row.closedOn}
                  </Moment>
                </Typography>
                <Typography color="textSecondary">Originator: {row.originator}</Typography>
                <Typography color="textSecondary">Prepared By: {row.preparedBy}</Typography>
              </Paper>
              <br/>
              <Typography variant="h6" component="h4">Description</Typography>
              <Paper>
                <Typography paragraph color="textSecondary">{row.description}</Typography>
              </Paper>
              <Typography variant="h6" component="h4" >Notes</Typography>
              <Paper>
                <ZActivitiesTimeline items={row.annotations} />
              </Paper>
            </CardContent>  
          </Paper>  

          <CardActions disableSpacing>       
            <IconButton 
              data-tip="add note"
              data-for="quotation_box_tooltip" 
              aria-label="add note">
              <ShareIcon />
            </IconButton>
           
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show notes"
              data-tip="show notes"
              data-for="quotation_box_tooltip" 
            >
              <ExpandMoreIcon />
            </IconButton>           
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Notes</Typography>
              { row.annotations.map((note) => {
                return (
                  <div key={note.id}>
                    <Card>
                      <CardHeader>{note.name}</CardHeader>
                      <CardContent>{note.description}</CardContent>
                    </Card>
                  </div>             
                );
              }) }
            </CardContent>
          </Collapse>
        </CardActionArea>    
      </Card>
    </SlidingPane> 
    <ReactTooltip id="quotation_box_tooltip"/>
    </>   
  )
}

export default ZQuotationDetailPane;