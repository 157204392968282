import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan, yellow, orange, grey } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getSurveyStatsForClientQuery } from 'queries_urql';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import ZTitle from 'components/core/ZTitle';

import eventBus from 'utils/EventBus';

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data01 = [
  { x: 10, y: 30 },
  { x: 30, y: 200 },
  { x: 45, y: 100 },
  { x: 50, y: 400 },
  { x: 70, y: 150 },
  { x: 100, y: 250 },
];
const data02 = [
  { x: 30, y: 20 },
  { x: 50, y: 180 },
  { x: 75, y: 240 },
  { x: 100, y: 100 },
  { x: 120, y: 190 },
];

///////////////////////////////

/*
props:{
  items
}

*/

///////////////////////////////

const ZAssetSurveyTimeChart = (props) => {

  const theme = useTheme();

  let { id } = useParams();

  const COLORS = [green[200], green[100], yellow[100], orange[100], red[100], grey[200], grey[100]];

  let rows = [];

  console.log('ZAssetSurveyTimeChart->props')
  console.log(props)

  //////////////

  //rows = props.items.reverse();

  for(let row of props.items){

    if(row.severity === 'Good'){
      row.y = 1;
    }

    if(row.severity === 'Acceptable'){
      row.y = 2;
    }

    if(row.severity === 'Reportable'){
      row.y = 3;
    }

    if(row.severity === 'Unsatisfactory'){
      row.y = 4;
    }

    if(row.severity === 'Unacceptable'){
      row.y = 5;
    }

    row.x = row.theDate.split('T')[0];
    row.z = 100;
    rows.push(row);
  }

  /*
  if(rows.length < 24){
    
    let last_date = moment(rows[0].theDate);
    while(rows.length < 24){
      var a = moment().subtract(1, 'days')
      props.items.push({
        x: a.format('YYYY-MM-DD'),
        y: 0,
        z: 100,
      }) 
    }
  }
  */

  console.log(props)

  ////////////////////////  

  return (
    <ResponsiveContainer width="100%" height={props.height ? props.height : 400}>
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="x" name="date" />
        <YAxis type="number" dataKey="y" name="severity" domain={[1,5]}/>
        <ZAxis type="number" range={[100]} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        <Scatter name="Asset Condition" data={rows} fill="#82ca9d" line shape="diamond" />
      </ScatterChart>
    </ResponsiveContainer>
  );
}



export default ZAssetSurveyTimeChart;
