import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getContact } from 'queries';
import { addContact, updateContact } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getContact } from 'queries_urql';
import { addContact, updateContact } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Moment from 'react-moment';
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactTooltip from 'react-tooltip';

import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import BallotIcon from '@material-ui/icons/Ballot';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZQuotationsList from 'components/lists/ZQuotationsList';

///////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  isOpen: false
  handleClose: ()=>{}
  quotation: {}
}
*/

const ZContactDetailPane = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);

  let skip_query = true;
  if(props.isOpen){
    skip_query = false;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /*
  const { loading, error, data } = useQuery(getContact, {
    variables: { id: props.contact.id },
    skip: skip_query,
  });
  const [updateFunction] = useMutation(updateContact);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) return (<div></div>);
  if (skip_query) return (<div></div>)

  console.log("contact data loaded")
  console.log(data);
  */

  ////////////////
  let id = props.contact.id
  const [ result, reexecuteQuery ]  = useQuery({
    query: getContact,
    variables: { id },
    pause: skip_query,
  });
  const [updateFunction] = useMutation(updateContact);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return (<div></div>);
  if (skip_query) return (<div></div>)
  ////////////////

  return (
    <>
    <SlidingPane
      isOpen={props.isOpen}
      title="Contact Detail"
      subtitle={props.contact.email}
      width="800px"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        //setDetailPane(false);
        props.handleClose()
      }}
    >
      <Card variant="outlined">
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="contact" className={classes.avatar}>
                <BallotIcon />
              </Avatar>
            }
            action={
              <Fab
                data-tip="contact actions"
                data-for="contact_box_tooltip"
                //color="primary"
                aria-label="add"
                size="small"
                onClick={/* 
                  handleCardHeaderClick */
                  console.log("contact card header clicked")
                }
              >
                <MoreVertIcon />
                { /* headerMenu */ }
              </Fab>
            }
            title={data.zeus_contact_by_pk.name}
            titleTypographyProps={{variant:'h4'}}
            subheader={
              <div>
                <Typography>{data.zeus_contact_by_pk.email}</Typography>
                <Typography>{data.zeus_contact_by_pk.phone}</Typography>
              </div>
            }
          />
          <Paper>
            <CardContent>
              <Paper>
                <Typography>{data.zeus_contact_by_pk.description}</Typography>
              </Paper>
              <Paper>
                <ZActivitiesTimeline items={data.zeus_contact_by_pk.annotations} size={3} />
              </Paper>
              <Paper>
                <ZQuotationsList title='Pending Quotes' items={data.zeus_contact_by_pk.pending_quotations} />
              </Paper>
              <Paper>
                <ZQuotationsList title='Lost Quotes' items={data.zeus_contact_by_pk.lost_quotations} />
              </Paper>
              <Paper>
                <ZQuotationsList title='Accepted Quotes' items={data.zeus_contact_by_pk.accepted_quotations} />
              </Paper>
              <Paper>
                <ZQuotationsList title='Recent Quotes' items={data.zeus_contact_by_pk.quotations} />
              </Paper>
            </CardContent>  
          </Paper>  
          <CardActions disableSpacing>       
            <IconButton 
              data-tip="add note"
              data-for="contact_box_tooltip" 
              aria-label="add note">
              <ShareIcon />
            </IconButton>
           
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show notes"
              data-tip="show notes"
              data-for="client_box_tooltip" 
            >
              <ExpandMoreIcon />
            </IconButton>           
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Notes</Typography>
              { data.zeus_contact_by_pk.annotations.map((note) => {
                return (
                  <div key={note.id}>
                    <Card>
                      <CardHeader>{note.name}</CardHeader>
                      <CardContent>{note.description}</CardContent>
                    </Card>
                  </div>             
                )
              }) }
            </CardContent>
          </Collapse>
        </CardActionArea>    
      </Card>
    </SlidingPane> 
    <ReactTooltip id="contact_box_tooltip"/>
    </>   
  )
}

export default ZContactDetailPane;