import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { uuid } from 'uuidv4';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation } from 'urql';
import { getAllContacts } from 'queries_urql';
import { addContactForClient, updateContact, addContact } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';

import  ZWizard  from 'components/core/ZWizard';
import moment from 'moment';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

/*
props: {
  client
}
*/

//////////////////////////////////////////

const ZAddContactDialog = (props) => {
  const classes = useStyles();

  const [state, setState] = React.useState({})
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [address, setAddress] = React.useState({label: 'NA'})
  const [suite, setSuite] = React.useState('')
  const [region, setRegion] = React.useState({})

  const [description, setDescription] = React.useState('')
  const [geometry, setGeometry] = React.useState({})

  let has_client = false;
  let mutation = addContact;

  if(typeof props.client !== 'undefined'){
    has_client = true;
    mutation = addContactForClient;
    console.log("addContactForClient")
  }

  const handleReview = () => {
    //console.log("handleReview->address"+ JSON.stringify(address));
    //console.log("handleReview->address.description"+ JSON.stringify(address.value.description));
    const x = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      description: description.trim(),
      phone: phone.trim(),
      address: address,
      suite: suite.trim(),
      region: region,
    }
    console.log("handleReview->"+ JSON.stringify(x));
    setState(x) 
  }

  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      setRegion({province: province, country: country})
      setGeometry({ lat, lng })
      setAddress(place) 
    });    
  }

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    const guuid = uuid()
    const createdOn = moment().format()

    console.log(`id: ${guuid}`)
    address.suite = suite

    let variables = { 
      id: guuid,
      firstName: firstName.trim(), 
      lastName: lastName.trim(), 
      name: `${firstName.trim()} ${lastName.trim()}`,
      email: email.trim(),
      description: description.trim(), 
      //address: address,
      //region: region,
      status: 'active',
      createdOn: createdOn,
    }

    if(has_client){
      console.log("adding client")
      console.log(props.client)
      variables.client_id = props.client.id
      console.log(props.client.id)
    }

    console.log("creating contact")
    console.log(variables)

    //return;
    insertContactFunction(variables).then(result => {
      // The result is almost identical to `updateTodoResult` with the exception
      // of `result.fetching` not being set.
      // It is an OperationResult.
      console.log("insertContactFunction -> result")
      console.log(result)
      props.handleClose()
    });
  }

  //const [insertFunction] = useMutation(mutation);
  const [addContactResult, insertContactFunction] = useMutation(mutation);

  return(
    <div>
      <ZWizard 
        title="Create New Contact for Client"
        steps={['Enter Contact Details','Enter Contact Description']}
        open={props.open} 
        onClose={props.handleClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
      >
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  console.log("setFirstName->"+ JSON.stringify(event.target.value));
                  setFirstName(event.target.value);
                } }
                id="contactFirstName" 
                label="First Name" 
                variant="standard"
              />
              <TextField 
                onChange={(event) =>{
                  console.log("setLastName->"+ JSON.stringify(event.target.value));
                  setLastName(event.target.value);
                }}
                id="contactLastName" 
                label="Last Name" 
                variant="standard"
              />
            </Paper>   
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <TextField
                  id="contactEmail"
                  label="Email"
                  onChange={(event) =>{
                    console.log("setEmail->"+ JSON.stringify(event.target.value));
                    setEmail(event.target.value);
                  }} 
                />  
            </Paper> 
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <TextField
                  id="contactPhone"
                  label="Phone"
                  onChange={(event) =>{
                    console.log("setPhone->"+ JSON.stringify(event.target.value));
                    setPhone(event.target.value);
                  }} 
                />  
            </Paper> 
            <br/> 

          </Box>
        </Container>

        <Container maxWidth="sm">
          <Box>
            <div>
              <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                  <TextField
                    id="contactDescription"
                    label="Description"
                    multiline
                    rows={4}
                    onChange={(event) =>{
                      console.log("setDescription->"+ JSON.stringify(event.target.value));
                      setDescription(event.target.value);
                    }} 
                  />  
              </Paper>            
            </div>
          </Box>
        </Container>

        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Typography>{`Name: ${firstName} ${lastName}`}</Typography>
              <Typography>{`Email: ${email}`}</Typography>
              <Typography>{`Phone: ${phone}`}</Typography>
              <Typography>{`Description: ${description}`}</Typography>
              <Typography>{`Address: ${address.label}`}</Typography>
              <Typography>{`Suite: ${suite}`}</Typography>
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddContactDialog;

/*

          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"



                  <Box>
          <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Region</InputLabel>
            <Select
              labelId="region-select-label"
              id="region-simple-select"
              value={region}
              onChange={(event) =>{
                console.log("handleRegionChange->"+ JSON.stringify(event.target.value));
                setRegion(event.target.value);
              }}
            >
              <MenuItem value='AB'>AB</MenuItem>
              <MenuItem value='BC'>BC</MenuItem>
              <MenuItem value='SD'>SK</MenuItem>
            </Select>
          </FormControl>
          </Paper>    
        </Box>
*/