import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode } from 'queries_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CircularProgress from '@material-ui/core/CircularProgress';

import QRCode from "react-qr-code";
import GoogleMapReact from 'google-map-react';
import Marker from 'google-map-react';
import PlaceIcon from '@material-ui/icons/Place';

import L from 'leaflet';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  notes: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    height: '400px',
  },
});

//////////////////////////

const ZLocatorMapComponent = (props) => {
  const classes = useStyles();
  const mapRef = React.useRef(null);

  const [lat,  setLat]  = React.useState(null)
  const [lng,  setLng]  = React.useState(null)

  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      dropMarker(position.coords.latitude, position.coords.longitude)
      console.log(`ZLocatorMapComponent [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  },[setLat,setLng]);


  let dropMarkerCB = React.useCallback(()=>{

    console.log(`React.useCallback [${lat} , ${lng}]`)
    if(lat === null || lng === null) return
    
    mapRef.current = L.map(props.name || 'map').setView([lat,lng], props.zoom || 8);
    
    L.marker([lat, lng]).addTo(mapRef.current);

    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(mapRef.current);       
  },[lat,lng])


  let dropMarker = (x,y) => {
    console.log(`dropMarker [${x} , ${y}]`)
    if(lat === null || lng === null) return
    
    mapRef.current = L.map(props.name || 'map').setView([x,y], props.zoom || 8);
    
    L.marker([x, y]).addTo(mapRef.current);

    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(mapRef.current);      
  }

  //ref={mapRef}


  /*
  var map = L.map(props.name || 'map').setView([props.lat, props.lng], props.zoom || 8);
    
  L.marker([props.lat, props.lng]).addTo(map);

  var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);
  */

  return (
    <div id={props.name || 'map'} ef={mapRef} className={classes.map}></div>
  );
};

export default ZLocatorMapComponent;