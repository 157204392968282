import React from 'react';
import ReactDOM from 'react-dom/client';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

//import { Connector } from 'mqtt-react-hooks';

//import { AsyncStorage } from 'react-native';
//import AsyncStorage from '@react-native-async-storage/async-storage';
//import { persistCache } from 'apollo3-cache-persist';

//import { InMemoryCache } from '@apollo/client/core';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

///////////////////

//import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, withMsal } from "@azure/msal-react";
import {  msalConfig } from "authConfig";
import {  Config } from "config";

///////////////////

import { getIntrospectionQuery } from 'graphql';
import fetch from 'node-fetch'; // or your preferred request in Node.js
import * as fs from 'fs';
import { uuid } from 'uuidv4';

///////////////////
import { createClient as createWSClient } from 'graphql-ws';
import { createClient, Provider, dedupExchange, fetchExchange, subscriptionExchange } from 'urql';
import { makeOperation } from '@urql/core';

import { offlineExchange } from '@urql/exchange-graphcache';
import { retryExchange } from '@urql/exchange-retry';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { authExchange } from '@urql/exchange-auth';

///////////////////

import { 
getAllClients, getAllContacts, getAllAssets, getAllQuotations, getAllPurchaseOrders, getAllInvoices, getAllTools, getAllAssetSurveys,
getAllJobBoardEntries, getAllActivities, getAllAnnotations, getAllRoutes, getAllToolNodes, getAllToolUsage, getAllTickets
} from 'queries_urql';
import { refreshMutation } from 'mutations_urql';

///////////////////

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import ZSignInButton from "components/buttons/ZSignInButton";
import { initializeIcons } from '@fluentui/react/lib/Icons';

initializeIcons(/* optional base url */);

//////////////////////
/*
azure ubuntu linux server for zeus

ssh -i ~/azure/zeus.pem azureuser@20.104.192.196
ssh -i ~/azure/zeus.pem azureuserzeusprimac.canadacentral.cloudapp.azure.com/

graphql endpoint = http://zeusprimac.canadacentral.cloudapp.azure.com:8080/v1/graphql

*/

////////////////////
/*
const cache = new InMemoryCache();
persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

export const client = new ApolloClient({
  uri: "http://ec2-3-99-42-105.ca-central-1.compute.amazonaws.com:8080/v1/graphql",
  cache,
  headers: {
    "x-hasura-zeus-graphql-engine": "PrimacReliabilityEngineersDoitInWaves",
  }
});
*/

/*
const client = new ApolloClient({
  //uri: "https://hasura-react-todo.herokuapp.com/v1alpha1/graphql",
  uri: "http://ec2-3-99-42-105.ca-central-1.compute.amazonaws.com:8080/v1/graphql",
  cache: new InMemoryCache(),
  headers: {
    "x-hasura-zeus-graphql-engine": "PrimacReliabilityEngineersDoitInWaves",
  }
});
*/

///////////////////

/*
fetch("http://ec2-3-99-42-105.ca-central-1.compute.amazonaws.com:8080/v1/graphql", {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    variables: {},
    query: getIntrospectionQuery({ descriptions: false }),
  }),
})
  .then(result => result.json())
  .then(({ data }) => {
    fs.writeFile('./schema.json', JSON.stringify(data), err => {
      if (err) {
        console.error('Writing failed:', err);
        return;
      }
      console.log('Schema written!');
    });
  });

  */
///////////////////

let msalConf = null;

console.log(`${process.env}`)

if(process.env.NODE_ENV === "development"){
  console.log("process.env.NODE_ENV === development")
  msalConf = msalConfig.development;
}

if(process.env.REACT_APP_ENV === "production"){
  console.log("process.env.NODE_ENV === production")
  msalConf = msalConfig.production;
}

if(process.env.REACT_APP_ENV === "staging"){
  console.log("process.env.REACT_APP_ENV === staging")
  msalConf = msalConfig.staging;
}

console.log(`${msalConf}`);

const msalInstance = new PublicClientApplication(msalConf);

///////////////////

/*
window.addEventListener('load', () => {
  var status = document.getElementById("network_status");
  var log = document.getElementById("log");

  const updateOnlineStatus = (event) => {
    var condition = navigator.onLine ? "online" : "offline";

    status.className = condition;
    status.innerHTML = condition.toUpperCase();

    log.insertAdjacentHTML("beforeend", "Event: " + event.type + "; Status: " + condition);
  }

  window.addEventListener('online',  updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);
});
*/

const logout = () => {
  localStorage.removeItem('token');
}

const getToken = () => {
  const token = localStorage.getItem('token');
  return token
}

const retryOptions = {
  initialDelayMs: 5000,
  maxDelayMs: 30000,
  randomDelay: true,
  maxNumberAttempts: 64,
  retryIf: err => err && err.networkError,
};

const introspectedSchema = {
  __schema: {
    queryType: { name: 'Query', },
    mutationType: { name: 'Mutation', },
    subscriptionType: { name: 'Subscription', },
  },
};

const storage = makeDefaultStorage({
  idbName: 'primac-db', // The name of the IndexedDB database
  maxAge: 90, // The maximum age of the persisted data in days
});

console.log('storage')
console.log(storage)

const cacheExchange = offlineExchange({
  //introspectedSchema,
  keys: {
    zeus_client: data => data.id,
    zeus_contact: data => data.id,
    zeus_asset: data => data.id,
    zeus_asset_survey: data => data.id,
    zeus_sales_document: data => data.id,
    zeus_annotation: data => data.id,
    zeus_activity: data => data.id,
    zeus_attachment: data => data.id,
    zeus_jobboard_entry: data => data.id,
    zeus_supplier: data => data.id,
    zeus_product_line: data => data.id,
    zeus_product: data => data.id,

    zeus_tool_node: data => data.id,
    zeus_tool: data => data.id,

    zeus_tool_user_status: data => data.id,
    zeus_tool_user_booking: data => data.id,
    zeus_tool_user_location: data => data.id,

    zeus_ticket: data => data.id,

    zeus_asset_aggregate: () => null, 
    zeus_asset_aggregate_fields: () => null,

    zeus_attachments_aggregate: () => null,
    zeus_images_aggregate: () => null,

    attachments_aggregate: () => null,
    images_aggregate: () => null,

    zeus_route_aggregate: () => null,
    zeus_route_aggregate_fields: () => null,

    zeus_sales_document_aggregate: () => null, 
    zeus_sales_document_aggregate_fields: () => null,
    zeus_sales_document_sum_fields: () => null,
    zeus_sales_document_max_fields: () => null,
    zeus_sales_document_min_fields: () => null,

    zeus_attachment_aggregate: () => null,
    zeus_attachment_aggregate_fields: () => null,
  },
  storage,
  resolvers: {
    Query: {
      getClient: (_, args) => {
        console.log('getClient local resolver')
        return { 
        __typename: 'zeus_client', 
        id: args.id 
      }},
      getContact: (_, args) => ({ 
        __typename: 'zeus_contact', 
        id: args.id 
      }),
      getQuotation: (_, args) => {
        console.log('getQuotation local resolver')
        return { 
        __typename: 'zeus_sales_document', 
        id: args.id 
      }},
      getAsset: (_, args) => ({ 
        __typename: 'zeus_asset', 
        id: args.id 
      }),
      getSupplier: (_, args) => ({ 
        __typename: 'zeus_supplier', 
        id: args.id 
      }),
      getToolNode: (_, args) => ({ 
        __typename: 'zeus_tool_node', 
        id: args.id 
      }),
      getTool: (_, args) => ({ 
        __typename: 'zeus_tool', 
        id: args.id 
      }),
      getUsageForNode: (_, args) => ({ 
        __typename: 'zeus_tool_user_status', 
        id: args.id 
      }),
      getUsageForTool: (_, args) => ({ 
        __typename: 'zeus_tool_user_status', 
        id: args.id 
      }),
      getBookingForTool: (_, args) => ({ 
        __typename: 'zeus_tool_user_booking', 
        id: args.id 
      }),
      getBookingForNode: (_, args) => ({ 
        __typename: 'zeus_tool_user_booking', 
        id: args.id 
      }),
      getTicket: (_, args) => ({ 
        __typename: 'zeus_ticket', 
        id: args.id 
      }),
      getJobBoardEntry: (_, args) => ({ 
        __typename: 'zeus_jobboard_entry', 
        id: args.id 
      }),
      getUser: (_, args) => ({ 
        __typename: 'zeus_zeus_user', 
        id: args.id 
      }),
      getAnnotation: (_, args) => ({ 
        __typename: 'zeus_annotation', 
        id: args.id 
      }),
      getAttachment: (_, args) => ({ 
        __typename: 'zeus_attachment', 
        id: args.id 
      }),
      getImage: (_, args) => ({ 
        __typename: 'zeus_image', 
        id: args.id 
      }),
      getAssetSurvey: (_, args) => ({ 
        __typename: 'zeus_asset_survey', 
        id: args.id 
      }),
    },
  },
  updates: {
    /* ... */
    Mutation: {
      updateJobBoardEntry(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllJobBoardEntries }, data => {
          data.zeus_jobboard_entry.push(result.zeus_jobboard_entry);
          return data;
        });
      },
      updateClient(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllClients }, data => {
          data.zeus_client.push(result.zeus_client);
          return data;
        });
      },
      updateContact(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllContacts }, data => {
          data.zeus_contact.push(result.zeus_contact);
          return data;
        });
      },
      updateAnnotation(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllAnnotations }, data => {
          data.zeus_annotation.push(result.zeus_annotation);
          return data;
        });
      },
      updateAsset(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllAssets }, data => {
          data.zeus_asset.push(result.zeus_asset);
          return data;
        });
      },
      updateRoute(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllRoutes }, data => {
          data.zeus_route.push(result.zeus_route);
          return data;
        });
      },
      updateActivity(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllActivities }, data => {
          data.zeus_activity.push(result.zeus_activity);
          return data;
        });
      },
      updateQuotation(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllQuotations }, data => {
          data.zeus_sales_document.push(result.zeus_sales_document);
          return data;
        });
      },
      updateInvoice(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllInvoices }, data => {
          data.zeus_sales_document.push(result.zeus_sales_document);
          return data;
        });
      },
      updatePurchaseOrder(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllPurchaseOrders }, data => {
          data.zeus_sales_document.push(result.zeus_sales_document);
          return data;
        });
      },
      updateToolNode(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllToolNodes }, data => {
          data.zeus_tool_node.push(result.zeus_tool_node);
          return data;
        });
      },
      updateTool(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllTools }, data => {
          data.zeus_tool.push(result.zeus_tool);
          return data;
        });
      },
      updateUsage(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllToolUsage }, data => {
          data.zeus_tool_user_status.push(result.zeus_tool_user_status);
          return data;
        });
      },
      updateTicket(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllTickets }, data => {
          data.zeus_tool_user_status.push(result.zeus_ticket);
          return data;
        });
      },
      updateAssetSurvey(result, _args, cache, _info) {
        cache.updateQuery({ query: getAllAssetSurveys }, data => {
          data.zeus_tool_user_status.push(result.zeus_asset_survey);
          return data;
        });
      },
    },    
  },
  optimistic: {
    /* ... */
    addClient: (variables, cache, info) => {
      variables.__typename = 'zeus_client'
      variables.deleted = false
      return variables
    }, 
    addContact: (variables, cache, info) => {
      variables.__typename = 'zeus_contact'
      variables.deleted = false
      return variables
    }, 
    addContactForClient: (variables, cache, info) => {
      variables.__typename = 'zeus_contact'
      variables.deleted = false
      return variables
    }, 
    addAnnotation: (variables, cache, info) => {
      variables.__typename = 'zeus_annotation'
      variables.deleted = false
      return variables
    },    
    addQuotation: (variables, cache, info) => {
      variables.__typename = 'zeus_sales_document'
      variables.deleted = false
      return variables
    },
    addAsset: (variables, cache, info) => {
      variables.__typename = 'zeus_asset'
      variables.deleted = false
      return variables
    },
    addClientNode: (variables, cache, info) => {
      variables.__typename = 'zeus_client_node'
      variables.deleted = false
      return variables
    },
    addRoute: (variables, cache, info) => {
      variables.__typename = 'zeus_route'
      variables.deleted = false
      return variables
    },
    addActivity: (variables, cache, info) => {
      variables.__typename = 'zeus_activity'
      variables.deleted = false
      return variables
    },    
    addAttachment: (variables, cache, info) => {
      variables.__typename = 'zeus_attachment'
      variables.deleted = false
      return variables
    },
    addJobBoardEntry: (variables, cache, info) => {
      variables.__typename = 'zeus_jobboard_entry'
      variables.deleted = false
      return variables
    },
    addMeasurement: (variables, cache, info) => {
      variables.__typename = 'zeus_measurement'
      variables.deleted = false
      return variables
    },
    addProduct: (variables, cache, info) => {
      variables.__typename = 'zeus_product'
      variables.deleted = false
      return variables
    },
    addProductLine: (variables, cache, info) => {
      variables.__typename = 'zeus_product_line'
      variables.deleted = false
      return variables
    },
    addSupplier: (variables, cache, info) => {
      variables.__typename = 'zeus_supplier'
      variables.deleted = false
      return variables
    },
    addToolNode: (variables, cache, info) => {
      variables.__typename = 'zeus_tool_node'
      variables.deleted = false
      return variables
    },
    addTool: (variables, cache, info) => {
      variables.__typename = 'zeus_tool'
      variables.deleted = false
      return variables
    },
    addImage: (variables, cache, info) => {
      variables.__typename = 'zeus_image'
      variables.deleted = false
      return variables
    },
    addUsage: (variables, cache, info) => {
      variables.__typename = 'zeus_tool_user_status'
      variables.deleted = false
      return variables
    },
    addLocation: (variables, cache, info) => {
      variables.__typename = 'zeus_usage_tool_location'
      variables.deleted = false
      return variables
    },
    addToolNodeBooking: (variables, cache, info) => {
      variables.__typename = 'zeus_user_tool_booking'
      variables.deleted = false
      return variables
    },
    addToolBooking: (variables, cache, info) => {
      variables.__typename = 'zeus_user_tool_booking'
      variables.deleted = false
      return variables
    },
    addTicket: (variables, cache, info) => {
      variables.__typename = 'zeus_ticket'
      variables.deleted = false
      return variables
    },
    addPurchaseOrder: (variables, cache, info) => {
      variables.__typename = 'zeus_sales_document'
      variables.deleted = false
      return variables
    },
    addInvoice: (variables, cache, info) => {
      variables.__typename = 'zeus_sales_document'
      variables.deleted = false
      return variables
    },
    addAssetSurvey: (variables, cache, info) => {
      variables.__typename = 'zeus_asset_survey'
      variables.deleted = false
      return variables
    },
  },
});

console.log('process.env');
console.log(process.env);

let env_args = null;
env_args = Config.development;

if(process.env.REACT_APP_ENV === "staging"){
  env_args = Config.staging;
}
if(process.env.REACT_APP_ENV === "production"){
  env_args = Config.production;
}

console.log('env_args');
console.log(env_args);

const wsClient = createWSClient({
  url: env_args.ZEUS_HASURA_WS_URL,

  connectionParams: async () => {
    //const token = await user.getToken();
    return {
      headers: {
        'content-type': 'application/json',
        'X-Hasura-Admin-Secret': 'PrimacReliabilityConsultantsDoItInWaves',
      },
    };
  }, 
});

const client = createClient({
  url: env_args.ZEUS_HASURA_URL,
  requestPolicy: 'cache-and-network',
  exchanges: [
    dedupExchange, 
    cacheExchange, 
    retryExchange(retryOptions), 
    fetchExchange,

    authExchange({
      addAuthToOperation: ({
        authState,
        operation,
      }) => {
        // the token isn't in the auth state, return the operation without changes
        if (!authState || !authState.token) {
          return operation;
        }

        // fetchOptions can be a function (See Client API) but you can simplify this based on usage
        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(
          operation.kind,
          operation,
          {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                "Authorization": authState.token,
              },
            },
          },
        );
      },
      willAuthError: ({ authState }) => {
        if (!authState) return true;
        // e.g. check for expiration, existence of auth etc
        return false;
      },
      didAuthError: ({ error }) => {
        // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
        return error.graphQLErrors.some(
          e => e.extensions?.code === 'FORBIDDEN',
        );
      },
      getAuth: async ({ authState, mutate }) => {
        // for initial launch, fetch the auth state from storage (local storage, async storage etc)
        if (!authState) {
          const token = localStorage.getItem('token');
          const refreshToken = localStorage.getItem('refreshToken');
          if (token && refreshToken) {
            return { token, refreshToken };
          }
          return null;
        }

        /**
         * the following code gets executed when an auth error has occurred
         * we should refresh the token if possible and return a new auth state
         * If refresh fails, we should log out
         **/

        // if your refresh logic is in graphQL, you must use this mutate function to call it
        // if your refresh logic is a separate RESTful endpoint, use fetch or similar
        const result = await mutate(refreshMutation, {
          token: authState.refreshToken,
        });

        if (result.data?.refreshLogin) {
          // save the new tokens in storage for next restart
          localStorage.setItem('token', result.data.refreshLogin.token);
          localStorage.setItem('refreshToken', result.data.refreshLogin.refreshToken);

          // return the new tokens
          return {
            token: result.data.refreshLogin.token,
            refreshToken: result.data.refreshLogin.refreshToken,
          };
        }

        // otherwise, if refresh fails, log clear storage and log out
        localStorage.clear();

        // your app logout logic should trigger here
        logout();

        return null;
      },
    }),

    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    }),
  ],

  fetchOptions: () => {
    //const token = getToken();
    return {
      headers: { 
        "X-Hasura-Admin-Secret": "PrimacReliabilityConsultantsDoItInWaves",
        "content-type": "application/json",
        //"authorization": token ? `Bearer ${token}` : ''
      },
    };
  },  
});

console.log('client')
console.log(client)

//////////////////////

//const WrappedAppComponent = withMsal(App);
const root = ReactDOM.createRoot(document.getElementById("root"));

let rootEl = (
  <MuiThemeProvider>
    <Provider value={client}>
      <App />
    </Provider>  
  </MuiThemeProvider>  
)

if(process.env.REACT_APP_ENV === "production"){
  rootEl = (
    <MuiThemeProvider>
      <MsalProvider instance={msalInstance}>
        <Provider value={client}>
          <AuthenticatedTemplate>
            <App />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>You are not signed in. <ZSignInButton /></p>
          </UnauthenticatedTemplate>
        </Provider>
      </MsalProvider>
    </MuiThemeProvider>
  )
}

if(process.env.REACT_APP_ENV === "staging"){
  rootEl = (
    <MuiThemeProvider>
      <MsalProvider instance={msalInstance}>
        <Provider value={client}>
          <AuthenticatedTemplate>
            <App />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>You are not signed in. <ZSignInButton /></p>
          </UnauthenticatedTemplate>
        </Provider>
      </MsalProvider>
    </MuiThemeProvider>
  )
}


root.render(
  <div>{rootEl}</div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);



/*

  fetchOptions: () => {
    const token = getToken();
    return {
      headers: { 
        //"X-Hasura-Admin-Secret": "PrimacReliabilityConsultantsDoItInWaves",
        "x-hasura-admin-secret": "PrimacReliabilityConsultantsDoItInWaves",
        //"x-hasura-zeus-graphql-engine": "PrimacReliabilityConsultantsDoItInWaves",
        //"authorization": token ? `Bearer ${token}` : ''
      },
    };
  },


  fetchOptions: {
    headers: {
      'content-type': 'application/json',
      'x-hasura-admin-secret': 'PrimacReliabilityConsultantsDoItInWaves',
    },
  }, 

*/
