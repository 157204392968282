import React from 'react';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getTool,  getQuotesForClient } from 'queries_urql';
import { addClient, updateTool } from 'mutations_urql';
import { subscribeAllActiveJobBoardEntries, subscribeTool } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZToolBox from 'components/boxes/ZToolBox';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZToolUsageList from 'components/lists/ZToolUsageList';// ZToolUsageList
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';// ZAttachmentsList
import ZTicketsList from 'components/lists/ZTicketsList';//ZTicketsList
import ZToolKitsList from 'components/lists/ZToolKitsList';// ZToolKitsList
import ZToolBookingsList from 'components/lists/ZToolBookingsList';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZAddTicketDialog from 'components/dialogs/ZAddTicketDialog';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ReactTooltip from 'react-tooltip';
import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 400,
    height: 800,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

//////////////////////////////////////////////

const ZToolView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
  
  const classes = useStyles();
  let { id } = useParams();
  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    showDialog: false,
    showBookingDialog: false,
    showAnnotationDialog: false,
    showTicketDialog: false,
  });
  
  const [tabIndex, setTabIndex] = React.useState(0);
  const [lat, setLat] = React.useState(null);
  const [lng, setLng] = React.useState(null);

  let row = undefined;
  let toolkits = [];
  let jobs = [];

  const handleAddBooking = () => {
    console.log('handleAddBooking')
    setState({...state, showBookingDialog: true});
  }

  const handleBookingClose = () => {
    console.log('handleBookingClose')
    setState({...state, showBookingDialog: false});
  };

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false});
  }  

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const calcRestrictedDates = (elements) =>{
    let restrictedDates = []
    console.log('callout')
    //console.log(row)
    elements.forEach((elem)=>{
      let d = moment(elem.theDate).local()
      d = d.add(1, 'day').toDate()
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  ////////////////
  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolView [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  }, [setLat,setLng]);
  /////////////////

  const [updateToolResult, updateFunction] = useMutation(updateTool);

  /////////////////

  const handleJobSubscription = (previousData, response) => {
    console.log('handleJobSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_tool_by_pk
    //return response
    return response.zeus_jobboard_entry
  };

  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_tool_by_pk
    return response
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('setting up subscription')
  const [subJobResult, executeJobSubscription] = useSubscription({ 
    query: subscribeAllActiveJobBoardEntries,
    //pause: showBookingDialog || !window.navigator.onLine,
    pause: state.showBookingDialog,
  }, handleJobSubscription); 

  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeTool,
    variables: { id },
    //pause: showBookingDialog || !window.navigator.onLine,
    pause: state.showBookingDialog,
  }, handleSubscription); 

  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getTool,
    variables: { id },
    //pause: window.navigator.onLine,
  });

  let { data, fetching, error } = subResult

  if(!window.navigator.onLine){
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }  

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  row = data.zeus_tool_by_pk

  const { data: jobData, fetching: jobFetching, error: jobError } = subJobResult

  console.log('ZToolView->data');
  console.log(row);

  console.log('ZToolView->jobs');
  console.log(jobData);

  row.parents.forEach(element => {
    toolkits.push(element.node)
  })

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showAnnotationDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )  

  let addBookingAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showBookingDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  ) 

  let openBookingAction = (
    <div>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        onClick={(event) => { 
          setState({...state, showBookingDialog: true});
        }}                     
      >
        Add Booking
      </Button>    
    </div>
  )   
  
  let addTicketAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showTicketDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )   

  let old_toprow = (
    <Row>
      <Col xs={12}>
        <ZToolBox id='tool_box' 
          tool={row}
          name={row.name} 
          lat={lat}
          lng={lng}
          description={row.description} 
          annotations={row.annotations}
          fullBox
          static
        />
      </Col>
    </Row>   
  )

  let toprow = (
    <Row>
      <Col xs={6}>
        <ZToolBox id='tool_box' 
          tool={row}
          name={row.name} 
          lat={row.lat}
          lng={row.lng}
          description={row.description} 
          annotations={row.annotations}
          refresh={executeSubscription}
          actions={openBookingAction}
          showmap
          static
        />
      </Col>
      <Col xs={6}>     
        <ZToolUsageList key='tool_usage_panel' client={row} parent={row.locker} data={row.usage}  static/>
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`tool/${row.id}/attachments`} multiple static/>
        <Row><br/></Row>
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="asset_survey_images" className={classes.image_avatar}>
                  <PhotoLibraryIcon />
                </Avatar>
              }
              title='Images'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZImageListHorizontal key='image_list' parent_id={row.id} path={`toolnode/${row.id}/images`} data={row.images} />
            </Paper>  
          </Card> 
      </Col>
    </Row>    
  )

  //////////////////////////
  // jobs={jobData}

  return (
    <div>
      <ZBookToolDialog open={state.showBookingDialog} handleClose={handleBookingClose} lat={lat} lng={lng} data={row} static tool/>  
      <Container fluid>
        {toprow}
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
            <ZAddTicketDialog open={state.showTicketDialog} parent={row} handleClose={()=>setState({...state, showTicketDialog: false})} />
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Tool Booking" />
                  <Tab label="ToolKits" />
                  <Tab label="Open Tickets" />
                  <Tab label="Closed Tickets" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.locker} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZToolBookingsList key='bookings_panel' title='Tool Booking' action={addBookingAction} parent={row} pageSize={10} descW={300} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    <ZToolKitsList key='toolkits_panel' parent={row.locker} action={<div></div>} data={toolkits} static/>
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Open Tickets" action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
                {tabIndex === 4 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Closed Tickets" action={<div></div>} data={row.closed_tickets} static/>
                  </Box>
                )}                
              </Box>
            </Paper>
          </Col>
        </Row> 
      </Container>
    </div>
  )
}

export default ZToolView;

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/


/*

        <Row>
          <Col xs={12}>
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Scheduled Dates" />
                  <Tab label="Tools Booked" />
                  <Tab label="ToolKits Booked" />
                  <Tab label="Purchase Orders" />
                  <Tab label="Invoices" />
                  <Tab label="Toolkits Used" />
                  <Tab label="Tools Used" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' action={addNoteAction} parent={data} items={data.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZScheduleList key='schedule_panel' title='Scheduled Dates' action={<div></div>} parent={data} data={data.scheduled_dates} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    <ZToolsList key='tools_booked_panel' title='Tools Booked' action={<div></div>} parent={data} data={tools_booked} static/>
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box>
                    <ZToolKitsList key='toolkits_booked_panel' title='ToolKits Booked' action={<div></div>} parent={data} data={toolnodes_booked} static/>
                  </Box>
                )}
                {tabIndex === 4 && (
                  <Box>
                    <ZPurchaseOrdersList key='purchase_orders_panel' action={<div></div>} items={data.purchaseOrders} static/>
                  </Box>
                )}
                {tabIndex === 5 && (
                  <Box>
                    <ZInvoicesList key='invoices_panel' action={<div></div>} items={data.invoices} static/>
                  </Box>
                )}
                {tabIndex === 6 && (
                  <Box>
                    <ZToolKitsList key='toolkits_panel' title='Toolkits Used' action={<div></div>} parent={data} data={toolkits} static/>
                  </Box>
                )}
                {tabIndex === 7 && (
                  <Box>
                    <ZToolsList key='tools_panel' title='Tools Used' action={<div></div>} parent={data} data={tools} static/>
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>        
        </Row> 

*/

/*
  return (
    <div>
      <ZBookToolDialog open={showBookingDialog} handleClose={handleBookingClose} lat={lat} lng={lng} data={row} jobs={jobData} static tool/>  
      <Container fluid>
        {toprow}
        <Row><br/></Row>
        <Row>
          <Col xs={6}>
            <ZToolBookingsList key='bookings_panel' title='Tool Booking' action={addBookingAction} parent={row} pageSize={5} realtime/>
          </Col>
          <Col xs={6}>
            <ZAddAnnotationDialog open={showAnnotationDialog} parent_id={data.id} handleClose={handleCloseAnnotation} />
            <ZAnnotationsList key='notes_panel' client={row} parent={row.locker} action={addNoteAction} items={row.annotations} static/>
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={6}>
            <ZAttachmentsList key='docs_panel' action={<div></div>} data={row.attachments} static/>
          </Col>
          <Col xs={6}>
            <ZAddTicketDialog open={showTicketDialog} parent={data} handleClose={()=>setShowTicketDialog(false)} />
            <ZTicketsList key='tickets_panel' action={addTicketAction} data={row.open_tickets} static/>
          </Col>
        </Row>  
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZToolKitsList key='toolkits_panel' parent={row.locker} action={<div></div>} data={toolkits} static/>
          </Col>
        </Row>                
      </Container>
    </div>
  )
*/
