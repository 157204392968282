import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllProducts, getAllToolsForNode, getAllTools } from 'queries_urql';
import { subscribeAllProductsForSupplier } from 'subscriptions_urql';

import LinearProgress from '@material-ui/core/LinearProgress';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import ReactTooltip from 'react-tooltip';

////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    overflowY: 'scroll',
    height: 400,
  },
  paper: {
    width: 200,
    height: 200,
    overflowY: 'scroll',
    maxHeight: 400,    
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
}

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
}

//////////////
/*
props {

}
*/
//////////////

const ZToolTransferList = (props) => {
  const classes = useStyles();
  
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const [search, setSearch] = React.useState(null);
  const [filterlist, setFilterlist] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  let rows = []
  let parent = {}
  if(props.parent){
    parent = props.parent
  }

  function onKeyUpHandler(e) {
    setSearch(e.target.value.toLowerCase());
  }

  const handleToggle = (value) => () => {
    console.log('handleToggle: '+ value)

    let currentIndex = -1
    //currentIndex = checked.indexOf(value);

    checked.map((selected,index)=>{
      if(selected.id === value.id){
        currentIndex = index
      }
    });

    const newChecked = [...checked];

    if (currentIndex === -1) {
      console.log('adding: '+ value)
      newChecked.push(value);
    } else {
      console.log('removing: '+ value)
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight_1 = () => {
    console.log('handleCheckedRight')
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    if(props.onChange){
      props.onChange(checked)
    } 

    setRight(right.concat(leftChecked));
  };

  const handleCheckedRight = () => {
    console.log('handleCheckedRight')
    //let newRight = [...checked];
    let filteredChecked = checked.filter((value)=>{
      if(right.indexOf(value) === -1){
        return true;
      } else {
        return false;
      }
    });

    let newRight = right.concat(filteredChecked);
    console.log('newRight');
    console.log(newRight);

    setRight(newRight);

    console.log('handleCheckedRight->selected')
    console.log(checked);

    if(props.onChange){
      props.onChange(checked)
    } 
  };

  const handleCheckedLeft = () => {
    console.log('handleCheckedLeft')
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    if(props.onChange){
      props.onChange(checked)
    } 
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  let node_id = "";
  let shouldPause = props.static !== undefined;

  let query_args = {
    query: getAllTools,
    pause: shouldPause,
  }

  if(parent.id){
    node_id = parent.id
    query_args = {
      query: getAllToolsForNode,
      variables: { node_id },
      pause: shouldPause,
    }
  }
  
  console.log('ZToolTransferList->node_id')
  console.log(node_id)
  
  const [ result, reexecuteQuery ]  = useQuery(query_args);

  console.log(result);
  
  const { data, fetching, error } = result;

  /*
  React.useEffect(() => {
    setLeft(data.zeus_product)
  },[setLeft, data.zeus_product]);
  
  React.useEffect(() => {
    const filteredList = data.zeus_product.filter((item) => {
      let all_str = `${item.name}`.toLowerCase();
      //return all_str.indexOf(search) > -1; // View All When Search Empty
      return all_str.indexOf(search) > -1 && search;
    });
    setFilterlist(filteredList);
  }, [data, search]);
  */

  /*
  React.useEffect(() => {
    if(!fetching){
      console.log('ToolTransferList->setLeft');
      console.log(`fetching == ${fetching}`);
      console.log(data);
    
      let indexes = data.zeus_tool.map((item,index)=>{
        return index;
      })
      setLeft(indexes)
      console.log(indexes)       
    }
  },[setLeft, data, fetching]);
  */



  if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) return <div></div>;

  
  console.log('ZToolTransferList->data');
  console.log(data);


  if (props.data){
    rows = props.data
  }

  console.log('ZToolTransferList->available_tools');
  console.log(data);

  console.log('ZToolTransferList -> checked + right');
  console.log('checked');
  console.log(checked);
  console.log('right');
  console.log(right);

  const isChecked = (value) => {
    console.log('isChecked')
    console.log(value)
    let currentIndex = -1;
    checked.map((selected,index)=>{
      if(selected.id === value.id){
        currentIndex = index
      }
    });
    if(currentIndex === -1){
      return false
    }
    return true;
  } 

  const customList = (items) => (
    <div>
    {console.log(items)}
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((xtool) => {
          console.log(xtool);
          let tool = xtool
          if(xtool.tool){
            tool = xtool.tool;
          }
           
          console.log(tool);

          if(search !== null){
            console.log('search')
            console.log(search)
            if(tool.name.toLowerCase().indexOf(search.toLowerCase()) === -1){
              return null;
            }
          }

          const labelId = `transfer-list-item-${tool.name}-label`;

          return (
            <ListItem id={tool.id} key={tool.id} role="listitem" button onClick={handleToggle(tool)}>
              <ListItemIcon>
                <Checkbox
                  //checked={checked.indexOf(tool.id) !== -1}
                  checked={isChecked(tool)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText               
              data-tip={`${parent.name} - ${tool.name} -  S/N: ${tool.serialNumber} - ${tool.description}`}
              data-for="tool_box_tooltip" 
              id={labelId} primary={`${tool.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
    </div>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="left"
      alignItems="left"
      className={classes.root}
    >
      {/* 
      <Grid item>        
        <input
          id="searchFilter"
          type="text"
          className="form-control"
          defaultValue={search}
          placeholder="Filter by Tool Num or Description"
          onKeyUp={onKeyUpHandler}
        />
      </Grid>
       */}
      <Grid item>{props.static ?  customList(props.data) : customList(data.zeus_tool)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={checked.length === 0}
            aria-label="move selected right"
          >
            Add
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            Remove
          </Button>
          {props.submitButton && props.submitButton}
          {props.closeButton && props.closeButton}
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
      <ReactTooltip id="tool_box_tooltip"/>
    </Grid>
  );
}


export default ZToolTransferList;
