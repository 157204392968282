import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { useLocalStorage } from 'hooks/useStorage';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useQuery, useMutation } from 'urql';
import { getClient } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getClient } from 'queries';
import { addClient, updateClient } from 'mutations';
*/

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Moment from 'react-moment';
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactTooltip from 'react-tooltip';

import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Collapse from '@material-ui/core/Collapse';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AssistantIcon from '@material-ui/icons/Assistant';
import CircularProgress from '@material-ui/core/CircularProgress';
import BallotIcon from '@material-ui/icons/Ballot';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//import OpenAI from 'openai-api';
//import LangChain from 'langchain-js';

//import { OpenAI } from "langchain/llms/openai";
/*
import { ChromaClient } from 'chromadb'
import { ConversationSummaryMemory } from "langchain/memory";
import { LLMChain } from "langchain/chains";
import { PromptTemplate } from "langchain/prompts";

import { OpenAI } from "langchain/llms/openai";
import { ConversationalRetrievalQAChain } from "langchain/chains";
import { HNSWLib } from "langchain/vectorstores/hnswlib";
import { Chroma } from "langchain/vectorstores/chroma";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
*/

import OpenAI from 'openai-api';
import Chroma from 'chroma-js';
import { ChromaClient, OpenAIEmbeddingFunction } from 'chromadb'

import { TextField, Button } from '@material-ui/core';

import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZFileS3UploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';
import ZAnnotationInputMenuItem from 'components/core/ZAnnotationInputMenuItem';

///////////////////////////////////////////////////

const ZeusOpenAIPrivateDataKey = "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"

//////////////////////////////////////////////

const useStyles = makeStyles({
  avatar: {
    backgroundColor: cyan[500],
  }, 
});

///////////////////////////////////////

const openai = new OpenAI(ZeusOpenAIPrivateDataKey);

/////////

const ZGPTConversationDetailPane = (props) => {
  const classes = useStyles();
  const [messages, setMessages] = React.useState([]);
  const [inputText, setInputText] = React.useState('');

  const chroma_client = new ChromaClient("https://zeus.primac.app:8000");
  const embedder = OpenAIEmbeddingFunction({openai_api_key: ZeusOpenAIPrivateDataKey});

  // This function sends the user input to the OpenAI API
  const sendMessage = async (input) => {
    try {
      const response = await openai.complete({
        engine: 'davinci',
        prompt: `User: ${input}\nAI:`,
        maxTokens: 50,
        temperature: 0.7,
      });

      // Add the response to the messages state
      setMessages([...messages, { sender: 'bot', message: response.choices[0].text }]);
    } catch (error) {
      console.log(error);
    }
  };

  // This function handles the user input
  const handleSubmit = (event) => {
    event.preventDefault();

    // Add the user input to the messages state
    setMessages([...messages, { sender: 'user', message: inputText }]);

    // Get the vector embedding of the user input
    const vector = embedder.generate([inputText]);

    /*
    const vector = Chroma(inputText)
      .hex()
      .split('')
      .map((char) => char.charCodeAt(0));
    */

    // Query our Zeus vector database
    const collection = chroma_client.getCollection('primac_zeus_collection', {}, embedder);
    const result = collection.query({
      query_embeddings: [vector],
      n_results: 20,
      where: {"style": "style2"},
    })

    // Store the vector in the Chroma vector database
    /*
    const url = 'https://zeus.primac.app:8000/api/vectors';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ vector }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
    */

    // Send the user input to the OpenAI API
    sendMessage(inputText);

    // Reset the input field
    setInputText('');
  };

  //////////////////////////////////////////

  return (
    <>
      <SlidingPane
        isOpen={props.isOpen}
        title="Zeus:AI"
        width="800px"
        onRequestClose={() => {
          props.handleClose()
        }}
      >
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="zeus_chat">
              <AssistantIcon />
            </Avatar>
          }
          title="Zeus:AI Assistant"
          titleTypographyProps={{variant:'h4'}}
        />
        <Paper elevation={0} variant="outlined" square>
          {messages.map((message,index)=>{
            return (
              <div>
                <Typography variant="body1" gutterBottom>
                  {message.sender === 'user' ? 'Me:' : 'Zeus:'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {message.message}
                </Typography>
              </div>
            )
          })}
        </Paper>
        <form onSubmit={handleSubmit}>
          <TextField
            id="input-text"
            label="Input Text"
            variant="outlined"
            value={inputText}
            onChange={(event)=>setInputText(event.target.value)}
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </form>
      </Card>
      </SlidingPane>
    </>    
  );
}

/////////////////////////////////////////////////


/////////////////////////////////////////////////

const ZGPTConversationDetailPaneWss = (props) => {
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  //const [messages, setMessages] = React.useState([]);
  const [inputText, setInputText] = React.useState('');

  //const [socketUrl, setSocketUrl] = React.useState('wss://zeus-ai.primac.app:9090/chat');
  const [messageHistory, setMessageHistory] = React.useState([]);

  let ai_state = (<div></div>)

  const { sendMessage, lastMessage, readyState } = useWebSocket(`wss://zeus-ai.primac.app:9090/chat/${props.collection_id}`, {
    onOpen: () => console.log('useWebSocket->opened'),
    onError: (event) => console.log(event),
    onMessage: (event) => {
      var message = JSON.parse(event.data);
      if(message.type === 'start'){
        ai_state = (<h2>Computing answer... <CircularProgress /></h2>);
      } else {
        ai_state = (<div></div>);
      }
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      if(props.handleClose){
        props.handleClose();
      }

    }
  }, []);

  React.useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  const handleClickSendMessage = React.useCallback(
    () => {
      sendMessage(inputText)
      setInputText('');
    }
  , [sendMessage,inputText]);


  const handleClickResetConversation = React.useCallback(
    ()=>{
      console.log('resetting converation history')
      sendMessage("cmd:user:reset");
      setMessageHistory([]);
      setInputText('')
    },
  [sendMessage, setMessageHistory, setInputText]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];  


  if(lastMessage !== null){
    var message = JSON.parse(lastMessage.data);
    if(message.type === 'start'){
      ai_state = (<h2>Computing answer... <CircularProgress /></h2>);
    }
  }

          //data-tip="Zeus AI Chat"
        //data-for="navbar_tooltip" 

  //////////////////////////////////////////

  return (
    <>
      <IconButton
        color="inherit"
        onClick={()=>{
          console.log('showGPTChat == toggle')
          props.handleOpen() 
        }}
        data-tip="Zeus:AI Assistant"
        data-for="zeus_gpt_tooltip" 
      >
        <SearchIcon/>
      </IconButton>    
      <SlidingPane
        isOpen={props.isOpen}
        title="Zeus:AI"
        width="800px"
        onRequestClose={() => {
          props.handleClose()
        }}
      >
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="zeus_chat" className={classes.avatar}>
              <AssistantIcon />
            </Avatar>
          }
          title="Zeus:AI Assistant"
          titleTypographyProps={{variant:'h4'}}
          subheader={<span>Zeus:AI connection is currently {connectionStatus}</span>}
          action={ai_state}
        />
        <Paper elevation={0} variant="outlined" square>
          {
            messageHistory.map((event,index)=>{
              var message = JSON.parse(event.data);
              console.log(message);
              if(message.type === 'start'){
                return null;
              }
              var color_sel =  message.sender === 'bot' ? "secondary" : "primary";

              return (
                <div>
                  <Typography color={color_sel} key={index} variant="body1" gutterBottom>
                    <div>
                      {message.sender === 'bot' ? 'Zeus: ' : `${userData.alias}: `}
                      {message.message}
                      {message.sender === 'bot' ? 
                        <div style={{ color: green[500] }}>{`[sources: ${message.sources}]`}</div> 
                        : null
                      }
                    </div>
                  </Typography>
                </div>
              )
            })
          }
        </Paper>
          <TextField
            id="input-text"
            label="Input Text"
            variant="outlined"
            value={inputText}
            onChange={(event)=>setInputText(event.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickSendMessage}
            disabled={readyState !== ReadyState.OPEN}
            data-tip="Submit question to Zeus:AI"
            data-for="zeus_gpt_tooltip" 
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={handleClickResetConversation}
            data-tip="Clear conversation history and context from Zeus:AI"
            data-for="zeus_gpt_tooltip" 
          >
            Clear
          </Button>          
      </Card>
      </SlidingPane>
      <ReactTooltip id="zeus_gpt_tooltip"/>
    </>    
  );
}


export { ZGPTConversationDetailPane, ZGPTConversationDetailPaneWss };


/*

          {
            lastMessage ? 
              <Typography variant="body1" gutterBottom>
                Last message: {lastMessage.data}
              </Typography>
            :
              null
          }

const ZGPTPromptDetailPane = (props) => {
  // Set up state to hold input and output text
  const [state, setState] = React.useState({
    inputText: undefined,
    outputText: undefined,
    historyText: [],
  });

  // Initialize OpenAI client
  const openai = new OpenAI({apiKey: ZeusOpenAIPrivateDataKey});

  // Initialize LangChain client
  const langchain = new LangChain({apiKey: 'YOUR_LANGCHAIN_API_KEY'});

  // Define function to get AI assistant response
  const getAiAssistantResponse = async (inputText) => {
    // Create a new project
    const project = await langchain.createProject({name: 'My AI Assistant Project', type: 'ai-assistant'});

    // Upload dataset to LangChain
    const dataset = await project.uploadDataset('/path/to/your/dataset.csv');

    // Train model with OpenAI
    const openaiModelId = "your-openai-model-id";
    const openaiFineTuneData = {"dataset": dataset.id};
    await openai.completions.create({engine: openaiModelId, fine_tune: openaiFineTuneData});

    // Get AI assistant response from OpenAI
    const response = await openai.completions.create({
      engine: openaiModelId,
      prompt: state.inputText,
      max_tokens: 50,
      n: 1,
      stop: null,
      temperature: 0.5,
    });

    // Return AI assistant response text
    return response.choices[0].text;
  }

  // Handle input text change
  const handleInputChange = (event) => {
    setState({...state, inputText: event.target.value});
  }

  // Handle form submit
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const aiResponse = await getAiAssistantResponse(state.inputText);
    setState({...state, outputText: aiResponse});
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SlidingPane
        isOpen={props.isOpen}
        title="Zeus AI Assistant"
        width="800px"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          //setDetailPane(false);
          props.handleClose()
        }}
      > 
        <Typography variant="h5" component="h2" gutterBottom>
          Zeus AI Assistant
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <TextField
            id="input-text"
            label="Input Text"
            variant="outlined"
            value={state.inputText}
            onChange={handleInputChange}
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </form>
        {state.outputText && (
          <Typography variant="body1" gutterBottom>
            Response: {state.outputText}
          </Typography>
        )}
      </SlidingPane>   
    </div>
  );
}
*/

///////////////////////////////////
///////////////////////////////////

/*
const ZGPTConversationDetailPane = (props) => {

  const classes = useStyles();

  // Set up state to hold input and output text
  const [state, setState] = React.useState({
    inputText: '',
    outputText: '',
    historyText: [],
  });

  // Initialize OpenAI client
  const openai = new OpenAI({apiKey: ZeusOpenAIPrivateDataKey});

  // Initialize LangChain client
  //const langchain = new LangChain({apiKey: 'YOUR_LANGCHAIN_API_KEY'});

  const getVectorStore = async () => {
    const vs = new Chroma(
      new OpenAIEmbeddings(),
      { 
        collectionName: "primac_zeus_collection",
        url: 'https://zeus.primac.app:8000',
        numDimensions: 1 
      }
    );

    return vs;
  }

  const chain = ConversationalRetrievalQAChain.fromLLM(
    openai,
    getVectorStore().asRetriever(),
  );

  // Define function to get embeddings from LangChain
  //const getEmbeddings = async (inputText) => {
  //  const embeddings = await langchain.embeddings.create({text: inputText, model: 'roberta-base'});
  //  return embeddings[0].vector;
  //}
  

  // Define function to store embeddings in ChromaDB
  //const storeEmbeddings = async (embeddings) => {
  //  await axios.post('https://zeus.primac.app:8000/vectors', {
  //    data: {
  //      vector: embeddings,
  //      meta: {
  //        type: 'langchain-embeddings'
  //      }
  //    }
  // });
  //}

  // Define function to get AI assistant response
  const getAiAssistantResponse = async (question) => {
    console.log('getAiAssistantResponse')
    const res = await chain.call({ question, chat_history: state.historyText });
    console.log(res);
    return res;
  }

  // Handle input text change
  const handleInputChange = (event) => {
    console.log('handleInputChange');
    console.log(event.target.value);
    setState({...state, inputText: event.target.value});
  }

  // Handle form submit
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log('handleFormSubmit');
    console.log(state.inputText);

    const aiResponse = await getAiAssistantResponse(state.inputText);
    let lastQuestion = state.inputText + aiResponse.text;
    //let hist = {
    //  question: state.inputText,
    //  answer: aiResponse,
    //}
    state.historyText.push(lastQuestion);
    setState({...state, inputText: '', historyText: state.historyText, outputText: aiResponse.text});
  }

  ///////////////////////////////////

  return (
    <>
      <SlidingPane
        isOpen={props.isOpen}
        title="Job Board Detail"
        width="800px"
        onRequestClose={() => {
          props.handleClose()
        }}
      >
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="zeus_chat">
              <AssistantIcon />
            </Avatar>
          }
          title="Zeus AI Assistant"
          titleTypographyProps={{variant:'h4'}}
        />
        <Paper elevation={0} variant="outlined" square>
          {state.historyText && state.historyText.map((entry,index)=>{
            return (
              <div>
                <Typography variant="body1" gutterBottom>
                  Q+A: {entry}
                </Typography>
              </div>
            )
          })}
        </Paper>
        <form onSubmit={handleFormSubmit}>
          <TextField
            id="input-text"
            label="Input Text"
            variant="outlined"
            value={state.inputText}
            onChange={handleInputChange}
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </form>
      </Card>
      </SlidingPane>
    </>
  );
}
*/
