import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZSensorAutocomplete = (props) => {
  let rows = []

  if(props.sensor){
    rows = [props.sensor]
  } else {
    rows = [...props.sensors]
  }

  return (
    <Autocomplete
      id="choose-sensor-combo-box"
      onChange={(event,item)=>{
        console.log("sensor autocomplete change")
        console.log(item)
        props.onChange(event,item)
      }}
      options={rows}
      getOptionLabel={(option) => `${option.name}: ${option.alias}`}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}
      style={{ width: 600 }}
      renderInput={(params) => <TextField {...params} label="Choose Sensor to Trigger" variant="outlined" />}
    />
  )
}

export default ZSensorAutocomplete;