import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode } from 'queries_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlaceIcon from '@material-ui/icons/Place';

import QRCode from "react-qr-code";

import {
  interaction, layer, custom, control, //name spaces
  Interactions, Overlays, Controls,     //group
  Map, Layers, Overlay, Util    //objects
} from "react-openlayers";

import * as ol from 'openlayers';
//import {transform} from 'ol/proj';

//import GoogleMapReact from 'google-map-react';
//import Marker from 'google-map-react';
//import L from 'leaflet';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  notes: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    height: '400px',
  },
});

//////////////////////////

const ZMapComponent = (props) => {
  const classes = useStyles();
  //const mapRef = React.useRef(null);

  console.log(`ZMapComponent lat = ${props.lat} lng = ${props.lng}`)

  var xy = ol.proj.transform([props.lng, props.lat], "EPSG:4326", "EPSG:3857");
  var iconFeature = new ol.Feature(new ol.geom.Point(xy));
  var source = new ol.source.Vector({features: [iconFeature]});
  var marker = new custom.style.MarkerStyle(
    'baseline_place_black_24dp.png'
  );

  let selectedMarkerStyle = Util.cloneObject(marker.style);
  selectedMarkerStyle.getImage().setOpacity(1);

  return (
    <div className={classes.map}>
      <Map view={{center: xy, zoom: 12}}>
        <Layers>
          <layer.Tile />
          <layer.Vector source={source} style={marker.style} />
        </Layers>
        <Interactions>
          <interaction.Select style={selectedMarkerStyle} />
        </Interactions>
      </Map>    
    </div>
  )
};

export default ZMapComponent;

///////////////////

  /*
  const [name, setName] = React.useState(props.name)
  const [lat,  setLat]  = React.useState(props.lat)
  const [lng,  setLng]  = React.useState(props.lng)
  */

  // 53.268443, -113.552206
  //console.log('lat: '+ lat)
  //console.log('lng: '+ lng)
  //console.log('key: '+ name)

  /*
  React.useEffect(()=>{
    mapRef.current = L.map(props.name || 'map').setView([props.lat, props.lng], props.zoom || 8);
    
    L.marker([props.lat, props.lng]).addTo(mapRef.current);

    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(mapRef.current);
    
  },[])
  */

  /*
  React.useEffect(()=>{
    console.log(`React.useEffect [${lat} , ${lng}]`)
    mapRef.current = L.map(name || 'map').setView([lat,lng], props.zoom || 8);
    
    L.marker([lat, lng]).addTo(mapRef.current);

    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(mapRef.current);
    
  },[])
  */

 
  /*
  React.useEffect(()=>{
    dropPosition()
  })
  

  let dropPosition = React.useMemo(()=>{
    console.log(`ZMapComponent React.useMemo -> dropPosition -> [${props.lat} , ${props.lng}]`)
    mapRef.current = L.map(props.name || 'map').setView([props.lat,props.lng], props.zoom || 8);
    
    L.marker([props.lat, props.lng]).addTo(mapRef.current);

    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(mapRef.current);    
  },[])
  */

  /*
  let dropPosition_1 = React.useCallback(()=>{
    console.log(`React.useCallback -> dropPosition -> [${lat} , ${lng}]`)
    mapRef.current = L.map(name || 'map').setView([lat,lng], props.zoom || 8);
    
    L.marker([lat, lng]).addTo(mapRef.current);

    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(mapRef.current);    
  },[lat,lng])
  */

  /*
  console.log(`React.useEffect [${lat} , ${lng}]`)
  mapRef.current = L.map(name || 'map').setView([lat,lng], props.zoom || 8);
  
  L.marker([lat, lng]).addTo(mapRef.current);

  var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(mapRef.current);
  */

  //ref={mapRef}


  /*
  var map = L.map(props.name || 'map').setView([props.lat, props.lng], props.zoom || 8);
    
  L.marker([props.lat, props.lng]).addTo(map);

  var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);
  */

  /*
  return (
    <div id={props.name || 'map'} ref={mapRef} className={classes.map}></div>
  );
  */
