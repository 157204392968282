import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getGateway } from 'queries_urql'; //subscribeToolBox
import { subscribeGatewayBox } from 'subscriptions_urql';
import { updateGatewayField } from 'mutations_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, blue, green, cyan } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import BuildIcon from '@material-ui/icons/Build';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QRCode from "react-qr-code";

import ZMapComponent from 'components/core/ZMapComponent';
import ZAssignGatewayToClientButton from 'components/buttons/ZAssignGatewayToClientButton'; //ZDeployGatewayConfigButton
import ZDeployGatewayConfigButton from 'components/buttons/ZDeployGatewayConfigButton'; //ZDeployGatewayConfigButton
import ZSetReadyButton from 'components/buttons/ZSetReadyButton'; 
import ZAssignGatewayToParentButton from 'components/buttons/ZAssignGatewayToParentButton';
import ZGatewayCommandsButton from 'components/buttons/ZGatewayCommandsButton'; //ZGatewayCommandsButton
import ZAddAliasButton from 'components/buttons/ZAddAliasButton';
import ZForgetAllSensorsButton from 'components/buttons/ZForgetAllSensorsButton'; //ZForgetAllSensorsButton
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: blue[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

/////////////////////////////////////

const ZGatewayBox = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);

  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    alias: false,
    field: null,
    value: null,
  })

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  const [updateResult, updateFunction] = useMutation(updateGatewayField);

  ///////////


  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZGatewayBox->handleEditCommit')
    let gateway_id = props.gateway.id;
  
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: gateway_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: gateway_id,
        name: `gateway ${field} updated`,
        description: `${userData.alias} updated gateway ${field} from ${props.gateway[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating tool: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`gateway update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated gateway', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);


  /////////

  let gateway = {}

  const queryPause = props.static !== undefined ? true : false;
  const subscriptionPause = props.realtime !== undefined ? false : true;
  const id = props.gateway.id;

  const handleSubscription = (previousData, response) => {
    console.log('ZGatewayBox::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    gateway = data.zeus_gateway_by_pk
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZToolBox setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeGatewayBox,
    variables: { id },
    pause: subscriptionPause,
  }, handleSubscription); 


  const [result, reexecuteQuery] = useQuery({
    query: getGateway,
    variables: { id },
    //pause: queryPause,
    pause: true,
  });

  console.log('ZGatewayBox result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static){
    gateway = props.gateway
  } else {
    gateway = data.zeus_gateway_by_pk
  }

  /////////

  console.log('ZGatewayBox->gateway');
  console.log(gateway)

  let smallChips = (<div></div>)
  let gatewayNotes = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)
  let images = (<div></div>)

  let assignToClientButton = (<div></div>)
  let assignToClientNodeButton = (<div></div>)
  let addAliasButton = (<div></div>)
  let deployConfigButton = (<div></div>)

  let forgetAllButton = (<div></div>)

  //////////////////////////////////

  if(props.noactions === undefined){
    assignToClientButton = (
      <div>
        <ZAssignGatewayToClientButton id={id} gateway={props.gateway} clients={props.clients} done={props.gateway.client ? true : false} />      
      </div>
    )

    if(props.gateway.client){
      assignToClientNodeButton = (
        <div>
          <ZAssignGatewayToParentButton id={id} gateway={props.gateway} client={props.gateway.client}  />      
        </div>
      )
    } else {
      assignToClientNodeButton = (
        <div>
          <ZAssignGatewayToParentButton id={id} gateway={props.gateway} disabled/>      
        </div>
      )      
    }

    addAliasButton  = (
      <div>
        <ZAddAliasButton id={id} entity={props.gateway} updateMutation={updateGatewayField} />  
      </div>
    )    

    forgetAllButton  = (
      <div>
        <ZForgetAllSensorsButton id={id} gateway={props.gateway} />  
      </div>
    )   

    deployConfigButton = (
      <div>
        <ZDeployGatewayConfigButton gateway={props.gateway} gateways={props.gateways} />
      </div>
    )
  }

  ///////////////////////////////////

  if(props.fullBox){
    smallChips = (
      <div>
        <Chip label={'Total Sensors: '+ gateway.sensors_aggregate.aggregate.count} />
      </div>
    )

    images = (
      <div>
        <CardActions disableSpacing>       
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show images"
            data-tip="show images"
            data-for="gateway_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
          { props.showImages && <ZImageListHorizontal parent_id={gateway.id} path={`gateway/${gateway.id}/images`} data={gateway.images} /> }             
          </CardContent>
        </Collapse>
      </div>
    )

  }

  let title = (
    <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/gateways')
          history.push(path)
        }}          
      >
        Gateway
      </Button> 
      <Typography variant='h3'>{gateway.name}</Typography>
      <div onClick={()=>setIsEditable({...isEditable, alias: true, field: 'alias'})}>
        <Typography variant='h3' color='secondary'>{gateway.alias ? gateway.alias : ''}</Typography>
      </div>
    </div>      
  )

  /*
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
  */

  // ready is the default
  let urlValue = `${baseURL}/gateway/${gateway.id}`;

  if(props.noqrcode === undefined){
    qrcode = (
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
        <QRCode
        size={512}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={urlValue}
        viewBox={`0 0 512 512`}
        />
      </div>
    )
  }

  
  let current_user = (<div></div>)
  let status_colour = "primary"

  /////////////////////////

  let lastCheckedOut = (<div>Last Checked-Out: never</div>)
  let lastCheckedIn  = (<div>Last Checked-In: never</div>)
  let clientName = (<div>Client: N/A</div>)
  let parentName = (<div>Parent: N/A</div>)
  let nfcTag = (<div>NFC Tag: N/A</div>)

  //if(tool.locker !== null){
  //  lockerName = (<div>Locker: {tool.locker.name}</div>)
 // }

  if(gateway.client !== null){
    clientName = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client/:id', {
            id: gateway.client.id}
          )
          history.push(path)
        }}          
      >
        Client: {gateway.client.name}
      </Button> 
    )   
  }

  if(gateway.parent !== null){
    parentName = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client_node/:id', {
            id: gateway.parent.id}
          )
          history.push(path)
        }}          
      >
        Parent: {gateway.parent.name}
      </Button> 
    )   
  }  

  if(gateway.nfcTag){
    nfcTag = (<div>NFC Tag: {gateway.nfcTag}</div>)
  }  

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>      
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="gateway" className={classes.avatar}>
              <BlurCircularIcon />
            </Avatar>
          }
          action={
            <div>
              <Paper>
                <Chip
                  style={{ marginLeft: 8 }}
                  icon={<TrackChangesIcon />}
                  label={ gateway.status.toUpperCase() }
                  color={status_colour}
                />              
                <br/>
                <div>
                  <ZGatewayCommandsButton gateway={props.gateway} />
                  {assignToClientButton}
                  {deployConfigButton}
                  {assignToClientNodeButton}
                  {addAliasButton}
                  {props.actions}
                </div>
              </Paper>
              <br/>
              {qrcode}
            </div>
          }
          title={
            isEditable.alias ?
            <div>
              <Typography  variant='overline' color='primary'>Gateway</Typography>
              <Typography variant='h3'>{gateway.name}</Typography>
              <TextField
                className={classes.nameField}
                fullWidth
                id="details"
                label="Gateway alias"
                defaultValue={gateway.alias ? gateway.alias : ''}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'alias', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />     
            </div>         
            :
            <div>{title}</div>
          }
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {gateway.createdOn}
                </Moment>
              </div>
              { smallChips } 
              {clientName}
              {parentName}
              {nfcTag}
            </div>
          }
        />
        <CardContent>
          {gmap}          
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >          
          {
            isEditable.description ?
            <TextField
              className={classes.descriptionField}
              fullWidth
              id="details"
              label="Description"
              multiline
              defaultValue={gateway.description}
              rows={4}
              onChange={(event) =>{
                console.log(event.target.value)
                setIsEditable({...isEditable, field: 'description', value: event.target.value});
                //console.log("state->"+ JSON.stringify(state));
              }} 
              variant="filled"
              InputProps={{
                endAdornment: (
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      console.log('edit commit')
                      handleEditCommit(isEditable.field, isEditable.value)
                      //setIsEditable({...isEditable, description: false, field: null, value: null})
                    }}                    
                  >
                    <CloudUploadIcon />
                  </IconButton>
                )
              }}              
            />  
            :       
            <Typography>{gateway.description}</Typography>
          }
          </Paper>
        </CardContent>
        { /* images */ }
        { images }
      </Card>
      <ReactTooltip id="gateway_box_tooltip"/>
    </div>
  );
};


export default ZGatewayBox;
