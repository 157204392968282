import React from 'react';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';

import { purple, green, lightBlue, orange, cyan } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllJobBoardEntries } from '../../queries';
import { updateClient, updateJobBoardEntry } from '../../mutations'
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllSensors } from 'queries_urql';
import { updateClient, updateSensor  } from 'mutations_urql';
import { subscribeAllSensors } from 'subscriptions_urql';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import LinearProgress from '@material-ui/core/LinearProgress'; //LinearProgress
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //ZJobBoardDetailPane
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import BurstModeIcon from '@material-ui/icons/BurstMode';


import ZSensorDetailPane from 'components/core/ZSensorDetailPane';
import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';

import ZAddJobBoardEntryDialog from 'components/dialogs/ZAddJobBoardEntryDialog';
import ZAddToolNodeDialog from 'components/dialogs/ZAddToolNodeDialog';
import ZAddToolDialog from 'components/dialogs/ZAddToolDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';

import { Link as RouterLink, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';
import eventBus from 'utils/EventBus';

//////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: cyan[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.anomaly': {
      backgroundColor: orange[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////////////////////////////////

const ZSensorsPanel = (props) => {
  console.log('ZSensorsPanel->props');
  console.log(props);  

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    showDetailPane: false,
    showAddSensorDialog: false,
    open: false,
    activeEntry: null,
    sensor_id: null,
  });

  let rows = []

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>
            <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                data-tip="quick view"
                data-for="sensor_panel_tooltip" 
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let entry = params.row;
                  console.log("selected entry");
                  console.log(entry);
                  setState({...state,
                    activeEntry: entry,
                    showDetailPane: true,
                  });
                }}          
              >
                <BurstModeIcon />
              </IconButton>                
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={()=>window.open(`https://zeus.primac.app/sensor/${params.row.id}`, "newwindow")}
                /*
                onClick={(event) => { 
                  let path = generatePath('/sensor/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}  */        
              >
                <OpenInNewIcon />
              </IconButton>           
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 130,
      editable: false,
      hide: false,
    },
    {
      field: 'alias',
      title: 'Alias',
      headerName: 'Alias',
      minWidth: 350,
      editable: true,
      hide: false,
    },    
    {
      field: 'lastMeasurement',
      title: 'Last',
      headerName: 'Last',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => {
        if(params.row.lastMeasurement == null){
          return 'N/A'
        }
      
        return (
          <Moment format="YYYY-MM-DD HH:mm:ss">
          {params.row.lastMeasurement}
          </Moment>
        )
      }
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 500,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'asset',
      title: 'Asset',
      headerName: 'Asset',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.asset){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={()=>window.open(`https://zeus.primac.app/asset/${params.row.id}`, "newwindow")}
                  /*
                  onClick={(event) => { 
                    let path = generatePath('/asset/:id', {
                      id: params.row.asset.id}
                      )
                    history.push(path)
                  }} */     
                >
                  {params.row.asset.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      }
    },
    {
      field: 'gateway',
      title: 'Gateway',
      headerName: 'Gateway',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.gateway){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={()=>window.open(`https://zeus.primac.app/gateway/${params.row.id}`, "newwindow")}
                  /*
                  onClick={(event) => { 
                    let path = generatePath('/gateway/:id', {
                      id: params.row.gateway.id}
                      )
                    history.push(path)
                  }}  */   
                >
                  {params.row.gateway.alias ? params.row.gateway.alias : params.row.gateway.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      }
    },    
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];

  //////

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZSensorsPanel::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleClickOpen = () => {
    if(props.handleOpen){
      console.log('ZSensorsPanel::handleClickOpen props.handleOpen is present handing off to parent');
      props.handleOpen()
      return;
    }
    console.log("ZSensorsPanel::handleClickOpen->open add sensor dialog")
    setState({...state, showAddSensorDialog: true});
    console.log("ZSensorsPanel::handleClickOpen == "+ state.showAddSensorDialog);
  };
  
  const handleClose = () => {
    setState({...state, showAddSensorDialog: false});
  };

  const handleCloseDetailPane = () => {
    setState({...state, showDetailPane: false});
  };

  const [updateFunction] = useMutation(updateSensor);

  console.log('props')
  console.log(props)

  /*
  let shouldPause = props.live || props.realtime ? false : true;

  const handleSubscription = (messages = [], response) => {
    console.log('ZToolsPanel->handleSubscription::response')
    console.log(response)
    return [...response.zeus_tool];
  }
 
  let subQuery = props.nodeType === "ToolKit" ? subscribeToolsForNode : subscribeToolsForLocker;

  let variables = {}
  if(props.nodeType === "ToolKit"){
    variables.parent_id = props.parent.id
  } else {
    console.log('locker')
    console.log(props.parent)
    variables.locker_id = props.parent.id
  }

  const [res] = useSubscription({ 
    query: subQuery, 
    pause: shouldPause,
    variables: variables,
  }, handleSubscription); 

  const { data, fetching, error } = res;

  if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  let title = 'View Sensor';

  if(state.activeEntry !== null){
    title = `View Sensor: ${state.activeEntry.name}`;
  }

  ////////////////////////////

  //use subscription
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return response;
  }

  const [subResult] = useSubscription({ 
    query: subscribeAllSensors, 
    pause: !window.navigator.onLine, 
  }, handleSubscription); 

  const [ result, reexecuteQuery ] = useQuery({
    query: getAllSensors,
    //pause: window.navigator.onLine
  });

  console.log('res');
  console.log(subResult)  

  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = result.data;
    fetching = result.fetching;
    error = result.error;
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  rows = data.zeus_sensor.slice(0)

  console.log("ZSensorsPanel->rows")
  console.log(rows)

  ////////////////////////////

  return (
    <div className={classes.root}>
    <ZSensorDetailPane isOpen={state.showDetailPane} handleClose={handleCloseDetailPane} sensor={state.activeEntry} static/>
    {/* <ZAddJobBoardEntryDialog open={showDialog} handleClose={handleClose}/> */}
    <ZAddToolDialog open={state.showAddToolDialog} parent={props.parent} handleClose={()=>setState({...state, showAddToolDialog: false})} static/>  
    <Card variant="outlined" elevation={2}>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
        {props.title}
        </Typography>
        <IconButton
          onClick={handleClickOpen}
          style={{color:"white"}}
          color="inherit"
          aria-label="add row"
          edge="start"
          data-tip="create new sensor"
          data-for="sensors_panel_tooltip" 
        >
          <AddCircleOutlineIcon/>
        </IconButton>
      </Box>
      <DataGrid autoHeight
        title="Sensors"
        columns={columns}
        rows={rows}
        pageSize={20}
        density="compact"
        onCellEditCommit={handleCellEditCommit}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick={false}
        getRowClassName={(params) =>
          clsx('super-app', {
            positive: params.row.status === 'active',
            anomaly: params.row.status === 'anomaly detected',
            negative: params.row.status !== 'active',
          })
        }
        options={{
          toolbar: true,
          filtering: true,
          search: true,
          grouping: true,
          exportButton: true,
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        filterModel={props.filter}
        onRowDoubleClick={(params, evt) => {
          let entry = params.row;
          console.log("selected sensor")
          console.log(entry)
          setState({...state, activeEntry: entry, showDetailPane: true});
        }}
      />
      </CardContent>
    </Card>
    <ReactTooltip id="sensors_panel_tooltip"/>
    </div>
  );  
}
  
export default ZSensorsPanel;

