import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';

/*
import { useMutation } from '@apollo/client';
import { addAnnotation } from '../../mutations';
*/

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { useMutation, useQuery } from 'urql';
import { addClientNode } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import ZClientNodeTypeAutocomplete from 'components/core/ZClientNodeTypeAutocomplete';//ZClientNodeTypeAutocomplete

/////////////////////

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

/////////////////////

const ZAddClientNodeDialog = (props) => {
  const classes = useStyles();
  const snackbarEl = React.useRef(null);
  const [userData, setUserData] = useLocalStorage('userData');

  const [open, setOpen] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);

  const [state, setState] = React.useState({
    name: '',
    description: '',
    status: 'active',
    node_type: null,
  });

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  ////////////////

  const [insertResult, insertFunction] = useMutation(addClientNode);

  let tempName = '';

  const handleClose = () => {
    if(props.onClose){
      props.onClose();
    } 
  };

  const handleClientNodeTypeCange = (event,item) => {
    console.log('handleClientNodeTypeCange');
    console.log(item);
    setState({...state, node_type: item, node_type_id: item.id});
  };

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    const guuid = uuidv4()

    let clientNode = {
      id: guuid,
      name: state.name,
      description: state.description,
      client_id: props.client.id,
      node_type_id: state.node_type_id,
    }

    if(props.parent){
      clientNode.parent_id = props.parent.id;
    }

    let activities = [{
      user_id: userData.id,
      parent_id: props.client.id,
      name: `created ${state.node_type.name}`,
      description: `${userData.alias} created ${state.node_type.name} ${state.name}`,          
    },
    {
      user_id: userData.id,
      parent_id: guuid,
      name: `created ${state.node_type.name}`,
      description: `${userData.alias} created ${state.node_type.name} ${state.name}`,          
    }]

    insertFunction({
      object: clientNode,
      activities: activities,
    }).then((result)=>{
      if(result.error){
        console.log('ERROR creating client node')
        console.log(result)
      } else {
        console.log('successfully created new client node')
        setAlertState({
          open: true,
          message: `Successfully created ${state.node_type.name}`
        })
      }
      handleClose()
    });
  }

  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to open snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              //setOpenAlert(false)
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    /> 
  );

  return (
    <div>
      <div ref={snackbarEl} />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.title ?? 'Add Plant'}</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <ZClientNodeTypeAutocomplete onChange={handleClientNodeTypeCange} default={state.node_type}/>
                <br/>
                <TextField 
                  onChange={(event) =>{
                    let newName = event.target.value;
                    let isDuplicate = true;
                    props.client.client_nodes.forEach((node)=>{
                      if(node.name !== newName){
                        isDuplicate = false
                      }
                    })
                    setState({...state, name: newName, duplicateName: isDuplicate});
                  }}
                  className={classes.nameField}
                  autoFocus
                  margin="dense"
                  id="nodeName" 
                  label="Name" 
                  variant="standard"
                  error={state.duplicateName}
                  helperText={state.duplicateName && 'duplicate name'}
                  defaultValue={tempName}
                />

                <TextField
                  className={classes.descriptionField}
                  id="description"
                  label="Description"
                  multiline
                  rows={8}
                  onChange={(event) =>{
                    var newState = state
                    newState.description = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" disabled={state.node_type === null ? true : state.name === '' ? true : state.duplicateName}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </div>
  );
}

export default ZAddClientNodeDialog;

/*
sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}
*/