import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';

/*
import { useMutation } from '@apollo/client';
import { addAnnotation } from '../../mutations';
*/

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { useMutation, useQuery } from 'urql';
import { addConditionDescriptorType } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import ZConditionTypeAutocomplete from 'components/core/ZConditionTypeAutocomplete';



/////////////////////

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

/////////////////////

const ZAddConditionDescriptorTypeDialog = (props) => {
  const classes = useStyles();
  const snackbarEl = React.useRef(null);
  const [userData, setUserData] = useLocalStorage('userData');

  /*
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [deleted, setDeleted] = React.useState(false);
  */
 
  const [state, setState] = React.useState({
    name: '',
    parent_id: null,
    description: '',
  });

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });

  const [insertResult, insertFunction] = useMutation(addConditionDescriptorType);

  const handleClose = () => {
    props.onClose()
  };

  const handleConditionTypeChange = (event,item) => {
    console.log('ZAddConditionDescriptorTypeDialog->handleConditionTypeChange')
    console.log(item)
    setState({...state, condition_type: item, parent_id: item.id})
  }

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    var { condition_type, ...object } = state;
    var user_id = userData.id;
    const guuid = uuidv4()
    object.id = guuid
    //setState(newState)

    var activity = {
        user_id: user_id,
        parent_id: guuid,
        name: "condition descriptor type created",
        description: `${userData.alias} created condition descriptor type ${state.name}`,          
    }

    console.log('variables')
    console.log(object)

    //return;

    insertFunction({
      object: object,
      activities: [ activity ],
    }).then((result)=>{
      if(result.error){
        console.log('ERROR creating condition descriptor type')
        console.log(result)
      } else {
        console.log('successfully created condition descriptor type')
        setAlertState({
          open: true,
          message: "Successfully created condition descriptor type"
        })
      }
      handleClose()
    });
  }

  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to open snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              //setOpenAlert(false)
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    /> 
  );

  return (
    <div>
      <div ref={snackbarEl} />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Condition Descriptor Type</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please detail the condition descriptor
              </DialogContentText>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.nameField}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  onChange={(event) =>{
                    setState({...state, name: event.target.value})
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                />
                <br/>
                <TextField
                  className={classes.descriptionField}
                  id="details"
                  label="Description"
                  multiline
                  rows={8}
                  onChange={(event) =>{
                    setState({...state, description: event.target.value})
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
                <br/>
                <ZConditionTypeAutocomplete open={props.open} default={state.condition_type ? state.condition_type : null} onChange={handleConditionTypeChange} />
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </div>
  );
}

export default ZAddConditionDescriptorTypeDialog;

/*
sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}
*/