import React from 'react';
import { useQuery } from 'urql';

//////////////////////////////////

export const usePollingQuery = (args) => {
  const { pollInterval = 60000 } = args;
  const [result, rexecuteQuery] = useQuery(args);

  React.useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(() => rexecuteQuery({
          requestPolicy: 'network-only', 
          isPolling: true  
      }), pollInterval);
      return () => clearTimeout(id);
    }
  }, [result.data]);

  /*
  React.useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(() => rexecuteQuery({
          requestPolicy: 'network-only', 
          isPolling: true  
      }), pollInterval);
      return () => clearTimeout(id);
    }
  }, [result.fetching, result.data]);
  */

  /*
  React.useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(() => rexecuteQuery({
          requestPolicy: 'network-only', 
          isPolling: true  
      }), pollInterval);
      return () => clearTimeout(id);
    }
  }, [result.fetching, rexecuteQuery, pollInterval]);
  */

  return [
    {
      ...result,
      fetching: result.fetching && (!result.operation || !result.operation.context.isPolling),
    },
    rexecuteQuery
  ]
}
