import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllSuppliers, getSupplier } from '../../queries';
*/
import { useQuery, useMutation } from 'urql';
import { getAllSuppliers, getSupplier } from 'queries_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZSupplierAutocomplete = (props) => {
  /*
  const { loading, error, data }  = useQuery(getAllSuppliers);
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log("suppliers")
  console.log(data)
  */
  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllSuppliers
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  return (
    <Autocomplete
      id="choose-supplier-combo-box"
      onChange={(event,item)=>{
        console.log("supplier autocomplete change")
        console.log(item)
        props.onChange(event,item)
      }}
      options={data.zeus_supplier}
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Supplier" variant="outlined" />}
    />
  )
}

export default ZSupplierAutocomplete;