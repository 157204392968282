import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { updateSensorAssignClient, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { uuid } from 'uuidv4';
import moment from 'moment';
import ZClientAutocomplete from 'components/core/ZClientAutocomplete';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  },   
}));

//////////////////////

const ZAssignSensorToClientButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({
    open: false,
    client: undefined,
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [updateSensorResult,     updateSensorFunction]     = useMutation(updateSensorAssignClient);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    setState({...state, open: false})
    if(props.onClose){
      props.onClose();
    }
  }

  const handleClientChange = (event,item) => {
    console.log('setting client')
    console.log(item)
    setState({...state, client_id: item.id, client: item})
  }

  let sensor_id = null;
  if(props.sensor){
    sensor_id = props.sensor.id
  }


  const handleConfirm = () => {
    let id = props.sensor.id;
    let modifiedOn = moment().format();
    let user_id = userData.id

    let variables = { 
      id: id,
      user_id: user_id,
      client_id: state.client_id,
      description: `${userData.alias} updated Sensor ${props.sensor.name} adding to client ${state.client.name}`,
    }

    console.log(variables)

    updateSensorFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        console.log('ZAssignSensorToClientButton::updateGatewayFunction')
        console.log(result);

        insertNotificationFunction({object: {
          name: `Sensor ${props.sensor.name} assigned to Client`,
          description: `${userData.alias} updated Sensor ${props.sensor.name} adding to client ${state.client.name}`,
          role: 'admin',
        }});
                
        setAlertState({
          open: true,
          message: 'Successfully added Client to Sensor',
          severity: 'info',
        })
      }

    })
  }

  ////////////////

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={10000}
              open={state.open}
              key="assign_sensor_to_client"
              onClose={()=>{
                setState({...state, client_id: '', client: undefined, open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={
                <div id="message-id">
                  <ZClientAutocomplete open={state.open} default={state.client} onChange={handleClientChange}/>
                </div>
              }              
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleConfirm}>
                    Submit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                  Close
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
              onClick={(event) => { 
                setState({...state, open: true})
              }}       
              disabled={props.disabled}              
            >
              Assign Client To Sensor
            </Button>
          </div>
        </Router>
      </strong>
    </div>
  )
}

export default ZAssignSensorToClientButton;
