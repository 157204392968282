import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllConditionTypes, getAllConditions, getAllComponents, getClient, 
    getContact, getLocationsForComponent, getComponentLocationsForAsset } from 'queries_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZConditionTypeAutocomplete = (props) => {

  let rows = []
  let skip_query = true;

  if(props.open){
    if(props.open){
      skip_query = false;
    }
  }

  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllConditionTypes,
    pause: skip_query,
  });

  /*
  const { loading, error, data }  = useQuery(getAllClients, {skip: skip_query});
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  

  const [ result, reexecuteQuery ]  = useQuery({
    query: getComponentLocationsForAsset,
    variables: { asset_id: props.asset.id },
    pause: skip_query,
  });

  console.log('ZComponentLocationAutocomplete->result');
  console.log(result)
  */

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.items){
    rows = [...props.items]
  } else {
    rows = [...data.zeus_condition_type]
  }  

  //////////////////  

  if(rows.length > 0){
    console.log("items")
    console.log(rows)
  }

  console.log('skip_query');
  console.log(skip_query);

  return (
    <Autocomplete
      id="choose-condition-combo-box"
      onChange={(event,item)=>{
        console.log("condition autocomplete change")
        console.log(item)
        if(props.onChange){
          props.onChange(event,item)
        }
      }}
      options={props.items ? [ ...props.items ] : rows}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}

      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Condition Type" variant="outlined" />}
    />
  )
}

export default ZConditionTypeAutocomplete;
