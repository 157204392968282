import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { updateTicketClosed, addNotification, updateClientField } from 'mutations_urql';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select'; //FormControl
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ReactTooltip from 'react-tooltip';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { uuid } from 'uuidv4';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  snackbarStyle: {
    backgroundColor: "lightyellow",
    minHeight: 400,
  },
}));

//////////////////////

const ZSetAnomalyDetectionButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const snackbarEl = React.useRef(null);

  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({
    open: false,
    alias: '',
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });


  console.log('ZSetAnomalyDetectionButton->props')
  console.log(props)

  const [updateResult,     updateFunction]     = useMutation(updateClientField);

  const handleClose = () => {
    setState({...state, open: false})
  }

  let client_id = null;
  if(props.client){
    client_id = props.client.id
  }

  ///////////////

  const handleSubmit = () => {
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: client_id, 
      activity: {
        user_id: user_id,
        parent_id: client_id,
        name: "client anomaly detection updated",
        description: `${userData.alias} updated anomaly detection settings for client`,
      },
      _set: {
      orientation: state.orientation,
      modifiedOn: modifiedOn,
    }}

    console.log(variables);

    //return;

    updateFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        setAlertState({
          open: true,
          message: 'Successfully enabled sensor anomaly detection system for client',
          severity: 'info',
        })
      }
    })

    handleClose();
  }


  const handleDisable = () => {
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: client_id, 
      activity: {
        user_id: user_id,
        parent_id: client_id,
        name: "client anomaly detection system disabled",
        description: `${userData.alias} disabled sensor anomaly detection`,
      },
      _set: {
      status: "disabled",
      modifiedOn: modifiedOn,
    }}

    console.log(variables);

    //return;

    updateFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        setAlertState({
          open: true,
          message: 'Successfully disabled sensor anomaly detection system for client',
          severity: 'info',
        })
      }
    })

    handleClose();    
  }

  ////////////////

  let oldReturn = (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={12000}
              open={state.open}
              key="add_orientation"
              onClose={()=>{
                setState({...state, open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={
                <div>
                  <Paper sx={{ maxWidth: 400, my: 10, mx: 'auto', p: 2 }}>
                    <FormControl className={classes.formControl}>
                    <InputLabel id="orientation-select-label">Sensor Orientation</InputLabel>
                      <Select
                        labelId="orientation-select-label"
                        id="orientation-simple-select"
                        value={state.orientation}
                        onChange={(event,item) =>{
                          setState({...state, orientation: item.props.value})
                          console.log(state);
                        }} 
                      >
                        <MenuItem value="HVA">HVA</MenuItem>
                        <MenuItem value="HAV">HAV</MenuItem>
                        <MenuItem value="VAH">VAH</MenuItem>
                        <MenuItem value="VHA">VHA</MenuItem> 
                        <MenuItem value="AHV">AHV</MenuItem>
                        <MenuItem value="AVH">AVH</MenuItem> 
                      </Select>
                    </FormControl>
                  </Paper>  
                </div>                   
              }
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                    No
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary" /> : <div/>}
              onClick={(event) => { 
                setState({...state, open: true})
              }}       
              disabled={props.disabled}               
            >
              Set Orientation
            </Button>
          </div>
        </Router>
      </strong>
    </div>
  );

  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to open snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              //setOpenAlert(false)
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    /> 
  );

  return (
    <div>
      <div ref={snackbarEl} />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Global Anomaly Detection Settings</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <div>
                  <Paper sx={{ maxWidth: 400, my: 10, mx: 'auto', p: 2 }}>
                    <FormControl className={classes.formControl}>
                    <InputLabel id="version-select-label">Model Version</InputLabel>
                      <Select
                        labelId="version-select-label"
                        id="version-simple-select"
                        value={state.version}
                        onChange={(event,item) =>{
                          setState({...state, version: item.props.value})
                          console.log(state);
                        }} 
                      >
                        <MenuItem value="1">1.0</MenuItem>
                        <MenuItem value="1.1">1.1</MenuItem>
                      </Select>
                    </FormControl>
                  </Paper>  
                </div>  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
              <Button onClick={handleDisable} color="primary">
                Disable
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        data-tip="Prodigy:Preventor AI anomaly detection system"
        data-for="anomaly_button_tooltip" 
        startIcon={ props.active ? <CheckIcon color="secondary" /> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true})
        }}       
        disabled={props.disabled}               
      >
        Prodigy:Preventor-Anomaly Detector
      </Button>
      <ReactTooltip id="anomaly_button_tooltip"/>
    </div>
  );

}

export default ZSetAnomalyDetectionButton;


/*

  return (
    <div>
      <div ref={snackbarEl} />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Note</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please detail any interactions
              </DialogContentText>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.nameField}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Subject"
                  onChange={(event) =>{
                    var newState = state
                    newState.name = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                />

                <TextField
                  className={classes.descriptionField}
                  id="details"
                  label="Details"
                  multiline
                  rows={8}
                  onChange={(event) =>{
                    var newState = state
                    newState.description = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </div>
  );


*/


