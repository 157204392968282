import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllQuotations, getAllClients, getClient, getContact, getAllJobBoardEntries } from 'queries_urql';
import { subscribeAllActiveJobBoardEntries, subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZJobNumberAutocomplete = (props) => {
  let rows = []
  let skip_query = true;

  if(props.job){
    skip_query = true
  }

  if(props.data){
    skip_query = true
  }

  if(props.isOpen){
    if(props.isOpen && props.data === undefined){
      skip_query = false;
    }
  }

  console.log(`ZJobNumberAutoComplete skip_query = ${skip_query}`)
  /*
  const { loading, error, data }  = useQuery(getAllClients, {skip: skip_query});
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_tool_by_pk
    return response
    //jobs = data.zeus_jobboard_entry
  };


  const [result, executeSubscription] = useSubscription({ 
    query: subscribeAllActiveJobBoardEntries,
    //pause: skip_query,
  }, handleSubscription); 

  /*
  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllJobBoardEntries,
    pause: skip_query,
  });
  */

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;


  if(props.data) {
    console.log("data")
    console.log(props.data)
  } else {
    console.log("jobs")
    console.log(data)
  }

  return (
    <Autocomplete
      id="choose-job-combo-box"
      onChange={(event,item)=>{
        console.log("job autocomplete change")
        console.log(item)
        props.onChange(event,item)
      }}
      options={props.data ?  props.data : (data.zeus_jobboard_entry ? data.zeus_jobboard_entry : []) }
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Job" variant="outlined" />}
    />
  )
}

export default ZJobNumberAutocomplete;