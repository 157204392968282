import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getUsageForNode } from 'queries_urql';
import { subscribeBookingsForNode, subscribeBookingsForTool } from 'subscriptions_urql';
import { addUsage, updateUsage, deleteBooking } from 'mutations_urql';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CallMissedIcon from '@material-ui/icons/CallMissed';

import ReactTooltip from 'react-tooltip';
import { shouldCanonizeResults } from '@apollo/client/cache/inmemory/helpers';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  route_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    overflowY: 'scroll',
    maxHeight: 400,
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.checkedout': {
      backgroundColor: red[100], //red
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.checkedin': {
      backgroundColor: green[100],   //green
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  button: {
    margin: theme.spacing(5),
  }, 
}));


/*
props : {
  node: {}

}
*/

const ZToolBookingsList = (props) => {
  const history = useHistory();
  //const [routeId, storeRouteId] = useLocalStorage('route_id', '');
  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [userData, setUserData] = useLocalStorage('userData');

  let rows = []

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
          <strong>       
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let booking_id = params.row.id;
                // now delete the booking by id
                handleBookingDelete(booking_id);
              }}          
            >
              <CloseIcon />
            </IconButton>
          </strong>
        </div>
      ),
    },   
    { 
      field: 'name', 
      title: 'Tool', 
      headerName: 'Tool',
      width: props.nameW ?? 220,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params)=>{
        return params.row.tool ? params.row.tool.name : params.row.node ? params.row.node.name : '';
        //return 0
      },
    },
    {
      field: 'theDate',
      title: 'Date',
      headerName: 'Date',
      type: 'date',
      minWidth: 140,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.theDate}
        </Moment>
      )
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descW ?? 240,
      editable: true,
      hide: false,
    },
    {
      field: 'createdOn',
      title: 'CreatedOn',
      headerName: 'CreatedOn',
      type: 'date',
      minWidth: 140,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD hh:mm">
         {params.row.createdOn}
        </Moment>
      )
    },    
  ];

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>CheckOut</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
      </Menu>
    </div>  
  )

  console.log('ZToolBookingsList->props')
  console.log(props)

  let node_id = props.parent.id
  let QUERY = props.parent.__typename === 'zeus_tool_node' ? subscribeBookingsForNode : subscribeBookingsForTool ;

  let skip_query = false;
  let skip_sub = true;

  if(props.static !== undefined){
    console.log('ZToolBookingsList is static')
    skip_query = true;
  }

  if(props.realtime !== undefined || props.live !== undefined){
    console.log('ZToolBookingsList is realtime')
    skip_sub = false;
  }

  let variables = {
    node_id
  }
  
  if(props.parent.node_type === undefined){
    let tool_id = props.parent.id
    variables = {
      tool_id
    }
  }

  console.log('variables')
  console.log(variables)

  ////////////////////////////////

  /*
  const [ result, reexecuteQuery ]  = useQuery({
    query: getUsageForNode,
    variables: variables ,
    pause: skip_query,
  });
  //const { data, fetching, error } = result;
  */

  const [updateUsageResult,     updateUsageFunction] = useMutation(updateUsage);
  const [deleteResult,          deleteBookingFunction] = useMutation(deleteBooking);


  const handleBookingDelete = React.useCallback(
    (booking_id) => {
    console.log('ZToolUsageList::handleBookingDelete');
    console.log(booking_id)
    let booking = null;

    for(var bbb of rows){
      if(bbb.id === booking_id){
        booking = bbb;
      }
    }

    console.log(booking);

    let user_id = userData.id;
    let parent_id = props.parent.id;

    variables = {
      id: booking_id,
      activity: {
        user_id: user_id,
        parent_id: parent_id,
        name: `booking for ${props.parent.name} day deleted`,
        description: `${userData.alias} deleted booking for ${props.parent.name} on ${booking.theDate}`,
      },
    }
    console.log('variables')
    console.log(variables);


    deleteBookingFunction( variables ).then((result)=>{
      if(result.error){
        console.log(`ERROR updating booking: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log("successfully deleted tool booking")
        console.log(result)
        setAlertState({...alertState, open: true, message: 'Successfully deleted tool booking', severity: 'success'})
      }
    })
  },
  []);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZToolUsageList::handleCellEditCommit');
      console.log(id, field, value)
      var _set = {}
      _set[field] = value
      console.log('variables')
      console.log({ id: id, _set })
      const createdOn = moment().format()

      updateUsageFunction( { id: id, createdOn: createdOn, _set } ).then((result)=>{
        if(result.error){
          console.log(`ERROR updating booking: ${result.error}`)
          setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
        } else {
          console.log("successfully updated tool usage")
          console.log(result)
          setAlertState({...alertState, open: true, message: 'Successfully updated tool usage', severity: 'success'})
        }
      })
    },
  [updateUsageFunction]);

  ////////
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return [...response.zeus_tool_user_booking];
  }

  const [res] = useSubscription({ query: QUERY, variables: variables, pause: skip_sub, }, handleSubscription); 
  /////////

  console.log('res');
  console.log(res)  

  const { data, fetching, error } = res;

  if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  //////////

  if(props.data !== undefined){
    rows = props.data;
  } 
  
  if(skip_sub === false){
    rows = data.slice(0)
  }

  console.log('ZToolBookingsList->data');
  console.log(rows);

  //////////
  
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        message={alertState.message}
        key="alert_button"
        onClose={()=>{
          setAlertState({...alertState, open: false})
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alertState, open: false})
              }}
            >
              No
            </Button>
          </div>
        }
      />  
      <div>
        <Card variant="outlined">
          <CardHeader
            avatar={
              <Avatar aria-label="client" className={classes.routes_avatar}>
                <CallMissedIcon />
              </Avatar>
            }      
            action={props.action ? props.action : null}
            title={props.title ? props.title : 'Usage'}
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>
            <Paper className={classes.status}>
              <DataGrid autoHeight
                rows={rows}
                columns={columns}
                pageSize={props.pageSize ?? 20}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                onCellEditCommit={handleCellEditCommit}
                options={{
                  toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  exportButton: true,
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </Paper>
          </CardContent>
          <ReactTooltip id="booking_list_tooltip"/>
        </Card>
      </div>
    </div>
  ); 
}
  
export default ZToolBookingsList;
