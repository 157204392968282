import React from 'react';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';

import { red, green } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllJobBoardEntries } from '../../queries';
import { updateClient, updateJobBoardEntry } from '../../mutations'
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllTools } from 'queries_urql';
import { updateClient, updateTool  } from 'mutations_urql';
import { subscribeAllTools, subscribeToolsForNode, subscribeToolsForLocker } from 'subscriptions_urql';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import LinearProgress from '@material-ui/core/LinearProgress'; //LinearProgress
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //ZJobBoardDetailPane
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';


import ZToolDetailPane from 'components/core/ZToolDetailPane';
import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';

import ZAddJobBoardEntryDialog from 'components/dialogs/ZAddJobBoardEntryDialog';
import ZAddToolNodeDialog from 'components/dialogs/ZAddToolNodeDialog';
import ZAddToolDialog from 'components/dialogs/ZAddToolDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';

import { Link as RouterLink, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

//////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
});

//////////////////////////////////////////////////

const ZToolsPanel = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    showDetailPane: false,
    showBookingDialog: false,
    showAddToolDialog: false,
    open: false,
    activeEntry: null,
    tool_id: null,
  });

  let rows = []

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Router>
            <div>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/tool/:id', {
                    id: params.row.id}
                    )
                  history.push(path)
                }}          
              >
                <OpenInNewIcon />
              </IconButton>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let tool_id = params.row.id;
                  setState({...state, activeEntry: params.row, tool_id: tool_id, showBookingDialog: true})
                }}          
              >
                <CalendarTodayIcon />
              </IconButton>              
            </div>
          </Router>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 250,
      editable: false,
      hide: false,
    },
    {
      field: 'serialNumber',
      title: 'S/N',
      headerName: 'S/N',
      minWidth: 250,
      editable: true,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 500,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'tool_locker',
      title: 'Tool Locker',
      headerName: 'Tool Locker',
      minWidth: 220,
      editable: false,
      hide: false,
      renderCell: (params)=>{
        if(params.row.locker){
          return (
            <div>
              <strong>
                <IconButton 
                  component="span"
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(event) => { 
                    let path = generatePath('/toolnode/:id', {
                      id: params.row.locker.id}
                      )
                    history.push(path)
                  }}     
                >
                  {params.row.locker.name}
                </IconButton>
              </strong>
            </div>
          )
        } else {
          return (<div>N/A</div>)
        }
      }
    },
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];

  //////

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZToolsPanel::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleClickOpen = () => {
    if(props.handleOpen){
      console.log('ZToolsPanel::handleClickOpen props.handleOpen is present handing off to parent');
      props.handleOpen()
      return;
    }
    console.log("ZToolsPanel::handleClickOpen->open add tool dialog")
    setState({...state, showAddToolDialog: true});
    console.log("ZToolsPanel::handleClickOpen == "+ state.showAddToolDialog);
  };
  
  const handleClose = () => {
    setState({...state, showAddToolDialog: false});
  };

  const handleCloseDetailPane = () => {
    setState({...state, showDetailPane: false});
  };

  const handleBookingClose = () => {
    setState({...state, showBookingDialog: false});
  };

  const [updateFunction] = useMutation(updateTool);

  /*
  const { loading, error, data  } = useQuery(getAllJobBoardEntries, {
    pollInterval: 5000,
  });
  const [updateFunction] = useMutation(updateJobBoardEntry);
  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  /*
  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: getAllJobBoardEntries,
  });

  const [updateSalesDocResult, updateFunction] = useMutation(updateJobBoardEntry);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  //use subscription
  /*
  const [ result, reexecuteQuery ] = useQuery({
    query: getAllTools,
  });

  const [updateJobResult, updateFunction] = useMutation(updateTool);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;
  */
  console.log('props')
  console.log(props)

  /*
  let shouldPause = props.live || props.realtime ? false : true;

  const handleSubscription = (messages = [], response) => {
    console.log('ZToolsPanel->handleSubscription::response')
    console.log(response)
    return [...response.zeus_tool];
  }
 
  let subQuery = props.nodeType === "ToolKit" ? subscribeToolsForNode : subscribeToolsForLocker;

  let variables = {}
  if(props.nodeType === "ToolKit"){
    variables.parent_id = props.parent.id
  } else {
    console.log('locker')
    console.log(props.parent)
    variables.locker_id = props.parent.id
  }

  const [res] = useSubscription({ 
    query: subQuery, 
    pause: shouldPause,
    variables: variables,
  }, handleSubscription); 

  const { data, fetching, error } = res;

  if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  let title = 'Book Tool';

  if(state.activeEntry !== null){
    title = `Book Tool: ${state.activeEntry.name}`;
  }

  ////////////////////////////

  return (
    <div className={classes.root}>
    {/* <ZToolDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} entry={activeEntry}/>  */}
    {/* <ZAddJobBoardEntryDialog open={showDialog} handleClose={handleClose}/> */}
    <ZAddToolDialog open={state.showAddToolDialog} parent={props.parent} handleClose={()=>setState({...state, showAddToolDialog: false})} static/>  
    <ZBookToolDialog open={state.showBookingDialog} title={title} handleClose={handleBookingClose} lat={props.lat} lng={props.lng} data={state.activeEntry} static tool/>
    <Card variant="outlined" elevation={2}>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
        {props.title}
        </Typography>
        <IconButton
          onClick={handleClickOpen}
          style={{color:"white"}}
          color="inherit"
          aria-label="add row"
          edge="start"
          data-tip="create new tool"
          data-for="tools_panel_tooltip" 
        >
          <AddCircleOutlineIcon/>
        </IconButton>
      </Box>
      <DataGrid autoHeight
        title="Tools"
        columns={columns}
        rows={props.data ?? []}
        pageSize={20}
        onCellEditCommit={handleCellEditCommit}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick={false}
        getRowClassName={(params) =>
          clsx('super-app', {
            negative: params.row.status !== 'ready',
          })
        }
        options={{
          toolbar: true,
          filtering: true,
          search: true,
          grouping: true,
          exportButton: true,
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        filterModel={props.filter}
        onRowDoubleClick={(params, evt) => {
          let entry = params.row;
          console.log("selected tool")
          console.log(entry)
          setState({...state, activeEntry: entry, showDetailPane: true});
        }}
      />
      </CardContent>
    </Card>
    <ReactTooltip id="tools_panel_tooltip"/>
    </div>
  );  
}
  
export default ZToolsPanel;

