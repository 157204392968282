import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';
import { red, green, cyan, purple } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAnnotationsForSalesDocument } from 'queries';
import { addAnnotation, updateAnnotation } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllConditionDescriptorTypes} from 'queries_urql';
import { updateConditionDescriptorType } from 'mutations_urql';
import { subscribeAssetsForParent } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShutterSpeedIcon from '@material-ui/icons/ShutterSpeed';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ExtensionIcon from '@material-ui/icons/Extension';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import ZAddAssetDialog from 'components/dialogs/ZAddAssetDialog';
import ZAddAssetSurveyDialog from 'components/dialogs/ZAddAssetSurveyDialog';
import AssetBloC from 'blocs/AssetBloC';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  asset_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.overdue': {
      backgroundColor: purple[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },    
  },
  avatar: {
    backgroundColor: green[500],
  },
  annotations_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  items: {}

}
*/

/////////////////////////////////////////

const ZConditionDescriptorTypesList = (props) => {
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    status: 'N/A',
    message: '',
    asset: null,
    showSurveyDialog: false,
    showAssetDialog: false,
  });

  const [anchorHeader, setAnchorHeader] = React.useState(null);

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });
  

  const classes = useStyles();

  const columns = [
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: props.nameW ?? 360,
      editable: true,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 650,
      editable: true,
      hide: false,
    },    
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 180,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
    {
      field: 'modifiedOn',
      title: 'Updated',
      headerName: 'Updated',
      type: 'date',
      minWidth: 180,
      editable: false,
      renderCell: (params) => {
        if(params.row.modifiedOn){
          return(
            <Moment format="YYYY-MM-DD">
              {params.row.modifiedOn}
           </Moment>
          )
        } else {
          return(
            <div></div>
          )
        }
      },
    },    
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZConditionDescriptorTypesList::handleCellEditCommit');

      var _set = {}      
      _set[field] = value
      _set['modifiedOn'] = moment().format()

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set,
        activity: {
          user_id: userData.id,
          parent_id: id,
          name: "condition descriptor type updated",
          description: `${userData.alias} updated condition descriptor type ${field} to ${value}`,            
        }         
      }
      console.log(variables)      

      updateFunction(variables).then(result => {
        if(result.error){
          console.log(`ERROR updating condition descriptor type  ${result.error}`)
          setAlertState({
            open: true,
            message: `ERROR updating condition descriptor type ${result.error}`
          })          
        } else {
          console.log(`updated condition descriptor type `)
          setAlertState({
            open: true,
            message: "Successfully updated condition descriptor type "
          })
        }
      })
    },
    [],
  );

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={()=>setState({...state, showAssetDialog: true})}>New Asset</MenuItem>
      </Menu>
    </div>  
  )

  console.log(props)

  let items = []

  //let skip_query = true;
  let skip_query = false;
  let title = "Condition Descriptor Types"

  if(props.static){
    skip_query = true;
  }

  if(props.realtime || props.live){
    skip_query = false;
  }

  if( props.title){
    title = props.title
  }

  if( props.items){
    skip_query = true;
  }


  console.log(props)

  const [updateResult, updateFunction] = useMutation(updateConditionDescriptorType);

  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllConditionDescriptorTypes,
    //pause: skip_query,
  });
  
  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if(!skip_query){
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_query){
    items = props.items
  } else {
    items = data.zeus_condition_descriptor_type
  }

  console.log(items);

  var now = moment();

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="assets actions"
      data-for="assets_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>
  )

  if(props.addColumns){
    console.log('adding outside columns');
    for(var col of props.addColumns){
      columns.push(col);
    }
  }

  //////////////////////////////////

  return (
    <div>
      {/* <ZAddAssetDialog open={state.showAssetDialog} onClose={()=>setState({...state, showAssetDialog: false})} client={props.client} /> */}
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        message={alertState.message}
        key="alert_bar"
        onClose={()=>{
          console.log('trying to open snackbar')
          //setOpenAlert(false)
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                console.log('trying to close snackbar')
                //setOpenAlert(false)
                setAlertState({
                  open: false,
                  message: ''
                })
              }}
            >
              Close
            </Button>
          </div>
        }
      />     
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="condition_descriptor_type" className={classes.annotations_avatar}>
                <ExtensionIcon />
              </Avatar>
            }      
            action={props.action ? props.action : defaultAction}            
            title={title}
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>         
            <Paper className={classes.asset_row}>
              <DataGrid autoHeight
                rows={items}
                columns={columns}
                pageSize={props.pageSize ? props.pageSize : 10}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                options={{
                  toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  exportButton: true,
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                onCellEditCommit={handleCellEditCommit}
              />
            </Paper>
          </CardContent>
        </CardActionArea>
        <ReactTooltip id="condition_descriptor_types_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZConditionDescriptorTypesList;
