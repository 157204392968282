import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact, getContactsForClient } from '../../queries';
*/
import { useQuery, useMutation } from 'urql';
import { getAllQuotations, getAllClients, getClient, getContact, getContactsForClient } from 'queries_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZContactAutocomplete = (props) => {
  let rows = []
  let client_id = ''
  let skip_query = true;
  

  if( props.client !== undefined){
    skip_query = false
    client_id = props.client.id
  }

  if(props.isOpen !== undefined){
    if(props.isOpen){
      skip_query = false;
    }
  }
  
  /*
  const { loading, error, data }  = useQuery(getContactsForClient, {
    variables: {client_id: props.client.id}
  });

  console.log("contacts")
  console.log(data)

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) return <div></div>;
*/

  const [ result, reexecuteQuery ]  = useQuery({
    query: getContactsForClient,
    variables: { client_id },
    pause: skip_query,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return <div></div>;
  //if (error) return `Error! ${error.message}`;

  if( skip_query ){
    console.log("client")
    console.log(props.client)
  } else {
    console.log("clients")
    console.log(data)
    rows = data.zeus_contact
  }  

  return (
    <Autocomplete
      id="choose-contact-combo-box"
      onChange={(event,item)=>{
        console.log("contact autocomplete change")
        console.log(item)
        props.onChange(event,item)
      }}
      options={rows}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.id === value.id }  
      value={props.default ? props.default : null}
          
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Contact" variant="outlined" />}
    />
  )
}

export default ZContactAutocomplete;
