import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllLocations, getClient, getContact } from 'queries_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

////////////////////////////////

const ZLocationSelect = (props) => {
  let rows = []
  let skip_query = false;

  const [selectionModel, setSelectionModel] = React.useState([]);

  if (props.items) {
    skip_query = true
  }

  if (props.open !== undefined) {
    if (props.open) {
      skip_query = false;
    }
  }


  const columns = [
    { field: 'id', hide: true },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 180,
      editable: false,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 220,
      editable: false,
      hide: false,
    },
  ];

  /*
  const { loading, error, data }  = useQuery(getAllClients, {skip: skip_query});
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [result, reexecuteQuery] = useQuery({
    query: getAllLocations,
    pause: skip_query,
  });

  console.log('ZLocationSelect->result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  //////////////////  

  if (props.items) {
    console.log("items")
    console.log(props.items)
    rows = [...props.items]
  } else {
    console.log("items")
    console.log(data)
    rows = data.zeus_location_type
  }

  return (
    <DataGrid autoHeight
      rows={rows}
      columns={columns}
      pageSize={props.pageSize ?? 10}
      rowsPerPageOptions={[5]}
      checkboxSelection={true}
      onSelectionModelChange={(newSelectionModel) => {
        console.log('newSelectionModel');
        console.log(newSelectionModel);
        var selectedLocations = rows.filter((item)=>{
          if(newSelectionModel.includes(item.id)){
            return item;
          }
        })
        console.log(selectedLocations)
        props.onChange(selectedLocations)
        //setSelectionModel(selectedLocations);
        //handleSelectionModelChange(newSelectionModel);
      }}
      selectionModel={selectionModel}                  
      disableSelectionOnClick={true}
      density="compact"
      options={{
        toolbar: false,
        filtering: true,
        search: true,
        grouping: true,
        exportButton: true,
      }}
    />
  )
}

export default ZLocationSelect;






/*

              <FormControl className={classes.formControl}>
              <InputLabel id="criticality-select-label">Asset Criticality</InputLabel>
                <Select
                  labelId="criticality-select-label"
                  id="criticality-simple-select"
                  value={state.criticality}
                  onChange={handleCriticalityChange}
                >
                  <MenuItem value={1}>Insignificant</MenuItem>
                  <MenuItem value={2}>Minor</MenuItem>
                  <MenuItem value={3}>Moderate</MenuItem>
                  <MenuItem value={4}>Major</MenuItem> 
                  <MenuItem value={5}>Extreme</MenuItem> 
                </Select>
              </FormControl>

*/