import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


import { useLocalStorage } from 'hooks/useStorage';
import usePersistentState from 'hooks/usePersistentState';
import { uploadFileToAzure } from 'azure/AzureBlobAPI';

/*
import { useMutation } from '@apollo/client';
import { getAllClients } from '../../queries';
import { addClient, updateClient } from '../../mutations';
*/

import { useQuery, useMutation } from 'urql';
import { getAllSensorsForClient } from 'queries_urql';
import { addSensor, updateSensor } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

import ZComponentAutocomplete from 'components/core/ZComponentAutocomplete'; 
import ZComponentLocationAutocomplete from 'components/core/ZComponentLocationAutocomplete'; 
import ZClientAutocomplete from 'components/core/ZClientAutocomplete';//ZClientAutocomplete
import ZRouteAutocomplete from 'components/core/ZRouteAutocomplete'; //ZClientNodeTypeAutocomplete
import ZAssetAutocomplete from 'components/core/ZAssetAutocomplete';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';

import  ZWizard  from 'components/core/ZWizard';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

const ZAddSensorWizard = (props) => {
  const classes = useStyles();
  const snackbarEl = React.useRef(null);

  const [userData, setUserData] = useLocalStorage('userData');
  
  const [alertState, setAlertState] = React.useState({
    show: false,
    message: '',
    severity: 'info',
  })

  const [state, setState] = React.useState({
    name: null,
    alias: null,
    description: null,
    status: 'active',
    asset: null,
    asset_id: null,
    component: null,
    component_id: null,
    location: null,
    location_id: null,
    parent: null,
    parent_id: null,
    user: null,
    user_id: null,
    client: null,
    client_id: null,
  })

  console.log('ZAddSensorDialog->props')
  console.log(props)

  let tempName = '';
  let tempAlias = '';

  const handleClose = () => {
    if(props.onClose){
      props.onClose();
    }
  }

  const handleReset = () => {
    setState({
      name: null,
      alias: null,
      description: null,
      status: 'active',
      asset: null,
      asset_id: null,
      component: null,
      component_id: null,
      location: null,
      location_id: null,
      parent: null,
      parent_id: null,
      user: null,
      user_id: null,
      client: null,
      client_id: null,
    })
    handleClose();
  }

  ////////////

  const [insertResult, insertFunction] = useMutation(addSensor);

  ////////////

  const handleReview = () => {
    const createdOn = moment().format()
    setState({
      ...state,
      parent_id: state.parent.id,
      client_id: state.client.id,
      component_type_id: state.component_type.id,
      createdOn: createdOn,
    })
    console.log("handleReview->"+ JSON.stringify(state));
  }

  const handleStatusChange = (event,item) => {
    console.log("handleStatusChange")
    console.log(item)
    //let address = item.
    setState({...state, status: item.props.value})
  }

  const handleAssetChange = (event,item) => {
    console.log("handleAssetChange")
    console.log(item)
    //let address = item.
    setState({...state, asset: item, asset_id: item.id})
  }

  const handleComponentChange = (event,item) => {
    console.log("handleComponentChange")
    console.log(item.props.value)
    //let address = item.
    setState({...state, component: item, component_id: item.id})
  }

  const handleComponentLocationChange = (event,item) => {
    console.log("handleComponentLocationChange")
    console.log(item)
    //let address = item.
    var component = item.component;
    var location = item;
    setState({...state, component: component, component_id: component.id, location: location, location_id: location.id})    
  }

  const handleParentChange = (event,item) => {
    console.log("handleParentChange")
    console.log(item)
    //let address = item.
    setState({...state, parent: item, parent_id: item.id})    
  }

  ////////////

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));

    const { guuid, client, asset, parent, user, component, location, user_id, duplicateName, ...object } = state;
    object.id = uuidv4();

    console.log('object');
    console.log(object);

    let activities = []

    // sensor activity
    activities.push({
      user_id: userData.id,
      parent_id: object.id,
      name: `sensor ${state.name} created`,
      description: `${userData.alias} created sensor ${state.name}`,           
    })

    // client activity
    activities.push({
      user_id: userData.id,
      parent_id: props.client.id,
      name: `sensor ${state.name} created`,
      description: `${userData.alias} created sensor ${state.name} in ${props.client.name}`,           
    })

    // asset activity
    if(state.asset !== null){
      activities.push({
        user_id: userData.id,
        parent_id: state.asset_id,
        name: `sensor ${state.name} created`,
        description: `${userData.alias} created sensor ${state.name} in ${state.asset.name}`,           
      })
    }

    // component activity
    if(state.component !== null){
      activities.push({
        user_id: userData.id,
        parent_id: state.component_id,
        name: `sensor ${state.name} created`,
        description: `${userData.alias} created sensor ${state.name} in ${state.component.name}`,           
      })
    }

    // location activity
    if(state.location !== null){
      activities.push({
        user_id: userData.id,
        parent_id: state.location_id,
        name: `sensor ${state.name} created`,
        description: `${userData.alias} created sensor ${state.name} in ${state.location.name}`,           
      })
    }
    

    insertFunction({
      object: object,
      activities: activities,
    }).then((result)=>{
      if(result.error){
        console.log('ERROR creating sensor')
        console.log(result)
      } else {
        console.log('successfully created new sensor')
        setAlertState({
          open: true,
          message: `Successfully created sensor ${state.name}`
        })
      }
    });

    handleClose()
  }

  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.show}
      message={alertState.message}
      key="alert_button"
      //onClose={()=>{
      //  setAlert({...alert, show: false, message: '', error: false})
      //}}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color={alert.error === true ? "secondary" : "primary"}
            startIcon={<CloseIcon />}
            onClick={()=>{
              setAlertState({...alertState, show: false, message: '', error: false})
            }}
          >
            Close
          </Button>
        </div>
      }
    />     
  )

  //////////////////////////

  return(
    <div>
      <div ref={snackbarEl} />      
      <ZWizard 
        title="Create New Sensor"
        steps={[
          'Enter Sensor Details', 
          'Enter Parent Details', 
          'Enter Survey Details'
        ]}
        open={props.open} 
        onClose={props.onClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        { /*  sensor details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  let newName = event.target.value;
                  let isDuplicate = false;
                  props.client.sensors.forEach((sensor)=>{
                    if(sensor.name === newName){
                      isDuplicate = true
                    }
                  })
                  setState({...state, name: newName, duplicateName: isDuplicate});
                }}
                id="sensorName" 
                label="Sensor S/N" 
                variant="standard"
                error={state.duplicateName}
                helperText={state.duplicateName && 'duplicate serial number'}
                defaultValue={tempName}
              />
            </Paper> 
            <br/>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  let newAlias = event.target.value;
                  let isDuplicate = false;
                  props.client.sensors.forEach((sensor)=>{
                    if(sensor.alias === newAlias){
                      isDuplicate = true
                    }
                  })
                  setState({...state, alias: newAlias, duplicateAlias: isDuplicate});
                }}
                id="sensorAlias" 
                label="Alias" 
                variant="standard"
                error={state.duplicateAlias}
                helperText={state.duplicateAlias && 'duplicate alias'}
                defaultValue={tempAlias}
              />
            </Paper>               
            <br/>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="assetDescription"
                label="Description"
                multiline
                rows={4}
                onChange={(event) =>{
                  setState({...state, description: event.target.value});
                }} 
              />  
            </Paper>              
            <br/>            
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <FormControl className={classes.formControl}>
              <InputLabel id="status-select-label">Sensor Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-simple-select"
                  value={state.status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="pending">pending</MenuItem>
                  <MenuItem value="active">active</MenuItem>
                  <MenuItem value="non-responsive">non-responsive</MenuItem>
                  <MenuItem value="recovered">recovered</MenuItem> 
                  <MenuItem value="lost">lost</MenuItem> 
                  <MenuItem value="low-battery">low battery</MenuItem> 
                  <MenuItem value="retired">retired</MenuItem> 
                </Select>
              </FormControl>
            </Paper>                 
          </Box>
        </Container>
        {/* sensor links */}
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <ZAssetAutocomplete client={props.client} default={state.asset} items={props.assets} open={props.open} onChange={handleAssetChange} />
                <br/>
                { state.asset ? <ZComponentLocationAutocomplete open={props.open} client={props.client} items={state.asset.component_locations} onChange={handleComponentLocationChange} /> : <div></div> }
            </Paper>              
          </Box>
        </Container>    
        {/* review details */}
        <Container maxWidth="sm">
        <Box>
            <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow key='1' selected={state.name === null ? true : false}>
                      <TableCell align="right">Serial Number</TableCell><TableCell align="left">{state.name}</TableCell>
                    </TableRow>

                    <TableRow key='3'> 
                      <TableCell align="right">Description</TableCell><TableCell align="left">{state.description}</TableCell>
                    </TableRow>

                    <TableRow key='4' selected={state.alias === null ? true : false}>
                      <TableCell align="right">Alias</TableCell><TableCell align="left">{`${state.alias}`}</TableCell>
                    </TableRow>

                    <TableRow key='4' selected={state.component === null ? true : false}>
                      <TableCell align="right">Component</TableCell><TableCell align="left">{`${state.component ? state.component.name : ''}`}</TableCell>
                    </TableRow>

                    <TableRow key='4' selected={state.location === null ? true : false}>
                      <TableCell align="right">Location</TableCell><TableCell align="left">{`${state.location ? state.location.name : ''}`}</TableCell>
                    </TableRow>

                    <TableRow key='7'>
                      <TableCell align="right">User</TableCell><TableCell align="left">{userData.alias}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddSensorWizard;

