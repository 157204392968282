import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Moment from 'react-moment';
import moment from 'moment';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

/////////////////////////

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));


const ZToolUsageTimeline = (props) => {
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      
      { props.items.map((state,index)=>{
        let size = props.size || props.items.length 
        let job_number = ""
        if(typeof state.job !== 'undefined'){
          job_number = state.job.name
        }
        if(index < size){
          return (
            <div key={state.id}>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                  <Moment format="YYYY-MM-DD">
                    {state.createdOn}
                  </Moment>
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot/>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {`${state.status} by ${state.user.firstName} ${state.user.lastName} ${job_number}`}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </div>
          )
        } else {
          return null
        }
      })}

    </Timeline>
  )
}


export default ZToolUsageTimeline;