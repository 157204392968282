import React from 'react';
import clsx from 'clsx';

import PropTypes from "prop-types";
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';

import { callMsGraph } from 'msgraph';
import { useMsal, useAccount } from "@azure/msal-react";

import { useQuery, useMutation, useSubscription } from 'urql';
import { getUserByEmail } from 'queries_urql';
import { subscribeNotifications, subscribeUserByEmail } from 'subscriptions_urql';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { useLocalStorage } from 'hooks/useStorage';
import { ContactlessOutlined } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Alert from '@material-ui/lab/Alert';

//////////////////////////

const ZUserAvatar = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = React.useState(null);
  
  const [accessToken, setAccessToken] = useLocalStorage('accessToken');
  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles', []);
  const [userNotifications, setUserNotifications] = useLocalStorage('userNotifications',[]);
  const [userName, setUserName] = useLocalStorage('userName');
  const [userEmail, setUserEmail] = useLocalStorage('userEmail');
  const [userAlias, setUserAlias] = useLocalStorage('alias');

  const [notification, setNotification] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  })

  let notifications = [];
  let alias = ""
  let skip_query = true;

  if(account !== {}){
    console.log('we have an account profile -> skipping query')
    console.log(account)
    skip_query = false;
  } else {
    console.log('we do not have an account profile')
    console.log(account)
    skip_query = true;
  }

  const requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if(permission !== 'granted'){
        throw new Error('Permission not granted for Notification');
    }
    return permission;
  } 

  console.log('userEmail')
  console.log(userEmail)
  //
  const handleSubscription = (messages = [], response) => {
    console.log('ZUserAvatar::handleSubscription::response')
    console.log(response)
    let x = response.zeus_zeus_user[0];
    
    console.log('processing notifications')
    let nontids = []
    userNotifications.forEach((nno)=>{
      console.log(nno)
      nontids.push(nno.id)
    })

    let roles = [];
    x.roles.forEach((item)=>{
      console.log(item)
      //setUserRoles([...userRoles, item.role.name])
      
      roles.push(item.role.name)
      item.role.notifications.forEach((no)=>{
        console.log(no)
        if(!nontids.includes(no.id)){
          new Notification(no.name,{body: no.description, icon: 'trident_blue512.png'})
          setUserNotifications([...userNotifications, no])
          console.log('new notification recieved')
          console.log(no)
        }
      })
    })

    setUserRoles([...roles])
    return response;
  }
 
  ////////////
  let emailAddr = userEmail

  if(account){
    emailAddr = account.username;
    console.log('querying email from account')
    console.log(emailAddr);
    console.log(emailAddr.toLowerCase());
  }

  const [res] = useSubscription({ 
    query: subscribeUserByEmail, 
    variables: {
      email: emailAddr.toLowerCase(),
    },
  }, handleSubscription); 

  const { data, fetching, error } = res;

  ////////////

  console.log('ZUserAvatar->res')
  console.log(res)

  React.useEffect(()=>  {
    requestNotificationPermission().then(permission=>{
      console.log('notification permission')
      console.log(permission)     
    });

    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
  

    if (account) {
      console.log('account');
      console.log(account);
      setUserEmail(account.username);
      setUserName(account.name);
      instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account: account
      }).then((response) => {
        console.log('response');
        console.log(response);
        if(response) {
          console.log('response.accessToken');
          console.log(response.accessToken);
          setAccessToken(response.accessToken);

        }
      });
    }    

    console.log('ZUserAvatar->data');
    console.log(data)
    if(data === undefined) return;

    if (data.zeus_zeus_user.length > 0) {
      setUserData(data.zeus_zeus_user[0])
      setUserAlias(data.zeus_zeus_user[0].alias)
      alias = data.zeus_zeus_user[0].alias;
      console.log('alias');
      console.log(alias);

      let xxx = data.zeus_zeus_user[0];
      let roles = [];
      xxx.roles.forEach((item)=>{
        let r = item.role;
        roles.push(r.name);
        //setUserRoles([...userRoles, r.name]);
      })

      
      //console.log('userRoles');
      //console.log(userRoles);
      //console.log(roles)
    }
  },[data,account,instance,userData,userAlias])

  if (fetching) return (<h2>Loading... </h2>);
  if (error) {
    console.log('error');
    console.log(error);
    return (<div>{error}</div>);
  }
  
  if (data.zeus_zeus_user.length === 0) return (<div>{"User not found"}</div>);
    
  console.log('ZUserAvatar->email');
  console.log(userEmail);

  console.log('ZUserAvatar->userRoles');
  console.log(userRoles);

  console.log('ZUserAvatar->data');
  console.log(data);

  return (
    <div>
      {/* notification component */} 
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={notification.show}
        key="notification_button"
        onClose={()=>{
          setNotification({...notification, show: false, message: '', severity: 'info'})
        }}
      >
        <Alert severity={alert.severity} 
          onClose={()=>{
            setNotification({...notification, show: false, message: '', severity: 'info'})
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar> 
      <Avatar>{userAlias}</Avatar>  
    </div>
      
  )

}


export default ZUserAvatar;

