import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, yellow, orange, grey } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAssetsForClient } from 'queries_urql';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, ZAxis, Label, ReferenceLine, ResponsiveContainer, CartesianGrid, Tooltip, PieChart, Pie, Sector, ScatterChart, Scatter, Cell } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import eventBus from 'utils/EventBus';
import moment from 'moment';

//////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

//////////////////////////
/*
props {
  data: []
  title: string
}
*/
//////////////////////////

const getAssetTags = (assets) => {
  let tags = [];
  for(let asset of assets){
    tags.push(asset.name);
  }

  //console.log(tags);
  return tags.join();
}

const ZCustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    //console.log(active,payload,label);
    let criticality_str = payload[0].name.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    let criticality = payload[0].value === 1 ? 'Insignificant' : payload[0].value === 2 ? 'Minor' : payload[0].value === 3 ? 'Moderate' : payload[0].value === 4 ? 'Major' : payload[0].value === 5 ? 'Extreme' : 'N/A' ;

    let severity_str = payload[1].name.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    let severity = payload[1].value === 1 ? 'Good' : payload[1].value === 2 ? 'Acceptable' : payload[1].value === 3 ? 'Reportable' : payload[1].value === 4 ? 'Unsatisfactory' : payload[1].value === 5 ? 'Unacceptable' : 'N/A';

    let asset_count_str = payload[2].name;
    let asset_count = payload[2].payload.entries.length;
    let assets = payload[2].payload.entries;

    return (
      <div className="custom-tooltip">
        <p className="label">{`${criticality_str} : ${criticality}`}</p>
        <p className="label">{`${severity_str} : ${severity}`}</p>
        <p className="label">{`${asset_count_str} : ${asset_count}`}</p>
        <p className="desc">{`Tags: ${getAssetTags(assets)}`}</p>
      </div>
    );
  }

  return null;
};

//////////////////////////

const BUBBLE_MULTIPLIER = 1; // dynamic range 1 - 200 // actual range 1 - number of active assets in client 

const ZRiskCriticalityPlot = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  let { id } = useParams();

  const [alert, setAlert] = React.useState({
    show: false,
    message: '',
    error: false,
  })

  const COLORS = [green[200], green[100], yellow[100], orange[100], red[100], grey[200], grey[100]];

  let temp_rows = new Map();
  let rows = [];
  let shouldPause = true;


  if(props.data === undefined){
    shouldPause = false;
    console.log('ZRiskCriticalityPlot->running query');
  } else {
    console.log('props.data');
    console.log(props.data)
  }

  /////////////

  console.log('setting up query')

  const [ result, reexecuteQuery ] = useQuery({
    query: getAssetsForClient, 
    variables: { 
      client_id: id, 
    },
    pause: shouldPause,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if(shouldPause === false){

    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;

    console.log('ZRiskCriticalityPlot -> processing query results')

    for(let asset of data.zeus_asset){

      if(asset.currentCondition === 'Good'){
        asset.severity = 1;
      }

      if(asset.currentCondition === 'Acceptable'){
        asset.severity = 2;
      }

      if(asset.currentCondition === 'Reportable'){
        asset.severity = 3;
      }

      if(asset.currentCondition === 'Unsatisfactory'){
        asset.severity = 4;
      }

      if(asset.currentCondition === 'Unacceptable'){
        asset.severity = 5;
      }

      if(asset.severity){
        let key = `${asset.criticality}-${asset.severity}`;
        if(temp_rows.has(key)){
          temp_rows[key].push(asset);
        } else {
          temp_rows.set(key, [asset]);
        }   
      }

      console.log(asset);
    }


    console.log('temp_rows');
    console.log(temp_rows);
    console.log(temp_rows.entries())

    for(const [key, value] of temp_rows.entries()){
      console.log('temp_rows [k,v]');
      console.log(`${key}-${value}`);

      let criticality = Number(key.split('-')[0]);
      let severity = Number(key.split('-')[1]);
      rows.push({
        key: key,
        entries: value,
        x: criticality,
        y: severity,
        z: value.length * BUBBLE_MULTIPLIER,
      })
    }

    console.log('ZRiskCriticalityPlot->temp_rows');
    console.log(temp_rows);

    console.log('ZRiskCriticalityPlot->rows');
    console.log(rows);

  } else {

    console.log('ZRiskCriticalityPlot -> processing data in props')

    props.data.forEach((asset)=>{
      if(asset.currentCondition === 'Good'){
        asset.severity = 1;
      }

      if(asset.currentCondition === 'Acceptable'){
        asset.severity = 2;
      }

      if(asset.currentCondition === 'Reportable'){
        asset.severity = 3;
      }

      if(asset.currentCondition === 'Unsatisfactory'){
        asset.severity = 4;
      }

      if(asset.currentCondition === 'Unacceptable'){
        asset.severity = 5;
      }

      if(asset.severity){
        let key = `${asset.criticality}-${asset.severity}`;
        if(temp_rows.has(key)){
          temp_rows[key].push(asset);
        } else {
          temp_rows.set(key, [asset]);
        }   
      }

      console.log(asset);
    }) 

    console.log('temp_rows');
    console.log(temp_rows);
    console.log(temp_rows.entries())    

    for(const [key, value] of temp_rows.entries()){
      console.log('temp_rows [k,v]');
      console.log(`${key}-${value}`);

      let criticality = Number(key.split('-')[0]);
      let severity = Number(key.split('-')[1]);
      rows.push({
        key: key,
        entries: value,
        x: criticality,
        y: severity,
        z: value.length * BUBBLE_MULTIPLIER,
      })
    }

    console.log('temp_rows')
    console.log(temp_rows)
  }

  const downloadFile = (file) => {
    // Create a link and set the URL using `createObjectURL`
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = URL.createObjectURL(file);
    link.download = file.name;
  
    // It needs to be added to the DOM so it can be clicked
    document.body.appendChild(link);
    link.click();
  
    // To make this work on Firefox we need to wait
    // a little while before removing it.
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 0);
  }

  const handleCellClick = (item) => {
    let entries = item.entries;
    console.log('handleCellClick->entries');
    console.log(entries);

    let ddate = moment().format('YYYY-MMM-DD');
    let fileName = `${props.client.name}-risk_criticality-${ddate}.csv`;
    console.log(fileName);

    // Dynamically create a File
    let lines = [`Tag,Description,Criticality,Condition,Survey Date,Summary,Recommendations\n\r`];
    for(let asset of entries){
      asset.summary = '';
      asset.recommendations = '';
      if(asset.surveys){
        if(asset.surveys.length > 0){
          let survey = asset.surveys[0];
          asset.summary = survey.summmary ? survey.summmary : '';
          asset.recommendations = survey.recommendations ? survey.recommendations : '';
          asset.theDate = survey.theDate;
        }
      }
      let row = `${asset.name},${asset.description},${asset.criticality},${asset.currentCondition},${asset.theDate},${asset.summary},${asset.recommendations}\n\r`;
      lines.push(row)
    }
    
    console.log('handleCellClick->lines');
    console.log(lines);

    const myFile = new File(lines, fileName, {type: "text/csv"});
    downloadFile(myFile);
  }

  ////////////////////////

  return (
    <div>
      {/* alert component */}
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alert.open}
        key="alert_bar"
        onClose={()=>setAlert({...alert, open: false})}
      >   
        <Alert severity={alert.severity} onClose={()=>setAlert({...alert, open: false})}>
          {alert.message}
        </Alert>       
      </Snackbar> 
      {/* main component */}    
      <ZTitle>Criticality Plot</ZTitle>
      <ScatterChart
        width={600}
        height={600}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }}
      >
        <CartesianGrid />
        <XAxis label={{ value: 'Criticality',  position: 'insideBottomRight', offset: -10 }} type="number" dataKey="x" name="criticality" domain={[1,5]}/>
        <YAxis label={{ value: 'Severity', angle: -90, position: 'insideLeft', offset: 10  }} type="number" dataKey="y" name="severity" domain={[1,5]} />
        <ZAxis type="number" dataKey="z" name="# of assets" domain={[1, props.client ? props.client.assets_aggregate.aggregate.count : props.data.length]} range={[50,10000]} />
        <ReferenceLine stroke="red"    strokeDasharray="3 3" segment={[{ x: 2, y: 5 }, { x: 5, y: 2 }]} />
        <ReferenceLine stroke="orange" strokeDasharray="3 3" segment={[{ x: 1, y: 4 }, { x: 4, y: 1 }]} />

        <Tooltip content={<ZCustomTooltip />} />
        <Scatter name="Assets" data={rows} fill="#8884d8">
          {rows.map((item) => {
            //console.log(item);
            return (
              <Cell key={`cell-${item.key}`} fill={COLORS[item.y -1]} onClick={()=> { handleCellClick(item) }} />
            )
          })}
        </Scatter>
      </ScatterChart>
    </div>
  );
}


export default ZRiskCriticalityPlot;

/*
onClick={()=>handleCellClick(item)}

<Tooltip cursor={{ strokeDasharray: "3 3" }} />

        <Tooltip formatter={(value,name,props)=>{
          if(name === '# of assets'){
            return [value/100, name, props]
          } else return [value, name, props]
        }}/>
*/

