import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getScheduleForJob, getRoutesForClient, getToolKitsForNode } from 'queries_urql';
import { updateJobBoardEntry, updateJobBoardSchedule, deleteJobBoardSchedule } from 'mutations_urql';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import BuildIcon from '@material-ui/icons/Build';

import ReactTooltip from 'react-tooltip';

import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  route_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //red
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100],   //green
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  client: {}

}
*/

const styles = mergeStyleSets();

const ZScheduleList = (props) => {
  const history = useHistory();
  //const [routeId, storeRouteId] = useLocalStorage('route_id', '');
  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [showBookingDialog, setShowBookingDialog] = React.useState(false);
  const [activeEntry, setActiveEntry] = React.useState({});
  const [showCalendarCallout, setShowCalendarCallout] = React.useState(false);

  const [userData, setUserData] = useLocalStorage('userData');

  let rows = []

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              data-tip="delete date"
              data-for="schedule_list_tooltip"           
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let node = params.row
                console.log('deleting date')
                console.log(node.theDate)
                handleDeleteScheduledDate(node)
              }}          
            >
              <CloseIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'dow',
      title: 'Day',
      headerName: 'Day',
      minWidth: 100,
      editable: false,
      hide: false,
    },    
    {
      field: 'theDate',
      title: 'Date',
      headerName: 'Date',
      minWidth: 120,
      editable: false,
      hide: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.theDate}
        </Moment>
      )      
    },
    {
      field: 'user',
      title: 'User',
      headerName: 'User',
      minWidth: 120,
      editable: true,
      hide: false,
      renderCell: (params) => (
         <div>{params.row.technician.alias}</div>
      )      
    },    
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 380,
      editable: true,
      hide: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 140,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
  ];

  /*
  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZRoutesList::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );
  */
  
  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Kit</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
      </Menu>
    </div>  
  )

  let QUERY = getScheduleForJob;
  let node_id = props.parent.id
  let skip_query = false;
  let skip_sub = true;
  let variables = {}

  if(props.static !== undefined){
    skip_query = true;
  }

  if(props.realtime || props.live){
    skip_sub = true;
  }

  console.log('ZScheduleList->props');
  console.log(props)

  if(props.data){
    rows = props.data.slice(0)
    rows.map((day)=>{
      var d = moment(day.theDate)
      var dd = d.format("dddd");
      day.dow = dd;
    })
  }

  console.log('ZScheduleList->data');
  console.log(rows);

  const [ result, reexecuteQuery ] = useQuery({
    query: QUERY,
    pause: skip_query,
  });
  
  const [updateDateResult, updateScheduleFunction] = useMutation(updateJobBoardSchedule);
  const [deleteDateResult, deleteDateFunction] = useMutation(deleteJobBoardSchedule);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZScheduleList::handleCellEditCommit');
      console.log(id, field, value)
      var _set = {}
      _set[field] = value
      console.log('variables')
      console.log({ id: id, _set })
      const createdOn = moment().format()

      updateScheduleFunction( { id: id, user_id: userData.id, createdOn: createdOn, _set } ).then((result)=>{
        if(result.error){
          console.log(result.error)
          setMessage(`ERROR: ${result.error}`)
          setOpenAlert(true)
        } else {
          console.log("successfully updated tool ")
          setMessage("successfully updated tool ")
          setOpenAlert(true)
        }
      })
    },
  [updateScheduleFunction]);


  const handleDeleteScheduledDate = (node) => {
    console.log('ZScheduleList::handleDeleteScheduledDate');
    console.log(node.id)
    var user_id = userData.id;

    var activity = {
      user_id: user_id,
      parent_id: props.parent.id,
      name: "job scheduled date deleted",
      description: `${userData.alias} updated job board entry deleted scheduled date ${node.theDate} from job ${props.parent.name}`,
    }

    deleteDateFunction( { id: node.id, activity: activity } ).then((result)=>{
      if(result.error){
        console.log(result.error)
        setMessage(`ERROR: ${result.error}`)
        setOpenAlert(true)
      } else {
        console.log("successfully deleted date ")
        setMessage("successfully deleted date ")
        setOpenAlert(true)
      }
    })
  }

  const handleSubscription = (messages = [], response) => {
    console.log('ZScheduleList::handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };
 
  //const [res] = useSubscription({ query: subscribeAllTools, pause: skip_sub, }, handleSubscription); 

  ////////////////////

  const defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="tool actions"
      data-for="schedule_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>    
  )

  ////////////////////
  
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={openAlert}
        message={message}
        key="alert_button"
        onClose={()=>{
          setOpenAlert(false)
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                setOpenAlert(false)
              }}
            >
              Close
            </Button>
          </div>
        }
      />  
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="schedule" className={classes.routes_avatar}>
              <EventIcon />
            </Avatar>
          }      
          action={props.action ? props.action : defaultAction}
          
          title={props.title ? props.title : 'Tools'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper id='tool-data-grid' className={classes.route_row}>
            <DataGrid autoHeight           
              rows={rows}
              columns={columns}
              pageSize={props.pageSize ? props.pageSize : 7}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={handleCellEditCommit}
              onRowClick={(params, evt) => {
                let toolkit = params.row;
                console.log("selected tool")
                console.log(toolkit)
                setActiveEntry(toolkit)
                setShowCalendarCallout(true)
              }}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="schedule_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZScheduleList;
