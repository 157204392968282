import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const ZSeverityEditInputCell = (props) => {
    const { id, value, api, field } = props;
    //const classes = useStyles();
  
    const handleChange = (event) => {
      api.setEditCellValue({ id, field, value: event.target.value }, event);
      // Check if the event is not from the keyboard
      // https://github.com/facebook/react/issues/7407
      if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, 'view');
      }
    };

    const handleChange_1 = (event) => {
      api.setEditCellValue({ id, field, value: Number(event.target.value) }, event);
      // Check if the event is not from the keyboard
      // https://github.com/facebook/react/issues/7407
      if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, 'view');
      }
    };    
  
    const handleRef = (element) => {
      if (element) {
        element.querySelector(`input[value="${value}"]`).focus();
      }
    };
  
    return (
      <div>
        <Select
          labelId="severity-select-label"
          id="severity-select"
          value={value}
          onChange={handleChange}
        >
          {props.children}
        </Select>
      </div>
    );
}

export default ZSeverityEditInputCell;