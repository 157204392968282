
export const msalConfig = {
  development: {
    auth: {
      clientId: "e3db0384-2bc7-4486-9454-ce29faa115e1",
      authority: "https://login.microsoftonline.com/dbdb9742-5722-44bf-8fab-23592bf5565e",
      redirectUri: "http://localhost:3000",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  },
  staging: {
    auth: {
      clientId: "e3db0384-2bc7-4486-9454-ce29faa115e1",
      authority: "https://login.microsoftonline.com/dbdb9742-5722-44bf-8fab-23592bf5565e",
      redirectUri: "https://dev-zeus.primac.app",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  },
  production: {
    auth: {
      clientId: "32b45f6b-fe61-4925-8f59-7d265d02859e",
      authority: "https://login.microsoftonline.com/dbdb9742-5722-44bf-8fab-23592bf5565e",
      redirectUri: "https://zeus.primac.app",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com"
};