import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem'; //ZAnnotationInputMenuItem
import ZAnnotationInputMenuItem from 'components/core/ZAnnotationInputMenuItem'; 
import ZAttachmentItem from 'components/core/ZAttachmentItem';  //ZAttachmentItem
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog'; // ZAddQuotationDialog

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

//const ZClientBox = React.forwardRef(({style, className, ...props}, ref) => {
const ZContactBox = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);

  const handleMenuClick = (event) => {
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  }

  const handleClickOpen = () => {
    console.log("ZContactBox::handleClickOpen->open dialog wizard")
    setShowDialog(true);
    console.log("ZContactBox::handleClickOpen == "+ showDialog);
  };

  const handleAddFileForDocument = () => {

  }

  const handleLogActivityForDocument = () => {

  }

  const handleClose = () => {
    console.log('handleClose')
    setShowDialog(false);
  }



  let annotations = []
  if( props.annotation !== undefined){
    annotations = props.annotations
  }

  const addQuotationElement = (
    <div>
      {/* */}
      <Button variant="outlined" color="primary"
        onClick={handleClickOpen}
      >Create Quotation</Button> 
      
      <ZAddQuotationDialog open={showDialog} handleClose={handleClose}/>
    </div>
  )

  const addFileElement = (
    <div>
      <ZFileCloudUploadMenuItem contact_id={props.contact.id} dirName={"attachments"} />
    </div>
  )

  const addAnnotationElement = (
    <div>
      <ZAnnotationInputMenuItem contact_id={props.contact.id} />
    </div>
  )

  let contactNotes = (<div></div>)
  let smallChips = (<div></div>)

  if(typeof props.fullBox !== 'undefined'){
    contactNotes = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="contact_box_tooltip" 
            aria-label="add note">
            <ShareIcon />
          </IconButton>
        
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="contact_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Notes</Typography>
            {annotations.map((note) => {
              return (
                <div key={note.id}>
                  <Card>
                    <CardHeader>{note.name}</CardHeader>
                    <CardContent>{note.description}</CardContent>
                  </Card>
                </div>             
              );
            })}
          </CardContent>
        </Collapse>
      </div>
    )

    smallChips = (
      <div>
        <Chip color="primary"   label={'Active Quotes:'+ props.pending_quotations_aggregate.aggregate.count} />
        <Chip                   label={'Won Quotes:'+ props.accepted_quotations_aggregate.aggregate.count} />
        <Chip color="secondary" label={'Lost Quotes:'+ props.lost_quotations_aggregate.aggregate.count} />                     
        <br/><Divider/>
        <Chip color="primary"   label={'Active Quotes: $'+ props.pending_quotations_aggregate.aggregate.sum.amount} />
        <Chip                   label={'Won Quotes: $'+ props.accepted_quotations_aggregate.aggregate.sum.amount} />
        <Chip color="secondary" label={'Lost Quotes: $'+ props.lost_quotations_aggregate.aggregate.sum.amount} />   
    </div>      
    )
  }

  let title = props.contact.name;
  if(typeof props.title !== 'undefined'){
    console.log('creating big title')
    title = (
      <div>
        <Typography  variant='overline' color='primary'>{props.title}</Typography>
        <Typography variant='h3'>{props.name}</Typography>
      </div>      
    )
  }

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorMenu}
        keepMounted
        open={Boolean(anchorMenu)}
        onClose={handleCloseMenu}
      >
        {/* 
        <Divider variant="middle"/>
        <MenuItem>{addQuotationElement}</MenuItem>
         */}
        <Divider variant="middle"/>
        <MenuItem>{addFileElement}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{addAnnotationElement}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem onClick={handleCloseMenu}>Close</MenuItem>
        <Divider variant="middle"/>
      </Menu>
    </div>  
  )

  /////////////////////////

  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="contact" className={classes.avatar}>
              <ContactPhoneIcon />
            </Avatar>
          }
          action={
            <Fab
              data-tip="contact actions"
              data-for="contact_box_tooltip"
              //color="primary"
              aria-label="add"
              size="small"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
              { headerMenu }
            </Fab>
          }
          title={title}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              Created On:&nbsp;
              <Moment format="YYYY-MM-DD">
              {props.createdOn}
              </Moment>
            </div>
          }
        />
        <CardContent>
          {
            smallChips
          }
          <Typography>{props.contact.description}</Typography>
        </CardContent>
        { contactNotes }
      </Card>
      <ReactTooltip id="contact_box_tooltip"/>
    </div>
  );
};


export default ZContactBox;