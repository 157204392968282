import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
/*
import { useQuery } from '@apollo/client';
import { getFunnelsQuery } from 'queries';
import { getClient,  getQuotesForClient } from 'queries';
import { addClient, updateClient } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getFunnelsQuery, getClient, getQuotesForClient } from 'queries_urql';
import { addClient, updateClient  } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReactTooltip from 'react-tooltip';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';

import ZMSalesChart from 'components/charts/ZMSalesChart';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZSalesToDateBox from 'components/boxes/ZSalesToDateBox';// ZSalesToDate
import ZSalesLostBox from 'components/boxes/ZSalesLostBox';//ZSalesLostBox
import ZMSalesDeltaChart from 'components/charts/ZMSalesDeltaChart';//ZMSalesDeltaChart
import ZSalesStatsBox from 'components/boxes/ZSalesStatsBox';// ZSalesStatsBox
import ZSalesTimeStatsBox from 'components/boxes/ZSalesTimeStatsBox';// ZSalesTimeStatsBox
import ZPendingSalesStatsBox from 'components/boxes/ZPendingSalesStatsBox';// ZPendingSalesStatsBox
import ZQuotationsPanel from 'components/panels/ZQuotationsPanel';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

////////////////////////////

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////////

const ZSalesMonthlyPanel = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth()+1);
  const [supplier, setSupplier] = React.useState('All');
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (event, params) => {
    console.log('handleExpandClick')
    console.log('params')
    console.log(params)
    console.log('event')
    console.log(event)

    setExpanded(!expanded);    
  };

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const getQuarter = (d) => {
    d = d || new Date(); // If no date supplied, use today
    //var q = [4,1,2,3];
    var q = [1,2,3,4];
    return q[Math.floor(d.getMonth() / 3)];
  }

  const computeFunnel = () => {
    var now = new Date()
    var year = now.getFullYear()
    var quarter = getQuarter()
    console.log("quarter: "+ quarter)
    var fff = year +" Q"+ quarter +" Pipeline";
    //setFunnel(fff)
    console.log("pipeline: "+ fff)
    return fff;
  }

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  const this_year = new Date().getFullYear();
  const last_year = year - 1;

  /*
  var { loading, error, data }  = useQuery(getFunnelsQuery);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  /*
  const [ result, reexecuteQuery ]  = useQuery({
    query: getFunnelsQuery,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  return (
    <div>
      <Card>
        <Paper>
          <Container fluid>
            <Row>
              <Col xs={3}>
                <div/>
              </Col>
              <Col xs={3}>
                <div>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <TextField 
                      onKeyPress={(event) =>{
                        if(event.key === "Enter"){
                          console.log("setYear->"+ JSON.stringify(event.target.value));
                          setYear(event.target.value);
                        }
                      }}
                      id="salesYear" 
                      label={year} 
                      variant="standard"
                    />
                  </FormControl>
                </div>
              </Col>
              <Col xs={3}>
                <div>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-month-native-simple">Month</InputLabel>
                    <Select
                      native
                      onChange={(event)=>{
                        let mon = event.target.value
                        console.log()
                        setMonth(mon)
                      }}
                      inputProps={{
                        name: 'month',
                        id: 'outlined-funnel-native-simple',
                      }}                      
                      value={month}
                      label="Month"
                    >
                      <option key="Jan" value={1}>Jan</option>
                      <option key="Feb" value={2}>Feb</option>
                      <option key="Mar" value={3}>Mar</option>
                      <option key="Apr" value={4}>Apr</option>
                      <option key="May" value={5}>May</option>
                      <option key="Jun" value={6}>Jun</option>
                      <option key="Jul" value={7}>Jul</option>
                      <option key="Aug" value={8}>Aug</option>
                      <option key="Sep" value={9}>Sep</option>
                      <option key="Oct" value={10}>Oct</option>
                      <option key="Nov" value={11}>Nov</option>
                      <option key="Dec" value={12}>Dec</option>
                    </Select>
                  </FormControl>
                </div>
              </Col>
              <Col xs={3}>
                <div>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-supplier-native-simple">Supplier</InputLabel>
                    <Select
                      native
                      onChange={(event)=>{
                        let supplier = event.target.value
                        console.log()
                        setSupplier(supplier)
                      }}
                      inputProps={{
                        name: 'product',
                        id: 'outlined-supplier-native-simple',
                      }}                      
                      value={supplier}
                      label="Supplier"
                    >
                      <option key="All" value="All">All</option>
                      <option key="CTC" value="CTC">CTC</option>
                      <option key="SDT" value="SDT">SDT</option>
                      <option key="SRV" value="SRV">SRV</option>
                    </Select>
                  </FormControl>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <ZSalesToDateBox key='month_sales_total' year={this_year}                     
                  onClick={(event)=>{
                    console.log('expand')
                    handleExpandClick(event,{
                      items: [{ columnField: 'status', operatorValue: 'contains', value: 'accepted' }],
                    })
                  }} />
              </Col>
              <Col xs={3}>
                <ZPendingSalesStatsBox id='funnel_sales_lost' supplier={supplier} month={month} year={year}                    
                  onClick={(event)=>{
                    console.log('expand')
                    handleExpandClick(event,{
                      items: [{ columnField: 'status', operatorValue: 'contains', value: 'pending' }],
                    })
                  }} />
              </Col>
              <Col xs={3}>
                <ZSalesStatsBox id='sales_stats' supplier={supplier} month={month} year={year} />
              </Col>
              <Col xs={3}>
                <ZSalesTimeStatsBox id='funnel_time_sales_stats' supplier={supplier} month={month} year={year} />
              </Col>
            </Row>
            <Row><br/></Row>
            <CardActions disableSpacing>       
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show quotations in funnel"
                data-tip="show quotations for funnel"
                data-for="funnel_box_tooltip" 
              >
                <ExpandMoreIcon />
              </IconButton>           
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Row><br/></Row>
              <Row>
                <Col xs={9}>
                  <ZMSalesChart id='monthly_sales_chart' supplier={supplier} year={year} />
                </Col>
                <Col xs={3}>
                  
                </Col>
              </Row>
              <Row><br/></Row>
              <Row>
                <Col xs={6}>
                  <ZMSalesDeltaChart id='sales_deltas' year={year} width={600} height={400} />
                </Col>
                <Col xs={6}>
                  <ZMSalesDeltaChart id='sales_deltas_pct' year={year} width={600} height={400} percentages/>
                </Col>
              </Row>
              <Row><br/></Row>
              <Row>
                <Col xs={9}>
                  <ZMSalesChart id='prev_monthly_sales_chart' supplier={supplier} year={last_year} />          
                </Col>
                <Col xs={4}>

                </Col>
              </Row>
              <Row><br/></Row>
              <Row>
                <Col xs={12}>
                  <ZQuotationsPanel id='funnel_quotations' supplier={supplier} month={month} year={year} />
                </Col>
              </Row>
            </Collapse>
          </Container>
        </Paper>
      </Card>
    <ReactTooltip id="funnel_box_tooltip"/>
    </div>
  )
}

export default ZSalesMonthlyPanel

/*
                  <ZSalesLostBox id='funnel_loss_stats' year={this_year} 
                    onClick={(event)=>{
                      console.log('expand')
                      handleExpandClick(event, {
                        items: [{ columnField: 'status', operatorValue: 'contains', value: 'closed' }],
                      })
                    }} 
                  />
*/