import React from 'react';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getTool,  getAllToolsForNode } from 'queries_urql';
import { addClient, updateClient, updateToolNode } from 'mutations_urql';
import { subscribeClient } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZToolBox from 'components/boxes/ZToolBox';
import ZToolUsageList from 'components/lists/ZToolUsageList';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ZToolsPanel from 'components/panels/ZToolsPanel';  
import ZToolKitsList from 'components/lists/ZToolKitsList'; //ZToolKitsList
import ZCheckInButton from 'components/buttons/ZCheckInButton';
import ZCheckOutButton from 'components/buttons/ZCheckOutButton';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const ZToolEventView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  let { id } = useParams();
  const [state, setState] = React.useState({});
  const [showDialog, setShowDialog] = React.useState(false);
  const [lat, setLat] = React.useState(null);
  const [lng, setLng] = React.useState(null);

  let row = {}

  if(props.checkin !== undefined){
    console.log("ZToolEventView -> result of a toolnode check-in")
  }

  /////////////////

  React.useEffect(()=>{
    navigator.geolocation.watchPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolEventView [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  }, [setLat,setLng]);

  /////////////////

  const [ result, reexecuteQuery ] = useQuery({
    query: getTool,
    variables: { id },
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  row = data.zeus_tool_node_by_pk

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let the_button = (
    <ZCheckOutButton key='tool_checkout' id={row.id} data={row} tool/>
  )

  if(row.status === 'in-use'){
    the_button = <ZCheckInButton key='tool_checkin' id={row.id} data={row} tool/> 
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={8}>
            <ZToolBox id='tool_box' 
              tool={row}
              lat={lat}
              lng={lng}
              static
              nomap
              noqrcode
              noactions
            />
          </Col>
        </Row>
      </Container>
    </div>
  )


}

export default ZToolEventView;

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/