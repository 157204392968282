import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { updateJobInvoiced, addJobInvoice, addJobCustomerPO, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
    '& .super-app.duplicate': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },    
  },
  margin: {
    margin: theme.spacing(1),
  },
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  },  
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },   
  duplicateName: {
    backgroundColor: "lightred",
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  amountField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },  
}));

//////////////////////

const ZAddJobPurchaseOrderDialog = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({
    name: '',
    open: false,
    job: null,
    customerPODate: new Date(),
    poAmount: '',
  });
  const [alertState, setAlertState] = React.useState({
    open: false,
    severity: 'info',
    message: '',
  });

  const [addPOResult,     createCustomerPOFunction]     = useMutation(addJobCustomerPO);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    setState({...state, open: false})
    props.onClose && props.onClose()
  }

  let job_id = null;

  if(props.job){
    console.log('ZAddJobPurchaseOrderDialog->props')
    console.log(props)
    job_id = props.job.id
  }

  /*
  React.useEffect(()=>{
    if(props.job){
      job_id = props.job.id
      tempName = props.job.name;
      tempName += `-${props.job.invoices.length + 1}`
      setState({...state, name: tempName})
    }
  },[state,props.job])
  */

  const handleDateChange = (value) => {
    // check to see if date overlaps with restrictedDates
    setState({...state, customerPODate: value})
    //console.log("state->"+ JSON.stringify(state));
  }

  const handlePOChange = (event) => {
    console.log('handlePOChange')
    console.log(event)
    setState({...state, name: event.target.value})
    console.log(state);
  }

  const handleConfirm = () => {
    console.log('ZSetJobInvoicedButton::handleConfirm')
    //let id = uuidv4();
    let guuid = uuidv4();
    let modifiedOn = moment().format();
    let user_id = userData.id

    let variables = {}

    /*
    if(state.po === null){
      setAlertState({...alertState, open: true, message: 'ERROR no PO has been chosen, cannot invoice without customer PO'})
      return;
    }
    */
   
    variables.object = {
      id: guuid,
      job_id: props.parent.id,
      document_type: "PurchaseOrder",
      name: state.name,
      amount: state.poAmount,
      parent_id: props.parent.id,
      theDate: moment(state.customerPODate).format(),
    }

    variables._set = {
      id: props.parent.id,
      customerPO: state.name,
      poAmount: state.poAmount,
      modifiedOn: modifiedOn,
    }

    variables.activities = [
      {
        user_id: user_id,
        parent_id: guuid,
        name: "customer PO created",
        description: `${userData.alias} created new PO ${state.name} for ${state.poAmount} active from ${state.customerPODate}`,   
      },
      {
        user_id: user_id,
        parent_id: props.parent.id,
        name: "customer PO created",
        description: `${userData.alias} updated job with new PO ${state.name} for ${state.poAmount} active from ${state.customerPODate}`,   
      }
    ]     

    console.log(variables)

    //return;

    createCustomerPOFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          severity: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        insertNotificationFunction({object: {
          name: `New Customer PO ${result.data.insert_zeus_sales_document_one.name} created`,
          description: `${userData.alias} created Customer PO ${result.data.insert_zeus_sales_document_one.name} for job ${props.parent.name}`,
          role: 'admin',
        }});   
        setAlertState({
          severity: 'success',
          open: true,
          message: 'Successfully set job as invoiced',
        })
      }
      handleClose();
    })
  }
  
  /*
  if(props.parent){
    if(props.parent.document_type === 'Invoice'){
      return (<div></div>)
    }
  }
  */

  ////////////////

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="scheduling_alert"
        onClose={()=>{
          setAlertState({...alertState, open: false, message: '', error: false})
        }}
        ContentProps={alertState.error === true      
        ? 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarErrorStyle
          }
        : 
          {
          "aria-describedby": "message-id",
          className: classes.snackbarSuccessStyle
          }
        }
        message={alertState.message}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alertState, open: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Job Purchase Order</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>      
              <FormControl className={classes.formControl}>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="customerPODate"
                        value={state.customerPODate}
                        label="Purchase Order Date"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />                 
                    </Grid>
                  </MuiPickersUtilsProvider> 
                </div>  
                <br/>
                <FormControl fullWidth className={classes.amountField} variant="outlined">
                  <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                  <FilledInput
                    id="filled-adornment-amount"
                    onChange={(event) =>{
                      setState({...state, poAmount: event.target.value});
                      //console.log("state->"+ JSON.stringify(state));
                    }} 
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                </FormControl>
                <br/>
                <TextField className={classes.nameField}
                  error={state.duplicateName}
                  helperText={state.duplicateName && 'duplicate name'}
                  id="name"
                  label="Customer PO"
                  onChange={(event) =>{
                    let newName = event.target.value;
                    let isDuplicate = true;
                    props.parent.purchaseOrders.forEach((po)=>{
                      if(po.name !== newName){
                        isDuplicate = false
                      }
                    })
                    setState({...state, name: newName, duplicateName: isDuplicate});
                    //console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
                <br/>
                <TextField
                  className={classes.descriptionField}
                  id="details"
                  label="Description"
                  multiline
                  rows={4}
                  onChange={(event) =>{
                    setState({...state, description: event.target.value});
                    //console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" disabled={state.duplicateName}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </div>
  )  
}

export default ZAddJobPurchaseOrderDialog;


/*

      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true})
        }}     
        disabled={props.disabled}                 
      >
        Add Job Invoiced Event
      </Button>


  return_1 (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity="error">
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={10000}
              open={state.open}
              key="set_job_closed"
              onClose={()=>{
                setState({...state, invoiceDate: '', invoiceAmount: '', open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={
                <div id="message-id">
                  <TextField
                    className={classes.nameField}
                    autoFocus
                    margin="dense"
                    id="invoiceAmount"
                    label="Enter Invoice Amount"
                    onChange={(event) =>{
                      setState({...state, invoiceAmount: event.target.value})
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                  /> 
                  <br/>
                  <TextField
                    className={classes.nameField}
                    autoFocus
                    margin="dense"
                    id="invoiceDate"
                    type="date"
                    defaultValue={new Date()}
                    label="Enter Invoice Date"
                    onChange={(event) =>{
                      setState({...state, invoiceDate: event.target.value})
                      console.log("state->"+ JSON.stringify(state));
                    }} 
                  />                  
                </div>
              }              
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleConfirm}>
                    Submit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                  Close
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
              onClick={(event) => { 
                setState({...state, open: true})
              }}                     
            >
              Add Job Invoiced Event
            </Button>
          </div>
        </Router>
      </strong>
    </div>
  )
*/
