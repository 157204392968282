import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan } from '@material-ui/core/colors';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation } from 'urql';
import { getClient } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getClient } from 'queries';
import { addClient, updateClient } from 'mutations';
*/

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Moment from 'react-moment';
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactTooltip from 'react-tooltip';

import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import BallotIcon from '@material-ui/icons/Ballot';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZFileS3UploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';
import ZAnnotationInputMenuItem from 'components/core/ZAnnotationInputMenuItem';

///////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  isOpen: false
  handleClose: ()=>{}
  quotation: {}
}
*/

const ZClientDetailPane = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [anchorHeader, setAnchorHeader] = React.useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePaneHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = (event) => {
    console.log(event)
    setAnchorHeader(null);
  }

  /*
  const { loading, error, data } = useQuery(getClient, {
    variables: { id: props.client.id },
    skip: skip_query,
  });
  const [updateFunction] = useMutation(updateClient);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) return (<div></div>);
  if (skip_query) return (<div></div>)

  console.log("client data loaded")
  console.log(data);
  */

  let id = null;
  let pending_quotes_amount = 0.00
  let pending_quotes_count = 0

  let handleAddFile = (<div></div>)
  let handleAnnotation = (<div></div>)

  /////////////////

  let skip_query = true;

  if(props.isOpen){
    skip_query = false;
  }

  if(props.client !== null){
    id = props.client.id
    skip_query = false
  } 

  const [ result, reexecuteQuery ]  = useQuery({
    query: getClient,
    variables: { id },
    pause: skip_query,
  });
  const [updateFunction] = useMutation(updateClient);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return (<div></div>);
  if (skip_query) return (<div></div>)

  /////////////////
  /*
  const [ result, reexecuteQuery ]  = useQuery({
    query: getClient,
    pause: !props.isOpen,
  });
  const [updateClientResult, updateFunction] = useMutation(updateClient);
  const [insertClientResult, insertFunction] = useMutation(addClient);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  React.useEffect(() => {
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;

    if(data.zeus_client_by_pk.pending_quotations.aggregate.sum.amount != null){
      pending_quotes_amount = data.zeus_client_by_pk.pending_quotations.aggregate.sum.amount
    }
  
    if(data.zeus_client_by_pk.pending_quotations.aggregate.count != null){
      pending_quotes_count = data.zeus_client_by_pk.pending_quotations.aggregate.count
    }

    handleAddFile = (
      <div>
        <ZFileS3UploadMenuItem client_id={data.zeus_client_by_pk.id} dirName={"client/attachment"} />
      </div>
    )
  
    handleAnnotation = (
      <div>
        <ZAnnotationInputMenuItem client_id={data.zeus_client_by_pk.id} />
      </div>
    )

    // Set up to refetch in 5 second, if the query is idle
    const timerId = setTimeout(() => {
      console.log('re-execute getClient')
      reexecuteQuery({ requestPolicy: 'network-only' });
    }, 30000);
  
    return () => clearTimeout(timerId);
  }, [data, fetching, error, reexecuteQuery, handleAddFile, handleAnnotation]);
  */
  /////////////

  if(data.zeus_client_by_pk.pending_quotations_aggregate.aggregate.sum.amount != null){
    pending_quotes_amount = data.zeus_client_by_pk.pending_quotations_aggregate.aggregate.sum.amount
  }

  if(data.zeus_client_by_pk.pending_quotations_aggregate.aggregate.count != null){
    pending_quotes_count = data.zeus_client_by_pk.pending_quotations_aggregate.aggregate.count
  }

  handleAddFile = (
    <div>
      <ZFileS3UploadMenuItem client_id={data.zeus_client_by_pk.id} dirName={"client/attachment"} />
    </div>
  )

  handleAnnotation = (
    <div>
      <ZAnnotationInputMenuItem client_id={data.zeus_client_by_pk.id} />
    </div>
  )

  ///////////

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <Divider variant="middle"/>
        <MenuItem>{handleAddFile}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem>{handleAnnotation}</MenuItem>
        <Divider variant="middle"/>
        <MenuItem onClick={handleCloseHeader}>Close</MenuItem>
        <Divider variant="middle"/>
      </Menu>
    </div>  
  )

  ///////////////////

  return (
    <>
    <SlidingPane
      isOpen={props.isOpen}
      title="Client Detail"
      subtitle={props.client.createdOn}
      width="800px"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        //setDetailPane(false);
        props.handleClose()
      }}
    >
      <Card variant="outlined">
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="client" className={classes.avatar}>
                <BallotIcon />
              </Avatar>
            }
            action={
              <Fab
                data-tip="client actions"
                data-for="client_box_tooltip"
                //color="primary"
                aria-label="add"
                size="small"
                onClick={handlePaneHeaderClick}
              >
                <MoreVertIcon />
                { headerMenu }
              </Fab>
            }
            title={data.zeus_client_by_pk.name}
            titleTypographyProps={{variant:'h4'}}
            subheader={
              <Moment format="YYYY-MM-DD">
               {data.zeus_client_by_pk.createdOn}
              </Moment>
            }
          />
          <Paper>
            <CardContent>
                <Chip color="primary" label={`${pending_quotes_count} Pending Quotes: $ ${pending_quotes_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`} />
              <Paper>
                <Typography>{data.zeus_client_by_pk.description}</Typography>
              </Paper>
              <Paper>
                <ZActivitiesTimeline items={data.zeus_client_by_pk.activities} size={5} />
              </Paper>
            </CardContent>  
          </Paper>  

          <CardActions disableSpacing>       
            <IconButton 
              data-tip="add note"
              data-for="client_box_tooltip" 
              aria-label="add note">
              <ShareIcon />
            </IconButton>
           
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show notes"
              data-tip="show notes"
              data-for="client_box_tooltip" 
            >
              <ExpandMoreIcon />
            </IconButton>           
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Notes</Typography>
              { data.zeus_client_by_pk.annotations.map((note) => {
                return (
                  <div key={note.id}>
                    <Card>
                      <CardHeader>{note.name}</CardHeader>
                      <CardContent>{note.description}</CardContent>
                    </Card>
                  </div>             
                )
              }) }
            </CardContent>
          </Collapse>
        </CardActionArea>    
      </Card>
    </SlidingPane> 
    <ReactTooltip id="client_box_tooltip"/>
    </>   
  )
}

export default ZClientDetailPane;
