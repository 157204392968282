import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

///////////////////////////////////

const ZSupplierBox = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let smallChips = (<div></div>)
  let supplierNotes = (<div></div>)

  if(typeof props.fullBox !== 'undefined'){
    smallChips = (
      <div>
        <Chip label={'Active Product Lines:'+ props.supplier.product_lines_aggregate.aggregate.count} />
        <Chip label={'Active Products:'+ props.supplier.active_products.aggregate.count} />
        <Chip color="primary" label={'Active Quotes:'+ props.supplier.pending_quotations.aggregate.count +'   Total $'+ props.supplier.pending_quotations.aggregate.sum.amount} />
      </div>
    )

    supplierNotes = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="supplier_box_tooltip" 
            aria-label="add note">
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="supplier_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Notes</Typography>
            {props.annotations.map((note) => {
              return (
                <div key={note.id}>
                  <Card>
                    <CardHeader>{note.name}</CardHeader>
                    <CardContent>{note.description}</CardContent>
                  </Card>
                </div>             
              );
            })}
          </CardContent>
        </Collapse>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="supplier" className={classes.avatar}>
              <AccountBoxIcon />
            </Avatar>
          }
          action={
            <Fab
              data-tip="supplier actions"
              data-for="supplier_box_tooltip"
              //color="primary"
              aria-label="add"
              size="small"
              onClick={
                /* handleCardHeaderClick */
                console.log("supplier card header clicked")
              }
            >
              <MoreVertIcon />
              { /* headerMenu */ }
            </Fab>
          }
          title={props.name}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <Moment format="YYYY-MM-DD">
              {props.createdOn}
            </Moment>
          }
        />
        <CardContent>
          { smallChips }
          <Typography>{props.description}</Typography>
        </CardContent>
        <CardActionArea>
          { supplierNotes }
        </CardActionArea>
      </Card>
      <ReactTooltip id="supplier_box_tooltip"/>
    </div>
  );
};


export default ZSupplierBox;