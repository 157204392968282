import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


import { useLocalStorage } from 'hooks/useStorage';
import usePersistentState from 'hooks/usePersistentState';
import { uploadFileToAzure } from 'azure/AzureBlobAPI';

/*
import { useMutation } from '@apollo/client';
import { getAllClients } from '../../queries';
import { addClient, updateClient } from '../../mutations';
*/

import { useQuery, useMutation } from 'urql';
import { getAllAssetsForClient } from 'queries_urql';
import { addComponent, addLocations, updateAsset } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

import ZComponentAutocomplete from 'components/core/ZComponentAutocomplete'; 
import ZLocationSelect from 'components/core/ZLocationSelect'; 
import ZClientAutocomplete from 'components/core/ZClientAutocomplete';//ZClientAutocomplete
import ZRouteAutocomplete from 'components/core/ZRouteAutocomplete'; //ZClientNodeTypeAutocomplete
import ZClientNodeAutocomplete from 'components/core/ZClientNodeAutocomplete';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';

import  ZWizard  from 'components/core/ZWizard';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

const ZAddComponentDialog = (props) => {
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');
  
  const [alertState, setAlertState] = React.useState({
    show: false,
    message: '',
    severity: 'info',
  })

  const [state, setState] = React.useState({
    guuid: uuidv4(),
    locations: [],
    name: null,
    description: null,
    status: 'active',
    component_type: null,
    component_type_id: null,
    parent: null,
    parent_id: null,
    user: null,
    user_id: null,
  })

  let tempName = '';

  const handleClose = () => {
    if(props.onClose){
      props.onClose();
    }
  }

  const handleReset = () => {
    setState({
      guuid: uuidv4(),
      locations: [],
      name: null,
      description: null,
      status: 'active',
      parent: null,
      parent_id: null,
      user: null,
      user_id: null,
      component_type: null,
      component_type_id: null,
    })
    handleClose();
  }

  ////////////

  const [insertResult, insertFunction] = useMutation(addComponent);
  const [insertLocResult, insertLocations] = useMutation(addLocations);

  ////////////

  const handleReview = () => {
    const createdOn = moment().format()
    setState({
      ...state,
      parent_id: state.parent.id,
      component_type_id: state.component_type.id,
      createdOn: createdOn,
    })
    console.log("handleReview->"+ JSON.stringify(state));
  }

  const handleLocationSelect = (newLocations) => {
    console.log("handleLocationSelect")
    console.log(newLocations)

    setState({...state, locations: newLocations})    
  }

  const handleComponentChange = (event,item) => {
    console.log("handleComponentChange")
    console.log(item)
    setState({...state, component: item, component_type_id: item.id})    
  }  


  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));

    const { guuid, parent, user, component_type, user_id, locations, component, ...object } = state;
    object.id = state.guuid;
    object.parent_id = props.parent.id;
    object.asset_id = props.parent.id;   

    console.log('object');
    console.log(object);

    let component_activity = {
      user_id: userData.id,
      parent_id:  state.guuid,
      name: `component created`,
      description: `${userData.alias} created component ${state.name} in ${props.parent.name}`,           
    }

    let asset_activity = {
      user_id: userData.id,
      parent_id: props.parent.id,
      name: `component ${state.name} created for asset`,
      description: `${userData.alias} created component ${state.name} for this asset`,           
    } 

    let loc_activities = [];
    let comp_locations = [];

    state.locations.forEach((item)=>{
      comp_locations.push({
        asset_id: props.parent.id,
        parent_id: state.guuid,
        name: item.name,
        description: item.description,
        status: 'active',
        location_type_id: item.id,
      })

      loc_activities.push({
        user_id: userData.id,
        parent_id: state.guuid,
        name: "component location created",
        description: `${userData.alias} created component location ${item.name}`,
      })
    })

    console.log('creating new component')
    console.log(object)

    //return;

    insertFunction({
      object: object,
      activities: [
        component_activity,
        asset_activity,
      ]
    }).then((result)=>{
      if(result.error){
        console.log('ERROR creating component')
        console.log(result)
      } else {
        console.log('successfully created new component')

        console.log("now creating component locations")
        console.log(comp_locations);
        
        insertLocations({
          objects: comp_locations,
          activities: loc_activities,
        }).then((result)=>{
          if(result.error){
            console.log('ERROR creating component')
            console.log(result)
          } else {
            console.log("successfully created component locations")
            setAlertState({
              open: true,
              message: `Successfully created component ${state.name}`
            })
          }
        })

      }
    });

    handleClose()
  }

  //////////////////////////

  return(
    <div>
     <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alert.show}
        message={alert.message}
        key="alert_button"
        //onClose={()=>{
        //  setAlert({...alert, show: false, message: '', error: false})
        //}}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color={alert.error === true ? "secondary" : "primary"}
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alert, show: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />       
      <ZWizard 
        title="Create New Component"
        steps={[
          'Choose Component Type', 
          'Select Locations', 
        ]}
        open={props.open} 
        onClose={props.onClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
        onReset={handleReset}
        disabled={
          state.component === null ? true 
          : state.name === null ? true 
          : state.locations.length === 0 ? true
          : false}        
      >
        { /*  component type details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, maxHeight: 600, my: 1, mx: 'auto', p: 2, overflowY: 'scroll' }}>
              <ZComponentAutocomplete isOpen={props.open} default={state.component ? state.component : null} onChange={handleComponentChange} />
            </Paper>
            <br/>                              
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  let newName = event.target.value;
                  setState({...state, name: newName});
                }}
                id="assetName" 
                label="Asset Tag" 
                variant="standard"
                defaultValue={tempName}
              />
            </Paper> 
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="assetDescription"
                label="Description"
                multiline
                rows={4}
                onChange={(event) =>{
                  setState({...state, description: event.target.value});
                }} 
              />  
            </Paper>             
          </Box>
        </Container>
        {/* location selection */}
        <Container maxWidth="sm">
          <Box>            
            <Paper sx={{ maxWidth: 400, maxHeight: 600, my: 1, mx: 'auto', p: 2, overflowY: 'scroll' }}>
              <ZLocationSelect isOpen={props.open} onChange={handleLocationSelect} />
            </Paper>
            <br/>
          </Box>
        </Container>  
        {/* review details */}
        <Container maxWidth="sm">
        <Box>
            <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow key='100' selected={state.component === null ? true : false}>
                      <TableCell align="right">Component:</TableCell><TableCell align="left">{state.component ? state.component.name : ''}</TableCell>
                    </TableRow>

                    <TableRow key='200' selected={state.name === null ? true : false}>
                      <TableCell align="right">Asset Tag:</TableCell><TableCell align="left">{state.name}</TableCell>
                    </TableRow>

                    <TableRow key='300'> 
                      <TableCell align="right">Description:</TableCell><TableCell align="left">{state.description}</TableCell>
                    </TableRow>
                    { state.locations.map((loc,index)=>{
                        return(
                          <TableRow key={index}> 
                            <TableCell align="right">{`Location: ${index}`}</TableCell><TableCell align="left">{loc.name}</TableCell>
                          </TableRow> 
                        )
                      })
                    }
                    <TableRow key='400'>
                      <TableCell align="right">User:</TableCell><TableCell align="left">{userData.alias}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddComponentDialog;

