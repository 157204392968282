import gql from 'graphql-tag';

export const salesStatsQuery = gql`
  query salesStatsQuery($accepted_year: float8 = "", $accepted_month: float8 = "") {
  zeus_monthly_sales_stats(where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}}}) {
    accepted_month
    accepted_year
    avg_time_to_accept
    max_amount
    max_time_to_accept
    min_amount
    min_time_to_accept
    quote_count
    total_amount
  }
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
}
`;


export const salesFunnelStatsQuery = gql`
query salesFunnelStatsQuery($funnel: String = "") {
  zeus_funnel_sales_stats(where: {funnel: {_eq: $funnel}}) {
    funnel
    avg_time_to_accept
    max_time_to_accept
    min_time_to_accept
    total_amount
    max_amount
    min_amount
    quote_count
  }
}
`;


export const salesBySupplierAndFunnelQuery = gql`
query salesBySupplierAndFunnelQuery($supplier: String = "", $funnel: String = "") {
  zeus_quotation_view(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id    
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    acceptedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  zeus_supplier_funnel_sales_stats(where: {_and: {funnel: {_eq: $funnel}, supplier: {_ilike: $supplier}}}) {
    supplier
    funnel
    avg_time_to_accept
    max_time_to_accept
    min_time_to_accept
    quote_count
    total_amount
    min_amount
    max_amount
  }
}
`;


export const salesBySupplierAndFunnelQuery_1 = gql`
query salesBySupplierAndFunnelQuery($supplier: String = "", $funnel: String = "", $status: String = "") {
  zeus_quotation_view(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: $status}}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id    
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: $status}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  zeus_supplier_funnel_sales_stats(where: {_and: {funnel: {_eq: $funnel}, supplier: {_ilike: $supplier}}}) {
    supplier
    funnel
    avg_time_to_accept
    max_time_to_accept
    min_time_to_accept
    quote_count
    total_amount
    min_amount
    max_amount
  }
}
`;



export const xxx = gql`
query salesMonthlyBySupplierQuery($accepted_year: float8 = "2021", $accepted_month: float8 = "9", $supplier: String = "CTC") {
  zeus_monthly_supplier_sales_view(where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, supplier: {_eq: $supplier}}}) {
    accepted_month
    accepted_year
    avg_time_to_accept
    max_amount
    max_time_to_accept
    min_amount
    min_time_to_accept
    quote_count
    total_amount
    supplier
  }
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
}
`;


export const salesBySupplierAndMonthQuery = gql`
query salesBySupplierAndMonthQuery($supplier: String = "", $accepted_year: float8 = "", $accepted_month: float8 = "", $status: String = "") {
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, document_type: {_eq: "Quotation"}, status: {_eq: $status}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, status: {_eq: $status}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  zeus_monthly_supplier_sales_view(where: {_and: {supplier: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}}) {
    accepted_month
    accepted_year
    avg_time_to_accept
    max_amount
    max_time_to_accept
    min_amount
    min_time_to_accept
    quote_count
    supplier
    total_amount
  }
}
`;


export const pendingSalesBySupplierAndMonthQuery = gql`
query pendingSalesBySupplierAndMonthQuery($supplier: String = "", $created_year: float8 = "", $created_month: float8 = "", $status: String = "") {
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {created_year: {_eq: $created_year}, created_month: {_eq: $created_month}, document_type: {_eq: "Quotation"}, status: {_eq: $status}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, status: {_eq: $status}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, created_year: {_eq: $created_year}, created_month: {_eq: $created_month}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const pendingSalesStatsQuery = gql`
query pendingSalesStatsQuery($created_year: float8 = "", $created_month: float8 = "") {
  zeus_monthly_pending_sales_stats(where: {_and: {created_year: {_eq: $created_year}, created_month: {_eq: $created_month}}}) {
    created_year
    created_month
    quote_count
    total_amount
    max_amount
    min_amount
    avg_amount
  }
}
`;


export const pendingSalesFunnelStatsQuery = gql`
query pendingSalesFunnelStatsQuery($funnel: String = "") {
  zeus_funnel_pending_sales_stats(where: {funnel: {_eq: $funnel}}, order_by: {funnel: asc}) {
    funnel
    quote_count
    total_amount
    max_amount
    min_amount
    avg_amount
  }
}
`;


export const monthlySalesQuery = gql`
query monthlySalesQuery($accepted_year: float8 = "") {
  zeus_monthly_sales_view(where: {accepted_year: {_eq: $accepted_year}}) {
    accepted_year
    accepted_month
    avg_days_to_accept
    avg_days_to_close
    total_amount
  }
}
`;


export const monthlySupplierSalesQuery = gql`
query monthlySalesQuery($accepted_year: float8 = "", $supplier: String = "") {
  zeus_monthly_supplier_sales_view(where: {_and: {accepted_year: {_eq: $accepted_year}, supplier: {_eq: $supplier}}}) {
    __typename
    supplier
    accepted_year
    accepted_month
    quote_count
    total_amount
    max_amount
 		min_amount
 		avg_time_to_accept
    max_time_to_accept
  	min_time_to_accept
  }
  zeus_quotation_view(where: {_and: {accepted_year: {_eq: $accepted_year}, supplier_code: {_eq: $supplier}, document_type: {_eq: "Quotation"}}}) {
    __typename
    id
    name
    description
    funnel
    amount
    document_type
    status
    supplier_code
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    job_id
    modifiedOn
    parent_id
    supplier_id
  }
}
`;





export const monthlySalesTotalQuery = gql`
query monthlySalesTotalQuery($accepted_year: float8 = "", $status: String = "") {
  zeus_monthly_sales_view_aggregate(where: {accepted_year: {_eq: $accepted_year}}) {
    aggregate {
      sum {
        total_amount
      }
    }
  }
}
`;


export const wonSalesTotalQuery = gql`
query wonSalesTotalQuery($accepted_year: float8 = "") {
  zeus_quotation_view_aggregate(where: {_and: {accepted_year: {_eq: $accepted_year}, status: {_eq: "accepted"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
      avg {
        amount
      }
    }
  }
}
`;


export const wonSalesFunnelQuery = gql`
query wonSalesFunnelQuery($funnel: String = "") {
  zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "accepted"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
      avg {
        amount
      }
    }
  }
}
`;


export const lostSalesTotalQuery = gql`
query lostSalesTotalQuery($closed_year: float8 = "") {
  zeus_quotation_view_aggregate(where: {_and: {closed_year: {_eq: $closed_year}, status: {_eq: "closed"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const lostSalesFunnelQuery = gql`
query lostSalesFunnelQuery($funnel: String = "") {
  zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "closed"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const salesFunnelQuery = gql`
query salesFunnelQuery($funnel: String = "") {
  lost_sales_stats: zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "closed"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_sales_stats: zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "pending"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  accepted_sales_stats: zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "accepted"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const getFunnelsQuery = gql`
query getFunnelsQuery {
  zeus_sales_document(distinct_on: funnel) {
    funnel
  }
}
`;


export const getAllClients = gql`
query getAllClients {
  zeus_client(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    address(path: "label")
    region: address(path: "region.province")
    assets_aggregate {
      aggregate {
        count
      }
    }
    active_routes: routes_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
    pending_quotations: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
  }
}`;


export const getClient = gql`
query getClient($id: uuid = "") {
  zeus_client_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    address(path: "label")
    region: address(path: "region.province")
    active_contacts: contacts(order_by: {firstName: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      firstName
      lastName
      email
      status
    }
    all_contacts: contacts(order_by: {firstName: asc}) {
      __typename
      id
      firstName
      lastName
      email
      status
    }    
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    assets_aggregate {
      aggregate {
        count
      }
    }
    active_routes: routes_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
    pending_quotations: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
  }
}`;


export const getAnnotationsForSalesDocument = gql`
query getAnnotationsForSalesDocument($sales_document_id: uuid = "") {
  zeus_annotation(where: {_and: {sales_document_id: {_eq: $sales_document_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getContactsForClient = gql`
query getContactsForClient($client_id: uuid = "") {
  zeus_contact(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    firstName
    lastName
    email
    description
    status
    createdOn
  }
}`;


export const getAssetsForClient = gql`
query getAssetsForClient($client_id: uuid = "") {
  zeus_asset(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    client_node {
      __typename
      id
      name
      description
      status
      node_type_id
    }
  }
}`;


export const getRoutesForClient = gql`
query getRoutesForClient($client_id: uuid = "") {
  zeus_route(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    client_node {
      __typename
      id
      name
      description
      node_type_id
      status
      createdOn
    }
  }
}`;


export const getQuotesForClient = gql`
query getQuotesForClient($client_id: uuid = "") {
  zeus_quotation_view(where: {_and: {client_id: {_eq: $client_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const getQuotesForSupplier = gql`
query getQuotesForSupplier($supplier_id: uuid = "") {
  zeus_quotation_view(where: {_and: {supplier_id: {_eq: $supplier_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const getQuotesForContact = gql`
query getQuotesForContact($contact_id: uuid = "") {
  zeus_quotation_view(where: {_and: {contact_id: {_eq: $contact_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const getAllProductLines = gql`
query getAllProductLines {
  zeus_product_line(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    supplier {
      id
      name
      status
      craetedOn
    }
    product_line_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getProductLine = gql`
query getProductLine($id: uuid = "") {
  zeus_product_line_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    active_products: products(order_by: {name: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      createdOn
      status
    }
    all_products: products(order_by: {name: asc}) {
      __typename
      id
      name
      createdOn
      status
    }    
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    products_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getMeasurementPoint = gql`
query getMeasurementPoint($id: uuid = "") {
  zeus_measurement_point_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    asset {
      __typename
      id
      name
      description
      status
      createdOn
    }
    measurements(order_by: {createdOn: desc}) {
      __typename
      id
      mAxis
      mType
      description
      createdOn
      status
      values
    }
  }
}
`;


export const getAllUsers = gql`
query getAllUsers {
  zeus_zeus_user(order_by: {firstName: asc}) {
    __typename
    id
    firstName
    lastName
    email
    description
    status
    createdOn
  }
}`;


export const getUser = gql`
query getUser($id: uuid = "") {
  zeus_zeus_user_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    quotations(where: {_and: {document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    pending_quotations_agg: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }
    open_activities: activities(where: {_and: {status: {_eq: "pending"}}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }    
  }
}`;


export const getAllSuppliers = gql`
query getAllSuppliers {
  zeus_supplier(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    product_lines_aggregate {
      aggregate {
        count
      }
    }
    products_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getSupplier = gql`
query getSupplier($id: uuid = "") {
  zeus_supplier_by_pk(id: $id) {
    id
    name
    description
    createdOn
    status
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    products(order_by: {name: asc}) {
      id
      name
      description
      status
    }
    products_aggregate {
      aggregate {
        count
      }
    }
    active_products: products_aggregate(where: {_and: {status: {_eq: "active"}}}) {
      aggregate {
        count
      }
    }
    product_lines(order_by: {name: asc}) {
      id
      name
      status
    }
    product_lines_aggregate {
      aggregate {
        count
      }
    }
    sales_documents(order_by: {createdOn: desc}) {
      id
      name
      amount
      description
      funnel
      document_type
      createdOn
      status
    }
    won_quotations: sales_documents_aggregate(where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}}}) {
      aggregate {
        count
        avg {
          amount
        }
        max {
          amount
        }
        sum {
          amount
        }
      }
    }
    lost_quotations: sales_documents_aggregate(where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "closed"}}}) {
      aggregate {
        count
        avg {
          amount
        }
        max {
          amount
        }
        sum {
          amount
        }
      }
    }
    pending_quotations: sales_documents_aggregate(where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "pending"}}}) {
      aggregate {
        count
        avg {
          amount
        }
        max {
          amount
        }
        sum {
          amount
        }
      }
    }
  }
}`;


export const getProductsForSupplier = gql`
query getProductsForSupplier($supplier_id: uuid = "") {
  zeus_product(where: {_and: {supplier_id: {_eq: $supplier_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    price
    description
    status
    createdOn
  }
}`;


export const getProductsForProductLine = gql`
query getProductsForProductLine($product_line_id: uuid = "") {
  zeus_product(where: {_and: {product_line_id: {_eq: $product_line_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    price
    description
    status
    createdOn
  }
}`;


export const getDataForMeasurementPoint = gql`
  query getDataForMeasurementPoint($mpoint_id: uuid = "") {
  zeus_measurement(where: {_and: {measurement_point_id: {_eq: $mpoint_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    mAxis
    mType
    description
    values
    createdOn
    status
    measurement_point {
      __typename
      id
      name
      description
      createdOn
    }
    asset {
      __typename
      id
      name
      description
    }
  }
}`;


export const getMeasurementData = gql`
query getMeasurementData($id: uuid = "") {
  zeus_measurement_by_pk(id: $id) {
    __typename
    id
    mAxis
    mType
    description
    createdOn
    status
    s3path
    s3bucket
    values
    measurement_point {
      __typename
      id
      name
      description
      status
    }
    asset {
      __typename
      id
      name
      description
      status
    }
  }
}
`;


export const getMeasurementsForAsset = gql`
  query getMeasurementsForAsset($asset_id: uuid = "") {
    zeus_asset(where: {_and: {id: {_eq: $asset_id}}}) {
      __typename
      id
      name
      description
      createdOn
      status
      measurement_points {
        __typename
        id
        name
        description
        status
        createdOn
        measurements(order_by: {createdOn: desc}) {
          __typename
          id
          mAxis
          mType
          description
          createdOn
          status
          values
        }
      }
    }
  }
`;


export const getAllContacts = gql`
query getAllContacts {
  zeus_contact(order_by: {firstName: asc}) {
    __typename
    id
    firstName
    lastName
    email
    description
    status
    createdOn
  }
}`;


export const getContact_old = gql`
query getContact($id: uuid = "") {
  zeus_contact_by_pk(id: $id) {
    __typename
    id
    firstName
    lastName
    email
    description
    status
    createdOn
  }
}`;


export const getContact = gql`
query getContact($id: uuid = "") {
  zeus_contact_by_pk(id: $id) {
    id
    name
    firstName
    lastName
    email
    description
    createdOn
    phone
    quotations: sales_documents(limit: 10, where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "pending"}}}, order_by: {createdOn: desc}) {
      id
      name
      description
      amount
      acceptedOn
      closedOn
      stage
      status
    }
    accepted_quotations: sales_documents(limit: 10, where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}}}, order_by: {createdOn: desc}) {
      id
      name
      description
      amount
      acceptedOn
      closedOn
      stage
      status
    }
    accepted_quotations_aggregate: sales_documents_aggregate(where: {_and: {status: {_eq: "accepted"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }   
    pending_quotations: sales_documents(limit: 10, where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "pending"}}}, order_by: {createdOn: desc}) {
      id
      name
      description
      amount
      acceptedOn
      closedOn
      stage
      status
    }
    pending_quotations_aggregate: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    lost_quotations: sales_documents(limit: 10, where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "closed"}}}, order_by: {createdOn: desc}) {
      id
      name
      description
      amount
      acceptedOn
      closedOn
      stage
      status
    }
    lost_quotations_aggregate: sales_documents_aggregate(where: {_and: {status: {_eq: "closed"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    } 
    annotations(limit: 5, order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    activities(limit: 5, order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
      status
    }
    client {
      id
      name
    }
  }
}`;


export const getAllAssets = gql`
query getAllAssets {
  zeus_asset(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getAsset = gql`
query getAsset($id: uuid = "") {
  zeus_asset_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    measurementPoints {
      __typename
      id
      name
      description
      status
      createdOn
    }
  }
}`;


export const getRoute = gql`
query getRoute($id: uuid = "") {
  zeus_route_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    client_node {
      __typename
      id
      name
      description
      node_type_id
      status
      createdOn
    }
    assets {
      __typename
      id
      name
      description
      status
      createdOn
    }
  }
}`;


export const getAllQuotations = gql`
query getAllQuotations {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}) {
    __typename
    id
    name
    funnel
    amount
    description
    acceptedOn
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
}`;


export const getQuotationsByMonthAndSupplier = gql`
query getQuotationsByMonthAndSupplier($created_year: float8 = "", $created_month: float8 = "", $supplier: String = "") {
  zeus_quotation_view(where: {_and: {created_year: {_eq: $created_year}, created_month: {_eq: $created_month}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    amount
    funnel
    document_type
    description
    status
    supplier_code
    client_name
    region
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_id
    client
  }
}
`;

 
export const getQuotation = gql`
query getQuotation($id: uuid = "") {
  zeus_sales_document_by_pk(id: $id) {
    __typename
    id
    name
    amount
    funnel
    description
    status
    createdOn
    modifiedOn
    closedOn
    acceptedOn
    preparedBy
    parent {
      __typename
      id
      name
      description
      createdOn    
      modifiedOn  
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
    }
    contact {
      __typename
      id
      name
      firstName
      lastName
      email
      createdOn
      modifiedOn
    }
    client {
      __typename
      id
      name
      description
      status
      createdOn
      modifiedOn
    }
    attachments: files {
      __typename
      id
      name
      bucket
      path
      description
      createdOn
      modifiedOn
    }
    attachments_aggregate: files_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getAllPurchaseOrders = gql`
query getAllPurchaseOrders {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
  }
}`;


export const getPurchaseOrder = gql`
query getPurchaseOrder($id: uuid = "") {
  zeus_sales_document(where: {_and: {document_type: {_eq: "PurchaseOrder"}, id: {_eq: $id}}}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getAllInvoices = gql`
query getAllInvoices {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
  }
}`;


export const getInvoice = gql`
query getInvoice($id: uuid = "") {
  zeus_sales_document(where: {_and: {document_type: {_eq: "Invoice"}, id: {_eq: $id}}}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getAllJobBoardEntries = gql`
query getAllJobBoardEntries {
  zeus_jobboard_entry(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    customerPO
    serviceType
    status
    createdOn
    client {
      __typename
      id
      name
      description
    }
    quotations: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    invoices: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
  }
}`;


export const getJobBoardEntry = gql`
query getJobBoardEntry($id: uuid = "") {
  zeus_jobboard_entry_by_pk(id: $id) {
    __typename
    id
    name
    description
    customerPO
    serviceType
    status
    createdOn
    client {
      __typename
      id
      name
      description
    }
    quotations: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    invoices: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
      __typename
      id
      name
      description
      amount
      customerPO
      status
      createdOn
      closedOn
    }
  }
}`;
  

export const getAllActivities = gql`
query getAllActivities {
  zeus_activity(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    contact {
      __typename
      id
      firstName
      lastName
    }
    client {
      __typename
      id
      name
    }
  }
}
`;

export const getActivitiesForClient = gql`
query getActivitiesForClient($client_id: uuid = "") {
  zeus_activity(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    salesDocument {
      __typename
      id
      name
      document_type
    }
    client {
      __typename
      id
      name
    }
    contact {
      __typename
      id
      lastName
      firstName
    }
  }
}
`;


export const getActivitiesForContact = gql`
query getActivitiesForContact($contact_id: uuid = "") {
  zeus_activity(where: {_and: {contact_id: {_eq: $contact_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    salesDocument {
      __typename
      id
      name
      document_type
    }
    client {
      __typename
      id
      name
    }
    contact {
      __typename
      id
      lastName
      firstName
    }
  }
}
`;

export const getClientNode = gql`
query getClientNode($id: uuid = "") {
  zeus_client_node_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    status
    assets {
      __typename
      id
      name
      description
      status
    }
    node_type {
      __typename
      id
      name
    }
    parent_node {
      __typename
      id
      name
    }
    client {
      __typename
      id
      name
    }
    children {
      __typename
      id
      name
    }
    routes {
      __typename
      id
      name
      description
    }
  }
}
`;

export const getClientNodesForClient = gql`
query getClientNodesForClient($client_id: uuid = "") {
  zeus_client_node(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    node_type {
      __typename
      id
      name
      status
    }  
    parent_node {
      __typename
      id
      name
      status
    }
    routes_aggregate {
      aggregate {
        count
      }
    }
    children_aggregate {
      aggregate {
        count
      }
    }
    assets_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;