import React from 'react';
import clsx from 'clsx';
import { makeStyles, styled } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { addSensorTrigger, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import Menu from '@material-ui/core/Menu'; //Menu
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { uuid } from 'uuidv4';
import moment from 'moment';
import ZSensorAutocomplete from 'components/core/ZSensorAutocomplete';
import { CheckBox } from '@material-ui/icons';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },  
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  },   
}));

//////////////////////

const ZFormControlLabel = styled(FormControlLabel)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  
});

//////////////////////

const ZAssignVibrationSensorToTriggerButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    open: false,
    sensor: undefined,
    name: "Vibration Sensor RMS Trigger",
    description: null,
    threshold: null,
    notification: false,
    waitUnitsTxt: 'Hours',
    waitUnits: 60 * 60, //  hours
    waitFor: 3, // 3 hours    
    dailyWaitFor: false,
    lastTriggered: 0,
  });

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [insertTriggerResult,     insertTriggerFunction]     = useMutation(addSensorTrigger);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    setState({...state, open: false})
  }

  const handleSensorChange = (event,item) => {
    console.log('setting sensor')
    console.log(item)
    setState({...state, sensor_id: item.id, sensor: item})
  }  

  const handleNotificationChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleDailyWaitForChange = (event) => {
    setState({ ...state, waitFor: 0, waitUnits: 0, [event.target.name]: event.target.checked });
  }  

  let sensor_id = null;
  if(props.sensor){
    sensor_id = props.sensor.id
  }


  const handleConfirm = () => {

    let variables = { 
      name: state.name,
      input_sensor_id: props.sensor.id,
      output_sensor_id: state.sensor_id,
      description: state.description,
      rms: state.threshold,
      notification: state.notification,
      client_id: props.sensor.client.id,
      lastTriggered: state.lastTriggered,
      dailyWaitFor: state.dailyWaitFor,
      status: "active",
    }

    variables.waitFor = state.waitFor * state.waitUnits;

    console.log("ZAssignVibrationSensorToTriggerButton->handleConfirm->variables");
    console.log(variables);

    insertTriggerFunction({"object": variables}).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        console.log('ZAssignVibrationSensorToTriggerButton::updateSensorFunction')
        console.log(result);

        insertNotificationFunction({object: {
          name: `Sensor ${state.sensor.alias} assigned to Trigger ${props.sensor.alias}`,
          description: `${userData.alias} updated Trigger ${props.sensor.name} added sensor ${state.sensor.name} 
          with RMA threshold ${state.threshold} on axis ${state.axis} with user notifications ${state.notification === 1 ? 'enabled' : 'disabled'}`,
          role: 'admin',
        }});
                
        setAlertState({
          open: true,
          message: 'Successfully added Sensor to Trigger',
          severity: 'info',
        })
      }

    })
  }

  //const error = [state.channel0, state.channel1, state.channel2, state.channel3].filter((v) => v).length !== 1;  //error={error}

  ////////////////

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">Vibration Trigger</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
                <div id="message-id">
                  <FormControl required component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <TextField
                        id="name-control"
                        style={{ width: 300 }}
                        fullWidth
                        label="Name"
                        onChange={(event) =>{
                          setState({...state, name: event.target.value});
                        }} 
                      />   
                      <br/>
                      <TextField
                        id="description-control"
                        label="Description"
                        style={{ width: 300 }}
                        fullWidth
                        onChange={(event) =>{
                          setState({...state, description: event.target.value});
                        }} 
                      />   
                      <br/>          
                      <FormControl className={classes.formControl}>
                      <InputLabel id="axis-select-label">Axis</InputLabel>
                        <Select
                          id="axis-simple-select"
                          //value={state.axis}
                          onChange={(event,item) =>{
                            setState({...state, axis: item.props.value})
                            console.log(state);
                          }}
                        >
                          <MenuItem value={0}>X</MenuItem>
                          <MenuItem value={1}>Y</MenuItem>
                          <MenuItem value={2}>Z</MenuItem>
                        </Select>
                      </FormControl>
                      <br/>
                        <TextField
                          id="rms-simple-select"
                          type="number"
                          label="RMS Threshold"
                          fullWidth
                          style={{ width: 300 }}
                          onChange={(event) =>{
                            setState({...state, threshold: event.target.value});
                          }} 
                        />   
                      <br/>
                      <ZFormControlLabel
                          control={
                            <Switch
                              checked={state.dailyWaitFor}
                              onChange={handleDailyWaitForChange}
                              name="dailyWaitFor"
                              color="secondary"
                            />
                          }
                          label="Daily Trigger"
                        />                          
                      <br/>
                      {
                        state.dailyWaitFor === false 
                        ?      
                        <Paper component="form" className={classes.timeUnits}>
                          <TextField
                            id="name-control"
                            style={{ width: 400 }}
                            fullWidth
                            defaultValue={state.waitFor}
                            label="Time to ignore trigger after collection"
                            onChange={(event) =>{
                              setState({...state, waitFor: event.target.value});
                            }} 
                          />  
                          <Button aria-controls="time-units-menu" aria-haspopup="true" 
                            onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                          }}>
                            {state.waitUnitsTxt}
                          </Button>
                          <Menu
                            id="time-units-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={()=>{
                              setAnchorEl(null)
                            }}
                          >
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60, waitUnitsTxt: 'Minutes'}));setAnchorEl(null)}}>Minutes</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60, waitUnitsTxt: 'Hours'}));setAnchorEl(null)}}>Hours</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24, waitUnitsTxt: 'Days'}));setAnchorEl(null)}}>Days</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24 * 7, waitUnitsTxt: 'Weeks'}));setAnchorEl(null)}}>Weeks</MenuItem>
                            <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24 * 30, waitUnitsTxt: 'Months'}));setAnchorEl(null)}}>Months</MenuItem>
                          </Menu>
                        </Paper>  
                        :
                        <div></div>                                        
                      }

                      <br/>
                      <ZFormControlLabel
                        control={
                          <Switch
                            checked={state.notification}
                            onChange={handleNotificationChange}
                            name="notification"
                            color="primary"
                          />
                        }
                        label="Send notification"
                      />                                   
                    </FormGroup>
                  </FormControl>

                  <br/><br/>
                  <ZSensorAutocomplete open={state.open} default={state.sensor} sensors={props.sensors} onChange={handleSensorChange}/>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={handleConfirm} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      </strong>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true})
        }}       
        disabled={props.disabled}              
      >
        Assign Sensor To Vibration Trigger
      </Button>      
    </div>
  )
}

export default ZAssignVibrationSensorToTriggerButton;



/*

return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={10000}
              open={state.open}
              key="assign_sensor_to_client"
              onClose={()=>{
                setState({...state, client_id: '', client: undefined, open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={
                <div id="message-id">
                  <FormControl required error={error} component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Vibration Trigger</FormLabel>
                    <FormGroup>
                      <FormControl className={classes.formControl}>
                      <InputLabel id="axis-select-label">Axis</InputLabel>
                        <Select
                          labelId="axis-select-label"
                          id="axis-simple-select"
                          //value={state.axis}
                          onChange={(event,item) =>{
                            setState({...state, axis: item.props.value})
                            console.log(state);
                          }}
                        >
                          <MenuItem value={0}>X</MenuItem>
                          <MenuItem value={1}>Y</MenuItem>
                          <MenuItem value={2}>Z</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="axis-select-label">RMS Threshold</InputLabel>
                        <TextField
                          id="rms-simple-select"
                          label="axis"
                          type="number"
                          //defaultValue={ds.value ? ds.value : undefined}
                          onChange={(event) =>{
                            setState({...state, threshold: event.target.value});
                          }} 
                        />   
                      </FormControl>                                            
                    </FormGroup>
                  </FormControl>

                  <br/><br/>
                  <ZSensorAutocomplete open={state.open} default={state.sensor} sensors={props.client.sensors} onChange={handleSensorChange}/>
                </div>
              }              
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={handleConfirm}>
                    Submit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                  Close
                  </Button>
                </div>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
              onClick={(event) => { 
                setState({...state, open: true})
              }}       
              disabled={props.disabled}              
            >
              Assign Sensor To Vibration Trigger
            </Button>
          </div>
        </Router>
      </strong>
    </div>
  )

*/