import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllUserRoles } from 'queries_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZUserRoleAutocomplete = (props) => {
  let rows = []
  let skip_query = true;

  if(!props.roles){
    skip_query = false
  }

  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllUserRoles,
    pause: skip_query,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.roles){
    console.log("role")
    console.log(props.roles)
    rows = props.roles.slice(0)
    /*
    for(var i=0; i < props.roles.length; i++){
        var rrr = props.roles[i]
        rows.push(rrr.role)
    }
    */
  } else {
    console.log("roles")
    console.log(data)
    rows = data.zeus_zeus_role.slice(0)
  }

  console.log('ZUserRoleAutocomplete->rows')
  console.log(rows)

  return (
    <Autocomplete
      id="choose-role-combo-box"
      onChange={(event,item)=>{
        console.log("role autocomplete change")
        console.log(item)
        props.onChange(event,item)
      }}
      options={rows}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}

      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Role" variant="outlined" />}
    />
  )
}

export default ZUserRoleAutocomplete;