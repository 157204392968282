import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';

/*
import { useMutation } from '@apollo/client';
import { addAnnotation } from '../../mutations';
*/

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { useMutation, useQuery } from 'urql';
import { addAnnotation } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Snackbar from 'material-ui/Snackbar';
import CloseIcon from '@material-ui/icons/Close';



/////////////////////

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

/////////////////////

const ZAddAnnotationDialog = (props) => {
  const classes = useStyles();
  const snackbarEl = React.useRef(null);
  const [userData, setUserData] = useLocalStorage('userData');

  /*
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [deleted, setDeleted] = React.useState(false);
  */
 
  const [state, setState] = React.useState({
    name: '',
    description: '',
  });

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
  });

  const [insertResult, insertFunction] = useMutation(addAnnotation);

  const handleClose = () => {
    props.handleClose()
  };

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    var newState = state
    const guuid = uuidv4()
    newState.id = guuid
    newState.parent_id = props.parent_id
    setState(newState)

    insertFunction({
      object: state,
      id: guuid,
      user_id: userData.id,
    }).then((result)=>{
      if(result.error){
        console.log('ERROR creating annotation')
        console.log(result)
      } else {
        console.log('successfully created annotation')
        setAlertState({
          open: true,
          message: "Successfully created annotation"
        })
      }
      handleClose()
    });
  }

  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to open snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              //setOpenAlert(false)
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    /> 
  );

  return (
    <div>
      <div ref={snackbarEl} />
      <Container maxWidth="lg">
        <Box>
          <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Note</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please detail any interactions
              </DialogContentText>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.nameField}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Subject"
                  onChange={(event) =>{
                    var newState = state
                    newState.name = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                />

                <TextField
                  className={classes.descriptionField}
                  id="details"
                  label="Details"
                  multiline
                  rows={8}
                  onChange={(event) =>{
                    var newState = state
                    newState.description = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </div>
  );
}

export default ZAddAnnotationDialog;

/*
sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}
*/