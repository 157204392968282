import React from 'react';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getQuotation,  getQuotesForClient } from 'queries';
import { addClient, updateClient } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getQuotation, getAllToolLockers, getAllToolKits, getAllTools, getToolsView, getQuotesForClient } from 'queries_urql';
import { subscribeAllToolKits, subscribeAllLockers, subscribeAllTools } from 'subscriptions_urql';
import { addClient, updateClient } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ZWizard from 'components/core/ZWizard';

import ZAddToolLockerDialog from 'components/dialogs/ZAddToolLockerDialog';
import ZAddToolKitDialog from 'components/dialogs/ZAddToolKitDialog';
import ZAddToolDialog from 'components/dialogs/ZAddToolDialog';

import ZToolLockerPanel from 'components/panels/ZToolLockerPanel';
import ZToolKitPanel from 'components/panels/ZToolKitPanel';
import ZToolsPanel from 'components/panels/ZToolsPanel';


import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';
//const ResponsiveReactGridLayout = WidthProvider(Responsive);

//////////////////////////////////////////////

const ZToolingView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
  
  //const [clientId, storeClientId] = useLocalStorage('client_id', '');
  let { id } = useParams();
  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({});
  const [showDialog, setShowDialog] = React.useState(false);
  const [locker, setLocker] = React.useState(null);
  const [lat, setLat] = React.useState(null);
  const [lng, setLng] = React.useState(null);

  ////////////////
  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolingView [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  }, [setLat,setLng]);
  /////////////////

  //let locker_rows = [];
  //let toolkit_rows = [];
  //let tool_rows = [];

  let tool_locker_panel = React.useRef(<h2>Loading... <CircularProgress /></h2>)
  let tool_kit_panel = React.useRef(<h2>Loading... <CircularProgress /></h2>)
  let tool_panel = React.useRef(<h2>Loading... <CircularProgress /></h2>)

  /*
  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllToolLockers,
  });
  //const [updateSalesDocResult, updateFunction] = useMutation(updateClient);

  console.log(result)
  const { data, fetching, error } = result;
  */

  const handleLockerSubscription = (previousData, response) => {
    console.log('handleLockerSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    return [...response.zeus_tool_node]
    //row = response
    //row = data.zeus_tool_by_pk
    //jobs = data.zeus_jobboard_entry
  };

  const handleToolKitSubscription = (previousData, response) => {
    console.log('handleToolKitSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    return [...response.zeus_tool_node]
    //row = response
    //row = data.zeus_tool_by_pk
    //jobs = data.zeus_jobboard_entry
  };

  const handleToolSubscription = (previousData, response) => {
    console.log('handleToolSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    return [...response.zeus_tool]
    //row = response
    //row = data.zeus_tool_by_pk
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('setting up subscription')
  const [resLockers] = useSubscription({ 
    query: subscribeAllLockers,
    pause: !window.navigator.onLine
  }, handleLockerSubscription);

  const [ resultLockers, reexecuteLockersQuery ] = useQuery({
    query: getAllToolLockers,
    //pause: window.navigator.onLine
  });  

  let { data: lockersData, fetching: lockersFetching, error: lockersError } = resLockers;

  if(!window.navigator.onLine){
    lockersData = resultLockers.data;
    lockersFetching = resultLockers.fetching;
    lockersError = resultLockers.error;
  }
  
  const [resToolKits] = useSubscription({ 
    query: subscribeAllToolKits,
    pause: !window.navigator.onLine,
  }, handleToolKitSubscription);

  const [ resultToolKits, reexecuteToolKitsQuery ] = useQuery({
    query: getAllToolKits,
    //pause: window.navigator.onLine
  });    

  let { data: toolkitsData, fetching: toolkitsFetching, error: toolkitsError } = resToolKits;
  if(!window.navigator.onLine){
    toolkitsData = resultToolKits.data;
    toolkitsFetching = resultToolKits.fetching;
    toolkitsError = resultToolKits.error;
  }
  
  const [resTools] = useSubscription({ 
    query: subscribeAllTools,
    pause: !window.navigator.onLine,
  }, handleToolSubscription);  

  const [ resultTools, reexecuteToolsQuery ] = useQuery({
    query: getAllTools,
    //pause: window.navigator.onLine
  });  

  let { data: toolsData, fetching: toolsFetching, error: toolsError } = resTools;
  if(!window.navigator.onLine){
    toolsData = resultTools.data;
    toolsFetching = resultTools.fetching;
    toolsError = resultTools.error;
  }

  //if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if(lockersData){
    console.log('useEffect->lockersData')
    let locker_rows = lockersData.slice(0)
    tool_locker_panel.current = (
      <ZToolLockerPanel 
        fullBox
        id='toollocker_panel' 
        title="Tool Lockers"
        data={locker_rows}
        static
      />      
    )
  }

  if(toolkitsData){
    console.log('useEffect->toolkitsData')
    let toolkit_rows = toolkitsData.slice(0)
    tool_kit_panel.current = (
      <ZToolKitPanel 
        id='toolkit_panel' 
        title='Tool Kits'
        data={toolkit_rows}
        parents={toolkit_rows}
        lat={lat}
        lng={lng}
        static
      />     
    )
  }

  if(toolsData){
    console.log('useEffect->toolsData')
    let tool_rows = toolsData.slice(0)
    tool_panel.current = (
      <ZToolsPanel 
        id='tools_panel'
        title='Tools' 
        data={tool_rows}
        parents={tool_rows}
        lat={lat}
        lng={lng}
        static
      />        
    )
  }  

  //////////////////////////////////////////

  console.log('ZToolingView->data')
  //console.log(props.documentType +' data')
  //console.log(data)

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10,
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={6}>
            {tool_locker_panel.current}
          </Col>
          <Col xs={6}>
            {tool_kit_panel.current}          
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            {tool_panel.current}          
          </Col>        
        </Row>
        <Row><br/></Row>
      </Container>
    </div>
  )
}

export default ZToolingView;

/*


  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <ZToolLockerPanel 
              fullBox
              id='toollocker_panel' 
              title="Tool Lockers"
              data={data.tool_lockers}
              static
            />
          </Col>
          <Col xs={6}>
            <ZToolKitPanel 
              id='toolkit_panel' 
              title='Tool Kits'
              data={data.tool_kits}
              parents={data.tool_kits}
              lat={lat}
              lng={lng}
              static
            />            
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZToolsPanel 
              id='tools_panel'
              title='Tools' 
              data={data.zeus_tool}
              parents={data.zeus_tool}
              lat={lat}
              lng={lng}
              static
            />            
          </Col>        
        </Row>
        <Row><br/></Row>
      </Container>
    </div>
  )



<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/
