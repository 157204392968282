import React from 'react';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Switch, Route, Link, BrowserRouter, useHistory} from "react-router-dom";

import Menu from '@material-ui/core/Menu'; //Menu
import MenuItem from '@material-ui/core/MenuItem';
import { useLocalStorage } from 'hooks/useStorage';

import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';

///////////////////////////////////
/*
props
{
  contact: {}
  anchorEl: {}
  handleContactSelect: ()=>{}
}
*/
const ZContactMenu = (props) => {
  const history = useHistory();
  const [showNewQuoteDialog, setShowNewQuoteDialog] = React.useState(false); //ShowNewNoteDialog
  const [showNewNoteDialog, setShowNewNoteDialog] = React.useState(false);

  const [contactId, storeContactId] = useLocalStorage('contact_id', '');
  const [anchorContact, setAnchorContact] = React.useState(null);

  const handleContactSelect = props.handleContactSelect

  const handleShow = () => {
    let path = generatePath('/contact/:id', {
      id: props.contact.id}
      )
    history.push(path)
  }

  const handleNewQuote = () => {
    setShowNewQuoteDialog(true);
    console.log("ZContactMenu::handleNewQuote == "+ showNewQuoteDialog);
    props.onClose()
  };

  const handleCloseNewQuote = () => {
    setShowNewQuoteDialog(false);
  };

  const handleNewNote = () => {
    setShowNewNoteDialog(true);
    console.log("ZContactMenu::handleNewNote == "+ showNewNoteDialog);
    props.onClose()
  };

  const handleCloseNewNote = () => {
    setShowNewNoteDialog(false);
  };

  const handleCloseContact = () => {
    setAnchorContact(null);
  }

  const handleNewQuote_old = () => {
    let contact = props.contact
    storeContactId(contact.id)
    history.push("/clients");
  }

  return (
    <div>
      <ZAddQuotationDialog client={props.client} contact={props.contact} open={showNewQuoteDialog} handleClose={handleCloseNewQuote}/>
      <ZAddAnnotationDialog contact={props.contact} open={showNewNoteDialog} handleClose={handleCloseNewNote}/>
      <Menu
        id="contact-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        //onClose={props.onClose}
        onClose={()=>{
          props.anchorEl = null
        }}
      >
        <MenuItem onClick={handleShow}>Show</MenuItem>
        <MenuItem onClick={handleNewQuote}>New Quote</MenuItem>
        <MenuItem onClick={handleNewNote}>Add Note</MenuItem>
        <MenuItem onClick={handleCloseContact}>Log Activity</MenuItem>
      </Menu>
    </div> 
  )
}

export default ZContactMenu;
