import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getJobBoardEntry } from 'queries_urql'; //subscribeToolBox
import { addAttachment, updateJobBoardEntryField } from 'mutations_urql';
import { subscribeJobBoardEntry } from 'subscriptions_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red, blue, green, cyan } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import BuildIcon from '@material-ui/icons/Build';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactTooltip from 'react-tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Moment from 'react-moment';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QRCode from "react-qr-code";

import ZMapComponent from 'components/core/ZMapComponent';
import ZBreadCrumbs from 'components/core/ZBreadCrumbs';
import ZSetJobScheduledButton from 'components/buttons/ZSetJobScheduledButton';
import ZSetJobClosedButton from 'components/buttons/ZSetJobClosedButton'; 
import ZSetJobClientPOReceivedButton from 'components/buttons/ZSetJobClientPOReceivedButton'; 
import ZAddPrimacQuoteNameButton from 'components/buttons/ZAddPrimacQuoteNameButton'; //ZAddPrimacQuoteNameButton
import ZSetJobInvoicedButton from 'components/buttons/ZSetJobInvoicedButton'; 
import ZAssignTechToJobButton from 'components/buttons/ZAssignTechToJobButton';//ZAssignTechToJobButton
import ZSetJobPrimacPOButton from 'components/buttons/ZSetJobPrimacPOButton';  // SetJobPrimacPOButton
import ZSetTravelAndAccomToJobButton from 'components/buttons/ZSetTravelAndAccomToJobButton';  //addSetTravelAndAccomodationsArrangedForJobButton
import ZSetProductShippedForJobButton from 'components/buttons/ZSetProductShippedForJobButton';//ZSetProductShippedForJobButton
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';// ZFileCloudUploadMenuItem
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';// ZActivitiesTimeline
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

import JobBoardBloC from 'blocs/JobBoardBloC';

import { uploadFileToAzure } from 'azure/AzureBlobAPI';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: blue[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  editField: {
    position: "absolute",
    top: 0,
    right: 0
  },  
  paper: {
    padding: '6px 16px',
  },  
});

/////////////////////////////////////

const ZJobBoardEntryBox = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [isEditable, setIsEditable] = React.useState({
    description: false,
    primacQuoteName: false,
    field: null,
    value: null,
  })
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [updateInvoiceResult, updateFunction] = useMutation(updateJobBoardEntryField);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDateChange = (value) => {
    console.log('handleDateChange')
    console.log(value)
    setIsEditable({...isEditable, field: 'completedOn', value: value})
  }

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZJobBoardEntryBox->handleEditCommit')
    let job_id = props.entry.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: job_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: job_id,
        name: `job ${field} updated`,
        description: `${userData.alias} updated job ${field} from ${props.entry[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating job: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`job update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated Job', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  /////////

  let entry = {}
  let hasValidCustomerPO = false;
  let isInvoiced = false;

  const queryPause = props.static ? true : false;
  const subscriptionPause = props.realtime ? false : true;
  const id = props.entry.id;

  console.log(`ZJobBoardEntryBox->queryPause: ${queryPause}`)
  console.log(`ZJobBoardEntryBox->subscriptionPause: ${subscriptionPause}`)

  const handleSubscription = (previousData, response) => {
    console.log('ZJobBoardEntryBox::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    entry = data.zeus_jobboard_entry_by_pk
    return data
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZJobBoardEntryBox setting up subscription')

  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeJobBoardEntry,
    variables: { id },
    pause: subscriptionPause,
  }, handleSubscription); 


  const [result, reexecuteQuery] = useQuery({
    query: getJobBoardEntry,
    variables: { id },
    pause: queryPause,
  });

  console.log('ZJobBoardEntryBox result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static === undefined){
    entry = data.zeus_jobboard_entry_by_pk
  } else {
    entry = props.entry
  }

  const bloc = new JobBoardBloC(entry);

  /////////

  let smallChips = (<div></div>)
  let images = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)

  let addPrimacQuoteNamedButton = (<div></div>)
  let addSetJobClosedButton = (<div></div>)
  let addSetJobScheduledButton = (<div></div>)
  let addSetJobPrimacPOButton = (<div></div>)
  let addSetJobClientPOReceivedButton = (<div></div>)
  let addSetJobInvoicedButton = (<div></div>)
  let addTechToJobButton = (<div></div>)
  let addSetTravelAndAccomodationsArrangedForJobButton = (<div></div>)
  let addSetProductShippedForJobButton = (<div></div>)

  //////////////////////////////////

  if(props.noactions === undefined || props.noActions === undefined){

    // show these buttons if it's a service job
    if(props.entry.isService === 1 && userRoles.includes('admin')){
      addSetJobScheduledButton = (
        <div>
          <ZSetJobScheduledButton label='Choose Tech' id={id} job={entry} done={entry.scheduled ? true : false} disabled={bloc.isCompleted()}/>      
        </div>
      )

      addSetJobClientPOReceivedButton = (
        <div>
          <ZSetJobClientPOReceivedButton id={id} job={entry} done={bloc.isValidCustomerPO()} disabled={bloc.isCompleted()}/>   
        </div>
      )

      addTechToJobButton = (
        <div>
          <ZAssignTechToJobButton id={id} job={entry} done={entry.technician ? true : false} disabled={bloc.isCompleted()}/>
        </div>
      )

      addSetTravelAndAccomodationsArrangedForJobButton = (
        <div>
          <ZSetTravelAndAccomToJobButton id={id} job={entry} done={entry.isTravelBooked ? true : false} disabled={bloc.isCompleted()}/>
        </div>
      )        
    }

    // show these buttons if it's a product job
    if(props.entry.isProduct === 1 && userRoles.includes('admin')){
      // has product been shipped
      addSetProductShippedForJobButton = (
        <div>
          <ZSetProductShippedForJobButton id={id} job={entry} done={entry.status === 'completed' ? true : false} disabled={entry.status === 'completed' ? true : false}/>
        </div>
      )  
      
      addSetJobPrimacPOButton = (
        <div>
          <ZSetJobPrimacPOButton id={id} job={entry} done={entry.primacPO ? true : false} disabled={bloc.isCompleted()}/>   
        </div>
      )         
    }

    // these are the default buttons that are shown at all times
    if(userRoles.includes('admin')){
      addPrimacQuoteNamedButton = (
        <div>
          <ZAddPrimacQuoteNameButton id={id} job={entry} done={entry.primacQuoteName ? true : false}/>      
        </div>     
      )

      addSetJobClosedButton = (
        <div>
          <ZSetJobClosedButton id={id} job={entry} done={entry.completedOn ? true : false} disabled={bloc.isCompleted()}/>      
        </div>
      ) 

      addSetJobInvoicedButton = (
        <div>
          <ZSetJobInvoicedButton id={id} job={entry} done={bloc.isInvoiced()} disabled={bloc.isCompleted()}/>
        </div>
      )
    }
  }

  ///////////////////////////////////

  if(props.fullBox){

    images = (
      <div>
        <CardActions disableSpacing>       
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show images"
            data-tip="show images"
            data-for="entry_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
          { props.showImages && <ZImageListHorizontal parent_id={props.entry.id} path={`job/${props.entry.id}/images`} data={props.entry.images} /> }             
          </CardContent>
        </Collapse>
      </div>
    )
  }

  let title = (
    <div>
      {/* <Typography  variant='overline' color='primary'>JobBoard Entry</Typography>   <ZBreadCrumbs/> */} 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/jobboard')
          history.push(path)
        }}          
      >
        JobBoard Entry
      </Button> 
      <Typography variant='h3'>{entry.name}</Typography>
    </div>      
  )

  /*
  status = "pending"
  status = "active"
  status = "completed"

  */

  // ready is the default
  let urlValue = `${baseURL}/jobboard_entry/${entry.id}`;

  if(props.noqrcode === undefined){
    qrcode = (
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
        <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={urlValue}
          viewBox={`0 0 512 512`}
        />
      </div>
    )
  }
  
  let assigned_user = (<div></div>)
  let status_colour = "primary"

  if(entry.status === 'active'){
    status_colour = "secondary"
    if(entry.technician){
      assigned_user = (
        <Typography>Assigned Tech: {entry.technician ? entry.technician.alias : 'N/A'}</Typography>
      )
    }
  }

  /////////////////////////

  let lastCheckedOut = (<div>Last Checked-Out: never</div>)
  let invoiceDate  = (<div>Invoiced Date: never</div>)
  let completedDate  = (<div>Completed Date: never</div>)
  let contact = (<div>Contact Name: N/A</div>)
  let createdBy = (<div>Created By: N/A</div>)
  let scheduled = (<div>Scheduled: No</div>)
  let client = (<div>Client: N/A</div>)
  let primacPO = (<div>Primac PO#: N/A</div>)
  let primacQuoteName = (<div>Primac Quote: N/A</div>)

  if(entry.createdBy){
    createdBy = (<div>{`Created By: ${entry.createdBy}`}</div>)
  }

  if(entry.scheduled){
    scheduled = (<div>{`Scheduled: Start: ${entry.scheduled.startDate}  End: ${entry.scheduled.endDate}`}</div>)
  }  


  if(entry.invoiceDate){
    invoiceDate = (
      <div>
        Invoiced Date:&nbsp;
        <Moment format="YYYY-MM-DD hh:mm">
          {entry.invoiceDate}
        </Moment>
      </div>
    )
  }

  if(entry.client){
    client = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client/:id', {
            id: entry.client.id}
          )
          history.push(path)
        }}          
      >
        Client: {entry.client.name}
      </Button> 
    )   
  }

  if(entry.contact !== null){
    contact = ( 
      <div>
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/contact/:id', {
            id: entry.contact.id}
          )
          history.push(path)
        }}          
      >
        Contact: {`${entry.contact.firstName} ${entry.contact.lastName}`}
      </Button>
      </div> 
    )   
  }  

  if(entry.primacPO){
    primacPO = (
      <div>Primac PO#: {`${entry.primacPO}`}</div>
    )
  }

  if(entry.primacQuoteName){
    primacQuoteName = (
      <Typography  color='primary'
        onClick={userRoles.includes('admin') ? ()=>setIsEditable({...isEditable, primacQuoteName: true, field: 'primacQuoteName'}) : null}
      >
        {`Primac Quote: ${entry.primacQuoteName}`}
      </Typography>
    )
  }
  /*
  if(entry.contact !== null){
    contact = (<div>Contact: {`${entry.contact.firstName} ${entry.contact.lastName}`}</div>)
  }  
  */
  ////////////////////////////////////

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>    
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="jobboard_entry" className={classes.avatar}>
              <DepartureBoardIcon />
            </Avatar>
          }
          action={
            <div>
              <Chip
                style={{ marginLeft: 64, marginBottom: 16 }}
                icon={<TrackChangesIcon />}
                label={ entry.status.toUpperCase() }
                color={status_colour}
                clickable
                onClick={props.statusUpdate ? ()=>props.statusUpdate() : null}
                data-for="entry_box_tooltip"
                data-tip="analyze job status"
              />              
              <br/>
              <Paper>
                {addPrimacQuoteNamedButton}
                {addSetJobClientPOReceivedButton} 
                {addSetJobPrimacPOButton}
                {addSetJobScheduledButton}
                {addTechToJobButton}
                {addSetTravelAndAccomodationsArrangedForJobButton}
                {addSetJobInvoicedButton}       
                {addSetProductShippedForJobButton}                           
                {addSetJobClosedButton}    
              </Paper>
              <br/>
              { qrcode }
            </div>
          }
          title={title}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {entry.createdOn}
                </Moment>
              </div>
              {client}
              {contact}
              {isEditable.primacQuoteName ?
                <TextField
                  className={classes.nameField}
                  fullWidth
                  id="details"
                  label="Primac Quote name"
                  defaultValue={entry.primacQuoteName}
                  onChange={(event) =>{
                    console.log(event.target.value)
                    setIsEditable({...isEditable, field: 'primacQuoteName', value: event.target.value});
                    //console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={()=>{
                          console.log('edit commit')
                          handleEditCommit(isEditable.field, isEditable.value)
                          //setIsEditable({...isEditable, description: false, field: null, value: null})
                        }}                    
                      >
                        <CloudUploadIcon />
                      </IconButton>
                    )
                  }}              
                />  
                :
                 <div>{primacQuoteName}</div>
              }
              {primacPO}
              {invoiceDate}             
              {createdBy}
              {assigned_user}
              {scheduled}
              {isEditable.completedOn ?
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={()=>{
                          console.log('edit commit')
                          handleEditCommit(isEditable.field, isEditable.value)
                        }}                    
                      >
                        <CloudUploadIcon />
                      </IconButton>                          
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="closedOn"
                        value={isEditable.value}
                        label="Completed Date"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />                 
                    </Grid>
                  </MuiPickersUtilsProvider> 
                </div> 
                :
                <div
                  onClick={userRoles.includes('admin') ? ()=>setIsEditable({...isEditable, completedOn: true, field: 'completedOn'}) : null}
                >
                  { entry.completedOn === null ?
                    <Typography color='primary'>Completed Date: N/A </Typography>
                  :
                    <Typography color='primary'>
                      Completed Date:&nbsp;
                      <Moment format="YYYY-MM-DD">
                        {entry.completedOn}
                      </Moment>
                    </Typography>
                  }
                </div>
              }
              {
                isEditable.serviceType ?
                <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      console.log('edit commit')
                      handleEditCommit(isEditable.field, isEditable.value)
                      //setIsEditable({...isEditable, description: false, field: null, value: null})
                    }}                    
                  >
                    <CloudUploadIcon />
                  </IconButton>
                  <Select
                    labelId="srvtype-select-label"
                    id="srvtype-simple-select"
                    defaultValue={entry.serviceType}
                    onChange={(event,item) =>{
                      console.log(item.props.value)
                      setIsEditable({...isEditable, field: 'serviceType', value: item.props.value});
                      //console.log("state->"+ JSON.stringify(state));
                    }}                   
                  >
                    <MenuItem value={'Vibration'}>Vibration</MenuItem>
                    <MenuItem value={'Multi-Channel'}>Multi-Channel</MenuItem>
                    <MenuItem value={'Motion Amplification'}>Motion Amplification</MenuItem> 
                    <MenuItem value={'Ultrasound'}>Ultrasound</MenuItem> 
                    <MenuItem value={'Balancing'}>Balancing</MenuItem> 
                  </Select>
                </Paper>                
                :
                <div onClick={userRoles.includes('admin') ? ()=>setIsEditable({...isEditable, serviceType: true, field: 'serviceType'}) : null}>
                  <Typography color='primary'>Service Type: {entry.serviceType ?? 'N/A'}</Typography>
                </div>
              }                 
            </div>
          }
        />
        <CardContent>
          <br/><Divider/><br/>
          {gmap}         
          { smallChips }
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={userRoles.includes('admin') ? ()=>setIsEditable({...isEditable, description: true, field: 'description'}) : null}
            square
          >
            {
              isEditable.description ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Description"
                multiline
                defaultValue={entry.description}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'description', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{entry.description}</Typography>
            }        
          </Paper>
        </CardContent>
        { images }
      </Card>
      <ReactTooltip id="entry_box_tooltip"/>
    </div>
  );
};


export default ZJobBoardEntryBox;

