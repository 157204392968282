import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getZeusUser,  getRolesForUser } from 'queries_urql';
import { addClient, updateSensor } from 'mutations_urql';
import { subscribeAllActiveJobBoardEntries, subscribeSensor } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';

import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZSensorBox from 'components/boxes/ZSensorBox';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';

import ZUserBox from 'components/boxes/ZUserBox';

import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';// ZAttachmentsList
import ZRolesList from 'components/lists/ZRolesList';
import ZTicketsList from 'components/lists/ZTicketsList';//ZTicketsList
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZAddTicketDialog from 'components/dialogs/ZAddTicketDialog';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';
import ZMeasurementList from 'components/lists/ZMeasurementList';
import ZMeasurementPlot from 'components/charts/ZMeasurementPlot'; 
import ZMeasurementPlotly from 'components/charts/ZMeasurementPlotly';
import ZSensorRMSPlotly from 'components/charts/ZSensorRMSPlotly'; //ZSensorRMSPlotly

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import { downloadObjectFromAzure } from 'azure/AzureBlobAPI';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ReactTooltip from 'react-tooltip';
import eventBus from 'utils/EventBus';
import ZAssignRoleToUserButton from './buttons/ZAssignRoleToUserButton';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 400,
    height: 800,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

//////////////////////////////////////////////

const ZZeusUserView = (props) => {
  const theme = useTheme();
  console.log('ZZeusUserView->props');
  console.log(props);

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const classes = useStyles();
  let { id } = useParams();
  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    selectedData: undefined,
    showAnnotationDialog: false,
    showTicketDialog: false,
  });
  
  const [tabIndex, setTabIndex] = React.useState(0);

  let row = undefined;
  let all_roles = [];

  let measurementMap = {};
  let selectedData = [];

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false});
  }  

  const handleCloseRole = () => {
    setState({...state, showRoleDialog: false});
  }  

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };


  //const [updateUserResult, updateFunction] = useMutation(updateUser);

  /////////////////

  /*
  const handleSubscription = (previousData, response) => {
    console.log('ZZeusUserView::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    return response
  };
 
  console.log('setting up subscription')

  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeZeusUser,
    variables: { id },
    pause: !window.navigator.onLine,
    //pause: state.showBookingDialog,
  }, handleSubscription); 
  */

  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getZeusUser,
    variables: { id },
    //pause: window.navigator.onLine,
  });

  const { data, fetching, error } = queryResult

  /*
  if(!window.navigator.onLine){
    console.log('not online')
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }  
  */

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log('ZZeusUserView->data');
  console.log(data);

  row = data.zeus_zeus_user_by_pk  
  all_roles = data.zeus_zeus_role.slice(0)

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showAnnotationDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )  
  
  let addRoleAction = (
    <div>  
      <ZAssignRoleToUserButton id={id} user={row} roles={all_roles} /> 
    </div>
  )   


  let toprow = (
    <Row>
      <Col xs={6}>
        <ZUserBox id='user_box' 
          user={row}
          fullBox
          static
        /> 
      </Col>
      <Col xs={6}>     
       { row.activities ?
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="user_timeline">
                  <TimelineIcon />
                </Avatar>
              }
              title='Timeline'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZActivitiesTimeline size={5} items={row.activities} />
            </Paper>  
          </Card> 
          : <div/>
        }        
        <Row><br/></Row>
        {/* <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`user/${row.id}/attachments`} multiple static/>   */}     
      </Col>     
    </Row>    
  )

  //////////////////////////
  console.log("selectedData")
  console.log(state.selectedData)

  return (
    <div>
      <Container fluid>
        {toprow}
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
            {/* <ZAddRoleDialog open={state.showRoleDialog} user={row} handleClose={handleCloseRole} /> */}
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Roles" />
                  <Tab label="Notes" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>                   
                    <ZRolesList key='roles_panel' title="Roles" action={addRoleAction} data={row.roles} static/>
                  </Box>
                )}  
                {tabIndex === 1 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.asset} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}             
              </Box>              
            </Paper>
          </Col>
        </Row> 
      </Container>
    </div>
  )
}

export default ZZeusUserView;
