
import moment from 'moment';


export default class TicketBloC {

  constructor(entry){
    this._entry = entry;
  }

  isActive = () => {
    if(this._entry.status === 'active'){
      return true;
    } else {
      return false;
    }
  }

  isCompleted = () => {
    if(this._entry.status === 'completed'){
      return true;
    } else {
      return false;
    }
  }
  
} ////////

