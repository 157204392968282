import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getQuotesForClient, getQuotesForContact, getQuotesForSupplier } from 'queries';
import { addQuotation, updateSalesDocument } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getQuotesForClient, getQuotesForContact, getQuotesForSupplier } from 'queries_urql';
import { subscribeQuotesForClient, subscribeQuotesForContact } from 'subscriptions_urql';
import { addQuotation, updateSalesDocument } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import ReactTooltip from 'react-tooltip';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

//////////////////////////

/*
props : {
  client: {}

}
*/

const ZQuotationsList = (props) => {
  const history = useHistory();
  //const [quoteId, storeQuoteId] = useLocalStorage('quote_id', '');
  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [showNewQuoteDialog, setShowNewQuoteDialog] = React.useState(false); 

  let rows = [];

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="accepted">accepted</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
          <strong>       
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/quotation/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
          </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Quote#',
      headerName: 'Quote#',
      minWidth: props.nameW ?? 200,
      editable: false,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 250,
      editable: true,
      hide: false,
    },    
    {
      field: 'amount',
      title: 'Amount',
      headerName: 'Amount',
      minWidth: 120,
      editable: false,
      disableColumnMenu: true,
      type: 'number',
      valueGetter: (params)=>{
        let amount = 0.00
        if(params.row.amount !== null){
          return `$ ${params.row.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        }
        
        return `$ ${amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
      }
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: true,   
      sortable: false,
      cellClassName: (params) =>
      clsx('super-app', {
        negative: params.value === 'closed',
        positive: params.value === 'accepted',
      }),   
    },
    {
      field: 'createdOn',
      title: 'Quoted',
      headerName: 'Quoted',
      type: 'date',
      minWidth: 140,
      editable: false,
      valueGetter: (params) => {
        return params.row.createdOn.split('T')[0];
      },       
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
  ];

  /////////////////////////////////////

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZDataGrid::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleNewQuote = () => {
    setShowNewQuoteDialog(true);
    console.log("ZQuotationsList::handleNewQuote == "+ showNewQuoteDialog);
    props.onClose()
  };

  console.log(props)

  let query = getQuotesForClient
  let subscription = subscribeQuotesForClient
  let variables = {}
  let skip_query = false;
  let skip_sub = false;
  let title = "Quotes"

  if(props.static !== undefined){
    skip_query = true;
    rows = props.items
  }

  if(props.realtime === undefined || props.live === undefined){
    skip_sub = true;
  }

  if(props.title !== undefined){
    title = props.title
  }

  if(props.items !== undefined){
    skip_query = true;
  }

  if(props.client !== undefined){
    let client_id = props.client.id
    variables = { client_id }
  }

  if(props.contact !== undefined){
    query = getQuotesForContact
    subscription = subscribeQuotesForContact
    let contact_id = props.contact.id
    variables = { contact_id }
  }

  if(props.supplier !== undefined){
    query = getQuotesForSupplier
    let supplier_id = props.supplier.id
    variables = { supplier_id }
  }

  const [ result, reexecuteQuery ] = useQuery({
    query: query,
    variables: variables,
    pause: skip_query,
  });

  const [updateSalesDocResult, updateFunction] = useMutation(updateSalesDocument);
  const [insertSalesDocResult, insertFunction] = useMutation(addQuotation);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  // use subscription
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };

 
  const [res] = useSubscription({ query: subscription, pause: skip_sub }, handleSubscription); 

  if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  //////////////////////////

  console.log('ZQuotationsList::rows');
  console.log(rows);

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="quotation" className={classes.quotes_avatar}>
            <BallotIcon />
          </Avatar>
        } 
        action={
          props.children
        }        
        title={title}
        titleTypographyProps={{variant:'h5'}}
      />
      <CardContent>
        <Paper>
          <DataGrid autoHeight
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            getRowClassName={(params) =>
              clsx('super-app', {
                negative: params.row.status === 'closed',
                positive: params.row.status === 'accepted',
              })
            }
            options={{
              toolbar: true,
              filtering: true,
              search: true,
              grouping: true,
              exportButton: true,
            }}
            onCellEditCommit={handleCellEditCommit}
          />
        </Paper>
      </CardContent>
      <ReactTooltip id="quotations_list_tooltip"/>
    </Card>
  ); 
}
  
export default ZQuotationsList;

/*
 className={classes.quote_row}
*/
