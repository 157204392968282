import React from 'react';
import AWS from 'aws-sdk';
import fs from 'fs';

import Button from '@material-ui/core/Button';

import ReactTooltip from 'react-tooltip';

///////////////////////////////////

const ZAttachmentItem = (props) => {

  AWS.config.update({
    accessKeyId: 'JAJHAFJFHJDFJSDHFSDHFJKDSF',
    secretAccessKey: 'jhsdf99845fd98qwed42ebdyeqwd-3r98f373f=qwrq3rfr3rf',
  });

  const handleClick = (e) => {
    e.preventDefault();
  };

  const downloadFile = () => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: props.attachment.bucket,
      Key: props.attachment.path,
    };

    s3.getObject(params, (err, data) => {
      if (err) console.error(err);
      fs.writeFileSync(props.attachment.name, data.Body.toString());
      console.log(`${props.attachment.name} has been created!`);
    });
  };

  const openFile = () => {
    console.log('openFile')
    console.log(props.attachment)
    window.open(props.attachment.url, "", "width=320, height=640")
  };

  return (
    <>
      <Button color="primary" 
        id={props.id}
        data-tip={props.attachment.description}
        data-for="attachment_item_tooltip"  
        style={{ marginLeft: 8 }}
        onClick={openFile}                     
      >
        {props.attachment.name}
      </Button>
      <ReactTooltip id="attachment_item_tooltip"/>
    </>
  );
};

export default ZAttachmentItem;

/*
  const handleDownload = () => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: props.attachment.bucket,
      Key: props.attachment.path,
    };


    function downloadBlob(blob) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = props.attachment.name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }

    s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        let pdfBlob = new Blob([data.Body.toString()], {
          type: 'text/csv;charset=utf-8;',
        });
        downloadBlob(pdfBlob);
      }
    });
  } 
*/