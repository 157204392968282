import React from 'react';
import PropTypes from 'prop-types';
import MaterialChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const ZChip = (props) => {
  const StyledChip = withStyles({
    root: {
      'color': 'white',
      'backgroundColor': `${props.color} !important`,
      '&:hover': {
        backgroundColor: props.color,
        filter: 'brightness(120%)',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: props.color,
        borderColor: props.color,
      },
    },
    label: {
      color: "black",
      // width: "32px",
      // height: "32px",
      //padding: "5px",
      //border: "1px solid",
      //backgroundColor: props.color,
    },    
    outlined: {
      color: props.color,
      border: `1px solid ${props.color}`,
      backgroundColor: `transparent !important`,
    },
    icon: {
      color: props.variant === 'outlined' ? props.color : 'white',
    },
    deleteIcon: {
      color: props.variant === 'outlined' ? props.color : 'white',
    },
  })(MaterialChip);

  return <StyledChip {...props} />;
};

ZChip.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.oneOf(['regular, outlined']),
};

export default ZChip;