import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getRoutesForClient, getToolKitsForNode } from 'queries_urql';
import { subscribeToolKitsForNode } from 'subscriptions_urql';
import { addRoute, updateToolNode } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import ReactTooltip from 'react-tooltip';

import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZAddToolKitDialog from 'components/dialogs/ZAddToolKitDialog';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  route_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //red
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100],   //green
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  parent: {}

}
*/

const styles = mergeStyleSets();

const ZToolKitsList = (props) => {
  const history = useHistory();
  //const [routeId, storeRouteId] = useLocalStorage('route_id', '');
  const [userData, setUserData] = useLocalStorage('userData');
  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [showCalendarCallout, setShowCalendarCallout] = React.useState(false);

  const [alertState, setAlertState] = React.useState({
    severity: 'info',
    open: false,
    message: '',
  });

  const [state, setState] = React.useState({
    showCalendarCallout: false,
    showBookingDialog: false,
    showAddToolKitDialog: false,
    activeEntry: null,
    node_id: null,    
  });


  let rows = []

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/toolnode/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
            <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  //setActiveEntry(params.row)
                  setState({...state, activeEntry: params.row, showBookingDialog: true})
                }}          
              >
                <CalendarTodayIcon />
              </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'ToolKit',
      headerName: 'ToolKit',
      minWidth: props.nameW ?? 300,
      editable: false,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: props.descriptionW ?? 440,
      editable: true,
      hide: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 140,
      editable: 'never',
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
  ];

  /*
  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZRoutesList::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );
  */
  
  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  const handleClickOpen = () => {
    if(props.handleOpen){
      console.log('ZToolsPanel::handleClickOpen props.handleOpen is present handing off to parent');
      props.handleOpen()
      return;
    }
    console.log("ZToolsPanel::handleClickOpen->open add tool dialog")
    setState({...state, showAddToolKitDialog: true});
    console.log("ZToolsPanel::handleClickOpen == "+ state.showAddToolKitDialog);
  };


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleClickOpen}>New Kit</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
      </Menu>
    </div>  
  )

  let node_id = null;
  let skip_query = true;
  let skip_sub = true;

  if(props.static ){
    skip_query = true;
  } else {
    node_id = props.parent.id
    skip_query = false
  }

  if(props.realtime || props.live ){
    skip_sub = true;
  }

  let variables = {
    node_id
  }
  
  if(props.parent.node_type === undefined){
    let id = props.parent.id
    variables = {
      id
    }
  }

  console.log(props)

  if(props.data){
    //rows = props.data
    skip_query = true;
  }


  const [updateToolKitResult, updateFunction] = useMutation(updateToolNode);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZToolKitsList::handleCellEditCommit');
      console.log(id, field, value)
      var _set = {}
      _set[field] = value
      console.log('variables')
      console.log({ id: id, _set })
      const createdOn = moment().format()

      updateFunction( { id: id, user_id: userData.id, createdOn: createdOn, _set } ).then((result)=>{
        if(result.error){
          console.log(result.error)
          setAlertState({...alertState, message: `ERROR: ${result.error}`, open: true, severity: 'error'})
        } else {
          console.log("successfully updated tool kit")
          setAlertState({...alertState, message: "successfully updated tool kit", open: true, severity: 'info'})
        }
      })
    },
  [updateFunction]);

  /*
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };
 
  const [res] = useSubscription({ query: subscribeToolKitsForNode, variables: variables, pause: skip_sub, }, handleSubscription); 
  */

  const handleClickBookingOpen = () => {
    console.log("ZToolKitsList::handleClickBookingOpen->open booking dialog wizard")
    setState({...state, showBookingDialog: true});
    console.log("ZToolKitsList::handleClickBookingOpen == "+ state.showBookingDialog);
  };
  
  const handleBookingClose = () => {
    setState({...state, showBookingDialog: false});
  };

  const calcRestrictedDates = () =>{
    let restrictedDates = []
    console.log('callout')
    console.log(state.activeEntry)
    state.activeEntry.bookings.forEach((elem)=>{
      //console.log(elem)
      let d = new Date(elem.theDate);
      console.log(d)
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  const [ result, reexecuteQuery ] = useQuery({
    query: getToolKitsForNode,
    variables: { node_id },
    pause: skip_query,
  });
  

  let { data, fetching, error } = result

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.data){
    rows = [...props.data]
  }else{
    rows = [...data.zeus_tool_node]
  }
  
  /////////////////////////result

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="toolkit actions"
      data-for="toolkit_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>    
  )

  defaultAction = (
      <IconButton
      onClick={handleClickOpen}
      color="inherit"
      aria-label="add row"
      edge="start"
      data-tip="create new toolkit"
      data-for="toolkit_list_tooltip" 
    >
      <AddCircleOutlineIcon/>
    </IconButton>
  )
  

  let title = 'Book ToolKit';
  if(state.activeEntry !== null){
    title = `Book ToolKit: ${state.activeEntry.name}`;
  }

  ////////////////////
  
  return (
    <div>
      <ZAddToolKitDialog open={state.showAddToolKitDialog} handleClose={()=>setState({...state, showAddToolKitDialog: false})} static/>
      <ZBookToolDialog open={state.showBookingDialog} title={title} handleClose={handleBookingClose} lat={props.lat} lng={props.lng} data={state.activeEntry} static toolnode/>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        message={alertState.message}
        key="alert_button"
        onClose={()=>{
          setAlertState({...alertState, open: false})
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alertState, open: false})
              }}
            >
              No
            </Button>
          </div>
        }
      />  
      {state.showCalendarCallout && (
        <Callout
          className={styles.callout}
          role="dialog"
          gapSpace={0}
          onDismiss={()=>{
            setState({...state, showCalendarCallout: false})
          }}
          setInitialFocus
        >
          <Text as="h4" block variant="xLarge" className={styles.title}>
            {`Bookings for ${state.activeEntry.name}`}
          </Text>
          <ZFluentCalendar 
            restrictedDates={calcRestrictedDates()}
            target='#toolkit-data-grid'
          />
        </Callout>
      )}
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="toolkits" className={classes.routes_avatar}>
              <NextWeekIcon />
            </Avatar>
          }      
          action={props.action ? props.action : defaultAction}
          
          title={props.title ? props.title : 'ToolKits'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper id='toolkit-data-grid' className={classes.route_row}>
            <DataGrid autoHeight             
              rows={rows}
              columns={columns}
              pageSize={props.pageSize ?? 20}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  negative: params.row.status === 'in-use',
                  positive: params.row.status === 'ready',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={handleCellEditCommit}
              onRowClick={(params, evt) => {
                let toolkit = params.row;
                console.log("selected toolkit")
                console.log(toolkit)
                setState({...state, activeEntry: toolkit, showCalendarCallout: true});
              }}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="toolkit_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZToolKitsList;
