import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

import { uuid } from 'uuidv4';
import moment from 'moment';

/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
import { addQuotation, updateQuotation} from '../../mutations';
*/

import { useMutation, useQuery } from 'urql';
import { getAllQuotations, getAllClients, getClient, getContact } from 'queries_urql';
import { addQuotation, updateQuotation} from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container'; //FormControlLabel
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import  ZWizard  from 'components/core/ZWizard';
//import  ZAutoCompleteInput  from 'components/core/ZAutoCompleteInput';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ZClientAutocomplete  from 'components/core/ZClientAutocomplete';  //
import ZContactAutocomplete from 'components/core/ZContactAutocomplete';  //ZContactAutocomplete
import ZSupplierAutocomplete from 'components/core/ZSupplierAutocomplete'; 
import ZUserAutocomplete from 'components/core/ZUserAutocomplete';  //ZUserAutocomplete
import ZProductTransferList from 'components/core/ZProductTransferList';  //ZProductTransferList

import ZPipelineInput from 'components/core/ZPipelineInput'; // ZPipelineInput
import ZQuotationNumberInput from 'components/core/ZQuotationNumberInput'; //  ZQuotationNumberInput

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

const ZAddQuotationDialog = (props) => {
  const classes = useStyles()
  const { id } = useParams()

  const [state, setState] = React.useState({})
  const [clientId, setClientId] = React.useState('')

  const [quoteType, setQuoteType] = React.useState('IQ')
  const [funnel, setFunnel] = React.useState('')
  const [amount, setAmount] = React.useState(0)
  const [isService, setIsService] = React.useState(false)

  const [supplier, setSupplier] = React.useState({})
  const [client, setClient] = React.useState({})
  const [preparedBy, setPreparedBy] = React.useState({})

  const [quotationNumber, setQuotationNumber] = React.useState('')

  const [clientName, setClientName] = React.useState('')
  const [contactName, setContactName] = React.useState('')

  const [contact, setContact] = React.useState('')
  const [region, setRegion] = React.useState({})
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [items, setItems] = React.useState([])
  const [contacts, setContacts] = React.useState([])

  const [address, setAddress] = React.useState({})
  const [geometry, setGeometry] = React.useState({})
  const [product, setProduct] = React.useState({})

  const [addQuoteResult, insertQuoteFunction] = useMutation(addQuotation);

  const handleReview = () => {
    const x = {
      name: name,
      description: description,
    }
    console.log("handleReview->"+ JSON.stringify(x));
    setState(x) 
  }
  
  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      setRegion({province: province, country: country})
      setGeometry({ lat, lng })
      setAddress(place) 
    });    
  }

  const handleSubmit = () => {
    console.log("ZAddQuotationDialog::handleSubmit->"+ JSON.stringify(state));
    const guuid = uuid()
    const createdOn = moment().format()
    insertQuoteFunction({
      variables: { 
        id: guuid,
        name: quotationNumber, 
        description: description, 
        amount: amount,
        funnel: funnel,
        preparedBy: preparedBy,
        client_id: clientId,
        createdOn: createdOn,
        deleted: false, 
      }
    })
  }

  const handleClientChange = (event,item) => {
    console.log("handleClientChange")
    console.log(item)
    //let address = item.
    setClient(item)
  }

  const handleAddProduct = (event,item) => {
    console.log("handleAddProduct")
    console.log(item)
    setProduct(item) 
  }

  const handleContactChange = (event,item) => {
    console.log("handleContactChange")
    console.log(item)
    setContact(item)
  }

  const handleQuoteTypeChange = (event,item) => {
    console.log("handleQuoteTypeChange")
    console.log(item)
    setQuoteType(item)
  }

  const handleServiceChange = (event,item) => {
    console.log("handleServiceChange")
    console.log(item)
    setIsService(item)
  }

  const handleSupplierChange = (event,item) => {
    console.log("handleSupplierChange")
    console.log(item)
    setSupplier(item)
  }

  const handleProductChange = (event,item) => {
    console.log("handleProductChange")
    console.log(item)
    setProduct(item)
  }

  const handleRegionChange = (event,item) => {
    console.log("handleRegionChange")
    console.log(item)
    setRegion(item)
  }

  ////////////////////////////

  return(
    <div>
      <ZWizard 
        title="Create New Quote"
        steps={[
          'Select Client',
          'Sales Details',
          'Quote Details',
          'Select Product Items',
        ]}
        open={props.open} 
        onClose={props.handleClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
      >
        { /*  client details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                props.client 
                ? <ZClientAutocomplete client={props.client} isOpen={props.open} onChange={handleClientChange} />
                : <ZClientAutocomplete isOpen={props.open} onChange={handleClientChange} />
              }
            </Paper>   
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                (props.client && props.contact) 
                ? <ZContactAutocomplete client={client} isOpen={props.open} contact={contact} onChange={handleContactChange}/>
                : <ZContactAutocomplete client={client} isOpen={props.open} onChange={handleContactChange} />
              }
            </Paper> 
          </Box>
        </Container>
        { /*  sales details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZPipelineInput  onChange={(event) =>{
                console.log("setFunnel->"+ JSON.stringify(event.target.value));
                setFunnel(event.target.value);
              }}/>
            </Paper>  
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZUserAutocomplete  isOpen={props.open} onChange={(event) =>{
                console.log("setUser->"+ JSON.stringify(event.target.value));
                setPreparedBy(event.target.value);
              }}/>
            </Paper>  
          </Box>
        </Container>

        { /*  Quote details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Select
                labelId="quotetype-select-label"
                id="quotetype-simple-select"
                value={quoteType}
                onChange={handleQuoteTypeChange}
              >
                <MenuItem value={'IQ'}>Inside Quote</MenuItem>
                <MenuItem value={'OQ'}>Outside Quote</MenuItem>
                <MenuItem value={'IP'}>Internal Proposal</MenuItem> 
                <MenuItem value={'MP'}>Mock Proposal</MenuItem>       
                <MenuItem value={'MQ'}>Mock Quote</MenuItem>
              </Select>
            </Paper>
              <br/><br/>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Select
                labelId="service-select-label"
                id="service-simple-select"
                value={isService}
                onChange={handleServiceChange}
              >
                <MenuItem value={true}>Service</MenuItem>
                <MenuItem value={false}>Equipment</MenuItem>
              </Select>
            </Paper>
              <br/><br/>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="details"
                label="Details"
                multiline
                rows={4}
                onChange={(event) =>{
                  console.log("setDescription->"+ JSON.stringify(event.target.value));
                  setDescription(event.target.value);
                }} 
              />  
            </Paper>  
          </Box>
        </Container>
        {/*  enter supplier  and products */}
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 800, my: 1, mx: 'auto', p: 2 }}>
              <div>
                <ZSupplierAutocomplete isOpen={props.open} onChange={handleSupplierChange} />    
              </div>
            </Paper>
            <Paper sx={{ maxWidth: 800, my: 1, mx: 'auto', p: 2 }}>
              <div> 
                <ZProductTransferList isOpen={props.open} supplier={supplier} onChange={handleProductChange}/>  
              </div>
            </Paper>
          </Box>
        </Container>
        { /*  quotation num */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZQuotationNumberInput quoteType={quoteType} client={client} supplier={supplier} 
                  onChange={(event) =>{
                  console.log("setQuotationNumber->"+ JSON.stringify(event.target.value));
                  setQuotationNumber(event.target.value);
                }}
              />
            </Paper>   
          </Box>
        </Container>


        <Container maxWidth="sm">
          <Box>
          <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Select
                labelId="region-select-label"
                id="region-simple-select"
                value={region}
                onChange={handleRegionChange}
              >
                <MenuItem value={'BC'}>Inside Quote</MenuItem>
                <MenuItem value={'AB'}>Outside Quote</MenuItem>
                <MenuItem value={'SK'}>Internal Proposal</MenuItem> 
              </Select>
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddQuotationDialog;

