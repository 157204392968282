import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';
import { useQuery, useMutation, useSubscription } from 'urql';

import { updateAnnotationField } from 'mutations_urql';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeWorkIcon from '@material-ui/icons/HomeWork'; 
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import { Alert, AlertTitle } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

/////////////////////////////////////

const ZAnnotationBox = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    field: null,
    value: null,
  })

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleMenuClick = (event) => {
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  }

  const [updateResult, updateFunction] = useMutation(updateAnnotationField);

  ////////////////////////////

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZAnnotationBox->handleEditCommit')
    let note_id = props.data.id;
    let parent_id = props.parent.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: note_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: parent_id,
        name: `annotation ${field} updated`,
        description: `${userData.alias} updated annotation ${field} from ${props.data[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating annotation: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`annotation update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated annotation', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);

  /////////

  let clientNotes = (<div></div>)

  let title = props.name ? props.name : props.data.name ;

  if(props.data){
    console.log('creating big title')
    title = (
      <div>
        <Typography  variant='overline' color='primary'>Note</Typography>
        <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
          <Typography variant='h3'>{props.data.name}</Typography>
        </div>
      </div> 
    )    
  }
  
  if(props.title){
    console.log('creating big title')
    title = (
      <div>
        <Typography  variant='overline' color='primary'>{props.title ? props.title : 'Note'}</Typography>
        <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
          <Typography variant='h3'>{props.name}</Typography>
        </div>
      </div> 
    )
  }

  let parent = (<div>Parent: N/A</div>)

  parent = ( 
    <Button 
      component="span"
      //variant="contained"
      color="primary"
      size="small"
      //style={{ marginLeft: 8 }}
      onClick={()=>window.history.back()}          
    >
      Parent
    </Button> 
  )   

  ////////////////////////////

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>  
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="annotation_node" className={classes.avatar}>
              <HomeWorkIcon />
            </Avatar>
          }
          action={
            props.children
          }
          title={
            isEditable.name ?
            <div>
              <Typography  variant='overline' color='primary'>Annotation</Typography>         
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="name"
                label="Name"
                defaultValue={props.data.name}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'name', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />
            </div>               
            :
            title
          }
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {props.createdOn}
                </Moment>
              </div>
              {parent}
            </div>
          }
        />
        <CardContent>        
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >
            {
              isEditable.description ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Description"
                multiline
                defaultValue={props.data.description}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'description', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{props.data.description}</Typography>
            }        
          </Paper>              
          <br/><Divider/><br/>
        </CardContent>
        { props.showImages && <ZImageListHorizontal parent_id={props.data.id} path={`annotation/${props.data.id}/images`} data={props.data.images} /> }
      </Card>
      <ReactTooltip id="client_node_box_tooltip"/>
    </div>
  );
};


export default ZAnnotationBox;
