import { BlobServiceClient, ContainerClient, InteractiveBrowserCredential} from '@azure/storage-blob';
  
const sasURL = "https://rsgcanadacentraldiag.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=co&sp=rwdlacupiyx&se=2100-01-10T08:19:52Z&st=2023-01-10T00:19:52Z&spr=https,http&sig=lhBJTkY%2BGEwyZ5TmFLmeKTEJAGOKdcTssrgQnmWaKz4%3D";
const sasToken = "?sv=2021-06-08&ss=bfqt&srt=co&sp=rwdlacupiyx&se=2100-01-09T09:34:06Z&st=2023-01-09T01:34:06Z&spr=https,http&sig=WNYs4GpaYPPTlkaTL%2Bl23V7KT%2ByoeVWeTU%2Fk72tIWYM%3D";
const containerName = "zeus-primac-app";
const storageAccountName = "rsgcanadacentraldiag";

const createBlobInContainer = async (containerClient, xpath, file) => {
  const fullPath = `${xpath}/${file.name}`
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(fullPath);
  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };
  // upload file
  await blobClient.uploadData(file, options);
}

const blobToString = async (blob) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
}


export const uploadFileToAzure = async (xpath, fileList) => {
  console.log('uploadFileToAzure');
  console.log(fileList);

  if (!fileList) return;

  /*
  if(typeof files !== Array){
    files = [files];
  }
  */

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  console.log('create BlobServiceClient');
  const blobService = new BlobServiceClient(
    sasURL
    //`https://${storageAccountName}.blob.core.windows.net/${sasToken}`
  );
  // get Container - full public read access
  //const fullPath = `${containerName}/${xpath}`;
  console.log('get containerClient');
  const containerClient = blobService.getContainerClient(containerName);

  console.log('createIfNotExists');
  await containerClient.createIfNotExists({
    access: 'container',
  });

  // upload files
  let rset = [];
  for(var i=0; i < fileList.length; i++){
    const file = fileList[i];
    //const base64 = file.base64;
    console.log(file)
    await createBlobInContainer(containerClient, xpath, file);
    const rval = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${xpath}/${file.name}`;
    rset.push( {...file, fileName: file.name, url: rval, bucket: containerName, path: xpath} );
  }

  /*
  fileList.forEach(async (file) => {
    console.log(file)
    await createBlobInContainer(containerClient, xpath, file);
    const rval = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${xpath}/${file.name}`
    rval.push( {...file, fileName: file.name, url: rval, bucket: containerName, path: xpath} );
  })
  */

  console.log('uploadFileToAzure->return');
  console.log(rset);

  return rset;
};  


export const downloadObjectFromAzure = async (xpath) => {
  console.log('downloadObjectFromAzure');

  if (!xpath) return;

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  console.log('create BlobServiceClient');
  const blobService = new BlobServiceClient(
    sasURL
    //`https://${storageAccountName}.blob.core.windows.net/${sasToken}`
  );
  // get Container - full public read access
  //const fullPath = `${containerName}/${xpath}`;
  console.log('get containerClient');
  const containerClient = blobService.getContainerClient(containerName);

  // Get a block blob client pointing to the blob
  const blobClient = containerClient.getBlobClient(xpath);
  // Download and convert a blob to a string
  const downloadBlockBlobResponse = await blobClient.download();
  const jsonData = await blobToString(await downloadBlockBlobResponse.blobBody);
  
  // Parse the JSON data
  const rset = JSON.parse(jsonData);
  console.log(rset);

  return rset;
};  

