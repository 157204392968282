import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";

import moment from 'moment';
import { uuid } from 'uuidv4';
import { v4 as uuidv4 } from 'uuid';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode, getToolNodeEventView } from 'queries_urql';
import { addClient, updateClient, updateToolNode, checkInToolNode, checkInTool, 
  nfcCheckInToolNode, nfcCheckInTool, updateLocation } from 'mutations_urql';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ZToolNodeBox from 'components/boxes/ZToolNodeBox';
import ZCheckInButton from 'components/buttons/ZCheckInButton';
import ZCheckOutButton from 'components/buttons/ZCheckOutButton';
import { ZNFCScanner } from 'components/core/ZNFCScanner';
import Snackbar from 'material-ui/Snackbar';
import { Button, ImageList, ImageListItem, ImageListItemBar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ZBackCameraCapture from './core/ZBackCameraCapture';
import ZFrontCameraCapture from './core/ZFrontCameraCapture';
import { isMobile } from 'react-device-detect';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import Card from 'material-ui/Card';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme)=>({
  root: {
    minWidth: 400,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button_new: {
    margin: theme.spacing(5),
  }, 
  button: {
    margin: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  notes: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    height: '200px',
  },
  image_root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },  
}));

//////////////////////////////////////////////

const ZToolNodeEventView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
  
  const classes = useStyles();
  let { id, usageid } = useParams();
  const history = useHistory();

  //const [state, setState] = React.useState({});
  const [alertState, setAlertState] = React.useState({open: false, message: ''});
  const [position, setPosition] = React.useState({lat: null, lng: null});
  const [imageData, setImageData] = React.useState([]);

  const [userData, setUserData] = useLocalStorage('userData');

  /////

  let usage_id = usageid === undefined ? null : usageid;
  let row = {}
  let jobs = []

  if(props.nfc){
    console.log('element in NFC mode')
  }

  let action = 'status'
  if(props.checkout){
    action = 'checkout'
    console.log("ZToolNodeEventView -> result of a toolnode check-out")
  }

  if(props.checkin){
    action = 'checkin'
    console.log("ZToolNodeEventView -> result of a toolnode check-in")
  }

  if(props.docheckin){
    action = 'docheckin'
  }

  if(props.docheckout){
    action = 'docheckout'
  }

  if(props.status){
    action = 'status'
    console.log("ZToolNodeEventView -> result of a toolnode status")
  }

  const [updateToolNodeResult, updateFunction] = useMutation(updateLocation);
  const [checkInToolNodeResult, checkInToolNodeFunction] = useMutation(nfcCheckInToolNode);
  const [checkInToolResult, checkInToolFunction] = useMutation(nfcCheckInTool);

  React.useEffect(()=>{
    console.log(`ZToolNodeEventView -> navigator.geolocation.watchPosition()`)
    navigator.geolocation.watchPosition((position)=>{
      setPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
      //setLng(position.coords.longitude)
      const guuid = uuidv4();
      const createdOn = moment().format();

      let variables = {
        user_id: userData.id,
        id: guuid,
        parent_id: id,
        createdOn: createdOn,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      
      console.log(`updating toolnode location in database:`);
      console.log(variables)

      updateFunction({object: variables}).then((result)=>{
        if(result.error){
          console.log(`ERROR updating toolnode position: ${result.error}`)
        } else {
          console.log(`ZToolNodeEventView update [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
          console.log(result)
        }
      })
      
    })
  }, [setPosition]);


  const captureImage = React.useCallback((imgSrc)=>{
    console.log('captureImage')
    const guuid = uuidv4()
    const createdOn = moment().format()

    let variables = {
      id: guuid,
      img: imgSrc,
      user_id: userData.id,
      parent_id: id,
      createdOn: createdOn,
    }

    console.log('captureImage->variables')
    console.log(variables)
    setImageData(oldArray => [...oldArray, variables]);
  },[]);

  ///////////////////////////////////////
  
  let skip_query = false;
  const [ result, reexecuteQuery ] = useQuery({
    query: getToolNodeEventView,
    variables: { id },
    pause: skip_query,
  });

  console.log('ZToolNodeEventView -> result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  row = data.zeus_tool_node_by_pk
  jobs = data.zeus_jobboard_entry

  ////////////////////////////////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  //////////////////////////////////

  let the_button = (
    <ZCheckOutButton key='node_checkout' id={row.id} data={row} toolnode/>
  )

  if(row.status === 'in-use'){
    the_button = <ZCheckInButton key='node_checkin' id={row.id} data={row} toolnode/> 
  }


  let snackbarEl = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to close snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              setAlertState({
                open: false,
                message: ""
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    />     
  )


  // let docheckout be the default box

  let container = (
    <Container fluid>
      <Row>
        <Col xs={8}>
          <ZToolNodeBox id='node_box' 
            node={row}
            lat={position.lat}
            lng={position.lng}
            usage_id={usage_id}
            jobs={jobs}
            fullBox
            static
            docheckin={props.docheckin !== undefined ? true : false}
            docheckout={props.docheckout !== undefined ? true : false}
            nfc={props.nfc !== undefined ? true : false}
            status
          />
        </Col>
      </Row>
    </Container>
  )


  const handleNFCScanCheckIn = (scannerData) => {
    // we are handling the scan of an item that
    console.log('ZUserBox::handleNFCScanCheckIn');
    console.log(scannerData)
    let serialNumber = scannerData.serialNumber;
    let item_id = scannerData.id;
    let tag_type = scannerData.type === 0 ? 'toolnode' : 'tool' ;
    let tag_name = scannerData.name;

    let the_description = `NFC tag ${serialNumber} was used to checkin ${tag_name} to ToolLocker ${row.name} by ${userData.alias}`;

    // so lets checkin the item
    //($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "")
    if(tag_type === 'toolnode'){
      const guuid = uuidv4()
      const createdOn = moment().format()

      let images = [...imageData]
      images.forEach((value,index)=>{
        value.parent_id = item_id
      })

      let variables = {
        id: guuid,
        user_id: userData.id,
        createdOn: createdOn,
        description: the_description,
        node_id: item_id,
        lat: position.lat,
        lng: position.lng,
        images: images,
      }

      console.log('ZToolNodeEventView::handleNFCScanCheckIn -> variables')
      console.log(variables)

      checkInToolNodeFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR checkInToolNodeFunction ${result.error}`)
          setAlertState({
            open: true,
            message: `Error checking in ${tag_name} using NFC tag ${serialNumber}`,
          })
          return;
        }

        setAlertState({
          open: true,
          message: the_description,
        })
      })
    }

    if(tag_type === 'tool'){
      const guuid = uuidv4()
      const createdOn = moment().format()

      let variables = {
        id: guuid,
        user_id: userData.id,
        createdOn: createdOn,
        description: the_description,
        tool_id: item_id,
        lat: position.lat,
        lng: position.lng,
      }

      console.log('ZUserBox::handleNFCScanCheckIn -> variables')
      console.log(ZToolNodeEventView)

      checkInToolFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR checkInToolFunction ${result.error}`)
          setAlertState({
            open: true,
            message: `Error checking in ${tag_name} using NFC tag ${serialNumber}`,
          })
          return;
        }

        setAlertState({
          open: true,
          message: the_description,
        })
      })
    }
  }

  if(props.doopen){
    container = (
    <Container fluid>
      <Row>
        <Col xs={8}>
          <ZToolNodeBox id='node_box' 
            node={row}
            lat={position.lat}
            lng={position.lng}
            doopen={props.doopen !== undefined ? true : false}
            nfc={props.nfc !== undefined ? true : false}
            action={
              <div>
                <ZNFCScanner title='Scan Check-In' onScan={handleNFCScanCheckIn} />
                { isMobile ? <ZBackCameraCapture width={320} height={480} handleChange={captureImage} /> 
                  : 
                  <ZFrontCameraCapture width={320} height={480} handleChange={captureImage} /> 
                }
              </div>           
            }
            fullBox
            static
            showmap
          />
        </Col>
      </Row>
      <Row><br/></Row>
      <Row>
        <Col xs={8}>
          <Card variant="outlined">
            <Typography>Check-In Images</Typography>
            <Divider/>
            <ImageList className={classes.imageList} cols={3.0}>
              {imageData.map((item,index) => (
                <ImageListItem key={`node_image_${index}`}>
                  <img src={item.img} alt={`node_image_${index}`} />
                  <ImageListItemBar
                    title={item.createdOn}
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Card>
        </Col>
      </Row>
    </Container>
    )
  }

  if(props.docheckout){
    container = (
      <Container fluid>
        <Row>
          <Col xs={8}>
            <ZToolNodeBox id='node_box' 
              node={row}
              lat={position.lat}
              lng={position.lng}
              jobs={jobs}
              fullBox
              static
              docheckin={props.docheckin !== undefined ? true : false}
              docheckout={props.docheckout !== undefined ? true : false}
              nfc={props.nfc !== undefined ? true : false}
              action={the_button}
              status
            />
          </Col>
        </Row>
      </Container>
    )    
  }

  if(action === "checkout"){
    container = (
    <Container fluid>
      <Row>
        <Col xs={8}>
          <ZToolNodeBox id='node_box' 
            node={row}
            lat={position.lat}
            lng={position.lng}
            fullBox
            static
            checkout
          />
        </Col>
      </Row>
    </Container>
    )
  }

  if(action === "checkin"){
    container = (
    <Container fluid>
      <Row>
        <Col xs={8}>
          <ZToolNodeBox id='node_box' 
            node={row}
            lat={position.lat}
            lng={position.lng}
            usage_id={usage_id}
            nfc={props.nfc !== undefined ? true : false}
            fullBox
            static
            showmap
            checkin
          />
        </Col>
      </Row>
    </Container>
    )
  }

  if(action === "docheckin"){
    container = (
    <Container fluid>
      <Row>
        <Col xs={8}>
          <ZToolNodeBox id='node_box' 
            node={row}
            lat={position.lat}
            lng={position.lng}
            usage_id={usage_id}
            docheckin={props.docheckin !== undefined ? true : false}
            docheckout={props.docheckout !== undefined ? true : false}
            nfc={props.nfc !== undefined ? true : false}
            action={the_button}
            fullBox
            static
            showmap
            checkin
          />
        </Col>
      </Row>
    </Container>
    )
  }

  return (
    <div>
      {snackbarEl}
      {container}
    </div>
  )
}

export default ZToolNodeEventView;

  /////////////////
  /*
  React.useEffect(()=>{
    navigator.geolocation.watchPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolNodeEventView [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })

    let posTimer = setTimeout(() => {
      navigator.geolocation.watchPosition((position)=>{
        setLat(position.coords.latitude)
        setLng(position.coords.longitude)
        console.log(`ZToolNodeEventView update [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
      })
    }, 5000);

    return () => {
      console.log("stopping ZToolNodeEventView -> position timer")
      clearTimeout(posTimer);
    };

  },[setLat,setLng]);
  */

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/