import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useStorage';

import { BlobServiceClient, ContainerClient, InteractiveBrowserCredential} from '@azure/storage-blob';
import {  Config } from "config";

/*
import { useMutation } from '@apollo/client';
import { addAnnotation } from '../../mutations';
*/
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { useMutation, useQuery } from 'urql';
import { addTool, addImage, addAttachment, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';



/////////////////////

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '50ch',
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '70ch',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

/////////////////////

  /*
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
  */


const ZAddToolDialog = (props) => {
  const classes = useStyles();

  let conf = null;
  if(process.env.NODE_ENV === "development"){
    conf = Config.development;
  }
  if(process.env.NODE_ENV === "staging"){
    conf = Config.staging;
  }
  if(process.env.NODE_ENV === "production"){
    conf = Config.production;
  }

  const [userData, setUserData] = useLocalStorage('userData');

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [deleted, setDeleted] = React.useState(false);
  const [guuid, setGuuid] = React.useState(uuidv4());
  const [imageData, setImageData] = React.useState({});
  const [fileSelected, setFileSelected] = React.useState(null);
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
  });

  const [state, setState] = React.useState({
    name: '',
    description: '',
  });

  //const sasToken = "st=2023-01-08T16:45:46Z&se=2100-01-02T00:45:46Z&si=zeus-tool-tracker&sv=2021-06-08&sr=c&sig=UAeoDiMHEBRoo%2BgMTivzlYy%2BJ7Oenn1D%2BVNPkL6kUbA%3D";
  //const sasToken = "sp=racwl&st=2023-01-08T23:23:52Z&se=2100-01-09T07:23:52Z&sv=2021-06-08&sr=c&sig=XpTsXMt6pM%2FUDuM8zOIkb639VctLtF6G25OOSnQtzJg%3D"
  //const sasURL = "https://rsgcanadacentraldiag.blob.core.windows.net/zeus-primac-app?st=2023-01-08T16:45:46Z&se=2100-01-02T00:45:46Z&si=zeus-tool-tracker&sv=2021-06-08&sr=c&sig=UAeoDiMHEBRoo%2BgMTivzlYy%2BJ7Oenn1D%2BVNPkL6kUbA%3D";
  //const sasURL = "https://rsgcanadacentraldiag.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=co&sp=rwdlacupiyx&se=2100-01-09T09:34:06Z&st=2023-01-09T01:34:06Z&spr=https,http&sig=WNYs4GpaYPPTlkaTL%2Bl23V7KT%2ByoeVWeTU%2Fk72tIWYM%3D";
  //const sasURL = "https://rsgcanadacentraldiag.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=co&sp=rwdlacupiyx&se=2023-01-10T08:14:04Z&st=2023-01-10T00:14:04Z&spr=https&sig=EJ0kI8GiGrjlf%2BslaEhqOOuk06d0GRhRxFfzVcrIDK0%3D";
  const sasURL = "https://rsgcanadacentraldiag.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=co&sp=rwdlacupiyx&se=2100-01-10T08:19:52Z&st=2023-01-10T00:19:52Z&spr=https,http&sig=lhBJTkY%2BGEwyZ5TmFLmeKTEJAGOKdcTssrgQnmWaKz4%3D";
  const sasToken = "?sv=2021-06-08&ss=bfqt&srt=co&sp=rwdlacupiyx&se=2100-01-09T09:34:06Z&st=2023-01-09T01:34:06Z&spr=https,http&sig=WNYs4GpaYPPTlkaTL%2Bl23V7KT%2ByoeVWeTU%2Fk72tIWYM%3D";
  const containerName = "zeus-primac-app";
  const storageAccountName = "rsgcanadacentraldiag";

  const fileUploadRef = React.useRef();
  //fileUploadRef.current = (<ZFileCloudUploadMenuItem parent_id={guuid} path={`tool/${guuid}/attachments`} noButton/>)

  let path = 'tool/attachments';

  const [insertToolResult, insertToolFunction] = useMutation(addTool);
  const [insertImageResult, insertImageFunction] = useMutation(addImage);  
  const [insertResult, addAttachmentFunction] = useMutation(addAttachment);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    if(props.handleClose){
      props.handleClose()
    }
  };

  const createBlobInContainer = async (containerClient, xpath, file) => {
    const fullPath = `${xpath}/${file.name}`
    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(fullPath);
    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    // upload file
    await blobClient.uploadData(file, options);
  }


  const uploadFileToAzure = async (xpath, file) => {
    if (!file) return;
  
    const signInOptions = {
      // the client id is the application id, from your earlier app registration
      clientId: conf.ZEUS_MSAL_CLIENTID,
      // this is your tenant id - the id of your azure ad tenant. available from your app registration overview
      tenantId: conf.ZEUS_MSAL_TENANTID,
    }
    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(
      sasURL
      //`https://${storageAccountName}.blob.core.windows.net/${sasToken}`
    );
    // get Container - full public read access
    //const fullPath = `${containerName}/${xpath}`;
    const containerClient = blobService.getContainerClient(containerName);
    await containerClient.createIfNotExists({
      access: 'container',
    });
  
    // upload file
    await createBlobInContainer(containerClient, xpath, file);
    const rval = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${xpath}/${file.name}`
    return {fileName: file.name, url: rval, bucket: containerName, path: xpath};
  };  

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    var newState = state
    
    const createdOn = moment().format()
    newState.id = guuid
    newState.createdOn = createdOn
    newState.locker_id = props.parent_id
    setState(newState)

    insertToolFunction({
      user_id: userData.id,
      id: guuid,
      object: state,
    }).then((result)=>{
      if(result.error){
        console.log(`ERROR creating new tool: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR creating new tool ${result.error}`
        });

      } else {
        console.log(`created new tool [ ${newState} ]`)
        console.log(result)
        console.log('uploading any attachments')

        uploadFileToAzure(`tool/${guuid}/attachments`, fileSelected).then((data) => {
          let xuuid = uuidv4();
          let attachment = {}; 
          attachment.id = xuuid;
          attachment.name = data.fileName;
          attachment.url = data.url;
          attachment.bucket = data.bucket;
          attachment.path = data.path;
          attachment.parent_id = guuid;          
    
          console.log('attachment')
          console.log(attachment)
    
          addAttachmentFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
            if (result.error) {
              console.error('ERROR creating attachment', result.error);
              setAlertState({
                message: result.error,
                open:true,
              })
              return
            }
            //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
          })
        }).catch(err => console.error(err))  


        insertNotificationFunction({object: {
          name: `Created new Tool ${state.name}`,
          description: `${userData.alias} created a new Tool ${state.name}`,
          role: 'admin',
        }}); 

        setAlertState({
          open: true,
          message: `Successfully created new Tool`
        });
      }
    })

    if(props.handleClose){
      props.handleClose();
    }  
  }

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };  

  const handleFileInputChange = React.useCallback((e)=>{
    console.log('handleFileInputChange')
    console.log(e.target.files[0]);

    let  file  = imageData.file;
    file = e.target.files[0];

    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        setImageData({
          base64URL: result,
          file
        });
      })
      .catch(err => {
        console.log(err);
      });
  },[]);

  const captureImage = React.useCallback((imgSrc)=>{
    console.log('captureImage')
    const guuid = uuidv4()
    const createdOn = moment().format()

    let variables = {
      id: guuid,
      user_id: userData.id,
      createdOn: createdOn,
      object: {
        id: guuid,
        img: imgSrc,
        user_id: userData.id,
        parent_id: guuid,
        createdOn: createdOn,
      }
    }

    console.log('captureImage->variables')
    console.log(variables)

    insertImageFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: insertImageFunction: ${result.error}`)
        setAlertState({
          open: true,
          message: `Error uploading image ${result.error}`
        })
      
      } else {
        console.log(result)
        setAlertState({
          open: true,
          message: `successfully uploaded image`
        });
      }
    })
  },[]);  

  let file_upload = (
    <div className={classes.image_root}>
      {/* <Input type="file" inputRef={fileInput}/><br/> */ }
      <input type="file" name="file" onChange={handleFileInputChange} />
      <Button variant="outlined" color="primary"
        onClick={()=>{
          console.log('image upload button clicked')
          console.log('uploading selected image file')
          console.log(imageData);
          captureImage(imageData.base64URL);
        }}
      >
        Upload Image
      </Button>
    </div>
  );

  const onFileChange = (event) => {
    // capture file into state
    setFileSelected(event.target.files[0]);
  };

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity="error">
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <Container maxWidth="lg">
        <Box>
          <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.title ? props.title : 'Create New Tool'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please add tool details
              </DialogContentText>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.nameField}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  onChange={(event) =>{
                    var newState = state
                    newState.name = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                />

                <TextField
                  className={classes.nameField}
                  autoFocus
                  margin="dense"
                  id="serialNumber"
                  label="Serial Number"
                  onChange={(event) =>{
                    var newState = state
                    newState.serialNumber = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                />                

                <TextField
                  className={classes.descriptionField}
                  id="description"
                  label="Description"
                  multiline
                  rows={8}
                  onChange={(event) =>{
                    var newState = state
                    newState.description = event.target.value
                    setState(newState)
                    console.log("state->"+ JSON.stringify(state));
                  }} 
                  variant="filled"
                />  
                <ZFileCloudUploadMenuItem parent_id={guuid} path={`tool/${guuid}/attachments`} onFileChange={onFileChange} noButton/>
              </FormControl> 
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </div>
  );
}

export default ZAddToolDialog;

/*
sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}
*/
