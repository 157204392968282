import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { monthlySalesQuery, monthlySupplierSalesQuery } from 'queries';
import { addQuotation, updateSalesDocument } from 'mutations';
*/

import { useQuery, useMutation } from 'urql';
import { monthlySalesQuery, monthlySupplierSalesQuery } from 'queries_urql';
import { addQuotation, updateSalesDocument  } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//////////////////////////
/*
props {
  width: number
  height: number
  year: number
  funnel: string
  percentages: boolean
}
*/
//////////////////////////

const ZMSalesChart = (props) => {
  const theme = useTheme();

  let supplier = false;
  if(typeof props.supplier !== 'undefined'){
    if(! props.supplier.includes('All')){
      supplier = true
      console.log("ZMSalesChart -> supplier is set")
      console.log(props)
    }     
  }

  let query = monthlySalesQuery
  let variables = { accepted_year: props.year }

  if(supplier === true){
     query = monthlySupplierSalesQuery
     variables['supplier'] = props.supplier
  }

  /*
  const { loading, error, data }  = useQuery(query,{ 
    variables: variables,
    pollInterval: 10000,
  });

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: query,
    variables: variables,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  
  var sum = 0.0

  if(supplier === true){

    console.log("monthly sales for supplier")
    console.log(data);

    sum = data.zeus_monthly_supplier_sales_view.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue.total_amount;
    }, 0);

    return (
      <Card>
        <ZTitle>{`Monthly Sales: ${props.year} for ${props.supplier}`}</ZTitle>
        <Typography style={{marginLeft: '2em'}}>${sum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Typography>
        <Paper>
          <LineChart data={data.zeus_monthly_supplier_sales_view} width={props.width ? props.width: 1000} height={props.height ? props.height : 400}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis
              dataKey="accepted_month"
              stroke={theme.palette.text.secondary}
              style={theme.typography.body2}
              //tick={renderCustomAxisTick}
            >
              <Label
                position="middle"
                style={{
                  //textAnchor: 'middle',
                  fill: theme.palette.text.primary,
                  ...theme.typography.body1,
                }}
              >
                Month
              </Label>
            </XAxis>
            <YAxis
              stroke={theme.palette.text.secondary}
              style={theme.typography.body2}
            >
              <Label
                angle={270}
                position="left"
                style={{
                  textAnchor: 'middle',
                  fill: theme.palette.text.primary,
                  ...theme.typography.body1,
                }}
              >
                Sales ($)
              </Label>
            </YAxis>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="total_amount"
              stroke={theme.palette.primary.main}
              dot={true}
            />
            <Tooltip/>
          </LineChart>
        </Paper>
      </Card>
    );
  }

  sum = data.zeus_monthly_sales_view.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue.total_amount;
  }, 0);

  return (
    <Card>
      <ZTitle>Monthly Sales: {props.year}</ZTitle>
      <Typography style={{marginLeft: '2em'}}>${sum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Typography>
      <Paper>
        <LineChart data={data.zeus_monthly_sales_view} width={props.width ? props.width: 1000} height={props.height ? props.height : 400}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="accepted_month"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            //tick={renderCustomAxisTick}
          >
            <Label
              position="middle"
              style={{
                //textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Month
            </Label>
          </XAxis>
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Sales ($)
            </Label>
          </YAxis>
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="total_amount"
            stroke={theme.palette.primary.main}
            dot={true}
          />
          <Tooltip/>
        </LineChart>
      </Paper>
    </Card>
  );
}


export default ZMSalesChart;

/*
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
*/