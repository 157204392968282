import React from 'react';

/*
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { getAllContacts } from '../../queries';
import { updateContact } from '../../mutations'
*/

import { useQuery, useMutation } from 'urql';
import { getAllContacts } from 'queries_urql';
import { updateContact } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocalStorage } from '../../hooks/useStorage';

//import ZDataGrid from 'components/core/ZDataGrid';
import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';

import ZContactDetailPane from 'components/core/ZContactDetailPane';


////////////////////////////////////

const ZContactsPanel = (props) => {
  const history = useHistory();

  const [showDialog, setShowDialog] = React.useState(false);
  const [showDetailPane, setShowDetailPane] = React.useState(false);
  const [activeContact, setActiveContact] = React.useState({});

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <IconButton 
            component="span"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={(event) => { 
              console.log('navigate to contact: '+ params.row.id)
              console.log(params.row.id)
              //storeClientId(params.row.id)
              let path = generatePath('/contact/:id', {
                id: params.row.id}
                )
              history.push(path)
            }}          
          >
            <OpenInNewIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      field: 'firstName',
      title: 'First Name',
      headerName: 'First Name',
      minWidth: 250,
      editable: true,
      hide: false,
    },
    {
        field: 'lastName',
        title: 'Last Name',
        headerName: 'Last Name',
        minWidth: 250,
        editable: true,
        hide: false,
      },
      {
        field: 'email',
        title: 'Email',
        headerName: 'Email',
        minWidth: 250,
        editable: 'never',
        hide: false,
      },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 200,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 150,
      editable: true,
    },
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      minWidth: 200,
      editable: 'never',
    },
  ];

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZDataGrid::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleCloseDetailPane = () => {
    setShowDetailPane(false);
  };

  /*
  const { loading, error, data }  = useQuery(getAllContacts,{ pollInterval: 10000 });
  const [updateFunction] = useMutation(updateContact);
  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [ result, reexecuteQuery ]  = usePollingQuery({
    query: getAllContacts,
  });

  const [updateContactResult, updateFunction] = useMutation(updateContact);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  return (
    <div>
    <ZContactDetailPane isOpen={showDetailPane} handleClose={handleCloseDetailPane} contact={activeContact}/>

    <Card  variant="outlined" elevation={2}>
      <CardContent>
        <Box display="flex" bgcolor="#5c6bc0">
          <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
            Contacts
          </Typography>
        </Box>
        <DataGrid autoHeight
          title="Contacts"
          columns={columns}
          rows={data.zeus_contact}
          pageSize={20}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick={false}
          options={{
            toolbar: true,
            filtering: true,
            search: true,
            grouping: true,
            exportButton: true,
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          onCellEditCommit={handleCellEditCommit}
          onRowClick={(params, evt) => {
            let contact = params.row;
            console.log("selected contact")
            console.log(contact)
            setActiveContact(contact)
            setShowDetailPane(true)
          }}
        />
        </CardContent>
      </Card>
    </div>
  );  
}
  
export default ZContactsPanel;

/*

              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={(params) => { 
                  console.log(params)
                  console.log(params.value)
                  console.log("client: "+ params.id +" clicked")
                }}
                component={RouterLink}
                to={"/client"}
              >
                Open
              </Button>

*/


/*

const useStyles = makeStyles({
  root: {
    marginLeft: 12,
    marginTop: 12,
    marginBottom: 24,
    width: '80%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    color: "#00EE00",
    fontWeight: "bold"
  },
  pos: {
    marginBottom: 12,
  },
});

const ZClientsPanel = (props) => {
  const classes = useStyles();
  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => { alert('clicked') }}
          >
            Open
          </Button>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Client Name',
      headerName: 'Client Name',
      minWidth: 250,
      editable: 'never',
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 200,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      minWidth: 150,
      editable: true,
    },
    {
      field: 'createdOn',
      title: 'Date Quoted',
      headerName: 'Date Quoted',
      minWidth: 200,
      editable: 'never',
    },
    {
      field: 'region.province',
      title: 'Region',
      headerName: 'Region',
      minWidth: 150,
      editable: 'never',
    },
  ];


  const { loading, error, data } = useQuery(getAllClients);
  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  // className={classes.title}
  return (
    <Paper className={classes.root} variant="outlined" elevation={2} raised>
      <CardContent>
      <Box display="flex" bgcolor="#5c6bc0">
        <Typography style={{color:"#FFFFFF", marginLeft: 12}} variant="h3" component="h2">
          Clients
        </Typography>
      </Box>

        <div style={{ height: 350, width: '100%' }}>
        <DataGrid autoHeight
            columns={columns}
            pageSize={props.pageSize}
            rowsPerPageOptions={[5]}
            rows={data.zeus_client}
            options={{
              toolbar: true,
              filtering: true,
              search: true,
              grouping: true,
              exportButton: true,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            //editable={editable}
        />
        </div>
      </CardContent>
    </Paper>
  );  
}

export default ZClientsPanel;
*/

/*
const ZClientsPanel = (props) => {
  const { loading, error, data } = useQuery(getAllClients);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <ul>
      {data.zeus_client.map(c => (
        <li key={c.id}>
          {c.name}
        </li>
      ))}
    </ul>
  );
}

export default ZClientsPanel;
*/

/*
const ZClientsPanel = (props) => {
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0); //totalCount

  setColumns( [
    { 
      field: 'id', 
      title: 'ID', 
      width: 90,
      render: (rowData) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
          >
            Open
          </Button>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Client Name',
      width: 150,
      editable: 'never',
      hide: true,
    },
    {
      field: 'description',
      title: 'Description',
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      width: 110,
      editable: true,
    },
    {
      field: 'createdOn',
      title: 'Date Quoted',
      width: 100,
      editable: 'never',
    },
    {
      field: 'region.province',
      title: 'Region',
      width: 100,
      editable: 'never',
    },
  ]);

  //const [updateQuotation, { data, loading, error }] = useMutation(mutateQuotation);

  /*
  const remoteData =(query)=>{
    console.log("query all quotations - ", query)
    return client.query({
      query:getAllClients, 
      variables:{
        offset:query.page * 5,
        limit:query.pageSize,
      } 
    }).then((res)=>{
      return {
        data:res.data.quotationList,
        page:query.page,
        totalCount:10
      }
    })
  }
  

  const { loading, error, data } = useQuery(getAllClients);

  console.log(data);

  const remoteData = {
    data: data.zeus_client,
    page: 0,
    totalCount: data.zeus_client.length,
  }

  return (
    <div>
        <MaterialTable
            columns={columns}
            data={remoteData}
            options={{
              toolbar: true,
              filtering: true,
              search: true,
              grouping: true,
              exportButton: true,
            }}
            //editable={editable}
        />
    </div>
  );  
}

export default ZClientsPanel;
*/


/*
const ZClientsPanel = (props) => {
  //const [rows, setRows] = React.useState([]);
  const columns = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 90,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
          >
            Open
          </Button>
        </strong>
      ),
    },
    {
      field: 'name',
      headerName: 'Client Name',
      width: 150,
      editable: false,
      hide: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
      editable: true,
    },
    {
      field: 'createdOn',
      headerName: 'Date Quoted',
      width: 100,
      editable: false,
    },
    {
      field: 'region.province',
      headerName: 'Region',
      width: 100,
      editable: false,
    },
  ];

  const [updateClient, { data, loading, error }] = useMutation(mutateClient);

  const handleCellEditCommit = ({ id, field, value }) => {
    updateClient({ variables: { field: value } });
  };

  return (
    <ZDataGrid
      auth={true}
      query={getAllClients}
      columns={columns}
      pageSize={20}
      onCellEditCommit={handleCellEditCommit}
    />      
  );

}

export default ZClientsPanel;
*/

/*
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { getAllClients } from '../queries';
import { client } from './App';
import { ApolloProvider } from "react-apollo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@mui/x-data-grid';

class ZClientsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'name',
        headerName: 'Client Name',
        width: 150,
        editable: true,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 150,
        editable: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 110,
        editable: true,
      },
      {
        field: 'region.province',
        headerName: 'Region',
        sortable: false,
        width: 100,
      },
    ];
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return isAuthenticated() && (
        <ApolloProvider client={client}>
          <Query query={getAllClients}>
              {({ loading, error, data }) => {
                  if (loading) return (<h2>Loading... <FontAwesomeIcon icon={faSpinner} style={{ color: 'blue' }} spin /></h2>);
                  if (error) return (`Error fetching todos.`);
                  <DataGrid
                    rows={data}
                    columns={this.columns}
                    pageSize={20}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                  />
              }}
          </Query>
        </ApolloProvider>
    );
  }
}

export default ZClientsPanel;
*/