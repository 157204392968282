import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllLocations, getClient, getContact } from 'queries_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

////////////////////////////////

const ZSurveyLocationAutocomplete = (props) => {
  let rows = []
  let skip_query = false;

  if(props.items){
    skip_query = true
  }

  if(props.open !== undefined){
    if(props.open){
      skip_query = false;
    }
  }

  /*
  const { loading, error, data }  = useQuery(getAllClients, {skip: skip_query});
  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  */

  const [ result, reexecuteQuery ]  = useQuery({
    query: getAllLocations,
    pause: skip_query,
  });

  console.log('ZSurveyLocationAutocomplete->result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2><LinearProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  //////////////////  

  if( props.items){
    console.log("items")
    console.log(props.items)
  } else {
    console.log("items")
    console.log(data)
    rows = data.zeus_location_type
  }

  return (
    <Autocomplete
      id="choose-location-combo-box"
      onChange={(event,item)=>{
        console.log("location autocomplete change")
        console.log(item)
        if(props.onChange){
          props.onChange(event,item)
        }
      }}
      options={props.items ? [ ...props.items ] : rows}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.name === value.name }
      value={props.default ? props.default : null}

      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Choose Location" variant="outlined" />}
    />
  )
}

export default ZSurveyLocationAutocomplete;
