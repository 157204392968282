import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getClient,  getQuotesForClient } from 'queries_urql';
import { addClient, updateClientNode } from 'mutations_urql';
import { subscribeClientNode } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimelineIcon from '@material-ui/icons/Timeline';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZClientNodeBox from 'components/boxes/ZClientNodeBox';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZInvoicesList from 'components/lists/ZInvoicesList';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZAssetsList from 'components/lists/ZAssetsList';//ZAssetsList
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZAttachmentsList from 'components/lists/ZAttachmentsList';
import ZAssetSurveyList from 'components/lists/ZAssetSurveyList';//ZAssetSurveyList
import ZPurchaseOrdersList from 'components/lists/ZPurchaseOrdersList';

import ZAddJobPurchaseOrderDialog from 'components/dialogs/ZAddJobPurchaseOrderDialog';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

//////////////////////////////////////////////

const ZClientNodeView = (props) => {
  const classes = useStyles();

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
    
  let { id } = useParams();

  const [userRoles, setUserRoles] = useLocalStorage('userRoles');
  const [state, setState] = React.useState({});
  const [showDialog, setShowDialog] = React.useState(false);
  const [tabIndex,  setTabIndex] = React.useState(0); //tabIndexB
  const [tabIndexB, setTabIndexB] = React.useState(0);
  const [showAnnotationDialog, setShowAnnotationDialog] = React.useState(false);
  const [showAssetDialog, setShowAssetDialog] = React.useState(false);
  const [showPODialog, setShowPODialog] = React.useState(false);

  let row = {}

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleAdminTabChange = (event, newTabIndexB) => {
    setTabIndexB(newTabIndexB);
  };

  const handleCloseAnnotation = () => {
    setShowAnnotationDialog(false);
  }

  const handleCloseAsset = () => {
    setShowAssetDialog(false);
  }

  /////////////////

  /*
  const [ result, reexecuteQuery ] = useQuery({
    query: getClient,
    variables: { id },
  });
  */

  const [updateClientResult, updateFunction] = useMutation(updateClientNode);

  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = data.zeus_client_by_pk
    //jobs = data.zeus_jobboard_entry
    return response;
  };
 
  console.log('setting up subscription')
  const [result, executeSubscription] = useSubscription({ 
    query: subscribeClientNode,
    variables: { id },
    //pause: true,
  }, handleSubscription); 

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  row = data.zeus_client_node_by_pk

  console.log('client_node->row')
  console.log(row)

  /////////////

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowAnnotationDialog(true)
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addAssetAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowAssetDialog(true)
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )  

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  return (
    <div>
      <Container fluid>
        <ZAddAnnotationDialog open={showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
        <Row>
          <Col xs={6}>
            <ZClientNodeBox id='client_node_box' 
              title={props.title ? props.title : row.node_type ? row.node_type.name : "Client Node"}
              client={row.client}
              client_node={row}
              parent={row.parent_node}
              name={row.name} 
              description={row.description} 
              createdOn={row.createdOn}
              surveyStats
              showImages
              fullBox
            />
            <Row><br/></Row>
            <ZAttachmentsList key='docs_panel' parent={row} action={<div></div>} data={row.attachments} path={`client_node/${row.id}/attachments`} multiple static/>            
          </Col>
          <Col xs={6}>
          { row.activities ?
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar aria-label="jobboard_entry">
                    <TimelineIcon />
                  </Avatar>
                }
                title='Timeline'
                titleTypographyProps={{variant:'h5'}}
              />
              <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                <ZActivitiesTimeline items={row.activities} />
              </Paper>  
            </Card> 
            : <div/>
          }  
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Assets" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={220} descriptionW={350} action={addNoteAction} parent={row} items={row.annotations} static/>
                  </Box>
                )}              
                {tabIndex === 1 && (
                  <Box>
                    <ZAssetsList key='assets_panel' client={row.client} parent={row} items={row.assets} static />
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>        
        </Row>           
      </Container>
    </div>
  )
}

export default ZClientNodeView;

/*

  {tabIndex === 2 && (
    <Box>
      <ZRoutesList key='routes_panel' action={<div></div>} client={row} items={row.routes} static />
    </Box>
  )}

  {tabIndex === 2 && (
    <Box>
      <ZAssetSurveyList key='survey_panel' action={<div></div>} client={row} items={row.asset_surveys} static/>
    </Box>
  )}

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={8}>
            <ZClientBox id='client_box' 
              client={row}
              name={row.name} 
              description={row.description} 
              createdOn={row.createdOn}
              //annotations={row.annotations}
              //fullBox
            />
          </Col>
          <Col xs={4}>
            <ZContactsList key='contacts_panel' client={row} data={row.all_contacts} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={8}>
            <ZQuotationsList key='quotations_panel' client={row} items={row.quotations} static />
          </Col>
          <Col xs={4}>
            <ZActivitiesList key='activities_panel' client={row} items={row.activities} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={6}>
            <ZClientNodesList key='plants_panel' client={row} items={row.client_nodes} static />
          </Col>
          <Col xs={6}>
            <ZRoutesList key='routes_panel' client={row} items={row.routes} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZAssetsList key='routes_panel' client={row} items={row.assets} static />
          </Col>
        </Row>
      </Container>
    </div>
  )
*/

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/
