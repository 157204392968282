import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useQuery, useMutation, useSubscription } from 'urql';
import { getContact } from 'queries_urql';
import { addContact, updateContact } from 'mutations_urql';
import { subscribeContactsForClient } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card'; //CardActionArea
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List'; 
import ListItem from '@material-ui/core/ListItem'; 
import Button from '@material-ui/core/Button'; //Button
import Menu from '@material-ui/core/Menu'; //Menu
import MenuItem from '@material-ui/core/MenuItem';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; 
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';   //ContactPhoneIcon
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fab from '@material-ui/core/Fab'; //Fab
import LinearProgress from '@material-ui/core/LinearProgress';

import ReactTooltip from 'react-tooltip';

//import ZContactRow from 'components/core/ZContactRow';

import Chip from '@material-ui/core/Chip';

import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';

import ZContactMenu from 'components/core/ZContactMenu';
import ZAddContactDialog from 'components/dialogs/ZAddContactDialog';

///////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  avatar: {
    backgroundColor: green[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

/////////////////////

const ZContactsList = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorContact, setAnchorContact] = React.useState(null); //setActiveContact
  const [activeContact, setActiveContact] = React.useState({});
  const [anchorHeader, setAnchorHeader] = React.useState(null); 
  const [showAddContactDialog, setShowAddContactDialog] = React.useState(false);//showNewContactDialog

  let rows = []

  const popupState = usePopupState({ variant: 'popover', popupId: 'contactsMenu' })
  console.log('popupState')
  console.log(popupState)
  const handleContactSelect = (event) => {
    //console.log(contact)
    console.log(event)
    setAnchorContact(event.currentTarget);
  };

  const handleAddContact = (event) => {
    console.log(event)
    //launch new contact dialog
    setShowAddContactDialog(true)
  };

  const handleContactClose = () => {
    setShowAddContactDialog(false);
  };

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  const handleCloseContact = () => {
    setAnchorContact(null);
  }

  const handleShowContact = () => {
    setAnchorContact(null);
  }

  const handleNewQuoteForContact = () => {
    setAnchorContact(null);
  }

  const handleLogActivityContact = () => {
    setAnchorContact(null);
  }

  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    let currentRows = rows.slice(0)
    let mergedRows = currentRows.concat(response)
    console.log("handleSubscription")
    console.log(mergedRows)
    //setRows(mergedRows)
    rows = mergedRows
  };

  // className={classes.root} component="h2"

  const headerMenu_old = (
    <div>
      <Menu 
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleAddContact}>New Contact</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Close</MenuItem>
      </Menu>
    </div>  
  )

  const headerMenu = (
    <Fab variant="contained" {...bindTrigger(popupState)}
      data-tip="limited contact actions"
      data-for="contact_list_tooltip"            
      //color="primary"
      aria-label="add"
      size="small"
      //onClick={handleCardHeaderClick}
    >
      <div>
        <MoreVertIcon />
        <Menu {...bindMenu(popupState)}
          id="simple-menu"
        >
          <MenuItem onClick={handleAddContact}>New Contact</MenuItem>
          <MenuItem onClick={(e)=>{
            console.log('popupState.close()')
            console.log(e)
            popupState.close()}
          }>Close</MenuItem>
        </Menu>
      </div> 
    </Fab> 
  )


  const [res] = useSubscription({ query: subscribeContactsForClient }, handleSubscription); 
  
  rows = props.data.slice(0)

  return (
    <div>
      <ZAddContactDialog client={props.client} open={showAddContactDialog} handleClose={handleContactClose}/>
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="client" className={classes.avatar}>
              <ContactPhoneIcon />
            </Avatar>
          }
          action={headerMenu}
          title='Contacts'
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper className={classes.root} component="ul">
            <List component="nav" aria-label="contacts">
              {rows.map((contact) => {
                const fullName = contact.firstName +' '+ contact.lastName
                return (
                  <div key={contact.id}>
                    <ListItem                      
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="text"
                      color="primary"
                      button={true}
                      onClick={ (event)=>{
                        setActiveContact(contact.id)
                        handleContactSelect(event)
                      }}
                    >
                      {fullName} - {contact.email}
                    </ListItem>
                    {/* contactMenu */}
                    <ZContactMenu client={props.client} contact={contact} anchorEl={anchorContact} onClose={handleCloseContact}/> 
                  </div>             
                );
              })}
            </List>
          </Paper>
        </CardContent>
        <ReactTooltip id="contact_list_tooltip"/>
      </Card>
    </div>
  );
};

export default ZContactsList;
