import React from 'react';
import clsx from 'clsx';
import {
  alpha,
  ThemeProvider,
  withStyles,
  makeStyles,
  createTheme,
} from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

import { useQuery } from 'urql';

import { getAllToolLockerNames } from 'queries_urql';

import Moment from 'react-moment';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

///////////////////

const ZUniqueTextField = (props) => {
  const [error, setError] = React.useState(false)
  const [state, setState] = React.useState("")
  const [valid, setValid] = React.useState(false)

  const verifyValue = (event) => {
    var vv = props.data.findIndex((element,index)=>{
      if(element.name === event.target.value) { 
        return true
      }
      return false
    })

    if(vv === -1){
      setValid(true)
      setState(event.target.value)
      setError(false)
      props.handleChange(state)
    } else {
      setValid(false)
      setError(true)
    }
  }

  return (
    <TextField
      label={props.label}
      margin="normal"
      variant={props.variant}
      required
      error={error}
      onChange={verifyValue}
    />
  );
};

export default ZUniqueTextField;