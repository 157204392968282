import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAnnotationsForSalesDocument } from 'queries';
import { addAnnotation, updateAnnotation } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllTickets} from 'queries_urql';
import { addAnnotation, updateTicketField  } from 'mutations_urql';
import { subscribeAnnotationsForParent } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import Snackbar from 'material-ui/Snackbar';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FlashOnIcon from '@material-ui/icons/FlashOn';

import ReactTooltip from 'react-tooltip';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  ticket_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  tickets_avatar: {
    backgroundColor: red[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  items: {}

}
*/

/////////////////////////////////////////

const ZTicketsList = (props) => {
  const history = useHistory();
  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [userData, setUserData] = useLocalStorage('userData');

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/ticket/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Title',
      headerName: 'Title',
      minWidth: 180,
      editable: true,
      hide: false,
    },
    {
      field: 'parent',
      title: 'Parent',
      headerName: 'Parent',
      minWidth: 150,
      editable: false,
      hide: false,
      valueGetter: (params) =>{
        //console.log(params.row)
        let elementName = 'N/A';
        if(params.row.tool !== null){
          elementName = params.row.tool.name;
        }

        if(params.row.toolNode !== null){
          elementName = params.row.toolNode.name;
        }

        return elementName;
      },
      renderCell: (params) => (
        <div>
          <ButtonGroup size="small">
            <div>        
              <Button 
                component="span"
                //variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let element = params.row.toolNode !== null ? 'toolnode' : 'tool' ;
                  let element_id = params.row.toolNode !== null ? params.row.toolNode.id : params.row.tool.id ;
                  let path = generatePath(`/${element}/:id`, {
                    id: element_id}
                    )
                  history.push(path)
                }}          
              >
                {params.row.toolNode !== null ? params.row.toolNode.name : params.row.tool !== null ? params.row.tool.name : 'N/A'}
              </Button>
            </div>
          </ButtonGroup>
        </div>
      ),     
    },       
    {
      field: 'daysOld',
      title: 'Age',
      headerName: 'Age',
      type: 'date',
      minWidth: 180,
      editable: false,
      renderCell: (params) => (
        <Moment fromNow>
         {params.row.createdOn}
        </Moment>
      )
    },
    {
      field: 'closer',
      title: 'Assigned User',
      headerName: 'Assigned User',
      minWidth: 140,
      editable: false,
      hide: false,
      renderCell: (params) => {
        if(params.row.closer){
          return params.row.closer.alias;
        } else {
          return 'None';
        }
      }
    },
    {
      field: 'description',
      title: 'Reason',
      headerName: 'Reason',
      minWidth: 220,
      editable: false,
      hide: false,
    },
    {
      field: 'resolution',
      title: 'Resolution',
      headerName: 'Resolution',
      minWidth: 220,
      editable: false,
      hide: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 140,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      )
    },
  ];

  const handleCellEditCommit_1 = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZTicketsList::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );


  const handleCellEditCommit = React.useCallback(({ id, field, value }) => {
    console.log('ZTicketBox->handleCellEditCommit')
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: id,
      _set: _set,
      activities: [{
        user_id: user_id,
        parent_id: id,
        name: `ticket ${field} updated`,
        description: `${userData.alias} updated ticket ${field} to ${value}`,          
      }],
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);

      if(result.error){
        console.log(`ERROR updating ticket: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'});

      } else {
        console.log(`ticket update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated Ticket', severity: 'success'})
      }
    })

  },[]);

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Note</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
      </Menu>
    </div>  
  )

  console.log(props)

  let items = []
  let query = getAllTickets
  let variables = {}

  let skip_query = false;
  let skip_sub = true;
  let title = "Tickets"

  if(props.static){
    skip_query = true;
  }

  if(props.realtime === undefined || props.live === undefined){
    skip_sub = true;
  }

  if( props.title){
    title = props.title
  }

  if( props.items){
    skip_query = true;
  }

  if( props.client){
    variables = { client_id: props.client.id }
  }

  console.log(props)

  const [ result, reexecuteQuery ]  = useQuery({
    query: query,
    variables: variables,
    pause: skip_query,
  });

  const [updateResult, updateFunction] = useMutation(updateTicketField);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;


  if(skip_query === false){
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_query === true){
    items = props.items !== undefined ? props.items : props.data;
  } else {
    items = data.zeus_ticket
  }

  console.log(data);

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="notes actions"
      data-for="tickets_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>    
  )

  //////////////////////////////////

  return (
    <div>
      {/* alert component */}
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="alert_bar"
        onClose={()=>setAlertState({...alertState, open: false})}
      >   
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>       
      </Snackbar> 
      {/* main component */}
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="ticket" className={classes.tickets_avatar}>
                <FlashOnIcon />
              </Avatar>
            }      
            action={props.action ? props.action : defaultAction}         
            title={title}
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>
            <Paper className={classes.ticket_row}>
              <DataGrid autoHeight
                rows={items}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                getRowClassName={(params) =>
                  clsx('super-app', {
                    negative: params.row.status === 'active',
                    positive: params.row.status === 'completed',
                  })
                }
                options={{
                  toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  exportButton: true,
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                onCellEditCommit={handleCellEditCommit}
              />
            </Paper>
          </CardContent>
        </CardActionArea>
        <ReactTooltip id="tickets_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZTicketsList;
