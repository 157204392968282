import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { uuid } from 'uuidv4';
import moment from 'moment';

/*
import { addAnnotation } from 'mutations';
import { useMutation } from '@apollo/client';
*/

import { useMutation, useQuery } from 'urql';
import { addJobBoardEntry } from 'mutations_urql';
import { getNewJobNumber } from 'queries_urql';

import S3 from 'react-aws-s3';
import gql from 'graphql-tag';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    width: '35ch',
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  avatar_closed: {
    backgroundColor: red[500],
  },
  avatar_pending: {
    backgroundColor: blue[500],
  },
  avatar_accepted: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
  props {
    client_id
    contact_id
    sales_document_id
    asset_id
    annotation_id
    supplier_id
    job_id
    route_id
    activity_id
    title
    name  **file name
    description
    bucket
    dirName
    s3Url
  }
*/

const ZAddJobInputMenuItem = (props) => {
  const classes = useStyles();
  const history = useHistory();
  //const fileInput = React.useRef();
  const [name , setName] = React.useState("");  //C4999 or CP4999
  const [productJob , setProductJob] = React.useState(false);
  const [serviceJob , setServiceJob] = React.useState(false);
  const [customerPO , setCustomerPO] = React.useState("");
  const [description, setDescription] = React.useState(null);
  const [alertOpen , setAlertOpen]  = React.useState(false);
  const [error , setError]          = React.useState(null);

  console.log('ZAddJobInputMenuItem - building job board entry input')
  console.log(props.sales_document)
  
  let sales_document = props.sales_document;

  const [insertFunction] = useMutation(addJobBoardEntry);

  const handleSubmit = (event) => {
    event.preventDefault()
    const guuid = uuid()
    const createdOn = moment().format()
    let entry = {
      id: guuid,
      name: name,
      customerPO: customerPO,
      description: description,
      isProduct: productJob,
      isService: serviceJob,
      createdOn: createdOn,
    }

    var prefix = "C"
    if(productJob === 1 && serviceJob === 1){
      prefix += "(P)"
    }

    if(productJob === 1 && serviceJob === 0){
      prefix += "P"
    }

    entry.prefix = prefix
    // sales_document
    entry.client_id = sales_document.client_id
    entry.companyName = sales_document.client.name
  
    insertFunction({variables: entry}).then((result)=>{
      if (result.error) {
        console.error('ERROR creating revised quote', result.error);
        setError(result.error)
        setAlertOpen(true)
        return
      }
      let path = generatePath('/quotation/:id', {id: props.sales_document.id})
      //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})
      history.push(path)      
    })
  }///

  React.useEffect(() => {
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }, [alertOpen]);

  return (
    <>
      <Collapse in={alertOpen}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Error creating new Job Board entry — <strong>{error}</strong>
        </Alert>  
      </Collapse>
      <Paper className={classes.root}>
        <strong>
          <div>
            <form onSubmit={handleSubmit}>
              <label>Create Job Board Entry</label><br/>
              <TextField 
                onChange={(event) =>{
                  console.log("setCustomerPO->"+ JSON.stringify(event.target.value));
                  setCustomerPO(event.target.value);
                } }
                id="customerPO" 
                label="Customer PO*" 
                variant="standard"
              />   
              <br/>
              <TextField 
                onChange={(event) =>{
                  console.log("setDescription->"+ JSON.stringify(event.target.value));
                  setDescription(event.target.value);
                } }
                id="description" 
                label="Description" 
                variant="standard"
                multiline
                rows={4}
              />
              <br/>  
              <Button variant="outlined" color="primary" type="submit">Create Job Board Entry</Button>
            </form>
          </div>
        </strong>
      </Paper>
    </>
  )
}

export default ZAddJobInputMenuItem;

/*
          <Button color="primary" 
            style={{ marginLeft: 8 }}
            onClick={() => uploadFile(selectedFile)}                     
          >
            {title}
          </Button>

          */