import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllProducts, getProductsForSupplier } from 'queries_urql';
import { subscribeAllProductsForSupplier } from 'subscriptions_urql';

import LinearProgress from '@material-ui/core/LinearProgress';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import ReactTooltip from 'react-tooltip';

////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 400,
    height: 300,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
}

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
}

//////////////
/*
props {

}
*/
//////////////

const ZProductTransferList = (props) => {
  const classes = useStyles();
  
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const [search, setSearch] = React.useState("");
  const [filterlist, setFilterlist] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);


  function onKeyUpHandler(e) {
    setSearch(e.target.value.toLowerCase());
  }

  const handleToggle = (value) => () => {
    console.log('handleToggle')
    console.log(value)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    props.onChange(checked)
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    props.onChange(checked)
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  let supplier_id = props.supplier.id
  console.log('supplier_id')
  console.log(supplier_id)

  const [ result, reexecuteQuery ]  = useQuery({
    query: getProductsForSupplier,
    variables: { supplier_id },
  });
  
  console.log(result);

  const { data, fetching, error } = result;

  /*
  React.useEffect(() => {
    setLeft(data.zeus_product)
  },[setLeft, data.zeus_product]);
  
  React.useEffect(() => {
    const filteredList = data.zeus_product.filter((item) => {
      let all_str = `${item.name}`.toLowerCase();
      //return all_str.indexOf(search) > -1; // View All When Search Empty
      return all_str.indexOf(search) > -1 && search;
    });
    setFilterlist(filteredList);
  }, [data, search]);
  */


  React.useEffect(() => {
    if(fetching){
      return
    }
    let indexes = data.zeus_product.map((item,index)=>{
      return index;
    })
    setLeft(indexes)
    console.log(indexes)
  },[setLeft, data]);


  if (fetching) return (<h2>Loading... <LinearProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) return <div></div>;

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((product) => {
          const labelId = `transfer-list-item-${product.name}-label`;

          return (
            <ListItem key={product.id} role="listitem" button onClick={handleToggle(product)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(product) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText               
              data-tip={`$ ${ product.price } - ${ product.description}}`}
              data-for="product_box_tooltip" 
              id={labelId} primary={`${product.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>

    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>        
        <input
          id="searchFilter"
          type="text"
          className="form-control"
          defaultValue={search}
          placeholder="Filter by Product Num or Description"
          onKeyUp={onKeyUpHandler}
        />
      </Grid>
      <Grid item>{customList(data.zeus_product)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
      <ReactTooltip id="product_box_tooltip"/>
    </Grid>
  );
}


export default ZProductTransferList;