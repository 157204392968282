import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card'; //CardActionArea
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimelineIcon from '@material-ui/icons/Timeline';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List'; 
import ListItem from '@material-ui/core/ListItem'; 
import Button from '@material-ui/core/Button'; //Button
import Menu from '@material-ui/core/Menu'; //Menu
import MenuItem from '@material-ui/core/MenuItem';

import Fab from '@material-ui/core/Fab'; //Fab

import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; 
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';   //ContactPhoneIcon
import FileCopyIcon from '@material-ui/icons/FileCopy';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import ReactTooltip from 'react-tooltip';

//import ZContactRow from 'components/core/ZContactRow';

import Chip from '@material-ui/core/Chip';

import ZAttachmentItem from 'components/core/ZAttachmentItem'; //ZAttachmentItem
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';

///////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    overflowY: 'scroll',
    maxHeight: 400,    
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  avatar: {
    backgroundColor: green[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

//const ZContactsList = React.forwardRef(({style, className, ...props}, ref) => {
const ZAttachmentsList = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [dialog, setDialog] = React.useState({
    open: false,
  });
  const [anchorAttachment, setAnchorAttachment] = React.useState(null); //setActiveContact
  const [activeAttachment, setActiveAttachment] = React.useState({});
  const [anchorHeader, setAnchorHeader] = React.useState(null);

  const handleAttachmentSelect = (event) => {
    //console.log(contact)
    console.log(event)
    setAnchorAttachment(event.currentTarget);
    //
  };

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
    //console.log('handleCardHeaderClick->clicked')
    //setDialog({...dialog, open: true});
  }

  const handleAddFile = () => {
    console.log('handleCardHeaderClick->handleAddFile')
    setDialog({...dialog, open: true});   
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  const handleCloseAttachment = () => {
    setAnchorAttachment(null);
  }

  const handleShowAttachment = () => {
    setAnchorAttachment(null);
  }

  const handleNewQuoteForContact = () => {
    setAnchorAttachment(null);
  }

  const handleLogActivityContact = () => {
    setAnchorAttachment(null);
  }

  // className={classes.root} component="h2"

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleAddFile}>Add File</MenuItem>
      </Menu>
    </div>  
  )

  const attachmentMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorAttachment}
        keepMounted
        open={Boolean(anchorAttachment)}
        onClose={handleCloseAttachment}
      >
        <MenuItem onClick={(event) => { 
          let path = generatePath('/contact/:id', {
            id: activeAttachment}
            )
          history.push(path)
        }}>
          Show
        </MenuItem>
        <MenuItem onClick={handleNewQuoteForContact}>New Quote</MenuItem>       
        <MenuItem onClick={handleLogActivityContact}>Log Activity</MenuItem>
      </Menu>
    </div> 
  )

  let title = (<div></div>);
  if(props.title){
    console.log('creating big title')
    title = (
      <div>
        <Typography variant='overline' color='primary'>{props.title}</Typography>
      </div>      
    )
  }

  let count = props.data ? props.data.length : 0;

  /////////////////////////

  let defaultAction = (
    <Fab
      data-tip="attachment actions"
      data-for="attachment_list_tooltip"            
      //color="primary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>    
  )

  defaultAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>setDialog({...dialog, open: true})}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )


  // path == `tool/${tool.id}/attachments`

  return (
    <div>
      {/* dialog component */}
      <Container maxWidth="lg">
        <Box>
          <Dialog open={dialog.open} onClose={()=>setDialog({...dialog, open: false})} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Attachments</DialogTitle>
            <DialogContent>
              <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <ZFileCloudUploadMenuItem parent_id={props.parent.id} path={props.path} multiple={props.multiple ? props.multiple : false} /> 
              </FormControl> 
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      </Container>
      {/* main component */}
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="client" className={classes.avatar}>
                <FileCopyIcon />
              </Avatar>
            }
            action={defaultAction}
            title={`Attachments: ${count}`}
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>
            <Paper className={classes.root} component="ul">
              <List component="nav" aria-label="attachments">
                {props.data.map((file) => {

                  return (
                    <div key={file.id}>
                      <ListItem                      
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="text"
                        color="primary"
                        button={true}
                        onClick={ (event)=>{
                          setActiveAttachment(file.id)
                          //handleAttachmentSelect(event)
                        }}
                      >
                        <ZAttachmentItem attachment={file} />
                      </ListItem>
                    </div>             
                  );
                })}
              </List>
            </Paper>
          </CardContent>
        </CardActionArea>
        <ReactTooltip id="attachment_list_tooltip"/>
      </Card>
    </div>
  );
};

export default ZAttachmentsList;


/*

<ZContactRow data={contact} />

                <li key={contact.id}>
                  <Chip
                    label={fullName}
                    className={classes.chip}
                  />
                </li>

*/
