import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { uuid } from 'uuidv4';

import { useLocalStorage } from 'hooks/useStorage';

/*
import { useMutation } from '@apollo/client';
import { getAllClients } from '../../queries';
import { addClient, updateClient } from '../../mutations';
*/

import { useQuery, useMutation } from 'urql';
import { getAllClients } from 'queries_urql';
import { addToolNode, updateToolNode } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
//import GoogleMap, { Marker } from 'react-maps-google';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';

import  ZWizard  from '../core/ZWizard';
import moment from 'moment';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

const ZAddToolNodeDialog = (props) => {
  const [state, setState] = React.useState({})
  const [address, setAddress] = React.useState({})
  const [region, setRegion] = React.useState({})
  const [name, setName] = React.useState({})
  const [description, setDescription] = React.useState({})
  const [geometry, setGeometry] = React.useState({})
  const classes = useStyles();

  const [userData, setUserData] = useLocalStorage('userData');

  const [insertFunction] = useMutation(addToolNode);


  const handleReview = () => {
    //console.log("handleReview->address"+ JSON.stringify(address));
    //console.log("handleReview->address.description"+ JSON.stringify(address.value.description));
    const x = {
      name: name,
      description: description,
      //region: region,
      //address: address,
    }
    console.log("handleReview->"+ JSON.stringify(x));
    setState(x) 
  }

  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      setRegion({province: province, country: country})
      setGeometry({ lat, lng })
      setAddress(place) 
    });    
  }

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));
    const guuid = uuid()
    const createdOn = moment().format()

    console.log("id"+ guuid)

    insertFunction({
      variables: { 
        id: guuid,
        name: name, 
        description: description, 
        address: address,
        createdOn: createdOn,
      }})
  }

  return(
    <div>
      <ZWizard 
        title="Create New Client"
        steps={['Enter Client Details','Enter Address']}
        open={props.open} 
        onClose={props.handleClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
      >
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  console.log("setName->"+ JSON.stringify(event.target.value));
                  setName(event.target.value);
                } }
                id="clientName" 
                label="Client Name" 
                variant="standard"
              />
            </Paper>   
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <TextField
                  id="clientDescription"
                  label="Description"
                  multiline
                  rows={4}
                  onChange={(event) =>{
                    console.log("setDescription->"+ JSON.stringify(event.target.value));
                    setDescription(event.target.value);
                  }} 
                />  
            </Paper> 
          </Box>
        </Container>
        <Container maxWidth="sm">
          <Box>
            <div>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyCb8p4Ce3dyBTwgTeCN-79V2YZPBYntWBI"
                onLoadFailed={(error) => (
                  console.error("Could not inject Google script", error)
                )}
                selectProps={{
                  address,
                  onChange: foundAddress,
                }}
              />             
            </div>
          </Box>
        </Container>

        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Typography>Name: {name}</Typography>
              <Typography>Description: {description}</Typography>
              <Typography>Address: {address.label}</Typography>
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddToolNodeDialog;
