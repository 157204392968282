import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';
import { useQuery, useMutation, useSubscription } from 'urql';

import { updateClientField } from 'mutations_urql';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import { Alert, AlertTitle } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ZAssetSurveySeverityPieChart from 'components/charts/ZAssetSurveySeverityPieChart';
import ZRiskCriticalityPlot from 'components/charts/ZRiskCriticalityPlot'; //ZRiskCriticalityPlot
import ZSetAnomalyDetectionButton from 'components/buttons/ZSetAnomalyDetectionButton';
import ZAddAnalysisBloCButton from 'components/buttons/ZAddAnalysisBloCButton';
import ZSetAnalysisBloCButton from 'components/buttons/ZSetAnalysisBloCButton'; //ZSetAnalysisBloCButton

// import { ZGPTConversationDetailPaneWss } from 'components/core/ZGPTDetailPane';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

/////////////////////////////////////

const ZClientBox = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const [state, setState] = React.useState({
    showGPTChat: false,
  })

  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    field: null,
    value: null,
  })

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleMenuClick = (event) => {
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  }

  const [updateResult, updateFunction] = useMutation(updateClientField);

  ///////////


  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);


  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZClientBox->handleEditCommit')
    let client_id = props.client.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: client_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: client_id,
        name: `client ${field} updated`,
        description: `${userData.alias} updated client ${field} from ${props.client[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating client: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`client update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated client', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);


  /////////////////////////////////////////////////
  let overdue = []
  let now = moment()
  const id = props.client.id;

  props.client.assets.forEach((asset)=>{

    if(asset.lastMeasurement === null){
      return;
    }
    
    let freq = moment.duration(asset.survey_frequency, 'seconds');
    let lm = moment(asset.lastMeasurement)
    // first look to see if we have a wireless sensor that is sending data and it's overdue
    let nSurvey = lm.add(freq)
    var duration = moment.duration(nSurvey.diff(now, 'days'))
    if(duration >= 1){
      overdue.push(asset.name +"\n");
      return;
    }

    // now look to see if we are collect data through manual surveys and it's overdue
    if(asset.surveys.length > 0){
      let last_survey = asset.surveys[0];
      var next_slot = moment(last_survey.theDate).add(asset.survey_frequency, 'seconds')
      if(now > next_slot){
        overdue.push(asset.name +"\n");
        return;
      }
    }
  
  })

  let smallChips = (<div></div>)
  let clientNotes = (<div></div>)
  let headerMenu = (<div></div>)
  let surveyStats = (<div></div>)
  let setAnomalyDetectionButton = (<div></div>)

  if( props.fullBox ){
    smallChips = (
      <div>
        <br/><Divider /><br/>
        <Chip label={`Active Assets: ${props.client.assets_aggregate.aggregate.count}`} />
        <Chip color="secondary" 
          label={`Overdue Assets: ${overdue.length}`} 
          data-for="client_box_tooltip"  
          data-tip={overdue.concat()} 
        />
        <Chip label={`Active Routes: ${props.client.active_routes.aggregate.count}`} />
        <Chip color="primary" label={`Active Quotes: ${props.client.pending_quotations_aggregate.aggregate.count}`} />
        <br/><Divider /><br/>
      </div>
    )

    clientNotes = (
      <div>
        <CardActions disableSpacing>       
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show KPIs"
            data-tip="show KPIs"
            data-for="client_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <ZRiskCriticalityPlot client={props.client} />
          </CardContent>
        </Collapse>
      </div>
    )
  } 

  let title = props.name;
  
  if( props.title){
    console.log('creating big title')
    title = (
      <div>
        <Typography  variant='overline' color='primary'>{props.title}</Typography>
        <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
          <Typography variant='h3'>{props.name}</Typography>
        </div>
      </div> 
    )
  }

  /*
  This is where pertinent operationally sensitive information for a client goes
  */
  let analysisBloCs = []
  props.client.analysis_blocs.forEach((ab)=>{
    let bloc = (
      <div>
        <ZSetAnalysisBloCButton id={id} client={props.client} bloc={ab} active={ab.status === "enabled" ? true : false} configurable global/>
      </div>      
    )
    analysisBloCs.push(bloc)
  })


  let setAllAnalysisBloCsButton = (
    <div>
      <ZAddAnalysisBloCButton parent={props.client} bloc_types={props.client.all_analysis_types}/>
    </div>
  )


  /////////////////////////

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>  
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="client" className={classes.avatar}>
              <AccountBoxIcon />
            </Avatar>
          }
          action={
            <div>
              {/* <ZGPTConversationDetailPaneWss isOpen={state.showGPTChat} action={<div/>} collection_id={props.client.id} handleOpen={()=>setState({...state, showGPTChat: true})} handleClose={()=>setState({...state, showGPTChat: false})} static/> */}
              {setAllAnalysisBloCsButton}
              {analysisBloCs}
              {/* setAnomalyDetectionButton */}
              {props.children}
            </div>
          }
          title={
            isEditable.name ?
            <div>
              <Typography  variant='overline' color='primary'>Client</Typography>         
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="name"
                label="Name"
                defaultValue={props.name}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'name', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />
            </div>               
            :
            title
          }
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              Created On:&nbsp;
              <Moment format="YYYY-MM-DD">
              {props.createdOn}
              </Moment>
            </div>
          }
        />
        <CardContent>
          { surveyStats }
          { smallChips }          
          <Typography 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})} 
            variant='h5'
            data-tip="This is where pertinent operationally sensitive information for a client goes like who what software they use and who signs time tickets, etc."
            data-for="client_box_tooltip" 
            >Client Overview</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >
            {
              isEditable.description ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Overview"
                multiline
                defaultValue={props.client.description}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'description', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{props.client.description}</Typography>
            }        
          </Paper>     
          <br/><Divider/><br/>     
          { /* clientNotes */}
          { props.riskCriticalityPlot && <ZRiskCriticalityPlot data={props.client.assets}/> }
        </CardContent>        
      </Card>
      <ReactTooltip id="client_box_tooltip"/>
    </div>
  );
};


export default ZClientBox;
