import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


/* props

steps []
stepContent [</>]

*/

const ZWizard = (props) => {
  const [state, setState] = React.useState({});

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const onSubmit = props.onSubmit;
  const onClose = props.onClose;
  const onReview = props.onReview
  //const steps = getSteps();

  const handleNext = () => {
    if(activeStep === props.children.length - 2){
      if(props.onReview){
        props.onReview()
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep === props.children.length-1){
      var sst = props.onReview()
      setState(sst)
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    if(props.onReset){
      props.onReset();
    }
  };

  const getStepContent = (stepIndex) => {
    return props.children[stepIndex]
  }

  const handleClose = () => {
    console.log("handleClose == "+ JSON.stringify(state))
    onClose(state);
    //setOpen(false)
     //open = false;
  };

  const handleSubmit = () => {
    console.log("handleSubmit == "+ JSON.stringify(state))
    onSubmit(state);
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Dialog 
        onClose={handleClose} 
        open={props.open}
        fullWidth={true}
        maxWidth='lg'
        style={{
          minHeight: '80vh',
          maxHeight: '90vh',
        }}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent style={{height:'800px'}}>
          <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
              {props.steps.map((label,index) => (
                <Step key={label}>
                  <StepLabel onClick={()=>setActiveStep(index)}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div>
            <Box>
              {activeStep === props.children.length -1 ? (
                <div>
                  <Card style={{height:'400px'}}>
                    <CardContent variant="outlined" elevation={2}>
                      {getStepContent(activeStep)}
                    </CardContent>
                  </Card>
                  <div>
                    <Button onClick={handleReset}>Reset</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={props.disabled}>Submit</Button>
                    <Button onClick={handleClose}>
                      Close
                  </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <Card style={{height:'400px'}}>
                    <CardContent variant="outlined" elevation={2}>
                      {getStepContent(activeStep)}
                    </CardContent>
                  </Card>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button color="primary" onClick={handleNext} >
                      {activeStep === props.children.length - 2 ? 'Review' : 'Next'}
                    </Button>
                    <Button color="primary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button color="primary" onClick={handleReset}>
                      Reset
                    </Button>                    
                  </div>
                </div>
              )}
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}


export default ZWizard;
