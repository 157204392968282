import React from 'react';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getSupplier,  getQuotesForClient } from 'queries';
import { addSupplier, updateSupplier } from 'mutations';
*/

import { useQuery, useMutation } from 'urql';
import { getSupplier } from 'queries_urql';
import { addSupplier, updateSupplier } from 'mutations_urql';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZClientBox from 'components/boxes/ZClientBox'; 
import ZSupplierBox from 'components/boxes/ZSupplierBox'; //ZSupplierBox
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZProductLinesList from 'components/lists/ZProductLinesList';//ZProductLinesList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZProductsList from 'components/lists/ZProductsList';// ZProductsList
//import ZProductsPanel from 'components/panels/ZProductsPanel';

import ReactTooltip from 'react-tooltip';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const styles = {
  breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
  containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
  columns: 12,
  gutterWidth: 10,
};

//////////////////////////////////////////////

const ZSupplierView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
  
  let { id } = useParams();
  let row = {};

  const [state, setState] = React.useState({});
  const [showDialog, setShowDialog] = React.useState(false);

  //console.log('supplier_id: '+ id)

  /*
  const { loading, error, data } = useQuery(getSupplier, {
    variables: { id: id },
  });

  if (loading) return (<h2>Loading supplier... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log('supplier data')
  console.log(data)
*/


  const [ result, reexecuteQuery ] = useQuery({
    query: getSupplier,
    variables: { id },
  });

  //const [updateSupplierResult, updateFunction] = useMutation(updateSupplier);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  console.log('are we fetching');
  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);

  console.log('or do we have an error');
  if (error) return `Error! ${error.message}`;

  row = data.zeus_supplier_by_pk;

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <ZSupplierBox id='supplier_box' 
              supplier={row}
              name={row.name} 
              description={row.description} 
              createdOn={row.createdOn}
              annotations={row.annotations}
              fullBox
            />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            {/*
              
              <ZProductsPanel key='products_panel' supplier={row} static/>
            */}
            <ZProductsList key='products_list' supplier={row}  static/>
          </Col>
        </Row>
      </Container>
    </div>
  )


}

export default ZSupplierView;

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/