import React from 'react';
import Webcam from "react-webcam";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

//////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 240,
    width: 160,
  },
  control: {
    padding: theme.spacing(2),
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

//////////////////

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: { exact: "environment" }
};

////////////////

const ZBackCameraCapture = (props) => {
  const classes = useStyles();

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
    console.log('ZBackCameraCapture->capturing image')
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    props.handleChange(imageSrc)
  }, [webcamRef, setImgSrc]);

  return (
    <Grid container className={classes.root} spacing={10}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={10}>
          <Grid key="webcam" item>
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                width={props.width ? props.width : 160} 
                height={props.height ? props.height : 240}
              />
              
            </div>
            <Button variant="outlined" onClick={capture}>Capture photo</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default ZBackCameraCapture;


/*
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        width={480} 
        height={720}
      />
      <Button onClick={capture}>Capture photo</Button>
      {imgSrc && (
        <img
          src={imgSrc}
        />
      )}
    </>
*/