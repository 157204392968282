import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getJobBoardEntry } from 'queries';
import { addJobBoardEntry, updateJobBoardEntry } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { getSensor } from 'queries_urql';
import { addJobBoardEntry, updateJobBoardEntry } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Link from '@material-ui/core/Link';

import Moment from 'react-moment';
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactTooltip from 'react-tooltip';

import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container'; //Container

import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import BallotIcon from '@material-ui/icons/Ballot';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZContactButton from 'components/buttons/ZContactButton';
import ZClientButton from 'components/buttons/ZClientButton';
import ZPurchaseOrderButton from 'components/buttons/ZPurchaseOrderButton';
import ZQuotationButton from 'components/buttons/ZQuotationButton';
import Divider from 'material-ui/Divider';
import ZAttachmentItem from 'components/core/ZAttachmentItem';
import ZToolKitsList from 'components/lists/ZToolKitsList';
import ZToolsList from 'components/lists/ZToolsList';

import ZSensorRMSPlotly from 'components/charts/ZSensorRMSPlotly';

///////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },  
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  avatar_closed: {
    backgroundColor: red[500],
  },
  avatar_pending: {
    backgroundColor: blue[500],
  },
  avatar_accepted: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  isOpen: false
  handleClose: ()=>{}
  quotation: {}
}
*/

const ZSensorDetailPane = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);

  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  console.log('props')
  console.log(props)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /*
  const { loading, error, data } = useQuery(getJobBoardEntry, {
    variables: { id: props.entry.id },
    skip: skip_query,
  });
  const [updateFunction] = useMutation(updateJobBoardEntry);

  console.log('props')
  console.log(props)
  console.log("job board entry data loaded")
  console.log(data);

  if (loading) return (<h2>Loading... <CircularProgress /></h2>);
  //if (error) return `Error! ${error.message}`;
  if (error) return (<div></div>);
  if (skip_query) return (<div></div>)
  */

  /////////////////////////////////

  let id = null
  let row = {}
  let skip_query = true;
  let tools_booked = [];
  let toolnodes_booked = []
  
  if(props.realtime || props.live){
    skip_query = false;
  }

  if(props.sensor !== null){
    id = props.sensor.id
    skip_query = false
  } 

  console.log('ZSensorDetailPane->props');
  console.log(props);

  const [ result, reexecuteQuery ]  = useQuery({
    query: getSensor,
    variables: { id },
    pause: skip_query,
  });
  const [updateFunction] = useMutation(updateJobBoardEntry);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return (<div></div>);
  if (props.sensor === null) return (<div></div>)
  
  /////////////////////////////////

  let avatar_class = classes.avatar
  let status_colour = ""

  if(!skip_query){
    row = data.zeus_sensor_by_pk
  } else {
    row = props.sensor
  } 

  
  let defaultAction = (
    <Fab
      data-tip="sensor actions"
      data-for="sensor_box_tooltip"
      //color="primary"
      aria-label="add"
      size="small"
      onClick={/* 
        handleCardHeaderClick */
        console.log("sensor card header clicked")
      }
    >
      <MoreVertIcon />
      { /* headerMenu */ }
    </Fab>
  )


  ///////////////////////

  return (
    <>
    <SlidingPane
      isOpen={props.isOpen}
      title="Sensor Detail"
      width="800px"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        //setDetailPane(false);
        props.handleClose()
      }}
    >
      <Card variant="outlined">
 
          <CardHeader
            avatar={
              <Avatar aria-label="sensordetail" className={avatar_class}>
                <BallotIcon />
              </Avatar>
            }
            action={props.action ? props.action : defaultAction }
            title={row.name}
            titleTypographyProps={{variant:'h4'}}
          />
          <Paper>
            <CardContent>
              <Container>
                <Chip color={`${status_colour}`} variant="outlined" label={row.status} />
              </Container>
            </CardContent>             
              <br/>
              <Typography variant="h6" component="h4">Details</Typography>
              <Paper elevation={0} variant="outlined" square>
                <Typography color="textSecondary">Created &nbsp;
                  { row.createdOn
                    ?
                    <Moment fromNow>
                      {row.createdOn}
                    </Moment>
                    : 'Never'
                  }
                </Typography>
                <Typography color="textSecondary">Last Modified &nbsp;
                  { row.modifiedOn 
                    ?
                    <Moment fromNow>
                    {row.modifiedOn}
                    </Moment> 
                    : 'Never'
                  }
                </Typography>
                <Typography color="textSecondary">Last Measurement &nbsp;
                  { row.lastMeasurement 
                    ?
                    <Moment fromNow>
                    {row.lastMeasurement}
                    </Moment> 
                    : 'Never'
                  }
                </Typography>                
              </Paper>
              <br/>
              <Typography variant="h6" component="h4">Description</Typography>
              <Paper elevation={0} variant="outlined" square>
                <Typography paragraph color="textSecondary">{row.description}</Typography>
              </Paper>
              <br/><Divider /><br/>
              <ZSensorRMSPlotly sensor={row} limit={10} width={700} realtime/>               
              <br/><Divider /><br/>
              <Typography variant="h6" component="h4">Timeline</Typography>
              { row.activities ?
                <Paper className={classes.scrollablePaper} elevation={0} variant="outlined" square>
                  <ZActivitiesTimeline items={row.activities} size={5} />
                </Paper>   
                : <div/>
              }                          
          </Paper>  

          <CardActions disableSpacing>       
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              //onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show notes"
              data-tip="show notes"
              data-for="sensor_box_tooltip" 
            >
              <ExpandMoreIcon />
            </IconButton>           
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography  variant="h6" component="h4">Notes</Typography>
              { row.annotations.length > 0 ? row.annotations.map((note,index) => {
                console.log('ZSensorDetailPane->expand->note');
                console.log(note);
                return (
                  <div key={`note-${index}`}>
                    <Card>
                      <CardHeader title={note.name} />
                      <CardContent>{note.description}</CardContent>
                    </Card>
                  </div>             
                );
              }) : <div></div> }
              <br/><Divider /><br/>
              <Typography  variant="h6" component="h4">Attachments</Typography>
              { row.attachments.length > 0 ? row.attachments.map((fff,index) => {
                return (
                  <div key={`attachment-${index}`}>
                    <Card>
                      <ZAttachmentItem attachment={fff} />
                    </Card>
                  </div>             
                );
              }) : <div></div> }            
            </CardContent>
          </Collapse>
         
      </Card>
    </SlidingPane> 
    <ReactTooltip id="sensor_box_tooltip"/>
    </>   
  )
}

export default ZSensorDetailPane;
