import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { uuid } from 'uuidv4';
import moment from 'moment';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

/*
import { addAnnotation } from 'mutations';
import { useMutation } from '@apollo/client';
*/

import { useMutation, useQuery } from 'urql';
import { addQuotation } from 'mutations_urql';
import { getNewJobNumber } from 'queries_urql';

import S3 from 'react-aws-s3';
import gql from 'graphql-tag';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    width: '35ch',
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  quote_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  avatar_closed: {
    backgroundColor: red[500],
  },
  avatar_pending: {
    backgroundColor: blue[500],
  },
  avatar_accepted: {
    backgroundColor: green[500],
  },
  quotes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
  props {
    parent
  }
*/

const ZAddQuotationRevisionMenuItem = (props) => {
  const classes = useStyles();
  const history = useHistory();
  //const fileInput = React.useRef();
  const [name , setName] = React.useState("");  //C4999 or CP4999
  const [productJob , setProductJob] = React.useState(false);
  const [serviceJob , setServiceJob] = React.useState(false);
  const [customerPO , setCustomerPO] = React.useState("");
  const [description, setDescription] = React.useState(null);
  const [revision   , setRevision]    = React.useState(null);// setRevision
  const [alertOpen , setAlertOpen]  = React.useState(false);
  const [error , setError]          = React.useState(null);

  console.log('ZAddQuotationRevisionMenuItem - building quote revision')
  console.log(props.sales_document)
  
  let parent  = props.parent;

  const [addQuoteResult, insertQuoteFunction] = useMutation(addQuotation)

  const handleSubmit = (event) => {
    event.preventDefault()
    const guuid = uuid()
    const createdOn = moment().format()

    let entry = {
      id: guuid,
      name: name,
      description: description,
      amount: parent.amount,
      funnel: parent.funnel,
      preparedBy: parent.preparedBy,
      status: parent.status,
      revision: revision,
      createdOn: createdOn,
    }

    // sales_document
    entry.parent_id = parent.id
    entry.client_id = parent.client.id
    entry.companyName = parent.client.name
    console.log("new quote version->handleSubmit")
    console.log(entry)

    // test // 992ce0d1-0585-49d1-b258-d1a1f9590b81
    //let path = generatePath('/quotation/:id', {id: entry.id})
    //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})
    //history.push(path)

    //setError("Test Error")
    //setAlertOpen(true)
    //return;

    insertQuoteFunction({variables: entry}).then((result)=>{
      if (result.error) {
        console.error('ERROR creating revised quote', result.error);
        setError(result.error)
        setAlertOpen(true)
        return
      }
      let path = generatePath('/quotation/:id', {id: entry.id})
      //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})
      history.push(path)      
    })
  } ///

  React.useEffect(()=>{
    console.log("creating name for revision")
    let parent_name = parent.name
    console.log(parent_name)

    if(parent_name.includes(".Rev") && parent.revision === 0){
      let vname = parent_name.split(".")[0]
      let vrev = parent_name.split(".")[1]
      vrev = vrev.at(-1)

      vrev = Number(vrev)
      vrev += 1

      setRevision(vrev)
      console.log('new version: '+ vrev)
      vname += "." + vrev
      setName(vname)
      console.log('new version name: '+ vname)
      return;
    }

    if(parent_name.includes(".") && parent.revision === 0){
      let vname = parent_name.split(".")[0]
      let vrev = parent_name.split(".")[1]

      vrev = Number(vrev)
      vrev += 1

      setRevision(vrev)
      console.log('new version: '+ vrev)
      vname += "." + vrev
      setName(vname)
      console.log('new version name: '+ vname)
      return;
    }

    let version = parent.revision + 1
    let vname = parent_name.split(".")[0]
    vname += "." + version

    setName(vname)
    setRevision(version)
  },[name, setName, setRevision])

  React.useEffect(() => {
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }, [alertOpen]);

  return (
    <>
      <Collapse in={alertOpen}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Error creating new Quote version — <strong>{error}</strong>
        </Alert>  
      </Collapse>
      <Paper className={classes.root}>
        <strong>
          <div>
            <form onSubmit={handleSubmit}>
              <label>New Version: {name}</label><br/>
              <TextField 
                onChange={(event) =>{
                  console.log("setDescription->"+ JSON.stringify(event.target.value));
                  setDescription(event.target.value);
                } }
                id="description" 
                label="Description" 
                variant="standard"
                multiline
                rows={4}
              />
              <br/>  
              <Button variant="outlined" color="primary" type="submit">Create New Version</Button>
            </form>
          </div>
        </strong>
      </Paper>
    </>
  )
}

export default ZAddQuotationRevisionMenuItem;
