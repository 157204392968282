import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode } from 'queries_urql'; ///
import { setToolNodeOutOfService, updateToolNodeField,
        setToolNodeOutForCalibration, addImage,
        setToolNodeRetired, setToolNodeReady, updateUsage } from 'mutations_urql';


import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import Input from '@material-ui/core/Input';

import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import LoopIcon from '@material-ui/icons/Loop';//LoopIcon

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab'; //Fab

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import PlaceIcon from '@material-ui/icons/Place';
import CloseIcon from '@material-ui/icons/Close';

import QRCode from "react-qr-code";
import GoogleMapReact from 'google-map-react';
import Marker from 'google-map-react';
import {isMobile} from 'react-device-detect';
import FileBase64 from 'react-file-base64';

import ZMapComponent from 'components/core/ZMapComponent';
import ZLocatorMapComponent from 'components/core/ZLocatorMapComponent';
import ZCheckInButton from 'components/buttons/ZCheckInButton';
import ZCheckOutButton from 'components/buttons/ZCheckOutButton';
import ZSetOutofServiceButton from 'components/buttons/ZSetOutofServiceButton';
import ZSetReadyButton from 'components/buttons/ZSetReadyButton';
import ZSetRetiredButton from 'components/buttons/ZSetRetiredButton';
import ZBackCameraCapture from 'components/core/ZBackCameraCapture';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZJobNumberAutoComplete from 'components/core/ZJobNumberAutoComplete';
import ZFrontCameraCapture from 'components/core/ZFrontCameraCapture';
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';

import { ZNFCScanner, ZNFCWriter} from 'components/core/ZNFCScanner';
import ZPulsate from 'components/core/ZPulsate';
//import ZNFCWriter from 'components/core/ZNFCWriter';
import ZFluentCalendar from 'components/core/ZFluentCalendar';

/////////////////////////////////////////

const useStyles = makeStyles((theme)=>({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary.light,
  },
  pos: {
    marginBottom: 12,
  },
  button_new: {
    margin: theme.spacing(5),
  }, 
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  notes: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    height: '200px',
  },
  image_root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',   
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },  
}));


/////////////////////////////////////

const ZToolNodeBox = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const fileInput = React.useRef();
  const base64file = React.useRef();

  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [job, setJob] = React.useState(null);
  const [openBookNodeDialog, setOpenBookNodeDialog] = React.useState(false);
  const [openAnnotationDialog, setOpenAnnotationDialog] = React.useState(false);// setOpenAnnotationDialog

  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    field: null,
    value: null,
  })

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [usageUpdated, setUsageUpdated] = React.useState(false);

  const [imageData, setImageData] = React.useState({
    file: null,
    base64URL: ""
  });

  const snackbarEl = React.useRef(null);

  console.log(`ZToolNodeBox props.usage_id -> ${props.usage_id}`)

  ////////////////////////

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  /////////////////////

  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZToolNodeBox->handleEditCommit')
    let node_id = props.node.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: node_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: node_id,
        name: `${props.node.node_type} ${field} updated`,
        description: `${userData.alias} updated ${props.node.node_type} ${field} from ${props.node[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating toolkit: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`toolkit update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated toolkit', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);

  //////////////////

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMenuClick = (event) => {
    console.log('ZToolNodeBox->handleMenuClick')
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  }

  const calcRestrictedDates = (elements) =>{
    let restrictedDates = []
    console.log('calcRestrictedDates')
    //console.log(elements)
    elements.forEach((elem)=>{
      //console.log(elem)
      let d = moment(elem.theDate).local()
      d = d.add(1, 'day').toDate()
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  /////////

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  /////////

  console.log('props')
  console.log(props)

  let node = {}
  let usage_description = null;

  let jobs = (props.jobs !== undefined) ? props.jobs : []; 

  let usage_id = null;

  const [updateResult, updateUsageFunction] = useMutation(updateUsage);
  const [insertResult, insertImageFunction] = useMutation(addImage);
  const [updateNodeResult, updateFunction] = useMutation(updateToolNodeField);

  const shouldPause = props.static !== undefined;
  const id = props.node.id;
  const [result, reexecuteQuery] = useQuery({
    query: getToolNode,
    variables: { id },
    pause: shouldPause,
  });

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;

  //let snackbar = (<div></div>)
  snackbarEl.current = (
    <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'center' }}
      autoHideDuration={6000}
      open={alertState.open}
      message={alertState.message}
      key="alert_bar"
      onClose={()=>{
        console.log('trying to open snackbar')
        //setOpenAlert(false)
      }}
      action={
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={()=>{
              console.log('trying to close snackbar')
              //setOpenAlert(false)
              setAlertState({
                open: false,
                message: ''
              })
            }}
          >
            Close
          </Button>
        </div>
      }
    />     
  )

  const handleFileInputChange = React.useCallback((e)=>{
    console.log('handleFileInputChange')
    console.log(e.target.files[0]);

    let  file  = imageData.file;

    file = e.target.files[0];

    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        setImageData({
          base64URL: result,
          file
        });
      })
      .catch(err => {
        console.log(err);
      });
  },[]);


  const captureImage = React.useCallback((imgSrc)=>{
    console.log('captureImage')
    const guuid = uuidv4()
    const createdOn = moment().format()

    let variables = {
      id: guuid,
      user_id: userData.id,
      createdOn: createdOn,
      object: {
        id: guuid,
        img: imgSrc,
        user_id: userData.id,
        parent_id: id,
        createdOn: createdOn,
      }
    }

    console.log('captureImage->variables')
    console.log(variables)

    insertImageFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: insertImageFunction: ${result.error}`)
        setAlertState({
          open: true,
          message: `Error uploading image ${result.error}`
        })
      
      } else {
        console.log(result)
        setAlertState({
          open: true,
          message: `successfully uploaded image`
        });
      }
    })
  },[]);


  const handleOpenAnnotationDialog = () => {
    setOpenAnnotationDialog(true);
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static === undefined){
    node = data.zeus_tool_node_by_pk
  } else {
    node = props.node
  }

  /////////////////////////////////

  let smallChips = (<div></div>)
  let nodeNotes = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)

  let addSetOutofServiceButton = (<div></div>)
  let addSetReadyButton = (<div></div>)
  let addSetRetiredButton = (<div></div>)
  let addBookToolNodeButton = (<div></div>)

  const ZToolLocationComponent = ({ text }) => <div>{text}</div>;

  /////////////////////////////////

  if(props.actions || props.showActions){
    addSetOutofServiceButton = (
      <div>
        <ZSetOutofServiceButton id={id} lat={props.lat} lng={props.lng} toolnode/>      
      </div>
    )
  }

  if(props.actions || props.showActions){
    addSetReadyButton = (
      <div>
        <ZSetReadyButton id={id} lat={props.lat} lng={props.lng} toolnode/>      
      </div>
    )
  }

  if(props.actions || props.showActions){
    addSetRetiredButton = (
      <div>
        <ZSetRetiredButton id={id} lat={props.lat} lng={props.lng} toolnode/>   
      </div>
    )
  }

  if(props.actions || props.showActions){
    addBookToolNodeButton = (
      <div>
        <Button size="large" color="primary"
          id={props.id}
          style={{ marginLeft: 8 }}
          onClick={(event) => { 
            setOpenBookNodeDialog(true)
          }}                     
        >
          {`Reserve ${node.name}`}
        </Button>          
      </div>
    )
  }

  ///////////////////////////////////

  if(props.fullBox){
    smallChips = (
      <div>
        <Chip label={`Total Uses: ${node.usage_count ? node.usage_count.aggregate.count : 'N/A'}`} />
      </div>
    )

    nodeNotes = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="toolnode_box_tooltip" 
            aria-label="add note"
            onClick={handleOpenAnnotationDialog}
          >
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="toolkit_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Notes</Typography>
            {node.annotations ? node.annotations.map((note) => {
              return (
                <div key={note.id}>
                  <Card>
                    <CardHeader>{note.name}</CardHeader>
                    <CardContent>{note.description}</CardContent>
                  </Card>
                </div>             
              );
            }) : <div></div>}
          </CardContent>
        </Collapse>
      </div>
    )
  }

  let title = node.name;
  title = (
    <div>
      <Typography  variant='h4' color='primary'>{node.node_type}</Typography>
      <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
        <Typography variant='h3'>{node.name}</Typography>
      </div>
    </div>     
  )

  if(props.nfc && node.node_type === 'ToolLocker'){
    title = (
      <div>
        <ZPulsate visible={props.nfc} width={180}>
          <Typography  variant='h4' color='primary'>{node.node_type}</Typography>
        </ZPulsate>
        <Typography variant='h3'>{node.name}</Typography>
      </div>       
    )
  }

  /*
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
  */

  // ready is default
  let urlValue = `${baseURL}/toolnode/${node.id}/checkout`;
  if(node.status === 'in-use'){
    urlValue = `${baseURL}/toolnode/${node.id}/checkin/${node.usage[0].id}`;
  }

  if(node.status === 'out-of-service'){
    urlValue = `${baseURL}/toolnode/${node.id}/ready`;
  }

  if(node.status === 'out-for-calibration'){
    urlValue = `${baseURL}/toolnode/${node.id}/ready`;
  }

  console.log(`QRcode url: ${urlValue}`)
  console.log(`props.usage_id: ${props.usage_id}`)
  if(node.usage){
    if(node.usage.length > 0){
      console.log(`node.usage[0].id: ${node.usage[0].id}`)
      usage_description = node.usage[0].description
    }
  }
  

  //////////////

  if((props.qrcode || props.showQRcode) || node.status === 'ready'){
    qrcode = (
      <div>
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
          <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={urlValue}
          viewBox={`0 0 512 512`}
          />
        </div>
        <br/><Divider /><br/>
      </div>
    )
  }


  if(props.showmap || props.showMap){
    let xlat = props.lat;
    let xlng = props.lng;
    if(node.usage){
      if(node.usage.length > 0 && props.checkin === undefined){
        xlat = node.usage[0].lat
        xlng = node.usage[0].lng
        console.log(`last usage xlat: ${xlat} xlng: ${xlng}`)
      }
    }

    if((node.lat !== null && node.lng !== null)  && props.checkin === undefined){
      xlat = node.lat
      xlng = node.lng
      console.log(`last usage xlat: ${xlat} xlng: ${xlng}`)      
    }

    gmap = (
      <div>
        <ZMapComponent name="tool_node_map" lat={xlat} lng={xlng} zoom={12}/>
        <br/><Divider /><br/>
      </div>
    )
  }

  /////////////////////////  status

  let current_user = (<div></div>)
  let status_colour = "primary"
  let node_status = node.status ? node.status.toUpperCase() : 'N/A';
  
  if(node.status === 'in-use'){
    status_colour = "secondary"
    if(node.usage.length > 0){
      current_user = (
        <Typography>Current User: {node.usage ? node.usage[0].user.alias : 'N/A'}</Typography>
      )
    }
  }

  if(node.node_type === 'ToolLocker'){
    status_colour = node.status === 'pending' ? 'secondary' : 'primary';
    node_status = node.status.toUpperCase();
  } else {
    if(node.tools_inuse_aggregate){
      if(node.tools_inuse_aggregate.aggregate.count > 0){
        console.log('toolkit incomplete, some tools in use')
        status_colour = "default"
        node_status = "INCOMPLETE"
      }
    }
  }

  const handleDescriptionChange = (value) => {
    setDescription(value)
  }

  const handleJobChange = (value) => {
    setJob(value)
  }

  const handleSubmitUsageInfo = () => {
    //setDescription(value)
    let job_id = null;
    if(job !== null){
      job_id = job.id;
    }

    let variables = {
      id: props.usage_id,
      _set: {
        description: description,
        job_id: job_id,
      }
    }

    console.log('subitting usage info')
    console.log(variables)
    //return

    updateUsageFunction(variables).then((result)=>{
      if(result.error){
        console.log('ERROR updating tool usage')
        setAlertState({
          open: true,
          message: `Error updating ${node.node_type} usage ${result.error}`
        });
      }else {
        console.log(`successfully updated ${node.node_type} usage`)
        console.log(result)
        setAlertState({
          open: true,
          message: `successfully updated ${node.node_type} usage`
        });
      }
    })
  }

  const handleNFCScanCheckOut = (scannerData) => {
      
    console.log('ZToolNodeBox::handleNFCScanCheckOut');
    console.log(scannerData)
    let serialNumber = scannerData.serialNumber;
    let item_id = scannerData.id;
    let tag_type = scannerData.type === 0 ? 'toolnode' : 'tool' ;
    let tag_name = scannerData.name;

    let the_description = `NFC tag ${serialNumber} was used to checkout ${tag_name}`;
    console.log(the_description)

    let urlValue = `/nfc/${tag_type}/:id/checkout`;
    let path = generatePath(urlValue, {
      id: item_id}
    )
    history.push(path)
  }

  const handleNFCWrite = () => {
    let url = `${baseURL}/nfc/toolnode/${node.id}`;
    if(node.node_type === 'ToolLocker'){
      url += '/doopen'
    }
    
    let data = {
      id: node.id,
      name: node.name,
      type: 0,
      url: url,
    }

    console.log('handleNFCWrite');
    console.log(data)
    return data;
  }

  const captureImage_1 = (imgSrc) => {
    console.log('captureImage')
    const guuid = uuidv4()
    const createdOn = moment().format()

    let variables = {
      id: guuid,
      user_id: userData.id,
      createdOn: createdOn,
      object: {
        id: guuid,
        img: imgSrc,
        user_id: userData.id,
        parent_id: id,
        createdOn: createdOn,
      }
    }

    console.log('captureImage->variables')
    console.log(variables)

    insertImageFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: insertImageFunction: ${result.error}`)   
        setAlertState({
          open: true,
          message: `Error update ${node.node_type} usage ${result.error}`
        });   
      } else {
        console.log(result)
        setAlertState({
          open: true,
          message: `successfully updated ${node.node_type} usage`
        });
      }
    })
  }


  let the_button = (<div></div>)
  let usage_info = (<div></div>)

  if(node.node_type !== 'ToolLocker'){
    the_button = (
      <div>
        <ZCheckOutButton key='node_checkout' id={node.id} data={node} lat={props.lat} lng={props.lng} toolnode/>
      </div>
    )
  
    if(props.checkin){
      console.log(`checking in toolnode to using live location lat:${props.lat}  lng:${props.lng}`)
      the_button = <ZCheckInButton key='node_checkin' id={node.id} usage_id={props.usage_id} data={node} lat={props.lat} lng={props.lng} description={description} toolnode/> 
      usage_info = (
        <div>
          <TextField
            id="usage_description"
            label="Description & Condition"
            variant="outlined"
            rows={4}
            fullWidth
            multiline
            onChange={(event) => {
              console.log(`handleDescriptionChange -> ${event.target.value}`)
              handleDescriptionChange(event.target.value)
            }} 
          />  
        </div>
      )
    }


    if(node.status === 'in-use' && props.checkin === undefined){
      //the_button = (<div></div>)
      the_button = <ZCheckInButton key='node_checkin' id={node.id} data={node} lat={props.lat} lng={props.lng} toolnode/> 

      if(props.status){
        usage_info = (
          <div>
            <ZJobNumberAutoComplete isOpen={true} data={jobs}
              onChange={(event)=>{
                handleJobChange(event.target.value)
              }}
            />
            <TextField
              id="usage_description"
              label="Description"
              variant="outlined"
              rows={4}
              onChange={(event) => {
                console.log(`handleDescriptionChange -> ${event.target.value}`)
                handleDescriptionChange(event.target.value)
              }} 
            />  
            <br/>
            <Button color="primary" variant="contained"
              onClick={(event)=>{
                console.log('submit job number and description')
                handleSubmitUsageInfo();
              }}
            >
              Submit
            </Button>
          </div>
        )
      }
    }

    if(node_status === "INCOMPLETE"){
      the_button = (
        <div>
          <ZCheckOutButton key='node_checkout' id={node.id} data={node} lat={props.lat} lng={props.lng} toolnode disabled/> 
        </div>
      )
    }    
  }

  /////////////////////////////////////

  let lastCheckedOut = (<div>Last Checked-Out: never</div>)
  let lastCheckedIn  = (<div>Last Checked-In: never</div>)

  if(node.lastCheckedOut !== null){
    lastCheckedOut = (
      <div>
        Last Checked-Out:&nbsp;
        <Moment format="YYYY-MM-DD hh:mm">
        {node.lastCheckedOut}
        </Moment>
      </div>
    )
  }

  if(node.lastCheckedIn !== null){
    lastCheckedIn = (
      <div>
        Last Checked-In:&nbsp;
        <Moment format="YYYY-MM-DD hh:mm">
        {node.lastCheckedIn}
        </Moment>
      </div>
    )
  }

  let node_images = (<div></div>)

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange_1 = (e) => {
    console.log(e.target.files[0]);
    let  file  = imageData.file;

    file = e.target.files[0];

    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        setImageData({
          base64URL: result,
          file
        });
      })
      .catch(err => {
        console.log(err);
      });
  };


  if(props.status || props.checkin){
    console.log('tool or toolnode status')
    if(isMobile){
      node_images = (<div>
        <ZBackCameraCapture width={320} height={480} handleChange={captureImage} />
      </div>)
    } else {
      node_images = (<div>
        <ZFrontCameraCapture width={320} height={480} handleChange={captureImage} />
      </div>)
    }
  } else {
    node_images = (
      <div className={classes.image_root}>
        {/* <Input type="file" inputRef={fileInput}/><br/> */ }
        <input type="file" name="file" onChange={handleFileInputChange} />
        <Button variant="outlined" color="primary"
          onClick={()=>{
            console.log('image upload button clicked')
            console.log('uploading selected image file')
            console.log(imageData);
            captureImage(imageData.base64URL);
          }}
        >
          Upload Image
        </Button>
        <ImageList className={classes.imageList} cols={3.0}>
          {node.images ? node.images.map((item,index) => (
            <ImageListItem key={`node_image_${index}`}>
              <img src={`data:image/jpeg;base64,${item.img}`} alt={`node_image_${index}`} />
              <ImageListItemBar 
                title={item.createdOn}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
              />
            </ImageListItem>
          )) : <div></div>}
        </ImageList>
        <br/><Divider /><br/>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <ZAddAnnotationDialog open={openAnnotationDialog} parent_id={node.id} handleClose={()=>setOpenAnnotationDialog(false)} />
      <div ref={snackbarEl} />
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="node" className={classes.avatar}>
              <BusinessCenterIcon />
            </Avatar>
          }
          action={
            <Paper>
              <Chip
                style={{ marginLeft: 8 }}
                icon={<LoopIcon />}
                label={ node_status }
                color={status_colour}
                onClick={props.refresh ? props.refresh : null}
                clickable
              />              
              <br/>
              <div>
                {/* <ZNFCScanner title='Scan Check-Out' doWrite={handleNFCWrite} small asButton/> */}
                { !props.doopen && <ZNFCWriter title='Scan Check-Out' doWrite={handleNFCWrite} small asButton/> }
                {addSetReadyButton}
                {addSetOutofServiceButton}
                {addSetRetiredButton}   
                {props.actions}    
              </div>
              <ZFluentCalendar restrictedDates={calcRestrictedDates(node.bookings)}/>
            </Paper>
          }         
          title={
            isEditable.name ?
            <div>
              <Typography  variant='overline' color='primary'>{node.node_type}</Typography>         
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="name"
                label="Name"
                defaultValue={node.name}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'name', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />
            </div>              
            :  
            title
          }
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {node.createdOn}
                </Moment>
              </div>
              { lastCheckedOut }
              { lastCheckedIn }
              { current_user }
              <div><Typography>{node.description}</Typography></div> 
            </div>
          }
        />
        <CardActions className={classes.button}>
          {/* console.log(`usageUpdated==${usageUpdated}`) */}
          {/* usage_description === null ? usage_info : (<div></div>) */}
          {props.action ? props.action : (<div></div>) /* the_button */}
        </CardActions>
        <CardContent className={classes.button}>
          {/* props.checkin ? usage_info : usage_description === null ? usage_info : (<div></div>) */}       
          {qrcode}
          <br/>
          {gmap}
          <br/>
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >          
          {
            isEditable.description ?
            <TextField
              className={classes.descriptionField}
              fullWidth
              id="details"
              label="Description"
              multiline
              defaultValue={node.description}
              rows={4}
              onChange={(event) =>{
                console.log(event.target.value)
                setIsEditable({...isEditable, field: 'description', value: event.target.value});
                //console.log("state->"+ JSON.stringify(state));
              }} 
              variant="filled"
              InputProps={{
                endAdornment: (
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      console.log('edit commit')
                      handleEditCommit(isEditable.field, isEditable.value)
                      //setIsEditable({...isEditable, description: false, field: null, value: null})
                    }}                    
                  >
                    <CloudUploadIcon />
                  </IconButton>
                )
              }}              
            />  
            :       
            <Typography>{node.description}</Typography>
          }
          </Paper>
          {/* 
          <br/><Divider/><br/>
          <ZFileCloudUploadMenuItem parent_id={node.id} path={`toolnode/${node.id}/attachments`} />
          */}
        </CardContent>
        { /* nodeNotes  */ }
      </Card>
      <ReactTooltip id="toolnode_box_tooltip"/>
    </div>
  );
};


export default ZToolNodeBox;

 /*
  let lat = 0;
  let lng = 0;
  navigator.geolocation.getCurrentPosition((position)=>{
    lat = position.coords.latitude
    lng = position.coords.longitude
    console.log(`ZToolNodeBox [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
  })
  */

  /*
  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZSetReadyButton [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })
  }, [setLat,setLng]);
  */

  /*
  React.useEffect(()=>{
    navigator.geolocation.getCurrentPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolNodeBox [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })

    let posTimer = setTimeout(() => {
      navigator.geolocation.getCurrentPosition((position)=>{
        setLat(position.coords.latitude)
        setLng(position.coords.longitude)
        console.log(`ZToolNodeBox update [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
      })
    }, 5000);

    return () => {
      console.log("stopping ZToolNodeBox -> position timer")
      clearTimeout(posTimer);

    };
  });
  */

/*
            <Fab
              data-tip="status"
              data-for="toolnode_box_tooltip"
              color={status_colour}
              aria-label="status"
              size="large"
              variant="extended"
              onClick={handleMenuClick}
            >
              <MoreVertIcon/>
              {headerMenu}
              { node.status.toUpperCase() }
              </Fab>

*/
