import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getTool,  getQuotesForClient } from 'queries_urql';
import { addClient, updateAssetSurvey, updateJobBoardEntryStatus, addNotification, addJobPurchaseOrder } from 'mutations_urql';
import { subscribeAssetSurvey } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import TimelineIcon from '@material-ui/icons/Timeline';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';//OpenIcon
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ZWizard from 'components/core/ZWizard';
import ZAssetSurveyBox from 'components/boxes/ZAssetSurveyBox';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZContactsList from 'components/lists/ZContactsList';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZScheduleList from 'components/lists/ZScheduleList';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';// ZAttachmentsList
import ZInvoicesList from 'components/lists/ZInvoicesList';//ZInvoicesList
import ZToolKitsList from 'components/lists/ZToolKitsList';// ZToolKitsList
import ZToolsList from 'components/lists/ZToolsList';// ZToolsList
import ZPurchaseOrdersList from 'components/lists/ZPurchaseOrdersList';//ZPurchaseOrdersList
import ZJobBoardEntryBox from 'components/boxes/ZJobBoardEntryBox';// ZJobBoardEntryBox
import ZAddJobPurchaseOrderDialog from 'components/dialogs/ZAddJobPurchaseOrderDialog';

import JobBoardBloC from 'blocs/JobBoardBloC';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import { DatePicker } from  'react-responsive-calendar-picker'
import  'react-responsive-calendar-picker/dist/index.css';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';


import ReactTooltip from 'react-tooltip';
import ZAddAnnotationDialog from './dialogs/ZAddAnnotationDialog';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZBreadCrumbs from 'components/core/ZBreadCrumbs';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  image_avatar: {
    backgroundColor: green[500],
  },
}));

//////////////////////////////////////////////

const ZAssetSurveyView = (props) => {

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
  
  const classes = useStyles();
  let { id } = useParams();

  const [state, setState] = React.useState({
    open: false,
    message: '',
    action: null,
  });
  const [userData, setUserData] = useLocalStorage('userData');

  const [tabIndex, setTabIndex] = React.useState(0);

  const [showDialog, setShowDialog] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    severity: 'info',
    open: false,
    message: '',
  });

  const [showAnnotationDialog, setShowAnnotationDialog] = React.useState(false);

  let row = {}

  console.log(`ZAssetSurveyView->id->${id}`);

  const handleCloseAnnotation = () => {
    setShowAnnotationDialog(false);
  }

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleSetDates = () => {

  }
   
  
  const handleChangeStatusActive = () => {
    console.log('ZAssetSurveyView->handleChangeStatusActive')
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: id,
        name: "job active",
        description: `${userData.alias} updated asset survey entry set status active ${modifiedOn}`,
      },
      _set: {
      status: 'active',
      modifiedOn: modifiedOn,
    }}

    console.log(variables)

    updateJobStatusFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          severity: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          severity: 'success',
          open: true,
          message: 'Successfully updated asset survey status',
        })
      }
      setState({...state, open: false})
    })       
  }


  const calcRestrictedDates = (elements) =>{
    let restrictedDates = []
    console.log('callout')
    //console.log(row)
    elements.forEach((elem)=>{
      let d = moment(elem.theDate).local()
      d = d.add(1, 'day').toDate()
      restrictedDates.push(d);
    })          
    return restrictedDates;    
  }

  const handlePublishSurvey = () => {

    insertNotificationFunction({object: {
      name: `Asset ${row.asset.name} survey posted`,
      description: `${userData.alias} created a new survey for asset ${row.asset.name} condition ${row.entry.severity}`,
      role: 'admin',
    }}).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR publishing survey: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`published survey`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully published survey', severity: 'success'})
      }
    })
  }

  const [updateJobResult, updateJobFunction] = useMutation(updateAssetSurvey);
  const [updateJobStatusResult, updateJobStatusFunction] = useMutation(updateJobBoardEntryStatus);//updateJobBoardEntryStatus
  const [insertNotificationResult,   insertNotificationFunction] = useMutation(addNotification);

  const handleSubscription = (previousData, response) => {
    console.log('ZAssetSurveyView->handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_jobboard_entry_by_pk
    return response;
    //return row;
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZAssetSurveyView setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeAssetSurvey,
    variables: { id },
  }, handleSubscription); 
  
  const { data, fetching, error } = subResult;

  console.log('ZAssetSurveyView->subResult')
  console.log(subResult);

  ////////////////////

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  
  console.log('ZAssetSurveyView->data');
  console.log(data);

  row = data.zeus_asset_survey_by_pk;

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setShowAnnotationDialog(true)
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let toprow = (
    <Row>
      <Col xs={6}>      
        <ZAssetSurveyBox id='entry_box' 
          entry={row}
          name={row.name} 
          description={row.description} 
          annotations={row.annotations}
          //statusUpdate={handlePublishSurvey}
          static
        />
      </Col>
      <Col xs={6}>
        { row.activities ?
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="asset_survey_entry">
                  <TimelineIcon />
                </Avatar>
              }
              title='Timeline'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZActivitiesTimeline items={row.activities} />
            </Paper>  
          </Card> 
          : <div/>
        }  
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} static/>
        <Row><br/></Row>
        <Card variant="outlined">
          <CardHeader
            avatar={
              <Avatar aria-label="asset_survey_images" className={classes.image_avatar}>
                <PhotoLibraryIcon />
              </Avatar>
            }
            title='Images'
            titleTypographyProps={{variant:'h5'}}
          />
          <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
            <ZImageListHorizontal key='image_list' parent_id={row.id} path={`asset_survey/${row.id}/images`} data={row.images} />
          </Paper>  
        </Card> 
        <ZAddAnnotationDialog open={showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />           
      </Col>
    </Row>    
  )

  /////////////////////

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={3000}
        open={alertState.open}
        key="survey_state"
        onClose={()=>setAlertState({...alertState, open: false})}
      >
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>      
      </Snackbar>
      <Container fluid>
        {toprow}
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={220} descriptionW={350} action={addNoteAction} parent={row} items={row.annotations} static/>
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>        
        </Row>                
      </Container>
    </div>
  )


}

export default ZAssetSurveyView;
