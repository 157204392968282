//ec2-3-99-50-93.ca-central-1.compute.amazonaws.com
export const Config = {
  development: {
    ZEUS_HASURA_WS_URL: "ws://3.98.104.34:8080/v1/graphql",
    ZEUS_HASURA_URL: "http://3.98.104.34:8080/v1/graphql",
    ZEUS_MSAL_CLIENTID1: "e3db0384-2bc7-4486-9454-ce29faa115e1",
    ZEUS_MSAL_CLIENTID: "32b45f6b-fe61-4925-8f59-7d265d02859e",
    ZEUS_MSAL_TENANTID: "dbdb9742-5722-44bf-8fab-23592bf5565e",
    ZEUS_MSAL_AUTHORITY: "https://login.microsoftonline.com/dbdb9742-5722-44bf-8fab-23592bf5565e",
    ZEUS_MSAL_REDIRECT_URL: "http://localhost:3000",
    ZEUS_REMOTEDB_URL: "https://couchdb.primac.app:5984",
  },
  staging: {
    ZEUS_HASURA_WS_URL: "wss://devhasura.primac.app/v1/graphql",
    ZEUS_HASURA_URL: "https://devhasura.primac.app/v1/graphql",
    ZEUS_MSAL_CLIENTID: "e3db0384-2bc7-4486-9454-ce29faa115e1",
    ZEUS_MSAL_TENANTID: "dbdb9742-5722-44bf-8fab-23592bf5565e",
    ZEUS_MSAL_AUTHORITY: "https://login.microsoftonline.com/dbdb9742-5722-44bf-8fab-23592bf5565e",
    ZEUS_MSAL_REDIRECT_URL: "https://dev-zeus.primac.app",
    ZEUS_REMOTEDB_URL: "https://couchdb.primac.app:5984",
  },
  production:{
    ZEUS_HASURA_WS_URL: "wss://hasura.primac.app/v1/graphql",
    ZEUS_HASURA_URL: "https://hasura.primac.app/v1/graphql",
    ZEUS_MSAL_CLIENTID: "32b45f6b-fe61-4925-8f59-7d265d02859e",
    ZEUS_MSAL_TENANTID: "dbdb9742-5722-44bf-8fab-23592bf5565e",
    ZEUS_MSAL_AUTHORITY: "https://login.microsoftonline.com/dbdb9742-5722-44bf-8fab-23592bf5565e",
    ZEUS_MSAL_REDIRECT_URL: "https://zeus.primac.app",
    ZEUS_REMOTEDB_URL: "https://couchdb.primac.app:5984",
  }
}
