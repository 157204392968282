import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useLocalStorage } from 'hooks/useStorage';
import { useQuery, useMutation, useSubscription } from 'urql';

import { updateClientNodeField } from 'mutations_urql';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeWorkIcon from '@material-ui/icons/HomeWork'; 
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import { Alert, AlertTitle } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

import ReactTooltip from 'react-tooltip';

import Moment from 'react-moment';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ZAssetSurveySeverityPieChart from 'components/charts/ZAssetSurveySeverityPieChart';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

/////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: red[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

/////////////////////////////////////

const ZClientNodeBox = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');

  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const [isEditable, setIsEditable] = React.useState({
    name: false,
    description: false,
    field: null,
    value: null,
  })

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleMenuClick = (event) => {
    console.log(event)
    setAnchorMenu(event.currentTarget);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  }

  const [updateResult, updateFunction] = useMutation(updateClientNodeField);

    ///////////


  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZClientNodeBox->handleEditCommit')
    let client_id = props.client.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: client_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: client_id,
        name: `client node ${field} updated`,
        description: `${userData.alias} updated client node ${field} from ${props.client_node[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating client node: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`client node update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated client node', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);


  /////////

  let smallChips = (<div></div>)
  let images = (<div></div>)
  let headerMenu = (<div></div>)
  let surveyStats = (<div></div>)

  if( props.fullBox !== undefined){
    smallChips = (
      <div>
        <br/><Divider /><br/>
        <Chip label={'Active Assets:'+ props.client_node.assets_aggregate.aggregate.count} />
        <br/><Divider /><br/>
      </div>
    )

    images = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="client_node_box_tooltip" 
            aria-label="add note">
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="client_node_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
          { props.showImages && <ZImageListHorizontal parent_id={props.client_node.id} path={`client_node/${props.client_node.id}/images`} data={props.client_node.images} /> } 
          </CardContent>
        </Collapse>
      </div>
    )
  } 

  let title = props.name;
  
  if( props.title){
    console.log('creating big title')
    title = (
      <div>
        <Typography  variant='overline' color='primary'>{props.title}</Typography>
        <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
          <Typography variant='h3'>{props.name}</Typography>
        </div>
      </div> 
    )
  }

  let client = (<div>Client: N/A</div>)

  if(props.client){
    client = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client/:id', {
            id: props.client.id}
          )
          history.push(path)
        }}          
      >
        Client: {props.client.name}
      </Button> 
    )   
  }  
  
  let parent = (<div></div>)

  if(props.parent){
    parent = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client_node/:id', {
            id: props.parent.id}
          )
          history.push(path)
        }}          
      >
        Parent: {props.parent.name}
      </Button> 
    )   
  }    

  /////////////////////////

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>  
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="client_node" className={classes.avatar}>
              <HomeWorkIcon />
            </Avatar>
          }
          action={
            props.children
          }
          title={
            isEditable.name ?
            <div>
              <Typography  variant='overline' color='primary'>Client Node</Typography>         
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="name"
                label="Name"
                defaultValue={props.name}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'name', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />
            </div>               
            :
            title
          }
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {props.createdOn}
                </Moment>
              </div>
              {client}
              {parent}
            </div>
          }
        />
        <CardContent>
          { surveyStats }
          { smallChips }          
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >
            {
              isEditable.description ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Description"
                multiline
                defaultValue={props.client_node.description}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'description', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{props.client_node.description}</Typography>
            }        
          </Paper>              
          <br/><Divider/><br/>
        </CardContent>
        { images }
      </Card>
      <ReactTooltip id="client_node_box_tooltip"/>
    </div>
  );
};


export default ZClientNodeBox;
