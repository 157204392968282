import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from 'clsx';

import { red, blue, green, cyan, grey, yellow, orange } from '@material-ui/core/colors';

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getJobBoardEntry } from 'queries_urql'; //subscribeToolBox
import { addAttachment, updateAssetField } from 'mutations_urql';
import { subscribeAsset } from 'subscriptions_urql';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card'; //CardHeader
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; //IconButton
import IconButton from '@material-ui/core/IconButton'; //MoreVertIcon
import MoreVertIcon from '@material-ui/icons/MoreVert'; //FavoriteIcon
import FavoriteIcon from '@material-ui/icons/Favorite'; //ShareIcon
import ShareIcon from '@material-ui/icons/Share'; //ExpandMoreIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox'; //AccountBoxIcon
import BuildIcon from '@material-ui/icons/Build';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; //Fab
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactTooltip from 'react-tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Moment from 'react-moment';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QRCode from "react-qr-code";

import ZMapComponent from 'components/core/ZMapComponent';
import ZSetJobScheduledButton from 'components/buttons/ZSetJobScheduledButton';
import ZSetJobClosedButton from 'components/buttons/ZSetJobClosedButton'; 
import ZSetJobClientPOReceivedButton from 'components/buttons/ZSetJobClientPOReceivedButton'; 
import ZSetJobInvoicedButton from 'components/buttons/ZSetJobInvoicedButton'; 
import ZAssignTechToJobButton from 'components/buttons/ZAssignTechToJobButton';//ZAssignTechToJobButton
import ZSetJobPrimacPOButton from 'components/buttons/ZSetJobPrimacPOButton';  // SetJobPrimacPOButton
import ZSetTravelAndAccomToJobButton from 'components/buttons/ZSetTravelAndAccomToJobButton';  //addSetTravelAndAccomodationsArrangedForJobButton
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';// ZFileCloudUploadMenuItem
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';// ZActivitiesTimeline
import ZChip from 'components/core/ZChip';

import AssetBloC from 'blocs/AssetBloC';

import { uploadFileToAzure } from 'azure/AzureBlobAPI';
import ZAssetSurveyTimeChart from 'components/charts/ZAssetSurveyTimeChart';

/////////////////////////////////////////

const useStyles = makeStyles((theme)=>({
  root: {
    minWidth: 400,
    '& .super-app.noaccess': {
      backgroundColor: grey[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.nostatus': {
      backgroundColor: grey[200], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unacceptable': {
      backgroundColor: red[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unsatisfactory': {
      backgroundColor: orange[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.reportable': {
      backgroundColor: yellow[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },        
    '& .super-app.acceptable': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.good': {
      backgroundColor: green[200], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },    
  },
  avatar: {
    backgroundColor: blue[500],
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  editField: {
    position: "absolute",
    top: 0,
    right: 0
  },  
  paper: {
    padding: '6px 16px',
  },  
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  condition: {
    '& .super-app.noaccess': {
      backgroundColor: grey[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.nostatus': {
      backgroundColor: grey[200], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unacceptable': {
      backgroundColor: red[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.unsatisfactory': {
      backgroundColor: orange[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.reportable': {
      backgroundColor: yellow[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },        
    '& .super-app.acceptable': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.good': {
      backgroundColor: green[200], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },  
  },
}));

/////////////////////////////////////

const ZAssetBox = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [state, setState] = React.useState({});
  const [userData, setUserData] = useLocalStorage('userData');
  const [isEditable, setIsEditable] = React.useState({
    description: false,
    criticality: false,
    survey_frequency: false,
    name: false,
    field: null,
    value: null,
  })
  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [updateResult, updateFunction] = useMutation(updateAssetField);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDateChange = (value) => {
    console.log('handleDateChange')
    console.log(value)
    setIsEditable({...isEditable, field: 'completedOn', value: value})
  }

  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setIsEditable({...isEditable, description: false, name: false, criticality: false, survey_frequency: false, field: null, value: null})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleEditCommit = React.useCallback((field,value) => {
    console.log('ZAssetBox->handleEditCommit')
    let job_id = props.asset.id;
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: job_id,
      user_id: user_id,
      _set: _set,
      activity: {
        user_id: user_id,
        parent_id: job_id,
        name: `asset ${field} updated`,
        description: `${userData.alias} updated asset ${field} from ${props.asset[field]} to ${value}`,          
      },
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR updating job: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`asset update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated asset', severity: 'success'})
      }

      setIsEditable({...isEditable, description: false, field: null, value: null})
    })

  },[]);

  let protocol = window.location.href.split("/")[0]
  let baseURL = `${protocol}//${window.location.href.split("/")[2]}`

  /////////

  let asset = {}
  let hasValidCustomerPO = false;
  let isInvoiced = false;

  const queryPause = props.static ? true : false;
  const subscriptionPause = props.realtime ? false : true;
  const id = props.asset.id;

  console.log(`ZAssetBox->queryPause: ${queryPause}`)
  console.log(`ZAssetBox->subscriptionPause: ${subscriptionPause}`)

  const handleSubscription = (previousData, response) => {
    console.log('ZAssetBox::handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //entry = data.zeus_jobboard_entry_by_pk
    return response
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZJobBoardEntryBox setting up subscription')

  const [result, executeSubscription] = useSubscription({ 
    query: subscribeAsset,
    variables: { id },
    pause: subscriptionPause,
  }, handleSubscription); 

  console.log('ZAssetBox result');
  console.log(result)

  const { data, fetching, error } = result;

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  if(props.static === undefined){
    asset = data.zeus_asset_by_pk
  } else {
    asset = props.asset
  }

  const bloc = new AssetBloC(asset);

  let criticality_str = 'N/A';
  /*
    <MenuItem value={1}>Insignificant</MenuItem>
    <MenuItem value={2}>Minor</MenuItem>
    <MenuItem value={3}>Moderate</MenuItem>
    <MenuItem value={4}>Major</MenuItem> 
    <MenuItem value={5}>Extreme</MenuItem> 
  */

  if(asset.criticality === 1){
    criticality_str = 'Insignificant';
  }
  if(asset.criticality === 2){
    criticality_str = 'Minor';
  }
  if(asset.criticality === 3){
    criticality_str = 'Moderate';
  }
  if(asset.criticality === 4){
    criticality_str = 'Major';
  }
  if(asset.criticality === 5){
    criticality_str = 'Extreme';
  }

  /////////

  let smallChips = (<div></div>)
  let activities = (<div></div>)
  let qrcode = (<div></div>)
  let gmap = (<div></div>)

  let addSetJobClosedButton = (<div></div>)
  let addSetJobScheduledButton = (<div></div>)
  let addSetJobPrimacPOButton = (<div></div>)
  let addSetJobClientPOReceivedButton = (<div></div>)
  let addSetJobInvoicedButton = (<div></div>)
  let addTechToJobButton = (<div></div>)
  let addSetTravelAndAccomodationsArrangedForJobButton = (<div></div>)

  ///////////////////////////////////

  if(props.fullBox){

    activities = (
      <div>
        <CardActions disableSpacing>       
          <IconButton 
            data-tip="add note"
            data-for="asset_box_tooltip" 
            aria-label="add note">
            <ShareIcon />
          </IconButton>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show notes"
            data-tip="show notes"
            data-for="asset_box_tooltip" 
          >
            <ExpandMoreIcon />
          </IconButton>           
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="h6" component="h4">Timeline</Typography>
              { asset.activities ?
                <Paper elevation={0} variant="outlined" square>
                  <ZActivitiesTimeline items={asset.activities} size={3} />
                </Paper>   
                : <div/>
              }              
          </CardContent>
        </Collapse>
      </div>
    )
  }

  let title = (<div>{
    isEditable.name ?
      <div>
        <Typography  variant='overline' color='primary'>Asset</Typography>     
        <TextField
          className={classes.descriptionField}
          fullWidth
          id="name"
          label="Name"
          defaultValue={props.name}
          onChange={(event) =>{
            console.log(event.target.value)
            setIsEditable({...isEditable, field: 'name', value: event.target.value});
            //console.log("state->"+ JSON.stringify(state));
          }} 
          variant="filled"
          InputProps={{
            endAdornment: (
              <IconButton
                variant="contained"
                color="primary"
                onClick={()=>{
                  console.log('edit commit')
                  handleEditCommit(isEditable.field, isEditable.value)
                  //setIsEditable({...isEditable, description: false, field: null, value: null})
                }}                    
              >
                <CloudUploadIcon />
              </IconButton>
            )
          }}              
        /> 
      </div>
      :
      <div>
        <Typography  variant='overline' color='primary'>Asset</Typography>  
        <div onClick={()=>setIsEditable({...isEditable, name: true, field: 'name'})}>
          <Typography color='primary' variant='h2'>{asset.name}</Typography>
        </div> 
      </div>                
    }</div>)

  /*
  status = "pending"
  status = "active"
  status = "completed"

  */

  // ready is the default
  let urlValue = `${baseURL}/asset/${asset.id}`;

  if(props.noqrcode === undefined){
    qrcode = (
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
        <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={urlValue}
          viewBox={`0 0 512 512`}
        />
      </div>
    )
  }
  
  let assigned_user = (<div></div>)

  let status_colour = "default"

  if(asset.currentCondition !== null){
    if(asset.currentCondition === 'Good' || asset.currentCondition === 'Acceptable'){
      status_colour = "primary"
    }

    if(asset.currentCondition === 'Reportable' || asset.currentCondition === 'Unsatisfactory' || asset.currentCondition === 'Unacceptable'){
      status_colour = "secondary"
    }
  }

  if(asset.status === 'active'){
    if(asset.technician){
      assigned_user = (
        <Typography>Assigned Tech: {asset.technician ? asset.technician.alias : 'N/A'}</Typography>
      )
    }
  }

    const handleCriticalityChange = (event,item) => {
    console.log("handleCriticalityChange")
    console.log(item.props.value)
    //let address = item.
    setState({...state, criticality: item.props.value})
  }

  /////////////////////////

  let criticality = (<div>Criticality: N/A</div>)
  let surveyFreq = (<div>Survey Frequency: N/A</div>)
  let client = (<div>Client: N/A</div>)
  let client_node = (<div>Parent Node: N/A</div>)
  let currentCondition = (<div>Current Condition: N/A</div>)
  let assetKPI = (<div></div>)
  let nextSurvey = (<div>Next Survey: N/A</div>)
  let nextMeasurement = (<div></div>)

  let condition_color = 'primary'
  if(asset.currentCondition === 'Reportable' || asset.currentCondition === 'Unsatisfactory' || asset.currentCondition === 'Unacceptable'){
    condition_color = 'error'
  }

  if(asset.currentCondition){
    currentCondition = (<div>{
      isEditable.currentCondition ?
        <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
          <FormControl className={classes.formControl}>
          <InputLabel id="freq-select-label">Survey Frequency</InputLabel>
            <Select
              labelId="freq-select-label"
              id="freq-simple-select"
              value={isEditable.value ? isEditable.value : asset.currentCondition}
              onChange={(event)=>setIsEditable({...isEditable, field: 'currentCondition', value: event.target.value})}
            >
              <MenuItem value={'Good'}>Good</MenuItem>
              <MenuItem value={'Acceptable'}>Acceptable</MenuItem>
              <MenuItem value={'Reportable'}>Reportable</MenuItem> 
              <MenuItem value={'Unsatisfactory'}>Unsatisfactory</MenuItem> 
              <MenuItem value={'Unacceptable'}>Unacceptable</MenuItem> 
              <MenuItem value={'No Status'}>No Status</MenuItem> 
              <MenuItem value={'No Access'}>No Access</MenuItem> 
            </Select>
          </FormControl>
          <IconButton
            variant="contained"
            color="primary"
            onClick={()=>{
              console.log('edit commit')
              handleEditCommit(isEditable.field, isEditable.value)
              //setIsEditable({...isEditable, description: false, field: null, value: null})
            }}                    
          >
            <CloudUploadIcon />
          </IconButton>
        </Paper>   
        :
        <div onClick={()=>setIsEditable({...isEditable, currentCondition: true, field: 'currentCondition', value: asset.currentCondition})}>
          <Typography color='primary' variant='h5' color={condition_color}>{`Current Condition: ${asset.currentCondition}`}</Typography>
        </div>                 
      }</div>)   
  }

  if(asset.survey_frequency){
    surveyFreq = (<div>{
      isEditable.survey_frequency ?
        <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
          <FormControl className={classes.formControl}>
          <InputLabel id="freq-select-label">Survey Frequency</InputLabel>
            <Select
              labelId="freq-select-label"
              id="freq-simple-select"
              value={isEditable.value ? isEditable.value : asset.survey_frequency}
              onChange={(event)=>setIsEditable({...isEditable, field: 'survey_frequency', value: event.target.value})}
            >
              <MenuItem value={10*60}>10 Minutes</MenuItem>
              <MenuItem value={30*60}>30 Minutes</MenuItem>
              <MenuItem value={3600}>1 Hour</MenuItem>
              <MenuItem value={3*3600}>3 Hours</MenuItem>
              <MenuItem value={12*3600}>12 Hours</MenuItem>
              <MenuItem value={24*3600} >Daily</MenuItem>
              <MenuItem value={7*24*3600} >Weekly</MenuItem>
              <MenuItem value={14*24*3600}>Bi Weekly</MenuItem> 
              <MenuItem value={60*24*3600}>Bi Monthly</MenuItem> 
              <MenuItem value={90*24*3600}>Quarterly</MenuItem> 
            </Select>
          </FormControl>
          <IconButton
            variant="contained"
            color="primary"
            onClick={()=>{
              console.log('edit commit')
              handleEditCommit(isEditable.field, isEditable.value)
              //setIsEditable({...isEditable, description: false, field: null, value: null})
            }}                    
          >
            <CloudUploadIcon />
          </IconButton>
        </Paper>   
        :
        <div onClick={()=>setIsEditable({...isEditable, survey_frequency: true, field: 'survey_frequency', value: asset.survey_frequency})}>
          <Typography color='primary' variant='h5'>{`Survey Frequency: ${asset.survey_frequency} days`}</Typography>
        </div>                 
      }</div>)
  }  

  if(asset.sensors.length > 0){
    let snsr = asset.sensors[0];
    if(snsr.last_measurement){
      let lm = moment(snsr.last_measurement);
      let now = moment();
      var duration = moment.duration(now.diff(lm))
      nextMeasurement = (
        <div>
          <Chip
            style={{ marginLeft: 8 }}
            label={
              <div>
                Next Measurement:&nbsp;
                <Moment fromNow>
                  {duration}
                </Moment>
              </div>   
            }
          />            
        </div>       
      )
    }
  }


  if(asset.surveys){
    let size = asset.surveys.length;
    var surv_freq = asset.survey_frequency;
    var now = moment();

    if(size > 0){
      var ns_color = "default"
      let last_survey = asset.surveys[0];

      var next_slot = moment(last_survey.theDate).add(surv_freq, 'seconds')

      var labelStr = `Last Survey: ${moment(last_survey.theDate).fromNow()}`

      // if we are overdue the set chip color to red
      if(now > next_slot){
        ns_color = "secondary"
        var over_due_str = now.diff(next_slot, 'days')

        //var over_due_str = next_slot.fromNow(true);
        labelStr += ` - ${over_due_str} days overdue!`

        status_colour = "black"
      }

      nextSurvey = (
        <Chip
          style={{ marginLeft: 8 }}
          color={ns_color}
          label={labelStr}
        />
      )
    } else {
      nextSurvey = (
        <Chip
          style={{ marginLeft: 8 }}
          label="N/A"
        />
      )
    }
  }

  if(asset.criticality){
    criticality = (<div>{
      isEditable.criticality ?
        <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
          <FormControl className={classes.formControl}>
          <InputLabel id="criticality-select-label">Asset Criticality</InputLabel>
            <Select
              labelId="criticality-select-label"
              id="criticality-simple-select"
              value={isEditable.value ? isEditable.value : asset.criticality }
              onChange={(event)=>setIsEditable({...isEditable, field: 'criticality', value: event.target.value})}
            >
              <MenuItem value={1}>Insignificant</MenuItem>
              <MenuItem value={2}>Minor</MenuItem>
              <MenuItem value={3}>Moderate</MenuItem>
              <MenuItem value={4}>Major</MenuItem> 
              <MenuItem value={5}>Extreme</MenuItem> 
            </Select>
          </FormControl>
          <IconButton
            variant="contained"
            color="primary"
            onClick={()=>{
              console.log('edit commit')
              handleEditCommit(isEditable.field, isEditable.value)
              //setIsEditable({...isEditable, description: false, field: null, value: null})
            }}                    
          >
            <CloudUploadIcon />
          </IconButton>          
        </Paper>  
        :
        <div onClick={()=>setIsEditable({...isEditable, criticality: true, field: 'criticality', value: asset.criticality})}>
          <Typography color='primary' variant='h5'>{`Criticality: ${asset.criticality} (${criticality_str})`}</Typography>
        </div>                 
      }</div>)
  }  

  if(asset.client){
    client = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client/:id', {
            id: asset.client.id}
          )
          history.push(path)
        }}          
      >
        Client: {asset.client.name}
      </Button> 
    )   
  } 

  //client_node
  if(asset.client_node){
    client_node = ( 
      <Button 
        component="span"
        //variant="contained"
        color="primary"
        size="small"
        //style={{ marginLeft: 8 }}
        onClick={(event) => { 
          let path = generatePath('/client_node/:id', {
            id: asset.client_node.id}
          )
          history.push(path)
        }}          
      >
        Parent Node: {asset.client_node.name}
      </Button> 
    )   
  } 

  assetKPI = (
    <div>
      <CardActions disableSpacing>       
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show KPIs"
          data-tip="show KPIs"
          data-for="client_box_tooltip" 
        >
          <ExpandMoreIcon />
        </IconButton>           
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ZAssetSurveyTimeChart items={props.asset.surveys} />
        </CardContent>
      </Collapse>
    </div>
  )

  ////////////////////////////////////////

  return (
    <div className={classes.root}>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>    
      <Card variant="outlined" className={clsx('super-app', {
        good: asset.currentCondition === 'Good',
        acceptable: asset.currentCondition === 'Acceptable',
        reportable: asset.currentCondition === 'Reportable',
        unsatisfactory: asset.currentCondition === 'Unsatisfactory',
        unacceptable: asset.currentCondition === 'Unacceptable',
        nostatus: asset.currentCondition === 'No Status',
        noaccess: asset.currentCondition === 'No Access',
      })}>
        <CardHeader
          avatar={
            <Avatar aria-label="asset" className={classes.avatar}>
              <DepartureBoardIcon />
            </Avatar>
          }
          action={
            <div>
              <ZChip
                style={{ marginLeft: 8 }}
                icon={<TrackChangesIcon />}
                label={ asset.currentCondition ? asset.currentCondition.toUpperCase() : 'N/A' }
                color={status_colour}
                //clickable
                //onClick={props.statusUpdate ? props.statusUpdate : null}
                data-for="asset_box_tooltip"
                data-tip="asset status"
              />              
              <br/><br/>
              <Paper>
                <div>
                  { qrcode }
                </div>
              </Paper>
            </div>
          }
          title={title}
          titleTypographyProps={{variant:'h3'}}
          subheader={
            <div>
              {assigned_user}
              {currentCondition}
              {criticality}
              {surveyFreq}
              {nextSurvey}
              {nextMeasurement}
              {client}
              <br/>
              {client_node}              
              <div>
                Created On:&nbsp;
                <Moment format="YYYY-MM-DD">
                {asset.createdOn}
                </Moment>
              </div>              
            </div>
          }
        />
        <CardContent>       
           <br/><Divider/><br/>
          {gmap}         
          { smallChips }
          <Typography variant='h5'>Description</Typography>
          <Paper  className={classes.paper}
            elevation={2} 
            onClick={()=>setIsEditable({...isEditable, description: true, field: 'description'})}
            square
          >
            {
              isEditable.description ?
              <TextField
                className={classes.descriptionField}
                fullWidth
                id="details"
                label="Description"
                multiline
                defaultValue={asset.description}
                rows={4}
                onChange={(event) =>{
                  console.log(event.target.value)
                  setIsEditable({...isEditable, field: 'description', value: event.target.value});
                  //console.log("state->"+ JSON.stringify(state));
                }} 
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        console.log('edit commit')
                        handleEditCommit(isEditable.field, isEditable.value)
                        //setIsEditable({...isEditable, description: false, field: null, value: null})
                      }}                    
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  )
                }}              
              />  
              :
              <Typography>{asset.description}</Typography>
            }        
          </Paper>              
          {/* assetKPI */}
        </CardContent>
        { activities }
      </Card>
      <ReactTooltip id="asset_box_tooltip"/>
    </div>
  );
};


export default ZAssetBox;

