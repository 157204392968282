import React from 'react';
import { TextField, Calendar, Slider, Dropdown, mergeStyleSets, defaultCalendarStrings } from '@fluentui/react';

///////////////////////////

const styles = mergeStyleSets({
  wrapper: { height: 360 },
  dropdown: { width: 230 },
});

const dayOptions = [
  { key: '1', text: '1' },
  { key: '2', text: '2' },
  { key: '3', text: '3' },
  { key: '4', text: '4' },
  { key: '5', text: '5' },
  { key: '6', text: '6' },
];

///////////////////////////
/*
props {
  restrictedDates: <Date>[]
  onSelectDate: ()=>{}
}

*/
///////////////////////////

const ZFluentCalendar = (props) => {

  const [selectedDateRange, setSelectedDateRange] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [daysToSelectInDayView, setDaysToSelectInDayView] = React.useState(1);

  const onSelectDate = React.useCallback((date, dateRangeArray) => {
    console.log()
    setSelectedDate(date);
    setSelectedDateRange(dateRangeArray);
  }, []);

  const onDaysToSelectInDayViewDropdownChange = React.useCallback((ev, option) => {
    console.log('onDaysToSelectInDayViewDropdownChange')
    console.log(ev.target.value)

    if (option) {
      setDaysToSelectInDayView(Number(option.key));
    }
  }, []);

  let dateRangeString = 'Not set';
  if (selectedDateRange) {
    const rangeStart = selectedDateRange[0];
    const rangeEnd = selectedDateRange[selectedDateRange.length - 1];
    dateRangeString = rangeStart.toLocaleDateString() + '-' + rangeEnd.toLocaleDateString();
  }

  return (
    <div className={styles.wrapper}>
      {props.details && (
        <div>
          <div>Selected date: {selectedDate?.toLocaleString() || 'Not set'}</div>
          <div >Selected range: {dateRangeString}</div>
        </div>
      )}
      <br/>
      {props.slider  && (
        <Slider
          label="Choose # of days to book"
          defaultValue={1}
          min={1}
          max={props.maxDays ? props.maxDays : 30}
          showValue
          onChange={(value) =>{
            setDaysToSelectInDayView(Number(value));
            console.log('days to book: '+ value)
          }}  
        />
      )}
      <Calendar
        showSixWeeksByDefault
        showMonthPickerAsOverlay={props.showMonthPickerAsOverlay !== undefined ? props.showMonthPickerAsOverlay : true}
        highlightSelectedMonth={props.highlightSelectedMonth !== undefined ? props.highlightSelectedMonth : true}
        showGoToToday={props.showGoToToday !== undefined ? props.showGoToToday : true}
        restrictedDates={props.restrictedDates !== undefined ? props.restrictedDates : []}
        onSelectDate={(date,dateRangeArray)=>{
          setSelectedDate(date);
          //console.log('onSelectDate')
          //console.log(props.restrictedDates)
          //console.log(dateRangeArray)
          //let found = props.restrictedDates.some(r=> dateRangeArray.indexOf(r) >= 0)
          //console.log('found')
          //console.log(found)
          //if(found){
          //  console.log('WARNING: date range overlap')
          //  console.log(found)
         // }
          setSelectedDateRange(dateRangeArray);
          props.onSelectDate(date,dateRangeArray);
        }}
        value={selectedDate}
        calendarDayProps={{ daysToSelectInDayView }}
        // Calendar uses English strings by default. For localized apps, you must override this prop.
        strings={defaultCalendarStrings}
      />
    </div>
  );
};


export default ZFluentCalendar;


/*

      <Dropdown
        className={styles.dropdown}
        selectedKey={String(daysToSelectInDayView)}
        label="Choose # days to select"
        options={dayOptions}
        onChange={onDaysToSelectInDayViewDropdownChange}
      />

*/