import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

import { useLocalStorage } from 'hooks/useStorage';
import usePersistentState from 'hooks/usePersistentState';
import { uploadFileToAzure } from 'azure/AzureBlobAPI';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

/*
import { useMutation, useQuery } from '@apollo/client';
import { getAllQuotations, getAllClients, getClient, getContact } from '../../queries';
import { addQuotation, updateQuotation} from '../../mutations';
*/

import { useMutation, useQuery } from 'urql';
import { getAllQuotations, getAllClients, getClient, getContact } from 'queries_urql';
import { addJobBoardEntryDialog, addJobBoardEntryChildren, updatePrimacPO, updateJobNum, 
  addNotification, addAttachment, addAttachments, addJobBoardEntry, addQuotation, updateQuotation} from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container'; //FormControlLabel
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import  ZWizard  from 'components/core/ZWizard';
//import  ZAutoCompleteInput  from 'components/core/ZAutoCompleteInput';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ZClientAutocomplete  from 'components/core/ZClientAutocomplete';  //
import ZContactAutocomplete from 'components/core/ZContactAutocomplete';  //ZContactAutocomplete
import ZSupplierAutocomplete from 'components/core/ZSupplierAutocomplete'; 
import ZUserAutocomplete from 'components/core/ZUserAutocomplete';  //ZUserAutocomplete
import ZProductTransferList from 'components/core/ZProductTransferList';  //ZProductTransferList
import ZFluentCalendar from 'components/core/ZFluentCalendar';

import ZPipelineInput from 'components/core/ZPipelineInput'; // ZPipelineInput
import ZQuotationNumberInput from 'components/core/ZQuotationNumberInput'; //  ZQuotationNumberInput
import ZFileCloudUploadMenuItem from 'components/buttons/ZFileCloudUploadMenuItem';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 300,
  },  
  tableRow: {
    "&$hover:hover": {
      backgroundColor: "red"
    }
  },
}));

//////////////////////////////////////////

const ZAddJobBoardEntryDialog = (props) => {
  const classes = useStyles()
  const [userData, setUserData] = useLocalStorage('userData');

  const [alert, setAlert] = React.useState({
    show: false,
    message: '',
    error: false,
  })

  /*
  const [state, setState] = React.useState({
    guuid: uuidv4(),
    prefix: 'C',
    client: null,
    companyName: null,
    user: null,

    customerPODate: new Date(),
    poAmount: null,
    customerPO: null,

    invoiceDate: new Date(),
    invoiceAmount: null,
    invoiceNum: null,
  })
  */

  const [state, setState] = usePersistentState({
    guuid: uuidv4(),
    prefix: 'C',
    serviceType: null,
    isProduct: 0,
    isService: 1,
    client: null,
    contact: null,
    companyName: null,
    user: null,
    tech: null,
    primacQuoteName: null,
    quoteAmount: null,

    customerPODate: null, //new Date()
    poAmount: null,
    customerPO: null,

    invoiceDate: null,  //new Date()
    invoiceAmount: null,
    invoiceNum: null,
  }, 'create-new-job-wizard')

  const [addJobResult, insertJobFunction] = useMutation(addJobBoardEntryDialog);
  const [addChildrenResult, insertJobChildrenFunction] = useMutation(addJobBoardEntryChildren);
  const [createPOResult, createPrimacPOFunction] = useMutation(updatePrimacPO); 
  const [createJobNumResult, createJobNumFunction] = useMutation(updateJobNum); 
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);
  const [insertResult, addAttachmentFunction] = useMutation(addAttachment);//addAttachmentsFunction
  const [attachmentsResult, addAttachmentsFunction] = useMutation(addAttachments);

  const handleReview = () => {
    const createdOn = moment().format()
    let fullName = userData.firstName +" "+ userData.lastName;

    setState({
      ...state, 
      id: state.guuid, 
      createdOn: createdOn, 
      preparedBy: fullName, 
      client_id: state.client.id, 
      contact_id: state.contact.id, 
      user_id: userData.id,
      status: "pending"
    })

    console.log("handleReview->"+ JSON.stringify(state));
  }

  const handleReset = () => {
    setState({
      guuid: uuidv4(),
      prefix: 'C',
      primacPO: false,
      serviceType: null,
      isService: 1,
      isProduct: 0,
      client: null,
      contact: null,
      companyName: null,
      user: null,
      tech: null,
      primacQuoteName: null,
      quoteAmount: null,
  
      customerPODate: new Date(),
      poAmount: null,
      customerPO: null,
  
      invoiceDate: new Date(),
      invoiceAmount: null,
      invoiceNum: null,
    })
    props.handleClose();
  }

  
  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      //setRegion({province: province, country: country})
      //setGeometry({ lat, lng })
      //setAddress(place) 
    });    
  }


  const handleSubmit = () => {
    console.log("ZAddJobBoardEntryDialog::handleSubmit->"+ JSON.stringify(state));
  
    const activities = [];
    const documents = [];

    // create job
    const { guuid, client, contact, user, tech, serviceType, poDescription, invDescription, customerPODate, customerPOEndDate, invoiceDate, primacPO, primacQuoteFile, poFile, poFileName, ...object } = state;
    object.id = state.guuid;
    object.serviceType = state.serviceType;
    object.quoteAmount = state.quoteAmount;
    object.createdBy = userData.alias;
    object.user_id = userData.id;

    if(state.prefix === 'CR' || state.prefix === 'CI'){
      object.serviceType = null;
    }

    console.log(object);

    let job_activity = {
      user_id: userData.id,
      parent_id: state.guuid,
      name: "job created",
      description: `${userData.alias} created job ${state.name}`,           
    }

    // create customer PO
    let poID = null;
    if(state.customerPO !== null){
      poID = uuidv4();
      documents.push({
        id: poID,
        document_type: "PurchaseOrder",
        job_id: state.guuid,
        //parent_id: state.guuid,
        client_id: state.client.id,
        name: state.customerPO,
        amount: state.poAmount,
        description: state.poDescription,
        theDate: moment(state.customerPODate).format(),
        closedOn: moment(state.customerPOEndDate).format(),
        status: "pending",
      })

      activities.push({
        user_id: userData.id,
        parent_id: poID,
        name: "customer PO created",
        description: `${userData.alias} created customer PO ${state.customerPO}`,           
      })
    }

    // create primac invoice
    if(state.invoiceNum !== null){
      let invID = uuidv4();
      documents.push({
        id: invID,
        document_type: "Invoice",
        job_id: state.guuid,
        parent_id: poID,
        client_id: state.client.id,      
        name: state.invoiceNum,
        amount: state.invoiceAmount,
        description: state.invDescription,
        theDate: moment(state.invoiceDate).format(),
        status: "pending",
      })
    
      activities.push({
        user_id: userData.id,
        parent_id: invID,
        name: "invoice created",
        description: `${userData.alias} created primac invoice ${state.invoiceNum}`,           
      })    
    }


    if(state.jobType === 'CR' || state.jobType === 'CI'){
      console.log('this job is an RMA or Internal product/service purchase');
      console.log('do we need to create a job number??');
    }

    if(state.primacPO){
      console.log('creating new job with new primac PO')
      createPrimacPOFunction().then((result)=>{
        if(result.error){
          console.log(`ERROR creating new primacPO: ${result.error}`)
          setAlert({...alert, show: true, message: `ERROR creating new primacPO: ${result.error}`, error: true})
        } else {
          console.log(result)
          let newPrimacPO = result.data.update_zeus_document_counter.returning[0].sequence;
          object.primacPO = newPrimacPO;

          createJobNumFunction().then((result)=>{
            if(result.error){
              console.log(`ERROR creating new Job number: ${result.error}`)
              setAlert({...alert, show: true, message: `ERROR creating new Job number: ${result.error}`, error: true})
            } else {
              console.log(result)
              let newJobNum = result.data.update_zeus_document_counter.returning[0].sequence;
              object.name = `${state.prefix}${newJobNum}`;
              object.jobCounter = newJobNum;

              insertJobFunction({
                user_id: userData.id,
                id: state.guuid,
                object: object,
                activity: job_activity,
              }).then((result)=>{
                if(result.error){
                  console.log(`ERROR creating job board entry: ${result.error}`)
                  setAlert({...alert, show: true, message: `ERROR creating job board entry: ${result.error}`, error: true})
                } else {
                  console.log(`created job board entry [ ${object} ]`)
                  console.log(result)
          
                  insertNotificationFunction({object: {
                    name: `Job ${result.data.insert_zeus_jobboard_entry_one.name} created`,
                    description: `${userData.alias} created job ${result.data.insert_zeus_jobboard_entry_one.name} description ${object.description}`,
                    role: 'admin',
                  }});   
                       
                  insertJobChildrenFunction({
                    user_id: userData.id,
                    id: state.guuid,
                    documents: documents,
                    activities: activities,          
                  }).then((result)=>{
                    if(result.error){
                      console.log(`ERROR creating job board entry children: ${result.error}`)
                      setAlert({...alert, show: true, message: `ERROR creating job board entry children: ${result.error}`, error: true})
                    } else {
                    
                      if(state.poFile !== null){
                        uploadFileToAzure(`job/${guuid}/attachments`, [state.poFile]).then((data) => {
                          console.log('upload->res');
                          console.log(data);

                          data = data[0];
                          let xuuid = uuidv4();
                          let attachment = {}; 
                          attachment.id = xuuid;
                          attachment.name = data.fileName;
                          attachment.url = data.url;
                          attachment.bucket = data.bucket;
                          attachment.path = data.path;
                          attachment.parent_id = guuid;          
                    
                          console.log('attachment')
                          console.log(attachment)
                    
                          addAttachmentFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
                            if (result.error) {
                              console.error('ERROR creating attachment', result.error);
                              setAlert({
                                ...alert,
                                message: result.error,
                                show: true,
                                error: true,
                              })
                            }
                            //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                          })
                        }).catch(err => console.error(err)) 
                      }

                      if(state.primacQuoteFile !== null){
                        uploadFileToAzure(`job/${guuid}/attachments`, [state.primacQuoteFile]).then((data) => {
                          console.log('upload->res');
                          console.log(data);

                          data = data[0];
                          let xuuid = uuidv4();
                          let attachment = {}; 
                          attachment.id = xuuid;
                          attachment.name = data.fileName;
                          attachment.url = data.url;
                          attachment.bucket = data.bucket;
                          attachment.path = data.path;
                          attachment.parent_id = guuid;          
                    
                          console.log('attachment')
                          console.log(attachment)
                    
                          addAttachmentFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
                            if (result.error) {
                              console.error('ERROR creating attachment', result.error);
                              setAlert({
                                ...alert,
                                message: result.error,
                                show: true,
                                error: true,
                              })
                            }
                            //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                          })
                        }).catch(err => console.error(err)) 
                      }         
                      
                      if(state.attachmentFiles !== null){
                        uploadFileToAzure(`job/${guuid}/attachments`, state.attachmentFiles).then((data_set) => {
                          console.log('upload->res');
                          console.log(data_set);

                          let attachments = [];
                          let attachments_activities = [];
                          for(var data of data_set){
                            let xuuid = uuidv4();
                            let attachment = {}; 
                            attachment.id = xuuid;
                            attachment.name = data.fileName;
                            attachment.url = data.url;
                            attachment.bucket = data.bucket;
                            attachment.path = data.path;
                            attachment.parent_id = guuid; 
                            attachments.push(attachment);
                            attachments_activities.push({
                              user_id: userData.id,
                              parent_id: state.guuid,
                              name: "added attachment",
                              description: `${userData.alias} added attachment ${data.fileName} to job ${object.name}`,           
                            })
                          }
         
                    
                          console.log('attachments')
                          console.log(attachments)
                    
                          addAttachmentsFunction({ user_id: userData.id, objects: attachments, activities: attachments_activities}).then((result)=>{
                            if (result.error) {
                              console.error('ERROR creating attachment', result.error);
                              setAlert({
                                ...alert,
                                message: result.error,
                                show: true,
                                error: true,
                              })
                            }
                            //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                          })
                        }).catch(err => console.error(err)) 
                      }                               
    
                      console.log(`created job board entry children [ ${object} ]`)
                      console.log(result)           
                      setAlert({...alert, show: true, message: 'Successfully created new Job', error: false})
                    }
                  })
                }
              })
            }
          })
        }
      })
    } else {
      createJobNumFunction().then((result)=>{
        if(result.error){
          console.log(`ERROR creating new Job number: ${result.error}`)
          setAlert({...alert, show: true, message: `ERROR creating new Job number: ${result.error}`, error: true})
        } else {
          console.log(result)
          let newJobNum = result.data.update_zeus_document_counter.returning[0].sequence;
          object.name = `${state.prefix}${newJobNum}`;
          object.jobCounter = newJobNum;

          insertJobFunction({
            user_id: userData.id,
            id: state.guuid,
            object: object,
            activity: job_activity,
          }).then((result)=>{
            if(result.error){
              console.log(`ERROR creating job board entry: ${result.error}`)
              setAlert({...alert, show: true, message: `ERROR creating job board entry: ${result.error}`, error: true})
            } else {
              console.log(`created job board entry [ ${object} ]`)
              console.log(result)
    
              insertNotificationFunction({object: {
                name: `Job ${result.data.insert_zeus_jobboard_entry_one.name} created`,
                description: `${userData.alias} created job ${result.data.insert_zeus_jobboard_entry_one.name} description ${object.description}`,
                role: 'admin',
              }});   
              
              insertJobChildrenFunction({
                user_id: userData.id,
                id: state.guuid,
                documents: documents,
                activities: activities,          
              }).then((result)=>{
                if(result.error){
                  console.log(`ERROR creating job board entry children: ${result.error}`)
                  setAlert({...alert, show: true, message: `ERROR creating job board entry children: ${result.error}`, error: true})
                } else {
                  console.log(`created job board entry children [ ${object} ]`)
                  console.log(result)    

                  if(state.poFile !== null){
                    uploadFileToAzure(`job/${guuid}/attachments`, [state.poFile]).then((data) => {
                      console.log('upload->res');
                      console.log(data);

                      data = data[0];
                      let xuuid = uuidv4();
                      let attachment = {}; 
                      attachment.id = xuuid;
                      attachment.name = data.fileName;
                      attachment.url = data.url;
                      attachment.bucket = data.bucket;
                      attachment.path = data.path;
                      attachment.parent_id = guuid;          
                
                      console.log('attachment')
                      console.log(attachment)
                
                      addAttachmentFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
                        if (result.error) {
                          console.error('ERROR creating attachment', result.error);
                          setAlert({
                            ...alert,
                            message: result.error,
                            show: true,
                            error: true,
                          })
                        }
                        //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                      })
                    }).catch(err => console.error(err)) 
                  }   
                  
                  if(state.primacQuoteFile !== null){
                    uploadFileToAzure(`job/${guuid}/attachments`, [state.primacQuoteFile]).then((data) => {
                      console.log('upload->res');
                      console.log(data);

                      data = data[0];
                      let xuuid = uuidv4();
                      let attachment = {}; 
                      attachment.id = xuuid;
                      attachment.name = data.fileName;
                      attachment.url = data.url;
                      attachment.bucket = data.bucket;
                      attachment.path = data.path;
                      attachment.parent_id = guuid;          
                
                      console.log('attachment')
                      console.log(attachment)
                
                      addAttachmentFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
                        if (result.error) {
                          console.error('ERROR creating attachment', result.error);
                          setAlert({
                            ...alert,
                            message: result.error,
                            show: true,
                            error: true,
                          })
                        }
                        //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                      })
                    }).catch(err => console.error(err)) 
                  }  
                  
                  if(state.attachmentFiles !== null){
                    console.log('attachmentFiles');
                    console.log(state.attachmentFiles);

                    uploadFileToAzure(`job/${guuid}/attachments`, state.attachmentFiles).then((data_set) => {
                      console.log('upload->res');
                      console.log(data_set);

                      let attachments = [];
                      let attachments_activities = [];

                      for(var data of data_set){
                        let xuuid = uuidv4();
                        let attachment = {}; 
                        attachment.id = xuuid;
                        attachment.name = data.fileName;
                        attachment.url = data.url;
                        attachment.bucket = data.bucket;
                        attachment.path = data.path;
                        attachment.parent_id = guuid; 
                        attachments.push(attachment);
                        attachments_activities.push({
                          user_id: userData.id,
                          parent_id: state.guuid,
                          name: "added attachment",
                          description: `${userData.alias} added attachment ${data.fileName} to job ${object.name}`,           
                        })
                      }
       
                      console.log('attachments')
                      console.log(attachments)
                
                      addAttachmentsFunction({ user_id: userData.id, objects: attachments, activities: attachments_activities}).then((result)=>{
                        if (result.error) {
                          console.error('ERROR creating attachment', result.error);
                          setAlert({
                            ...alert,
                            message: result.error,
                            show: true,
                            error: true,
                          })
                        }
                        //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                      })
                    }).catch(err => console.error(err)) 
                  }                   
                  
                  setAlert({...alert, show: true, message: 'Successfully created new Job', error: false})
                }
              })
            }
          })
        }
      })
    }

    props.handleClose()
  }



  const handleSubmit_3 = () => {
    console.log("ZAddJobBoardEntryDialog::handleSubmit->"+ JSON.stringify(state));
  
    const activities = [];
    const documents = [];

    // create job
    const { guuid, client, contact, user, tech, serviceType, poDescription, invDescription, customerPODate, invoiceDate, primacPO, ...object } = state;
    object.id = state.guuid;
    object.serviceType = state.serviceType;
    object.quoteAmount = state.quoteAmount;
    object.createdBy = userData.alias

    console.log(object);

    let job_activity = {
      user_id: userData.id,
      parent_id: state.guuid,
      name: "job created",
      description: `${userData.alias} created job ${state.name}`,           
    }

    // create customer PO
    let poID = null;
    if(state.customerPO !== null){
      poID = uuidv4();
      documents.push({
        id: poID,
        document_type: "PurchaseOrder",
        job_id: state.guuid,
        //parent_id: state.guuid,
        client_id: state.client.id,
        name: state.customerPO,
        amount: state.poAmount,
        description: state.poDescription,
        theDate: moment(state.customerPODate).format(),
        status: "pending",
      })

      activities.push({
        user_id: userData.id,
        parent_id: poID,
        name: "customer PO created",
        description: `${userData.alias} created customer PO ${state.customerPO}`,           
      })
    }

    // create primac invoice
    if(state.invoiceNum !== null){
      let invID = uuidv4();
      documents.push({
        id: invID,
        document_type: "Invoice",
        job_id: state.guuid,
        parent_id: poID,
        client_id: state.client.id,      
        name: state.invoiceNum,
        amount: state.invoiceAmount,
        description: state.invDescription,
        theDate: moment(state.invoiceDate).format(),
        status: "pending",
      })
    
      activities.push({
        user_id: userData.id,
        parent_id: invID,
        name: "invoice created",
        description: `${userData.alias} created primac invoice ${state.invoiceNum}`,           
      })    
    }

    //object.salesDocuments = { data: [customerPO, primacInv] }
    //object.activities = { data: [activity] }
    if(state.primacPO){
      console.log('creating new job with new primac PO')
      createPrimacPOFunction().then((result)=>{
        if(result.error){
          console.log(`ERROR creating new primacPO: ${result.error}`)
          setAlert({...alert, show: true, message: `ERROR creating new primacPO: ${result.error}`, error: true})
        } else {
          console.log(result)
          let newPrimacPO = result.data.update_zeus_document_counter.returning[0].sequence;
          object.primacPO = newPrimacPO;
          insertJobFunction({
            user_id: userData.id,
            id: state.guuid,
            object: object,
            activity: job_activity,
          }).then((result)=>{
            if(result.error){
              console.log(`ERROR creating job board entry: ${result.error}`)
              setAlert({...alert, show: true, message: `ERROR creating job board entry: ${result.error}`, error: true})
            } else {
              console.log(`created job board entry [ ${object} ]`)
              console.log(result)
      
              insertNotificationFunction({object: {
                name: `Job ${result.data.insert_zeus_jobboard_entry_one.name} created`,
                description: `${userData.alias} created job ${result.data.insert_zeus_jobboard_entry_one.name} description ${object.description}`,
                role: 'admin',
              }});   
                   
              insertJobChildrenFunction({
                user_id: userData.id,
                id: state.guuid,
                documents: documents,
                activities: activities,          
              }).then((result)=>{
                if(result.error){
                  console.log(`ERROR creating job board entry children: ${result.error}`)
                  setAlert({...alert, show: true, message: `ERROR creating job board entry children: ${result.error}`, error: true})
                } else {
                
                  if(state.customerPO !== null){
                    uploadFileToAzure(`job/${guuid}/attachments`, state.poFile).then((data) => {
                      let xuuid = uuidv4();
                      let attachment = {}; 
                      attachment.id = xuuid;
                      attachment.name = data.fileName;
                      attachment.url = data.url;
                      attachment.bucket = data.bucket;
                      attachment.path = data.path;
                      attachment.parent_id = guuid;          
                
                      console.log('attachment')
                      console.log(attachment)
                
                      addAttachmentFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
                        if (result.error) {
                          console.error('ERROR creating attachment', result.error);
                          setAlert({
                            ...alert,
                            message: result.error,
                            show: true,
                            error: true,
                          })
                        }
                        //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                      })
                    }).catch(err => console.error(err)) 
                  }

                  console.log(`created job board entry children [ ${object} ]`)
                  console.log(result)           
                  setAlert({...alert, show: true, message: 'Successfully created new Job', error: false})
                }
              })
            }
          })
        }
      })
    } else {
      insertJobFunction({
        user_id: userData.id,
        id: state.guuid,
        object: object,
        activity: job_activity,
      }).then((result)=>{
        if(result.error){
          console.log(`ERROR creating job board entry: ${result.error}`)
          setAlert({...alert, show: true, message: `ERROR creating job board entry: ${result.error}`, error: true})
        } else {
          console.log(`created job board entry [ ${object} ]`)
          console.log(result)
 
          insertNotificationFunction({object: {
            name: `Job ${result.data.insert_zeus_jobboard_entry_one.name} created`,
            description: `${userData.alias} created job ${result.data.insert_zeus_jobboard_entry_one.name} description ${object.description}`,
            role: 'admin',
          }});   
          
          insertJobChildrenFunction({
            user_id: userData.id,
            id: state.guuid,
            documents: documents,
            activities: activities,          
          }).then((result)=>{
            if(result.error){
              console.log(`ERROR creating job board entry children: ${result.error}`)
              setAlert({...alert, show: true, message: `ERROR creating job board entry children: ${result.error}`, error: true})
            } else {
              console.log(`created job board entry children [ ${object} ]`)
              console.log(result)    

              if(state.customerPO !== null){
                uploadFileToAzure(`job/${guuid}/attachments`, state.poFile).then((data) => {
                  let xuuid = uuidv4();
                  let attachment = {}; 
                  attachment.id = xuuid;
                  attachment.name = data.fileName;
                  attachment.url = data.url;
                  attachment.bucket = data.bucket;
                  attachment.path = data.path;
                  attachment.parent_id = guuid;          
            
                  console.log('attachment')
                  console.log(attachment)
            
                  addAttachmentFunction({ id: xuuid, user_id: userData.id, object: attachment }).then((result)=>{
                    if (result.error) {
                      console.error('ERROR creating attachment', result.error);
                      setAlert({
                        ...alert,
                        message: result.error,
                        show: true,
                        error: true,
                      })
                    }
                    //let path = generatePath('/quotation/:id', {id: '992ce0d1-0585-49d1-b258-d1a1f9590b81'})    
                  })
                }).catch(err => console.error(err)) 
              }              
              
              setAlert({...alert, show: true, message: 'Successfully created new Job', error: false})
            }
          })
        }
      })
    }

    props.handleClose()
  }


  const handleSubmit_2 = () => {
    console.log("ZAddJobBoardEntryDialog::handleSubmit->"+ JSON.stringify(state));
    const createdOn = moment().format()
    let fullName = userData.firstName +" "+ userData.lastName
    setState({...state, id: state.guuid, createdOn: createdOn, preparedBy: fullName, 
      client_id: state.client.id, contact_id: state.contact.id, user_id: userData.id,
      status: "pending"})

    const activities = [];
    const documents = [];
    const customerPO = null;
    const primacInv = null;

    // create job
    const { guuid, client, contact, user, tech, serviceType, poDescription, invDescription, customerPODate, invoiceDate, ...object } = state;
    object.id = state.guuid;

    console.log(object);

    let job_activity = {
      user_id: userData.id,
      parent_id: state.guuid,
      name: "job created",
      description: `${userData.alias} created job ${state.name}`,           
    }

    // create customer PO
    if(state.customerPO !== null){
      customerPO = {
        document_type: "PurchaseOrder",
        job_id: state.guuid,
        //parent_id: state.guuid,
        client_id: state.client.id,
        name: state.customerPO,
        amount: state.poAmount,
        description: state.poDescription,
        theDate: moment(state.customerPODate).format(),
        status: "pending",
      }
    }

    // create primac invoice
    if(state.invoiceNum !== null){
      primacInv = {
        document_type: "Invoice",
        job_id: state.guuid,
        parent_id: state.guuid,
        client_id: state.client.id,      
        name: state.invoiceNum,
        amount: state.invoiceAmount,
        description: state.invDescription,
        theDate: moment(state.invoiceDate).format(),
        status: "pending",
      } 
    }

    if(customerPO !== null){
      object.salesDocuments = { data: [] }
      object.salesDocuments.data.push(customerPO)
      object.activities = { data: [{
        user_id: userData.id,
        parent_id: state.guuid,
        name: "customer PO created",
        description: `${userData.alias} created customer PO ${state.customerPO}`,           
      }] }

    }

    if(primacInv !== null){
      object.salesDocuments.data.push(primacInv)
      object.activities.data.push({
        user_id: userData.id,
        parent_id: state.guuid,
        name: "invoice created",
        description: `${userData.alias} created primac invoice ${state.invoiceNum}`,           
      })
    }

    insertJobFunction({
      user_id: userData.id,
      id: state.guuid,
      object: object,
    }).then((result)=>{
      if(result.error){
        console.log(`ERROR creating job board entry: ${result.error}`)
        setAlert({...alert, show: true, message: result.error, error: true})
      } else {
        console.log(`created job board entry [ ${object} ]`)
        console.log(result)
        setAlert({...alert, show: true, message: 'Successfully created new Job', error: false})
      }
    })

    props.handleClose()
  }







  const handleSubmit_1 = () => {
    console.log("ZAddJobBoardEntryDialog::handleSubmit->"+ JSON.stringify(state));
    const createdOn = moment().format()
    let fullName = userData.firstName +" "+ userData.lastName
    setState({...state, id: state.guuid, createdOn: createdOn, preparedBy: fullName, 
      client_id: state.client.id, contact_id: state.contact.id, user_id: userData.id,
      status: "pending"})

    const { client, contact, user, tech, serviceType, ...object } = state;

    console.log(object);

    let activity = {
      user_id: userData.id,
      parent_id: state.guuid,
      name: "job created",
      description: `${userData.alias} created job ${state.name}`,           
    }

    insertJobFunction({
      user_id: userData.id,
      id: state.guuid,
      object: object,
      activity: activity,
    }).then((result)=>{
      if(result.error){
        console.log(`ERROR creating job board entry: ${result.error}`)
        setAlert({...alert, show: true, message: result.error, error: true})
      } else {
        console.log(`created job board entry [ ${object} ]`)
        console.log(result)
        setAlert({...alert, show: true, message: 'Successfully created new Job', error: false})
      }
    })

    props.handleClose()
  }

  const handleJobTypeChange = (event,item) => {
    console.log("handleJobTypeChange")
    console.log(item.props.value)
    //let address = item.
    let jobType = item.props.value;
    let isProduct = 0;
    let isService = 1;
    let serviceType = '';

    if(jobType === 'C'){
      isService = 1;
    }

    if(jobType === 'CP'){
      isProduct = 1;
      isService = 0;
    }
    
    if(jobType === 'C(P)'){
      isService = 1;
      isProduct = 1;
    }    

    if(jobType === 'CR'){
      isService = 0;
      isProduct = 0;
      setState({...state, prefix: jobType, serviceType: null, isProduct: isProduct, isService: isService})
      return;
    }   

    if(jobType === 'CI'){
      isService = 0;
      isProduct = 0;
      setState({...state, prefix: jobType, serviceType: null, isProduct: isProduct, isService: isService})
      return;
    }    

    setState({...state, prefix: jobType, isProduct: isProduct, isService: isService})
  }

  const handlePrimacPOChange = (event,item) => {
    console.log("handlePrimacPOChange")
    console.log(item)
    console.log(event.target.checked)
    setState({...state, primacPO: event.target.checked})
  }

  const handleServiceTypeChange = (event,item) => {
    console.log("handleServiceTypeChange")
    console.log(item.props.value)
    setState({...state, serviceType: item.props.value})
  }

  const handleClientChange = (event,item) => {
    console.log("handleClientChange")
    console.log(item)
    setState({...state, client: item, client_id: item.id, companyName: item.name})
  }

  const handleContactChange = (event,item) => {
    console.log("handleContactChange")
    console.log(item)
    setState({...state, contact: item, contact_id: item.id})
  }

  const handlePODateSelected = (theDate, dateRange) => {
    console.log("handlePODateSelected")
    console.log(theDate)
    setState({...state, customerPODate: theDate})
  }

  const handlePOEndDateSelected = (theDate, dateRange) => {
    console.log("handlePOEndDateSelected")
    console.log(theDate)
    setState({...state, customerPOEndDate: theDate})
  }

  const handleInvoiceDateSelected = (theDate, dateRange) => {
    console.log("handleInvoiceDateSelected")
    console.log(theDate)
    setState({...state, invoiceDate: theDate})
  }

  const handleQuoteFileChange = (file) => {
    console.log('handleQuoteFileChange')
    console.log(file)
    if(file.length === 0){
      console.log('no files selected')
      setState({...state, primacQuoteName: null, primacQuoteFile: null});
      return;
    }

    var fileName = file[0].name.substring(0, file[0].name.lastIndexOf('.'));
    console.log('primacQuoteName')
    console.log(fileName)
    setState({...state, primacQuoteName: fileName, primacQuoteFile: file[0]})
  }

  const handleAttachmentFileChange = (file) => {
    console.log('handleAttachmentFileChange')
    console.log(file)
    if(file.length === 0){
      console.log('no files selected')
      setState({...state, attachmentFiles: null});
      return;
    }

    setState({...state, attachmentFiles: file})
  }

  const handlePOFileChange = (file) => {
    console.log('handlePOFileChange')
    console.log(file)
    if(file.length === 0){
      console.log('no files selected')
      setState({...state, poFileName: null, poFile: null});
      return;
    }

    var fileName = file[0].name.substring(0, file[0].name.lastIndexOf('.'));
    console.log('poFileName')
    console.log(fileName)
    setState({...state, poFileName: fileName, poFile: file[0]})   
  }

  const handleUserChange = (event,item) => {
    console.log('setting tech')
    console.log(item)
    setState({...state, tech_id: item.id, tech: item})
  }    
  //const { loading, error, data }  = useQuery(getAllClients);

  //console.log("add quotation dialog->data")
  //console.log(data)

  const PrimacPOCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return(
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alert.show}
        message={alert.message}
        key="alert_button"
        //onClose={()=>{
        //  setAlert({...alert, show: false, message: '', error: false})
        //}}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color={alert.error === true ? "secondary" : "primary"}
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlert({...alert, show: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />     
      <ZWizard 
        title="Create New Job"
        steps={[
          'Job Details',
          'Client Details',
          'Sales Details',
          'PO Details',
          'Invoice Details',
          'Assign Tech',
        ]}
        open={props.open} 
        onClose={props.handleClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
        onReset={handleReset}
        disabled={state.client === null ? true : state.contact === null ? true : ((state.prefix === 'C' || state.prefix === 'CP') && state.primacQuoteName === null) ? true : false}
      >
        {/* job details */}
        <Container maxWidth="sm">
        <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <FormControl className={classes.formControl}>
              <InputLabel id="jobtype-select-label">Job Type</InputLabel>
                <Select
                  labelId="jobtype-select-label"
                  id="jobtype-simple-select"
                  value={state.prefix}
                  onChange={handleJobTypeChange}
                >
                  <MenuItem value={'C'}>Service Job</MenuItem>
                  <MenuItem value={'CP'}>Product</MenuItem>
                  <MenuItem value={'C(P)'}>Service/Product</MenuItem> 
                  <MenuItem value={'CR'}>RMA</MenuItem> 
                  <MenuItem value={'CI'}>Internal</MenuItem> 
                </Select>
              </FormControl>
            </Paper>
            <br/><br/>
            <FormControlLabel
              control={<PrimacPOCheckbox checked={state.primacPO} onChange={handlePrimacPOChange} name="primacPOchcked" />} label="Create Primac PO#"
            />            
            <br/><br/>
            { state.prefix === 'C' || state.prefix === 'C(P)' ?
              <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="srvtype-select-label">Service Type</InputLabel>
                  <Select
                    labelId="srvtype-select-label"
                    id="srvtype-simple-select"
                    onChange={handleServiceTypeChange}
                  >
                    <MenuItem value={'Vibration'}>Vibration</MenuItem>
                    <MenuItem value={'Multi-Channel'}>Multi-Channel</MenuItem>
                    <MenuItem value={'Motion Amplification'}>Motion Amplification</MenuItem> 
                    <MenuItem value={'Ultrasound'}>Ultrasound</MenuItem> 
                    <MenuItem value={'Balancing'}>Balancing</MenuItem> 
                  </Select>
                </FormControl>
              </Paper>
              : <div></div>
            }
              <br/><br/>             
            <Paper sx={{ maxWidth: 600, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="details"
                label="Details"
                value={state.description}
                fullWidth
                multiline
                rows={4}
                onChange={(event) =>{
                  console.log("setDescription->"+ JSON.stringify(event.target.value));
                  setState({...state, description: event.target.value});
                }} 
              />  
            </Paper>  
          </Box>
        </Container>        
        { /*  client details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                props.client 
                ? <ZClientAutocomplete client={props.client} onChange={handleClientChange} />
                : state.client !== null
                ? <ZClientAutocomplete default={state.client} onChange={handleClientChange} />
                : <ZClientAutocomplete open={props.open} onChange={handleClientChange} />
              }
            </Paper>   
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              {
                state.contact !== null
                ? <ZContactAutocomplete client={state.client} default={state.contact} open={props.open} onChange={handleContactChange} />
                : state.client !== null
                ? <ZContactAutocomplete client={state.client} open={props.open} onChange={handleContactChange} />
                : <div></div>
              }
            </Paper> 
          </Box>
        </Container>
        { /*  sales details */ }
        <Container maxWidth="sm">
        { state.prefix === 'CR' || state.prefix === 'CI' ?
          <Box>
            <ZFileCloudUploadMenuItem parent_id={state.guuid} title='Upload Any Attachments' onFileChange={handleAttachmentFileChange} path={`job/${state.guuid}/attachments`} multiple noButton/>              
          </Box>          
          :
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="filled-adornment-amount">Quote Amount</InputLabel>
                <FilledInput
                  id="filled-adornment-amount"
                  value={state.quoteAmount ?? ''}
                  onChange={(event) =>{
                  console.log("quoteAmount->"+ JSON.stringify(event.target.value));
                    setState({...state, quoteAmount: event.target.value});
                  }} 
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
              </FormControl>              
            </Paper>  
            <br/><br/>
            <ZFileCloudUploadMenuItem parent_id={state.guuid} title='Upload Primac Quote File' onFileChange={handleQuoteFileChange} path={`job/${state.guuid}/attachments`} noButton/>              
          </Box>            
        }
        </Container>
        { /*  PO details */ }
        <Container maxWidth="sm">
          <Box>
            <FormControl className={classes.formControl}>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="customerPODate"
                      value={state.customerPODate}
                      label="Customer PO Date"
                      onChange={handlePODateSelected}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />   
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="poEndDate"
                      value={state.customerPOEndDate ?? null}
                      label="End Date"
                      onChange={handlePOEndDateSelected} 
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    /> 
                  </Grid>
                </MuiPickersUtilsProvider> 
              </div> 
            </FormControl>          
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="customerPO"
                label="Customer PO"
                value={state.customerPO ?? ''}
                onChange={(event) =>{
                  console.log("customerPO->"+ JSON.stringify(event.target.value));
                  setState({...state, customerPO: event.target.value});
                }} 
              />  
            </Paper>  
            <br/>
            <ZFileCloudUploadMenuItem parent_id={state.guuid} title='Upload Customer PO File' onFileChange={handlePOFileChange} path={`job/${state.guuid}/attachments`} noButton/> 
            <br/>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="filled-adornment-amount">PO Amount</InputLabel>
                <FilledInput
                  id="filled-adornment-amount"
                  value={state.poAmount ?? ''}
                  onChange={(event) =>{
                  console.log("poAmount->"+ JSON.stringify(event.target.value));
                  setState({...state, poAmount: event.target.value});
                }} 
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
              </FormControl>                 
            </Paper>     
            <br/>
            <Paper sx={{ maxWidth: 600, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="details"
                label="Details"
                value={state.poDescription}
                fullWidth
                multiline
                rows={2}
                onChange={(event) =>{
                  console.log("setDescription->"+ JSON.stringify(event.target.value));
                  setState({...state, poDescription: event.target.value});
                }} 
              />  
            </Paper>                    
          </Box>
        </Container> 
        {/* Invoice details */}
        <Container maxWidth="sm">
          <FormControl className={classes.formControl}>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="invoiceDate"
                    value={state.invoiceDate ?? new Date()}
                    label="Primac Invoice Date"
                    onChange={handleInvoiceDateSelected}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />                 
                </Grid>
              </MuiPickersUtilsProvider> 
            </div> 
          </FormControl> 
          <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
            <TextField
              id="invoiceNum"
              label="Invoice Number"
              value={state.invoiceNum ?? ''}
              onChange={(event) =>{
                console.log("invoiceNum->"+ JSON.stringify(event.target.value));
                setState({...state, invoiceNum: event.target.value});
              }} 
            />  
          </Paper>
          <br/> 
          <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="filled-adornment-amount">Invoice Amount</InputLabel>
              <FilledInput
                id="filled-adornment-amount"
                value={state.invoiceAmount ?? ''}
                onChange={(event) =>{
                  console.log("invoiceAmount->"+ JSON.stringify(event.target.value));
                  setState({...state, invoiceAmount: event.target.value});
                }} 
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
            </FormControl>              
          </Paper> 
          <br/>
            <Paper sx={{ maxWidth: 600, my: 1, mx: 'auto', p: 2 }}>
              <TextField
                id="details"
                label="Details"
                value={state.invDescription}
                fullWidth
                multiline
                rows={4}
                onChange={(event) =>{
                  console.log("setDescription->"+ JSON.stringify(event.target.value));
                  setState({...state, invDescription: event.target.value});
                }} 
              />  
            </Paper>            
        </Container>
        { /*  technician details */ }
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <ZUserAutocomplete open={props.open} label='Choose Tech' default={state.tech} onChange={handleUserChange} />
            </Paper> 
          </Box>
        </Container>          
        <Container maxWidth="sm">
          {console.log(state)}
          <Box>
            <Paper className={classes.scrollablePaper} sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow key='0'>
                      <TableCell align="right">Job Prefix</TableCell><TableCell align="left">{state.prefix}</TableCell>
                    </TableRow>

                    <TableRow key='00'> 
                      <TableCell align="right">Service Type</TableCell><TableCell align="left">{state.serviceType}</TableCell>
                    </TableRow>

                    <TableRow key='1'>
                      <TableCell align="right">PrimacPO#</TableCell><TableCell align="left">{state.primacPO}</TableCell>
                    </TableRow>

                    <TableRow key='2' selected={state.client === null ? true : false}>
                      <TableCell align="right">Client</TableCell><TableCell align="left">{state.companyName ?? 'Client Required'}</TableCell>
                    </TableRow>

                    <TableRow key='3' selected={state.contact === null ? true : false}>
                      <TableCell align="right">Contact</TableCell><TableCell align="left">{state.contact ? `${state.contact.firstName} ${state.contact.lastName}` : 'Contact Required'}</TableCell>
                    </TableRow>

                    <TableRow key='4'>
                      <TableCell align="right">Description</TableCell><TableCell align="left">{state.description}</TableCell>
                    </TableRow>

                    <TableRow key='44'><TableCell align="right"></TableCell><TableCell align="left"></TableCell></TableRow>

                    { state.prefix === 'C' || state.prefix === 'CP' ?
                      <TableRow key='5' selected={state.primacQuoteName === null  ? true : false}>
                        <TableCell align="right">Quote#</TableCell><TableCell align="left">{state.primacQuoteName ?? 'Quote Required'}</TableCell>
                      </TableRow>
                      :
                      <div></div>
                    }

                    { state.prefix === 'C' || state.prefix === 'CP' ?
                      <TableRow key='6' selected={state.quoteAmount === null  ? true : false}>
                        <TableCell align="right">Quote Amount $</TableCell><TableCell align="left">{state.quoteAmount ?? 'Quote Required'}</TableCell>
                      </TableRow>
                    :
                      <div></div>
                    }

                    <TableRow key='7'>
                      <TableCell align="right">Customer PO</TableCell><TableCell align="left">{state.customerPO}</TableCell>
                    </TableRow>

                    <TableRow key='8'>
                      <TableCell align="right">PO Amount $</TableCell><TableCell align="left">{state.poAmount}</TableCell>
                    </TableRow>

                    <TableRow key='9'>
                      <TableCell align="right">Customer PO Date</TableCell><TableCell align="left">{state.customerPO !== null ?? JSON.stringify(state.customerPODate)}</TableCell>
                    </TableRow>

                    <TableRow key='4455'><TableCell align="right"></TableCell><TableCell align="left"></TableCell></TableRow>

                    <TableRow key='10'>
                      <TableCell align="right">Primac Inv</TableCell><TableCell align="left">{state.invoiceNum}</TableCell>
                    </TableRow>

                    <TableRow key='11'>
                      <TableCell align="right">Inv Amount $</TableCell><TableCell align="left">{state.invoiceAmount}</TableCell>
                    </TableRow>

                    <TableRow key='12'>
                      <TableCell align="right">Invoice Date</TableCell><TableCell align="left">{state.invoiceNum !== null ?? JSON.stringify(state.invoiceDate)}</TableCell>
                    </TableRow>

                    <TableRow key='444'><TableCell align="right"></TableCell><TableCell align="left"></TableCell></TableRow>

                    <TableRow key='13'>
                      <TableCell align="right">User</TableCell><TableCell align="left">{userData.alias}</TableCell>
                    </TableRow>

                    <TableRow key='14'>
                      <TableCell align="right">Tech</TableCell><TableCell align="left">{state.tech ? state.tech.alias : 'N/A'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Container>                
      </ZWizard>
    </div>
  )
};

export default ZAddJobBoardEntryDialog;


/*

                  <Typography>Job Prefix: {state.prefix}</Typography>
                  <Typography>PrimacPO#: {state.primacPO}</Typography>
                  <Typography color={state.client === null ? 'secondary' : 'initial'}>
                    Client: {state.companyName ?? 'Client Required'}
                  </Typography>
                  <Typography color={state.contact === null ? 'secondary' : 'initial'}>
                    Contact: {state.contact ? `${state.contact.firstName} ${state.contact.lastName}` : 'Contact Required'}
                  </Typography>
                  <Typography>Description: {state.description}</Typography>   
                  <Typography color={state.primacQuoteName === null  ? 'secondary' : 'initial'}>
                    Quote #: {state.primacQuoteName ?? 'Quote Required'}
                  </Typography>        
                  <Typography>Quote Amount: ${state.primacQuoteName ?? 'Quote Required'}</Typography>
                  <Typography>Customer PO: {state.customerPO}</Typography>
                  <Typography>PO Amount: ${state.poAmount}</Typography>
                  <Typography>PO Date: {JSON.stringify(state.customerPODate)}</Typography>
                  <Typography>Primac Inv: {state.invoiceNum}</Typography>
                  <Typography>Inv Amount: ${state.invoiceAmount}</Typography>
                  <Typography>Invoice Date: {JSON.stringify(state.invoiceDate)}</Typography> 
                  <Typography>User: {userData.alias}</Typography> 
                  <Typography>Tech: {state.tech ? state.tech.alias : 'N/A'}</Typography> 

*/


/*

            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Select
                labelId="service-select-label"
                id="service-simple-select"
                value={state.serviceType}
                onChange={handleServiceTypeChange}
              >
                <MenuItem value={true}>Service</MenuItem>
                <MenuItem value={false}>Equipment</MenuItem>
              </Select>
            </Paper>
              <br/><br/>

*/
