import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getUser, getAllClients, getQuotesForClient, getCheckedOutToolsForUser, getCheckedOutToolNodesForUser, getOpenTickets, getLowBalancePurchaseOrders } from 'queries_urql';
import { addClient, updateUser } from 'mutations_urql';
import { subscribeClient, subscribeUser, subscribeCheckedOutToolNodesForUser, 
  subscribeCheckedOutToolsForUser, subscribeOpenTickets, subscribeLowBalancePurchaseOrders } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LoopIcon from '@material-ui/icons/Loop';//LoopIcon
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Fab from '@material-ui/core/Fab'; //Fab

import ZWizard from 'components/core/ZWizard';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZAddComponentTypeDialog from 'components/dialogs/ZAddComponentTypeDialog';//ZAddComponentTypeDialog
import ZAddConditionTypeDialog from 'components/dialogs/ZAddConditionTypeDialog';//ZAddConditionTypeDialog
import ZAddConditionDescriptorTypeDialog from 'components/dialogs/ZAddConditionDescriptorTypeDialog';//ZAddConditionDescriptorTypeDialog

import ZToolBox from 'components/boxes/ZToolBox';
import ZUserBox from 'components/boxes/ZUserBox';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZToolUsageList from 'components/lists/ZToolUsageList';// ZToolUsageList
import ZToolKitsList from 'components/lists/ZToolKitsList';// ZToolUsageList
import ZToolsList from 'components/lists/ZToolsList';// ZToolUsageList
import ZJobsList from 'components/lists/ZJobsList';//ZJobsList
import ZToolBookingsList from 'components/lists/ZToolBookingsList';//ZToolBookingsList
import ZTicketsList from 'components/lists/ZTicketsList';//ZTicketsList
import ZPurchaseOrdersList from 'components/lists/ZPurchaseOrdersList';//ZPurchaseOrdersList
import ZComponentTypesList from 'components/lists/ZComponentTypesList';//ZComponentTypesList
import ZConditionTypesList from 'components/lists/ZConditionTypesList';//ZConditionTypesList
import ZConditionDescriptorTypesList from 'components/lists/ZConditionDescriptorTypesList';//ZConditionDescriptorTypesList;
//

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import {isMobile} from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';

import eventBus from 'utils/EventBus';

/////////////////////////////////////////

const useStyles = makeStyles((theme)=>({
  root: {
    minWidth: 400,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button_new: {
    margin: theme.spacing(5),
  }, 
  action: {
    margin: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/////////////////////////////////////////

const ZUserView = (props) => {
  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  //let { id } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    showComponentTypeDialog: false,
    showConditionDescriptorTypeDialog: false,
    showConditionTypeDialog: false,
  });
  const [showDialog, setShowDialog] = React.useState(false);
  const [lat, setLat] = React.useState(null);
  const [lng, setLng] = React.useState(null);

  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [updateUserResult, updateFunction] = useMutation(updateUser);

  let row = {};
  let all_clients = [];
  let roles = [];
  let my_checkedout_nodes = [];
  let my_checkedout_tools = [];
  let lo_balance_purchase_orders = [];
  let open_tickets = [];
  let component_types = []

  if(process.env.NODE_ENV === "development"){
    
  }
  
  console.log('userData');
  console.log(userData);

  let shouldPause = false;
  let id = null;

  if(userData){
    id = userData.id;
  }else{
    shouldPause = true;
  }

  if(!window.navigator.onLine){
    shouldPause = true;
  }

  console.log(`user_id: ${id}`);

  /////////////////

  React.useEffect(()=>{
    navigator.geolocation.watchPosition((position)=>{
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      console.log(`ZToolNodeView [ ${position.coords.latitude} , ${position.coords.longitude} ]`)
    })

    console.log('useEffect->userData');
    console.log(userData);

  }, [setLat,setLng,userData,shouldPause]);

  //////////////////

  console.log('ZUserView->shouldPause');
  console.log(shouldPause);

  /*
  const [ result, reexecuteQuery ] = useQuery({
    query: getUser,
    variables: { id },
  });
  */

  const handleUserSubscription = (messages = [], response) => {
    console.log('handleUserSubscription::response');
    console.log(response);
    return response;
  };

  const handleToolNodeSubscription = (messages = [], response) => {
    console.log('handleToolNodeSubscription::response');
    console.log(response);
    //my_checkedout_nodes = [...response.zeus_tool_node];
    return response;
  };

  const handleToolSubscription = (messages = [], response) => {
    console.log('handleToolSubscription::response');
    console.log(response);
    //my_checkedout_tools = [...response.zeus_tool];
    return response;
  };

  const handleTicketSubscription = (messages = [], response) => {
    console.log('handleTicketSubscription::response');
    console.log(response);
    //my_checkedout_tools = [...response.zeus_tool];
    return response;
  };  
 
  const handleLowBalancePOSubscription = (messages = [], response) => {
    console.log('handleLowBalancePOSubscription::response');
    console.log(response);
    //my_checkedout_tools = [...response.zeus_tool];
    let lowBalancePOs = [];
    for(var po of response.zeus_sales_document){
      let poRemaining = 0.00
      let acculatedInv = 0.00
      po.invoices.forEach((inv)=>{
        acculatedInv += inv.amount;
      })

      poRemaining = po.amount - acculatedInv;
      if((acculatedInv / po.amount) > 0.8){
        lowBalancePOs.push(po);
      }
    }

    return lowBalancePOs;
  };  
 
  /////////////////////

  const [resultUser] = useSubscription({
    query: subscribeUser,
    variables: { id }, 
    //shouldPause: shouldPause,
  }, handleUserSubscription); 

  const [ userQueryResult, reexecuteUserQuery ] = useQuery({
    query: getUser,
    variables: { id },
  });

  /////////////

  const [resultTools] = useSubscription({
    query: subscribeCheckedOutToolsForUser,
    variables: { id }, 
    shouldPause: shouldPause,
  }, handleToolSubscription); 

  const [ toolsQueryResult, reexecuteToolsQuery ] = useQuery({
    query: getCheckedOutToolsForUser,
    variables: { id },
  });

  //////////////

  const [resultToolNodes] = useSubscription({
    query: subscribeCheckedOutToolNodesForUser,
    variables: { id }, 
    shouldPause: shouldPause,
  }, handleToolNodeSubscription); 

  const [ toolnodesQueryResult, reexecuteToolNodesQuery ] = useQuery({
    query: getCheckedOutToolNodesForUser,
    variables: { id },
  });

  ///////////////

  const [resultTickets] = useSubscription({
    query: subscribeOpenTickets,
    shouldPause: shouldPause,
  }, handleTicketSubscription); 

  const [ ticketsQueryResult, reexecuteTicketsQuery ] = useQuery({
    query: getOpenTickets,
    variables: { id },
  });

  ///////////////  

  const [resultLoBalPOs] = useSubscription({
    query: subscribeLowBalancePurchaseOrders,
    shouldPause: shouldPause,
  }, handleLowBalancePOSubscription); 

  const [ posQueryResult, reexecutePOsQuery ] = useQuery({
    query: getLowBalancePurchaseOrders,
    variables: { id },
  });

  ///////////////

  const [ clientsQueryResult, reexecuteClientsQuery ] = useQuery({
    query: getAllClients,
  });

  ///////////////  


  let { data: dataUser,  fetching: fetchingUser,  error: errorUser  } = resultUser;
  let { data: dataTools, fetching: fetchingTools, error: errorTools } = resultTools;
  let { data: dataNodes, fetching: fetchingNodes, error: errorNodes } = resultToolNodes;
  let { data: dataTickets, fetching: fetchingTickets, error: errorTickets } = resultTickets;
  let { data: dataLoBalPOs, fetching: fetchingLoBalPOs, error: errorLoBalPOs } = resultLoBalPOs;
  let { data: dataClients, fetching: fetchingClients, error: errorClients } = clientsQueryResult;

  console.log('result');
  console.log('resultUser')
  console.log(resultUser)

  console.log('resultTools')
  console.log(resultTools)

  console.log('resultToolNodes')
  console.log(resultToolNodes)

  console.log('resultTickets')
  console.log(resultTickets)

  console.log('resultLoBalPOs')
  console.log(resultLoBalPOs)

  if(!window.navigator.onLine){
    console.log('not online')
    dataUser = userQueryResult.data;
    fetchingUser = userQueryResult.fetching;
    errorUser = userQueryResult.error;

    dataTools = toolsQueryResult.data;
    fetchingTools = toolsQueryResult.fetching;
    errorTools = toolsQueryResult.error;

    dataNodes = toolnodesQueryResult.data;
    fetchingNodes = toolnodesQueryResult.fetching;
    errorNodes = toolnodesQueryResult.error;

    dataTickets = ticketsQueryResult.data;
    fetchingTickets = ticketsQueryResult.fetching;
    errorTickets = ticketsQueryResult.error;

    dataLoBalPOs = posQueryResult.data;
    fetchingLoBalPOs = posQueryResult.fetching;
    errorLoBalPOs = posQueryResult.error;
  }

  console.log('result');
  console.log('resultUser')
  console.log(dataUser)

  console.log('resultTools')
  console.log(dataTools)

  console.log('resultToolNodes')
  console.log(dataNodes)

  console.log('resultTickets')
  console.log(dataTickets)

  console.log('resultLoBalPOs')
  console.log(dataLoBalPOs)

  console.log('dataClients')
  console.log(dataClients)

  if (fetchingUser || fetchingTools || fetchingNodes || fetchingTickets || fetchingLoBalPOs || fetchingClients ) return (<h2>Loading... <CircularProgress /></h2>);
  if (errorUser || errorTools || errorNodes || errorTickets || errorLoBalPOs || errorClients) return `Error! ${errorUser ? errorUser.message : errorTools ? errorTools.message : errorNodes ? errorNodes.message : errorLoBalPOs ? errorLoBalPOs.message : errorTickets.message }`;

  console.log('ZUserView->dataLoBalPOs')
  console.log(dataLoBalPOs)

  row = dataUser.zeus_zeus_user_by_pk;
  my_checkedout_tools = dataTools.zeus_tool;
  my_checkedout_nodes = dataNodes.zeus_tool_node;
  open_tickets = dataTickets.zeus_ticket;
  all_clients = dataClients.zeus_client;

  if(!window.navigator.onLine){
    lo_balance_purchase_orders = [...dataLoBalPOs.zeus_sales_document];
  } else {
    lo_balance_purchase_orders = [...dataLoBalPOs];
  }
  
  console.log('user')
  console.log(row)

  console.log('open_tickets')
  console.log(open_tickets)

  console.log('my_checkedout_tools')
  console.log(my_checkedout_tools)

  console.log('my_checkedout_nodes')
  console.log(my_checkedout_nodes)

  console.log('lo_balance_purchase_orders')
  console.log(lo_balance_purchase_orders)

  /*
  row.roles.forEach((item)=>{
    let r = item.role;
    roles.push(r.name);
  })
  */

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  /////////////////////

  let listAction = (
    <div className={classes.action}>
      <Fab
        //color="secondary"
        aria-label="add"
        size="small"
        //onClick={reexecuteQuery}
        data-tip="refresh"
        data-for="user_view_tooltip" 
      >
        <LoopIcon />
      </Fab> 
    </div>   
  )

  let addComponentTypeAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showComponentTypeDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>       
  )

  let addConditionTypeAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showConditionTypeDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
  </div>        
  )

  let addConditionDescriptorTypeAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showConditionDescriptorTypeDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>       
  )

  const columns = [
    {
      field: 'client_name',
      title: 'Company Name',
      headerName: 'Company Name',
      minWidth: 320,
      editable: false,
      valueGetter: (params) => {
        //console.log({ params });
        if( params.row.client === null){
          return params.row.companyName;
        } else {
          return params.row.client.name;
        }
      },
      renderCell: (params) => (
        <strong>
          <Router>
            <div>          
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={params.row.client ? (event) => { 
                  let path = generatePath('/client/:id', {
                    id: params.row.client.id}
                    )
                  history.push(path)
                } : null}          
              >
                {params.row.client ? params.row.client.name : 'N/A'}
              </IconButton>
            </div>
          </Router>
        </strong>
      ),      
    },
    { 
      field: 'job_name', 
      title: 'Job', 
      headerName: 'Job',
      width: 140,
      editable: false,
      renderCell: (params) => (
        <strong>
          <Router>
            <div>          
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={params.row.jobBoardEntry ? (event) => { 
                  let path = generatePath('/jobboard_entry/:id', {
                    id: params.row.jobBoardEntry.id}
                    )
                  history.push(path)
                } : null}          
              >
                {params.row.jobBoardEntry ? params.row.jobBoardEntry.name : 'N/A'}
              </IconButton>
            </div>
          </Router>
        </strong>
      ),
    },    
  ]

  /////////////////////

  /*
  show recent tool usage
  show tools currently checked out
  1-button ability to checkout a tool by
        QRcode
        NFC
  1-button ability to checkin a tool by
        QRcode
        NFC
  
  */
  
  return (
    <div>
      <ZAddComponentTypeDialog open={state.showComponentTypeDialog} onClose={()=>setState({...state, showComponentTypeDialog: false})} />
      <ZAddConditionTypeDialog open={state.showConditionTypeDialog} onClose={()=>setState({...state, showConditionTypeDialog: false})} />
      <ZAddConditionDescriptorTypeDialog open={state.showConditionDescriptorTypeDialog} onClose={()=>setState({...state, showConditionDescriptorTypeDialog: false})} />
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ZUserBox id='tool_box' 
              user={row}
              lat={lat}
              lng={lng}
              clients={all_clients}
              fullBox
              withImages
              static
            />
          </Col>      
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZJobsList key='my_jobs_panel' title='Jobs Assigned to Me' action={listAction} parent={row} data={row.jobs_assigned} lat={lat} lng={lng} static />
          </Col>
        </Row>          
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
          { userRoles.includes('admin') && <ZPurchaseOrdersList key='pos_panel' title='Low Balance Client Purchase Orders' action={listAction} parent={row} addColumns={columns} items={lo_balance_purchase_orders} lat={lat} lng={lng} showStatus static /> }
          </Col>
        </Row>          
        <Row><br/></Row>        
        <Row>
          <Col xs={12}>
            <ZToolKitsList key='toolkits_checkedout_panel' title='ToolKits Checkedout to Me' action={listAction} parent={row} data={my_checkedout_nodes} lat={lat} lng={lng} static />
          </Col>
        </Row>    
        <Row><br/></Row>    
        <Row>
          <Col xs={12}>
            <ZToolsList key='tools_checkedout_panel' title='Tools Checkedout to Me' action={listAction} parent={row} data={my_checkedout_tools} lat={lat} lng={lng} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZToolBookingsList key='tool_usage_panel' title='Tools Booked by Me' pageSize={10} action={listAction} parent={row} data={row.tool_bookings} lat={lat} lng={lng} static />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <ZToolUsageList key='tool_usage_panel' title='Tool usage by Me' action={listAction} parent={row}  lat={lat} lng={lng} static  />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            { row.tickets_assigned.length > 0 && <ZTicketsList key='my_tickets_panel' title="Tickets Assigned To Me" action={<div></div>} data={row.tickets_assigned} static/> }
            <Row><br/></Row>
            { userRoles.includes('admin') && <ZTicketsList key='all_tickets_panel' title="All Open Tickets" action={<div></div>} data={open_tickets} static/> }
          </Col>              
        </Row>
        
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            { userRoles.includes('root') && <ZComponentTypesList key='user_component_type_panel' action={addComponentTypeAction} /> }
            <Row><br/></Row>
            { userRoles.includes('root') && <ZConditionTypesList key='user_condition_type_panel' action={addConditionTypeAction} /> }
            <Row><br/></Row>
            { userRoles.includes('root') && <ZConditionDescriptorTypesList key='user_condition_descriptor_type_panel' action={addConditionDescriptorTypeAction} /> }
          </Col>
        </Row>
        
      </Container>
      <ReactTooltip id="user_view_tooltip"/>
    </div>
  )
}

export default ZUserView;

//ZConditionDescriptorTypesList