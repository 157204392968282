
import moment from 'moment';


export default class JobBoardBloC {

  constructor(entry){
    this._entry = entry;
  }

  isActive = () => {
    let rval = false;
    if(this._entry.purchaseOrders.length > 0){
      rval = true;
    }

    if(this._entry.primacPO !== null){
      rval = true;
    }

    if(this._entry.isTravelBooked > 0){
      rval = true;
    }    

    if(this._entry.status === 'completed'){
      rval = false;
    }   

    return rval;
  }

  isCompleted = () => {
    if(this._entry.status === 'completed'){
      return true;
    } else {
      return false;
    }
  }

  isValidCustomerPO = () => {
    let hasValidCustomerPO = false;
  
    if(this._entry.purchaseOrders.length > 0){
      let today = moment();
      hasValidCustomerPO = true;
      let po = this._entry.purchaseOrders[0];

      if(po.closedOn){
        let poCloseDate = moment(po.closedOn)
        if(today.isAfter(poCloseDate)){
          hasValidCustomerPO = false;
        }
      }
      
      if(po.status === 'pending'){
        hasValidCustomerPO = false;
      }

      if(hasValidCustomerPO === false && this._entry.purchaseOrders.length >= 2){
        hasValidCustomerPO = true;
        let po = this._entry.purchaseOrders[1];

        if(po.closedOn){
          let poCloseDate = moment(po.closedOn)
          if(today.isAfter(poCloseDate)){
            hasValidCustomerPO = false;
          }
        }
        
        if(po.status === 'pending'){
          hasValidCustomerPO = false;
        }       
      }

    }
  
    return hasValidCustomerPO;
  }


  isValidCustomerPO_1 = () => {
    var hasValidCustomerPO = false;
  
    if(this._entry.purchaseOrders.length > 0){
      var today = moment();
      hasValidCustomerPO = true;
      this._entry.purchaseOrders.map((po)=>{
        if(po.closedOn){
          var poCloseDate = moment(po.closedOn)
          if(today.isAfter(poCloseDate)){
            hasValidCustomerPO = false;
          }
        }
      })
    }
  
    return hasValidCustomerPO;
  }

  lastCustomerPO = () => {
    let rval = null;
    if(this._entry.purchaseOrders.length > 0){
      rval = this._entry.purchaseOrders[ this._entry.purchaseOrders.length - 1 ];
    }
    return rval;
  }

  accumulatedInvoiced = (lastPO) => {
    let rval = 0.0;
    if(!lastPO){
      lastPO = this.lastCustomerPO();
    }
    if(lastPO !== null){
      if(lastPO.invoices.length > 0){
        lastPO.invoices.forEach((inv)=>{
          rval += inv.amount;
        })
      }
    }

    return rval;
  }

  isInvoiced = () => {
    var hasInvoice = false;
  
    if(this._entry.invoices.length > 0){
      let today = moment();
      hasInvoice = true;  
      var inv = this._entry.invoices[0];
  
      if(this.isValidCustomerPO()){
        var po = this._entry.purchaseOrders[0]
        var poCloseDate = moment(po.closedOn)
        if(today.isAfter(poCloseDate)){
          hasInvoice = false;
        }
      } else {
        hasInvoice = false;
      }
  
      if(hasInvoice){
        hasInvoice = today.isSame( moment(inv.theDate), 'month' )
      }  
    }
  
    return hasInvoice;
  }


  
} ////////

