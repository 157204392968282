import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { BarChart, Bar, Legend, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { monthlySalesQuery } from 'queries';
import { addQuotation, updateSalesDocument } from 'mutations';
*/
import { useQuery, useMutation } from 'urql';
import { monthlySalesQuery } from 'queries_urql';
import { addQuotation, updateSalesDocument  } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//////////////////////////

const ZMSalesDeltaChart = (props) => {
  const theme = useTheme();

  /*
  var { loading: this_year_loading, error: this_year_error, data: this_year_data }  = useQuery(monthlySalesQuery,{ 
    variables: {accepted_year: props.year},
    pollInterval: 10000,
  });

  var { loading: last_year_loading, error: last_year_error, data: last_year_data }  = useQuery(monthlySalesQuery,{ 
    variables: {accepted_year: props.year-1},
    pollInterval: 10000,
  });
  */

  let accepted_year =  props.year
  var [ result, reexecuteQuery ]  = usePollingQuery({
    query: monthlySalesQuery,
    variables: { accepted_year },
  });  
  var { data: this_year_data, fetching: this_year_loading, error: this_year_error  } = result;

  accepted_year = props.year - 1
  var [ result, reexecuteQuery ]  = usePollingQuery({
    query: monthlySalesQuery,
    variables: { accepted_year },
  });  
  var { data: last_year_data, fetching: last_year_loading, error: last_year_error  } = result;

  if (this_year_loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (this_year_error) return `Error! ${this_year_error.message}`;

  //////////////////
  
  const this_years_data = this_year_data.zeus_monthly_sales_view

  console.log("this_years_data")
  console.log(this_years_data);

  if (last_year_loading) return (<h2>Loading... <CircularProgress /></h2>);
  if (last_year_error) return `Error! ${last_year_error.message}`;

  const last_years_data = last_year_data.zeus_monthly_sales_view

  console.log("last_years_data")
  console.log(last_years_data);

  let sales_deltas = []
  var sum = 0.0
  if(typeof props.percentages !== 'undefined' && props.percentages === true) {
    sales_deltas = this_years_data.map( (value,index) => {
      let delta = ((value.total_amount - last_years_data[index].total_amount) / last_years_data[index].total_amount) * 100.0;
      delta = delta.toFixed(1)
      return { accepted_month: value.accepted_month, sales_delta: delta}
    });
  } else {
    sales_deltas = this_years_data.map( (value,index) => {
      let l = last_years_data[index].total_amount;
      let t = value.total_amount;
      if (typeof l === 'undefined'){
        l = 0;
      }
      if (typeof t === 'undefined'){
        t = 0
      }
      let delta = t - l;
      delta = delta.toFixed(1)
      return { accepted_month: value.accepted_month, sales_delta: delta}
    });
    sum = sales_deltas.reduce(function (accumulator, currentValue) {
      var xxx = Number(accumulator) + Number(currentValue.sales_delta);
      console.log(xxx)
      return xxx
    }, 0);
  }

  console.log("monthly sales deltas")
  console.log(sales_deltas);
  console.log('deltas sum')
  console.log(sum)

  const renderCustomAxisTick = ({ x, y, payload }) => {
    let month = '';

    console.log(payload)
    switch (payload.value) {
      case 1:
        month = 'Jan';
        break;
      case 2:
        month = 'Feb';
        break;
      case 3:
        month = 'Mar';
        break;
      case 4:
        month = 'Apr';
        break;
      case 5:
        month = 'May';
        break;
      case 6:
        month = 'Jun';
        break;
      case 7:
        month = 'Jul';
        break;
      case 8:
        month = 'Aug';
        break;
      case 9:
        month = 'Sep';
        break;
      case 10:
        month = 'Oct';
        break;
      case 11:
        month = 'Nov';
        break;
      case 12:
        month = 'Dec';
        break;

      default:
        month = '';
    }

    return (
      <div>{month}</div>
    );
  }

  return (
    <Card>
      <ZTitle>Monthly Sales Deltas: {props.year}</ZTitle>
      {props.percentages ? <Typography style={{marginLeft: '2em'}}>Percent Change</Typography> : <Typography style={{marginLeft: '2em'}}>${sum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Typography>}      
      <Paper>
        <BarChart data={sales_deltas} width={props.width ? props.width : 800} height={props.height ? props.height : 400}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="accepted_month"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            //tick={renderCustomAxisTick}
          >
            <Label
              position="middle"
              style={{
                //textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Month
            </Label>
          </XAxis>
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              {(props.percentages) ? (`Sales Delta (%)`) : (`Sales Delta ($)`)}
            </Label>
          </YAxis>
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Legend />
          <Bar dataKey="sales_delta" fill="#8884d8" />
          <Tooltip/>
        </BarChart>
      </Paper>
    </Card>
  );
}


export default ZMSalesDeltaChart;

/*
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
*/