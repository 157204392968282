import React from 'react';
import clsx from 'clsx';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAllClients } from 'queries';
import { addClient, updateClient } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllClients, getAllClients_old } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { subscribeAllClients } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import Typography from '@material-ui/core/Typography';
import ZWizard from 'components/core/ZWizard';
import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZClientDetailPane from 'components/core/ZClientDetailPane';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Moment from 'react-moment';
import moment from 'moment';

import ReactTooltip from 'react-tooltip';

/////////////////////////////////////////

/*
props: {
  auth: {jwt/access_headers/cookies/etc}
  colums: []                   // column definition
  data: {},
  loading: boolean, 
  error: {}, 
  startPolling: (), 
  stopPolling: (),
  pageSize: int
  handleCellEditCommit: ()={}  // function to handle mutations
}
*/

const ZClientsPanel = (props) => {
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  //const [clientId, storeClientId] = useLocalStorage('client_id', '');
  const [showDialog, setShowDialog] = React.useState(false);
  const [showDetailPane, setShowDetailPane] = React.useState(false);
  const [activeClient, setActiveClient] = React.useState({});
  const [alertState, setAlertState] = React.useState({
    show: false,
    message: '',
    error: false,
  });
  const [activeEntry, setActiveEntry] = React.useState({
    entry: null,
    show: false
  });
 
  let rows = []

  const renderStatusEditInputCell = (params) => {
    return <ZStatusEditInputCell {...params} />;
  }

  const columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      renderCell: (params) => (
        <strong>
          <IconButton 
            component="span"
            variant="contained"
            color="primary"
            size="small"
            data-tip="quick view"
            data-for="clients_panel_tooltip" 
            style={{ marginLeft: 8 }}
            onClick={(event) => { 
              let entry = params.row;
              console.log("selected entry");
              console.log(entry);
              setActiveEntry({
                entry: entry,
                show: true,
              });
            }}          
          >
            <BurstModeIcon />
          </IconButton>  
          <IconButton 
            component="span"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={(event) => { 
              console.log('navigate to client: '+ params.row.id)
              console.log(params.row.id)
              //storeClientId(params.row.id)
              let path = generatePath('/client/:id', {
                id: params.row.id}
                )
              history.push(path)
            }}          
          >
            <OpenInNewIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      field: 'name',
      title: 'Client Name',
      headerName: 'Client Name',
      minWidth: 400,
      editable: false,
      hide: false,
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 350,
      editable: true,
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 150,
      editable: true,
    },
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: false,
    },
    {
      field: 'region.province',
      title: 'Region',
      headerName: 'Region',
      minWidth: 150,
      editable: false,
      valueGetter: (params)=>{
        return params.row.region
      }
    },
    {
      field: 'pending',
      title: 'Quotes',
      headerName: 'Quotes',
      minWidth: 150,
      editable: false,
      valueGetter: (params)=>{
        return params.row.pending_quotations.aggregate.count
        //return 0
      }
    },
    {
      field: 'amount',
      title: 'Amount',
      headerName: 'Amount',
      minWidth: 150,
      editable: false,
      type: 'number',
      valueGetter: (params)=>{
        let amount = 0.00
        //console.log(params.row)
        
        if(params.row.pending_quotations.aggregate.sum.amount !== null){
          amount = params.row.pending_quotations.aggregate.sum.amount
        }
        
        amount = amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        //console.log(`amount: ${amount}`)
        return `$ ${amount}`;
      }
    },
  ];

  ////////////////////////////

  const [updateClientResult, updateFunction] = useMutation(updateClient);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZJobBoardPanel::handleCellEditCommit');
      var modifiedOn = moment().format();
      var user_id = userData.id;

      var _set = {}
      _set[field] = value
      _set.modifiedOn = modifiedOn;

 
      let activity = {
        user_id: user_id,
        parent_id: id,
        name: `client ${field} updated`,
        description: `${userData.alias} updated client ${field} to ${value}`,        
      }

      let variables = { 
        user_id: userData.id,
        id: id, 
        _set: _set,
        activity: activity, 
      }
      console.log(variables)

      updateFunction(variables).then((result)=>{
        if(result.error){
          console.log(`ERROR updating client: ${result.error}`)
          setAlertState({...alertState, show: true, message: result.error, severity: 'error'})
        } else {
          console.log(`client update [ ${_set} ]`)
          console.log(result)
          setAlertState({...alertState, show: true, message: 'Successfully updated Client', severity: 'success'})
        }
      }) 
    },[]
  );  

  const handleCellEditCommit_1 = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZDataGrid::handleCellEditCommit');
      var _set = {}
      _set[field] = value
      updateFunction({variables: { id: id, _set }})
    },
    [],
  );

  const handleClickOpen = () => {
    console.log("ZClientsPanel::handleClickOpen->open dialog wizard")
    setShowDialog(true);
    console.log("ZClientsPanel::handleClickOpen == "+ showDialog);
  };
  
  const handleClose = () => {
    setShowDialog(false);
    //setSelectedValue(value);
  };

  const handleCloseDetailPane = () => {
    setActiveEntry({
      show: false,
      entry: null,
    })
  };  

  /////////////////

  //use subscription
  const handleSubscription = (messages = [], response) => {
    console.log('handleSubscription::response')
    console.log(response)
    return response;
  }
  
  const [subResult] = useSubscription({ 
    query: subscribeAllClients, 
    pause: !window.navigator.onLine, 
  }, handleSubscription); 

  const [ result, reexecuteQuery ] = useQuery({
    query: getAllClients,
    //pause: window.navigator.onLine
  });

  console.log('res');
  console.log(subResult)  

  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = result.data;
    fetching = result.fetching;
    error = result.error;
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  rows = data.zeus_client.slice(0)

  /////////////////

  return (
    <div>
      {/* alert component */} 
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={10000}
        open={alertState.show}
        key="alert_button"
        onClose={()=>{
          setAlertState({...alertState, show: false, message: '', severity: 'info'})
        }}
      >
        <Alert severity={alertState.severity} 
          onClose={()=>{
            setAlertState({...alertState, show: false, message: '', severity: 'info'})
        }}>
          {alertState.message}
        </Alert>
      </Snackbar>       
      {/* add client component */} 
      <ZAddClientDialog open={showDialog} handleClose={handleClose}/>
      {/* client detail pane component */} 
      <ZClientDetailPane isOpen={activeEntry.show} action={<div/>} handleClose={handleCloseDetailPane} client={activeEntry.entry} static/>
      {/* alert component */} 
      <Card  variant="outlined" elevation={2}>
        <CardContent>
        <Box display="flex" bgcolor="#5c6bc0">
          <Typography style={{color:"#FFFFFF", marginLeft: 12, flex: 1,}} variant="h4" component="h2">
            Clients
          </Typography>
          <IconButton
            onClick={handleClickOpen}
            style={{color:"white"}}
            color="inherit"
            aria-label="add row"
            edge="start"
            data-tip="add new client"
            data-for="clients_panel_tooltip" 
          >
            <AddCircleOutlineIcon/>
          </IconButton>
        </Box>
          <DataGrid autoHeight
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            options={{
              toolbar: true,
              filtering: true,
              search: true,
              grouping: true,
              exportButton: true,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellEditCommit={handleCellEditCommit}
            onRowClick={(params, evt) => {
              let client = params.row;
              console.log("selected client")
              console.log(client)
              setActiveClient(client)
              setShowDetailPane(true)
            }}
          />
        </CardContent>
      </Card>
      <ReactTooltip id="clients_panel_tooltip"/>
    </div>
  );
}

export default ZClientsPanel;


/*

    <ZWizard open={showDialog} onClose={handleClose} steps={['step-1','step-2','step-3','step-4']}>
        <Typography>Step 1</Typography>
        <Typography>Step 2</Typography>
        <Typography>Step 3</Typography>
        <Typography>Step 4</Typography>
    </ZWizard>
*/
