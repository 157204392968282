import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAsset } from 'queries_urql';
import { updateAsset, updateAssetStatus } from 'mutations_urql';
import { subscribeAsset } from 'subscriptions_urql';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import TimelineIcon from '@material-ui/icons/Timeline';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';//OpenIcon
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import ZWizard from 'components/core/ZWizard';
import ZAssetBox from 'components/boxes/ZAssetBox'
import ZAddAssetSurveyDialog from 'components/dialogs/ZAddAssetSurveyDialog';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZScheduleList from 'components/lists/ZScheduleList';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';// ZAttachmentsList
import ZAssetSurveyList from 'components/lists/ZAssetSurveyList';
import ZComponentsList from 'components/lists/ZComponentsList';
import ZSensorsList from 'components/lists/ZSensorsList';
import ZInvoicesList from 'components/lists/ZInvoicesList';//ZInvoicesList
import ZToolKitsList from 'components/lists/ZToolKitsList';// ZToolKitsList
import ZToolsList from 'components/lists/ZToolsList';// ZToolsList
import ZPurchaseOrdersList from 'components/lists/ZPurchaseOrdersList';//ZPurchaseOrdersList
import ZJobBoardEntryBox from 'components/boxes/ZJobBoardEntryBox';// ZJobBoardEntryBox
import ZAddJobPurchaseOrderDialog from 'components/dialogs/ZAddJobPurchaseOrderDialog';
import ZAddComponentDialog from 'components/dialogs/ZAddComponentDialog';
import ZAssetSurveyTimeChart from 'components/charts/ZAssetSurveyTimeChart';

import JobBoardBloC from 'blocs/JobBoardBloC';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import { DatePicker } from  'react-responsive-calendar-picker'
import  'react-responsive-calendar-picker/dist/index.css';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';


import ReactTooltip from 'react-tooltip';
import ZAddAnnotationDialog from './dialogs/ZAddAnnotationDialog';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZBreadCrumbs from 'components/core/ZBreadCrumbs';

import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  image_avatar: {
    backgroundColor: green[500],
  },
}));

//////////////////////////////////////////////

const ZAssetView = (props) => {

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
  
  const classes = useStyles();
  let { id } = useParams();

  const [state, setState] = React.useState({
    statusOpen: false,
    showAnnotationDialog: false,
    showSurveyDialog: false,
    showComponentDialog: false,
    message: '',
    action: null,
  });
  const [userData, setUserData] = useLocalStorage('userData');

  const [tabIndex, setTabIndex] = React.useState(0);

  const [showDialog, setShowDialog] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    severity: 'info',
    open: false,
    message: '',
  });

  const [lat, setLat] = React.useState(null);
  const [lng, setLng] = React.useState(null);

  let row = {}
  let jobs = []

  console.log(`ZAssetView->id->${id}`);

  const handleAddSurvey = () => {
    console.log('handleAddSurvey')
    //setShowSurveyDialog(true);
    setState({...state, showSurveyDialog: true});
  }

  const handleSurveyClose = () => {
    //setShowSurveyDialog(false);
    setState({...state, showSurveyDialog: false});
  };

  const handleCloseAnnotation = () => {
    //setShowAnnotationDialog(false);
    setState({...state, showAnnotationDialog: false});
  }

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  
  const handleChangeStatusPending = () => {
    console.log('ZAssetView::handleChangeStatusPending')
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: id,
        name: "survey status pending",
        description: `${userData.alias} updated asset set status pending ${modifiedOn}`,
      },
      _set: {
      status: 'pending',
      modifiedOn: modifiedOn,
    }}

    console.log(variables)

    updateAssetStatusFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          severity: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          severity: 'success',
          open: true,
          message: 'Successfully updated asset status',
        })
      }
      setState({...state, statusOpen: false})
    })       
  }

  const handleChangeStatusActive  = () => {
    console.log('handleChangeStatusActive')
    let user_id = userData.id
    let modifiedOn = moment().format();

    let variables = { 
      id: id, 
      user_id: user_id,
      activity: {
        user_id: user_id,
        parent_id: id,
        name: "asset status active",
        description: `${userData.alias} updated asset set status active ${modifiedOn}`,
      },
      _set: {
      status: 'active',
      completedOn: modifiedOn,
      modifiedOn: modifiedOn,
    }}

    console.log(variables)

    updateAssetStatusFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          severity: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          severity: 'success',
          open: true,
          message: 'Successfully updated asset status',
        })
      }
      setState({...state, open: false})
    })       
  } 

  const [updateResult, updateAssetFunction] = useMutation(updateAsset);
  const [updateStatusResult, updateAssetStatusFunction] = useMutation(updateAssetStatus);//updateJobBoardEntryStatus

  const handleSubscription = (previousData, response) => {
    console.log('ZAssetView->handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_asset_by_pk
    return response;
    //return row;
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('ZAssetView setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeAsset,
    variables: { id },
    pause: !window.navigator.onLine,
  }, handleSubscription); 

  console.log('ZAssetView setting up query')
  const [ queryResult, reexecuteQuery ] = useQuery({
    query: getAsset,
    variables: { id },
    //pause: window.navigator.onLine,
  });
  
  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }

  console.log('ZAssetView->subResult')
  console.log(subResult);

  console.log('ZAssetView->queryResult')
  console.log(queryResult);

  let UpdateStatus = (
    <div>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={12000}
              open={state.open}
              key="set_job_active"
              onClose={()=>{
                setState({...state, statusOpen: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              message={state.message}
              action={
                <div>
                  <Button className={classes.button} color="default" variant="contained" onClick={state.action}>
                    Yes
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, statusOpen: false})
                    }}
                  >
                    No
                  </Button>
                </div>
              }
            />
          </div>
        </Router>
      </strong>
    </div>    
  )

  /*
  React.useEffect(()=>{
    if(!data) return;
    var bloc = new JobBoardBloC(data)
    console.log('JobBoardBloC');
    if(bloc.isActive() && data.status === 'pending'){
      console.log('job status is still pending but enough info to be active, changing status')
      setTimeout((data)=>{
        console.log('showing status update dialog')
        setState({...state, action: handleChangeStatusActive, open: true, message: 'Job status is still pending but has enough info to be active, would you like to change status to active?'})
      }, 5000);      
    }

    if(!bloc.isValidCustomerPO() && data.status === 'active'){
      console.log('job status is still active but customer PO has expired, asking to set to completed')
      setTimeout((data)=>{
        console.log('showing status update dialog')
        setState({...state, action: handleChangeStatusCompleted, open: true, message: 'Job status is still active but Customer PO has expired, would you like to change status to completed?'})
      }, 5000);  
    }
  },[data])
  */

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;
  
  console.log('ZAssetView->data');
  console.log(data);

  if(data){
    row = data.zeus_asset_by_pk;
    console.log(row);
  }

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showAnnotationDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addSurveyAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showSurveyDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addComponentAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showComponentDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>    
  )

  let toprow = (
    <Row>
      <Col xs={6}>      
        <ZAssetBox id='entry_box' 
          asset={row}
          name={row.name} 
          description={row.description} 
          annotations={row.annotations}
          refresh={executeSubscription}
          //statusUpdate={handleStatusUpdate}
          //showmap
          //scheduled={data.scheduled}
          //fullBox
          static
        />
      </Col>
      <Col xs={6}>
        { row.activities ?
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="jobboard_entry">
                  <TimelineIcon />
                </Avatar>
              }
              title='Timeline'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZActivitiesTimeline items={row.activities} />
            </Paper>  
          </Card> 
          : <div/>
        }  
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`asset/${row.id}/attachments`} multiple static/>
        <Row><br/></Row>
        <Card variant="outlined">
          <CardHeader
            avatar={
              <Avatar aria-label="asset_survey_images" className={classes.image_avatar}>
                <PhotoLibraryIcon />
              </Avatar>
            }
            title='Images'
            titleTypographyProps={{variant:'h5'}}
          />
          <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
            <ZImageListHorizontal key='image_list' parent_id={row.id} path={`asset/${row.id}/images`} data={row.images} />
          </Paper>  
        </Card>         
        <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />           
      </Col>
    </Row>    
  )

  let assetChartRow = (
    <Row>
      <Col xs={12}> 
        <Card variant="outlined">
          <CardHeader
            avatar={
              <Avatar aria-label="asset_survey_chart" className={classes.image_avatar}>
                <DeveloperBoardIcon />
              </Avatar>
            }
            title='Condition Over Time'
            titleTypographyProps={{variant:'h5'}}
          />
          <Paper className={classes.scrollablePaper} elevation={3} variant="outlined"> 
            <ZAssetSurveyTimeChart  items={row.surveys} />
          </Paper>
        </Card>
      </Col>
    </Row>   
  )

  /////////////////////

  return (
    <div>
      { UpdateStatus }
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={3000}
        open={alertState.open}
        key="job_state_active"
        onClose={()=>setAlertState({...alertState, open: false})}
      >
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>      
      </Snackbar>

      <Container fluid>
        <ZAddAssetSurveyDialog open={state.showSurveyDialog} client={row.client} parent={row} asset={row} job={row} onClose={()=>setState({...state, showSurveyDialog: false})} />
        <ZAddComponentDialog open={state.showComponentDialog} client={row.client} parent={row} asset={row} onClose={()=>setState({...state, showComponentDialog: false})} />
        {toprow}
        <Row><br/></Row>
        {assetChartRow}
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Asset Surveys" />
                  <Tab label="Components" />
                  <Tab label="Sensors" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={220} descriptionW={350} action={addNoteAction} parent={row} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <ZAssetSurveyList key='survey_panel' title='Asset Surveys' action={addSurveyAction} parent={row} items={row.surveys} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    <ZComponentsList key='components_panel' title='Components' nameW={280} descriptionW={450} client={row.client} parent={row} action={addComponentAction} items={row.components} static/>
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box>
                    <ZSensorsList key='sensors_panel' title='Sensors' nameW={280} descriptionW={450} client={row.client} parent={row} action={<div></div>} items={row.sensors} static/>
                  </Box>
                )}
              </Box>
            </Paper>
          </Col>        
        </Row>                
      </Container>
    </div>
  )


}

export default ZAssetView;

/*

  return (
    <div>
      { UpdateStatus }

      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={3000}
        open={alertState.open}
        key="job_state_active"
        //message='Job status is still pending but has enough info to be active, would you like to change status to active?'
      >
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>      
      </Snackbar>

      <Container fluid>
        {toprow}
        <Row><br/></Row>
        <Row>
          <Col xs={6}>
            <ZScheduleList key='schedule_panel' title='Scheduled Dates' action={<div></div>} parent={data} data={data.scheduled_dates} static/>
          </Col>
          <Col xs={6}>
            <ZToolsList key='tools_booked_panel' title='Tools Booked' action={<div></div>} parent={data} data={tools_booked} static/>
            <Row><br/></Row>
            <ZToolKitsList key='toolkits_booked_panel' title='ToolKits Booked' action={<div></div>} parent={data} data={toolnodes_booked} static/>
          </Col>            
        </Row>  
        <Row>
          <Col xs={6}>
            
            <ZPurchaseOrdersList key='purchase_orders_panel' action={<div></div>} items={data.purchaseOrders} static/>
          </Col>
          <Col xs={6}>
            <Row><br/></Row>
            <ZInvoicesList key='invoices_panel' action={<div></div>} items={data.invoices} static/>
          </Col>          
        </Row>  
        <Row><br/></Row>
        <Row>
          <Col xs={6}>
            <ZToolKitsList key='toolkits_panel' title='Toolkits Used' action={<div></div>} parent={data} data={toolkits} static/>
          </Col>
          <Col xs={6}>
            <ZToolsList key='tools_panel' title='Tools Used' action={<div></div>} parent={data} data={tools} static/>
          </Col>          
        </Row>                
      </Container>
    </div>
  )

*/

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/
