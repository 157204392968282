import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { v4 as uuidv4 } from 'uuid';

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useMutation } from '@apollo/client';
import { getAllClients } from '../../queries';
import { addClient, updateClient } from '../../mutations';
*/

import { useQuery, useMutation } from 'urql';
import { getAllClients } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close'; 
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; 
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; 
import GoogleMap, { Marker } from 'react-maps-google';

// If you want to use the provided css
//import 'react-google-places-autocomplete/dist/index.min.css';

import  ZWizard  from '../core/ZWizard';
import moment from 'moment';

//////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//////////////////////////////////////////

const ZAddClientDialog = (props) => {
  const classes = useStyles();

  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({})
  const [address, setAddress] = React.useState({})
  const [region, setRegion] = React.useState({})
  const [name, setName] = React.useState(null)
  const [description, setDescription] = React.useState(null)
  const [geometry, setGeometry] = React.useState({})
  

  const [alertState, setAlertState] = React.useState({
    show: false,
    message: '',
    severity: 'info',
    error: false,
  })

  const [insertResult, insertFunction] = useMutation(addClient);

  ////////////////

  const handleClose = () => {
    if(props.onClose){
      props.onClose();
    }
  }

  const handleReview = () => {
    //console.log("handleReview->address"+ JSON.stringify(address));
    //console.log("handleReview->address.description"+ JSON.stringify(address.value.description));
    const x = {
      name: name,
      description: description,
      //region: region,
      //address: address,
    }
    console.log("handleReview->"+ JSON.stringify(x));
    setState(x) 
  }

  const foundAddress = (place) => {
    geocodeByAddress(place.value.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log('Successfully got latitude and longitude', { lat, lng })
      place['geometry'] = {lat: lat, lng: lng}
      
      console.log("foundAddress->"+ JSON.stringify(place));
      var index = place.value.terms.length
      var country = place.value.terms[index-1].value
      var province = place.value.terms[index-2].value
      place['region'] = {province: province, country: country}
      setRegion({province: province, country: country})
      setGeometry({ lat, lng })
      setAddress(place) 
    });    
  }

  const handleSubmit = () => {
    console.log("insertFunction->"+ JSON.stringify(state));

    let guuid = uuidv4();

    let object = { 
      id: guuid,
      name: name, 
      description: description, 
      address: address,
    }


    let client_activity = {
      user_id: userData.id,
      parent_id: guuid,
      name: `client created`,
      description: `${userData.alias} created client ${name}`,           
    }

    insertFunction({
      id: guuid,
      user_id: userData.id,
      object: object,
      activity: client_activity,
    }).then((result)=>{
      if(result.error){
        console.log('ERROR creating client')
        console.log(result)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
          error: true,
        })
      } else {
        console.log('successfully created new client')
        setAlertState({
          open: true,
          message: `Successfully created client ${name}`,
          severity: 'info',
          error: false,
        })
      }
    });

    handleClose()    
  }

  const handleReset = () => {
    setState({})
    setAddress({})
    setRegion({})
    setName(null)
    setDescription(null)
    setGeometry({})

    handleClose();
  }

  return(
    <div>
     <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={10000}
        open={alert.show}
        message={alertState.message}
        key="alert_button"
        //onClose={()=>{
        //  setAlert({...alert, show: false, message: '', error: false})
        //}}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color={alertState.error === true ? "secondary" : "primary"}
              startIcon={<CloseIcon />}
              onClick={()=>{
                setAlertState({...alertState, show: false, message: '', error: false})
              }}
            >
              Close
            </Button>
          </div>
        }
      />      
      <ZWizard 
        title="Create New Client"
        steps={['Enter Client Details','Enter Address']}
        open={props.open} 
        onClose={props.handleClose}
        onReview={handleReview}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <TextField 
                onChange={(event) =>{
                  console.log("setName->"+ JSON.stringify(event.target.value));
                  setName(event.target.value);
                } }
                id="clientName" 
                label="Client Name" 
                variant="standard"
              />
            </Paper>   
            <br/> 
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                <TextField
                  id="clientDescription"
                  label="Description"
                  multiline
                  rows={4}
                  onChange={(event) =>{
                    console.log("setDescription->"+ JSON.stringify(event.target.value));
                    setDescription(event.target.value);
                  }} 
                />  
            </Paper> 
          </Box>
        </Container>
        <Container maxWidth="sm">
          <Box>
            <div>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyCb8p4Ce3dyBTwgTeCN-79V2YZPBYntWBI"
                onLoadFailed={(error) => (
                  console.error("Could not inject Google script", error)
                )}
                selectProps={{
                  address,
                  onChange: foundAddress,
                }}
              />             
            </div>
          </Box>
        </Container>

        <Container maxWidth="sm">
          <Box>
            <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
              <Typography>Name: {name}</Typography>
              <Typography>Description: {description ? description : 'N/A'}</Typography>
              <Typography>Address: {JSON.stringify(address.label)}</Typography>
            </Paper>
          </Box>
        </Container>    
      </ZWizard>
    </div>
  )
};

export default ZAddClientDialog;

/*

          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"



                  <Box>
          <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Region</InputLabel>
            <Select
              labelId="region-select-label"
              id="region-simple-select"
              value={region}
              onChange={(event) =>{
                console.log("handleRegionChange->"+ JSON.stringify(event.target.value));
                setRegion(event.target.value);
              }}
            >
              <MenuItem value='AB'>AB</MenuItem>
              <MenuItem value='BC'>BC</MenuItem>
              <MenuItem value='SD'>SK</MenuItem>
            </Select>
          </FormControl>
          </Paper>    
        </Box>
*/
